<template>
<div class="root-dados">
  <div class="container list-data">

    <ModalCentral
      componentName="UploadEgressos"
      title="Carregar egressos"
      idName="uploadEgressos"
      />

    <ModalCentral
      componentName="AddEgresso"
      :title="egressoEditing == null ? 'Adicionar egresso' : 'Atualizar egresso'"
      idName="addEgresso"
      :data="{ editing: egressoEditing }"
      />

    <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-campo"/>
    <br/>

    <h3 class="title-info"> Egressos </h3>

    <div class="title-row">
      <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
    </div>

    <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os Egressos da Instituição. Caso necessário, utilize o filtro abaixo para localizar o Egresso desejado.</p>

    <template v-if="userPerm.permissao != 'APLICADOR'">
      <div class="lista-data-info">
        <Button
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Carregar dados"
          :isOutline="true"
          :hasIcon="true"
          iconName="user-friends"
          data-toggle="modal"
          data-target="#uploadEgressos"
          />

        <Button
          @click="egressoEditing = null"
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Adicionar egresso"
          :isOutline="true"
          :hasIcon="true"
          iconName="plus"
          data-toggle="modal"
          data-target="#addEgresso"
          />
      </div>
      <br />
    </template>

    <div class="input-group">
      <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar egressos por nome..." autocomplete="off">
      <div class="input-group-append">
        <span class="input-group-text">Nome</span>
      </div>
    </div>
    <hr class="separator">

    <div v-if="isLoadedInfo">
      <MemberQnts
        term="egressos"
        :totalQnt="totalEgressos"
        :filteredQnt="egressos?.length || 0"
        icon="clipboard" />

      <div class="list-box">
        <transition name="slide-fade" v-if="egressos?.length > 0">

          <div class = "table-wrap">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col">  </th>
                  <th scope="col">Nome</th>
                  <th scope="col">CPF</th>
                  <th scope="col">Data de nascimento</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <TransitionGroup name="list" appear tag="tbody">
                <tr class="table-row" v-for="egresso in egressos" :key="egresso.counter" >

                  <td>
                    <img
                      class="img-info-box-lista"
                      src="@assets/img/Dados/ListarMembros/coordenador-user-blue.png"
                      alt="Ícone de egresso" />
                  </td>

                  <td>{{ egresso.nome }}</td>

                  <td>
                    {{ egresso?.cpf ? formatCpf(`${egresso.cpf}`) : 'Não informado' }}
                  </td>

                  <td>
                    <span class="second-info">{{ egresso?.dataNascimento ? maskDate(egresso.dataNascimento) : 'Não informada' }}</span>
                  </td>

                  <td>
                    <div class="controlGroup">
                      <Button
                        v-if="!egresso.isEditionLocked"
                        value="Editar egresso"
                        class="orange-text"
                        section="instituicao-campo"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="pen"
                        data-toggle="modal"
                        data-target="#addEgresso"
                        @click="egressoEditing = egresso"
                        />

                      <Button
                        v-if="!egresso.isEditionLocked"
                        value="Excluir egresso"
                        class="orange-text"
                        section="instituicao-campo"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="trash"
                        @click="removeEgresso(egresso)"
                        />
                    </div>
                  </td>

                </tr>
              </TransitionGroup>
            </table>
          </div>

        </transition>

        <transition v-else>
          <h2 class="title-style-box-lista">Nenhum egresso encontrado.</h2>
        </transition>
      </div>

    </div>

    <div v-else>
      <SkeletonList/>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@components/Breadcrumb';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo';
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import ModalCentral from '@components/ModalCentral';
import Button from '@components/Button';
import Swal from 'sweetalert2';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'left',
    y: 'bottom',
  }
});

export default {
  components: {
    Breadcrumb,
    InstitutionInfo,
    ModalCentral,
    Button,
    SkeletonList,
    MemberQnts,
  },
  data: () => ({
    searchByName: '',
    egressoEditing: null,
    isLoadedInfo: false,
    crumbs: [
      {
        id: 1,
        value: '',
        iconName: 'th',
        name_route: 'GerenciarInstituicoes'
      },
      {
        id: 2,
        value: 'Instituição',
        iconName: 'university',
        name_route: 'InstituicaoMenu'
      },
      {
        id: 3,
        value: 'Cadastrar Informações',
        iconName: 'search-plus',
        name_route: 'InstituicaoMenuCadastrar'
      },
      {
        id: 4,
        value: 'Egressos',
        iconName: 'door-open',
        name_route: ''
      }
    ],
  }),
  beforeMount() {
    window.scrollTo(0, 0);
  },
  async created() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao
    };
    this.$store.dispatch('setUserPerm', payload);

    await this.$store.dispatch('loadListEgressos', payload);
    this.isLoadedInfo = true;
  },
  computed: {
    userPerm() {
      return this.$store.getters.getUserPerm;
    },
    teams() {
      return this.$store.getters.getListTeams;
    },
    egressos() {
      let query = this.$store.getters.getListEgressos;

      if (this.searchByName.trim() != '') {
        let filtered = query?.filter(egresso => egresso.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()));

        if (filtered?.length != 0)
          query = filtered;
        else
          query = false;
      }
      return query;
    },
    totalEgressos() { return this.$store.getters.getListEgressos?.length || 0; },
    nomeInstituicao() {
      return this
        .teams
        .find(insti => insti.idinstituicao == this.$route.params.idInstituicao)
        ?.name || '';
    },
  },
  methods: {
    maskDate(inputDate){
      let date = new Date(inputDate);
      var day = date.getUTCDate();
      var month = date.getUTCMonth() + 1;
      var year = date.getUTCFullYear();

      var formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
      return formattedDate
    },
    formatCpf(cpf) {
      return cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring(6, 9) + '-' + cpf.substring(9, 11);
    },
    async removeEgresso(egresso) {
      const result = await Swal.fire({
        title: "Tem certeza que deseja excluir esse egresso?",
        text: "Essa ação não poderá ser revertida.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
      });

      if (!result.isConfirmed) return;

      this
        .$store
        .dispatch("removeEgresso", {
          idInstituicao: this.$route.params.idInstituicao,
          idEgresso: egresso.idEgresso,
        })
        .then(() => {
          if (!this.$store.getters.getIsEgressosRemoved) {
            notyf.error("Erro ao excluir o egresso");
            return;
          }

          notyf.success("Egresso excluído com sucesso");
          this.$store.dispatch('loadListEgressos', {
            idInstituicao: this.$route.params.idInstituicao,
          });
        });
    },
  },
}
</script>

<style scoped>
div {
    color: currentColor;
}

/* LIST TRANSITION */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
