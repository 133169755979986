<template>

    <div class="root-dados">
        <ModalCentral
            componentName="InfoQuestoes"
            :title="modalTitle"
            idName="questForm"
            :data="{ items: showItems, form: true, }"
            />

        <div class="container list-data">
            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-campo"/>
            <br/>

            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os Formulários relacionados ao Campo. Caso necessário, utilize o filtro abaixo para localizar o Formulário desejado.</p>

            <template v-if="userPerm.permissao != 'APLICADOR' && campoStatus != 'OFF'">
                <div class="lista-data-info">
                    <Button
                        @click="setSelectAllItems"
                        class="btn greenGradient"
                        section="instituicao-campo"
                        value="Marcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check-square"
                        style="height: min-content;"
                    />
                    <Button
                        @click="setUnselectAllItems"
                        class="btn ml-2 greenGradient"
                        section="instituicao-campo"
                        value="Desmarcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="square"
                        style="height: min-content;"
                    />
                    <Button
                        @click="applyChanges"
                        class="btn ml-2 greenGradient"
                        section="instituicao-campo"
                        value="Adicionar formulário ao campo"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                        style="height: min-content;"
                    />

                    <router-link :to="{ name: 'AddForm', }">
                        <Button
                            class="btn ml-2 greenGradient"
                            section="instituicao-campo"
                            value="Criar Formulário"
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="plus"
                            style="height: min-content;"
                        />
                    </router-link>
                </div>
                <br />
            </template>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar formulários por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS FORMULÁRIOS DO CAMPO ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnts
                    term="formulários"
                    :totalQnt="totalFormularios"
                    :filteredQnt="formularios_filtered?.length || 0"
                    icon="clipboard" />

                <transition-group v-if="formularios_filtered?.length > 0" name="slide-fade">

                    <div class="table-wrap">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">  </th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Data de criação</th>
                                    <th scope="col">Código de aplicação</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row" v-for="formulario in formularios_filtered" :key="formulario.idformulario">

                                    <td>
                                        <img  class="img-info-box-lista" src="@assets/img/Cards/Documento.svg" alt="">
                                    </td>

                                    <td> {{formulario.nome}} </td>

                                    <td> {{ dateForm(formulario.dataCriacao) }} </td>

                                    <td> {{ formulario.identificador == 'código' ? formulario.curinga : '-' }} </td>

                                    <td>
                                        <div class="controlGroup">
                                            <div v-if="userPerm.idPermissao != '1'" :class="campoStatus == 'OFF'? 'not-clickable' : ''">
                                                <div v-if="formulario.isEditionLocked">Selecionado</div>
                                                <div v-else>
                                                    <div @click="setIsSelected(formulario.idformulario)" class="select-info">
                                                        <div :class="['box-select-info mr-2 ',{'selected-info': formulario.isSelect.isSelected}]"></div>
                                                        <span class="span-label-selected" v-if="formulario.isSelect.isSelected">Selecionado</span>
                                                        <span class="span-label-selected" v-else>Selecionar</span>
                                                    </div>
                                                    <!-- <div v-else class="select-info-blocked"> -->
                                                    <!--     <span class="span-label-selected" v-if="formulario.isSelect.isSelected">Selecionado</span> -->
                                                    <!-- </div> -->
                                                </div>
                                            </div>
                                            <div class="d-flex gap-2 flex-wrap action-buttons">
                                                <Button 
                                                    data-bs-toggle="tooltip" 
                                                    data-bs-placement="top" 
                                                    title="Copiar link"
                                                    v-if="campActive && userPerm.idPermissao != '1'"
                                                    @click="getLink(formulario.idformularioamostra)"
                                                    class="btn greenGradient"
                                                    section="instituicao-campo"
                                                    value=""
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    iconName="copy" 
                                                />
                                                <Button
                                                    data-bs-toggle="tooltip" 
                                                    data-bs-placement="top" 
                                                    title="Ver informações"
                                                    class="btn greenGradient"
                                                    section="instituicao-campo"
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    iconName="info"
                                                    value=""
                                                    @click="selectItems(formulario)"
                                                    data-toggle="modal"
                                                    data-target="#questForm"
                                                />
                                                <router-link :to="{ name: 'AddForm', params: { idForm: formulario.idformulario } }">
                                                    <Button
                                                        data-bs-toggle="tooltip" 
                                                        data-bs-placement="top" 
                                                        title="Editar formulário"
                                                        v-if="!formulario.isEditionLocked && campoStatus != 'OFF'"
                                                        class="btn greenGradient"
                                                        section="instituicao-campo"
                                                        value=""
                                                        :isOutline="true"
                                                        :hasIcon="true"
                                                        iconName="pen"
                                                        style="height: min-content;"
                                                    />
                                                </router-link>

                                                <Button
                                                    data-bs-toggle="tooltip" 
                                                    data-bs-placement="top" 
                                                    title="Excluir formulário"
                                                    v-if="!formulario.isDeletionLocked && campoStatus != 'OFF'"
                                                    @click="deleteForm(formulario)"
                                                    class="btn greenGradient"
                                                    section="instituicao-campo"
                                                    value=""
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    iconName="trash"
                                                    style="height: min-content;"
                                                />
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style-box-lista" key="transition-group2">Nenhum formulário encontrado.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>
        </div>
    </div>

</template>

<script>
import Button from '@components/Button'
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import Amostra from '@middleware/amostra.js'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import ModalCentral from '@components/ModalCentral';
import parsers from '@middleware/parsers';

import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
    name: 'CampoListaFormularios',
    data (){
        return {
            nomeCampo: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            searchByCode: '',
            searchWithOrder: '',
            isLoadedInfo: false,
            teste: [],
            formularios_amostra: [],
            formularios: [],
            showItems: [],
            modalTitle: '',
            options: [
                {
                    id:1,
                    value: 'Todos'
                },
                {
                    id:2,
                    value: 'Nome'
                },
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Campos',
                    iconName: 'th-large',
                    name_route: 'ListarCampos'
                },
                {
                    id:4,
                    value: 'Gerenciar Campo',
                    iconName: 'chart-pie',
                    name_route: 'InstituicaoMenuCampo'
                },
                {
                    id:5,
                    value: 'Formulários',
                    iconName: 'file-alt',
                    name_route: ''
                }
            ]
        }
    },
    watch: {
        rawListFormularios() { this.setData() },
        rawListCampoFormularios() { this.setData() },
    },
    computed: {
        campoStatus() {
            return this.$store.getters.getAmostraStatusAtiva
        },
        userPerm() {
            return this.$store.getters.getUserPerm
        },
        totalFormularios() {
            return this?.formularios?.length || 0;
        },
        formularios_filtered() {
            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = this?.formularios || [];
            if (this.searchByName.trim() != '') {
                var filtered = this?.formularios.filter(formulario => formulario.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

                if(filtered?.length != 0){
                    query = filtered
                }

                else{
                    query = false
                }
            }
            return query;

        },
        rawListFormularios() { return this.$store.getters.getListFormularios },
        rawListCampoFormularios() { return this.$store.getters.getListCampoFormularios },
        campActive() {
            return this
                .$store
                .getters
                .getListCampos
            ?.find(camp => camp.idamostra == this.$route.params.idCampo)
            ?.isativo == 'ON';
        },
    },
    async created() {
        this.isLoadedInfo = false

        this.campos = this.$store.getters.getListCampos

        this.loadData()

        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }

        await this.$store.dispatch('setUserPerm', payload)
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

        const all_campos = this.$store.getters.getListCampos
        const campo = all_campos.filter((value) => {
            return value.idamostra == this.$route.params.idCampo
        }).map((value) => {return value.isativo})

        const campoStatus = campo[0]

        this.$store.commit('setAmostraStatusAtiva', campoStatus)
    },
    components: {
        Button,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
        MemberQnts,
        ModalCentral,
    },
    methods: {
        dateForm(date) { return date ? parsers.convertUTC(date) : null; },
        async selectItems(form) {
            this.modalTitle = `Carregando...`;
            this.showItems = [];
            await this.$store.dispatch('setFormItems', {
                idInstituicao: this.$route.params.idInstituicao,
                idFormulario: form?.idformulario
            });
            this.modalTitle = `Questões do formulário: '${form?.nome}'`;
            this.showItems = [ ...(form?.itens || []) ];
        },
        async deleteForm(formulario) {
            const result = await Swal.fire({
                title: "Tem certeza que deseja excluir esse Formulário?",
                text: "Essa ação não poderá ser revertida.",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim",
            });

          if (!result.isConfirmed) return;

          await this.$store.dispatch("deleteFormulario", {
            idInstituicao: this.$route.params.idInstituicao,
            idFormulario: formulario?.idformulario
          });

          if (this.$store.getters.getFormDeleted){
            notyf.open({
              type: "success",
              message: "Formulário excluído com sucesso",
            });
            window.location.reload();
        }else
            notyf.open({
              type: "error",
              message: "Não foi possível excluir o formulário!",
            });
        },
        async getLink(idForm) {
            const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idAmostra: this.$route.params.idCampo,
                idFormularioAmostra: idForm,
            };
            await this.$store.dispatch("setLinkTransicao", payload);

            if (this.$store.getters.getLinkTransicao) {
                if (window.isSecureContext) {
                    navigator.clipboard.writeText(`${window.location.host}/transicao/${this.$store.getters.getLinkTransicao}`);
                    notyf.open({
                        type: "success",
                        message: "Link copiado com sucesso",
                    });
                } else {
                    console.log(`${window.location.host}/transicao/${this.$store.getters.getLinkTransicao}`);
                    notyf.open({
                        type: "success",
                        message: "Link exibido no console",
                    });
                }
            } else {
                if(payload.idFormularioAmostra == undefined){
                    notyf.open({
                    type: "error",
                    message: "O formulário não foi adicionado ao campo.",
                    });
                }
                else{
                    notyf.open({
                    type: "error",
                    message: "Erro ao obter link!",
                    });
                }

            }
        },
        setIsSelected(id) {
            this.formularios = Amostra.setIsSelectedItem(this?.formularios, 'formulario', id)
        },
        setSelectAllItems() {
            this.formularios = Amostra.setSelectAllItems(this?.formularios, 'selectFormularios')
 
            Amostra.setAllSelectedItems(this?.formularios, 'formulario')
        },
        setUnselectAllItems() {
            this.formularios = Amostra.setUnselectAllItems(this?.formularios, 'selectFormularios')

            Amostra.setAllSelectedItems(this?.formularios, 'formulario')
        },
        setData() {
            this.formularios = this.rawListFormularios || []
            this.formularios_amostra = this.rawListCampoFormularios || []

            this.formularios.forEach(formulario => {
                // formulario.isLocked = false
                // this.formularios_amostra.forEach(formulario_amostra => {
                //     if (formulario_amostra.idformulario == formulario.idformulario) {
                //         formulario.isLocked = formulario_amostra.isLocked
                //     }
                // })
                const found = this?.formularios_amostra.find(formulario_amostra => formulario_amostra.idformulario == formulario.idformulario);
                formulario.isSelect = {
                    isSelected: found ? true : false,
                    isSaved: true
                }
                formulario.idformularioamostra = found?.idformularioamostra;
            });

            this.isLoadedInfo = true
        },
        async loadData() {
            const payload = {
             idInstituicao: this.$route.params.idInstituicao,
             idAmostra: this.$route.params.idCampo
            }
            await this.$store.dispatch('setListCampoFormularios', payload)
            await this.$store.dispatch('setListFormularios', {
                idInstituicao: this.$route.params.idInstituicao
            })
        },
        async applyChanges() {
            if (this.$store.getters.getAmostraStatusAtiva == 'ON') {
                const result = await Swal.fire({
                    title: "Tem certeza que deseja aplicar as alterações?",
                    text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Sim, tenho certeza",
                })
                
                if (result.isConfirmed) {
                    Amostra.setAllSelectedItems(this?.formularios, 'formulario')
                    const payloadManageFormularios = this.$store.getters.getPayloadManageFormularios
                    Amostra.setAllUnselectedItems(this?.formularios, 'formulario')
                    const payloadUnselectedFormularios = this.$store.getters.getPayloadUnselectedFormularios;
                    const payload = {
                        idInstituicao: this.$route.params.idInstituicao,
                        idAmostra: this.$route.params.idCampo,
                        ids: JSON.parse(JSON.stringify(payloadManageFormularios)),
                        addIds: JSON.parse(JSON.stringify(payloadManageFormularios)),
                        removeIds: JSON.parse(JSON.stringify(payloadUnselectedFormularios)),
                    }
                    await this.$store.dispatch('setManageFormularios', payload)
                    
                    this.loadData()
                    Swal.fire(
                        'Alterações aplicadas!',
                        'As seleções foram aplicadas com sucesso.',
                        'success'
                    )
                }
   
            } else {
                Amostra.setAllSelectedItems(this?.formularios, 'formulario')
                const payloadManageFormularios = this.$store.getters.getPayloadManageFormularios
                Amostra.setAllUnselectedItems(this?.formularios, 'formulario')
                const payloadUnselectedFormularios = this.$store.getters.getPayloadUnselectedFormularios;
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idAmostra: this.$route.params.idCampo,
                    ids: JSON.parse(JSON.stringify(payloadManageFormularios)),
                        addIds: JSON.parse(JSON.stringify(payloadManageFormularios)),
                        removeIds: JSON.parse(JSON.stringify(payloadUnselectedFormularios)),
                }
                await this.$store.dispatch('setManageFormularios', payload)
                
                this.loadData()
                Swal.fire(
                    'Alterações aplicadas!',
                    'As seleções foram aplicadas com sucesso.',
                    'success'
                )
            }
        },
        setSelectedValue: function() {
            this.selectedValue = this.$refs.filtersearchname.value
        }
        
    },
    async mounted() {
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao);

        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idCampo

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome do Campo"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.campos.filter(team => 
                team.idamostra == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeCampo = teamSelected.titulo
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>
 :deep(.box-lista span) {
     font-size: 1.1em;
 }
.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: flex-end;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
    }
}

.action-buttons button{
    padding: 0.5rem;
}

@media screen and (max-width: 768px) {
    table > thead > tr > th{
        display: block;
    }

    tr{
        display: block;
        border: 1px solid #00853B;
        margin: 1.25rem 0;
        text-align: center;
        border-radius: 6px;
    }

    tr > td{
        display: block;
        width: 100%;
        border-top: 1px solid rgba(128, 128, 128, 0.301)!important;
        border-bottom: none;
    }
}

.not-clickable{
    cursor: not-allowed;
}

.not-clickable > div{
    pointer-events: none;
}
</style>