<template>
    <form @submit.prevent>
    <div class="container offset">
        <div class="custom-form">
        
			<div class="form-group">
				<label class="question-style">Novo Título do Video:</label>
				<input
					v-model.trim="newVideoTitle"
					type="name"
					class="form-control"
					placeholder="Insira aqui o novo título do Video... (opcional)"
				/>
				<br />
				<label class="question-style">Novo Link do Video:</label>
				<input
					v-model.trim="newVideoLink"
					type="name"
					class="form-control"
					placeholder="Insira aqui o novo link do Video... (opcional)"
				/>
			</div>
        </div> 
        <div class="button-div pt-3">
            <Button 
                @click="editVideo()"
                section="instituicao-campo"
                value="Editar Vídeo"
                :isOutline="true"
                :hasIcon="true"
                iconName="edit"
            /> 
        </div>
    </div>
    </form>
   
</template>

<script>

import Button from '@components/Button'
import getVideoId from 'get-video-id'
// import Swal from 'sweetalert2';
// import { Notyf } from "notyf";
// const notyf = new Notyf({
//     duration: 5000,
//     position: {
//         x: "left",
//         y: "bottom",
//     }
// });

export default {
    name: "EditVideo",
    data() {
        return {
            newVideoTitle: "",
			newVideoLink: "",
        }
    },
	props: ['data'],
    components: {
        Button
    },
    methods: {
		async editVideo() {
			const titulo = this.newVideoTitle !== '' ? this.newVideoTitle : null
			const payload = {
				idModal: this.data.idModal,
				titulo,
				link: getVideoId(this.newVideoLink).id,
			}

			await this.$store.dispatch('updateModal', payload)
			await this.$store.dispatch('setListModais', {
				categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
			})

			this.clearForm()
			this.$emit('closeModal')
		},

		clearForm() {
			this.newVideoTitle = ''
			this.newVideoLink = ''
		},
	},
}

</script>

<style scoped>

    .container{
        padding: 1rem;
        width: 100%;
        background-color: white;
        margin: 0;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;
    }

    .word-style{
        color:  #656565;
    }

    .custom-form{
        margin: 0 auto;
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .button-div{
        text-align: right;
    }

</style>
