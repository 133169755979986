<template>
    <div class="container offset">
        <form @submit.prevent="updateTurma" ref="formClass">
            <div class="custom-form">
                <div class="form-group">
                    <label class="question-style pt-1">Nome da turma: *</label>
                    <input
                        v-model.trim="classData.nome"
                        type="name"
                        class="form-control"
                        placeholder="Insira aqui o nome da turma..."
                        required
                    />
                </div>
                <div class="form-group">
                    <label class="question-style"
                        >Código/Número da turma:</label
                    >
                    <input
                        v-model.trim="classData.codigoTurma"
                        type="name"
                        class="form-control"
                        placeholder="Insira aqui o código da turma..."
                    />
                </div>

                <div class="form-group">
                    <label class="question-style">Período da turma: *</label>
                    <select
                        v-model.trim="classData.tipoPeriodo"
                        class="form-control"
                        required
                    >
                        <option hidden class="question-style">
                            Selecione aqui o período
                        </option>
                        <option class="question-style" value="19">
                            Matutino
                        </option>
                        <option class="question-style" value="20">
                            Vespertino
                        </option>
                        <option class="question-style" value="21">
                            Noturno
                        </option>
                        <option class="question-style" value="22">
                            Integral
                        </option>
                        <option class="question-style" value="23">
                            Intermediário
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="question-style pt-1">Tipo da turma: *</label>
                    <select
                        v-model.trim="classData.tipoTurma"
                        class="form-control"
                        required
                    >
                        <option hidden class="question-style">
                            Selecione aqui o tipo da turma
                        </option>
                        <option class="question-style" value="25">
                            Regular
                        </option>
                        <option class="question-style" value="26">
                            Multisseriada/Mista
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="question-style pt-1">Série da turma: *</label>
                    <input
                        v-model.trim="classData.tipoIdade"
                        type="name"
                        class="form-control"
                        placeholder="Insira a série ou ano da turma..."
                        required
                    />
                </div>
            </div>

            <div class="formButtons">
                <!-- <div class="button-div pt-4">
                     <Button
                     value="Limpar"
                     section="descartar"
                     :isOutline="true"
                     :hasIcon="true"
                     iconName="trash"
                     @click="clearForm" />
                     </div> -->
                <div class="button-div pt-4">
                    <Button
                        section="instituicao-cadastrar"
                        value="Atualizar turma"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="save"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Button from "@components/Button";
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
    name: "UpdateTurma",
    data() {
        return {
            classData: {
                nome: "",
                codigoTurma: "",
                tipoPeriodo: "",
                tipoIdade: "",
                tipoTurma: "",
            },
        };
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    watch: {
        data() {
            this.loadInfo();
        },
        ues() {
            this.loadInfo();
        },
    },
    computed: {
        turmas() {
            return this.$store.getters.getListTurma || [];
        },
    },
    methods: {
        async loadInfo() {
            const found =
                this.turmas.find(
                    (turma) => turma.idturma === this.data.idturma
                ) || {};
            this.classData = {
                ...found,
                codigoTurma: found?.codigoturma,
                tipoPeriodo: found?.periodo,
                tipoIdade: found?.idade,
                tipoTurma: found?.tipo_turma,
            };

            switch (found?.periodo) {
                case "MATUTINO":
                    this.classData.tipoPeriodo = 19;
                    break;
                case "VESPERTINO":
                    this.classData.tipoPeriodo = 20;
                    break;
                case "NOTURNO":
                    this.classData.tipoPeriodo = 21;
                    break;
                case "INTEGRAL":
                    this.classData.tipoPeriodo = 22;
                    break;
                case "INTERMEDIARIO":
                    this.classData.tipoPeriodo = 23;
                    break;
                default:
                    break;
            }
        },
        async updateTurma() {
            const foundTurma =
                this.turmas.find(
                    (turma) => turma.idturma === this.data.idturma
                ) || {};
            let turmaAlreadyExists = this.turmas.some((item) => {
                let newP;
                if (item.periodo == "MATUTINO") {
                    newP = 19;
                } else if (item.periodo == "VESPERTINO") {
                    newP = 20;
                } else if (item.periodo == "NOTURNO") {
                    newP = 21;
                } else if (item.periodo == "INTEGRAL") {
                    newP = 22;
                } else if (item.periodo == "INTERMEDIARIO") {
                    newP = 23;
                }

                return (
                    item.nome.toLowerCase() ===
                        this.classData.nome.toLowerCase() &&
                    item.codigoturma === this.classData.codigoTurma &&
                    newP == this.classData.tipoPeriodo &&
                    item.tipo_turma == this.classData.tipoTurma &&
                    item.idade == this.classData.tipoIdade &&
                    (item.nome.toLowerCase() != foundTurma.nome.toLowerCase() ||
                        item.codigoturma != foundTurma.codigoturma ||
                        item.periodo != foundTurma.periodo ||
                        item.tipo_turma != foundTurma.tipo_turma ||
                        item.idade != foundTurma.idade)
                );
            });

            if (this.data.length == 0 || !turmaAlreadyExists) {
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUnidade: this.$route.params.idUnidade,
                    infoClass: this.classData,
                };
                await this.$store.dispatch("updateTurma", payload);

                if (this.$store.getters.getIsTurmaUpdated) {
                    notyf.open({
                        type: "success",
                        message: "Turma atualizada com sucesso!",
                    });
                    this.$store.dispatch("setListTurmas", {
                        idInstituicao: this.$route.params.idInstituicao,
                        idUnidade: this.$route.params.idUnidade,
                    });
                    // this.clearForm();
                    this.$emit("closeModal");
                } else {
                    notyf.open({
                        type: "error",
                        message: "Erro na atualização da turma!",
                    });
                }
            } else {
                //se as informações já existirem em outra turma
                notyf.open({
                    type: "error",
                    message:
                        "Já existe uma turma criada com estas informações, verifique os campos e tente novamente.",
                });
            }
        },
        clearForm() {
            this.loadInfo();
        },
    },
    components: {
        Button,
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    background-color: white;
}

.title-style {
    font-size: 1.5rem;
    font-weight: 600;
    color: #656565;
}

.question-style {
    width: 100%;
    font-size: 1rem;
    color: #656565;
}

.image-style {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding: 1rem;
}

.button-div {
    text-align: center;
}

.formMsg {
    margin-top: 5px;
}
.formMsgSuccess {
    color: green;
}
.formMsgError {
    display: none;
}
.formButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-content: space-around;
}
</style>
