<template>
  <div>
    <div id="carouselControls2" class="carousel slide carousel-fade finish-registration-view" data-ride="carousel" data-interval="false">
      <div class="carousel-inner">

        <!-- 1 FORMULARIO -->
        <div class="carousel-item active">
          
          <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
            <div class="crumb crumb-active mr-2"></div>
            <div class="crumb mr-2"></div>
            <div class="crumb"></div>
          </div>


          <form class="formulario" @submit.prevent="" autocomplete="off">
            <div class="form-group">
              <label for="nome">Nome</label>
              <input v-model.trim="nome" type="text" class="form-control" id="nome" placeholder="Insira seu nome..." required/>
            </div>
    
            <div class="form-group">
              <label for="email">Email</label>
              <input @input="verifyValidadeEmail()" v-model.trim="email" type="email" class="form-control" id="email" placeholder="Insira seu email..." required/>
              <span v-if="(isValidEmail !== 'true') && (validateEmail(email))" class="text-alert-match-passwords"><b>O email está indisponível.</b></span>
              <span v-else-if="(isValidEmail === 'true') && (validateEmail(email))" class="text-alert-valid-email"><b>O email está disponível.</b></span>
            </div>

            <div class="form-group">
              <label for="dtnasc">Data de Nascimento</label>
              <input v-model="dtnasc" type="date" class="form-control" id="dtnasc" placeholder="Insira sua data de nascimento..." required/>
            </div>

            <label>Gênero</label>
            <div class="form-inline">
              <div class="form-check mr-3">
                <input v-model="genero" class="form-check-input" type="radio" name="genero" id="masculino" value="MASCULINO" required>
                <label class="form-check-label">Masculino</label>    
              </div>

              <div class="form-check mr-3">
                <input v-model="genero" class="form-check-input" type="radio" name="genero" id="feminino" value="FEMININO">
                <label class="form-check-label">Feminino</label>
              </div>

              <div class="form-check">
                <input v-model="genero" class="form-check-input" type="radio" name="genero" id="outro" value="OUTRO">
                <label class="form-check-label">Outro</label>
              </div>
            </div>

            <div class="d-flex justify-content-center">
              <Button :isDisabled="!form1verified" href="#carouselControls2" data-slide="next" class="" section="main" value="Próximo" />
            </div>
          </form>

        </div>

        <!-- 2 FORMULARIO -->
        <div class="carousel-item">
          <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
            <div class="crumb mr-2"></div>
            <div class="crumb crumb-active mr-2"></div>
            <div class="crumb"></div>
          </div>

          <form class="formulario" @submit.prevent="" autocomplete="off">
            <div class="form-group">
              <label for="profissao">Profissão</label>
              <input v-model.trim="profissao" type="text" class="form-control" id="profissao" placeholder="Insira sua profissão..." required/>
            </div>

            <div class="form-group">
              <label for="organizacao">Organização</label>
              <input v-model.trim="organizacao" type="text" class="form-control" id="organizacao" placeholder="Insira a organização que você participa..." required/>
            </div>

            <div class="form-group">
              <label for="atuacao">Atuação</label>
              <input v-model.trim="atuacao" type="text" class="form-control" id="atuacao" placeholder="Insira sua área de atuação..." required/>
            </div>

            <div class="d-flex justify-content-start align-items-center">
            <div class="mr-4">
                <label for="estado">Estado</label>
                <select @change="getCities()" v-model="estado" class="form-control" id="estado" required>
                  <option value="AC">Acre</option>
                  <option value="AL">Alagoas</option>
                  <option value="AP">Amapá</option>
                  <option value="AM">Amazonas</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PR">Paraná</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
            </div>

            <div>
                <label class="text-align-left" for="cidade">Cidade</label>
                <select v-model="cidade" class="form-control" id="cidade" required>
                  <option v-for="city in cidades" :key="city.idCidade" :value="city.nome">{{city.nome}}</option>
                </select>
            </div>
            </div>

            <div class="d-flex flex-row-reverse justify-content-between mt-3">
              <Button :isDisabled="!form2verified" href="#carouselControls2" data-slide="next"  section="main" value="Próximo" />
              <Button href="#carouselControls2" data-slide="prev"  section="main" value="Voltar" />
            </div>
          </form>


        </div>



         <!-- 3 FORMULARIO -->
        <div class="carousel-item">
           <div class="d-flex justify-content-center align-items-center mt-1 mb-2">
            <div class="crumb mr-2"></div>
            <div class="crumb mr-2"></div>
            <div class="crumb crumb-active"></div>
          </div>

          <form class="formulario" @submit.prevent="" autocomplete="off">
            
            <div class="form-group">
              <label for="senha">Senha</label>
              <input v-model="senha" type="password" class="form-control" id="senha" placeholder="Insira uma senha..." required/>
            </div>

            <div class="form-group">
              <label for="senha-repeat">Repita a senha</label>
              <input v-model="senha_repeat" type="password" class="form-control" id="senha-repeat" placeholder="Repita a senha..." required/>
            </div>

            <span v-if="!senhasIguais" class="text-alert-match-passwords"><b>As senhas precisam ser iguais.</b></span>
            <span v-if="!senhaVerified" class="text-alert-match-passwords">
              <ul>A senha precisa ter: 
                <li :class="{'green-text': senhaVerifiedMinusculo}">Pelo menos um caractere minúsculo</li>
                <li :class="{'green-text': senhaVerifiedMaiusculo}">Pelo menos um caractere maiúsculo</li>
                <li :class="{'green-text': senhaVerifiedEspecial}">Pelo menos um caractere especial</li>
                <li :class="{'green-text': senhaVerifiedNumero}">Pelo menos um caractere numérico</li>
                <li :class="{'green-text': senhaVerifiedTamanho}">Pelo menos oito caracteres</li>
              </ul>
            </span>

            <div class="d-flex flex-row-reverse justify-content-between mt-3">
              <Button @click="setSignUp()" :isDisabled="!form3verified" section="main-confirm" value="Finalizar" />
              <Button href="#carouselControls2" data-slide="prev" section="main" value="Voltar" />
            </div>
          </form>

        </div>


        <!-- CONFIRMACAO FORM -->
        <div class="carousel-item">
          <div class="formulario d-flex flex-column justify-content-center align-items-center">
            <img class="img-success mb-3" src="../assets/img/check.png">
            <h1 class="success-msg-h1">Parabéns, sua conta foi criada!</h1>
            <span class="success-msg-span text-justify">Um link para validação da conta foi enviado para seu email. Para poder acessar a plataforma, é <b>obrigatório a validação da conta.</b></span>
            <span @click="resendVerificationMail" class="link-resend-email">Não recebeu o email? Clique aqui para reenviar.</span>
            <span class="feedback-msg-verification-mail" v-if="isResendVerificationMail">O email foi enviado novamente!</span>
            <span class="feedback-msg-verification-mail" v-else-if="isResendVerificationMail == false">Aguarde um pouco para reenviar :)</span>
            <Button @click="newSignUp" class="mt-4" section="main-confirm" value="Novo Cadastro" />
          </div>
        </div>

        <!-- ERRO FORM -->
        <div class="carousel-item">
          <div class="formulario d-flex flex-column justify-content-center align-items-center">
            <img class="img-success mb-3" src="../assets/img/error.png">
            <h1 class="success-msg-h1">Oops, algo deu errado!</h1>
            <span class="success-msg-span text-justify">Por favor, tente novamente.</span>
            <Button @click="newSignUp" class="mt-4" section="main" value="Novo Cadastro" />
          </div>
        </div>

      </div>
      <a ref="inicioForm" class="d-none" data-target="#carouselControls2" data-slide-to="0" href="#carouselControls2"></a>
      <a ref="successForm" class="d-none" data-target="#carouselControls2" data-slide-to="3" href="#carouselControls2"></a>
      <a ref="errorForm" class="d-none" data-target="#carouselControls2" data-slide-to="4" href="#carouselControls2"></a>
    </div>
  </div>
</template>

<script>
import Button from "@components/Button";

export default {
  name: "SignUp",
  data() {
    return {
      nome: "",
      email: "",
      genero: "",
      dtnasc: "",
      profissao: "",
      organizacao: "",
      atuacao: "",
      estado: "",
      cidade: "",
      senha: "",
      senha_repeat: ""
    };
  },
  components: {
    Button,
  },
  computed: {
    form1verified() {
      if ((this.nome != '') && (this.validateEmail(this.email)) &&
       (this.genero != '') &&  (this.dtnasc != '') && (this.isValidEmail === "true")){
         return true
      }
      else {
        return false
      }
    },
    form2verified() {
      if ((this.profissao != '') && (this.organizacao) &&
       (this.atuacao != '') && (this.estado != '') && (this.cidade != '')){
         return true
      }
      else {
        return false
      }
    },
    form3verified() {
      if ((this.senha != '') && (this.senha_repeat != '')){
        if(this.senha == this.senha_repeat){
          if (this.validateSenha(this.senha)) {
            return true
          }
          else {
            return false
          }
        }
        else {
          return false
        }
      }
      else {
        return false
      }
    },

    senhaVerified(){
      if(this.validateSenha(this.senha)){
        return true
      }
      else {
        return false
      }
    },

    senhasIguais(){
      if(this.senha == this.senha_repeat){
          return true
        }
        else {
          return false
        }
    },

    isValidEmail() {
      return this.$store.getters.getIsValidEmail
    },

    isResendVerificationMail() {
      return this.$store.getters.getResendVerificationMail
    },

    cidades(){
      if (this.estado != '') {
        return this.$store.getters.getCidades;
      }
      else {
        return []
      }
    },

    //VALIDADORES INDIVIDUAIS
    senhaVerifiedMinusculo() {
      var re = new RegExp("^(?=.*[a-z])");
      return re.test(this.senha);
    },

    senhaVerifiedMaiusculo() {
      var re = new RegExp("^(?=.*[A-Z])");
      return re.test(this.senha);
    },

    senhaVerifiedNumero() {
      var re = new RegExp("^(?=.*[0-9])");
      return re.test(this.senha);
    },

    senhaVerifiedEspecial() {
      var re = new RegExp("^(?=.*[!@#$&*_?<>/%-])");
      return re.test(this.senha);
    },

    senhaVerifiedTamanho() {
      var re = new RegExp("^(?=.{8,})");
      return re.test(this.senha);
    }

  },
  methods: {
    newSignUp() {
      this.nome = ""
      this.email = ""
      this.genero = ""
      this.dtnasc = ""
      this.profissao = ""
      this.organizacao = ""
      this.atuacao = ""
      this.estado = ""
      this.cidade = ""
      this.senha = ""
      this.senha_repeat = ""
      this.$refs.inicioForm.click()
    },

    async getCities() {
      await this.$store.dispatch('setCidades', this.estado);
      this.cidade = ''
    },
    validateEmail(email) {
      var re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateSenha(senha) {
      var re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$&*_?<>/%-])(?=.{8,})");
      return re.test(senha);
    },

    async resendVerificationMail() {
      await this.$store.dispatch('setResendVerificationMail', this.email)
    },

    async verifyValidadeEmail() {
      if (this.validateEmail(this.email)){
        await this.$store.dispatch('setIsValidEmail', this.email)
      }
    },

    async setSignUp(){
      const payload = {
        nome: this.nome,
        email: this.email,
        genero: this.genero,
        dtnasc: this.dtnasc,
        profissao: this.profissao,
        organizacao: this.organizacao,
        atuacao: this.atuacao,
        cidade: this.cidade,
        estado: this.estado,
        senha: this.senha
      }
      await this.$store.dispatch('setSignUp', payload)
      let verificaRegistro = await this.$store.getters.getErrorRegisterNewUser
      if (!verificaRegistro) {
        this.$store.commit('setResendVerificationMail', null)
        this.$refs.successForm.click()
      }
      else {
        this.$refs.errorForm.click()
      }
    }
  },
};
</script>

<style scoped>
.finish-registration-view{
    padding: 6rem 10rem;
}

.crumb {
  background-color: rgb(204, 203, 203);
  height: 0.5rem;
  width: 2rem;
  border-radius: 0.5rem;
}

.green-text {
  color: rgb(104, 158, 60);
}

.crumb-active {
  background-color: rgb(137, 197, 87);
  box-shadow: 1px 1px 5px 0px rgb(137 197 87)
}

.formulario {
  padding: 0rem 2rem 2rem 2rem;
}

.btn-full {
  margin-top: 0.8rem;
  width: 100%;
}

.img-success {
  width: 9rem;
  height: 9rem;
}

.success-msg-h1 {
  font-size: 1.4rem;
}

.success-msg-span {
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgb(90, 88, 88);
}

.text-alert-match-passwords {
  color: rgb(224, 53, 53);
  font-weight: 100;
  font-size: 0.9rem;
}

.text-alert-valid-email {
  color: rgb(104, 158, 60);
  font-weight: 100;
  font-size: 0.9rem;
}

.link-resend-email {
  margin-top: 1rem;
  color: #00853b;
  text-decoration: underline;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
}

.feedback-msg-verification-mail {
  font-size: 0.8rem;
}
</style>
