<template>
    <div class="container offset">
        <div class="custom-form">

            <p>Veja aqui todos os convites recebidos. Você pode aceitá-los ou recusá-los.</p>
            <input 
                type="name"
                class="form-control input-personal"
                placeholder="Digite o nome do remetente..."
                v-model="searchByName"
                id="inlineFormCustomName"
                autocomplete="off"
            />
            
            <div class="typeTitle">
                Tipos de solicitação:
            </div>
            <div class="filters">
                <select
                    v-model="typeSearch"
                    class="form-control input-personal">
                    <option value="">Todos</option>
                    <option value="GRUPO">Grupo</option>
                    <option value="INSTITUICAO">Instituição</option>
                </select>
            </div>
            
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <div class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home-user" role="tab" aria-controls="nav-home" aria-selected="true">Pendentes</div>
                    <div class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile-user" role="tab" aria-controls="nav-profile" aria-selected="false">Aceitos</div>
                    <div class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact-user" role="tab" aria-controls="nav-contact" aria-selected="false">Recusados</div>
                    <div class="nav-item nav-link" id="nav-sent-tab" data-toggle="tab" href="#nav-sent-user" role="tab" aria-controls="nav-sent" aria-selected="false">Enviados</div>
                </div>
            </nav>

            <!------------ ABAS - GERAL ------------------------------------------------------>
            <div class="tab-content" id="nav-tabContent">
                <!----------------- ABA CONVITES PENDENTES/NULL ---------------------------->
                <div class="tab-pane fade show active" id="nav-home-user" role="tabpanel" aria-labelledby="nav-home-tab">
                    <transition-group tag="div" v-if='filterState("PENDENTE").length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in filterState('PENDENTE')" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i
                                        class="img-style ml-1 mr-2"
                                        :class="{
                                            'fas fa-users': info.tipoSolicitacao.nome == 'GRUPO_USUARIO',
                                            'fas fa-university': info.tipoSolicitacao.nome == 'INSTITUICAO_USUARIO',
                                        }"
                                    >
                                    </i>
                                    <label class="text-style"> {{  info.tipoSolicitacao.nome == 'GRUPO_USUARIO' ? info.idGrupo?.nome : info.idInstituicao?.nome }} </label>
                                </div>
                                <div class="inviteType font-weight-semibold">
                                    {{ info.tipoSolicitacao.nome == 'GRUPO_USUARIO' ? 'GRUPO' : 'INSTITUICAO' }}
                                </div>
                                <div class="buttons">
                                    <Button
                                        @click="setStatusUserRequest(info.idNotificacao, 'RECUSADO')"
                                        section="descartar"
                                        value="Recusar"
                                        :isOutline="true"
                                        :hasIcon="false"
                                        class="button-style"
                                    />
                                    <Button
                                        @click="setStatusUserRequest(info.idNotificacao, 'ACEITO')"
                                        section="salvar"
                                        value="Aceitar"
                                        :isOutline="true"
                                        :hasIcon="false"
                                        class="button-style ml-2"
                                    />
                                </div>
                            </div>
                        </div>
                    </transition-group>
                    <transition tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition>             
                </div>

                <!----------------- ABA CONVITES ACEITOS/TRUE ------------------------------------->
                <div class="tab-pane fade" id="nav-profile-user" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <transition-group tag="div" v-if='filterState("ACEITO").length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in filterState('ACEITO')" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i
                                        class="img-style ml-1 mr-2"
                                        :class="{
                                            'fas fa-users': info.tipoSolicitacao.nome == 'GRUPO_USUARIO',
                                            'fas fa-university': info.tipoSolicitacao.nome == 'INSTITUICAO_USUARIO',
                                        }"
                                    >
                                    </i>
                                    <label class="text-style"> {{  info.tipoSolicitacao.nome == 'GRUPO_USUARIO' ? info.idGrupo?.nome : info.idInstituicao?.nome }} </label>
                                </div>
                                <div class="inviteType">
                                    {{ info.tipoSolicitacao.nome == 'GRUPO_USUARIO' ? 'GRUPO' : 'INSTITUICAO' }}
                                </div>
                                <label class="text-style aceito-text">ACEITO</label>
                            </div>
                        </div>
                    </transition-group>
                    <transition tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition>
                </div>
                
                <!----------------- ABA CONVITES RECUSADOS/FALSE ------------------------------------->
                <div class="tab-pane fade" id="nav-contact-user" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <transition-group tag="div" v-if='filterState("RECUSADO").length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in filterState('RECUSADO')" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i
                                        class="img-style ml-1 mr-2"
                                        :class="{
                                            'fas fa-users': info.tipoSolicitacao.nome == 'GRUPO_USUARIO',
                                            'fas fa-university': info.tipoSolicitacao.nome == 'INSTITUICAO_USUARIO',
                                        }"
                                    >
                                    </i>
                                    <label class="text-style"> {{  info.tipoSolicitacao.nome == 'GRUPO_USUARIO' ? info.idGrupo?.nome : info.idInstituicao?.nome }} </label>
                                </div>
                                <div class="inviteType">
                                    {{ info.tipoSolicitacao.nome == 'GRUPO_USUARIO' ? 'GRUPO' : 'INSTITUICAO' }}
                                </div>
                                <label class="text-style recusado-text">RECUSADO</label>
                            </div>
                        </div>
                    </transition-group>
                    <transition tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition>
                </div>

                <!----------------- ABA CONVITES ENVIADOS ------------------------------------->
                <div class="tab-pane fade" id="nav-sent-user" role="tabpanel" aria-labelledby="nav-sent-tab">
                    <transition-group tag="div" v-if='sentSolicitacoes.length' class="listagem-solicitacao" name="listanimate">
                        <div v-for="(info, idx) in sentSolicitacoes" :key="idx">
                            <div class="institution-row-style">
                                <div class="infos-text">
                                    <i
                                        class="img-style ml-1 mr-2"
                                        :class="{
                                            'fas fa-users': info.tipoSolicitacao.nome == 'USUARIO_GRUPO',
                                            'fas fa-university': info.tipoSolicitacao.nome == 'USUARIO_INSTITUICAO',
                                        }"
                                    >
                                    </i>
                                    <label class="text-style"> {{  info.tipoSolicitacao.nome == 'GRUPO_USUARIO' ? info.idGrupo?.nome : info.idInstituicao?.nome }} </label>
                                </div>
                                <div class="inviteType">
                                    {{ info.tipoSolicitacao.nome == 'USUARIO_GRUPO' ? 'GRUPO' : 'INSTITUICAO' }}
                                </div>
                                <label class="text-style recusado-text">{{ info?.aceito }}</label>
                                <div class="buttons" v-if="info?.aceito == 'PENDENTE'">
                                    <Button
                                        @click="cancelNoti(info)"
                                        section="descartar"
                                        value="Cancelar"
                                        :isOutline="true"
                                        :hasIcon="false"
                                        class="button-style"
                                    />
                                </div>
                            </div>
                        </div>
                    </transition-group>
                    <transition tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style noneFound">Sem resultados.</label>
                    </transition>
                </div>
                
            </div>  
            <!------------ FIM DAS ABAS - GERAL ------------------------------------------------------>                
            
        </div>       
    </div>
</template>

<script>

 import Button from '@components/Button'
 import { Notyf } from "notyf";
 import Swal from 'sweetalert2';

 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 })

 export default {
     name: "UserInvites",
     data() {
         return {
             searchByName: '',
             typeSearch: "",
             infosData: []
         }
     },
     components: {
         Button
     },
     async created() {

         // COMENTADO PARA TESTE EM PRODUÇÃO (PARA PARAR DE PUXAR AS NOTIFICAÇÕES SEM QUE O USER ESTEJA LOGADO)
         if (this.$store.getters.getIsLogged)
             this.$store.dispatch('setListUserRequests', {
                 notiTypes: [
                     "INSTITUICAO_USUARIO",
                     "USUARIO_INSTITUICAO",
                 ]
             })
     },
     computed: {
         filterSolicitacoes: function() {
             const mainFilters = [
                 "INSTITUICAO_USUARIO",
                 "GRUPO_USUARIO",
             ];
             //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
             var allRequests = this.$store.getters.getListUserRequests.filter(request => mainFilters.includes(request?.tipoSolicitacao?.nome));

             var query = allRequests
             query = query?.sort((a, b) =>
                 (a?.idInstituicao?.nome || a?.idGrupo?.nome)?.localeCompare(
                     (b?.idInstituicao?.nome || b?.idGrupo?.nome)
                 )
             );

             if (this.searchByName.trim() != '')
                 query = query?.filter(team =>
                     team?.idInstituicao?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()) ||
                     team?.idGrupo?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase())
                 )

             switch (this.typeSearch) {
                 case "":
                     break;
                 case "GRUPO":
                     query = query?.filter(team => team?.idGrupo)
                     break;
                 case "USUARIO":
                     query = query?.filter(team => team?.idUsuario)
                     break;
                 default:
                     break;
             }

             return query;
         },
         sentSolicitacoes() {
             const sentFilters = [
                 "USUARIO_INSTITUICAO",
                 "USUARIO_GRUPO",
             ];
             let allRequests = this.$store.getters.getListUserRequests.filter(request => sentFilters.includes(request?.tipoSolicitacao?.nome));

             var query = allRequests
             query = query?.sort((a, b) =>
                 (a?.idInstituicao?.nome || a?.idGrupo?.nome)?.localeCompare(
                     (b?.idInstituicao?.nome || b?.idGrupo?.nome)
                 )
             );

             if (this.searchByName.trim() != '')
                 query = query?.filter(team =>
                     team?.idGrupo?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()) ||
                     team?.idInstituicao?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase())
                 )

             switch (this.typeSearch) {
                 case "":
                     break;
                 case "GRUPO":
                     query = query?.filter(team => team?.tipoSolicitacao?.nome == 'USUARIO_GRUPO')
                     break;
                 case "INSTITUICAO":
                     query = query?.filter(team => team?.tipoSolicitacao?.nome == 'USUARIO_INSTITUICAO')
                     break;
                 default:
                     break;
             }

             return query;
         },
     },
     methods: {
         async setStatusUserRequest(idSolicitacao, status) {
             // console.log(idSolicitacao)
             const payload = {
                 idSolicitacao: idSolicitacao,
                 isAceito: status,
             }
             await this.$store.dispatch('setStatusUserRequest', payload)

             if (this.$store.getters.getIsRequestUpdated) {
                 this.$store.dispatch('setListUserRequests', {
                     notiTypes: [ "INSTITUICAO_USUARIO" ],
                 })

                 notyf.open({
                     type: 'success',
                     message: 'Solicitação atualizada!'
                 });

                 if(status == 'ACEITO'){
                    await this.$store.dispatch('setListTeams');
                 }
             } else {
                 notyf.open({
                     type: 'error',
                     message: 'Não foi possível atualizar a solicitação'
                 });
             }
         },
         async cancelNoti(info) {
             const swalResult = await Swal.fire({
                 title: 'Cancelar convite?',
                 text: `Tem certeza que deseja cancelar convite a ${info?.idGrupo?.nome || info?.idInstituicao?.nome}?`,
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: '#d33',
                 cancelButtonColor: '#3085d6',
                 cancelButtonText: 'Cancelar',
                 confirmButtonText: 'Sim, tenho certeza'
             })
             if (!swalResult.isConfirmed) return;

             await this.$store.dispatch('deleteUserNoti', {
                 idNoti: info?.idNotificacao,
             })

             if (this.$store.getters.getIsCanceledGroupNoti) {
                 Swal.fire(
                     'Convite cancelado!',
                     `O convite feito a ${info?.idUsuario?.nome} foi cancelado com sucesso`,
                     'success'
                 )
                 this.$store.dispatch('setListUserRequests', {
                    notiTypes: [
                        "INSTITUICAO_USUARIO",
                        "USUARIO_INSTITUICAO",
                    ]
                 })
             } else {
                 Swal.fire(
                     'Erro no cancelamento!',
                     `Não foi possível cancelar o convite`,
                     'error'
                 )
             }
         },
         filterState(state) {
             return this.filterSolicitacoes.filter(info => info.aceito == state);
         },
     },

 }

</script>

<style scoped>

 .container{
     width: 100%;
     background-color: transparent;
 }

 .nav-tabs{
     margin: 1rem 0 0 0;
     cursor: pointer;
 }

 .buttons{
     display: flex;
     justify-content: right;
 }

 .title-style{
     font-size: 1.5rem;
     font-weight: 600;
     color: #656565;
 }

 p{
     width: 100%;
     font-size: 1rem;
     font-weight: 400;
     color: #656565;
 }

 .image-style{
     display: block;
     margin-left: auto;
     margin-right: auto;
     width: 50%;
     padding: 1rem;
 }

 .button-div{
     text-align: right;
 }

 .recusado-text {
     color: #d40707;
     font-weight: bold;
     padding: 0 1rem 0 0;
 }

 .aceito-text {
     font-weight: bold;
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/verdeEapi.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .institution-row-style{
     border-bottom: 1px solid rgba(255, 255, 255, 0.8);
     margin: 0;
     padding: 0.5rem 0rem;
     text-align: center;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .institution-row-style > * {
     flex: 1;
 }

 .img-style{
     font-size: 1.3rem;
     color: #0d9f9f;
     border-radius: 5rem;
 }

 .text-style{
     font-weight: 500;
     margin: 0;
     text-align: center;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 1 0 0 0;
     margin-bottom: 0;
     background-color: rgba(51, 51, 51, 0.178);
 }

 .button-style {
     line-height: 1.3em;
 }

 .input-personal {
     padding: 0.2rem 0.4rem;
     font-size: 15px;
     font-weight: 400;
 }

 .listagem-solicitacao {
     max-height: 300px;
 }

 #nav-tabContent {
     overflow: auto;
     padding: 0 5px;
 }

 .typeTitle {
     margin-top: 5px;
 }



 .buttons {
     width: auto;
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
     padding: 5px;
     box-sizing: border-box;
     align-items: center;
 }

 .infos-text {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-wrap: wrap;
     padding: 5px;
     box-sizing: border-box;
 }

 .infos-text > label {
     font-weight: bold !important;
 }

 .inviteType {
     padding: 5px;
     box-sizing: border-box;
     font-weight: 600;
 }

 .noneFound {
     margin: 5px 0;
 }

 .nav-item.active {
     background-color: rgba(255, 255, 255, 0.3);
     border: 1px solid rgba(255, 255, 255, 0.8);
 }

 .nav-item, .nav-tabs {
     border-bottom: 1px solid rgba(255, 255, 255, 0.8);
 }

 .nav {
     flex-wrap: nowrap;
     gap: 5px;
     align-items: center;
     justify-content: space-around;
 }

 .nav-item {
     flex: 1;
     padding: 10px 0;
     text-align: center;
 }

</style>
