<template>
<div>
    <ModalCentral 
        componentName="ShowCampoInfo"
        title="DESCRIÇÃO DO CAMPO"
        idName="showCampoInformacao"
        :data="dataToShowCampoInfo"
    />
    <div v-if="listaType == 'campoSelecao'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
        <div class="box-lista" v-for="info in infosData" :key="info.counter" :class="{'list-item-selected': info.isativo == 'ON'}">
            <div class="box-img" v-if="info.isativo == 'ON'" >
                <img class="img-info" src="@assets/img/Dados/ListarCampos/field-icon-purple.png" alt="">
            </div>
            <div class="box-img" v-else>
                <img class="img-info turning-grey" src="@assets/img/Dados/ListarCampos/field-icon-purple.png" alt="">
            </div>
            <span class="first-info-short d-flex align-items-center">{{info.titulo}}<i @click="sendInfosToShowCampoInfo(info.descricao)" data-toggle="modal" data-target="#showCampoInformacao" class="fa fa-info-circle ml-2"></i></span>
            <span v-if="info.isativo != 'ON'" class="second-info" @click="teste">Criado em {{info.ano}}</span>
            <span v-else class="second-info">Campo Ativo</span>
            <Button v-if="info.isativo == 'ON' && userPerm.permissao != 'APLICADOR'" @click="encerraCampo(info.idamostra)" 
                value="Encerrar Campo"
                section="instituicao-cadastrar"
                :isOutline="true"   
            />

            <Button v-if="info.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == false" @click="iniciaCampo(info.idamostra)" 
                value="Iniciar Campo"
                section="salvar"
                :isOutline="true"   
            />

            <Button v-if="info.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR'" @click="excluirCampo(info.idamostra)" 
                value="Excluir Campo"
                section="descartar"
                :isOutline="true"   
            />

            <span v-if="info.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == true" class="third-info-non-clickable">Campo Inativo</span>
            
            
            <span v-if="info.isativo == 'OFF'" class="third-info-non-clickable">Campo Encerrado</span>
            <router-link :to="{name: 'InstituicaoMenuCampo', params: { idCampo: info.idamostra }}" >
                <Button  
                    value="Ver Campo"
                    section="instituicao-campo"
                    :isOutline="true"                
                />
            </router-link>
            <!-- <span class="last-info">Excluir Campo</span> -->
        </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style">Nenhum campo encontrado.</h2>
        </transition-group>
    </div>
</div>
</template>

<script>
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral'
import Swal from 'sweetalert2';

export default {
    name: 'ListagemElementSelect',
    components: {
        ModalCentral,
        Button
    },
    data() {
        return {
            dataToShowCampoInfo: {}
        }
    },
    computed: {
        infosData() {
            return this.infos
        },
        userPerm() {
            return this.$store.getters.getUserPerm
        },
    },
    async created(){
        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }
        await this.$store.dispatch('setUserPerm', payload)
    },
    props: {
        infos: Array,
        listaType: String
    },
    methods: {
        sendInfosToShowCampoInfo: function(descricao) {
            this.dataToShowCampoInfo = {
                descricao: descricao
            }
        },
        teste() {
            console.log(this.hasCampoAtivo())
        },
        hasCampoAtivo() {
            var hasAtivo = false
            this.infos.forEach((value) => {
                if (value.isativo == 'ON') {
                    console.log("tem")
                    hasAtivo =  true
                }
            })
            return hasAtivo
        },
        async encerraCampo(idAmostra){
            const result = await Swal.fire({
              title: 'Deseja mesmo encerrar o campo?',
              text: 'Uma vez encerrado, ele não poderá ser reaberto.',
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: "Cancelar",
              confirmButtonText: "Encerrar Campo",
            })

            if (result.isConfirmed) {
              const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idAmostra: idAmostra
            }            
            await this.$store.dispatch('setEncerraCampo', payload)
            await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
            }
            
        },

        async iniciaCampo(idAmostra){
            const result = await Swal.fire({
              title: 'Deseja mesmo inicar o campo?',
              text: 'Uma vez iniciado, você só podera modificar alguma entidade do campo (membro, formulário, unidades e turmas) caso não possua aplicação vinculada.',
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: "Cancelar",
              confirmButtonText: "Iniciar Campo",
            })

            if (result.isConfirmed) {
              const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idAmostra: idAmostra
            }            
            await this.$store.dispatch('setIniciaCampo', payload)
            await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
            }
            
        },

        async excluirCampo(idAmostra){
            const result = await Swal.fire({
              title: 'Deseja mesmo excluir o campo?',
              text: 'Ao excluir, você não terá mais acesso às entidades (membro, formulário, unidades e turmas) do campo.',
              icon: "question",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              cancelButtonText: "Cancelar",
              confirmButtonText: "Excluir Campo",
            })

            if (result.isConfirmed) {
              const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idAmostra: idAmostra
            }            
            await this.$store.dispatch('setDeleteAmostra', payload)
            await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
            }         
        }
    }
}
</script>


<style scoped>

.turning-grey{
    filter: grayscale(100%);
}

 .title-style{
    font-size: 25px;
    color: #656565;
    margin: 0;
    text-align: center;
    font-weight: 500;
}

.box-lista {
    background-color: #FCFCFC;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 15vh;
    margin: 1rem;
    padding: 0.4rem 2.5rem;
}

.list-item-selected {
    background-color: #e4ccfe;
    border: 1.8px solid #00853b;
}

.second-info {
    width: 20%;
    text-align: center;
}

.third-info {
    color: #5c2597;
    cursor: pointer;
    user-select: none;
    font-weight: 500;   
    transition: all ease 0.2s;
}

.third-info:hover {
    color: #341258;
}

.third-info-non-clickable {
    color: #212529;
    cursor: default;
    user-select: none;
    font-weight: normal;   
    transition: all ease 0.2s;
}

.last-info {
    color: #EB3E3E;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.3s;
    font-weight: 500;
    text-align: right;
}

.last-info:hover {
    color: #8f1c1c;
}

.info-hide {
    opacity: 0;
    pointer-events: none;
}

.box-img {
    flex-basis: 5%;
}

.img-info {
    width: auto;
    max-height: 72px;
    margin: 0.2rem;
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
    .first-info-short {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 680px) {
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .first-info-short {
        text-align: center;
        width: -webkit-fill-available;
    }
    .second-info, .third-info{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .info-hide{
        display: none;
    }
    .box-lista{
        place-content: center;
    }
}

.slide-fade-enter-active {
    transition: all .2s ease;
}
.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-40px);
    opacity: 0;
}

.fa-info-circle {
    color: #ffac30;
    font-size: 1.1rem;
    cursor: pointer;
}
</style>