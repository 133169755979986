import axios from '@middleware/api.js';
import Cookies from 'js-cookie';
const JWT = () => {
  return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO");
};

const state = () => ({
  listEmpregadores: null,
  isEmpregadorCreated: null,
  isEmpregadorUpdated: null,
  isEmpregadorRemoved: null,
  isEmpregadorUploaded: null,
});

const mutations = {
  setListEmpregadores: ( state, list ) => state.listEmpregadores = list,
  setIsEmpregadorCreated: ( state, v ) => state.isEmpregadorCreated = v,
  setIsEmpregadorUpdated: ( state, v ) => state.isEmpregadorUpdated = v,
  setIsEmpregadorRemoved: ( state, v ) => state.isEmpregadorRemoved = v,
  setIsEmpregadorUploaded: ( state, v ) => state.isEmpregadorUploaded = v,
};

const actions = {
  async loadListEmpregadores({ commit }, payload) {
    commit('setListEmpregadores', []);

    await axios
      .get(`empregador/${payload.idInstituicao}/${payload.idEmpresa}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        },
      })
      .then(response => commit('setListEmpregadores', response.data?.data))
      .catch(err => {
        console.log(err);
        commit('setListEmpregadores', null);
      });
  },

  async createEmpregador({ commit }, payload) {
    commit('setIsEmpregadorCreated', null);

    await axios
      .post(
        `empregador/${payload.idInstituicao}/${payload.idEmpresa}`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => commit('setIsEmpregadorCreated', true))
      .catch((error) => {
        console.log(error);
        commit('setIsEmpregadorCreated', false);
      });
  },

  async updateEmpregador({ commit }, payload) {
    commit('setIsEmpregadorCreated', null);

    await axios
      .put(
        `empregador/${payload.idInstituicao}/${payload.idEmpresa}/${payload.idEmpregador}`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => commit('setIsEmpregadorUpdated', true))
      .catch((error) => {
        console.log(error);
        commit('setIsEmpregadorUpdated', false);
      });
  },

  async removeEmpregador({ commit }, payload) {
    commit('setIsEmpregadorRemoved', null);

    await axios
      .delete(
        `empregador/${payload.idInstituicao}/${payload.idEmpresa}/${payload.idEmpregador}`,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => commit('setIsEmpregadorRemoved', true))
      .catch((error) => {
        console.log(error);
        commit('setIsEmpregadorRemoved', false);
      });
  },
}

const getters = {
  getListEmpregadores: (state) => state.listEmpregadores,
  getIsEmpregadorCreated: (state) => state.isEmpregadorCreated,
  getIsEmpregadorUpdated: (state) => state.isEmpregadorUpdated,
  getIsEmpregadorRemoved: (state) => state.isEmpregadorRemoved,
  getIsEmpregadorUploaded: (state) => state.isEmpregadorUploaded,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
