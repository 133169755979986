<template>
    <ModalCentral
        v-if="isAdmin"
        componentName="CreatePratica"
        title="Criar prática"
        idName="createPratica" />

    <ModalCentral
        v-if="isAdmin"
        componentName="UpdateCategoriesPraticas"
        title="Editar categorias"
        idName="updateCategorias" />

    <ModalCentral
        v-if="isAdmin"
        componentName="UpdateSectionsPraticas"
        title="Editar seções"
        idName="updateSections" />

    <div class="outter">
        <h1 class="bancoTitle">BANCO DE BOAS PRÁTICAS</h1>

        <div v-if="isAdmin" class="adminMenu">
            <Button
                data-target="#createPratica"
                data-toggle="modal"
                class="menuBut"
                value="Criar prática"
                :isOutline="false" />

            <Button
                data-target="#updateCategorias"
                data-toggle="modal"
                class="menuBut"
                value="Editar categorias"
                :isOutline="false" />

            <Button
                data-target="#updateSections"
                data-toggle="modal"
                class="menuBut"
                value="Editar seções"
                :isOutline="false" />
        </div>

        <div class="filterHeader">
            <div>Filtrar por:</div>

            <select
                v-model="filterCategory">
                <option value="">Todas</option>
                <option
                    v-for="(category, idx) in categoryNames"
                    :key="idx"
                    :value="category">{{ category }}</option>
            </select>

            <select v-model="filterAge">
                <option value="">Todas</option>
                <option
                    v-for="(age, idx) in ageBrackets"
                    :key="idx"
                    :value="age">{{ age }}</option>
            </select>

            <select v-model="filterRegion">
                <option value="">Todas</option>
                <option
                    v-for="(region, idx) in regions"
                    :key="idx"
                    :value="region">{{ region }}</option>
            </select>

            <div class="clearFilter"
                 @click="clearFilters">
                <i class="fas fa-backspace"></i>
                &nbsp;
                Remover filtros
            </div>
        </div>

        <div class="emptyList" v-if="loaded && !filteredBoasPraticas.length">
            <span>Nenhuma prática atualmente cadastrada!</span>
        </div>

        <div class="categoriesContainer"
             v-else
             v-for="categoryName in categoryNames"
             :key="categoryName">
            <div v-if="loaded && containsCategory(categoryName) && filterCategory == '' || filterCategory == categoryName">
                <h3
                    :style="{
                        'backgroundImage': `url(${categories[categoryName].background})`
                    }">
                    {{ categoryName }}
                </h3>

                <div class="categories">
                    <div
                        v-for="pratica in filteredBoasPraticas.filter(pratica => praticaCategory(pratica, categoryName))"
                        :key="pratica.idPratica"
                        :style="{
                            'backgroundImage': `linear-gradient(white, white), linear-gradient(to bottom right, ${praticaGradient(pratica)})`
                        }"
                        class="category">
                        <a :href="`/boaspraticas/${pratica.idPratica}`" class="categoryInner">
                            <img
                                v-if="pratica?.link && pratica?.link != 'null' && pratica?.link != 'undefined'"
                                :src="`https://i.ytimg.com/vi/${clipVideoId(pratica.link)}/maxresdefault.jpg`"
                                alt="Capa do vídeo" />
                            <img
                                v-else
                                :src="require('../../assets/img/BoasPraticas/oquee.jpeg')"
                                alt="Imagem da prática" />

                            <div class="titulo">{{ pratica.titulo }}</div>
                            <div class="autor">
                                <i class="fas fa-id-badge"></i>
                                &nbsp;
                                {{ pratica.autor }}
                            </div>
                            <div class="idade">
                                <i class="fas fa-shapes"></i>
                                &nbsp;
                                {{ pratica.idade }}
                            </div>
                            <div class="tags">
                                <div v-for="(tematica, k) in pratica.tematicas"
                                    :style="{ 'background': `url(${categories[tematica].background})` }"
                                    :key="k"
                                    class="tag">
                                    {{ tematica }}
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

        </div>


    </div>
</template>

<script>
 import Button from "@components/Button";
 import ModalCentral from "@components/ModalCentral";

 export default {
     name: "BoasPraticas_Acervo",
     components: {
         Button,
         ModalCentral,
     },
     data() {
         return {
             filterCategory: "",
             ageBrackets: [
                 "Bebês (0 a 6 meses de idade)",
                 "Crianças bem pequenas (1 a 3 anos e 11 meses)",
                 "Crianças pequenas (4 a 5 anos e 11 meses)",
             ],
             filterAge: "",
             regions: ['NORTE', 'NORDESTE', 'CENTRO-OESTE', 'SUDESTE', 'SUL'],
             filterRegion: "",
             loaded: false,
         };
     },
     async created() {
         await this.$store.dispatch('loadListBoasPraticas');
         this.loaded = true;
         this.$store.dispatch('setPraticaCategories');
     },
     methods: {
         containsCategory(categoryName) {
             return this.filteredBoasPraticas
                        .some(pratica => this.praticaCategory(pratica, categoryName));
         },
         praticaCategory(pratica, categoryName) {
             return pratica.boasPraticasCategorias
                           .some(cat => cat.idTipo.nome == categoryName);
         },
         clipVideoId(raw) {
             let url = raw.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
             return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
         },
         clearFilters() { this.filterCategory = this.filterAge = this.filterRegion = ''; },
         praticaGradient(pratica) {
             if (pratica.boasPraticasCategorias.length > 1)
                 return pratica.boasPraticasCategorias?.map(tematica =>
                     this.categories[tematica.idTipo.nome].color
                 );
             return [
                 this.categories[pratica.boasPraticasCategorias[0].idTipo.nome].color,
                 this.categories[pratica.boasPraticasCategorias[0].idTipo.nome].color,
             ];
         },
     },
     computed: {
         categoryNames() {
             let original = this.$store.getters.getPraticaCategories;
             return original?.sort((a, b) => a.codigo - b.codigo).map(cat => cat.nome) || [];
         },
         categories() {
             let base = {
                 "Parcerias com as famílias e comunidade escolar": {
                     color: "blue",
                     background: require('../../assets/img/Backgrounds/azulEscuro.png'),
                 },
                 "Reconhecimento e valorização da diversidade cultural": {
                     color: "purple",
                     background: require('../../assets/img/Backgrounds/roxoEscuro.png'),
                 },
                 "Escuta infantil, observação, investigação": {
                     color: "#73c0e9",
                     background: require('../../assets/img/Backgrounds/azulClaro.png'),
                 },
                 "Acolhimento e gestão de sentimentos": {
                     color: "maroon",
                     background: require('../../assets/img/Backgrounds/marrom.png'),
                 },
                 "Valorização e reconhecimento da importância do ecossistema e do meio ambiente": {
                     color: "darkcyan",
                     background: require('../../assets/img/Backgrounds/cianoEscuro.png'),
                 },
                 "Práticas de formação da equipe de profissionais": {
                     color: "brown",
                     background: require('../../assets/img/Backgrounds/marsala.png'),
                 },
                 "Cuidado, autonomia e atenção as crianças": {
                     color: "darkgreen",
                     background: require('../../assets/img/Backgrounds/verdeEscuro.png'),
                 },
                 "Práticas de gestão": {
                     color: "darkorange",
                     background: require('../../assets/img/Backgrounds/laranja.png'),
                 },
             };

             Object.keys(base).forEach((cat, idx) => {
                 base[this.categoryNames[idx]] = base[cat];
                 delete base[cat];
             });

             return base;
         },
         listBoasPraticas() {
             return this.$store.getters.getListBoasPraticas;
         },
         filteredBoasPraticas() {
             if (this.filterCategory == "" && this.filterAge == "" && this.filterRegion == "")
                 return this.listBoasPraticas;

             const filters = [
                 pratica => this.filterCategory == "" || pratica.tematicas.includes(this.filterCategory),
                 pratica => this.filterAge == "" || this.filterAge == pratica.idade,
                 pratica => this.filterRegion == "" || this.filterRegion == pratica.regiao,
             ];

             return this.listBoasPraticas.filter(pratica => filters.every(filter => filter(pratica)));
         },
         isAdmin() {
             return this.$store.getters.getUserInfo?.data?.isAdmin;
         },
     }
 };
</script>

<style scoped>
 .outter {
     background: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     width: 100%;
     padding: 3rem;
     min-height: 85vh;
 }

 .bancoTitle {
     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
     color: rgba(25, 89, 83, 0.8);
     font-weight: bolder;
     width: 100%;
     text-align: center;
 }

 .filterHeader {
     width: 100%;
     padding: 5px;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
     text-align: center;
     align-items: center;
 }

 .filterHeader > select {
     width: min-content;
     max-width: 150px;
     margin: 5px;
     border: 1px solid grey;
     border-radius: 8px;
     box-shadow: 6px 6px 5px -1px rgba(0, 0, 0, 0.15);
     background: url("~@/assets/img/Backgrounds/laranjaClaro.png");
     background-repeat: repeat;
     background-size: 256px;
 }

 .filterHeader > div {
     display: inline-block;
     margin: 5px;
 }

 .clearFilter {
     color: red;
     cursor: pointer;
     margin: 5px 15px !important;
 }

 .categories {
     width: 100%;
     display: flex;
     flex-direction: row;
     overflow-x: auto;
     margin-bottom: 10px;
 }

 .category {
     cursor: pointer;
     width: 100%;
     max-width: 200px;
     margin: 5px;
     border: double 3px transparent;
     border-radius: 1rem;
     background-origin: border-box;
     background-clip: content-box, border-box;
 }

 .categoryInner {
     color: currentColor !important;
     width: 100%;
     padding: 10px;
     display: flex;
     flex-direction: column;
     border-radius: 1rem;
     background: url("~@/assets/img/Backgrounds/laranjaMaisClaro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     height: 100%;
 }

 h3 {
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
 }

 .categoryInner > img {
     border-radius: 5px;
 }

 .categoryInner > .titulo {
     font-weight: bold;
     width: 100%;
     text-align: center;
 }

 .categoryInner > .autor {
     width: 100%;
     text-align: left;
     font-size: 0.8em;
 }

 .categoryInner > .idade {
     width: 100%;
     text-align: left;
     font-size: 0.8em;
 }

 .categoryInner > .tags {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
 }

 .tags > .tag {
     width: min-content;
     max-width: 100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     color: white;
     padding: 2px;
     border: none;
     border-radius: 5px;
     margin: 5px;
     box-sizing: border-box;
     font-size: 0.7em;
     font-weight: bold;
 }

 .categoriesContainer {
     width: 100%;
     margin: 2rem 0;
 }

 .noneFound {
     width: 100%;
     text-align: center;
     padding: 5px;
     font-size: 1.1em;
 }

 .adminMenu {
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
 }

 .menuBut {
     margin: 5px;
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 8px;
 }

 .emptyList {
     width: 100%;
     padding: 10px;
     font-size: 1.2em;
     color: rgba(0, 0, 0, 0.7);
     font-style: italic;
     text-align: center;
     padding-top: 20vh;
 }
</style>
