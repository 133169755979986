<template>

    <div>

        <PDFModal
            :pdfLink="pdfLink"
            :id="`idPDF${idNum}`"
        />
        
        <VideoModal
            :videoLink="videoLink"
            :id="`idVideo${idNum}`"
        />

        <!---------- MODAL CENTRAL PARA EDITAR VÍDEO E PDF -------------------->
        <ModalCentral 
            componentName="EditPDF"
            title="EDITAR PDF"
            :idName="`EditPDF${idNum}`"
			:data="{ idModal: idNum }"
        />
        <ModalCentral 
            componentName="EditVideo"
            title="EDITAR VÍDEO"
            :idName="`EditVideo${idNum}`"
			:data="{ idModal: idNum }"
        />

        <div @click="closeMiniDropdown()" class="overlay-invisible" :class="{'overlay-visible': isActiveMiniDropdown}"></div>

        <!----------- AQUI COMEÇA O CARD -------------------------------------------------->
        <div class="card custom-rounded">

            <div @mouseenter="turnOnCog()" @mouseleave="turnOffCog()" class="box-img-card">
                    
                <!--------------- SE FOR VÍDEO  ------------------------------------------>
                <div v-if="isVideo" :id="idNum">
                    <div class="embed-responsive">
                        <div  data-toggle="modal" :data-target="`#idVideo${idNum}`">
                            <!-- <div class="play-white-circle"> -->
                                <i class="fas fa-play-circle"></i>
                            <!-- </div> -->
                        </div>
                        <img class="embed-responsive-item" :src="`https://img.youtube.com/vi/${videoLink}/0.jpg`"/>
                    </div>   
                    <div class="card-body-video">
                        <p class="title-style">{{title}}</p>
                    </div>      
                </div>

                <!--------------- SE FOR PDF  ------------------------------------------>
                <div v-else :id="idNum">
                    <div class="pdf-wrapper">                        
                        <div class="pdf-style" >
                            <object :data="handledPdfLink" type="application/pdf" width="100%" height="100%" fameborder="0">
                                <!-- <embed type="application/pdf" :src="pdfLink"  width="100%" height="100%" /> -->
                                <p class="pdf-indisponivel">
                                    <i class="fas fa-file-pdf"></i>
                                </p>
                            </object>

                            <!-- <pdf :src="handledPdfData()" :page="1">
                                 </pdf> -->
                        </div>
                    </div>
                    <div class="card-body-PDF">
                        <div  data-toggle="modal" :data-target="`#idPDF${idNum}`">
                            <i class="fas fa-eye"></i>
                        </div>
                        <p class="title-style">{{title}}</p>
                    </div>
                </div>

                <!--------------- DROPDOWN DA ENGRENAGEM  ------------------------------------------>
                <div v-show="userPerm == true" @click="showMiniDropdown()" class="material-actions">
                    
                    <i class="fas fa-cog text-secondary" :class="{'turning-cog': isTurningCog, 'turning-instead-cog': isTurningInsteadCog}"></i>
                    <div class="dropdown-box" :class="{'dropdown-box-visible': isActiveMiniDropdown}">
                        <div class="mini-dropdown">
                            <!-- TRIÂNGULO DO POP-UP PEQUENO -->
                            <div class="mini-triangle"> 
                                <svg class="triangulo-1" width="26" height="30" viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M13.6699 2.5C15.5944 -0.833332 20.4056 -0.833334 22.3301 2.5L35.3205 25C37.245 28.3333 34.8394 32.5 30.9904 32.5H5.00962C1.16062 32.5 -1.24501 28.3333 0.679492 25L13.6699 2.5Z" fill="white"/>
                                </svg>
                            </div>                            
                            <!-- POP-UP PEQUENO -->
                            <div class="mini-pop-up shadow-lg pt-3 pr-1 pb-3 pl-2">

                                <!--------------- OPÇÕES PARA EDITAR MATERIAL  ------------------------------------------>
                                <div v-show="!isVideo" @click="editPDF" data-toggle="modal" :data-target="`#EditPDF${idNum}`" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown"><i class="fas fa-sign-out-alt mr-1"></i> Editar PDF</div>
                                <div v-show="isVideo" @click="editVideo" data-toggle="modal" :data-target="`#EditVideo${idNum}`" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown"><i class="fas fa-sign-out-alt mr-1"></i> Editar Vídeo</div>
                                <!--------------- OPÇÕES PARA Excluir MATERIAL  ------------------------------------------>
                                <div v-show="!isVideo" @click="deleteMaterial(idNum)" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown"><i class="fas fa-trash mr-1"></i> Excluir PDF</div>
                                <div v-show="isVideo" @click="deleteMaterial(idNum)" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown"><i class="fas fa-trash mr-1"></i> Excluir Vídeo</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--------------- FIM DO DROPDOWN DA ENGRENAGEM  ------------------------------------------>
            </div>  
        </div>                            
    </div>

</template>

<script>

import PDFModal from '@components/Forms/PDFModal';
import VideoModal from '@components/Forms/VideoModal';
import ModalCentral from '@components/ModalCentral';

// import Swal from "sweetalert2";
// import { Notyf } from 'notyf';
// const notyf = new Notyf({
//   duration: 5000,
//   position: {
//     x: 'left',
//     y: 'bottom',
//   },
//   types: [
//     {
//       type: 'info',
//       background: '#18a0fb',
//       icon: {
//         className: 'fa fa-info-circle',
//         tagName: 'i',
//         color: 'white'
//       }
//     }
//   ]
// });

export default {
    name: 'MaterialBox',
    data() {
        return {
            idNum: this.id,
            YouTubeLink: this.videoLink,
            linkPDF: this.pdfLink,
            isActiveMiniDropdown: false,
            isTurningCog: false,
            isTurningInsteadCog: false,
        }
    },
    props: {
        id: String,
        isVideo: Boolean,
        title: String,
        palette: String,
        videoLink: String,
        pdfLink: String
    },
    components: {
        PDFModal,
        VideoModal,
        ModalCentral
    },
    methods: {
        // TODO: Talvez excluir este método
        async editMaterial() {
        
        },
        async deleteMaterial(idNum) {
			const payload = {
				idModal: idNum,
			}
			await this.$store.dispatch('deleteMaterial', payload)
			await this.$store.dispatch('setListModais', {
				categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
			})
        },
        showMiniDropdown: function () {
            this.isActiveMiniDropdown = true;
        },
        closeMiniDropdown: function () {
            this.isActiveMiniDropdown = false;
        },
        turnOnCog: function () {
            this.isTurningCog = true;
            this.isTurningInsteadCog = false;
        },
        turnOffCog: function () {
            this.isTurningCog = false;
            this.isTurningInsteadCog = true;
        },
    },
    async created(){
    },
    computed: {
		userPerm() {
			try{
            	return this.$store.getters.getUserInfo.data.isAdmin
			} catch(e) {
				return false
			}
        },
        handledPdfLink() {
            return `${this.pdfLink}#toolbar=0&navpanes=0&scrollbar=0`
        }
	},

}

</script>

<style scoped>

    .overlay-invisible {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(22, 22, 22, 0);
        z-index: 2;
        transition: all ease-in-out 0.3s;
        opacity: 0;
        pointer-events: none;
    }

    .overlay-invisible {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(22, 22, 22, 0);
        z-index: 2;
        transition: all ease-in-out 0.3s;
        opacity: 0;
        pointer-events: none;
    }

    p {
        margin: 0 !important;
        white-space: break-spaces !important;
        word-wrap: break-word;
        display: flex;
        
    }

    .card {
        border: none !important;
        white-space: nowrap; 
    }

    .card.custom-rounded {
        border-radius: 20px;
        box-shadow: 12px 12px 23px -7px rgba(158, 146, 158, 0.671);
    }

    .box-img-card {
        display: inline-block;
        overflow: hidden;
        border-top-left-radius: calc(1rem - 1px);
        border-top-right-radius: calc(1rem - 1px);
        height: 14rem;
    }

    .fa-cog {
        color: rgb(221, 221, 221);
        font-size: 1rem;
    }

    .fa-eye{
        padding: 0 0 0.5rem 0;
        font-size: 1.2rem;
    }

    .turning-cog {
        animation: turningCogAnimation;
        animation-duration: 0.6s;
        animation-timing-function: ease-in-out;
    }

    .turning-instead-cog {
        animation: turningCogInsteadAnimation;
        animation-duration: 0.6s;
        animation-timing-function: ease-in-out;
    }

    .overlay-visible {
        opacity: 1;
        pointer-events: all;
        cursor: default;
    }

    .card-body-video {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: rgba(236, 186, 224, 0);
        font-size: 0.9rem;
        min-height: 5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 1rem 1rem 1rem;
        align-items: center;
    }

    .card-body-PDF {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: rgba(246, 183, 184, 0.562);
        font-size: 0.9rem;
        min-height: 6rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 1rem 0 1rem;
        align-items: center;
    }

    .mini-triangle {
        margin: 0rem;
        display: center;
        align-items: center;
        justify-items: center;
        position: absolute;
        top: -16%;
        left: 68%;
    }
    .dropdown-box {
        position: absolute;
        top: 150%;
        right: -41%;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        cursor: default;
        transition: all 0.4s ease;
    }

    .dropdown-box-visible {
        opacity: 1;
        cursor: pointer;
        pointer-events: all;
    }

    .mini-dropdown {
        position: relative;
    }

    .mini-pop-up {
        height: 5.5rem;
        width: 8rem;
        background-color: white;
        color: grey;
        border-radius: 1rem;
        transition: color ease-in-out 0.2s;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .item-minidropdown:hover {
        color: rgb(58, 58, 58);
    }

    .material-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 0.9rem;
        font-weight: bold;
        cursor: pointer;
        background: white;
        border-radius: 100%;
        width: 32px;
        height: 30px;
        box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    }

    .fas{
        cursor: pointer;
        color: #EB7071;
    }

    .play-white-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 0.9rem;
        font-weight: bold;
        cursor: pointer;
        background: #fff;
        border-radius: 100%;
        width: 32px;
        height: 30px;
        box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    }

    .pdf-wrapper {
        height: 8rem;
        width: 15rem;
        overflow: hidden;
    } 

    .pdf-style{
        height: 9rem;
        width: 100%;
    }

    .embed-responsive{
        height: 9rem;
        width: 15rem;
    }

    .fa-play-circle{
        color: #EB7071;
        position: absolute;
        font-size: 44px;
        z-index: 1;
        left: 42%;
        top: 35%;
    }

    .pdf-indisponivel {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .pdf-indisponivel > i {
        font-size: 3em;
    }

    @keyframes turningCogAnimation {
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes turningCogInsteadAnimation {
        to {
            transform: rotate(-360deg);
        }
    }

</style>
