<template>
  <div class="outterCuringa">
    <p>Se o CPF não pode ser informado, por favor peça ajuda para seu aplicador para prosseguir.</p>
    <div class="codeInput">
      <label for="code">Insira o código da amostra:&nbsp;</label>
      <input
        @input="checkCode"
        type="password"
        name="code"
        v-model="code"
        placeholder="XXXX"
        @change="checkCode"
        />
    </div>
    <div class="termContainer">
      <div class="bylawCheck" v-show="containsTerm">
        <input type="checkbox" name="bylaw" v-model="termAccepted" />
        <label for="bylaw">
          Li e aceito os <a target="_blank" class="clickable" :href="termLink">termos e condições</a>
        </label>
      </div>
    </div>

    <button
      @click="data.changeComponent('TransicaoInitModal');"
      class="greenGradient clickable"
      style="margin-top: 1em; float: left;"
      >
      Voltar
    </button>

    <button
      @click="initForm"
      :disabled="!termAccepted || !codeValid"
      :class="{
              'greenGradient clickable': termAccepted && codeValid,
              'disabled': !termAccepted || !codeValid,
              }"
      style="margin-top: 1em; float: right;"
      >
      Prosseguir
    </button>
  </div>
</template>

<script>
import axios from '@middleware/api.js';

export default {
    name: "CpfCuringa",
    data() {
        return {
            code: null,
            termAccepted: !(this.$store.getters.getFormTransicao?.termo && this.$store.getters.getFormTransicao?.termo !== 'null' && this.$store.getters.getFormTransicao?.termo !== 'undefined'),
        };
    },
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    methods: {
        initForm() {
            if (!this.termAccepted || !this.codeValid) return;
            this.$emit("closeModal");
            this.$store.dispatch("startForm", { curinga: this.code });
        },
        checkCode() {
            if ((this.code?.length || 0) < 4) return;
            this.$store.dispatch("checkCode", {
                cypher: this.cypher,
                curinga: this.code,
            });
        },
    },
    computed: {
        form() {
            return this.$store.getters.getFormTransicao;
        },
        containsTerm() {
            return (this.form?.termo && this.form?.termo !== 'null' && this.form?.termo !== 'undefined');
        },
        termLink() {
            return axios.defaults.baseURL + "/transicao/getTermo/?path=" + this.form?.termo;
        },
        cypher() {
            return this.$route.params.cypher;
        },
        codeValid() {
            return this.$store.getters.getCodeIsValid;
    },
  },
};
</script>

<style media="screen" scoped>
@import url('../../assets/css/TransicaoEscolaTrabalho/styling.css');

.codeInput {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1em;
}

.codeInput > label {
    flex: 0 1 max-content;
    margin: 0 5px 0 5px;
}

.codeInput > input {
    flex: 1;
    display: block;
    border: 0;
    border-radius: 8px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    min-width: 0;
    padding: 5px;
}

.termContainer {
    flex: 1 0 100%;
    display: flex;
    flex-wrap: wrap;
}

.termContainer a, .buttonContainer a {
    color: var(--dark-green);
    font-weight: bold;
    display: inline-block;
}

.termContainer a:hover, .buttonContainer a:hover {
    transform: scale(1.015);
}

.bylaw {
    flex: 1 0 100%;
    display: block;
    background-color: whitesmoke;
    max-height: 200px;
    overflow: hidden;
    padding: 5px;
    margin: 0 auto 1em auto;
    border-radius: 8px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
 }

 .bylaw > div {
    height: 100%;
    overflow: auto;
 }

 .bylawCheck {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
    align-items: center;
 }

 .bylawCheck > input {
    cursor: pointer;
 }

 .bylawCheck > label {
    padding: 0;
    margin: 0;
 }
</style>
