<template>

    <div>

        <!----------- AQUI COMEÇA O CARD -------------------------------------------------->
        <div class="card custom-rounded px-3 py-4">
            
            <div class="embed-responsive">
                <img class="embed-responsive-item" src="../../assets/img/BoasPraticas/aspraticas.png" alt=""/>
            </div> 
             <div class="card-body-video mt-2">
                <p class="title-style">{{title}}</p>

                 <div>
                    <p class="info-style">{{autor}}</p>
                    <p class="info-style">{{idade}}</p>
                </div>

                 <button class="category-style mt-2">{{categoria}}</button>
            </div>      
        </div>

    </div>  

</template>

<script>

// import PDFModal from '@components/Forms/PDFModal';
// import VideoModal from '@components/Forms/VideoModal';
// import ModalCentral from '@components/ModalCentral';


export default {
    name: 'MaterialBox',
    data() {
        return {
            
        }
    },
    props: {
        id: String,
        title: String,
        categoria: String,
        idade: String,
        autor: String,
    },
    components: {
    },
    methods: {
        // TODO: Talvez excluir este método
        // async editMaterial() {
        
        // },
        // async deleteMaterial(idNum) {
		// 	const payload = {
		// 		idModal: idNum,
		// 	}
		// 	await this.$store.dispatch('deleteMaterial', payload)
		// 	await this.$store.dispatch('setListModais', {
		// 		categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
		// 	})
        // },
        // showMiniDropdown: function () {
        //     this.isActiveMiniDropdown = true;
        // },
        // closeMiniDropdown: function () {
        //     this.isActiveMiniDropdown = false;
        // },
        // turnOnCog: function () {
        //     this.isTurningCog = true;
        //     this.isTurningInsteadCog = false;
        // },
        // turnOffCog: function () {
        //     this.isTurningCog = false;
        //     this.isTurningInsteadCog = true;
        // },
    },
    async created(){
    },
    // computed: {
	// 	userPerm() {
	// 		try{
    //         	return this.$store.getters.getUserInfo.data.isAdmin
	// 		} catch(e) {
	// 			return false
	// 		}
    //     },
    //     handledPdfLink() {
    //         return `${this.pdfLink}#toolbar=0&navpanes=0&scrollbar=0`
    //     }
	// },

}

</script>

<style scoped>

    .overlay-invisible {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(22, 22, 22, 0);
        z-index: 2;
        transition: all ease-in-out 0.3s;
        opacity: 0;
        pointer-events: none;
    }

    .overlay-invisible {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(22, 22, 22, 0);
        z-index: 2;
        transition: all ease-in-out 0.3s;
        opacity: 0;
        pointer-events: none;
    }

    p {
        margin: 0 !important;
        white-space: break-spaces !important;
        word-wrap: break-word;
        
    }

    .title-style{
        font-weight: bold;
        text-align: center;
    }

    .category-style{
        border: 1px solid #3f3Fb8 !important;
        border-radius: 15px;
        color: white;
        height: 22px;
        width: 100%;
        background-color: #3F3FB8;
        text-align: center;
    }

    .card {
        border: 3px solid #3f3Fb8;
        white-space: nowrap; 
    }

    .card.custom-rounded {
        border-radius: 20px;
        box-shadow: 12px 12px 23px -7px rgba(158, 146, 158, 0.671);
    }

    .box-img-card {
        display: inline-block;
        overflow: hidden;
        border-top-left-radius: calc(1rem - 1px);
        border-top-right-radius: calc(1rem - 1px);
        height: 14rem;
    }

    .fa-cog {
        color: rgb(221, 221, 221);
        font-size: 1rem;
    }

    .fa-eye{
        padding: 0 0 0.5rem 0;
        font-size: 1.2rem;
    }

    .turning-cog {
        animation: turningCogAnimation;
        animation-duration: 0.6s;
        animation-timing-function: ease-in-out;
    }

    .turning-instead-cog {
        animation: turningCogInsteadAnimation;
        animation-duration: 0.6s;
        animation-timing-function: ease-in-out;
    }

    .overlay-visible {
        opacity: 1;
        pointer-events: all;
        cursor: default;
    }

    .card-body-video {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: rgba(236, 186, 224, 0);
        font-size: 0.9rem;
        min-height: 5rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .card-body-PDF {
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: rgba(246, 183, 184, 0.562);
        font-size: 0.9rem;
        min-height: 6rem;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 1rem 0 1rem;
        align-items: center;
    }

    .mini-triangle {
        margin: 0rem;
        display: center;
        align-items: center;
        justify-items: center;
        position: absolute;
        top: -16%;
        left: 68%;
    }
    .dropdown-box {
        position: absolute;
        top: 150%;
        right: -41%;
        z-index: 2;
        opacity: 0;
        pointer-events: none;
        cursor: default;
        transition: all 0.4s ease;
    }

    .dropdown-box-visible {
        opacity: 1;
        cursor: pointer;
        pointer-events: all;
    }

    .mini-dropdown {
        position: relative;
    }

    .mini-pop-up {
        height: 5.5rem;
        width: 8rem;
        background-color: white;
        color: grey;
        border-radius: 1rem;
        transition: color ease-in-out 0.2s;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .item-minidropdown:hover {
        color: rgb(58, 58, 58);
    }

    .material-actions {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 0.9rem;
        font-weight: bold;
        cursor: pointer;
        background: white;
        border-radius: 100%;
        width: 32px;
        height: 30px;
        box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    }

    .fas{
        cursor: pointer;
        color: #EB7071;
    }

    .play-white-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 0.9rem;
        font-weight: bold;
        cursor: pointer;
        background: #fff;
        border-radius: 100%;
        width: 32px;
        height: 30px;
        box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    }

    .pdf-wrapper {
        height: 8rem;
        width: 15rem;
        overflow: hidden;
    } 

    .pdf-style{
        height: 9rem;
        width: 100%;
    }

    .embed-responsive{
        height: 9rem;
        width: 13rem;
    }

    .fa-play-circle{
        color: #EB7071;
        position: absolute;
        font-size: 44px;
        z-index: 1;
        left: 42%;
        top: 35%;
    }

    .pdf-indisponivel {
        width: 100%;
        height: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .pdf-indisponivel > i {
        font-size: 3em;
    }

    @keyframes turningCogAnimation {
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes turningCogInsteadAnimation {
        to {
            transform: rotate(-360deg);
        }
    }

</style>
