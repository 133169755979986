<template>

    <div class="root-dados">
        <div class="container list-data">

            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-aplicacoes"/>
            <br/>


            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal">Nesta seção, você encontrará todas as Aplicações relacionadas ao Campo. Caso necessário, utilize o filtro abaixo para localizar a Aplicação desejada.</p>

            <br />

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <select class="col-2" v-model="searchType">
                    <option selected value="todos"> Todos </option>
                    <option value="estudante"> Estudante </option>
                    <option value="escola"> Escola </option>
                    <option value="turma"> Turma </option>
                    <option value="data"> Data de aplicação </option>
                    <option value="status"> Status </option>
                    <option value="formulario"> Formulário </option>
                </select>
                <input v-if="searchType !== 'data'" class="col form-control input-personal" v-model="searchTerm" type="text" name="filtro" id="inlineFormCustomName" placeholder="Termo a buscar..." autocomplete="off">
                <div class="col data-filter row align-middle" v-else>
                    <label class="col-2 align-middle">Início</label> <input class="col form-control input-personal" v-model="startDate" type="date" placeholder="Data inicial" autocomplete="off">
                    <label class="col-2 align-middle">Fim</label><input class="col form-control input-personal" v-model="endDate" type="date" placeholder="Data final" autocomplete="off">
                </div>
            </div>
            <hr class="separator">


            <!-------------------------------- LISTANDO AS APLICAÇÕES ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnts
                    term="aplicações"
                    :totalQnt="totalAplicacoes"
                    :filteredQnt="aplicacoes?.length || 0"
                    icon="clipboard" />

                <transition-group name="slide-fade" v-if="aplicacoes?.length > 0">
                    <div class = "table-wrap">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">  </th>
                                    <th scope="col">Estudante</th>
                                    <th scope="col">Formulário</th>
                                    <th scope="col">Escola</th>
                                    <th scope="col">Turma</th>
                                    <th scope="col">Status</th>
                                    <th scope="col">Envio</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <ListagemElements :infos="membros" listaType="addMembros"/>  -->
                                <tr class="table-row" v-for="(aplicacao, idx) in aplicacoes" :key="idx" >
                                    <td>
                                        <img  class="img-info-box-lista" src="@assets/img/Dados/ListarCampos/icon-aplicacoes.png" alt="">
                                    </td>
                                    <td>
                                        {{aplicacao.estudante}}
                                    </td>
                                    <td>
                                        {{aplicacao.form_name}}
                                    </td>
                                    <td>
                                        {{aplicacao.escola}}
                                    </td>
                                    <td>
                                        {{aplicacao.turma}}
                                    </td>
                                    <td>
                                        {{aplicacao.status}}
                                    </td>
                                    <td>
                                        <span class="orange-text-no-hover">{{convertUTC(aplicacao?.hora_termino)}}</span>
                                    </td>
                                    <td>
                                        <div class="controlGroup">
                                            <!--<Button
                                            
                                                 @click="downloadResposta(aplicacao.id_aplicacao)"
                                                 class="greenGradient clickable"
                                                 value="Baxiar respostas"
                                                 section="instituicao-aplicacoes"
                                                 :isOutline="true"
                                                 />-->
                                            -
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </transition-group>
                <transition-group v-else>
                    <div class="empty-list">
                        <h2 class="title-style-box-lista">Nenhuma Aplicação encontrada.</h2>
                    </div>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

            <div class="pagination-wrapper" v-if="totalPages > 1">
                <div class="pagination">
                    <!-- <button @click="updatePage(1)" :disabled="curPage === 1">
                        <i class="fas fa-angle-double-left"></i>
                    </button> -->
                    <button @click="updatePage(curPage - 1)" :disabled="curPage === 1">
                        <i class="fas fa-angle-left"></i>
                    </button>

                    <template v-for="page in visiblePages" :key="page">
                        <span v-if="page === '...'">...</span>
                        <button v-else @click="updatePage(page)" :class="{ active: page === curPage}">{{ page }}</button>
                    </template>
                    
                    <button @click="updatePage(curPage + 1)" :disabled="curPage === totalPages">
                        <i class="fas fa-angle-right"></i>
                    </button>
                    <!-- <button @click="updatePage(totalPages)" :disabled="curPage === totalPages">
                        <i class="fas fa-angle-double-right"></i>
                    </button> -->
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import parsers from '@middleware/parsers'
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
    name: 'CampoListaFormularios',
    data (){
        return {
            nomeCampo: '',
            descricao: '',
            selectedValue: '',
            searchTerm: '',
            startDate: '',
            endDate: '',
            searchType: 'todos',
            searchByCode: '',
            searchWithOrder: '',
            isLoadedInfo: false,
            curPage: 1,
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome do aplicador'
                },
                {
                id:3,
                value: 'Nome do formulário'
                },
                {
                id:4,
                value: 'Nome da UE'
                },
                {
                id:5,
                value: 'Nome da turma'
                }
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Campos',
                    iconName: 'th-large',
                    name_route: 'AplicacoesListaCampos'
                },
                {
                    id:4,
                    value: 'Aplicações',
                    iconName: 'pencil-alt',
                    name_route: ''
                }
                
            ]
        }
    },
    async created(){
        this.campos = this.$store.getters.getListCampos

        const payload = {
            idInstituicao: this.$route.params.idInstituicao,
            idCampo: this.$route.params.idCampoAplicacoes,
            page: this.curPage,
        }            

        this.isLoadedInfo = false
        await this.$store.dispatch('setListarAplicacaoPage', payload)
        this.isLoadedInfo = true
    },
    computed: {
        visiblePages() {
            const pages = [];

            if (this.totalPages <= 5) {
                for (let i = 1; i <= this.totalPages; i++) {
                    pages.push(i);
                }
            } else {
                
                if((this.curPage - 1) < 1){
                    pages.push(this.curPage, this.curPage + 1, this.curPage + 2);
                } else if((this.curPage + 1) > this.totalPages){
                    pages.push(this.curPage - 2, this.curPage - 1, this.curPage);
                } else{
                    pages.push(this.curPage - 1, this.curPage, this.curPage + 1);
                }

                if(pages[0] != 1){
                    if(pages[0]!= 2){
                        pages.unshift('...');
                        pages.unshift(1);
                    } else pages.unshift(1);
                }
                if(pages[pages.length - 1] != this.totalPages){
                    if(pages[pages.length -1] != this.totalPages -1){
                        pages.push('...');
                        pages.push(this.totalPages)
                    } else pages.push(this.totalPages);
                }
            }
            return pages
        },
        totalAplicacoes() {
            return this.$store.getters.getListarAplicacaoPageItens;
        },
        totalPages() {
            return this.$store.getters.getListarAplicacaoPageMaxPage;
        },
        aplicacoes() {
            let aplicacoes =  this.$store.getters.getListarAplicacaoPage,
                query = aplicacoes;
            if (this.searchTerm.trim() != '' || (this.startDate || this.endDate)) {
                let filtered = aplicacoes?.filter(aplicacao => {
                    switch (this.searchType) {
                        case 'estudante':
                            return (aplicacao.estudante || '').trim().toLowerCase().match(this.searchTerm.trim().toLowerCase());
                        case 'escola':
                            return (aplicacao.escola || '').trim().toLowerCase().match(this.searchTerm.trim().toLowerCase());
                        case 'turma':
                            return (aplicacao.turma || '').trim().toLowerCase().match(this.searchTerm.trim().toLowerCase());
                        case 'data':
                                var data_aplicacao = aplicacao.hora_termino.slice(0,10)
                                return(data_aplicacao >= this.startDate && data_aplicacao <= this.endDate);
                        case 'status':
                            return (aplicacao.status || '').trim().toLowerCase().match(this.searchTerm.trim().toLowerCase());
                        case 'formulario':
                            return (aplicacao.form_name || '').trim().toLowerCase().match(this.searchTerm.trim().toLowerCase());
                        default:
                            return [
                                aplicacao.estudante || '',
                                aplicacao.escola || '',
                                aplicacao.turma || '',
                                aplicacao.status || '',
                                aplicacao.form_name || '',
                            ].some(v => v.trim().toLowerCase().match(this.searchTerm.trim().toLowerCase()));
                    }
                });

                if (filtered?.length != 0)
                    query = filtered
                else
                    query = false
            }

            return query;
        }
    },
    components: {
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
        MemberQnts,
    },
    methods: {
        setSelectedValue: function() {
            this.selectedValue = this.$refs.filtersearchname.value
        },

        convertUTC(data){
            return parsers.convertUTC(data)
        },

        downloadResposta(idaplicacao) {
            notyf.open({
                type: "info",
                background: "var(--light-green)",
                message: "Carregando resposta...",
            });
            this.$store
                .dispatch("loadAmostraRespostas", {
                    idInstituicao: this.$route.params.idInstituicao,
                    idCampo: this.$route.params.idCampoAplicacoes,
                    idAplicacao: idaplicacao,
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'respostas.csv');
                    document.body.appendChild(link);
                    link.click();
                });
        },
      updatePage(page) {
        this.curPage = page;

        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          idCampo: this.$route.params.idCampoAplicacoes,
          page: this.curPage,
        };

        this.$store.dispatch('setListarAplicacaoPage', payload);
      },
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idCampoSelected = this.$route.params.idCampoAplicacoes

        if (typeof idCampoSelected == "undefined"){
            this.nomeCampo = "Nome do Campo"
        }
        else {
            const _campo = this.campos.find(campo => campo.idCampo = idCampoSelected);
            this.nomeCampo = _campo?.titulo;
            this.descricao = _campo?.descricao;
        }
    }
}
</script>

<style scoped>

@import "../../../assets/css/Dados/list.css";

@media screen and (max-width: 768px) {
    table > thead > tr > th{
        display: block;
    }

    tr{
        display: block;
        border: 1px solid #00853B;
        margin: 1.25rem 0;
        text-align: center;
        border-radius: 6px;
    }

    tr > td{
        display: block;
        width: 100%;
        border-top: 1px solid rgba(128, 128, 128, 0.301)!important;
        border-bottom: none;
    }
}

:deep(.box-lista span) {
    font-size: 1.1em;
}

.text-info-personal {
    font-size: 1.1rem;
    text-align: justify;
    padding: 0rem;
}

.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.list-box {
    margin: 2rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}


.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 3rem;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.main-link {
    outline:none;
    text-decoration: none;
    cursor: pointer;
    margin-right: .3rem;
}

.sub-link {
    margin-left: .3rem;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-box{
        margin: 0 3rem;
    }

    .lista-data-info{
        margin: 0 3rem;
    }
}

.input-group {
    overflow: hidden;
}

.input-group > select {
    background-color: white;
    border-width: 0 3px 0 0;
    border-radius: 0;
    border-style: solid;
    border-color: #00853B;
}

.data-filter{
    background-color: white;
    align-items: center;
    width: auto;
}

.pagination-wrapper{
    margin: 2rem 0 1.5rem 0;
    display: flex;
    justify-content: center;
}

.pagination {
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 0.5em 1em;
    gap: 0.25em;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
}

.pagination > button {
    border: none;
    box-shadow: none;
    background: #fff;
    transition: 0.5s all ease-in-out;
    padding: 0.75rem 1rem;
    font-weight: 600;
}

.pagination > button:not(:disabled):hover{
    background-color: #dae0e5;
}

.pagination > button:disabled{
    cursor: not-allowed;
}

.pagination .active{
    background-color: #196555 !important;
    color: #fff;
    font-weight: 700;
}

.empty-list{
    padding: 4rem 0;
}
</style>
