<template>
	<form @submit.prevent>
		<div class="container offset">
			<div class="custom-form">
				<div class="form-group">
					<label class="question-style">Título do Vídeo:</label>
					<input
						v-model.trim="title"
						type="name"
						class="form-control"
						placeholder="Insira aqui o título do Vídeo..."
						required
					/>
				</div>
				<div class="form-group">
					<label class="question-style">Link do Vídeo:</label>
					<input
						v-model.trim="videoLink"
						type="name"
						class="form-control"
						placeholder="Insira aqui o link do Vídeo..."
						required
					/>
				</div>
			</div>
			<div class="button-div pt-3">
				<Button
					@click="addVideo()"
					section="instituicao-campo"
					value="Adicionar Vídeo"
					:isOutline="true"
					:hasIcon="true"
					iconName="plus"
				/>
			</div>
		</div>
	</form>
</template>

<script>
import Button from '@components/Button'
 // import getVideoId from 'get-video-id'

// import Swal from 'sweetalert2';
// import { Notyf } from "notyf";
// const notyf = new Notyf({
//     duration: 5000,
//     position: {
//         x: "left",
//         y: "bottom",
//     }
// });

export default {
	name: 'AddVideo',
	data() {
		return {
			categoria: '',
			title: '',
			videoLink: '',
		}
	},
	components: {
		Button,
	},
	methods: {
		async addVideo() {
			const payload = {
				categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
				titulo: this.title,
				// link: getVideoId(this.videoLink).id,
				link: this.videoLink,
				isVideo: 'true',
			}
			await this.$store.dispatch('createModal', payload)
			await this.$store.dispatch('setListModais', {
				categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
			})
			this.clearForm()
			this.$emit('closeModal')
		},

		clearForm() {
			this.title = ''
			this.videoLink = ''
		},
	},
}
</script>

<style scoped>
.container {
	padding: 1rem;
	width: 100%;
	background-color: white;
	margin: 0;
}

.title-style {
	font-size: 1.5rem;
	font-weight: 600;
	color: #656565;
}

.word-style {
	color: #656565;
}

.custom-form {
	margin: 0 auto;
}

.question-style {
	width: 100%;
	font-size: 1rem;
	color: #656565;
}

.button-div {
	text-align: right;
}
</style>
