<template>
    <ModalCentral
        componentName="AddStudent"
        :title="editing == null ?  'ADICIONAR ESTUDANTE' : 'EDITAR ESTUDANTE'"
        :data="{editing: editing, listStudents: rawListEstudantes}"
        idName="AddStudent"
        @emit="loadData()"
    />

    <div class="root-dados">
        <div class="container list-data">

            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br />

            <div class="title-row" style="justify-content: flex-start; gap: 10px;">
                <InstitutionInfo class="title-style" :title="nomeTurma" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os estudantes pertencentes à turma. Caso necessário, utilize o filtro abaixo para localizar o estudante desejado.</p>

            <template v-if="userPerm.permissao != 'APLICADOR'">
                <div class="lista-data-info">
                    <Button
                        v-if="campoStatus != 'OFF'"
                        class="btn btnCtrl greenGradient"
                        section="instituicao-campo"
                        value="Adicionar estudante"
                        @click="editing = null"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="plus"
                        data-toggle="modal"
                        data-target="#AddStudent"
                    />

                    <Button
                        v-if="campoStatus != 'OFF'"
                        class="btn btnCtrl greenGradient"
                        section="instituicao-campo"
                        value="Aplicar alterações"
                        @click="applyChanges"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                    />
                </div>
                <br />
            </template>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" @input="filterEstudantes" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar estudantes por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <div v-if="isLoadedInfo">

                <transition-group v-if="estudantes_filtered?.length > 0" name="slide-fade">
                    <div class = "table-wrap">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">  </th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">CPF</th>
                                    <th scope="col">Gênero</th>
                                    <th scope="col">Data de nascimento</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row" v-for="estudante in estudantes_filtered" :key="estudante.idEstudante">

                                    <td> <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-turmas.svg" alt=""> </td>

                                    <td> {{estudante.nome}} </td>
                                    <td>{{ estudante?.cpf ? formatCpf(`${estudante.cpf}`) : 'Não informado' }}</td>
                                    <td>{{ estudante?.genero ? estudante?.genero : 'Não informado' }}</td>
                                    <td>{{ estudante?.dataNascimento ? maskDate(estudante.dataNascimento) : 'Não informada' }}</td>

                                    <td>
                                        <div class="controlGroup">
                                            <template v-if="userPerm.idPermissao != '1'">
                                                <Button
                                                    v-if="!estudante.isEditionLocked && campoStatus != 'OFF'"
                                                    class="greenGradient clickable"
                                                    section="instituicao-campo"
                                                    value="Editar"
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    @click="editing = estudante"
                                                    iconName="pen"
                                                    data-toggle="modal"
                                                    data-target="#AddStudent"
                                                />

                                                <Button
                                                    v-if="!estudante.isDeletionLocked && campoStatus != 'OFF'"
                                                    class="greenGradient clickable"
                                                    section="instituicao-campo"
                                                    value="Excluir"
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    @click="deleteStudent(estudante)"
                                                    iconName="trash"
                                                />

                                                <div v-if="userPerm.idPermissao != '1'" :class="campoStatus == 'OFF'? 'not-clickable' : ''">
                                                  <div v-if="estudante.isEditionLocked">Selecionado</div>
                                                  <div v-else>
                                                    <div @click="setIsSelected(estudante.idEstudante)" class="select-info">
                                                      <div :class="['box-select-info mr-2 ',{'selected-info': estudante?.isSelect?.isSelected}]"></div>
                                                      <span class="span-label-selected" v-if="estudante?.isSelect?.isSelected">Selecionado</span>
                                                      <span class="span-label-selected" v-else>Selecionar</span>
                                                    </div>
                                                  </div>
                                                </div>

                                            </template>
                                            <span v-else>-</span>
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style-box-lista" key="classesKey">Nenhum estudante encontrado.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>
        </div>
    </div>

</template>

<script>
 import Button from '@components/Button'
 import Breadcrumb from '@components/Breadcrumb'
 import ModalCentral from '@components/ModalCentral'
 import Swal from 'sweetalert2';
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     }
 });

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
             updating: false,
             editing: null,
             selectedValue: '',
           estudantesSelected: {},
           estudantes: [],
           estudantes_filtered: [],
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: 'ListarCampos'
                 },
                 {
                     id:4,
                     value: 'Gerenciar Campo',
                     iconName: 'chart-pie',
                     name_route: 'InstituicaoMenuCampo'
                 },
                 {
                     id:5,
                     value: 'Unidades Educacionais',
                     iconName: 'graduation-cap',
                     name_route: 'CampoListaUnidadesEducacionais'
                 },
                 {
                     id:6,
                     value: 'Turmas',
                     iconName: 'graduation-cap',
                     name_route: 'CampoListaTurmas',
                 },
                 {
                     id:7,
                     value: 'Estudantes',
                     iconName: 'person',
                     name_route: '',
                 },
             ]
         }
     },
     computed: {
         userPerm() {
             return this.$store.getters.getUserPerm
         },
         campoStatus() {
            const all_campos = this.$store.getters.getListCampos || [];
            const campo = all_campos
                    .find((value) => value.idamostra == this.$route.params.idCampo);
            return campo.isativo;
         },
         rawListEstudantes() { return this.$store.getters.getListEstudantes },
         rawListCampoEstudantes() { return this.$store.getters.getListCampoEstudantes },
         idTurma() { return this.$route.params.idTurma; },
         nomeTurma() {
             return this.$store
                        .getters
                        .getListTurma
                        .find(turma => turma.idturma == this.idTurma )?.nome || "";
         },
     },
   watch: {
     rawListCampoEstudantes() { this.setData(); },
     rawListEstudantes() { this.setData(); },
   },
     async created() {
         this.isLoadedInfo = false

         this.campos = this.$store.getters.getListUE

         const payload = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setUserPerm', payload)

         const payload2 = {
             idInstituicao: this.$route.params.idInstituicao,
             idAmostra: this.$route.params.idCampo,
             idUnidade: this.$route.params.idUnidade,
         }
         await this.$store.dispatch('setListTurmas', payload2)

       await this.loadData()
     },
     components: {
         Button,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         ModalCentral,
     },
     methods: {
        maskDate(inputDate){
            let date = new Date(inputDate);
            var day = date.getUTCDate();
            var month = date.getUTCMonth() + 1;
            var year = date.getUTCFullYear();

            var formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
            return formattedDate
        },
        formatCpf(cpf) {
            return cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring(6, 9) + '-' + cpf.substring(9, 11);
        },
       async applyChanges() {
         const result = await Swal.fire({
           title: "Tem certeza que deseja aplicar as alterações?",
           text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
           icon: "question",
           showCancelButton: true,
           confirmButtonColor: "#3085d6",
           cancelButtonColor: "#d33",
           cancelButtonText: "Cancelar",
           confirmButtonText: "Sim, tenho certeza",
         })

         if (result.isConfirmed) {
            const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idAmostra: this.$route.params.idCampo,
                addIds: this.estudantes.filter(estudante => estudante.isSelect.isSelected).map(estudante => estudante.idEstudante),
                removeIds: this.estudantes.filter(estudante => estudante.isSelect.isSelected == false).map(estudante => estudante.idEstudante),
            }
            await this.$store.dispatch('setManageEstudantes', payload)

           if (this.$store.getters.getIsEstudantesManaged) {
             Swal.fire(
               'Alterações aplicadas!',
               'As seleções foram aplicadas com sucesso.',
               'success'
             )
             this.loadData()
           } else
             Swal.fire(
               'Erro na alteração',
               'As seleções não foram aplicadas, favor tente novamente.',
               'error',
             )
         }
       },

       filterEstudantes() {
         // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
         var query = this.estudantes;

         //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
         if (this.searchByName.trim() !== '') {
           var filtered = query.filter(estudante => estudante.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
           if (filtered?.length != 0)
             query = filtered
           else
             query = false
         }

         this.estudantes_filtered = query;
       },

       setIsSelected(id) {
         const _estudante = { ...this.estudantes_filtered.find(estudante => estudante.idEstudante == id) };
         this.estudantes.find(estudante => estudante.idEstudante == id).isSelect = {
           isSelected: !_estudante.isSelect.isSelected,
           isSaved: false,
         };
       },
       setSelectAllItems() {
         this.estudantes.forEach(estudante => {
           estudante.isSelect = {
             isSelected: true,
             isSaved: false,
           }
         });
       },
       setUnselectAllItems() {
         this.estudantes.forEach(estudante => {
           estudante.isSelect = {
             isSelected: false,
             isSaved: false,
           }
         });
       },
       setData() {
         let _estudantes_amostra = this.rawListCampoEstudantes || [];

         this.estudantes.forEach(estudante => {
           estudante.isSelect = {
             isSelected: _estudantes_amostra.some(estudante_amostra => estudante_amostra.idestudante == estudante.idEstudante),
             isSaved: true
           };
         });

         this.isLoadedInfo = true;
       },

         async loadData() {
           const payload = {
             idInstituicao: this.$route.params.idInstituicao,
             idTurma: this.$route.params.idTurma,
           };
           await this.$store.dispatch('setListEstudantes', payload);

           const payload2 = {
             idInstituicao: this.$route.params.idInstituicao,
             idAmostra: this.$route.params.idCampo,
             idUnidade: this.$route.params.idUnidade,
           }
           await this.$store.dispatch('setListCampoEstudantes', payload2)

           this.estudantes = this.rawListEstudantes;
           this.setData();
           this.filterEstudantes();
         },
         async deleteStudent(estudante) {
             const swalResult = await Swal.fire({
                 title: 'Excluir estudante?',
                 text: `Tem certeza que deseja excluir o estudante ${estudante?.nome} da turma?`,
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: '#d33',
                 cancelButtonColor: '#3085d6',
                 cancelButtonText: 'Cancelar',
                 confirmButtonText: 'Sim, tenho certeza'
             })
             if (!swalResult.isConfirmed) return;

             await this.$store.dispatch('deleteStudent', {
                 idInstituicao: this.$route.params.idInstituicao,
                 idTurma: this.$route.params.idTurma,
                 ...estudante,
             });

             if (!this.$store.getters.getStudentDeleted){
                 notyf.open({
                     type: "error",
                     message: "Erro ao excluir o estudante!",
                 });
                 return;
             }

             notyf.open({
                 type: "success",
                 message: "Estudante excluído com sucesso!",
             });

             this.loadData();
         },
     },
     mounted() {
         window.scrollTo(0, 0);
     }
 }
</script>

<style scoped>

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     color: #fcfcfc;
 }

 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }


 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
 }

 .active-img {
     filter: brightness(0) invert(1) contrast(0.9);
 }


.not-clickable{
    cursor: not-allowed;
}

.not-clickable > div{
    pointer-events: none;
}
</style>
