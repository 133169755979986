import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';

const routes = [
  {
    path: '/',
    alias: ['/home', '/index'],
    name: 'Home',
    // component: () => import(/* webpackChunkName: "Home" */ '@views/Home') /* Permitir acesso através de outra URL? */
    component: () => import(/* webpackChunkName: "Home" */ '@views/NewHome')
  },
  
  {
    path: '/dados/gerenciar',
    alias: ['/dados/gerenciar/instituicao', '/dados'],
    name: 'GerenciarInstituicoes',
    component: () => import(/* webpackChunkName: "GerenciarInstituicoes" */ '@views/Dados/GerenciarInstituicoes'),
    meta: { requiresLogin: true }
  },

  {
    path: '/dados/gerenciar/instituicao/:idInstituicao',
    name: 'InstituicaoMenu',
    component: () => import(/* webpackChunkName: "InstituicaoMenu" */ '@views/Dados/InstituicaoMenu'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/cadastrar',
    name: 'InstituicaoMenuCadastrar',
    component: () => import(/* webpackChunkName: "InstituicaoMenuCadastrar" */ '@views/Dados/InstituicaoMenuCadastrar'),
    meta: { requiresLogin: true }

  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/cadastrar/membros',
    name: 'ListarMembros',
    component: () => import(/* webpackChunkName: "ListarMembros" */ '@views/Dados/Lists/ListarMembros'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/cadastrar/formularios',
    name: 'ListarFormularios',
    component: () => import(/* webpackChunkName: "ListarFormularios" */ '@views/Dados/Lists/ListarFormularios'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/cadastrar/unidades',
    name: 'ListarUnidadesEducacionais',
    component: () => import(/* webpackChunkName: "ListarUnidadesEducacionais" */ '@views/Dados/Lists/ListarUnidadesEducacionais'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/cadastrar/unidades/:idUnidade/turmas',
    name: 'ListarTurmas',
    component: () => import(/* webpackChunkName: "ListarTurmas" */ '@views/Dados/Lists/ListarTurmas'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/empresas',
    name: 'ListarEmpresas',
    component: () => import(/* webpackChunkName: "ListarEmpresas" */ '@views/Dados/Lists/ListarEmpresas'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/empresas/:idEmpresa/empregadores',
    name: 'ListarEmpregadores',
    component: () => import(/* webpackChunkName: "ListarEmpregadores" */ '@views/Dados/Lists/ListarEmpregadores'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/empresas/:idEmpresa/empregadores/:idEmpregador/jovens',
    name: 'ListarJovens',
    component: () => import(/* webpackChunkName: "ListarJovens" */ '@views/Dados/Lists/ListarJovens'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/cadastrar/unidades/:idUnidade/turmas/:idTurma/estudantes',
    name: 'ListarEstudantes',
    component: () => import(/* webpackChunkName: "ListarEstudantes" */ '@views/Dados/Lists/ListarEstudantes'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/cadastrar/unidades/:idUnidade/professores',
    name: 'ListarProfessores',
    component: () => import(/* webpackChunkName: "ListarProfessores" */ '@views/Dados/Lists/ListarProfessores'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos',
    name: 'ListarCampos',
    component: () => import(/* webpackChunkName: "ListarCampos" */ '@views/Dados/Lists/ListarCampos'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/egressos',
    name: 'ListarEgressos',
    component: () => import(/* webpackChunkName: "ListarEgressos" */ '@views/Dados/Lists/ListarEgressos'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo',
    name: 'InstituicaoMenuCampo',
    component: () => import(/* webpackChunkName: "InstituicaoMenuCampo" */ '@views/Dados/InstituicaoMenuCampo'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/membros',
    name: 'CampoListaMembros',
    component: () => import(/* webpackChunkName: "CampoListaMembros" */ '@views/Dados/Lists/CampoListaMembros'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/egressos',
    name: 'CampoListaEgressos',
    component: () => import(/* webpackChunkName: "CampoListaEgressos" */ '@views/Dados/Lists/CampoListaEgressos'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/formularios',
    name: 'CampoListaFormularios',
    component: () => import(/* webpackChunkName: "CampoListaFormularios" */ '@views/Dados/Lists/CampoListaFormularios'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/formulario/:idForm?',
    name: 'AddForm',
    component: () => import(/* webpackChunkName: "AddForm" */ '@views/TransicaoEscolaTrabalho/AddForm'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/unidades',
    name: 'CampoListaUnidadesEducacionais',
    component: () => import(/* webpackChunkName: "CampoListaUnidadesEducacionais" */ '@views/Dados/Lists/CampoListaUnidadesEducacionais'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/unidades/:idUnidade/turmas',
    name: 'CampoListaTurmas',
    component: () => import(/* webpackChunkName: "CampoListaTurmas" */ '@views/Dados/Lists/CampoListaTurmas'),
    meta: { requiresLogin: true }
  },

  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/unidades/:idUnidade/professores',
    name: 'CampoListaProfessores',
    component: () => import(/* webpackChunkName: "CampoListaProfessores" */ '@views/Dados/Lists/CampoListaProfessores'),
    meta: { requiresLogin: true }
  },

  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/unidades/:idUnidade/turmas/:idTurma/estudantes',
    name: 'CampoListaEstudantes',
    component: () => import(/* webpackChunkName: "CampoListaEstudantes" */ '@views/Dados/Lists/CampoListaEstudantes'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/empresas',
    name: 'CampoListaEmpresas',
    component: () => import(/* webpackChunkName: "CampoListaEmpresas" */ '@views/Dados/Lists/CampoListaEmpresas'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/empresas/:idEmpresa/empregadores',
    name: 'CampoListaEmpregadores',
    component: () => import(/* webpackChunkName: "CampoListaEmpregadores" */ '@views/Dados/Lists/CampoListaEmpregadores'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/campos/:idCampo/empresas/:idEmpresa/empregadores/:idEmpregador/jovens',
    name: 'CampoListaJovens',
    component: () => import(/* webpackChunkName: "CampoListaJovens" */ '@views/Dados/Lists/CampoListaJovens'),
    meta: { requiresLogin: true }
  },

  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/aplicacoes/campos',
    name: 'AplicacoesListaCampos',
    component: () => import(/* webpackChunkName: "AplicacoesListaCampos" */ '@views/Dados/Lists/AplicacoesListaCampos'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/aplicacoes/campos/:idCampoAplicacoes',
    name: 'ListarAplicacoes',
    component: () => import(/* webpackChunkName: "ListarAplicacoes" */ '@views/Dados/Lists/ListarAplicacoes'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/devolutivas',
    name: 'ListarDevolutivas',
    component: () => import(/* webpackChunkName: "ListarDevolutivas" */ '@views/Dados/Lists/ListarDevolutivas'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/instituicao/:idInstituicao/csv',
    name: 'ListarCSV',
    component: () => import(/* webpackChunkName: "ListarCSV" */ '@views/Dados/Lists/ListarCSV'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/grupos',
    name: 'GerenciarGrupos',
    component: () => import(/* webpackChunkName: "GerenciarGrupos" */ '@views/Dados/GerenciarGrupos'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/grupos/:idGrupo',
    name: 'GrupoMenu',
    component: () => import(/* webpackChunkName: "GrupoMenu" */ '@views/Dados/GrupoMenu'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/grupos/:idGrupo/instituicao/:idInstituicao',
    name: 'GrupoSubInsti',
    component: () => import(/* webpackChunkName: "GrupoSubInsti" */ '@views/Dados/GrupoSubInsti'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/grupos/:idGrupo/membros',
    name: 'GrupoMembros',
    component: () => import(/* webpackChunkName: "GrupoMembros" */ '@views/Dados/GrupoMembros'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/grupos/:idGrupo/cadastros/instituicoes',
    name: 'GrupoInstituicoes',
    component: () => import(/* webpackChunkName: "GrupoInstituicoes" */ '@views/Dados/GrupoInstituicoes'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/grupos/:idGrupo/devolutivas',
    name: 'GrupoDevolutivas',
    component: () => import(/* webpackChunkName: "GrupoDevolutivas" */ '@views/Dados/Lists/GrupoDevolutivas'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/gerenciar/grupos/:idGrupo/csv',
    name: 'GrupoCSV',
    component: () => import(/* webpackChunkName: "GrupoCSV" */ '@views/Dados/Lists/GrupoCSV'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/baixardadosnacionais',
    name: 'BaixarDadosNacionais',
    component: () => import(/* webpackChunkName: "BaixarDadosNacionais" */ '@views/Dados/BaixarDadosNacionais')
  },
  {
    path: '/dados/exportardevolutiva',
    name: 'ExportarDevolutiva',
    component: () => import(/* webpackChunkName: "ExportarDevolutiva" */ '@views/Dados/ExportarDevolutiva')
  },

  {
    path: '/materiaisedocumentos/avaliacao',
    name: 'Avaliacao',
    component: () => import(/* webpackChunkName: "Avaliacao" */ '@views/MateriaiseDocumentos/Avaliacao')
  },
  {
    path: '/materiaisedocumentos/instrumento',
    name: 'Instrumento',
    component: () => import(/* webpackChunkName: "Instrumento" */ '@views/MateriaiseDocumentos/Instrumento')
  },
  {
    path: '/materiaisedocumentos/treinamento',
    name: 'MaterialTreinamento',
    component: () => import(/* webpackChunkName: "MaterialTreinamento" */ '@views/MateriaiseDocumentos/MaterialTreinamento')
  },
  {
    path: '/materiaisedocumentos/leitura',
    name: 'MaterialLeitura',
    component: () => import(/* webpackChunkName: "MaterialLeitura" */ '@views/MateriaiseDocumentos/MaterialLeitura')
  },
  {
    path: '/materiaisedocumentos/realizadas',
    name: 'AvaliacoesRealizadas',
    component: () => import(/* webpackChunkName: "AvaliacoesRealizadas" */ '@views/MateriaiseDocumentos/AvaliacoesRealizadas')
  },
  {
    path: '/materiaisedocumentos/oquee',
    name: 'MateriaisOQueE',
    component: () => import(/* webpackChunkName: "MateriaisOQueE" */ '@views/MateriaiseDocumentos/OQueE')
  },
  {
    path: '/materiaisedocumentos/materiais',
    name: 'Materiais',
    component: () => import(/* webpackChunkName: "Materiais" */ '@views/MateriaiseDocumentos/Materiais')
  },
  {
    path: '/materiaisedocumentos/apresentacao',
    name: 'Apresentacao',
    component: () => import(/* webpackChunkName: "Apresentacao" */ '@views/MateriaiseDocumentos/Apresentacao')
  },
  {
    path: '/materiaisedocumentos/videostreino',
    name: 'VideosTreino',
    component: () => import(/* webpackChunkName: "VideosTreino" */ '@views/MateriaiseDocumentos/VideosTreino')
  },
  {
    path: '/materiaisedocumentos/campo',
    name: 'MaterialCampo',
    component: () => import(/* webpackChunkName: "MaterialCampo" */ '@views/MateriaiseDocumentos/MaterialCampo')
  },

  {
    path: '/boaspraticas/oquee',
    name: 'OqueE',
    component: () => import(/* webpackChunkName: "OQueE" */ '../views/BoasPraticas/OqueE')
  },
  {
    path: '/boaspraticas/qualidade',
    name: 'Qualidade',
    component: () => import(/* webpackChunkName: "Qualidade" */ '../views/BoasPraticas/Qualidade')
  },
  {
    path: '/boaspraticas/acervo',
    name: 'Acervo',
    component: () => import(/* webpackChunkName: "Acervo" */ '../views/BoasPraticas/Acervo')
  },
  {
    path: '/boaspraticas/:idPratica',
    name: 'Pratica',
    component: () => import(/* webpackChunkName: "Pratica" */ '../views/BoasPraticas/Pratica')
  },
  {
    path: '/boaspraticas/docoficiais',
    name: 'PraticasDocsOficiais',
    component: () => import(/* webpackChunkName: "PraticasDocOficiais" */ '@views/BoasPraticas/DocsOficiais')
  },

  {
    path: '/geradorformulario',
    name: 'GeradorFormulario',
    component: () => import(/* webpackChunkName: "GeradorFormulario" */ '@views/GeradorDeFormularios/GeradorFormularios'),
    meta: { requiresLogin: true }
  },
  {
    path: '/dados/permissao',
    name: 'SaibaMaisPermissoes',
    component: () => import(/* webpackChunkName: "GeradorFormulario" */ '@views/Dados/SaibaMaisPermissoes'),
    meta: { requiresLogin: true }
  },
  {
    path: '/faq',
    name: 'FAQ',
    component: () => import(/* webpackChunkName: "GeradorFormulario" */ '@views/FAQ'),
    meta: { requiresLogin: false }
  },

  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "RedefinirSenha" */ '@views/ResetPassword'),
    meta: { requiresLogin: false }
  },
  {
    path: '/finishregistration',
    name: 'FinishRegistration',
    component: () => import(/* webpackChunkName: "RedefinirSenha" */ '../views/FinishRegistration'),
    meta: { requiresLogin: false }
  },

  {
    path: '/confirmaccount',
    name: 'ConfirmAccount',
    component: () => import(/* webpackChunkName: "VerifyMail" */ '../views/UserConfirmation'),
    meta: { requiresLogin: false }
  },

  {
    path: '/boaspraticas/oquee',
    name: 'BoasPraticas_OQueE',
    component: () => import(/* webpackChunkName: "BoasPraticas_OQueE" */ '../views/BoasPraticas/OqueE.vue'),
    meta: { requiresLogin: false }
  },

  {
    path: '/boaspraticas/acervo',
    name: 'BoasPraticas_Acervo',
    component: () => import(/* webpackChunkName: "BoasPraticas_Acervo" */ '../views/BoasPraticas/Acervo'),
    meta: { requiresLogin: false }
  },

  {
    path: '/boaspraticas/:idPratica',
    name: 'Pratica',
    component: () => import(/* webpackChunkName: "Pratica" */ '@views/BoasPraticas/Pratica'),
    meta: { requiresLogin: false }
  },

  {
    path: '/cursogestores',
    name: 'Gestores',
    component: () => import(/* webpackChunkName: "CursoGestores" */ '@views/CursoGestores/EmBreve'),
  },

  {
    path: '/transicao/:cypher',
    name: 'TransicaoEscolaTrabalho',
    component: () => import(/* webpackChunkName: "TransicaoEscolaTrabalho" */ '@views/TransicaoEscolaTrabalho/Form'),
  },

  //--------------------------------------------
  //ROTA 404 (DEVE SER SEMPRE A ULTIMA DA LISTA)
  {
    path: '/:catchAll(.*)',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "Error404" */ '@views/Error404')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin) && store.getters.getIsLogged == false) {
    store.commit("setDoNotHavePermissionLogin", true) // TODO! VERIFICAR O ESTADO PARA DAR UM ALERTA DE FALTA DE PERMISSAO
    next("/");
  } else {
    next();
  }
});

export default router;
