<template>
<form @submit.prevent="createInstitution">
    <div class="container offset">
        <div class="custom-form">

            <div class="form-group">
                <label class="question-style pt-1">Nome: *</label>
                <input
                    v-model.trim="nome"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o nome da instituição"
                    required
                    />
            </div>
            <div class="form-group">
                <label class="question-style">Descrição:</label>
                <textarea
                    v-model.trim="descricao"
                    row=5
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui a descrição da instituição"
                    ></textarea>
            </div>
            <div class="form-group">
                <label class="question-style">Órgão:</label>
                <input
                    v-model.trim="orgao"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o órgão responsável pela instituição"
                    />
            </div>

            <div class="d-flex justify-content-start align-items-center">
                <div class="mr-4">
                    <label for="estado">Estado: *</label>
                    <select @change="getCities()" v-model="estado" class="form-control" id="estado" required>
                        <option value="AC">Acre</option>
                        <option value="AL">Alagoas</option>
                        <option value="AP">Amapá</option>
                        <option value="AM">Amazonas</option>
                        <option value="BA">Bahia</option>
                        <option value="CE">Ceará</option>
                        <option value="DF">Distrito Federal</option>
                        <option value="ES">Espírito Santo</option>
                        <option value="GO">Goiás</option>
                        <option value="MA">Maranhão</option>
                        <option value="MT">Mato Grosso</option>
                        <option value="MS">Mato Grosso do Sul</option>
                        <option value="MG">Minas Gerais</option>
                        <option value="PA">Pará</option>
                        <option value="PB">Paraíba</option>
                        <option value="PR">Paraná</option>
                        <option value="PE">Pernambuco</option>
                        <option value="PI">Piauí</option>
                        <option value="RJ">Rio de Janeiro</option>
                        <option value="RN">Rio Grande do Norte</option>
                        <option value="RS">Rio Grande do Sul</option>
                        <option value="RO">Rondônia</option>
                        <option value="RR">Roraima</option>
                        <option value="SC">Santa Catarina</option>
                        <option value="SP">São Paulo</option>
                        <option value="SE">Sergipe</option>
                        <option value="TO">Tocantins</option>
                    </select>
                </div>

                <div>
                    <label class="text-align-left" for="cidade">Cidade: *</label>
          <select v-model="cidade" class="form-control" id="cidade" required>
            <option v-for="city in cidades" :key="city.idCidade" :value="city.idCidade">{{city.nome}}</option>
          </select>
        </div>
      </div>

    </div>

    <div class="input-file">
      <label class="question-style">Imagem da Instituição:</label>
      <ImageInput :img="arquivo" ref="imgInput" />
    </div>

    <div class="button-div pt-4">
      <Button
        section="dados-outline"
        value="Criar Instituição"
        :isOutline="true"
        :hasIcon="true"
        iconName="save"
        />
    </div>
  </div>
</form>
</template>

<script>

import Button from '@components/Button'
import Swal from "sweetalert2";
import ImageInput from "@components/ImageInput";
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: 'left',
        y: 'bottom',
    }
})

export default {
    name: "CreateInstitution",
    data() {
        return {
            nome: '',
            descricao: '',
            orgao: '',
            id_cidade: '',
            imagem: '',
            cidade: "",
            estado: "",
            label_button: 'Fazer Upload',
            isTurningCog: false,
            arquivo: null,
            isDragOver: false,
            name_image: "icone-upload",
            label: "Escolha ou arraste uma imagem"
        }
    },
    components: {
        Button,
        ImageInput,
    },
    computed: {
        cidades(){
            if (this.estado != '') {
                return this.$store.getters.getCidades;
            }
            else {
                return []
            }
        },
    },
    methods: {
        async getCities() {
            await this.$store.dispatch('setCidades', this.estado);
            this.cidade = ''
        },
        clearForm() {
            this.nome = ''
            this.descricao = ''
            this.orgao = ''
            this.id_cidade = ''
            this.imagem = ''
            this.cidade = ""
            this.estado = ""
            this.label_button = 'Fazer Upload'
            this.isTurningCog = false
            this.arquivo = null
            this.isDragOver = false
            this.name_image = "icone-upload"
            this.label = "Escolha ou arraste uma imagem"
        },

        async createInstitution(){
            const payload = {
                nome: this.nome,
                descricao: this.descricao,
                orgao: this.orgao,
                id_cidade: this.cidade,
                id_grupos: [],
                imagem: this.$refs.imgInput.getFile() || null,
            }
            await this.$store.dispatch('setCreateInstitution', payload)

            if (!this.$store.getters.getInstiIsCreated) {
                notyf.open({
                    type: 'error',
                    message: 'Não foi possível criar a instituição, tente novamente'
                });
                return;
            }

            await this.$store.dispatch('setListTeams')
            this.clearForm()
            this.$emit('closeModal')
            notyf.open({
                type: 'success',
                message: 'Instituição criada com sucesso!'
            });
        },

        // METODOS DO FILE INPUT IMAGE
        isDragOverActions: function() {
            this.isDragOver = true
            this.label = "Solte o arquivo aqui!"
            this.name_image = "icone-upload-happy"
        },
        isDragLeaveActions: function() {
            this.isDragOver = false
            this.label = "Clique ou arraste um arquivo para gerar a devolutiva"
            this.name_image = "icone-upload"
        },
        geraDevolutiva: function() {
            //método com funções de demonstração
            if (this.arquivo != null){

                this.label_button = 'Gerando Devolutiva...'
                this.isTurningCog = true  
                
                setTimeout( () => { 
                    this.label_button = 'Gerar Devolutiva'
                    this.isTurningCog = false
                    this.arquivo = null
                    this.$refs.uploadFile.value = ''
                    Swal.fire(
                        'Upload feito com sucesso!',
                        '',
                        'success'
                    )
                }, 3000)
            } 
            else {
                Swal.fire(
                    'Erro!',
                    'Você deve fazer o upload de uma imagem.',
                    'error'
                )   
            }
        },
        addFile: function(e) {
            let droppedFiles = e.dataTransfer.files;
            this.isDragLeaveActions()
            for (let f of droppedFiles) {
                if (f.type.split("/")[0] == "image") {
                    this.arquivo = f;
                    console.log(this.arquivo)
                }
                else {
                    Swal.fire(
                        'Erro!',
                        'Só é permitido arquivos de imagem.',
                        'error'
                    )
                }
            }     
        },
        addFileViaInput: function() {
            let droppedFiles = this.$refs.uploadFile.files;
            for (let f of droppedFiles) {
                if (f.type.split("/")[0] == "image") {
                    this.arquivo = f;
                }
                else {
                    Swal.fire(
                        'Erro!',
                        'Só é permitido arquivos de imagem.',
                        'error'
                    )
                }
            }  
        },
        removeFile(){
            this.arquivo = null; 
        },
        openDialog: function() {
            this.$refs.uploadFile.value = ''
            this.$refs.uploadFile.click() 
        }
    },
    
}

</script>

<style scoped lang="scss">
@import "../../assets/scss/_variables.scss";

    .container{
        width: 100%;
        background-color: white;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;        
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .image-style{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding: 1rem;
    }

    .button-div{
        text-align: right;
    }

    /* INPUT FILE STYLES */

    .input-file {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 40vh;
    position: relative;
}

.input-file input {
    max-width: 30rem;
}

.input-file h1 {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: $verdeEscuro-eapi;
}

.input-file button {
    border: 1px solid $verdeEscuro-eapi;
    color: $verdeEscuro-eapi;
    background-color: white;
    border-radius: 4px;
    padding: 0.4rem 1.2rem 0.4rem 0.4rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: all ease-in-out 0.2s;
    -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    box-shadow: 6px 6px 5px -1px rgba(0,0,0,0.2);
}

.input-file button:hover {
    color: white; 
    background-color: $verdeEscuro-eapi;
}

.drag-and-drop {
    height: 14rem;
    width: 27rem;
    border: 4px dashed $verdeEscuro-eapi;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 0 1.5rem;
    margin: 0 2rem;
}

.drag-and-drop img {
    max-height: 5rem;
}

.drag-and-drop p {
    color: #656565;
    font-weight: 400;
    user-select: none;
}

.drag-style {
    filter: blur(0.7px);
}

.file-uploaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -2.5rem;
}

.animateCog {
    animation: cogAnimation linear infinite 1s;
}

.infos {
    pointer-events: none;
}

.input-file .remove-file {
    border: 1px solid #fd5454;
    color: #fd5454;
    padding: 0.4rem;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    position: absolute;
    bottom: 24%;
}

.input-file .remove-file:hover {
    color: white; 
    background-color: #fd5454;
}

@keyframes cogAnimation {
    to {
        transform: rotate(360deg);
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 728px) {
    .drag-and-drop{
        width: auto;
        margin: 0 1rem;
    }
}

.img-icon-uploaded{
    max-height: 17% !important;
    width: auto;
}

</style>
