<template>

    <div class="root-dados">
        <div class="container list-data">

            <ModalCentral
                componentName="AddEducationalUnitByFileAmostra"
                title="ADICIONAR UNIDADES EDUCACIONAIS NA AMOSTRA"
                idName="AddUEbyFile"
            />

            <ModalCentral
                componentName="UpdateUE"
                title="Atualizar UE"
                idName="UpdateUE"
                :data="{
                    idue: editing,
                }"
            />

            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-campo"/>
            <br/>

            <div class="title-row" style="justify-content: flex-start; gap: 10px;">
                <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todas as Unidades Educacionais relacionadas ao Campo. Caso necessário, utilize o filtro abaixo para localizar a Unidade Educacional desejada. Para visualizar as Turmas pertencentes a cada Unidade Educacional, clique no botão "Ver UE".</p>

            <template v-if="userPerm.permissao != 'APLICADOR'">
                <div class="lista-data-info">
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="setSelectAllItems"
                        class="btn ml-2 btnCtrl greenGradient"
                        value="Marcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check-square"
                        style="height: min-content;"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="setUnselectAllItems"
                        class="btn ml-2 btnCtrl greenGradient"
                        value="Desmarcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="square"
                        style="height: min-content;"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="applyChanges"
                        class="btn ml-2 btnCtrl greenGradient"
                        value="Aplicar Alterações"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                        style="height: min-content;"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        class="btn ml-2 btnCtrl greenGradient"
                        value="Importar Lista de UE"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="clipboard-list"
                        data-toggle="modal"
                        data-target="#AddUEbyFile"
                        style="height: min-content;"
                    />

                    <Button
                        :disabled="baixando"
                        v-if="userPerm.permissao != 'APLICADOR'"
                        class="btn ml-2 btnCtrl greenGradient"
                        value="Baixar dados"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="download"
                        @click="downloadInfo"
                    />
                </div>
                <br />
            </template>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar Unidades Educacionais por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS MEMBROS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnts
                    term="unidades"
                    :totalQnt="totalUnidades"
                    :filteredQnt="unidades_filtered?.length || 0"
                    icon="graduation-cap" />

                <transition-group v-if="unidades_filtered?.length > 0" name="slide-fade">
                    <div class = "table-wrap">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">  </th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Município</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <ListagemElements :infos="membros" listaType="addMembros"/>  -->
                                <tr class="table-row" v-for="unidade_educacional in unidades_filtered" :key="unidade_educacional.counter">

                                    <td>
                                        <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-ue.svg" alt="">
                                    </td>

                                    <td> {{unidade_educacional.nome}} </td>

                                    <td> {{unidade_educacional.cidade}} </td>

                                    <td>
                                        <div class="controlGroup">

                                            <router-link :to="{name: 'CampoListaTurmas', params: { idUnidade: unidade_educacional.idue }}" class="btn-router-link">
                                                <Button
                                                    value="Ver UE"
                                                    class="btnLista greenGradient"
                                                    section="instituicao-campo"
                                                    :isOutline="true"
                                                />
                                            </router-link>

                                            <router-link :to="{name: 'CampoListaProfessores', params: { idUnidade: unidade_educacional.idue }}" class="btn-router-link">
                                                <Button
                                                    value="Gerenciar professores"
                                                    class="btnLista greenGradient"
                                                    section="instituicao-campo"
                                                    :isOutline="true"
                                                />
                                            </router-link>

                                            <Button
                                                v-if="userPerm.idPermissao != '1' && !unidade_educacional.isEditionLocked && campoStatus != 'OFF'"
                                                @click="editing = unidade_educacional.idue"
                                                value="Editar UE"
                                                class="btnLista greenGradient"
                                                section="instituicao-campo"
                                                :isOutline="true"
                                                data-toggle="modal"
                                                data-target="#UpdateUE"
                                            />

                                            <Button
                                                v-if="userPerm.idPermissao != '1' && !unidade_educacional.isDeletionLocked && campoStatus != 'OFF'"
                                                @click="deleteUe(unidade_educacional.idue)"
                                                value="Excluir UE"
                                                :has-icon="true"
                                                iconName="trash"
                                                class="btnLista greenGradient"
                                                section="instituicao-campo"
                                                :isOutline="true"
                                            />

                                            <div v-if="userPerm.idPermissao != '1'" style="word-break: keep-all !important;" :class="campoStatus == 'OFF'? 'not-clickable' : ''">
                                                <span v-if="unidade_educacional.isLocked">Selecionado</span>
                                                <div v-else>
                                                    <div @click="setIsSelected(unidade_educacional.idue)" class="select-info">
                                                        <div :class="['box-select-info mr-2 ',{'selected-info': unidade_educacional.isSelect.isSelected}]"></div>
                                                        <span class="span-label-selected" v-if="unidade_educacional.isSelect.isSelected">Selecionado</span>
                                                        <span class="span-label-selected" v-else>Selecionar</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style-box-lista" key="educationalUnitKey">Nenhuma Unidade Educacional encontrada.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import Swal from 'sweetalert2';
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import Amostra from '@middleware/amostra.js'
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });
 import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
             baixando: false,
             editing: null,
             nomeCampo: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             teste: [],
             unidades_amostra: [],
             unidades: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: 'ListarCampos'
                 },
                 {
                     id:4,
                     value: 'Gerenciar Campo',
                     iconName: 'chart-pie',
                     name_route: 'InstituicaoMenuCampo'
                 },
                 {
                     id:5,
                     value: 'Unidades Educacionais',
                     iconName: 'graduation-cap',
                     name_route: ''
                 }
             ]
         }
     },
     watch: {
         rawListCampoUEs() { this.setData(); },
         rawListUE() { this.setData(); },
     },
     computed: {
         campoStatus() {
             return this.$store.getters.getAmostraStatusAtiva
         },
         userPerm() {
             return this.$store.getters.getUserPerm
         },
         totalUnidades() {
             return this.unidades?.length || 0;
         },
         unidades_filtered() {
             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = this.unidades || [];
             if (this.searchByName.trim() != '') {
                 var filtered = this.unidades?.filter(unidade =>unidade.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                 if(filtered?.length != 0){
                     query = filtered
                 }

                 else{
                     query = false
                 }
             }
             return query;
         },
         rawListUE() {
             return this.$store.getters.getListUE;
         },
         rawListCampoUEs() {
             return this.$store.getters.getListCampoUEs;
         }
     },
     async created() {
         this.campos = this.$store.getters.getListCampos

         this.isLoadedInfo = false
         this.loadData()

         const payload = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setUserPerm', payload)

         await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

         const all_campos = this.$store.getters.getListCampos
         const campo = all_campos.filter((value) => {
             return value.idamostra == this.$route.params.idCampo
         }).map((value) => {return value.isativo})

         const campoStatus = campo[0]

         this.$store.commit('setAmostraStatusAtiva', campoStatus)
     },
     components: {
         Button,
         Breadcrumb,
         InstitutionInfo,
         ModalCentral,
         SkeletonList,
         MemberQnts,
     },
     methods: {
         async deleteUe(idue) {
           const result = await Swal.fire({
             title: "Tem certeza que deseja excluir essa Unidade Educacional?",
             text: "Essa ação não poderá ser revertida.",
             icon: "question",
             showCancelButton: true,
             confirmButtonColor: "#3085d6",
             cancelButtonColor: "#d33",
             cancelButtonText: "Cancelar",
             confirmButtonText: "Sim",
           });

           if (!result.isConfirmed) return;

           await this.$store.dispatch("deleteUE", {
             idInstituicao: this.$route.params.idInstituicao,
             idUE: idue,
           });

           if (!this.$store.getters.getIsUEDeleted) {
             notyf.open({
               type: "error",
               message: "Erro ao excluir!",
             });
           }

           notyf.open({
             type: "success",
             message: "Unidade excluída com sucesso",
           });

           this.loadData();
         },
         async downloadInfo() {
             if (this.baixando) return;

             this.baixando = true;

             const notiLoad = notyf.open({
                 type: "info",
                 background: "var(--light-green)",
                 message: "Carregando dados...",
                 duration: 0,
             });

             this.$store
                 .dispatch("getAmostraFile", {
                     idInstituicao: this.$route.params.idInstituicao,
                     idAmostra: this.$route.params.idCampo,
                 })
                 .then(response => {
                     const url = window.URL.createObjectURL(new Blob([response.data]));
                     const link = document.createElement('a');
                     link.href = url;
                     link.setAttribute('download', 'info.csv');
                     document.body.appendChild(link);
                     link.click();
                 })
                 .catch(err => {
                     console.log(err);
                     notyf.open({
                         type: "error",
                         message: "Erro ao obter informações da instituição",
                     });
                 })
                 .finally(() => {
                     notyf.dismiss(notiLoad);
                     this.baixando = false;
                 });
         },
         setIsSelected(id) {
             this.unidades = Amostra.setIsSelectedItem(this.unidades, 'unidade', id)
         },
         setSelectAllItems() {
             this.unidades = Amostra.setSelectAllItems(this.unidades, 'selectUnidades')

             Amostra.setAllSelectedItems(this.unidades, 'unidade')
         },
         setUnselectAllItems() {
             this.unidades = Amostra.setUnselectAllItems(this.unidades, 'selectUnidades')

             Amostra.setAllSelectedItems(this.unidades, 'unidade')
         },
         setData() {
             this.unidades = [ ...(this.rawListUE || []) ];
             this.unidades_amostra = [ ...(this.rawListCampoUEs || []) ];

             this.unidades = this.unidades.map(unidade => {
                 unidade.isLocked = this.unidades_amostra.find(unidade_amostra => unidade_amostra.idue == unidade.idue)?.isLocked || false;
                 unidade.isSelect = {
                     isSelected: this.unidades_amostra.some(unidade_amostra => unidade_amostra.idue == unidade.idue),
                     isSaved: true
                 };
                 return unidade;
             });

             this.isLoadedInfo = true
         },
         async loadData() {
             const payload = {
                 idInstituicao: this.$route.params.idInstituicao,
                 idAmostra: this.$route.params.idCampo
             }
             const payload2 = {
                 idInstituicao: this.$route.params.idInstituicao
             }

             await this.$store.dispatch('setListCampoUEs', payload)
             await this.$store.dispatch('setListUE', payload2)
             this.setData();
         },
         async applyChanges() {
            
            const result = await Swal.fire({
                title: "Todas as Turmas pertencentes as Unidades Educacionais selecionadas serão adicionadas ao campo, deseja prosseguir?",
                text: (this.$store.getters.getAmostraStatusAtiva == 'ON' || this.$store.getters.getAmostraStatusAtiva == 'ATIVO') ? 
                "Você ainda poderá modificar os elementos internos da Unidade Educacional desde que esta não esteja relacionada à uma aplicação." : 
                "Você ainda poderá modificar os elementos um por um ao acessar a Unidade Educacional.",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim",
            })

            if (result.isConfirmed) {
                Amostra.setAllSelectedItems(this.unidades, 'unidade')
                const payloadManageUnidades = this.$store.getters.getPayloadManageUnidades
                Amostra.setAllUnselectedItems(this.unidades, 'unidade')
                const payloadUnselectedUnidades = this.$store.getters.getPayloadUnselectedUnidades;
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idAmostra: this.$route.params.idCampo,
                    addIds: JSON.parse(JSON.stringify(payloadManageUnidades)),
                    removeIds: JSON.parse(JSON.stringify(payloadUnselectedUnidades)),
                    ids: JSON.parse(JSON.stringify(payloadManageUnidades))
                }
                await this.$store.dispatch('setManageUnidades', payload)

                this.loadData()
                Swal.fire(
                    'Alterações aplicadas!',
                    'As seleções foram aplicadas com sucesso.',
                    'success'
                )
            }
         },
         setSelectedValue: function() {
             this.selectedValue = this.$refs.filtersearchname.value
         }
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idCampo

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome do Campo"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.campos.filter(team =>
                 team.idamostra == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeCampo = teamSelected.titulo
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     color: #fcfcfc;
 }


 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }
 }

 .list-item-selected-pink {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.webp");
 }

 .active-img {
     filter: brightness(0) invert(1) contrast(0.9);
 }

.not-clickable{
    cursor: not-allowed;
}

.not-clickable > div{
    pointer-events: none;
}
</style>
