//---------------------------------------------
// Instituicao Module 
//---------------------------------------------
// Módulo para gerenciar as ações da instituição

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
// var qs = require('qs');
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listTeams: [],
    listAllTeams: [],
    isInstiCreated: null,
    isInstiUpdated: null,

    deleteInstiErr: null,
    deleteInstiCode: null,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DO LISTTEAMS
    setListTeams(state, payload) {
        state.listTeams = payload
    },

    //-- MUTATION REQUISICAO DE TODAS AS INSTITUICOES
    setListAllTeams(state, payload) {
        state.listAllTeams = payload
    },

    setInstiCreated(state, payload) {
        state.isInstiCreated = payload;
    },

    setInstiUpdated(state, payload) {
        state.isInstiUpdated = payload;
    },

    setDeleteInstiErr(state, err) { state.deleteInstiErr = err },
    setDeleteInstiCode(state, code) { state.deleteInstiCode = code },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DE CRIAR INSTITUIÇÃO
    async setCreateInstitution({ commit }, payload) {
        var formData = new FormData();

        formData.append("nome", payload.nome);
        if (payload.descricao) formData.append("descricao", payload.descricao);
        formData.append("orgao", payload.orgao);
        formData.append("id_cidade", payload.id_cidade);

        if (payload.id_grupos.length > 0) {
            formData.append("id_grupos", payload.id_grupos);
        }
        if (payload.imagem) {
            formData.append("imagem", payload.imagem)
        }

        await axios
            .post(`insti/`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .then(() => {
                commit('setInstiCreated', true);
            })
            .catch((error) => {
                commit('setInstiCreated', false);
                console.log(error.message);
            })
    },

    async setUpdateInstitution({ commit }, payload) {
        var formData = new FormData();

        formData.append("descricao", payload.descricao);
        formData.append("orgao", payload.orgao);
        formData.append("updateImg", payload.updateImg);

        if (payload?.id_grupos?.length > 0) {
            formData.append("id_grupos", payload?.id_grupos);
        }
        if (payload.imagem) {
            formData.append("imagem", payload.imagem)
        }

        await axios
            .put(`insti/${payload.idInsti}`,
                formData,
                {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .then(() => {
                commit('setInstiUpdated', true);
            })
            .catch((error) => {
                commit('setInstiUpdated', false);
                console.log(error.message);
            })
    },

    //-- ACTION REQUISICAO DO LISTTEAMS
    async setListTeams({ commit }) {
        await axios
            .get(`insti/listUserInstis`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListTeams', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO DE TODAS AS INSTITUICOES
    async setListAllTeams({ commit }) {
        await axios
            .get(`insti`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListAllTeams', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE INSTITUIÇÃO
    async deleteInstitution({ commit }, payload) {
        await axios
            .delete(`insti/${payload.idInstituicao}`, {
                data: {
                    justificativa: payload.justificativa
                },
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => {
                commit('setDeleteInstiErr', false)
                commit('setDeleteInstiCode', 200)
            })
            .catch((error) => {
                console.log(error)
                commit('setDeleteInstiErr', error?.response?.data?.message?.message)
                commit('setDeleteInstiCode', error?.response?.data?.code)
            })
    },

    getInstiFile(_, idInstituicao) {
        return axios.get(`insti/instiEntities/${idInstituicao}`, {
            headers: {
                'Authorization': `Bearer ${JWT()}`
            }
        });
    },
}

// GETTERS
const getters = {
    getListTeams(state) {
        try {
            return state.listTeams.data
        } catch (e) {
            return []
        }
    },

    getListAllTeams(state) {
        try {
            return state.listAllTeams.data
        } catch (e) {
            return []
        }
    },

    getInstiIsCreated(state) {
        return state.isInstiCreated;
    },

    getInstiIsUpdated(state) {
        return state.isInstiUpdated;
    },

    getDeleteInstiErr(state) { return state.deleteInstiErr },
    getDeleteInstiCode(state) { return state.deleteInstiCode },
}

export default {
    state,
    mutations,
    actions,
    getters
}
