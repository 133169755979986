<!--
|---------------------------------------------
| BreadCrumb Component 
|---------------------------------------------
| @usage Import the component and use <Breadcrumb crumb='Array()' section='Section Name'/>
| 
-->

<template>
    <div id="crumbs" :class="{ 'hidden': borderSize === 0 }">
        <ul :class="section">
            <li v-for="(crumb, idx) in crumbs" :key="crumb.id">
                <router-link :to="{name: crumb.name_route}" :ref="`crumb${idx}`">
                        <i :class="'fa fa-'+crumb.iconName" aria-hidden="true" />{{' '+crumb.value}}
                </router-link>
            </li>
        </ul>
    </div>
</template>


<script>
 export default {
     name: "BreadCrumbs",
     props: {
         crumbs: Object,
         section: String,
     },
     data() {
         return {
             refs: {},
         };
     },
     mounted() {
         this.updateRefs();
     },
     methods: {
         updateRefs() {
             this.refs = this.$refs;
         },
         borderSize(idx) {
             return Math.floor(this.refs[`crumb${idx}`]?.$el?.clientHeight/2.0) || 0;
         }
     },
 }
</script>


<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
$crumbs-back:#FFFFFF;
$text-color:#00853B;
#crumbs {
    margin: 0 auto;
    transition: none;
    transition-delay: 0.5s;
    padding: 0;
    a{
        font-size: 1rem;
        font-weight: 600;
        letter-spacing:.01rem;
        color: $text-color;
    }
    ul {
        list-style: none;
        display: inline-flex;
        padding: 0;
        align-items: stretch;
        flex-wrap: wrap;
        row-gap: 5px;
        li {
            //flex: 1 0 min-content;
            max-width: max-content;
            height: auto;
            a {
                display: block;
                height: 100%;
                background: white;
            
                text-align: center;
                padding: 10px 20px 10px 20px;
                position: relative;
                margin: 0 30px 0 0; 
                
                text-decoration: none;
                color: $text-color;
                -webkit-box-shadow: 10px 10px 29px -18px rgba(0,0,0,0.75);
                -moz-box-shadow: 10px 10px 29px -18px rgba(0,0,0,0.75);
                box-shadow: 10px 10px 29px -18px rgba(0,0,0,0.75);

                user-select: none;

                &:after {
                    content: "";  
                    border-top: 23.25px solid transparent;
                    border-bottom: 23.25px solid transparent;
                    border-left: 20px solid white;
                    position: absolute; 
                    right: -19px;
                    top: 0;
                    z-index: 1;
                }
                &:before {
                    content: "";  
                    border-top: 23.25px solid transparent;
                    border-bottom: 23.25px solid transparent;
                    border-left: 20px solid #40a46c;
                    position: absolute; 
                    content: "";
                    z-index: 1;
                    left: 0; 
                    top: 0;
                }
            }
        }
    } /* end of ul */
} /*end of crumb */

#crumbs ul li:first-child a {
    border-top-left-radius: 10px; border-bottom-left-radius: 10px;
}
#crumbs ul li:first-child a:before {
    display: none; 
}

/* Crumb last element style */

#crumbs ul li:last-child a {
    padding-right: 40px;
    border-top-right-radius: 10px; border-bottom-right-radius: 10px;
}
#crumbs ul li:last-child a:after {
    display: none; 
}   

#crumbs ul li:not(:last-child) a, 
#crumbs ul li:not(:last-child) a:after {
    transition: all 0.3s;
}

/* Utilizar um esquema de cores padrão para toda a aplicação */

#crumbs li:not(:first-child) a i{
    margin-left: 1em;
}

#crumbs li:not(:last-child) a:hover {
    background: rgb(25, 101, 85);
    color:#fff;
    cursor: pointer;
    filter: brightness(1) !important;
}

#crumbs li a:hover:after{
    border-left-color: rgb(25, 101, 85);
    border-right-color: #40a46c;
}

#crumbs li:last-child a:hover{
    border-right-color: #40a46c;
    filter: brightness(1);
}

#crumbs li:last-child a {
    background: #196555;
    color:#fff;
    cursor: default;
} 


</style>
