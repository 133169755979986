import axios from '@middleware/api.js';
import Cookies from 'js-cookie';
const JWT = () => {
  return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO");
};

const state = () => ({
  listJovens: null,
  isJovemCreated: null,
  jovemCreationError: null,
  isJovemUpdated: null,
  jovemUpdateError: null,
  isJovemRemoved: null,
});

const mutations = {
  setListJovens: ( state, list ) => state.listJovens = list,
  setIsJovemCreated: ( state, v ) => state.isJovemCreated = v,
  setCreationError(state,v){state.jovemCreationError = v},
  setIsJovemUpdated: ( state, v ) => state.isJovemUpdated = v,
  setUpdateError(state,v){state.jovemUpdateError = v},
  setIsJovemRemoved: ( state, v ) => state.isJovemRemoved = v,
};

const actions = {
  async loadListJovens({ commit }, payload) {
    commit('setListJovens', []);

    await axios
      .get(`jovem/${payload.idInstituicao}/${payload.idEmpregador}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        },
      })
      .then(response => commit('setListJovens', response.data?.data))
      .catch(err => {
        console.log(err);
        commit('setListJovens', null);
      });
  },

  async createJovem({ commit }, payload) {
    commit('setIsJovemCreated', null);

    await axios
      .post(
        `jovem/${payload.idInstituicao}/${payload.idEmpregador}`,
        payload.jovem,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => {
        commit('setIsJovemCreated', true);
        commit('setCreationError', null)
    })
      .catch((err) => {
        commit('setCreationError', err.response.data.code)
        commit('setIsJovemCreated', false);
      });
  },

  async updateJovem({ commit }, payload) {
    commit('setIsJovemCreated', null);

    await axios
      .put(
        `jovem/${payload.idInstituicao}/${payload.idEmpregador}/${payload.idJovem}`,
        payload.jovem,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => {
        commit('setIsJovemUpdated', true)
        commit('setUpdateError', null);
      })
      .catch((err) => {
        commit('setUpdateError', err.response.data.code);
        commit('setIsJovemUpdated', false);
      });
  },

  async removeJovem({ commit }, payload) {
    commit('setIsJovemRemoved', null);

    await axios
      .delete(
        `jovem/${payload.idInstituicao}/${payload.idEmpregador}/${payload.idJovem}`,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => commit('setIsJovemRemoved', true))
      .catch((error) => {
        console.log(error);
        commit('setIsJovemRemoved', false);
      });
  },
};

const getters = {
  getListJovens: (state) => state.listJovens,
  getIsJovemCreated: (state) => state.isJovemCreated,
  getJovemCreationError: (state) => state?.jovemCreationError,
  getIsJovemUpdated: (state) => state.isJovemUpdated,
  getJovemUpdateError: (state) => state?.jovemUpdateError,
  getIsJovemRemoved: (state) => state.isJovemRemoved,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
