<template>
    <ModalCentral
        componentName="GroupInvites"
        title="Convites do grupo"
        idName="idInvites"
        :data="{ idGrupo: idGrupo }"
    />

    <div class="bg">
        <div class="container">
            <div class="title-row">
                <h1 class="title-info">{{ group?.name || "Nome do grupo..." }}</h1>
                <div>
                    <Button
                        v-if="groupPerm?.idPermissao == 3"
                        class="btn mr-2 menuButton"
                        section="dados-outline"
                        value="Solicitações"
                        :isOutline="true"
                        :hasIcon="true"
                        data-target="#idInvites"
                        data-toggle="modal"
                        iconName="envelope" />
                </div>
            </div>

            <div class="cardsful-bg">
                <router-link :to="{name: 'GrupoMembros'}">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="search-plus"
                        title="Membros"
                        palette="blue-section"
                    />
                </router-link>

                <router-link :to="{name: 'GrupoInstituicoes'}">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="landmark"
                        title="Instituições"
                        palette="pink-section"
                    />
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
 import ColorfulCardsBG from '@components/ColorfulCardsBG';
 import Button from '@components/Button';
 import ModalCentral from '@components/ModalCentral';

 export default {
     data() {
         return {
             idGrupo: this.$route.params.idGrupo,
         };
     },
     components: {
         ColorfulCardsBG,
         Button,
         ModalCentral,
     },
     async created() {
         await this.$store.dispatch('setListGroups');
         this.$store.dispatch("groupPerm", { idGrupo: this.idGrupo })
     },
     computed: {
         group() {
             return this.$store.getters.getListGroups?.find(
                 g => g.idgrupo == this.$route.params.idGrupo
             );
         },
         groupPerm() {
             return this.$store.getters.getGroupPerm(this.idGrupo) || {}
         },
     },
 }

</script>

<style scoped>

 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .title-info {
     font-weight: 600;
     font-size: 1.6rem;
     color: #535353;
     margin: 0;
 }

 .cardsful-bg {
     display: flex;
     align-items: center;
     justify-content: center;
     flex-wrap: wrap;
 }

 .cardsful-bg a:hover {
     text-decoration: none;
 }

 .cardful-single {
     margin: 1rem;
 }

 .title-row {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }

 .menuButton {
     background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.webp");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
 }

 :deep(.menuButton div) {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }
</style>
