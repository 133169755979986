<template>
    <div>
        <Carousel/>
        <main class = "mt-5">
          <InfoGraphs/>
          <InfoCards/>
        </main>
    </div>
</template>

<script>
import Carousel from '@components/NewHome/Carousel'
import InfoGraphs from '@components/NewHome/InfoGraphs'
import InfoCards from '@components/NewHome/InfoCards'

/* Migrar componente para o template 'App.vue' quando possível */

/* Testando implementação de Modals */

export default {
  name: 'NewHome',

  props: {
    id: String,
    title: String,
    pdfLink: String
  },

  components: {
    Carousel,
    InfoCards,
    InfoGraphs,
    /* Testando implementação de Modals */
  }
}
</script>

<style scoped>
</style>
