<template>
<div class="container offset">
  <form @submit.prevent="uploadEgressos">
    <p class="text-justify">Para tornar mais eficiente o método de inserção dos egressos da sua instituição, nós criamos um método de inserção por arquivo CSV. Para isso, baixe o nosso arquivo de modelo e edite seguindo o mesmo padrão para adicionar os novos elementos.</p>
    <a href="/examples/egresso_data.csv" download="Modelo Egressos" target="_blank">Baixar planilha de modelo.</a>
    <br /><br />

    <div class="form-group">
      <label class="question-style pt-1">Arquivo: *</label>
      <FileInput ref="uploadInput" :types="['text/csv']" />
    </div>

    <div class="control">
      <div class="button-div pt-4">
        <Button
          section="dados-outline"
          value="Carregar dados"
          :isOutline="true"
          :hasIcon="true"
          iconName="save"
          :isDisabled="!hasFile" />
      </div>
    </div>
  </form>
</div>
</template>

<script>
import Button from '@components/Button'
import FileInput from '@components/FileInput';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
  components: {
    Button,
    FileInput,
  },
  data: () => ({
    fileTemp: null,
    hasFile: null,
  }),
  mounted() {
    this.$watch(
      () => this.$refs.uploadInput.getFile(),
      (newFileChange) => {
        this.hasFile = newFileChange;
      }
    );
  },
  methods: {
    async uploadEgressos() {
      const arq = this.$refs.uploadInput.getFile();
      if (!arq) return;

      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        file: arq,
      };
      if(this.$route.params.idCampo){
        payload['idAmostra'] = this.$route.params.idCampo;
      }
      await this.$store.dispatch('uploadEgressos', payload);

      if (this.$store.getters.getIsEgressosUploaded.success) {
        const payload2 = {
          idInstituicao: this.$route.params.idInstituicao
        };
        if(this.$route.params.idCampo){
          payload2['idAmostra'] = this.$route.params.idCampo;
          await this.$store.dispatch('loadListCampoEgressos', payload2)
        }
        await this.$store.dispatch('loadListEgressos', payload2);

        notyf.success("Egressos adicionados com sucesso!");
        this.$emit('closeModal');
        this.$refs.uploadInput.clearFile();

      } else {
        notyf.error(this.$store.getters.getIsEgressosUploaded.message);
        this.$refs.uploadInput.clearFile();
      }
    },
    clearForm() {},
  },
};
</script>

<style scoped>
.control {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
}
</style>
