//---------------------------------------------
// Auth Module 
//---------------------------------------------
// Módulo para gerenciar as ações de autenticação

// import axios from 'axios';
// // axios.defaults.baseURL = 'http://localhost:3000'
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
// var qs = require('qs');
var JWT = () => {
    return Cookies.get("accessToken");
}

// STATES
const state = () => ({
    isLogged: null,
    signUp: null,
    userInfo: null,
    expireCookieDate: null,
    isAlreadyShowedExpNotyf: false,
    errorRegisterNewUser: false,
    isValidEmail: false,
    isResendVerificationMail: null,
    isResetPassword: false,
    isUserVerified: false,
});

// MUTATIONS
const mutations = {
    //-- MUTATION PARA ESTADO DO LOGIN
    setIsLogged(state, payload) {
        state.isLogged = payload
    },

    //-- MUTATION PARA CRIAR USUÁRIO (SIGN UP)
    setSignUp(state, payload) {
        state.signUp = payload
    },

    //-- MUTATION PARA AS INFOS DO USER
    setUserInfo(state, payload) {
        state.userInfo = payload
    },

    //-- MUTATION PARA A HORA DE EXPIRACAO DO TOKEN DA SESSAO
    setExpireCookieDate(state, payload) {
        state.expireCookieDate = payload
    },

    //-- MUTATION PARA O BOOLEAN DA EXIBICAO DA NOTIFICACAO DE EXPIRACAO
    setIsAlreadyShowedExpNotyf(state, payload) {
        state.isAlreadyShowedExpNotyf = payload
    },

    //-- MUTATION PARA O BOOLEAN DE ERRO AO REGISTRAR
    setErrorRegisterNewUser(state, payload) {
        state.errorRegisterNewUser = payload
    },

    //-- MUTATION PARA O BOOLEAN DE EMAIL VALIDO
    setIsValidEmail(state, payload) {
        state.isValidEmail = payload
    },

    //-- MUTATION PARA O BOOLEAN DE REENVIO DE EMAIL
    setResendVerificationMail(state, payload) {
        state.resendVerificationMail = payload
    },

    //-- MUTATION PARA O BOOLEAN DE REDEFINICAO DE SENHA
    setIsResetPassword(state, payload) {
        state.isResetPassword = payload
    },

    //-- MUTATION PARA BOOLEANO DE VERIFICAÇÃO DE USUÁRIO
    setIsUserVerified(state, payload) {
        state.isUserVerified = payload;
    }

}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DO LOGIN
    async setLogin({ commit }, payload) {
        await axios
            .post(`auth/signin`, {
                email: payload.email,
                senha: payload.senha
            })
            .then((response) => {
                let today = new Date();
                today.setHours(today.getHours() + 24);
                commit('setIsLogged', true)
                commit('setExpireCookieDate', today)
                Cookies.set("accessToken", response.data.accessToken, {
                    expires: 1,
                    sameSite: "strict",
                    secure: false,
                });
            })
            .catch(() => {
                commit('setIsLogged', false)
                commit('setExpireCookieDate', null)
            })
    },

    async logout({ commit }) {
        commit("setIsLogged", false);
        commit("setUserInfo", null);
        commit('setExpireCookieDate', null)
        commit('setIsAlreadyShowedExpNotyf', false)
        Cookies.remove("accessToken");
        localStorage.removeItem('vuex');
    },

    //-- ACTION REQUISICAO DO TOKEN
    async setVerifyToken({ commit }) {
        await axios
            .get(`auth/verifyToken`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => {
                commit('setIsLogged', true)
            })
            .catch(() => {
                commit('setIsLogged', false)
            })
    },

    //-- ACTION REQUISICAO DO REFRESH TOKEN
    async setRefreshToken({ commit }) {
        await axios
            .get(`auth/refreshToken`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then((response) => {
                commit('setIsLogged', true)
                let today = new Date();
                // today.setMinutes(today.getMinutes() + 2); // USADO EM TESTES
                today.setHours(today.getHours() + 1);
                commit('setIsLogged', true)
                commit('setExpireCookieDate', today)
                Cookies.set("accessToken", response.data.accessToken, {
                    expires: 1 / 24,
                    sameSite: "strict",
                    secure: false,
                });
            })
            .catch((response) => {
                commit('setIsLogged', false)
                console.log(response)
            })
    },

    //-- ACTION REQUISICAO DAS INFOS DO USER
    async setUserInfo({ commit }) {
        if (!JWT()) {
            commit('setUserInfo', null);
            return;
        }

        await axios
            .get(`auth`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then((response) => {
                commit('setUserInfo', response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    },

    //-- ACTION REQUISICAO DE CRIAR USUÁRIO
    async setSignUp({ commit }, payload) {
        await axios
            .post(`auth/signup`, {
                nome: payload.nome,
                email: payload.email,
                genero: payload.genero,
                dataNascimento: payload.dtnasc,
                profissao: payload.profissao,
                organizacao: payload.organizacao,
                atuacao: payload.atuacao,
                nomeCidade: payload.cidade,
                estado: payload.estado,
                senha: payload.senha
            })
            .then(() => {
                commit('setErrorRegisterNewUser', false)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setErrorRegisterNewUser', true)
            })
    },

    //-- ACTION REQUISICAO DE CRIAR USUÁRIO
    async setIsValidEmail({ commit }, payload) {
        await axios
            .post(`auth/isValidEmail`, {
                email: payload,
            })
            .then((response) => {
                commit('setIsValidEmail', response.data)
            })
            .catch((error) => {
                console.log(error.message)
            })
    },

    //-- ACTION REQUISICAO DE RESEND EMAIL DE CONFIRMACAO
    async setResendVerificationMail({ commit }, payload) {
        await axios
            .get(`auth/resendVerificationMail?email=${payload}`)
            .then(() => {
                console.log('reenviado')
                commit('setResendVerificationMail', true)
            })
            .catch((error) => {
                console.log(error)
                commit('setResendVerificationMail', false)
            })
    },

    //-- ACTION REQUISICAO DE SEND EMAIL DE REDEFINICAO
    async setSendResetPasswordMail(_, payload) {
        await axios
            .get(`auth/sendResetPasswordMail?email=${payload}`)
            .then(() => {
                console.log('enviado')
            })
            .catch((error) => {
                console.log(error)
            })
    },

    //-- ACTION REQUISICAO DE ALTERAR USUÁRIO
    async setUpdateUser(_, payload) {
        await axios
            .put(`auth`, {
                nome: payload.nome,
                email: payload.email,
                genero: payload.genero,
                dataNascimento: payload.dataNascimento,
                profissao: payload.profissao,
                organizacao: payload.organizacao,
                atuacao: payload.atuacao
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => {
                console.log('Atualizado com sucesso!')
            })
            .catch((error) => {
                console.log(error.message)
            })
    },

    //-- ACTION REQUISICAO DE REDEFINIR SENHA
    async setResetPassword({commit}, payload) {
        await axios
            .put(`auth/resetPassword?email=${payload.email}&token=${payload.token}`, {
                senhaNova: payload.senhaNova,
            })
            .then(() => {
                commit('setIsResetPassword', true)
                console.log('Sennha alterada sucesso!')
            })
            .catch((error) => {
                commit('setIsResetPassword', false)
                console.log(error.message)
            })
    },

    //-- ACTION REQUISIÇÃO CONFIRMAÇÃO DE USUÁRIO
    async setFinishRegistration({ commit }, payload) {
        await axios
            .post(`auth/finishUserRegistration?email=${payload.email}&token=${payload.token}`, {
                nome: payload.nome,
                genero: payload.genero,
                dataNascimento: payload.dtnasc,
                profissao: payload.profissao,
                organizacao: payload.organizacao,
                atuacao: payload.atuacao,
                nomeCidade: payload.cidade,
                estado: payload.estado,
                senha: payload.senha
            })
            .then(() => {
                commit('setIsUserVerified', true);
            })
            .catch((error) => {
                console.log(error.message);
                commit('setIsUserVerified', false);
            });
    }
}

// GETTERS
const getters = {
    getIsLogged(state) {
        return state.isLogged
    },

    getUserInfo(state) {
        return state.userInfo
    },

    getSignUp(state) {
        return state.signUp
    },

    getExpireCookieDate(state) {
        return state.expireCookieDate
    },

    getIsAlreadyShowedExpNotyf(state) {
        return state.isAlreadyShowedExpNotyf
    },

    getErrorRegisterNewUser(state) {
        return state.errorRegisterNewUser
    },

    getIsValidEmail(state) {
        try {
            return state.isValidEmail.data
        } catch (e) {
            return false
        }
    },

    getResendVerificationMail(state) {
        return state.resendVerificationMail
    },

    getIsResetPassword(state) {
        return state.isResetPassword
    },

    getIsUserVerified(state) {
        return state.isUserVerified;
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
