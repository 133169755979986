<template>
    <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_1950_144)">
            <mask
                id="mask0_1950_144"
                style="mask-type: luminance"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="40"
                height="40"
            >
                <path
                    d="M0 9.91821e-05H39.9999V40H0V9.91821e-05Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_1950_144)">
                <path
                    d="M21.5562 0.857207V5.31251C21.5562 6.17547 22.2557 6.875 23.1186 6.875H27.5739"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M27.4026 36.0918H2.34375C1.48078 36.0918 0.78125 35.3922 0.78125 34.5293V2.34477C0.78125 1.48188 1.48078 0.782271 2.34375 0.782271H21.6342L27.652 6.7971V22.3315"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.68555 6.875H15.7813"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.3364 10.9375H23.7478"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.3364 15H23.7478"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.68555 31.25H21.0823"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M4.68555 27.1875H19.4732"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M15.821 20.5469V23.125H4.68555V10.9375H15.821V13.5157"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M27.3152 26.2344L26.532 24.5548C26.1673 23.7727 26.5057 22.8431 27.2877 22.4784C28.0699 22.1137 28.9996 22.452 29.3643 23.2341L30.1475 24.9138"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M30.1478 24.9141L29.4939 23.512C29.1292 22.7298 29.4676 21.8002 30.2497 21.4355C31.0317 21.0708 31.9614 21.4092 32.3261 22.1912L32.9799 23.5934"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M27.0092 25.5778L22.9182 16.8048C22.5535 16.0226 21.6239 15.6842 20.8418 16.049C20.0597 16.4137 19.7214 17.3433 20.086 18.1254L24.8023 28.2395L22.12 26.6479C21.2028 26.1036 20.0179 26.4057 19.4732 27.3227C19.015 28.094 19.1481 29.0793 19.7945 29.7014L24.2966 34.0338C25.6126 35.3002 27.2608 36.1672 29.0499 36.5342L30.2822 39.2168L39.2187 35.0496L37.9865 32.3671C38.6503 30.2991 38.5092 28.0567 37.5913 26.0882L35.5058 21.6157C35.1411 20.8336 34.2114 20.4952 33.4294 20.8599C32.6473 21.2246 32.3088 22.1543 32.6736 22.9364L32.9798 23.593"
                    stroke="#196555"
                    stroke-width="1.25"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1950_144">
                <rect width="40" height="40" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>
