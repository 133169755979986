<template>
<form ref="addFieldForm" @submit.prevent="createAmostra()">
    <div class="container offset">
        <div class="custom-form">

            <div class="form-group">
                <label class="question-style">Nome: *</label>
                <input
                    v-model.trim="nome"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o nome do campo..."
                    required
                    />
            </div>
            <div class="form-group">
                <label class="question-style">Ano: *</label>
                <input
                    v-model.trim="ano"
                    type="number"
                    min="2020"
                    :max="new Date().getFullYear()"
                    maxlength="4"
                    pattern="\d{4}"
                    class="form-control"
                    placeholder="Insira aqui o ano do campo..."
                    @blur="formatYear"
                    required
                    />
            </div>
            <div class="form-group">
                <label class="question-style">Descrição: *</label>
                <textarea v-model.trim="descricao" class="form-control" rows=5 placeholder="Insira aqui a descrição do campo..." required></textarea>
            </div>
        </div>
        <div class="button-div pt-3">
            <Button 
                class="greenGradient clickable"
                value="Salvar Campo"
                :isOutline="true"
                :hasIcon="true"
                iconName="save"
            /> 
        </div>
    </div>
    </form>
   
</template>

<script>
import Button from '@components/Button'
import Swal from 'sweetalert2';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: "AddField",
  data() {
    return {
      nome: "",
      ano: "",
      descricao: "",
    }
  },
  components: {
    Button,
  },
  methods: {
    async createAmostra(){
      if ((this.nome != '') && (this.ano != '') && (this.descricao != '')) {        
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
        const campos = this.$store.getters.getListCampos
        const camposAtivos = campos.filter((value) => {
          return value.isativo == true
        })

        if (camposAtivos.length == 0) {
          const payload = {
            idInstituicao: this.$route.params.idInstituicao,
            titulo: this.nome,
            ano: this.ano,
            descricao: this.descricao,
          }
          await this.$store.dispatch('setCreateAmostra', payload)
          if (!this.$store.getters.getAmostraCreated) {
            notyf.open({
              type: "error",
              message: "Erro ao adicionar campo"
            });
            return;
          }
          await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
          this.$emit('closeModal')
          this.$refs.addFieldForm.reset();
          notyf.open({
            type: "success",
            message: "Campo adicionado com sucesso!"
          });
        }
        else {
          Swal.fire(
            'Você não pode iniciar um novo campo',
            'Só pode haver um campo ativo por instituição.',
            'error'
          )
          this.$emit('closeModal')
        }
      }
    },
    formatYear(event) {
            let inputValue = parseInt(event.target.value);

            const currentYear = new Date().getFullYear();
            if (isNaN(inputValue)) {
                inputValue = currentYear;
            } else {
                // [2020, currentYear]
                inputValue = Math.max(2020, Math.min(currentYear, inputValue));
            }
            
            this.ano = inputValue
        },
    clearForm(){
      this.nome = "";
      this.ano = "";
      this.descricao = "";
    }
  }
}
</script>

<style scoped>

    .container{
        padding: 1rem;
        width: 100%;
        background-color: white;
        margin: 0;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;
    }

    .word-style{
        color:  #656565;
    }

    .custom-form{
        margin: 0 auto;
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .button-div{
        text-align: right;
    }

</style>
