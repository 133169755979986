import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
import crypto from 'crypto';

var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

const answerFilters = {
    'CLEAR_EXTENDED': (answer) => (
      answer?.replaceAll('-', ' ')?.replaceAll(/\s\s+/g, ' ')?.trim() || answer
    ),
};

// STATES
const state = () => ({
  answers: {},
  comps: {},
  formData: null,
  formLoaded: null,
  section: 1,
  gotos: {},
  nextKeys: {},
  start: null,
  cpf: null,
  curinga: null,
  formSent: null,
  inserts: {},
  isUnique: null,
  amostraRespostas: [],
  validCode: null,
  listItens: [],
  listItemQuestions: [],
  createdForm: null,
  updatedForm: null,
  curCypher: null,
  toFilter: {},
  errFormularioEncerrado: null,

  // IDS
  ueAmostra: null,
  turmaAmostra: null,
  estudanteAmostra: null,
  professorAmostra: null,
  empresaAmostra: null,
  empregadorAmostra: null,
  jovemAmostra: null,
  egressoAmostra: null,
});

// MUTATIONS
const mutations = {
  addToFilter(state, payload) {
    state.toFilter[payload.key] = payload.filter;
  },
  setCurCypher(state, cypher) {
    state.curCypher = cypher;
  },
  setComp(state, comp) {
    state.comps[comp.id] = comp.value;
  },
  clearComp(state, comp) {
    delete state.comps[comp.id];
  },
  setAnswer(state, answer) {
    state.answers[answer.cod] = answer;
  },
  setErrFormEncerrado(state, v) { state.errFormularioEncerrado = v },
  clearAll(state, ) {
    state.answers = {};
    state.comps = {};
    state.section = 1;
    state.gotos = {};
    state.nextKeys = {};
    state.start = null;
    state.identificacao = null;
    state.curinga = null;
    state.inserts = {};
    state.amostraRespostas = [];
    state.listItens = [];
    state.listItemQuestions = [];
    state.validCode = null;
    state.createdForm = null;
    state.updatedForm = null;
    state.curCypher = null;
    state.toFilter = {};
    state.formData = null;
    state.formLoaded = null;
    state.errFormularioEncerrado = null;
    localStorage.setItem('vuex', null)

    // IDS
    state.ueAmostra = null;
    state.turmaAmostra = null;
    state.estudanteAmostra = null;
    state.professorAmostra = null;
    state.empresaAmostra = null;
    state.empregadorAmostra = null;
    state.jovemAmostra = null;
    state.egressoAmostra = null;
  },
  clearId(state, id) {
    delete state.answers[id];
  },
  setSection(state, n) {
    state.section = n;
  },
  setFormTransicao(state, form) {
    state.formData = form;
  },
  setFormTransicaoLoad(state, l) {
    state.formLoaded = l;
  },
  addGoto(state, goto) {
    if (!state.gotos[goto.to]) state.gotos[goto.to] = [];
    state.gotos[goto.to].push(goto.from);
  },
  removeGoto(state, goto) {
    if (!state.gotos[goto.to]) return;
    const idxRemove = state.gotos[goto.to].indexOf(goto.from);
    if (idxRemove === -1) return;
    state.gotos[goto.to].splice(idxRemove, 1);
    if (!state.gotos[goto.to].length)
      delete state.gotos[goto.to];
  },
  pushNextKey(state, key) {
    state.nextKeys[key] = true;
  },
  popNextKey(state, key) {
    delete state.nextKeys[key];
  },
  setStart(state, time) {
    state.start = time;
  },
  setIdentificacao(state, identificacao) {
    state.identificacao = identificacao;
  },
  setCuringa(state, curinga) {
    state.curinga = curinga;
  },
  setFormSent(state, value) {
    state.formSent = value;
  },
  setInsert(state, toInsert) {
    state.inserts = {
      ...state.inserts,
      ...toInsert,
    };
  },
  removeInsert(state, toInsert) {
    if (state.inserts[toInsert])
      delete state.inserts[toInsert];
  },
  setIsUnique(state, unique) {
    state.isUnique = unique;
  },
  setValidCode(state, valid) {
    state.validCode = valid;
  },
  setAmostraRespostas(state, respostas) {
    state.amostraRespostas = respostas;
  },
  setListItens(state, itens) {
    state.listItens = itens;
  },
  setListItemQuestions(state, itens) {
    state.listItemQuestions = itens;
  },
  setCreatedForm(state, form) {
    state.createdForm = form;
  },
  setUpdatedForm(state, form) {
    state.updatedForm = form;
  },

  setUeAmostra(state, id) {
    if (id) state.ueAmostra = id;
    else delete state.ueAmostra;
  },
  setTurmaAmostra(state, id) {
    if (id) state.turmaAmostra = id;
    else delete state.turmaAmostra;
  },
  setEstudanteAmostra(state, id) {
    if (id) state.estudanteAmostra = id;
    else delete state.estudanteAmostra;
  },

  setProfessorAmostra(state, id) {
    if (id) state.professorAmostra = id;
    else delete state.professorAmostra;
  },
  setEmpresaAmostra(state, id) {
    if (id) state.empresaAmostra = id;
    else delete state.empresaAmostra;
  },
  setEmpregadorAmostra(state, id) {
    if (id) state.empregadorAmostra = id;
    else delete state.empregadorAmostra;
  },
  setJovemAmostra(state, id) {
    if (id) state.jovemAmostra = id;
    else delete state.jovemAmostra;
  },
  setEgressoAmostra(state, id) {
    if (id) state.egressoAmostra = id;
    else delete state.egressoAmostra;
  },
};

// ACTIONS
const actions = {
  clearAll({ commit }) { commit("clearAll"); },
  addFiltering({ commit }, payload) {
    commit('addToFilter', payload);
  },
  registerIdType({ commit }, option) {
    if (!option.idType) return;
    switch (option.idType) {
      case "id_ue_amostra":
        commit("setUeAmostra", option.id);
        break;
      case "id_turma_amostra":
        commit("setTurmaAmostra", option.id);
        break;
      case "id_estudante_amostra":
        commit("setEstudanteAmostra", option.id);
        break;

      case "id_professor_amostra":
        commit("setProfessorAmostra", option.id);
        break;
      case "id_empresa_amostra":
        commit("setEmpresaAmostra", option.id);
        break;
      case "id_empregador_amostra":
        commit("setEmpregadorAmostra", option.id);
        break;
      case "id_jovem_amostra":
        commit("setJovemAmostra", option.id);
        break;
      case "id_egresso_amostra":
        commit("setEgressoAmostra", option.id);
        break;
    }
  },
  upsertAnswer({ commit }, answer) {
    if (answer.answer !== undefined)
      commit('setAnswer', answer);
    else
      commit("clearId", answer.cod);
  },
  upsertComp({ commit }, comp) {
    if (comp.value !== undefined)
      commit('setComp', comp);
    else
      commit('clearComp', comp);
  },
  clearAnswer({ commit }, id) {
    if (id) commit("clearId", id);
    else commit("clearAll");
  },
  setSection({ commit }, n) {
    commit('setSection', n);
  },
  async loadFormTransicao({ commit, state }, cypher) {
    commit("setFormTransicaoLoad", false);
    commit('setErrFormEncerrado', null);
    await axios
      .get(`transicao/?paramCypher=${encodeURIComponent(cypher)}`)
      .then(response => {
        response.data.data.categories = response?.data?.data?.jsonform.filter(cat => Object.keys(cat).length);
        const formCypher = crypto.createHash('sha1').update(JSON.stringify(response.data?.data)).digest('hex');
        if (formCypher != state.curCypher)
          commit("clearAll");
        commit("setFormTransicao", response.data?.data);
        commit("setCurCypher", formCypher);
        commit('setErrFormEncerrado', false);
      })
      .catch(error => {
        if (error.response.status == 401)
          commit('setErrFormEncerrado', true);
        console.log(error.message);
        commit("setFormTransicao", null);
        commit("setCurCypher", null);
      }).finally(() => {
        commit("setFormTransicaoLoad", true);
      });
  },
  addGoto({ commit }, goto) {
    commit("addGoto", goto);
  },
  removeGoto({ commit }, goto) {
    commit("removeGoto", goto);
  },
  addNextKey({ commit }, key) {
    commit("pushNextKey", key);
  },
  removeNextKey({ commit }, key) {
    commit("popNextKey", key);
  },
  async startForm({ commit }, payload) {
    commit("setStart", new Date().toISOString());
    commit("setIdentificacao", payload?.id);
    // if (payload?.id)
    // else
    //   return;
  },
  async sendForm({ commit, state }, cypher) {
    commit("setFormSent", null);
    commit('setErrFormEncerrado', null);

    let filteredAnswers = { ...state.answers };
    let filteredInserts = { ...state.inserts };

    for (const key of Object.keys(state.toFilter)) {
      if (!answerFilters[state.toFilter[key]])
        continue;

      if (state.answers[key])
        filteredAnswers[key].answer = answerFilters[state.toFilter[key]](state.answers[key].answer);
      else if (state.inserts[key])
        filteredInserts[key] = answerFilters[state.toFilter[key]](state.inserts[key]);
    }
    const keysToRemove = ["CE01_O", "CE04_O", "CE05_O", "CE11_O", "HE01_O", "HEE01_O", "HE07_O"];
    keysToRemove.forEach(key => {
      delete filteredAnswers[key];
  });
    const categoryName = state.formData?.categories?.at(0)?.categoryName;
    const curDate = new Date(),
          payload = {
            // IDS
            idUeAmostra: state.ueAmostra?.toString() ?? null,
            idTurmaAmostra: state.turmaAmostra?.toString() ?? null,
            idEstudanteAmostra: state.estudanteAmostra?.toString() ?? null,
            idProfessorAmostra: state.professorAmostra?.toString() ?? null,
            idEmpresaAmostra: state.empresaAmostra?.toString() ?? null,
            idEmpregadorAmostra: state.empregadorAmostra?.toString() ?? null,
            idJovemAmostra: state.jovemAmostra?.toString() ?? null,
            idEgressoAmostra: state.egressoAmostra?.toString() ?? null,

            horaInicio: state.start || curDate.toISOString(),
            horaTermino: curDate.toISOString(),
            dataAplicacao: `${curDate.getFullYear()}-${curDate.getMonth()}-${curDate.getDay()}`,
            respostas: {
              ...({ identificacao: state.identificacao?.replaceAll('.', '').replaceAll('-', '') }),
              ...filteredAnswers,
              ...filteredInserts,
            },
            categoria: categoryName,
            ...({ identificacao: state.identificacao?.replaceAll('.', '').replaceAll('-', '') }),
            ...filteredInserts,
          };

    await axios
      .post(`transicao?paramCypher=${encodeURIComponent(cypher)}`, payload)
      .then(() => {
        commit("setFormSent", true);
        commit("clearAll");
        commit('setErrFormEncerrado', false);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          commit('setErrFormEncerrado', true);
        commit("setFormSent", false);
      });
  },
  addInsert({ commit }, toInsert) {
    if (!toInsert) return;
    commit("setInsert", toInsert);
  },
  removeInsert({ commit }, toInsert) {
    if (!toInsert) return;
    commit("removeInsert", toInsert);
  },
  async checkUnique({ commit }, payload) {
    commit("setIsUnique", null);
    await axios
      .get(`transicao/isUniqueIdentificacao/?paramCypher=${encodeURIComponent(payload.cypher)}&identificacao=${payload.id?.replace(/\D/g, '')}`)
      .then(response => {
        commit("setIsUnique", response?.data);
      })
      .catch(err => {
        commit("setIsUnique", false);
        console.log(err);
      });
  },
  async checkCode({ commit }, payload) {
    commit("setValidCode", null);
    await axios
      .get(`transicao/isValidCuringa/?paramCypher=${encodeURIComponent(payload.cypher)}&curinga=${payload.id}`)
      .then(response => {
        commit("setValidCode", response.data);
      })
      .catch(err => {
        commit("setValidCode", false);
        console.log(err);
      });
  },
  // async loadAmostraRespostas({ commit }, payload) {
  async loadAmostraRespostas(_, payload) {
    return axios
      .get(`amostras/amostraRespostas/${payload.idInstituicao}/${payload.idAmostra}?isAnon=${payload.anon}`, {
        responseType:'blob',
        headers: {
          'Authorization': `Bearer ${JWT()}`,
        },
      });
  },
  async loadListItens({ commit }) {
    return axios
      .get(`formularios/itens`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`,
        },
      })
      .then(response => {
        commit('setListItens', response.data.data);
      })
      .catch(err => {
        console.log(err);
      });
  },
  async loadListItemQuestions({ commit }, idItems) {
    commit('setListItemQuestions', []);
    return axios
      .get(`formularios/itens${idItems ? '?' + idItems.map(id => `idItem[]=${id}`).join('&') : ''}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`,
        },
      })
      .then(response => {
        commit('setListItemQuestions', response.data.data);
      })
      .catch(err => {
        console.log(err);
        commit('setListItemQuestions', null);
      });
  },
  async createForm({ commit }, payload) {
    let formData = new FormData();
    formData.append("idInstituicao", payload.idInstituicao);
    formData.append("nome", payload.nome);
    formData.append("idTipoForm", payload.idTipoForm);
    for (const id of payload.idItem)
      formData.append("idItem[]", id);
    formData.append("identificador", payload.identificador);
    formData.append("isUniqueId", (!payload.isUniqueId).toString());
    formData.append("complementares", payload.complementares ?? JSON.stringify({}));
    if (payload.pdf) formData.append("pdf", payload.pdf);

    commit('setCreatedForm', {});
    return axios
      .post(
        `formularios/${payload?.idInstituicao}`,
        formData, {
          headers: {
            'Authorization': `Bearer ${JWT()}`
          }
        }
      ).then(response => {
        commit('setCreatedForm', response?.data?.data);
      }).catch(err => {
        commit('setCreatedForm', null);
        console.log(err);
      });
  },
  async updateForm({ commit }, payload) {
    commit('setUpdatedForm', {});

    let formData = new FormData();
    formData.append("idInstituicao", payload.idInstituicao);
    formData.append("idForm", payload.idForm);
    formData.append("nome", payload.nome);
    formData.append("idTipoForm", payload.idTipoForm);
    for (const id of payload.idItem)
      formData.append("idItem[]", id);
    formData.append("identificador", payload.identificador);
    formData.append("isUniqueId", (!payload.isUniqueId).toString());
    formData.append("complementares", payload.complementares ?? JSON.stringify({}));
    formData.append("updateTermo", payload.updateTermo.toString());
    formData.append("pdf", payload?.pdf);

    return axios
      .put(
        `formularios/${payload?.idInstituicao}/${payload?.idForm}`,
        formData, {
          headers: {
            'Authorization': `Bearer ${JWT()}`
          }
        }
      ).then(response => {
        commit('setUpdatedForm', response?.data?.data);
      }).catch(err => {
        commit('setUpdatedForm', null);
        console.log(err);
      });
  },
};

// GETTERS
const getters = {
  getAllAnswers(state) { return state.answers },
  getAnswerId: (state) => (cod) => {
    return state.answers[cod];
  },
  getSection(state) {
    return state.section;
  },
  getFormTransicao(state) {
    return state.formData;
  },
  getFormTransicaoLoaded(state) {
    return state.formLoaded;
  },
  getAllGotos(state) {
    return Object.keys(state.gotos);
  },
  getGotoSet: (state) => (id) => {
    return state.gotos[id] !== undefined;
  },
  getAllInserts(state) { return state.inserts; },
  getInsertSet: (state) => (toInsert) => { return state.inserts[toInsert]; },
  getAllNextKeys(state) {
    return Object.keys(state.nextKeys);
  },
  getLinkedKeySet: (state) => (key) => {
    return state.nextKeys[key] || false;
  },
  getFormStarted(state) {
    return state.start !== null;
  },
  getFormStartTime(state) {
    return state.start;
  },
  getFormSent(state) {
    return state.formSent;
  },
  getIdentificacao(state) {
    return state.identificacao;
  },
  getIsUnique(state) { return state.isUnique; },
  getCodeIsValid(state) { return state.validCode; },
  getAmostraRespostas(state) { return state.amostraRespostas; },
  getListItens(state) { return state.listItens; },
  getListItemQuestions(state) { return state.listItemQuestions; },
  getCreatedForm(state) { return state.createdForm; },
  getUpdatedForm(state) { return state.updatedForm; },
  getComp: (state) => (cod) => { return state.comps[cod]; },
  getErrFormEncerrado(state) { return state.errFormularioEncerrado; },
};

export default {
    state,
    mutations,
    actions,
    getters
};
