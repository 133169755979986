<template>
  <div class="root-dados">
    <ModalCentral
      componentName="AddProfessor"
      :title="profEditing == null ? 'ADICIONAR PROFESSORES' : 'EDITAR PROFESSOR'"
      idName="EditProf"
      :data="{ editing: profEditing, listProfessores: rawProfessores }"
    />

    <div class="container list-data">
      <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
      <br />

      <div class="title-row" style="justify-content: flex-start; gap: 10px;">
        <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>
      </div>

      <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os professores pertencentes à Unidade Educacional. Caso necessário, utilize o filtro abaixo para localizar o professor desejado.</p>

      <template v-if="userPerm?.permissao != 'APLICADOR'">
        <div class="lista-data-info">
          <Button
            v-if="campoStatus != 'OFF'"
            @click="setSelectAllItems"
            class="btn btnCtrl greenGradient"
            section="instituicao-campo"
            value="Marcar Todos"
            :isOutline="true"
            :hasIcon="true"
            iconName="check-square"
            />
          <Button
            v-if="campoStatus != 'OFF'"
            @click="setUnselectAllItems"
            class="btn ml-2 btnCtrl greenGradient"
            section="instituicao-campo"
            value="Desmarcar Todos"
            :isOutline="true"
            :hasIcon="true"
            iconName="square"
            />
          <Button
            v-if="campoStatus != 'OFF'"
            @click="applyChanges"
            class="btn ml-2 btnCtrl greenGradient"
            section="instituicao-campo"
            value="Aplicar Alterações"
            :isOutline="true"
            :hasIcon="true"
            iconName="check"
            />
        </div>
      </template>
      <br />

      <!-- INICIO DO FORM DE FILTRAGEM -->
      <div class="input-group">
        <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar professores por nome..." autocomplete="off">
        <div class="input-group-append">
          <!-- <i class="fas fa-bars"></i> -->
          <span class="input-group-text"> Nome </span>
        </div>
      </div>
      <hr class="separator">

      <div v-if="isLoadedInfo">

        <transition-group v-if="professores_filtered?.length > 0" name="slide-fade">
          <div class = "table-wrap">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col">&nbsp;</th>
                  <th scope="col">Nome</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-row" v-for="professor in professores_filtered" :key="professor.idprofessor">

                  <td> <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-turmas.svg" alt=""> </td>

                  <td> {{ professor.nome }} </td>

                  <td>
                    <div v-if="userPerm.idPermissao != '1'" class="controlGroup" :class="campoStatus == 'OFF'? 'not-clickable' : ''">
                      <span v-if="professor.isLocked">Selecionado</span>
                      <div v-else>
                        <div @click="setIsSelected(professor)" class="select-info">
                          <div :class="['box-select-info mr-2 ',{'selected-info': professor?.isSelect?.isSelected}]"></div>
                          <span class="span-label-selected" v-if="professor.isSelect.isSelected">Selecionado</span>
                          <span class="span-label-selected" v-else>Selecionar</span>
                        </div>
                      </div>

                      <div class="controlGroup">
                        <Button 
                          v-if="!professor.isEditionLocked && campoStatus != 'OFF'"
                          section="instituicao-campo"
                          value="Editar"
                          @click="profEditing = professor"
                          :isOutline="true"
                          :hasIcon="true"
                          iconName="pen"
                          data-toggle="modal"
                          data-target="#EditProf"
                          />

                        <Button
                          v-if="!professor.isDeletionLocked && campoStatus != 'OFF'"
                          @click="removeProfessor(professor.idProfessor)"
                          section="descartar"
                          value="Excluir"
                          :isOutline="true"
                          :hasIcon="true"
                          iconName="trash"
                          />
                      </div>
                    </div>
                    <div v-else>
                      -
                    </div>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </transition-group>

        <transition-group v-else>
          <h2 class="title-style-box-lista" key="classesKey">Nenhum professor encontrado.</h2>
        </transition-group>

      </div>

      <div v-else>
        <SkeletonList/>
      </div>

    </div>
  </div>
</template>

<script>
import Button from '@components/Button'
import Breadcrumb from '@components/Breadcrumb'
import ModalCentral from '@components/ModalCentral'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
  name: "CampoListaProfessores",
  data() {
    return {
      nomeCampo: null,
      descricao: null,
      searchByName: "",
      professores: [],
      professores_amostra: [],
      profEditing: null,
      crumbs: [
        {
          id:1,
          value: '',
          iconName: 'th',
          name_route: 'GerenciarInstituicoes'
        },
        {
          id:2,
          value: 'Instituição',
          iconName: 'university',
          name_route: 'InstituicaoMenu'
        },
        {
          id:3,
          value: 'Campos',
          iconName: 'th-large',
          name_route: 'ListarCampos'
        },
        {
          id:4,
          value: 'Gerenciar Campo',
          iconName: 'chart-pie',
          name_route: 'InstituicaoMenuCampo'
        },
        {
          id:5,
          value: 'Unidades Educacionais',
          iconName: 'graduation-cap',
          name_route: 'CampoListaUnidadesEducacionais'
        },
        {
          id:6,
          value: 'Professores',
          iconName: 'chalkboard-teacher',
          name_route: ''
        },
      ],
    };
  },
  components: {
    Button,
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    ModalCentral,
  },
  computed: {
    isLoadedInfo() {
      return (this.professores != null) && (this.professores_amostra != null);
    },
    rawProfessores() { return this.$store.getters.getListProfessores; },
    rawProfessoresCampo() { return this.$store.getters.getListCampoProfessores; },
    campos() { return this.$store.getters.getListUE; },
    campoStatus() {
      const all_campos = this.$store.getters.getListCampos || [];
      const campo = all_campos
            .find((value) => value.idamostra == this.$route.params.idCampo);
      return campo.isativo;
    },
    userPerm() { return this.$store.getters.getUserPerm; },
    professores_filtered() {
      var query = this.professores || [];

      if (this.searchByName.trim() !== '') {
        let filtered = this.professores.filter(professor => professor.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()));

        if (filtered?.length != 0) query = filtered;
        else query = false
      }

      return query;
    },
  },
  async mounted() {
    this.loadData();

    this.$store.dispatch('setListCampos', this.$route.params.idInstituicao);

    await this.$store.dispatch('setListUE', { idInstituicao: this.$route.params.idInstituicao });
    const idTeamSelected = this.$route.params.idUnidade;

    if (!idTeamSelected) this.nomeInstituicao = "ERRO AO CARREGAR UNIDADE";
    else {
      let teamsSelected,
          teamSelected;

      teamsSelected = this.campos.filter(team =>
        team.idue == idTeamSelected
      )

      teamSelected = [...teamsSelected].shift()

      this.nomeCampo = teamSelected.nome;
      this.descricao = teamSelected.descricao;
    }
  },
  watch: {
    rawProfessores() { this.setData(); },
    rawProfessoresCampo() { this.setData(); },
  },
  methods: {
    async loadData() {
      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo,
        idUnidade: this.$route.params.idUnidade,
      };

      this.$store.dispatch('setUserPerm', payload)
      this.$store.dispatch('setListProfessores', payload);
      this.$store.dispatch('setListCampoProfessores', payload);
    },
    async removeProfessor(idProfessor) {
      const result = await Swal.fire({
        title: 'Deseja mesmo excluir o professor?',
        text: 'Ao excluir, você não terá mais acesso às informações do professor.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir professor",
      })
      if (result.isConfirmed) {
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          idUnidade: this.$route.params.idUnidade,
          idProfessor
        };

        await this.$store.dispatch('setRemoveProfessor', payload);
        if (this.$store.getters.getIsProfessorUpdated) {
          notyf.success("Professor excluído com sucesso");
          this.$store.dispatch('setListProfessores', payload).then(() => this.isLoadedInfo = true);
        } else {
          notyf.error("Erro ao excluir o professor, tente novamente");
        }
      }
    },
    setIsSelected(professor) {
      professor.isSelect.isSelected = !professor.isSelect.isSelected;
      professor.isSelect.isSaved = false;
    },
    setSelectAllItems() {
      this.professores.forEach(professor => {
        professor.isSelect = {
          isSelected: true,
          isSaved: false,
        };
      });
    },
    setUnselectAllItems() {
      this.professores.forEach(professor => {
        professor.isSelect = {
          isSelected: false,
          isSaved: false,
        };
      });
    },
    async applyChanges() {
      const result = await Swal.fire({
        title: "Tem certeza que deseja aplicar as alterações?",
        text: "Caso o campo esteja ativo, os elementos inseridos não poderão ser modificados.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, tenho certeza",
      });

      if (!result.isConfirmed) return;

      const payloadAddProfessores = this.professores.filter(professor => professor.isSelect.isSelected).map(professor => professor.idProfessor);
      const payloadRemoveProfessores = this.professores.filter(professor => professor.isSelect.isSelected == false).map(professor => professor.idProfessor);

      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idUnidade: this.$route.params.idUnidade,
        idAmostra: this.$route.params.idCampo,
        addIds: JSON.parse(JSON.stringify(payloadAddProfessores)),
        removeIds: JSON.parse(JSON.stringify(payloadRemoveProfessores)),
      };
      await this.$store.dispatch('setManageProfessores', payload);

      if (this.$store.getters.getIsProfessoresManaged) {
        notyf.success('As alterações foram aplicadas com sucesso');
        this.loadData();
      }
      else
        notyf.error('Erro ao aplicar as alterações');
    },
    setData() {
      this.professores = this.rawProfessores || []
      this.professores_amostra = this.rawProfessoresCampo || []

      this.professores.forEach(professor => {
        professor.isLocked = false;
        const profFound = this.professores_amostra.find(professor_amostra => professor_amostra.idProfessor == professor.idProfessor);

        if (profFound)
          professor.isLocked = profFound.isLocked;

        professor.isSelect = {
          isSelected: profFound != null,
          isSaved: true,
        };
      });
    },
  },
}
</script>

<style scoped>
:deep(.box-list span) {
    font-size: 1.1em;
}

.box-lista span {
    color: #fcfcfc;
}


.not-clickable{
    cursor: not-allowed;
}

.not-clickable > div{
    pointer-events: none;
}
</style>
