<template>
    <VideoModal
        v-if="pratica?.link && pratica?.link != 'null' && pratica?.link != 'undefined'"
        :videoLink="clipVideoId(pratica.link)"
        id="videoModal"
    />

    <ModalCentral
        v-if="pratica?.pdf && pratica?.pdf != 'null' && pratica?.pdf != 'undefined'"
        componentName="PopupDownload"
        title="Baixar PDF"
        :data="{
            pdf: pratica.pdf,
            name: pratica.titulo,
            id: pratica.idPratica,
        }"
        idName="pdfPopup" />

    <ModalCentral
        componentName="PopupRating"
        title="Avaliar Prática"
        :data="{ idPratica: idPratica }"
        idName="ratingPopup" />

    <ModalCentral
        componentName="UpdatePratica"
        :data="{ idPratica: idPratica }"
        title="Editar Prática"
        ref="edit"
        idName="editPopup" />

    <div class="outter" v-if="loaded">
        <div v-if="!pratica">
            Não foi possível encontrar a prática solicitada!
        </div>
        <div v-else>
            <div v-if="isAdmin" class="adminMenu">
                <Button
                    @click="deletePratica"
                    class="menuBut"
                    value="Excluir prática"  />

                <Button
                    @click="setEdit"
                    data-target="#editPopup"
                    data-toggle="modal"
                    class="menuBut"
                    value="Editar prática" />
            </div>

            <div class="intro">
                <div class="info">
                    <div class="title">{{ pratica.titulo }}</div>

                    <img
                        v-if="pratica?.link && pratica?.link != 'null' && pratica?.link != 'undefined'"
                        class="interactive"
                        :src="`https://i.ytimg.com/vi/${clipVideoId(pratica.link)}/maxresdefault.jpg`"
                        data-target="#videoModal"
                        data-toggle="modal"
                        alt="Capa do vídeo" />
                    <img
                        v-else
                        :src="require('../../assets/img/BoasPraticas/oquee.jpeg')"
                        :alt="`Prática ${pratica.titulo}`" />

                    <div class="innerData">
                        <div class="innerInfos">
                            <h6>Publicado em {{ horaCriacao }}</h6>
                            <div>
                                <i class="fas fa-id-badge"></i>
                                &nbsp;
                                {{ pratica.autor }}
                            </div>
                            <div>
                                <i class="fas fa-shapes"></i>
                                &nbsp;
                                {{ pratica.idade }}
                            </div>
                            <Button
                                v-if="pratica?.pdf && pratica?.pdf != 'null' && pratica?.pdf != 'undefined'"
                                data-target="#pdfPopup"
                                data-toggle="modal"
                                class="innerButton"
                                value="BAIXAR ARQUIVO PDF"
                                :isOutline="true"
                                :hasIcon="true"
                                iconName="file-download" />
                        </div>
                        <div class="themes">
                            <h6>Categorias</h6>
                            <div class="tags">
                                <div v-for="tematica in pratica?.boasPraticasCategorias.map(category => category.idTipo.nome)"
                                     :style="{ 'background': `url(${categories[tematica].background})` }"
                                     :key="tematica"
                                     class="tag">
                                    {{ tematica }}
                                </div>
                            </div>
                            <Button
                                data-target="#ratingPopup"
                                data-toggle="modal"
                                class="innerButton"
                                value="AVALIAR"
                                :isOutline="true"
                                :hasIcon="true"
                                iconName="star" />
                        </div>
                    </div>
                </div>

                <div class="summary">
                    <div class="title">{{ sections[0] }}</div>
                    <p> {{ pratica.secao1 }} </p>
                </div>
            </div>

            <div class="bottom">
                <div class="bottomText">
                    <div class="title">{{ sections[1] }}</div>
                    <p> {{ pratica.secao2 }} </p>
                    <div class="title">{{ sections[2] }}</div>
                    <p> {{ pratica.secao3 }} </p>
                    <div class="title">{{ sections[3] }}</div>
                    <p> {{ pratica.secao4 }} </p>
                    <div class="title">{{ sections[4] }}</div>
                    <p> {{ pratica.secao5 }} </p>

                    <div v-if="isAdmin && avaliacoes.length">
                        <div class="title">Avaliações</div>
                        <Avaliacao
                            v-for="(rating, idx) in avaliacoes"
                            :key="idx"
                            :score="rating?.score"
                            :text="rating?.msg" />
                    </div>
                </div>
                <div class="related">
                    <div class="title">Práticas Relacionadas</div>
                    <div
                        v-for="(related, idx) in relatedPraticas"
                        :style="{
                            'backgroundImage': `linear-gradient(white, white), linear-gradient(to bottom right, ${praticaGradient(related)})`
                        }"
                        :key="idx"
                        class="relatedPratica">
                        <a :href="`/boaspraticas/${related.idPratica}`" class="relatedInner">
                            <img :src="`https://i.ytimg.com/vi/${clipVideoId(related.link)}/maxresdefault.jpg`" alt="Capa do vídeo" />
                            <div class="titulo"> {{ related.titulo }} </div>
                            <div class="autor">
                                <i class="fas fa-id-badge"></i>
                                &nbsp;
                                {{ related.autor }}
                            </div>
                            <div class="idade">
                                <i class="fas fa-shapes"></i>
                                &nbsp;
                                {{ related.idade }}
                            </div>
                        </a>
                    </div>
                    <div class="noneFound" v-if="!relatedPraticas.length">
                        Nenhuma prática relacionada encontrada!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
 import Button from "@components/Button";
 import Avaliacao from "@components/BoasPraticas/Avaliacao";
 import ModalCentral from "@components/ModalCentral";
 import VideoModal from "@components/Forms/VideoModal";

 import Swal from 'sweetalert2';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "Pratica",
     components: {
         Button,
         ModalCentral,
         Avaliacao,
         VideoModal,
     },
     data() {
         return {
             loaded: false,
             categories: {
                 "Categoria 1": {
                     color: "blue",
                     background: require('../../assets/img/Backgrounds/azulEscuro.png'),
                 },
                 "Categoria 2": {
                     color: "purple",
                     background: require('../../assets/img/Backgrounds/roxoEscuro.png'),
                 },
                 "Categoria 3": {
                     color: "#73c0e9",
                     background: require('../../assets/img/Backgrounds/azulClaro.png'),
                 },
                 "Categoria 4": {
                     color: "maroon",
                     background: require('../../assets/img/Backgrounds/marrom.png'),
                 },
                 "Categoria 5": {
                     color: "darkcyan",
                     background: require('../../assets/img/Backgrounds/cianoEscuro.png'),
                 },
                 "Categoria 6": {
                     color: "brown",
                     background: require('../../assets/img/Backgrounds/marsala.png'),
                 },
                 "Categoria 7": {
                     color: "darkgreen",
                     background: require('../../assets/img/Backgrounds/verdeEscuro.png'),
                 },
                 "Categoria 8": {
                     color: "darkorange",
                     background: require('../../assets/img/Backgrounds/laranja.png'),
                 },
             },
         };
     },
     async created() {
         this.$store.dispatch('loadListBoasPraticas');

         this.$store.dispatch('setPraticaSections');
         await this.$store.dispatch('setPraticaCategories');
         Object.keys(this.categories).forEach((cat, idx) => {
             this.categories[this.categoryNames[idx]] = this.categories[cat];
             delete this.categories[cat];
         });
         this.loaded = true;
     },
     computed: {
         categoryNames() {
             let original = this.$store.getters.getPraticaCategories;
             return original?.sort((a, b) => a.codigo - b.codigo).map(cat => cat.nome) || [];
         },
         listBoasPraticas() {
             return this.$store.getters.getListBoasPraticas;
         },
         pratica() {
             return this.listBoasPraticas.find(p => p.idPratica == this.idPratica);
         },
         avaliacoes() {
             return this.pratica?.avaliacao?.map((v, idx) => {
                 return { score: v, msg: this.pratica?.avaliacaoText[idx] || "", };
             }) || [];
         },
         idPratica() {
             return this.$route.params.idPratica;
         },
         horaCriacao() {
             return new Date(this.pratica.horaCriacao).toLocaleDateString('pt-BR');
         },
         relatedPraticas() {
             return this.listBoasPraticas.filter(praticaItem => {
                 if (praticaItem.idPratica == this.pratica.idPratica) return false;
                 for (const tematica of this.praticaCategories(praticaItem)) {
                     if (this.praticaCategories(this.pratica).includes(tematica))
                         return true;
                 }
                 return false;
             }).slice(0, 3);
         },
         isAdmin() {
             return this.$store.getters.getUserInfo?.data?.isAdmin;
         },
         sections() {
             return this.$store.getters.getPraticaSections?.map(section => section.nome) || [
                 "Seção 1",
                 "Seção 2",
                 "Seção 3",
                 "Seção 4",
                 "Seção 5",
             ];
         },
     },
     methods: {
         setEdit() {
             this.$refs.edit.dispatch("setData");
         },
         praticaCategories(pratica) {
             return pratica.boasPraticasCategorias.map(t => t.idBoasPraticasCategoria);
         },
         clipVideoId(raw) {
             if (!raw) return "";
             let url = raw.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
             return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
         },
         praticaGradient(pratica) {
             if (pratica.tematicas.length > 1)
                 return pratica.tematicas?.map(tematica => this.categories[tematica].color);
             return [
                 this.categories[pratica.tematicas[0]].color,
                 this.categories[pratica.tematicas[0]].color,
             ];
         },
         async deletePratica() {
             const result = await Swal.fire({
                 title: 'Deseja mesmo excluir essa prática?',
                 text: `Tem certeza que deseja excluir a prática ${this.pratica.titulo}`,
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: '#d33',
                 cancelButtonColor: '#3085d6',
                 cancelButtonText: 'Cancelar',
                 confirmButtonText: 'Sim, tenho certeza',
             });
             if (!result.isConfirmed) return;

             await this.$store.dispatch("deletePratica", {
                 idPratica: this.idPratica,
             });

             if (this.$store.getters.getPraticaDeleted) {
                 notyf.open({
                     type: "success",
                     message: "Prática excluída com sucesso"
                 });
                 this.$router.push("/boaspraticas/acervo");
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro ao excluir a prática!"
                 });
             }
         },
     },
 };
</script>

<style scoped>
 .outter {
     width: 100%;
     padding: 1em;
     background: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
 }

 .intro {
     background: url("~@/assets/img/Backgrounds/azul.png");
     background-repeat: repeat;
     background-size: 256px;
     padding: 10px;
     width: 100%;
     display: grid;
     grid-template-columns: 1fr 2fr;
     grid-template-rows: auto;
     row-gap: 15px;
     column-gap: 15px;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 1rem;
 }

 .intro > .info {
     display: flex;
     flex-direction: column;
     justify-content: center;
 }

 .interactive {
     cursor: pointer;
     transition: 0.4s;
 }

 .interactive:hover {
     transform: scale(1.02);
     transform-origin: center center;
 }

 .info img {
     width: 100%;
     display: block;
     border-radius: 8px;
     box-shadow: 4px 6px 6px -2px rgb(0 0 0 / 25%);
     margin-bottom: 5px;
 }

 .innerInfos > div {
     color: #444343;
 }

 .innerData {
     width: 100%;
     display: grid;
     grid-template-columns: 50% 50%;
     grid-template-rows: auto;
     column-gap: 5px;
     padding: 2px;
     font-size: 0.8em;
 }

 .innerData h6 {
     width: 100%;
     font-weight: 700;
 }

 .innerInfo {
     text-align: justify;
 }

 .themes {
     text-align: center;
 }

 .tags {
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     justify-content: center;
 }

 .tags > .tag {
     width: min-content;
     max-width: 100%;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     color: white;
     border: none;
     border-radius: 50px;
     margin: 5px;
     box-sizing: border-box;
     font-size: 0.9em;
     font-weight: bold;
     padding: 5px 8px;
     background-repeat: repeat;
     background-size: 256px;
 }

 .innerButton {
     display: block;
     margin: 5px auto;
     border: none;
     border-radius: 2em;
     color: white;
     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
     background: url("~@/assets/img/Backgrounds/verdeMedio.png");
     box-sizing: border-box;
     width: calc(100% - 30px);
 }

 .title {
     width: 100%;
     text-align: center;
     font-weight: bold;
     font-size: 1.7em;
     filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .bottom .title {
     background-image: url("~@/assets/img/Backgrounds/cianoEscuro.png");
 }

 .bottom {
     padding: 15px;
     display: grid;
     grid-template-columns: 4fr 1fr;
     grid-template-rows: auto;
     column-gap: 5px;
     row-gap: 5px;
     width: 100%;
 }

 .bottomText {
     width: auto;
 }

 .related {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     width: 100%;
     max-width: 220px;
     margin: 0 auto;
 }

 .noneFound {
     width: 100%;
     text-align: center;
     font-style: italic;
 }

 .relatedPratica {
     cursor: pointer;
     width: 100%;
     max-width: 220px;
     margin: 5px auto;
     box-sizing: border-box;
     border: double 3px transparent;
     border-radius: 1rem;
     background-origin: border-box;
     background-clip: content-box, border-box;
 }

 .relatedInner {
     color: currentColor !important;
     width: 100%;
     height: 100%;
     padding: 10px;
     display: flex;
     flex-direction: column;
     background: url("~@/assets/img/Backgrounds/laranjaMaisClaro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 1rem;
 }

 .relatedInner > img {
     border-radius: 5px;
 }

 .relatedInner > .titulo {
     font-weight: bold;
     width: 100%;
     text-align: center;
 }

 .relatedInner > .autor {
     width: 100%;
     text-align: left;
     font-size: 0.8em;
 }

 .relatedInner > .idade {
     width: 100%;
     text-align: left;
     font-size: 0.8em;
 }
 p {
     text-indent: 1em;
 }

 @media screen and (max-width: 900px) {
     .intro {
         grid-template-columns: auto;
     }

     .bottom {
         grid-template-columns: 1fr;
     }

     .related {
         flex-direction: row;
         max-width: none;
     }
 }

 .adminMenu {
     width: 100%;
     display: flex;
     flex-direction: row-reverse;
 }

 .menuBut {
     margin: 5px;
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     border-radius: 8px;
     border: 0;
     color: #fcfcfc;
 }
</style>
