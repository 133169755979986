<template>
    <!-- Navbar -->
    <nav class="navbar sticky-top navbar-expand-md bg-light navbar-light">
        <ModalLateral ref="ModalLogin" component="Login" title="Entrar"/>
        <ModalLateral ref="ModalSignUp" component="SignUp" title="Cadastre-se"/>
        
        <!-- MODAL CENTRAL DE 'PERFIL' -->
        <ModalCentral
            componentName="Profile"
            title="Perfil"
            idName="Profile"
            ref="ModalProfile"
        />

        <!-- MODAL CENTRAL DOS 'CONVITES' -->
        <ModalCentral
            componentName="UserInvites"
            title="Convites Recebidos"
            idName="UserInvites"
        />

        <div class="container d-flex justify-content-around">
            <router-link class="navbar-brand" to="/">
                <img
                    id="MDB-logo"
                    src="@/assets/img/NewHome/AppNavbar/logoipsum.svg"
                    alt="MDB Logo"
                    draggable="false"
                    height="40" />
            </router-link>
            <button
                ref="collapseButton"
                class="navbar-toggler"
                type="button"
                data-mdb-toggle="collapse"
                data-mdb-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation" >
                <i class="fa fa-bars" />
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto align-items-center">
                    <li class="nav-item">
                        <router-link class="nav-link mx-2" to="/" active-class="active" exact-active-class="exact-active">Página Inicial</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link mx-2" to="/materiaisedocumentos/instrumento" active-class="active" exact-active-class="exact-active">Materiais</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link mx-2" to="/materiaisedocumentos/oquee" active-class="active" exact-active-class="exact-active">Sobre</router-link>
                    </li>
                </ul>
                

                <ul v-if="isLogged" class="navbar-nav ms-auto align-items-center">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/dados" @click="toggleNavbar" >
                            <button type="button" class="clickable greenGradient">Dados</button>
                        </router-link>
                    </li>

                    <li class="nav-item dropdown">
                        <button type="button" class="greenGradient dropdown-toggle" id="dropdownMenu" data-mdb-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-user pr-2" aria-hidden="true"></i>
                            {{ userName }}
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li> <a class="dropdown-item" href="#Profile" data-target="#Profile" data-toggle="modal" @click="loadProfile()">Meu perfil</a> </li>
                            <li> <a class="dropdown-item" href="#" data-target="#UserInvites" data-toggle="modal" @click="loadInvites()" >Convites</a> </li>
                            <li> <a class="dropdown-item" href="#" @click="logout">Logout</a> </li>
                        </ul>
                    </li>
                </ul>


                
                <!-- login-register-buttons !-->
                <ul v-else class="navbar-nav ms-auto align-items-center">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/" >
                            <button type="button" class="clickable greenGradient" @click="openLogin">Login</button>
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link mx-2" to="/" @click="openSignUp">Cadastrar-se</router-link>
                    </li>
                </ul>

            </div>
        </div> <!-- Container !-->
    </nav>
</template>

<style scoped>
</style>

<script>
 import ModalCentral from '@components/ModalCentral';
 import ModalLateral from "@components/ModalLateral";
 import Swal from "sweetalert2";
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
     types: [
         {
             type: "info",
             background: "#18a0fb",
             icon: {
                 className: "fa fa-info-circle",
                 tagName: "i",
                 color: "white",
             },
         },
     ],
 });

 export default {
     name: 'AppNavbar',
     components: {
         ModalLateral,
         ModalCentral
     },
     data () {
         return {};
     },
     created() {
         this.$store.dispatch("setUserInfo");
     },
     computed: {
         isLogged() {
             return this.$store.getters.getIsLogged;
         },
         userName() {
             return this.$store.getters?.getUserInfo?.data?.nome?.split(" ")[0] || "Visitante";
         },
     },
     methods: {
        loadProfile() {
            this.emitter.emit('loadDataProfile');
        },
        loadInvites() {
            this.$store.dispatch('setListUserRequests', {
                notiTypes: [ "INSTITUICAO_USUARIO" ],
            })
        },
         openLogin() {
             this.$refs.ModalLogin.showModalLateral();
             this.toggleNavbar();
         },
         closeLogin() {
             this.$refs.ModalLogin.closeModalLateral();
         },
         openSignUp() {
             this.$refs.ModalSignUp.showModalLateral();
         },
         closeSingUp() {
             this.$refs.ModalSignUp.closeModalLateral();
         },
         async logout() {
             const c = await Swal.fire({
                 title: "Tem certeza que deseja sair?",
                 text: "Ao sair, todos os dados não salvos serão perdidos.",
                 icon: "question",
                 showCancelButton: true,
                 confirmButtonColor: "#d33",
                 cancelButtonColor: "#3085d6",
                 cancelButtonText: "Cancelar",
                 confirmButtonText: "Sim, tenho certeza",
             });
             if (!c.isConfirmed) return;
             await this.$store.dispatch("logout");
             this.$router.push("/");
             notyf.open({
                 type: "info",
                 message: "Logout efetuado",
             });
         },
         toggleNavbar() {
             this.$refs.collapseButton.click();
         },
     }
 }
</script>

<style scoped>

.sticky-top{
    position: fixed;
    width: 100%;
    top: 0;
}
nav li.router-link-active, 
nav li.router-link-exact-active {
    background-color: #316B3C!important;
 }

 .modal-title{
     color: #6B6B6B;
     font-family: 'Poppins', sans-serif;
     font-weight: 500;
 }

 button.close{
     box-shadow: none !important;
 }

</style>
