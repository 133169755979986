<template>
<div class="root-dados">
  <ModalCentral
    componentName="AddEmpregador"
    :title="empregadorEdit == null ? 'Adicionar empregador' : 'Atualizar empregador'"
    idName="AddEmpregador"
    :data="{ editing: empregadorEdit, listEmpregadores: empregadores }"
    />

  <div class="container list-data">
    <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-aplicacoes"/>
    <br/>

    <div class="title-row">
      <InstitutionInfo class="title-style" :title="nomeInsti" section="instituicao-cadastrar"/>
    </div>

    <p class="text-info-personal">Nesta seção, você encontrará todos os Empregadores relacionadas à Instituição. Caso necessário, utilize o filtro abaixo para localizar o Empregador desejado.</p>

    <template v-if="userPerm.permissao != 'APLICADOR'">
      <div class="lista-data-info">
        <Button
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Adicionar empregador"
          @click="empregadorEdit = null"
          :isOutline="true"
          :hasIcon="true"
          iconName="plus"
          data-toggle="modal"
          data-target="#AddEmpregador"
          />
      </div>
      <br />
    </template>

    <div class="input-group">
      <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar empregadores de uma instituição por nome..." autocomplete="off">
      <div class="input-group-append">
        <span class="input-group-text">Nome</span>
      </div>
    </div>
    <hr class="separator">

    <div v-if="isLoadedInfo">
      <MemberQnts
        :totalQnt="totalEmpregadores"
        term="empregadores"
        :filteredQnt="empregadoresFiltered?.length || 0"
        icon="user-tie" />

      <div class="list-box">
        <transition name="slide-fade" v-if="empregadoresFiltered?.length > 0">
          <div class = "table-wrap">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col">  </th>
                  <th scope="col">Nome</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <TransitionGroup name="list" appear tag="tbody">
                <tr class="table-row" v-for="empregador in empregadoresFiltered" :key="empregador.counter">
                  <td>
                    <img  class="img-info-box-lista" src="@assets/img/Dados/ListarInstis/img.png" alt="Ícone de empregador">
                  </td>

                  <td> {{ empregador.nome }}</td>

                  <td>
                    <div class="controlGroup">
                      <router-link :to="{ name: 'ListarJovens', params: { idEmpregador: empregador.idEmpregador } }">
                        <Button
                          class="orange-text"
                          value="Gerenciar jovens"
                          section="instituicao-aplicacoes"
                          :isOutline="true"
                          :hasIcon="true"
                          iconName="user-alt"
                          />
                      </router-link>

                      <Button
                        v-if="!empregador.isEditionLocked"
                        @click="empregadorEdit = empregador"
                        class="orange-text"
                        value="Atualizar empregador"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="pen"
                        data-toggle="modal"
                        data-target="#AddEmpregador"
                        />

                      <Button
                        v-if="!empregador.isDeletionLocked"
                        @click="removeEmpregador(empregador)"
                        class="orange-text"
                        value="Excluir empregador"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="trash"
                        />
                    </div>
                  </td>

                </tr>
              </TransitionGroup>
            </table>
          </div>
        </transition>

        <transition v-else>
          <h2 class="title-style-box-lista">Nenhum empregador encontrada.</h2>
        </transition>
      </div>

    </div>

    <div v-else>
      <SkeletonList/>
    </div>

  </div>
</div>
</template>

<script>
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  components: {
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    MemberQnts,
    Button,
    ModalCentral,
  },
  data: () => ({
    crumbs: [
      {
        id: 1,
        value: '',
        iconName: 'th',
        name_route: 'GerenciarInstituicoes'
      },
      {
        id: 2,
        value: 'Instituição',
        iconName: 'university',
        name_route: 'InstituicaoMenu'
      },
      {
        id: 3,
        value: 'Cadastrar Informações',
        iconName: 'search-plus',
        name_route: 'InstituicaoMenuCadastrar'
      },
      {
        id: 4,
        value: 'Empresas',
        iconName: 'building',
        name_route: 'ListarEmpresas'
      },
      {
        id: 5,
        value: 'Empregadores',
        iconName: 'user-tie',
        name_route: ''
      },
    ],
    searchByName: '',
    isLoadedInfo: null,
    empregadorEdit: null,
  }),
  async beforeMount() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao,
      idEmpresa: this.$route.params.idEmpresa,
    };

    this.$store
        .dispatch('loadListEmpregadores', payload)
        .then(() => this.isLoadedInfo = true);

    this.$store.dispatch('setUserPerm', payload);
  },
  computed: {
    userPerm() { return this.$store.getters.getUserPerm; },
    teams() {
      return this.$store.getters.getListTeams;
    },
    nomeInsti() {
      return this
        .teams
        .find(insti =>
          insti.idinstituicao == this.$route.params.idInstituicao
        )?.name;
    },
    empregadores() { return this.$store.getters.getListEmpregadores; },
    totalEmpregadores() { return this.empregadores?.length || 0; },
    empregadoresFiltered() {
      let query = this.empregadores;

      if (this.searchByName.trim() != '') {
        let filtered = query?.filter(empregador => empregador.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
        if (filtered?.length != 0)
          query = filtered;
        else
          query = false;
      }

      return query;
    },
  },
  methods: {
    async removeEmpregador(empregador) {
      const result = await Swal.fire({
        title: 'Deseja mesmo excluir o empregador?',
        text: 'Ao excluir, você não terá mais acesso às informações do empregador.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir empregador",
      })

      if (result.isConfirmed) {
        await this.$store.dispatch('removeEmpregador', {
          idInstituicao: this.$route.params.idInstituicao,
          idEmpresa: this.$route.params.idEmpresa,
          idEmpregador: empregador.idEmpregador,
        });

        if (this.$store.getters.getIsEmpregadorRemoved) {
          notyf.success("Empregador excluído com sucesso");

          this.$store
              .dispatch('loadListEmpregadores', { 
                idInstituicao: this.$route.params.idInstituicao, 
                idEmpresa: this.$route.params.idEmpresa 
              })
              .then(() => this.isLoadedInfo = true);
        } else
          notyf.error("Erro ao excluir empregador");
      }
    },
  },
}
</script>

<style scoped>
div {
    color: currentColor;
}

/* LIST TRANSITION */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
