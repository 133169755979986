<template>
    <ModalCentral
        componentName="AddInstitutionGroup"
        :data="{ id: idGrupo }"
        title="Adicionar Instituição"
        idName="idInsti"
    />

    <div class="bg">
        <div class="title-tree">
            <BreadCrumb :crumbs="crumbs" section="instituicao-cadastrar" />
        </div>

        <div class="title-info">
            <h1 class="title">Instituições</h1>
            <p class="text-info-personal imgText">
                Nesta seção, você encontrará todos as instituições do
                Grupo. Caso necessário, utilize o filtro abaixo
                para localizar a instituição desejada.
            </p>
        </div>

        <div>
            <div class="itens-box">
                <div class="filter-box">
                    <div @submit.prevent="" class="form-inline">
                        <div class="form-group">
                            <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" placeholder="Nome..." autocomplete="off">
                        </div>
                    </div>
                </div>
                <div class="lista-data-info">
                    <Button
                        v-if="isAdmin"
                        class="btn mr-2 menuButton"
                        section="dados-outline"
                        value="Adicionar Instituição"
                        :isOutline="true"
                        :hasIcon="true"
                        data-target="#idInsti"
                        data-toggle="modal"
                        iconName="university" />
                </div>
            </div>
            <hr class="separator">

            <div class="listContainer" v-if="loaded">
                <InstisQnt :totalQnt="totalInstis" :filteredQnt="institutions.length" />
                <div class="list-box" v-if="institutions.length">
                    <div
                        v-for="institution in institutions"
                        :key="institution.idinstituicao"
                        class="institution">
                        <img
                            :src="thumbnailPath(institution)"
                            :alt="`Instituição '${institution.name}'`" />

                        <div class="name">{{ institution.nome }}</div>

                        <router-link :to="{ name: 'GrupoSubInsti', params: { idInstituicao: institution.idinstituicao, } }">
                            <Button
                                class="instiButton"
                                value="Acessar"
                                :isOutline="true"
                                :isDisabled="!isAdmin" />
                        </router-link>

                        <Button
                            @click="unlinkInsti(institution.idinstituicao)"
                            class="instiButton"
                            value="Excluir"
                            :isOutline="true"
                            :isDisabled="!isAdmin"
                            :hasIcon="true"
                            iconName="trash" />
                    </div>
                </div>
                <div v-else class="emptyList">
                    Nenhuma instituição correspondente!
                </div>
            </div>
            <div v-else>
                <SkeletonList />
            </div>
        </div>
    </div>
</template>


<script>
 import Button from '@components/Button';
 import ModalCentral from '@components/ModalCentral';
 import InstisQnt from '@components/Dados/VisualizarGrupo/InstisQnt';
 import SkeletonList from '@components/SkeletonUI/SkeletonList';
 import BreadCrumb from '@components/Breadcrumb'

 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "GrupoMenu",
     data () {
         return {
             idGrupo: null,
             loaded: false,
             isActiveMiniDropdown: false,
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Grupo de Pesquisa'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarGrupos'
                 },
                 {
                     id:2,
                     value: 'Grupo',
                     iconName: 'university',
                     name_route: 'GrupoMenu'
                 },
                 {
                     id:3,
                     value: 'Instituições',
                     iconName: 'user-friends',
                     name_route: ''
                 }
             ],
         }
     },
     methods: {
         showMiniDropdown: function() {
             this.isActiveMiniDropdown = true
         },
         closeMiniDropdown: function() {
             this.isActiveMiniDropdown = false
         },
         setSelectedValue: function() {
             //! TODO: só pra parar de dar erro no console
         },
         thumbnailPath(institution) {
             try {
                 return require('../../../' + institution.thumbnail);
             } catch (e) {
                 return require('@assets/img/Dados/ListarInstituicao/Instituicoes/instituicao1.jpg');
             }
         },
         async unlinkInsti(id) {
             await this.$store.dispatch('unlinkInstis', {
                 idGrupo: this.idGrupo,
                 instiIds: [id],
             });

             if (this.$store.getters.getAreInstitutionsUnlinked) {
                 this.$store.dispatch('loadListInstis', { idGrupo: this.idGrupo });
                 this.$store.dispatch('setListTeams');
                 this.$store.dispatch('setListGroupRequests', {
                     idGrupo: this.idGrupo,
                     notiTypes: [ "GRUPO_INSTITUICAO" ],
                 });

                 notyf.open({
                     type: "success",
                     message: "Instituição excluída com sucesso",
                 });
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro ao excluir instituição",
                 });
             }
         }
     },
     async created() {
         this.idGrupo = this.$route.params.idGrupo;
         await this.$store.dispatch('loadListInstis', { idGrupo: this.idGrupo });
         this.$store.dispatch("groupPerm", { idGrupo: this.idGrupo })
         this.loaded = true;
     },
     computed: {
         userPerm() {
             return this.$store.getters.getUserPerm;
         },
         institutions() {
             let query = this.$store.getters.getListInstis;
             query = query?.sort((a, b) => a?.name?.localeCompare(b?.name));

             if (this.searchByName.trim() != '')
                 query = query?.filter(insti => insti?.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

             return query;
         },
         totalInstis() {
             return this.$store.getters.getListInstis.length;
         },
         groupPerm() {
             return this.$store.getters.getGroupPerm(this.idGrupo) || {}
         },
         isAdmin() {
             return this.groupPerm?.idPermissao == 3
         },
     },
     components: {
         Button,
         ModalCentral,
         SkeletonList,
         InstisQnt,
         BreadCrumb,
     },
 }
</script>


<style scoped lang="scss">
 @import "../../assets/scss/_variables.scss";

 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .overlay-invisible {
     position: fixed;
     top: 0; right: 0; bottom: 0; left: 0;
     background-color: rgba(22, 22, 22, 0.0);
     z-index: 2;
     transition: all ease-in-out 0.3s;
     opacity: 0;
     pointer-events: none;
 }

 .overlay-visible {
     opacity: 1;
     pointer-events: all;
     cursor: default;
 }

 .container-view {
     margin: 3rem 8rem;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     flex-direction: column;
 }
 .banner-sup {
     display: flex;
     width: 80%;
     max-width: 1200px;
     min-width: 300px;
 }
 .img-banner {
     height: 100%;
     width: 100%;
     border-radius: 0.8rem  0rem  0rem 0.8rem;
 }
 .left-side {
     flex: 30%;
 }
 .right-side {
     display: flex;
     flex-direction: column;
     justify-content: center;
     padding: 2rem;
     background-color: white;
     flex: 70%;
     border-radius: 0 0.8rem 0.8rem 0;
     position: relative;
 }
 .right-side h1 {
     font-size: 1rem;
     font-weight: bold;
 }
 .right-side p {
     font-size: 0.9rem;
     font-weight: lighter;
     text-align: justify;
     margin: 0;
 }

 .fa-cog {
     cursor: pointer;
     font-size: 1.4rem;
     position: absolute;
     top: 8%;
     right: 4%;
 }

 .item-minidropdown {
     color: #656565;
 }

 .item-minidropdown:hover {
     color: rgb(58, 58, 58);
 }

 .mini-triangle{
     margin: 0rem;
     display: center;
     align-items: center;
     justify-items: center;
     position: absolute;
     top: -16%;
     left: 80%;

 }
 .dropdown-box {
     position: absolute;
     top: 188%;
     left: -480%;
     z-index: 2;
     opacity: 0;
     pointer-events: none;
     cursor: default;
     transition: all 0.4s ease;
 }

 .dropdown-box-visible{
     opacity: 1;
     cursor: pointer;
     pointer-events: all;
 }

 .mini-dropdown {
     position: relative;
 }

 .mini-pop-up{
     height: 5.5rem;
     width: 8rem;
     background-color:white;
     color:grey;
     border-radius: 1rem;
     transition: color ease-in-out 0.2s;
     font-size: 1rem;
     font-weight: 300;
     font-family: 'Hind', sans-serif;
 }

 .item-menu-dropdown {
     margin: 1rem 0;
 }

 .label-filtro {
     font-weight: 600;
 }

 .filter-box {
     margin: 1rem 3rem;
 }

 .list-box {
     margin: 2rem;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }


 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
     margin: 2rem 3rem;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 0.8rem 3rem;
     background-image: url("~@/assets/img/Backgrounds/azulEapi.png");
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 .listContainer {
     width: 100%;
 }

 .emptyList {
     font-weight: bold;
     text-align: center;
     width: 100%;
     padding: 10px;
     color: #656565;
     font-size: 1.2em;
 }

 .institution {
     width: 100%;
     padding: 1em;
     align-items: center;
     box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
     border-radius: 8px;
     margin-bottom: 10px;
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }

 .institution > img {
     width: 100%;
     max-width: 160px;
     display: block;
 }

 .institution > .name {
     width: max-content;
     text-align: center;
     font-size: 1.1em;
     font-weight: bold;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
     }

     .img-banner{
         border-radius: 1rem 1rem 0 0;
     }

     .banner-sup{
         flex-direction: column;
     }

     .right-side{
         border-radius: 0 0 1rem 1rem;
     }
 }

 .title-info {
     padding: 0 3rem;
     box-sizing: border-box;
     margin: 20px auto 0 auto;
 }

 .title-info > p {
     margin: 0;
 }

 .title-info > .title {
     font-weight: 600;
     font-size: 1.6rem;
     color: #535353;
     margin: 0;
     margin-top: 0.8rem;
 }

 .text-info-personal {
     color: #656565;
     font-size: 1.1rem;
     text-align: justify;
     padding: 1rem 0rem 0rem 0rem;
 }

 .institution {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.png");

     border: 1px solid #952713;
 }

 .institution span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .instiButton {
     background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.png");
     background-repeat: repeat;
     background-size: 256px;
     border: 0;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     color: #fcfcfc;
 }

 .instiButton span {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

</style>
