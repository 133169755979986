<template>
    <ModalCentral
        v-if="userPerm"
        componentName="EditCard"
        :title="idCard ? 'Editar card' : 'Adicionar card'"
        idName="editCard"
        :data="{ idCard: idCard, ordem: order, idTipo: idTipo }"
    />

    <div class="root container">

        <div class="info-card row project-metrics p-4 mb-6"
            v-for="card in materiaisTop"
            :key="card.idCard">
            <div class="d-flex justify-content-between">
                <h1 class="card-title">
                    {{ card?.titulo }}
                </h1>
                <div class="card-options text-center" v-if="userPerm">
                    <button type="button" class="btn btn-success btn-circle" @click="setIdCard(card.idCard)" data-toggle="modal" data-target="#editCard">
                        <i class="fas fa-pen"/>
                    </button>
                    <button type="button" class="btn btn-success btn-circle" @click="deleteCard(card.idCard)">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
            </div>

            <div class="card-text row mb-6">
                <img class="img-fluid col-lg-6 col-md-12 col-sm-12"
                    v-if="card?.imagem?.length > 0"
                    :src="getImage(card?.imagem[0])"
                    :alt="card?.titulo" />
                <p class="col-lg-6 col-md-12 col-sm-12" v-html="card?.corpoTexto"></p>
            </div>     
        </div>


        <div v-if="userPerm" class="text-center mb-6">
            <button type="button" 
            @click="setIdCard(parseInt(cards.length) + 1, true, 42)"
            data-toggle="modal"
            data-target="#editCard"
            class="btn btn-success btn-circle">
            <i class="fas fa-plus"></i>
            </button>
        </div>

        <div class="d-flex flex-column align-items-center mt-6 mb-6"
                v-for="card in materiaisMid"
                :key="card.idCard">
                <div class="card-options text-center mb-4 p-2" v-if="userPerm">
                    <button type="button" class="btn btn-success btn-circle" @click="setIdCard(card.idCard)" data-toggle="modal" data-target="#editCard">
                        <i class="fas fa-pen"/>
                    </button>
                    <!-- <button type="button" class="btn btn-success btn-circle" disabled>
                        <i class="fas fa-trash"></i>
                    </button> -->
                </div>
                <a
                    v-for="(button, idx) in card?.botao"
                    :key="idx"
                    :href="button?.link"
                    target="_blank"
                    class="text-center clickable greenGradient p-2">
                    {{ button?.texto }}
                </a>
        </div>

        <div class="info-card project-metrics p-4 mb-6"
             v-for="card in materiaisBot"
             :key="card.idCard">
            <div class="d-flex justify-content-between">
                <h1 class="card-title">
                    {{ card?.titulo }}
                </h1>
                <div class="card-options text-center" v-if="userPerm">
                    <button type="button" class="btn btn-success btn-circle" @click="setIdCard(card.idCard)" data-toggle="modal" data-target="#editCard">
                        <i class="fas fa-pen"/>
                    </button>
                    <!-- <button type="button" class="btn btn-success btn-circle disabled">
                        <i class="fas fa-trash"></i>
                    </button> -->
                </div>
            </div>
            <div class="card-text row">
                <p class="col-7" v-html="card?.corpoTexto"></p>
                <div class="d-flex flex-column justify-content-between col">
                    <a
                    v-for="(button, idx) in card?.botao"
                    :key="idx"
                    :href="button.link"
                    target="_blank"
                    class="card-button clickable greenGradient p-2">
                    {{ button.texto }}
                    </a>
                </div>
            </div>
        </div>
    </div>

</template>

<script type="text/javascript">
 import ModalCentral from '@components/ModalCentral';
 import axios from '@middleware/api.js';
 import Swal from 'sweetalert2';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 });

export default {
  name: "Instrumentos",
  components: {
      ModalCentral,
  },
  data() {
      return {
          idCard: null,
          order: null,
          idTipo: null,
      };
  },
  async created() {
      this.$store.dispatch("setListCards");
  },
  methods: {
    setIdCard(id, newCard, idTipo) {
             this.idCard = newCard ? null : id;
             this.order = newCard ? id : null;
             this.idTipo = newCard ? idTipo : this.cards.find(card => card.idCard == id)?.idTipo;
         },
     getImage(path) {
         return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
     },
     async deleteCard(idCard) {
        const result = await Swal.fire({
            title: 'Deseja realmente excluir esse?',
            text: 'Tem certeza que deseja excluir esse card? Essa operação não pode ser desfeita.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, tenho certeza'
        });
        if (!result.isConfirmed) return;

        await this.$store.dispatch("deleteCard", idCard);

        if (this.$store.getters.getCardDeleted) {
            notyf.open({
                type: "success",
                message: "Card excluído com sucesso",
            });
            this.$store.dispatch("setListCards");
        } else {
            notyf.open({
                type: "error",
                message: "Não foi possível excluir o card",
            });
        }
    },
  },
  computed: {
      userPerm() {
          try{
              return this.$store.getters.getUserInfo.data.isAdmin;
          } catch(e) {
              return false;
          }
      },
      cards() { return this.$store.getters.getListCards; },
      materiaisTop() { return (this.cards || []).filter(card => card?.idTipo == 42); },
      materiaisMid() { return (this.cards || []).filter(card => card?.idTipo == 43); },
      materiaisBot() { return (this.cards || []).filter(card => card?.idTipo == 44); },
  },
};
</script>
<style scoped>

@import "../../assets/css/MateriaiseDocumentos/main.css";

 .greenGradient button{
    width: 100%;
    min-height: 3rem;
    max-width: 60rem;   
 }

 .border-right{
    border-color: #00853B;
 }

 .card-button + .card-button{
    margin-top: 10px;
 }

a.greenGradient{
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    color: initial;

    text-align: center;
    font-weight: bolder;
    border-radius: 8px;
    line-height: 2em;
    padding: 12px 20px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);
}

p, li, ::v-deep li{
    text-align: justify;
    color: #6B6B6B;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 28px !important;
}

img{
    object-fit: cover;
}

</style>
