//---------------------------------------------
// Turmas Module 
//---------------------------------------------
// Módulo para gerenciar as ações das turmas

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listTurmas: [],

    //VERIFICACAO DE ERRO
    isCreateTurmaCSV: false,
    isTurmaCreated: false,
    isTurmaUpdated: false,
    isTurmaDeleted: false,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DO LISTTURMAS
    setListTurmas(state, payload) {
        state.listTurmas = payload
    },

    setIsCreateTurmaCSV(state, payload) {
        state.isCreateTurmaCSV = payload
    },
    setIsTurmaCreated(state, payload){
        state.isTurmaCreated = payload
    },
    setIsTurmaUpdated(state, payload){
        state.isTurmaUpdated = payload
    },
    setIsTurmaDeleted(state, status) {
        state.isTurmaDeleted = status;
    },
}

// ACTIONS
const actions = {
     //-- ACTION REQUISICAO DE CRIAR TURMA
    async setCreateTurma( {commit}, payload)  {
        await axios
            .post(`turmas/${payload.idInstituicao}/${payload.idUnidade}`, {
                nome: payload.nome,
                codigoTurma: payload.codigoTurma,
                tipoPeriodo: payload.tipoPeriodo,
                tipoIdade: payload.tipoIdade,
                tipoTurma: payload.tipoTurma
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => {
                commit('setIsTurmaCreated', true)
            })
            .catch((error) => {
                console.log(error)
                commit('setIsTurmaCreated', false)
            })
    },

    //-- ACTION REQUISICAO DE ENVIAR ARQUIVO PARA CRIAR TURMA
    async setSendClassFile( {commit}, payload)  {
        var formData = new FormData();
        if (payload.file) {
            formData.append("file", payload.file)
        }
        await axios
            .post(`turmas/upload/${payload.idInstituicao}/${payload.idUnidade}`, 
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(
                commit('setIsCreateTurmaCSV', true)
            )
            .catch((error) => {
                console.log(error.message)
                commit('setIsCreateTurmaCSV', false)
            })
    },

    //-- ACTION REQUISICAO DO LISTTURMAS
    async setListTurmas({ commit }, payload) {
        await axios
            .get(`turmas/${payload.idInstituicao}/${payload.idUnidade}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListTurmas', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE TURMA
    async deleteTurma({ commit }, payload) {
        commit('setIsTurmaDeleted', null);

        await axios
            .delete(`turmas/${payload.idInstituicao}/${payload.idUE}/${payload.idTurma}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => commit('setIsTurmaDeleted', true))
            .catch((error) => {
                console.log(error.message);
                commit('setIsTurmaDeleted', false);
            });
    },

    async updateTurma({ commit }, payload) {
        commit('setIsTurmaUpdated', true);

        await axios
            .put(
                `turmas/${payload.idInstituicao}/${payload.idUnidade}/${payload.infoClass.idturma}`, {
                    ...payload.infoClass,
                }, {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                }
            )
            .then(() => commit('setIsTurmaUpdated', true))
            .catch(err => {
                console.log(err);
                commit('setIsTurmaUpdated', false);
            });
    },
}

// GETTERS
const getters = {
    getListTurma(state) {
        try {
            return state.listTurmas.data
        } catch (e) {
            return []
        }
    },

    getIsCreateTurmaCSV(state) {
        return state.isCreateTurmaCSV
    },
    
    getIsTurmaCreated(state) {
        return state.isTurmaCreated;
    },

    getIsTurmaUpdated(state) {
        return state.isTurmaUpdated;
    },

    getIsTurmaDeleted(state) {
        return state.isTurmaDeleted;
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
