<template>
    <div class="container offset">
        <form @submit.prevent="addForm" class="customForm">
            <div class="form-group">
                <label class="question-style pt-1">Nome do formulário: *</label>
                <input
                    v-model.trim="formData.nome"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o nome do formulário..."
                    required
                />
            </div>

            <div class="form-group">
                <label class="question-style pt-1">Identificador do formulário: *</label>
                <select
                    v-model.trim="formData.identificador"
                    class="form-control"
                    >
                    <option value="cpf" selected>
                        CPF
                    </option>
                    <option value="código" >
                        Código único (gerado automaticamente)
                    </option>
                    <option value="CAMPO_ABERTO" >
                        Outro
                    </option>
                </select>

                <input
                    v-if="formData.identificador == 'CAMPO_ABERTO'"
                    style="margin-top: 1em;"
                    v-model.trim="nomeId"
                    type="text"
                    class="form-control"
                    placeholder="Nome do identificador do formulário"
                    required
                />
            </div>

            <div class="form-group uniqueId" v-if="formData.identificador != 'código'">
                <label class="question-style pt-1">
                    <input
                        v-model="formData.isUniqueId"
                        type="checkbox"
                    /> Aceita múltiplas respostas com o mesmo identificador*
                </label>
            </div>

            <div class="form-group">
                <label class="question-style pt-1">Contexto selecionado:</label>
                <div class="contexto formBorder">
                    <div id="topInfo">
                        <i class="fas fa-search"></i>
                        <div id="title">
                            <h5 style="font-weight: 700">
                                {{ contexto?.titulo }}
                            </h5>
                            <h6>{{ contexto?.modelo }}</h6>
                        </div>
                    </div>
                    <span>{{ contexto?.descricao }}</span>
                </div>
            </div>

            <div class="form-group">
                <label class="question-style pt-1">Items selecionados:</label>
                <div class="fatores formBorder text-left">
                    <span class="title text-left">Nome</span>
                    <span class="title text-left">Modelo</span>
                    <template
                        v-for="fator of data.fatores"
                        :key="fator?.idItem"
                    >
                        <span class="text-left">
                            <i class="fas fa-clipboard"></i>
                            {{ fator?.titulo }}
                        </span>
                        <span class="text-left">{{ fator?.modelo }}</span>
                    </template>
                </div>
            </div>

            <div class="form-group">
                <label class="question-style pt-1">Perguntas criadas:</label>
                <div class="perguntas formBorder text-left">
                    <template v-if="data.perguntas.length > 0">
                        <div
                            v-for="pergunta in data.perguntas"
                            :key="pergunta.ordination"
                            class="pergunta"
                        >
                            <div class="pergunta-head">
                                <h6 class="mb-1">
                                    Pergunta 0{{ pergunta.ordination }} :
                                    {{ pergunta.enunciado }}
                                </h6>
                                <span
                                    >Tipo:
                                    {{
                                        'text' in pergunta.questionType
                                            ? "Objetiva"
                                            : 'radio' in pergunta.questionType
                                            ? "Seleção única"
                                            : "Objetiva"
                                    }}</span
                                >
                            </div>
                            <div
                                v-if="
                                    !('text' in pergunta.questionType)
                                "
                                class="pergunta-opcoes"
                            >
                                <template v-if="'radio' in pergunta.questionType">
                                    <div
                                        v-for="op in pergunta.questionType.radio.options"
                                        :key="op.id"
                                        class="pergunta-item"
                                    >
                                        <div
                                            class="outline-radio"
                                        ></div>
                                        <span v-html="op.optionEnum"></span>
                                    </div>
                                </template>

                                <template v-if="'select' in pergunta.questionType">
                                    <div
                                        v-for="op in pergunta.questionType.select.options"
                                        :key="op.id"
                                        class="pergunta-item"
                                    >
                                        <div
                                            class="outline-square"
                                        ></div>
                                        <span v-html="op.optionEnum"></span>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                    <h6 v-else class="mb-0">
                        Não há novas perguntas criadas para este formulário.
                    </h6>
                </div>

                <br />

                <div class="form-group">
                    <label class="question-style">Termo de privacidade do formulário:</label>
                    <FileInput
                        ref="termInput"
                        :file="formData?.termo"
                        :types="['application/pdf']" />
                </div>
            </div>

            <Button
                class="btn ml-2 greenGradient clickable"
                section="instituicao-campo"
                :value="`${idForm ? 'Editar' : 'Criar'} formulário`"
                :isOutline="true"
                :hasIcon="false"
            />
        </form>
    </div>
</template>

<script>
import Button from "@components/Button";
import FileInput from '../FileInput.vue';
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
    name: "CreateForm",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            formData: {
                idInstituicao: null,
                nome: "",
                idTipoForm: "1",
                idItem: [],
                complementares: null,
                identificador: 'cpf',
                isUniqueId: false,
              termo: null,
            },
          nomeId: "",
            contexto: {},
            newQuestions: [],
        };
    },
    watch: {
        data(updated) {
            this.contexto = { ...updated?.contexto };
            this.newQuestions = [...updated?.perguntas];
            this.formData.idItem = [
                this.contexto,
                ...(updated.fatores || []),
            ].map((item) => `${item?.idItem}`);
            this.formData.complementares = this.newQuestions.length > 0 ? JSON.stringify({
                id: 1000,
                categoryName: 'Complementar',
                questions: this.newQuestions
            }) : null;
            if (updated?.formName) this.formData.nome = updated?.formName;
          this.formData.termo = updated?.termo;
          this.formData.identificador = updated?.identificador || 'cpf';
          if (!['cpf', 'código'].includes(this.formData.identificador)) {
            this.formData.identificador = 'CAMPO_ABERTO';
            this.nomeId = updated?.identificador;
          }
          this.formData.isUniqueId = !(updated.isUniqueId);
        },
    },
    components: {
        Button,
      FileInput,
    },
    mounted() {
        this.formData.idInstituicao = this.$route.params.idInstituicao;
        if (this.idForm) this.formData.idForm = this.idForm;
    },
    methods: {
        async addForm() {
            if (!this.formData?.nome || this.formData?.nome?.length == 0) {
                notyf.open({
                    type: "info",
                    message: "Informar nome do formulário!",
                });
                return;
            }

            await this.$store.dispatch(
                this.idForm ? "updateForm" : "createForm",
                {
                  ...this.formData,
                  idUniqueId: this.formData.identificador != 'código' ? JSON.stringify(false) : this.formData.isUniqueId,
                  identificador: this.formData.identificador == 'CAMPO_ABERTO' ? this.nomeId : this.formData.identificador,
                  ...(this.$refs.termInput.getAltered() && { pdf: this.$refs.termInput.getFile() }),
                  ...(this.idForm && { updateTermo: this.$refs.termInput.getAltered() }),
                },
            );
            if (
                this.idForm
                    ? !this.$store.getters.getUpdatedForm
                    : !this.$store.getters.getCreatedForm
            ) {
                notyf.open({
                    type: "error",
                    message: `Erro ao ${
                        this.idForm ? "editar" : "criar"
                    } formulário!`,
                });
                return;
            }

            notyf.open({
                type: "success",
                message: `Formulário ${
                    this.idForm ? "editado" : "criado"
                } com sucesso`,
            });
            this.$emit("closeModal");
            this.$router.push({ name: "CampoListaFormularios" });
        },
    },
    computed: {
        idForm() {
            return this.$route.params.idForm;
        },
    },
};
</script>

<style scoped>
.contexto {
    width: 100%;
    padding: 1rem;
}

.formBorder {
    border: 1px solid #00853b;
    border-radius: 8px;
}

.contexto > #topInfo {
    color: #00853b;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1em;
    margin-bottom: 0.75em;
}

#topInfo > i {
    flex: 0 0 min-content;
    font-size: 2em;
}

#topInfo > #title {
    flex: 1;
}

#title > h2,
#title > h4 {
    color: #00853b;
}

#title > h2 {
    font-size: 1.5em;
}

#title > h4 {
    font-size: 0.9em;
}

.fatores {
    padding: 1em;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr;
    max-height: 200px;
    overflow: auto;
}

.perguntas {
    padding: 1em;
    width: 100%;
    display: grid;
    gap: 1rem;
    grid-template-columns: 1fr;
    max-height: 200px;
    overflow: auto;
}

.perguntas > .pergunta {
    box-shadow: 0px 8px 24px -4px rgba(24, 39, 75, 0.08),
        0px 6px 12px -6px rgba(24, 39, 75, 0.12);
    border-radius: 12px;
    overflow: hidden;
}

.pergunta-head {
    background-color: var(--light-green);
    padding: 1em 1em 0.75em 1em;
}

.pergunta-head h6 {
    color: var(--font-white);
    font-weight: 600;
}

.pergunta-head span {
    color: var(--font-white);
    font-weight: 500;
    font-size: 0.9rem;
}

.pergunta-opcoes {
    padding: 0.75em 1em;
    display: grid;
    gap: 0.25em;
}
.pergunta-item {
    display: flex;
    gap: 0.5em;
    align-items: center;
}

:deep(.pergunta-item p){
    margin-bottom: 0;
}

.outline-square {
    width: 1em;
    height: 1em;
    border: 1px solid rgba(0, 133, 59, 1);
    border-radius: 2px;
}

.outline-radio {
    min-width: 1em;
    width: 1em;
    height: 1em;
    border: 1px solid rgba(0, 133, 59, 1);
    border-radius: 50%;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.fatores .title {
    color: #00853b;
    font-weight: bold;
    padding-top: 0px;
}

.fatores span {
    display: flex;
    align-items: baseline;
    padding: 0.75em 0.5em;
    border-bottom: 1px solid black;
}

.fatores span > i {
    color: black;
    margin-right: 1rem;
}

.fatores span:last-child,
.fatores span:nth-last-child(2) {
    border-bottom: none;
}

.clickable {
    margin: 0;
    float: right;
}

.uniqueId {
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
