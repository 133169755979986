<template>
    <div class="root-dados">
    <div class="container list-data">

        <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br/>

            <h3 class="title-info"> Devolutivas </h3>

            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
            <div class="lista-data-info">

            </div>
            </div>

        <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todas as Devolutivas da Instituição. Caso necessário, utilize o filtro abaixo para localizar a Devolutiva desejada.</p>
        
        <div class="itens-box">
        <div class="filter-box">
            <!-- INICIO DO FORM DE FILTRAGEM -->
            <form @submit.prevent="" class="form-inline">
                <div class="form-group">
                
                <!-- DROPDOWN DE SELECAO DE FILTRO -->
                <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomSelect">Filtrar por: </label>
                    <Filter ref="filtersearchname" @click="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
                </div>

                <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
                <div class="form-group" v-if="selectedValue == 2">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
                    <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">
                
                    <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="cres" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioAZ" checked>
                    <label class="mr-sm-2 text-nowrap label-filtro mr-sm-4" for="inlineFormCustomRadioAZ">A - Z</label>
                    
                    <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="desc" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioZA">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomRadioZA">Z - A</label>
                </div>

                <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
                <div class="form-group" v-if="selectedValue == 3">
                    <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
                    <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
                </div>

            </form>
        </div>
        </div>
        <hr class="separator">
        
        <div class="list-box">
            <ListagemElement :infos="devolutivas" listaType="listarDevolutivas"/>
        </div>

    </div>
    </div>
</template>

<script>
import Filter from '@components/Filter'
import ListagemElement from '@dados/VisualizarInstituicao/ListagemElement'
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'

export default {
    name: 'CampoListaFormularios',
    data (){
        return {
            nomeInstituicao: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            searchByCode: '',
            searchWithOrder: '',
            devolutivas: [
                // {
                //     id: 1,
                //     cidade: 'Ribeirão Preto',
                //     inicio: '29/01/2020',
                //     encerrado: '10/03/2021'
                // },
                // {
                //     id: 2,
                //     cidade: 'Ribeirão Preto',
                //     inicio: '11/04/2019',
                //     encerrado: '21/05/2019'
                // },
                // {
                //     id: 3,
                //     cidade: 'Ribeirão Preto',
                //     inicio: '01/05/2018',
                //     encerrado: '01/08/2018'
                // }
            ],
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome'
                },
                {
                id:3,
                value: 'Grupo de Pesquisa'
                }
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:4,
                    value: 'Devolutivas',
                    iconName: 'chart-bar',
                    name_route: ''
                }
                
            ]
        }
    },
    components: {
        Filter,
        ListagemElement,
        Breadcrumb,
        InstitutionInfo
    },
    methods: {
        applyChanges: function() {
            Swal.fire(
                'Alterações aplicadas!',
                'As seleções foram aplicadas com sucesso.',
                'success'
            )
        },
        setSelectedValue: function() {
            //! TODO: só pra parar de dar erro no console
        }
    },
    created() {
        this.teams = this.$store.getters.getListTeams
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>

@import "../../../assets/css/Dados/list.css";

.breadcrumb-style{
    display: inline-flex;
}

.title-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.text-info-personal {
  color: #fff;
  font-weight: 450;
  font-size: 1.1rem;
  text-align: justify;
}

.label-filtro {
    font-weight: 600;
}

 :deep(.btnLista div) {
     color: #fff;
     text-align: center;
 }


.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-info{
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
}
.title-style{
    margin: 0;
    padding: 0;
}

 h3.title-info{
    margin-top: 1.6rem;
    font-size: 1.25rem;
 }

.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.main-link {
    outline:none;
    text-decoration: none;
    cursor: pointer;
    margin-right: .3rem;
}

.sub-link {
    margin-left: .3rem;
}

.separator {
    border: none;
    height: 1px;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-items: flex-start;
    }

    .filter-box{
        margin: 0 3rem;
    }

    .lista-data-info{
        margin: 0 3rem;
    }
}
    
.table-wrap{
    box-sizing: border-box;
    background: #fff;
    border-radius: 8px;
    box-shadow: 2px 6px 15px 4px rgba(0, 0, 0, 0.25);

    padding: 0em 1em;

    text-align: left;
}


.table > thead > tr:first-child > th,
.table > tbody > tr:first-child > td{
    border-top: none;
}

.table > thead > tr:first-child > th{
    border-bottom: 2px solid #00853B;
}

.table > thead > tr > th{
    color: #00853B;
    font-weight: 700;
}

</style>
