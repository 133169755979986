<template>
    <footer class="text-dark text-center text-lg-start custom-footer bg-light">
    <!-- Grid container -->
    <div class="container p-4">

        <!--Grid row-->
        <div class="d-flex row justify-content-between">
        <!--Grid column-->
        <div class="col-lg-6 col-md-12 mb-4 mb-md-0">
            <h5 class="text-uppercase">A iniciativa</h5>
            <p> O projeto escola trabalho foi idealizado pelas seguintes organizações: </p>
            <div class="logos">
                <a class="ripple clickable" href="http://lepes.fearp.usp.br/" target="_blank">
                    <img src="@/assets/img/NewHome/AppFooter/logo-lepes.png" class="img-fluid" alt="Logo LEPES"/>
                </a>
                <a class="ripple clickable" href="https://www.itaueducacaoetrabalho.org.br/" target="_blank">
                    <img src="@/assets/img/NewHome/AppFooter/logo-itau.png" class="img-fluid" alt="Logo ITAU"/>
                </a>
                <a class="ripple clickable" href="https://www.movetech.dev/" target="_blank">
                    <img src="@/assets/img/NewHome/AppFooter/logo-move.png" class="img-fluid" alt="Logo MOVE"/>
                </a>
            </div>
        </div>
        <!--Grid column-->

        <!--Grid column-->
        <div class="col-lg-3 col-md-6 mb-4 mb-md-0">
            <h5 class="text-uppercase">Menu</h5>

            <ul class="list-unstyled mb-0">
            <li>
                <a href="/home" class="text-dark">Página Inicial</a>
            </li>
            <li>
                <a href="/dados/gerenciar" class="text-dark">Dados</a>
            </li>
            <li>
                <a href="/materiaisedocumentos/oquee" class="text-dark">Sobre</a>
            </li>
            <li>
                <a href="/materiaisedocumentos/instrumento" class="text-dark">Materiais</a>
            </li>
            </ul>
        </div>
        <!--Grid column-->
        </div>
        <!--Grid row-->
    </div>
    <!-- Grid container -->

    <!-- Section: Social media -->
    <section class="text-center p-2 gradient-custom">
        <!-- Facebook -->
        <!-- <a class="btn btn-outline-light btn-floating m-2" href="#!" role="button">
            <i class="fab fa-facebook-f"></i>
        </a> -->

        <!-- Twitter -->
        <!-- <a class="btn btn-outline-light btn-floating m-2" href="#!" role="button"
            ><i class="fab fa-twitter"></i>
        </a> -->

        <!-- Google -->
        <!-- <a class="btn btn-outline-light btn-floating m-2" href="#!" role="button"
            ><i class="fab fa-google"></i>
        </a> -->

        <!-- Instagram -->
        <!-- <a class="btn btn-outline-light btn-floating m-2" href="#!" role="button"
            ><i class="fab fa-instagram"></i>
        </a> -->

        <!-- Linkedin -->
        <!-- <a class="btn btn-outline-light btn-floating m-2" href="#!" role="button"
            ><i class="fab fa-linkedin-in"></i>
        </a> -->

        <!-- Github -->
        <!-- <a class="btn btn-outline-light btn-floating m-2" href="#!" role="button"
            ><i class="fab fa-github"></i>
        </a> -->
    </section>
    <!-- Section: Social media -->
    </footer>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>

<style type="text/css" media="screen">
 .logos {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-around;
     align-items: center;
     gap: 10px;
 }
</style>
