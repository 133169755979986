<template>
    <div class="apresentacao">

        <h2 class="pageTitle text-img-clipper">Entenda sobre a importância da qualidade na Educação Infantil</h2>

        <div class="infoCard cardRight">
            <div class="text">
                <span>
                    <h2 class="titlePraticas titleRight text-img-clipper">POR QUE VOLTAR A ATENÇÃO PARA A QUALIDADE DA EDUCAÇÃO?</h2>
                    <p>É na primeira infância, do nascimento até os 6 anos, que grande parte do nosso potencial humano é construído, isso se deve à elevada capacidade de aprender pela qual passamos, particularmente, nessa fase da vida. Os cérebros dos bebês, das crianças bem pequenas e pequenas têm a capacidade de serem moldados pelas experiências vividas de uma forma que não ocorre em nenhum outro momento.</p>
                    <p>Por isso, esta etapa caracteriza-se como primordial para que o alicerce que dará apoio para as aprendizagens se forme. Entende-se que as experiências pelas quais as crianças passarão nessa época vão impactar em todo o seu desenvolvimento ao longo da vida, seja positivamente para que ocorra de forma plena, ou de maneira negativa, causando riscos ao desenvolvimento integral.</p>
                    <p>É de suma importância compreender quais são os aspectos para os quais precisamos dar especial atenção quando estamos considerando as experiências a serem promovidas na Educação Infantil. O fato é que para tratar de qualidade e impulsionar o desenvolvimento integral das nossas crianças é preciso buscar ir além de assegurar boas condições fisiológicas, como alimentação e saúde. É preciso olhar para outros componentes fundamentais que vão nortear aquilo que é preciso considerar para assegurar a qualidade na Educação Infantil, como o afeto e a brincadeira. Dados científicos sinalizam sobre como as diferenças nas oportunidades que as crianças têm de vivenciar o cotidiano em ambientes acolhedores e estimulantes são também fonte de desigualdade de oportunidades.</p>
                    <p>A qualidade foi constituída como direito, estando na meta 7 do Plano Nacional de Educação e como objetivo de desenvolvimento sustentável da Agenda da Educação 2030, que foi  acordada em 2015 pelos Estados-membros da UNESCO, como forma de assegurar que todos tenham garantidas as oportunidades de aprendizagem necessárias para se desenvolver plenamente na vida. No entanto, é preciso refletir e entrar em um acordo sobre o que é qualidade, para então buscar assegurá-la.</p>
                </span>

                <!-- <a target="_blank" href="https://www.youtube.com/playlist?list=PLo2yL7tIvR9HiGp0CrPqdqJTTrYpYW0FI" class="bancoContainer">
                     <Button
                     class="butBanco"
                     :isOutline="false"
                     value="Protagonismo em Ação: Conceitos de Qualidade na Educação Infantil" />
                     </a> -->
            </div>
        </div>

        <br />

        <div class="infoCard cardLeft">
            <div class="text">
                <span>
                    <h2 class="titlePraticas text-img-clipper">O QUE SIGNIFICA UMA EDUCAÇÃO INFANTIL DE QUALIDADE?</h2>
                    <p>É urgente que se discuta e se defina o que é uma Educação Infantil de qualidade, tendo em vista que o Brasil passou e continua a passar por uma acelerada expansão do acesso. A primeira coisa a ser dita sobre a definição do que é qualidade é que diferentes características podem ser consideradas como qualidade em diferentes contextos.</p>
                    <p>O que é qualidade na Educação Infantil se relaciona profundamente com aquilo que é acordado como sendo sua função, seu papel, ou sua missão, e não se pode ignorar que isso sempre será definido a partir de um contexto social, cultural e histórico. Isso significa que, em geral, oferecer um serviço educacional de qualidade se refere a cumprir de modo satisfatório a uma missão específica, atribuída por um grupo social de seu contexto; e esse processo de definição pode ocorrer de diversas formas.</p>
                    <p>Até 1996, o papel da Educação Infantil estava primordialmente associado ao sistema de assistência social, tendo como objetivo oferecer cuidado às crianças enquanto seus familiares trabalhavam. Quando a Lei de Diretrizes e Bases da Educação (LDB) foi instaurada, o papel da Educação Infantil alterou-se, compreendendo-se que seria necessário ir além da assistência, e que as instituições precisam oferecer um trabalho pedagógico intencional, com o objetivo claro de facilitar o desenvolvimento integral da criança (LDB/artigo 29).</p>
                    <p>Mas não foi apenas devido à LDB que o entendimento sobre a função da Educação Infantil mudou. A pré-escola foi se expandindo a partir de uma demanda que as famílias de classe média tinham por antecipar a escolarização formal de seus filhos. Com esses marcos sociohistóricos, passou-se a compreender, atualmente,  que a etapa da Educação Infantil deve ser um direito de todos, e que deve respeitar as especificidades da infância. A qualidade, nesse sentido, se compromete a garantir a missão que a nossa sociedade deu ao serviço em seu contexto histórico, de maneira satisfatória.</p>
                    <p>Além disso, é preciso reconhecer a multidimensionalidade de qualquer conceito que qualidade possa vir a ter. Concordamos que para que a qualidade seja discutida em sua integralidade, é preciso considerar a relação entre aspectos estruturais e materiais - os insumos - e aspectos processuais - os processos. Muito se fala sobre a dimensão dos insumos, devido à especial atenção dada a esta pelos documentos nacionais. É fundamental assegurar uma estrutura predial compatível, uma equipe qualificada, segurança e alimentação adequadas, uma gestão escolar democrática, e a integração e participação ativa das famílias.</p>
                    <p>Outra dimensão importante de se considerar são as práticas pedagógicas, pois são nelas que se fazem presentes as oportunidades de experiências do afeto e da brincadeira. E até mesmo dentro dessa dimensão, é preciso acordar-se quais são boas práticas que irão facilitar o desenvolvimento integral das crianças.</p>
                    <p>São nas interações entre crianças e professores; crianças e crianças; e crianças com o ambiente que as oportunidades de aprendizagem que proporcionarão o desenvolvimento são materializadas. A existência de interações pedagógicas que visem promover o protagonismo, que respeitem os ritmos das crianças, e que promovam acolhimento e a possibilidade de negociar nos momentos de lidar com conflitos na sala de referência são alguns dos principais preditores de aprendizagem.</p>
                    <p>Como forma de evidenciar a importância de se considerar essa dimensão ao falar sobre qualidade, em 2019, foi realizado um estudo pelo Laboratório de Estudos e Pesquisas em Economia Social (LEPES), com o apoio da Fundação Maria Cecilia Souto Vidigal (FMCSV), em que observou-se que um indicador global de processos pedagógicos (que incluía dimensões de interações) impactava positivamente o desenvolvimento das crianças.</p>
                    <p>Contudo, ainda é difícil discutir quais questões embasam as experiências na Educação Infantil que de fato contribuem para o desenvolvimento pleno das crianças. Somado a isso, tem-se uma escassez de avaliações que se proponham a olhar para os processos pedagógicos em escala, o que dificulta ainda mais a disseminação de dados que demonstram o quanto as práticas são o ponto chave para que a Educação Infantil se transforme.</p>
                    <p>Salienta-se, então, que é preciso dar atenção a todas essas dimensões, se quisermos assegurar a qualidade na Educação Infantil. Nesse sentido, podemos dizer que para uma unidade escolar ter uma boa qualidade, não é suficiente que se tenha uma boa infraestrutura. Se as práticas adotadas forem inadequadas podem ser até prejudiciais ao desenvolvimento ou à saúde das crianças. E seguindo a mesma lógica, boas práticas precisam de ambientes adequados para serem desenvolvidas.</p>
                    <p>Para além desses aspectos, considerar o que os documentos e normas oficiais descrevem como o que é esperado para a Educação Infantil em relação à qualidade no Brasil é essencial para discutir o que é qualidade. Alguns desses documentos são as Diretrizes Curriculares Nacionais para a Educação Infantil, que trouxeram oficialmente as interações e brincadeiras para o centro do debate; os Parâmetros Nacionais de Qualidade da Educação InfantiI, que trazem princípios e parâmetros para os diferentes profissionais da Educação Infantil; a Base Nacional Comum Curricular, que foca no âmbito pedagógico e lista objetivos de aprendizagem divididos em campos de experiência e embasados por direitos de aprendizagem; os Indicadores de Qualidade na Educação Infantil, também conhecido como INDIQUES, que serve ao propósito de orientar processos de autoavaliação nas unidades educacionais; o CAQi, que demonstra valores e um cálculo para o custo aluno-qualidade inicial, dentre outros.</p>
                </span>
            </div>
        </div>

        <br />

        <div class="infoCard cardRight">
            <div class="text">
                <span>
                    <h2 class="titlePraticas text-img-clipper">COMO A PERSPECTIVA CURRICULAR CONTRIBUI PARA COMPREENDER A QUALIDADE NA EDUCAÇÃO INFANTIL?</h2>
                    <p>
                        O lançamento da Base Nacional Comum Curricular (BNCC), em 2017, deu parâmetros para o debate sobre o que é qualidade. Oriunda das Diretrizes Curriculares Nacionais para a Educação Infantil, a base estabelece prioridades e acordos sobre o que a sociedade brasileira contemporânea quer para a Educação Infantil, mesmo que ainda caibam muitos aprimoramentos e discussões.
                    </p>
                    <p>
                        A Base Nacional configura-se, assim, como uma referência obrigatória para a elaboração dos currículos escolares, avaliações e propostas pedagógicas, ao estabelecer qual a missão da educação: assumir o compromisso de transformar crianças e jovens em cidadãos ativos, que tenham repertório para modificar o mundo, considerando os desafios da sociedade em que vivem. Para que isso ocorra, a Base dispõe competências necessárias a serem desenvolvidas, como aprender a aprender, resolver problemas, ter autonomia, saber dialogar e conviver com as diferenças, entre outras. Assim, compreende-se que é preciso levar em conta os interesses e necessidades das crianças, tornando significativo para elas o processo de aprendizagem.
                    </p>
                    <p>
                        A prioridade definida é de ampliar as aprendizagens das crianças quanto ao repertório cultural e científico. Nesse sentido, as estratégias educacionais devem assegurar os direitos de aprendizagem de conviver, brincar, explorar, participar, expressar e conhecer-se dos bebês e das crianças bem pequenas e pequenas, colocando-as no centro do processo de aprendizagem.
                    </p>
                    <p>
                        Para isso, as oportunidades oferecidas devem considerar a aprendizagem baseada no brincar e ampliação da aprendizagem, uma vez que, no contexto da infância, é por meio do lúdico que a criança aprende e vai consolidando o conhecimento. É preciso contextualizar de onde partem as experiências e os conhecimentos abordados para dar sentido ao que se aprende, dar protagonismo para as crianças na sua aprendizagem e na construção das suas vivências e experiências, e identificar estratégias para conectar os conhecimentos, com base na realidade do lugar e do tempo, com as aprendizagens propostas.
                    </p>
                    <p>
                        Outro aspecto importante que a BNCC traz à luz para se compreender qualidade na Educação Infantil é o conceito da intencionalidade pedagógica, ocorrida ao mediar os processos de descoberta das crianças voltando-se ao momento do desenvolvimento e de vida em que se encontram, uma vez que algumas aprendizagens já podem estar consolidadas e outras estão em processo de construção. Ao apreender que existem esses momentos no desenvolvimento, pode-se intervir, encorajando as crianças e auxiliando-as.
                    </p>
                    <p>
                        Evidencia-se, assim, a importância de assegurar a qualificação dos profissionais em identificar quais são as especificidades da faixa etária com quem trabalham, adequando e adaptando as brincadeiras, jogos e mobiliários, por exemplo. É somente assumindo um compromisso com a qualidade educacional, compreendendo-a como direito, que se poderá melhorar as condições sociais em que vivemos.
                    </p>
                </span>
            </div>
        </div>

        <br />

        <div class="infoCard cardLeft">
            <div class="text">
                <span>
                    <h2 class="titlePraticas text-img-clipper">QUAL A VISÃO DE QUALIDADE ADOTADA PELO LEPES?</h2>
                    <p>Especificamente no contexto dos estudos realizados pelo LEPES, trabalhamos com uma proposta conceitual focada na aprendizagem baseada no brincar. Para adotar essa proposta, em 2019, realizamos o processo de adaptação de uma escala, - a Escala de Avaliação dos Ambientes de Aprendizagens dedicados à Primeira Infância - um instrumento que avalia a qualidade das práticas pedagógicas e outros aspectos dos ambientes da Educação Infantil. Por esse motivo, os conceitos-chave de qualidade que embasaram a elaboração da escala são coerentes com a missão da educação defendida pela BNCC, e podem contribuir para orientar o que as condições educativas precisam levar em conta quando o objetivo é promover a qualidade: a aprendizagem baseada no brincar;  oportunidades de aprendizagens; mediação do professor; perguntas abertas; e adequação ao desenvolvimento.</p>
                </span>
            </div>
        </div>

    </div>

</template>

<script>
 // import Button from '@components/Button';

 export default {
     name: 'BoasPraticas_Qualidade',
     components: {
         // Button,
     },
 }
</script>

<style scoped>
 .apresentacao {
     padding: 2rem 2rem;
     width: 100%;
     background: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
 }

 .infoCard {
     /* display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center; */
     padding: 2rem;
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 2rem;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 .cardRight {
     background: url("~@/assets/img/Backgrounds/cianoEscuro.webp");
 }

 .cardLeft {
     background: url("~@/assets/img/Backgrounds/marsala.webp");
 }

 /* .infoCard > img {
    margin: 0 auto;
    flex: 1 0 30%;
    display: block;
    width: 100%;
    max-width: 400px;
    min-width: 300px;
    border-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    } */

 .infoCard > .text {
     /* flex: 1 0 70%; */
     width: 100%;
     display: block;
     text-align: justify;
     font-size: 1.15em;
     box-sizing: border-box;
     padding: 10px;
 }

 .infoCard > .text p {
     text-indent: 1em;
 }

 .apresentacao > .imgs {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     text-align: center;
 }

 .imgs > img {
     margin: 0 auto;
     padding: 10px;
     width: 100%;
 }

 .bancoContainer {
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: flex-end;
 }

 .butBanco {
     display: block;
     background: url("~@/assets/img/Backgrounds/verdeEapi.webp");
     background-repeat: repeat;
     background-size: 256px;
     border-radius: 20px;
 }

 :deep(.butBanco span) {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .categories {
     display: grid;
     grid-template-columns: repeat(2, 1fr);
     /* grid-template-rows: auto; */
     grid-auto-rows: 1fr;
     row-gap: 10px;
     column-gap: 10px;
     text-align: center;
     padding: 15px;
 }

 .category {
     margin: 0 auto;
     border: 0;
     border-radius: 2rem;
     padding: 0.5rem;
     width: 70%;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
     font-weight: 600;
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     background-repeat: repeat;
     background-size: 256px;
 }

 .titlePraticas {
     width: 100%;
     padding: 5px;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
 }

 .cardRight .titlePraticas {
     text-align: right;
 }

 .cardLeft .titlePraticas {
     text-align: left;
 }

 .titleAxis {
     width: 100%;
     padding: 20px;
     text-align: center;
     color: rgba(25, 89, 83, 0.8);
     text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
 }

 @media screen and (max-width: 800px) {
     .categories {
         grid-template-columns: 1fr;
     }

     .imgs > img {
         width: 40% !important;
         max-width: 350px;
     }
 }

 .text p {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .pageTitle {
     background-image: url("~@/assets/img/Backgrounds/marsala.webp");
     width: 100%;
     text-align: center;
 }
</style>
