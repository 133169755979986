<template>
  <!--
      |---------------------------------------------
      | ListTeams Component
      |---------------------------------------------
      | @usage Import the component and use, no params
      | List all teams and show its filters
    -->
  <div class="container list-teams">
    <div>
      <Panel
        :infos="infosPanel"
        :teams="teams"
        />

      <div class="container mt-5">
        <Button
          class="clickable greenGradient"
          value="Entenda"
          iconName="question-circle"
          :isOutline="true"
          :hasIcon="true"
          data-target="#idBotaoInstituicoes"
          data-toggle="modal"
          />
      </div>
    </div >
    <!-- MODAL CENTRAL DO 'ENTENDA' -->
    <ModalCentral
      componentName="Carousel"
      :slides="infosCarousel"
      title="Instituições"
      idName="idBotaoInstituicoes"
      />

    <div class="container mt-4" v-if="Object?.keys(teams ?? []).length != 0">
      <h3 class="mr-sm-2 mb-4 text-nowrap">Filtrar instituições por: </h3>

      <!-- INICIO DO FORM DE FILTRAGEM -->
      <form @submit.prevent="" class="mt-4 form-inline">
        <div class="form-group">
          <!-- DROPDOWN DE SELECAO DE FILTRO -->
          <Filter ref="filtersearchname" @click="setSelectedValue()" :optionsSelect="options" nameSelect="filtro_instituicoes" id="inlineFormCustomSelect"/>
        </div>

        <!-- SE A SELECAO FOR NOME (2) ENTAO EXIBIRA OS SUBFILTROS DE NOME -->
        <div class="form-group" v-if="selectedValue == 2">
          <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomName">Digite o nome: </label>
          <input class="input-personal mr-sm-4" v-model="searchByName" type="text" name="filtro_nome_instituicoes" id="inlineFormCustomName" autocomplete="off">

          <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="cres" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioAZ" checked>
          <label class="mr-sm-2 text-nowrap label-filtro mr-sm-4" for="inlineFormCustomRadioAZ">A - Z</label>

          <input class="radio-personal mr-sm-2" v-model="searchWithOrder" value="desc" type="radio" name="filtro_nome_ordenacao" id="inlineFormCustomRadioZA">
          <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomRadioZA">Z - A</label>
        </div>

        <!-- SE A SELECAO FOR POR GRUPOS (3) ENTAO EXIBIRA OS SUBFILTROS DOS GRUPOS -->
        <div class="form-group" v-if="selectedValue == 3">
          <label class="mr-sm-2 text-nowrap label-filtro" for="inlineFormCustomCodigo">Selecione o grupo: </label>
          <Filter ref="filtersearchgroup" @click="setSelectedGroup()" :optionsSelect="group_options" nameSelect="filtro_grupo_instituicoes" id="inlineFormCustomCodigo"/>
        </div>

      </form>
    </div>

    <transition-group tag="div" v-if='filterInstituicao' class="d-flex cards mt-5" name="listanimate">
      <div class="card-personal mb-2"  v-for="(team) in filterInstituicao" :key="team.idinstituicao">
        <CardsBG
          :id="parseInt(team.idinstituicao)"
          :name="team.name"
          :nr_members="team.nr_members"
          :nr_sample="team.nr_sample"
          :thumbnail="team.thumbnail"
          />
      </div>

    </transition-group>

    <transition-group v-else>
      <h2 class="title-style mt-4" :key="1">Nenhum resultado encontrado.</h2>
    </transition-group>
  </div>
</template>

<!--Linguagem marcação: JavaScript-->
<script>

import CardsBG from '@dados/GerenciarInstituicoes/CardsBG';
import Filter from '@components/Filter';
import Button from '@components/Button';
import ModalCentral from '@components/ModalCentral';
import Panel from '@dados/GerenciarInstituicoes/Panel';
import ImgListInstiuicao from '@assets/img/Dados/ListarInstituicao/entendaInstituicoes.png';
import ImgBuscarCriar from '@assets/img/Dados/ListarInstituicao/entendaBuscarInstituicao.png';

export default {
  name: 'ListTeams',
  data() {
    return {
      qtdTeams: 0,
      selectedValue: '',
      searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             selectedGroupFilter: 0,
             teste: [],
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 // {
                 //     id:3,
                 //     value: 'Grupo de Pesquisa'
                 // }
             ],
             group_options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'LEPES X'
                 },
                 {
                     id:3,
                     value: 'Secretaria X'
                 }
             ],

             infosPanel: {
                 title: 'Instituições',
                 firstButtonTitle: 'Buscar instituição',
                 firstButtonText: 'Faz parte de alguma instituição? Encontre-a e solicite a sua entrada.',
                 secondButtonTitle: 'Criar instituição',
                 secondButtonText: 'Não faz parte de uma instituição? Crie uma instituição agora mesmo.',
                 thumbnail: 'grupos-pesquisa-image2.png',
                 type: 'Institution'
             },

             infosCarousel: [
                 {
                     id:0,
                     title: 'O que são Instituições?',
                     text: 'Instituições são estruturas ou mecanismos de ordem social, que regulam o comportamento de um conjunto de indivíduos dentro de uma determinada comunidade. Instituições são identificadas com uma função social, que transcende os indivíduos e as intenções mediando as regras que governam o comportamento vivo.',
                     image: ImgListInstiuicao,
                     alt: 'Página da listagem de instituições'
                 },
                 {
                     id:1,
                     title: 'Como buscar Instituições?',
                     text: 'Localize e clique no botão "Buscar instituição", com isso, uma janela se abrirá contendo a listagem de instituições disponíveis para o seu ingresso. Você poderá filtrar as instituições através da barra de pesquisa, a fim de encontrar a instituição desejada. Ao localizar a instituição desejada, clique em "Enviar" para enviar uma solicitação de entrada ao Administrador da Instituição. Por fim, aguarde pela aprovação de sua solicitação.',
                     image: ImgBuscarCriar,
                     alt: 'Página da listagem de instituições'
                 },
                 {
                     id:2,
                     title: 'Como criar Instituições?',
                     text: 'Localize e clique no botão "Criar instituição", com isso, uma janela se abrirá contendo o formulário de criação de uma instituição. Para criar uma instituição basta preencher os campos solicitados. Nome, estado e cidade são campos obrigatórios nesse formulário, enquanto descrição, orgão e imagem são campos opcionais. Com pelo menos os campos obrigatórios preenchidos, finalize a criação clicando no botão "Criar instituição" que está localizado no canto inferior direito da janela.',
                     image: ImgBuscarCriar,
                     alt: 'Página da listagem de instituições'
                 },

             ]
         }
     },
     components: {
         CardsBG,
         Filter,
         Panel,
         Button,
         ModalCentral
     },
     methods: {
         setSelectedValue () {
             this.selectedValue = this.$refs.filtersearchname.value
         },
         setSelectedGroup () {
             this.selectedGroupFilter = this.$refs.filtersearchgroup.value
         }

     },

     async created() {
         await this.$store.dispatch('setListTeams');
         const lista = this.$store.getters?.getListTeams;
         for (const instituicao of (lista || [])){
             const payload = {
                 idInstituicao: instituicao.idinstituicao
             };
             await this.$store.dispatch('setUserPerm', payload);
         }
     },

     mounted: function() {
         this.qtdTeams = Object?.keys(this.teams ?? []).length;
     },
     computed: {
         //TODO: José - Carregamento automático de retornos de busca está bugando
         teams() {
             return this.$store.getters.getListTeams
         },
         filterInstituicao: function() {

             //VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = this.teams;
             query = query?.sort((a, b) => a?.name.localeCompare(b?.name));

             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             if (this.selectedValue == 1) {
                 query = this.teams;
                 query = query?.sort((a, b) => a?.name.localeCompare(b?.name));
             }

             //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             else if (this.selectedValue == 2) {

                 //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
                 if (this.searchByName.trim() == '') {
                     query = this.teams;
                 }
                 //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
                 else {

                     var filtered = this.teams?.filter(team =>team.name.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

                     if(filtered.length != 0){
                         query = filtered
                     }

                     else{
                         query = false
                     }

                 }

                 //ORDERNA CRESCENTEMENTE
                 if (this.searchWithOrder == 'cres' && query != false) {
                     query?.sort((a, b) => a?.name.localeCompare(b.name));
                 }

                 //ORDERNA CRESCENTEMENTE
                 else if (this.searchWithOrder == 'desc' && query != false) {
                     query?.reverse((a, b) => a?.name.localeCompare(b.name));
                 }

             }

             //CASO SEJA SELECIONADO GRUPO DE PESQUISA, FILTRA POR GRUPO
             else if (this.selectedValue == 3) {

                 if (this.selectedGroupFilter == 0){
                     query = this.teams;
                 }

                 else if (this.selectedGroupFilter == 1){
                     query = this.teams;
                 }

                 else if (this.selectedGroupFilter == 2) {
                     query = this.teams?.filter(team => team.gp_research == 'LEPES X')
                 }

                 else if (this.selectedGroupFilter == 3) {
                     query = this.teams?.filter(team => team.gp_research == 'Secretaria X')
                 }
             }
             return query;
         }
     }
 }

</script>

<style scoped>

body{
    background: linear-gradient(180deg, rgba(0, 133, 59, 0.8) 0%, rgba(0, 133, 59, 0) 100%) !important;
}

 .title-style{
     font-size: 25px;
     color: var(--font-white);
     text-align: center;
     margin: 0;
     font-weight: 500;
 }

 .about-team {
     background-color: rgb(255, 255, 255);
     display: flex;
     align-items: center;
     justify-content: space-around;
     border-radius: 12px;
     padding: 1rem 4rem;
 }

 .img-about-team {
     max-height: 16rem;
 }

 .box-btn-about-team button {
     background-color: transparent;
     outline: none;
     cursor: pointer;
     padding: .3rem .8rem;
     border-radius: 6px;
     color: #105B9F;
     border: 3px solid #105B9F;
     font-size: 1.1rem;
     display: flex;
     justify-content: flex-end;
     align-items: center;
 }

 .box-btn-about-team i {
     font-size: 1.3rem;
 }

 .label-filtro {
     font-weight: 600;
 }

h3{
  font-size: 1.2rem;
  text-transform: none !important;
  font-weight: 700;
  color: var(--font-white);
}

 .cards {
     display: flex;
     flex-wrap: wrap;
     column-gap: 2rem;
 }

 /* Responsoivizando alinhamento dos cards */
 @media screen and (max-width: 1160px) {
     .cards {
         grid-template-columns: repeat(auto-fit, minmax(22.75rem, max-content));
     }
 }

 .card-personal {
     width: 24.75rem;
 }

 .card.custom-rounded {
     border-radius: 20px;
     box-shadow: 12px 12px 23px -7px rgba(158,146,158,0.49);
 }

 .filter-box {
     font-family: inherit;
     margin: 2rem 4rem 2rem 0;
     margin-left: 6.5vw;
 }

 .text-nowrap{
     color: var(--font-white);
 }

 .card-body {
     text-align: center;
     font-weight: bold;
     min-height: 116px;
 }

 .card-text {
     padding-top: 1rem;
     color: #212529;
     font-size: 1rem;
 }

 .card-img-top {
     cursor: pointer;
 }

 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 .listanimate-enter-active,
 .listanimate-leave-active {
     transition: all 0.4s;
 }

 .listanimate-enter,
 .listanimate-leave-to  {
     transform: scale(0);
     opacity: 0;
 }

 .list-teams {
     margin-bottom: 3rem;
 }

 h3{
     color: var(--font-white);
     font-weight: 600;
 }
</style>
