<template>
  <!-- Carousel wrapper -->
  <div id="carouselMaterialStyle" class="carousel slide carousel-fade" data-mdb-ride="carousel">
    <!-- Indicators -->
    <div class="carousel-indicators">
      <button type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide-to="0" class="active" aria-current="true"
              aria-label="Slide 1"></button>
      <button type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide-to="2" aria-label="Slide 3"></button>
    </div>

    <!-- Inner -->
    <div class="carousel-inner shadow-4-strong">
      <!-- Single item -->
      <div class="carousel-item active">
        <img src="@/assets/img/NewHome/Carousel/carousel-1.jpg" class="d-block"
             alt="Estudantes escrevendo em um papel" />
        <!-- <div class="carousel-caption d-none d-md-block">
          <h5>Acompanhe os dados do formulário da transição</h5>
          <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          <router-link class="nav-link" to="/" >
            <button type="button" class="greenGradient clickable">Call to action</button>
          </router-link>
        </div> -->
      </div>

      <!-- Single item -->
      <div class="carousel-item">
        <img src="@/assets/img/NewHome/Carousel/carousel-2.jpg" class="d-block"
             alt="Pessoa segurando um capelo" />
        <!-- <div class="carousel-caption d-none d-md-block">
          <h5>Second slide label</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <router-link class="nav-link" to="/">
            <button type="button" class="greenGradient clickable">Call to action 2</button>
          </router-link>
        </div> -->
      </div>

      <!-- Single item -->
      <div class="carousel-item">
        <img src="@/assets/img/NewHome/Carousel/carousel-3.jpg" class="d-block"
             alt="Mulher trabalhando no computador com criança ao fundo" />
        <!-- <div class="carousel-caption d-none d-md-block">
          <h5>Third slide label</h5>
          <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
          <router-link class="nav-link" to="/" >
            <button type="button" class="greenGradient clickable">Call to action 3</button>
          </router-link>
        </div> -->
      </div>
    </div>
    <!-- Inner -->

    <!-- Controls -->
    <button class="carousel-control-prev" style="box-shadow: none !important;" type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-mdb-target="#carouselMaterialStyle" data-mdb-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>

<style scoped>

.carousel-item{
  height: 38rem;
  max-height: 38rem;
}
.carousel-item img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.carousel-indicators button{
  box-shadow: none !important;
  background-color: #D9D9D9;
}

.carousel-indicators .active{
  background-color: #40C9A2;
}

.carousel-control-next:hover, .carousel-control-next:focus,
.carousel-control-prev:hover, .carousel-control-prev:focus{
  color: #40C9A2;
}


</style>
