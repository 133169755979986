<template>
  <!--
      |---------------------------------------------
      | CardsBG Component
      |---------------------------------------------
      | @usage Import the component and use
      | <CardsBG :name="team.name"
      |   :nr_members="team.nr_members"
      |   :nr_application="team.nr_application"
      |   thumbnail="img-escola.png"/>
    -->

<div class="col">

  <!-- MODAL CENTRAL DO 'INFORMAÇÕES' -->
  <ModalCentral
    componentName="Information"
    title="Informações"
    :idName="`Information${idInstituicao}`"
    :data="infosData"
    />

  <ModalCentral
    componentName="UpdateInstitution"
    :title="`Editar instituição '${name}'`"
    :idName="`update${idInstituicao}`"
    :data="{ idInsti: idInstituicao, }"
    />

  <div @click="closeMiniDropdown()" class="overlay-invisible" :class="{'overlay-visible': isActiveMiniDropdown}"></div>

  <!-- CARDS DAS INSTITUIÇÕES -->
  <div class="card custom-rounded">
    <router-link :to="{name: 'InstituicaoMenu', params: { idInstituicao: idInstituicao }}">

      <div @mouseenter="turnOnCog()" @mouseleave="turnOffCog()" class="box-img-card">
        <img class="card-img-top" :src="path_thumbnail" alt="Instituição"/>
      </div>
    </router-link>
    <div @click="showMiniDropdown()" class="institution-actions">
      <!-- <span>Ações da Instituição </span> -->
      <i class="fas fa-cog" :class="{'turning-cog': isTurningCog, 'turning-instead-cog': isTurningInsteadCog}" ></i>
      <div class="dropdown-box" :class="{'dropdown-box-visible': isActiveMiniDropdown}">
        <div class="mini-dropdown">
          <!-- TRIÂNGULO DO POP-UP PEQUENO -->
          <div class="mini-triangle">
            <svg class="triangulo-1" width="26" height="30" viewBox="0 0 36 33" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.6699 2.5C15.5944 -0.833332 20.4056 -0.833334 22.3301 2.5L35.3205 25C37.245 28.3333 34.8394 32.5 30.9904 32.5H5.00962C1.16062 32.5 -1.24501 28.3333 0.679492 25L13.6699 2.5Z" fill="white"/>
            </svg>
          </div>

          <!-- POP-UP PEQUENO -->
          <div class="mini-pop-up shadow-lg pt-3 pr-1 pb-3 pl-2">
            <!-- <div class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown" :data-target="`#Information${idInstituicao}`" data-toggle="modal"><i class="fas fa-info-circle mr-1"></i> Informações</div> -->
            <div
              v-if="userPerm == 'ADMINISTRADOR'"
              :data-target="`#update${idInstituicao}`"
              data-toggle="modal"
              class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown">
              <i class="fas fa-pen mr-1" />
              Editar Instituição
            </div>
            <div @click="deixarEquipe(nomeInstituicao)" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown"><i class="fas fa-sign-out-alt mr-1"></i> Deixar Instituição</div>
            <div v-if="userPerm == 'ADMINISTRADOR'" @click="deletarEquipe(nomeInstituicao)" class="row-md-12 font-weight-light pl-0 pr-0 item-minidropdown"><i class="fas fa-trash mr-1"></i> Excluir</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body pt-1 pb-1">
      <p class="card-text mb-3">{{name}}</p>
      <p v-if="nr_members>1" class="text-secondary mb-1"><i class="fas fa-user-friends mb-0 mr-1"></i>{{nr_members}} membros na instituição</p>
      <p v-else class="text-secondary mb-1"><i class="fas fa-user-friends mb-0 mr-1"></i>{{nr_members}} membro na instituição</p>
      <p v-if="nr_sample>1" class="text-secondary mb-1"><i class="fas fa-layer-group mb-0 mr-1"></i>{{nr_sample}} campos criados na instituição</p>
      <p v-if="nr_sample==0" class="text-secondary mb-1"><i class="fas fa-layer-group mb-0 mr-1"></i> Nenhum campo foi criado na instituição</p>
      <p v-if="nr_sample==1" class="text-secondary mb-1"><i class="fas fa-layer-group mb-0 mr-1"></i>{{nr_sample}} campo criado na instituição</p>
    </div>
  </div>
</div>
</template>

<script>
import Swal from "sweetalert2";
import ModalCentral from '@components/ModalCentral';
import axios from '@middleware/api.js';
import { Notyf } from 'notyf';
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'left',
    y: 'bottom',
  },
  types: [
    {
      type: 'info',
      background: '#18a0fb',
      icon: {
        className: 'fa fa-info-circle',
        tagName: 'i',
        color: 'white'
      }
    }
  ]
});

export default {
  name: "CardsBG",
  data() {
    return {
      idInstituicao: this.id,
      nomeInstituicao: this.name,
      isActiveMiniDropdown: false,
      isTurningCog: false,
      isTurningInsteadCog: false,
      infosData: {
        idInstituicao: this.id
      },
    };
  },
  props: {
    id: Number,
    name: String,
    nr_members: String,
    nr_sample: String,
    thumbnail: String,
  },
  components:{
    ModalCentral
  },
  methods: {
    async deixarEquipe(name) {
      let justificativaInput = "";

      if (this.userPerm != 'ADMINISTRADOR'){
        justificativaInput = await Swal.fire({
          title: "Deseja mesmo sair da instituição?",
          text:
          "Tem certeza que deseja sair da instituição " +
            name +
            "? Se sim, justifique sua saída.",
          icon: "warning",
          input: "textarea",
          inputPlaceholder: 'Digite sua justificativa aqui..',
          inputAttributes: {
            autocapitalize: "off",
          },
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, tenho certeza",
          inputValidator: (value) => {
            if (!value) {
              return 'Você precisa escrever a justificativa.'
            }
          }
        })
        if (justificativaInput.isDismissed) {
          notyf.open({
            type: 'info',
            background: '#18a0fb',
            message: 'Ação cancelada',
          });
          return;
        }
      }

      if (this.nr_members == 1) {
        const result = await Swal.fire({
          title: "Atenção",
          text: 'Você é atualmente o único membro desta instituição. Se optar por sair, a instituição será excluída automaticamente.',
          icon: "warning",
          showCancelButton: true,
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Confirmar",
        });

        if (!result.isConfirmed) {
          notyf.open({
            type: 'info',
            background: '#18a0fb',
            message: 'Ação cancelada',
          });
          return;
        }
      }

      const payload = {
        idInstituicao: this.id,
        justificativa: justificativaInput
      }
      await this.$store.dispatch('deixarInsti', payload)

      const leaveErr = this.$store.getters.getLeaveInstiErr;

      if (!leaveErr) {
        this.$store.dispatch('setListTeams');
        notyf.open({
          type: 'success',
          message: 'Você não pertence mais a essa instituição!'
        });
        return;
      }

      notyf.open({
        type: 'error',
        message: leaveErr === true ? "Erro ao sair da instituicao" : leaveErr,
      });
    },

    async deletarEquipe(name) {
      const justificativaInput = await Swal.fire({
        title: "Deseja mesmo excluir a instituição?",
        text:
        "Tem certeza que deseja excluir a instituição " +
          name +
          "? Se sim, justifique o motivo da deleção.",
        icon: "warning",
        input: "textarea",
        inputPlaceholder: 'Digite sua justificativa aqui..',
        inputAttributes: {
          autocapitalize: "off",
        },
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, tenho certeza",
        inputValidator: (value) => {
          if (!value) {
            return 'Você precisa escrever a justificativa.'
          }
        }
      })

      if (justificativaInput.isDismissed) {
          notyf.open({
            type: 'info',
            background: '#18a0fb',
            message: 'Ação cancelada',
          });
          return;
        }

      if (justificativaInput.value) {
        const payload = {
          idInstituicao: this.id,
          justificativa: justificativaInput.value
        }
        await this.$store.dispatch('deleteInstitution', payload)

        const deleteErr = this.$store.getters.getDeleteInstiErr;

        if (!deleteErr) {
          this.$store.dispatch('setListTeams');
          notyf.open({
            type: 'success',
            message: 'Você não pertence mais a essa instituição!'
          });
          return;
        }

        notyf.open({
          type: 'error',
          message: deleteErr === true ? "Erro ao deletar a instituicao" : deleteErr,
        });
      }
    },

    showMiniDropdown: function () {
      this.isActiveMiniDropdown = true;
    },
    closeMiniDropdown: function () {
      this.isActiveMiniDropdown = false;
    },
    turnOnCog: function () {
      this.isTurningCog = true;
      this.isTurningInsteadCog = false;
    },
    turnOffCog: function () {
      this.isTurningCog = false;
      this.isTurningInsteadCog = true;
    },
  },
  computed: {
    userPerm() {
      return this.$store.getters.getUserPerm?.permissao
    },
     path_thumbnail() {
      if (!this.thumbnail)
        return require('@assets/img/Dados/ListarInstituicao/Instituicoes/instituicao1.jpg');
      else
        return `${axios.defaults.baseURL}/insti/getImage?path=${this.thumbnail}`;

    },
 }
};
</script>

<style scoped>
.overlay-invisible {
    position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0);
  z-index: 2;
  transition: all ease-in-out 0.3s;
  opacity: 0;
  pointer-events: none;
}

.overlay-visible {
  opacity: 1;
  pointer-events: all;
  cursor: default;
}

.card {
  border: none !important;
  background-color: #fff;
  background-size: 256px;
  height: auto !important;
  width: 100% !important;
  overflow: hidden;
  
}
.card.custom-rounded {
  border-radius: 16px;
  box-shadow: 12px 12px 23px -7px rgba(158, 146, 158, 0.671);
}

.box-img-card {
  -webkit-mask-image: linear-gradient(to bottom, black 55%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 55%, transparent 100%);
  display: inline-block;
  overflow: hidden;
  border-top-left-radius: calc(1rem - 1px);
  border-top-right-radius: calc(1rem - 1px);
  height: 10rem;
  width: 100%;
}

.card-img-top {
  cursor: pointer;
  display: block;
  transition: transform 0.4s;
}

.card-img-top:hover {
  transform: scale(1.1);
  transform-origin: 50% 50%;
}

img.card-img-top{
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

.fa-cog {
  color: var(--font-green);
  font-size: 1rem;
}

.turning-cog {
  animation: turningCogAnimation;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

.turning-instead-cog {
  animation: turningCogInsteadAnimation;
  animation-duration: 0.6s;
  animation-timing-function: ease-in-out;
}

@keyframes turningCogAnimation {
  to {
    transform: rotate(360deg);
  }
}

@keyframes turningCogInsteadAnimation {
  to {
    transform: rotate(-360deg);
  }
}

.card-body {
  font-size: 0.9rem;
  min-height: 8rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-body i{
  color: var(--font-green);
}

.card-text{
  color: var(--font-green);
  font-weight: 600;
}

.text-secondary{
  color: #6B6B6B !important;
  font-weight: 400;
  font-size: 0.875rem;
}

.mini-triangle {
  margin: 0rem;
  display: center;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: -16%;
  left: 71%;
}
.dropdown-box {
  position: absolute;
  top: 150%;
  right: -41%;
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  cursor: default;
  transition: all 0.4s ease;
}

.dropdown-box-visible {
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.mini-dropdown {
  position: relative;
}

.institution-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 0.9rem;
  font-weight: bold;
  cursor: pointer;
  background: #fff;
  border-radius: 100%;
  width: 32px;
  height: 30px;
  box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
}

.mini-pop-up {
  min-height: 5.5rem;
  width: 9rem;
  background-color: white;
  color: grey;
  border-radius: 1rem;
  transition: color ease-in-out 0.2s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.item-minidropdown:hover {
  color: rgb(58, 58, 58);
}


</style>

