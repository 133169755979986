<template>
<div class="root-dados">
  <ModalCentral
    componentName="AddJovem"
    :title="jovemEdit == null ? 'Adicionar jovem' : 'Atualizar jovem'"
    idName="AddJovem"
    :data="{ editing: jovemEdit, }"
    />

  <div class="container list-data">
    <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-aplicacoes"/>
    <br/>

    <div class="title-row">
      <InstitutionInfo class="title-style" :title="nomeInsti" section="instituicao-cadastrar"/>
    </div>

    <p class="text-info-personal">Nesta seção, você encontrará todos os Jovens relacionados ao Empregador. Caso necessário, utilize o filtro abaixo para localizar o Jovem desejado.</p>

    <template v-if="userPerm.permissao != 'APLICADOR'">
      <div class="lista-data-info">
        <Button
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Adicionar jovem"
          @click="jovemEdit = null"
          :isOutline="true"
          :hasIcon="true"
          iconName="plus"
          data-toggle="modal"
          data-target="#AddJovem"
          />
      </div>
      <br />
    </template>

    <div class="input-group">
      <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar jovens de um empregador por nome..." autocomplete="off">
      <div class="input-group-append">
        <span class="input-group-text">Nome</span>
      </div>
    </div>
    <hr class="separator">

    <div v-if="isLoadedInfo">
      <MemberQnts
        :totalQnt="totalJovens"
        term="jovens"
        :filteredQnt="jovensFiltered?.length || 0"
        icon="user-alt" />

      <div class="list-box">
        <transition name="slide-fade" v-if="jovensFiltered?.length > 0">
          <div class = "table-wrap">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col">  </th>
                  <th scope="col">Nome</th>
                  <th scope="col">CPF</th>
                  <th scope="col">Data de nascimento</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <TransitionGroup tag="tbody" appear name="list">
                <tr class="table-row" v-for="jovem in jovensFiltered" :key="jovem.idJovem">
                  <td>
                    <img  class="img-info-box-lista" src="@assets/img/Dados/ListarMembros/coordenador-user-blue.png" alt="Ícone de jovem">
                  </td>

                  <td> {{ jovem.nome }}</td>
                  <td>
                    {{ jovem?.cpf ? formatCpf(`${jovem.cpf}`) : 'Não informado' }}
                  </td>
                  <td>
                    <span class="second-info">{{ jovem?.dataNascimento ? maskDate(jovem.dataNascimento) : 'Não informada' }}</span>
                  </td>

                  <td>
                    <div class="controlGroup">
                      <Button
                        v-if="!jovem.isEditionLocked"
                        @click="jovemEdit = jovem"
                        class="orange-text"
                        value="Atualizar jovem"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="pen"
                        data-toggle="modal"
                        data-target="#AddJovem"
                        />

                      <Button
                        v-if="!jovem.isDeletionLocked"
                        @click="removeJovem(jovem)"
                        class="orange-text"
                        value="Excluir jovem"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="trash"
                        />
                    </div>
                  </td>

                </tr>
              </TransitionGroup>
            </table>
          </div>
        </transition>

        <transition v-else>
          <h2 class="title-style-box-lista">Nenhum jovem encontrado.</h2>
        </transition>
      </div>

    </div>

    <div v-else>
      <SkeletonList/>
    </div>

  </div>
</div>
</template>

<script>
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import Swal from "sweetalert2";
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  components: {
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    MemberQnts,
    Button,
    ModalCentral,
  },
  data: () => ({
    crumbs: [
      {
        id: 1,
        value: '',
        iconName: 'th',
        name_route: 'GerenciarInstituicoes'
      },
      {
        id: 2,
        value: 'Instituição',
        iconName: 'university',
        name_route: 'InstituicaoMenu'
      },
      {
        id: 3,
        value: 'Cadastrar Informações',
        iconName: 'search-plus',
        name_route: 'InstituicaoMenuCadastrar'
      },
      {
        id: 4,
        value: 'Empresas',
        iconName: 'building',
        name_route: 'ListarEmpresas'
      },
      {
        id: 5,
        value: 'Empregadores',
        iconName: 'user-tie',
        name_route: 'ListarEmpresas'
      },
      {
        id: 6,
        value: 'Jovens',
        iconName: 'user-alt',
        name_route: ''
      },
    ],
    searchByName: '',
    isLoadedInfo: null,
    jovemEdit: null,
  }),
  async created() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao,
      idEmpresa: this.$route.params.idEmpresa,
      idEmpregador: this.$route.params.idEmpregador,
    };

    this.$store
        .dispatch('loadListJovens', payload)
        .then(() => this.isLoadedInfo = true);

    this.$store.dispatch('setUserPerm', payload);
  },
  computed: {
    userPerm() { return this.$store.getters.getUserPerm; },
    teams() {
      return this.$store.getters.getListTeams;
    },
    nomeInsti() {
      return this
        .teams
        .find(insti =>
          insti.idinstituicao == this.$route.params.idInstituicao
        )?.name;
    },
    jovens() { return this.$store.getters.getListJovens; },
    totalJovens() { return this.jovens?.length || 0; },
    jovensFiltered() {
      let query = this.jovens;

      if (this.searchByName.trim() != '') {
        let filtered = query?.filter(jovem => jovem.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
        if (filtered?.length != 0)
          query = filtered;
        else
          query = false;
      }

      return query;
    },
  },
  methods: {
    maskDate(inputDate){
      let date = new Date(inputDate);
      var day = date.getUTCDate();
      var month = date.getUTCMonth() + 1;
      var year = date.getUTCFullYear();

      var formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
      return formattedDate
    },
    formatCpf(cpf) {
      return cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring(6, 9) + '-' + cpf.substring(9, 11);
    },
    async removeJovem(jovem) {
      const result = await Swal.fire({
        title: 'Deseja mesmo excluir o jovem?',
        text: 'Ao excluir, você não terá mais acesso às informações desse jovem.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir jovem",
      })
      if (result.isConfirmed) {
        await this.$store.dispatch('removeJovem', {
          idInstituicao: this.$route.params.idInstituicao,
          idEmpregador: this.$route.params.idEmpregador,
          idJovem: jovem.idJovem,
        });

        if (this.$store.getters.getIsJovemRemoved) {
          notyf.success("Jovem excluído com sucesso");

          const payload = {
            idInstituicao: this.$route.params.idInstituicao,
            idEmpresa: this.$route.params.idEmpresa,
            idEmpregador: this.$route.params.idEmpregador,
          };

          this.$store
              .dispatch('loadListJovens', payload);
        } else
          notyf.error("Erro ao excluir jovem");
      }
    },
  },
}
</script>

<style scoped>
div {
    color: currentColor;
}

/* LIST TRANSITION */
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
