//---------------------------------------------
// Estudantes Module
//---------------------------------------------
// Módulo para gerenciar as ações dos estudantes

import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
  return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
  listEstudantes: [],
  studentCreated: null,
  studentUpdated: null,
  studentDeleted: null,
});

// MUTATIONS
const mutations = {
  setListEstudantes(state, payload) {
    state.listEstudantes = payload;
  },
  setStudentCreated(state, created) {
    state.studentCreated = created;
  },
  setStudentUpdated(state, updated) {
    state.studentUpdated = updated;
  },
  setStudentDeleted(state, deleted) {
    state.studentDeleted = deleted;
  },
};

// ACTIONS
const actions = {
  async setListEstudantes({ commit }, payload) {
    await axios
      .get(`estudantes/${payload.idInstituicao}/${payload.idTurma}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        commit('setListEstudantes', response.data.data);
      })
      .catch((error) => {
        console.log(error.message)
        commit('setListEstudantes', null);
      });
  },

  async addStudent({ commit }, payload) {
    commit("setStudentCreated", null);
    await axios
      .post(
        `estudantes/${payload.idInstituicao}/${payload.idTurma}`,
        payload.estudante, {
          headers: {
            'Authorization': `Bearer ${JWT()}`
          }
        }
      )
      .then((response) => {
        //commit("setStudentCreated", true)
        if (response.data.status == "created")
            commit('setStudentCreated', { success: true, message: "" })
        else
            commit('setStudentCreated', { success: false, message: response.data.message })
      })
      .catch(err => {
        console.log(err);
        //commit("setStudentCreated", false);
        commit('setStudentCreated', { success: false, message: "Houve algum problema com a criação do estudante. Por favor, revise as informações." })
      });
  },

  async updateStudent({ commit }, payload) {
    commit("setStudentUpdated", null);
    await axios
      .put(
        `estudantes/${payload.idInstituicao}/${payload.idTurma}/${payload.estudante.idEstudante}`,
        payload.estudante, {
          headers: {
            'Authorization': `Bearer ${JWT()}`
          }
        }
      )
      .then((response) =>{
        //commit("setStudentUpdated", true)
        if (response.data.status == "ok")
            commit('setStudentUpdated', { success: true, message: "" })
        else
            commit('setStudentUpdated', { success: false, message: response.data.message })
      })
      .catch(err => {
        console.log(err);
        //commit("setStudentUpdated", false);
        commit('setStudentUpdated', { success: false, message: "Houve algum problema com a edição do estudante. Por favor, revise as informações." })
      });
  },

  async deleteStudent({ commit }, payload) {
    commit("setStudentDeleted", null);
    await axios
      .delete(
        `estudantes/${payload.idInstituicao}/${payload.idTurma}/${payload.idEstudante}`, {
          headers: {
            'Authorization': `Bearer ${JWT()}`
          },
        },
      )
      .then(() => commit("setStudentDeleted", true))
      .catch(err => {
        console.log(err);
        commit("setStudentDeleted", false);
      });
  },
};

// GETTERS
const getters = {
  getListEstudantes(state) {
    return state.listEstudantes || [];
  },
  getStudentCreated(state) {
    return state.studentCreated;
  },
  getStudentUpdated(state) {
    return state.studentUpdated;
  },
  getStudentDeleted(state) {
    return state.studentDeleted;
  },
};

export default {
  state,
  mutations,
  actions,
  getters
};
