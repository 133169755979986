<template>
    <div class="root-dados">
        <div class="container list-data">
            <ModalCentral
                componentName="AddClass"
                title="ADICIONAR TURMAS"
                idName="AddTurmas"
                :data="turmas"
            />

            <ModalCentral
                componentName="AddClassByFile"
                title="ADICIONAR TURMAS"
                idName="AddTurmasByFile"
            />

            <ModalCentral
                componentName="UpdateTurma"
                title="Atualizar turma"
                idName="UpdateTurma"
                :data="{
                    idturma: editing,
                }"
            />

            <Breadcrumb
                class="breadcrumb-style"
                :crumbs="crumbs"
                section="instituicao-cadastrar"
            />
            <h3 class="title-info">Lista de turmas da UE</h3>

            <div class="title-row">
                <InstitutionInfo
                    class="title-style"
                    :title="nomeInstituicao"
                    section="instituicao-cadastrar"
                />
            </div>

            <p class="text-info-personal mt-4 mb-6">
                Nesta seção, você encontrará todas as Turmas pertencentes à
                Unidade Educacional. Caso necessário, utilize o filtro abaixo
                para localizar a Turma desejada.
            </p>

            <div class="lista-data-info">
                <!-- INSERINDO @CLICK APENAS PARA TESTAR O POST CRIAR MEMBRO, RETIRAR DEPOIS!!!!!!!!!!!!!!! -->
                <Button
                    v-if="userPerm.permissao != 'APLICADOR'"
                    class="btn mr-2 btnCtrl"
                    value="Adicionar Turmas"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="graduation-cap"
                    data-toggle="modal"
                    data-target="#AddTurmas"
                />
            </div>
            <br />

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input
                    class="form-control input-personal"
                    v-model="searchByName"
                    type="text"
                    name="filtro_nome_membros"
                    id="inlineFormCustomName"
                    placeholder="Pesquisar turmas por nome..."
                    autocomplete="off"
                />
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator" />

            <!-------------------------------- LISTANDO AS TURMAS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <transition v-if="turmas?.length > 0" name="slide-fade">
                    <div class="table-wrap">
                        <table class="table mt-3 text-center">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Período</th>
                                    <th scope="col">Código</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <TransitionGroup name="list" appear tag="tbody">
                                <tr
                                    class="table-row text-center"
                                    v-for="turma in turmas"
                                    :key="turma.counter"
                                >
                                    <td>
                                        <img
                                            class="img-info-box-lista"
                                            src="@assets/img/Dados/ListarUE/icon-turmas.svg"
                                            alt=""
                                        />
                                    </td>

                                    <td>{{ turma.nome }}</td>
                                    <td>{{ turma.periodo }}</td>
                                    <td>{{ turma.codigoturma }}</td>

                                    <td>
                                        <div class="controlGroup">
                                            <router-link
                                                v-if="
                                                    userPerm.idPermissao != '1'
                                                "
                                                :to="{
                                                    name: 'ListarEstudantes',
                                                    params: {
                                                        idTurma: turma.idturma,
                                                    },
                                                }"
                                            >
                                                <Button
                                                    class="greenGradient"
                                                    section="instituicao-campo"
                                                    value="Gerenciar estudantes"
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    iconName="person"
                                                />
                                            </router-link>

                                            <Button
                                                v-if="
                                                    userPerm.permissao !=
                                                        'APLICADOR' &&
                                                    !turma.isEditionLocked
                                                "
                                                class="greenGradient clickable"
                                                section="instituicao-campo"
                                                value="Editar turma"
                                                :isOutline="true"
                                                :hasIcon="true"
                                                iconName="pen"
                                                @click="editing = turma.idturma"
                                                data-toggle="modal"
                                                data-target="#UpdateTurma"
                                            />

                                            <Button
                                                v-if="
                                                    userPerm.permissao !=
                                                        'APLICADOR' &&
                                                    !turma.isDeletionLocked
                                                "
                                                class="third-info-box-lista btnLista"
                                                value="Excluir"
                                                :isOutline="true"
                                                :has-icon="true"
                                                iconName="trash"
                                                @click="
                                                    deleteTurma(turma.idturma)
                                                "
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </TransitionGroup>
                        </table>
                    </div>
                </transition>

                <transition v-else>
                    <h2 class="title-style-box-lista">
                        Nenhuma Turma encontrada.
                    </h2>
                </transition>
            </div>

            <div v-else>
                <SkeletonList />
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import Button from "@components/Button";
import ModalCentral from "@components/ModalCentral";
import Breadcrumb from "@components/Breadcrumb";
import InstitutionInfo from "@dados/VisualizarInstituicao/InstitutionInfo";
import SkeletonList from "@components/SkeletonUI/SkeletonList";

export default {
    name: "ListarFormularios",
    data() {
        return {
            editing: null,
            nomeInstituicao: "",
            descricao: "",
            selectedValue: "",
            searchByName: "",
            isLoadedInfo: false,
            teste: [],
            options: [
                {
                    id: 1,
                    value: "Todos",
                },
                {
                    id: 2,
                    value: "Nome",
                },
            ],
            crumbs: [
                {
                    id: 1,
                    value: "",
                    iconName: "th",
                    name_route: "GerenciarInstituicoes",
                },
                {
                    id: 2,
                    value: "Instituição",
                    iconName: "university",
                    name_route: "InstituicaoMenu",
                },
                {
                    id: 3,
                    value: "Cadastrar Informações",
                    iconName: "search-plus",
                    name_route: "InstituicaoMenuCadastrar",
                },
                {
                    id: 4,
                    value: "Unidades Educacionais",
                    iconName: "graduation-cap",
                    name_route: "ListarUnidadesEducacionais",
                },
                {
                    id: 5,
                    value: "Turmas",
                    iconName: "graduation-cap",
                    name_route: "",
                },
            ],
        };
    },
    async created() {
        this.teams = this.$store.getters.getListTeams;

        window.scrollTo(0, 0);
        const payload = {
            idInstituicao: this.$route.params.idInstituicao,
            idUnidade: this.$route.params.idUnidade,
        };
        const payload2 = {
            idInstituicao: this.$route.params.idInstituicao,
        };
        await this.$store.dispatch("setListTurmas", payload);

        this.isLoadedInfo = false;
        await this.$store.dispatch("setUserPerm", payload2);
        this.isLoadedInfo = true;
    },
    computed: {
        turmas() {
            var turmas = this.$store.getters.getListTurma;

            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = turmas ?? [];
            if (this.searchByName.trim() !== "") {
                var filtered = turmas?.filter((turma) =>
                    turma.nome
                        .trim()
                        .toLowerCase()
                        .match(this.searchByName.trim().toLowerCase())
                );
                if (filtered?.length != 0) {
                    query = filtered;
                } else {
                    query = false;
                }
            }
            return query;
        },
        userPerm() {
            return this.$store.getters.getUserPerm;
        },
    },
    components: {
        Button,
        ModalCentral,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
    },
    methods: {
        setSelectedValue: function () {
            //! TODO: só pra parar de dar erro no console
            this.selectedValue = this.$refs.filtersearchname.value;
        },
        async createTurma() {
            const payload = {
                idUE: 3,
                nome: "Turma xxxx",
                codigoTurma: "Quinta",
                periodo: "Integral",
                idade: "(5A - 5A E 11M)",
                tipoTurma: "regular",
            };
            await this.$store.dispatch("setCreateTurma", payload);
        },
        async sendClassFile() {
            const payload = {
                id_instituicao: 2,
                id_turma: 1,
                file: "filePathHere",
            };
            await this.$store.dispatch("setSendClassFile", payload);
        },

        async deleteTurma(turmaId) {
            const result = await Swal.fire({
                title: "Deseja mesmo excluir a turma?",
                text: "Ao excluir, você não terá mais acesso às informações desta turma.",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Excluir turma",
            });
            if (result.isConfirmed) {
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUE: this.$route.params.idUnidade,
                    idTurma: turmaId,
                };
                await this.$store.dispatch("deleteTurma", payload);

                const payload2 = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUnidade: this.$route.params.idUnidade,
                };

                await this.$store.dispatch("setListTurmas", payload2);
            }
        },
    },
    mounted: function () {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao;

        if (typeof idTeamSelected == "undefined") {
            this.nomeInstituicao = "Nome da Instituição";
        } else {
            let teamsSelected;
            let teamSelected;

            teamsSelected = this.teams.filter(
                (team) => team.idinstituicao == idTeamSelected
            );

            teamSelected = [...teamsSelected].shift();

            this.nomeInstituicao = teamSelected.name;
            this.descricao = teamSelected.descricao;
        }
    },
};
</script>

<style scoped>
@import "../../../assets/css/Dados/list.css";

.box-lista {
    background-repeat: repeat;
    background-size: 256px;
    background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");

    border: 1px solid #952713;
}

:deep(.box-lista span) {
    font-size: 1.1em;
}

.text-info-personal {
    color: #fff;
    font-weight: 450;
    font-size: 1.1rem;
    text-align: justify;
}

.label-filtro {
    font-weight: 600;
}

.breadcrumb-style {
    display: inline-flex;
}

.title-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.main-link {
    outline: none;
    text-decoration: none;
    cursor: pointer;
    margin-right: 0.3rem;
}

.sub-link {
    margin-left: 0.3rem;
}

.title-info {
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
}
.title-style {
    margin: 0;
    padding: 0;
}

h3.title-info {
    margin-top: 1.6rem;
    font-size: 1.25rem;
}

.title-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.separator {
    border: none;
    height: 1px;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s;
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 680px) {
    .itens-box {
        flex-direction: column;
    }
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .box-lista span,
    .box-lista a {
        width: 100%;
        text-align: center;
        margin-top: 0.5rem;
    }
    .box-lista {
        place-content: center;
    }
}

.btnCtrl {
    background-color: white;
    text-align: center;
    box-sizing: border-box;
    border: none;
}

:deep(.btnCtrl div) {
    background-clip: text;
    background-repeat: repeat;
    background-size: 256px;
    color: #00853b;
    padding: 0.5rem 0;
    transition: 0.4s;
}

:deep(.btnLista div) {
    color: #fff;
    text-align: center;
}

/* LIST TRANSITION */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
