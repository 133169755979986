<template>
    <div class="root-dados">
        <div class="container list-data">

            <ModalCentral
                componentName="ShowCampoInfo"
                title="DESCRIÇÃO DO CAMPO"
                idName="showCampoInformacaoTwo"
                :data="dataToShowCampoInfo"
            />

            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br/>

            <h3 class="title-info"> Campos - Aplicações </h3>

            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">
                Nesta seção, você encontrará todos os Campos relacionados à Instituição.
                Caso necessário, utilize o filtro abaixo para localizar o Campo desejado.
                Apenas um Campo pode apresentar o status "Ativo" por vez. Para acessar as Aplicações realizadas em
                cada Campo, clique no botão "Ver Aplicações" do Campo desejado.
            </p>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar campos de uma aplicação por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS APLICAÇÕES DO CAMPO ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnts
                    term="campos"
                    :totalQnt="totalCampos"
                    :filteredQnt="campos?.length || 0"
                    icon="clipboard" />

                <div class="list-box">
                <transition-group name="slide-fade" v-if="campos?.length > 0">
                <div class = "table-wrap">
                <table class="table mt-3">
                    <thead>
                        <tr>
                            <th scope="col">  </th>
                            <th scope="col">Nome</th>
                            <th scope="col">Criação</th>
                            <th scope="col">Status</th>
                            <th scope="col">Ações</th>
                        </tr>
                    </thead>
                    <tbody>
                        <!-- <ListagemElements :infos="membros" listaType="addMembros"/>  -->
                        <tr class="table-row" v-for="campo in campos" :key="campo.counter" :class="{'list-item-selected-orange': campo.isativo == 'ON'}">
                           
                            <td>
                                <img  class="img-info-box-lista" src="@assets/img/Cards/Lapis.svg" alt="">
                            </td>

                            <td> {{campo.titulo}}<i @click="sendInfosToShowCampoInfo(campo)" data-toggle="modal" data-target="#showCampoInformacaoTwo" class="fa fa-info-circle ml-2"></i> </td>

                            <td> Iniciada em {{campo.ano}} </td>

                            <td>
                                <span v-if="campo.isativo == 'INATIVO'" class="second-info-fixed">Campo Inativo</span>
                                <span v-if="campo.isativo == 'OFF'" class="second-info-fixed">Campo Encerrado</span>
                                <span v-if="campo.isativo == 'ON'" class="second-info-fixed orange-text-no-hover">Campo Ativo</span>
                            </td>

                            <td>
                                <div class="controlGroup">
                                    <router-link
                                        v-if="userPerm.idPermissao != '1'"
                                        :to="{name: 'ListarAplicacoes', params: { idCampoAplicacoes: campo.idamostra }}"
                                        class="btn-router-link">
                                        <Button
                                            class="orange-text btnLista"
                                            value="Ver Aplicações"
                                            section="instituicao-aplicacoes"
                                            :isOutline="true"
                                        />
                                    </router-link>

                                    <Button
                                        :disabled="baixando"
                                        v-if="userPerm.idPermissao != '1'"
                                        @click="downloadCsv(campo.idamostra, false)"
                                        class="orange-text btnLista"
                                        :value="campo.isativo == 'OFF' ? `Baixar CSV final` : `Baixar CSV parcial`"
                                        section="instituicao-aplicacoes"
                                        :isOutline="true"
                                    />

                                    <Button
                                        :disabled="baixando"
                                        v-if="userPerm.idPermissao != '1'"
                                        @click="downloadCsv(campo.idamostra, true)"
                                        class="orange-text btnLista"
                                        :value="campo.isativo == 'OFF' ? `Baixar CSV final anonimizado` : `Baixar CSV parcial anonimizado`"
                                        section="instituicao-aplicacoes"
                                        :isOutline="true"
                                    />
                                </div>
                            </td>

                        </tr>
                    </tbody>
                </table>
                </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhum Campo encontrado.</h2>
                </transition-group>
                </div>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral'
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'

import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
    name: 'CampoListaFormularios',
    data (){
        return {
            baixando: false,
            nomeInstituicao: '',
            nomeCampo: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            searchByCode: '',
            searchWithOrder: '',
            isLoadedInfo: false,
            dataToShowCampoInfo: {},
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome'
                }
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Campos - Aplicações',
                    iconName: 'th-large',
                    name_route: ''
                }
            ]
        }
    },
    components: {
        Button,
        Breadcrumb,
        ModalCentral,
        InstitutionInfo,
        SkeletonList,
        MemberQnts,
    },
    async created() {
        this.teams = this.$store.getters.getListTeams

        this.isLoadedInfo = false
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
        this.isLoadedInfo = true
    },
    computed: {
        totalCampos() {
            return this.$store.getters.getListCampos?.length || 0;
        },
        campos() {
            var campos = this.$store.getters.getListCampos

            var query = campos;

            //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
            // if (this.selectedValue == 1) {
            //     query = campos;
            // }
            //     //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
            // if (this.selectedValue == 2) {
            //     //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
            //     if (this.searchByName.trim() == '') {
            //         query = campos;
            //     }
            //     //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
            //     else {
            //         var filtered = campos?.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
            //         if(filtered?.length != 0){
            //             query = filtered
            //         }
            //
            //         else{
            //             query = false
            //         }
            //     }
            // }

            if (this.searchByName.trim() != '') {
                var filtered = campos?.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                if(filtered?.length != 0){
                    query = filtered
                }

                else{
                    query = false
                }
            }

            return query;
        },
        userPerm() {
            return this.$store.getters.getUserPerm
        },
    },
    methods: {
        downloadCsv(_idAmostra, anon) {
            if (this.baixando) return;

            this.baixando = true;
            const notiLoad = notyf.open({
                type: "info",
                background: "var(--light-green)",
                message: "Carregando dados...",
                duration: 0,
            });

            this.$store
                .dispatch("loadAmostraRespostas", {
                    idInstituicao: this.$route.params.idInstituicao,
                    idAmostra: _idAmostra,
                  anon: anon,
                })
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'resultados.zip');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    this.baixando = false;
                    notyf.dismiss(notiLoad);
                    notyf.open({
                        type: "error",
                        ...(err?.status == 491 ? { background: "var(--light-green)" } : {}),
                        message: err?.status == 491 ? err?.message : "Erro ao baixar o arquivo!",
                    });
                })
                .finally(() => {
                    this.baixando = false;
                    // Delay the dismissal of the notification
                    setTimeout(() => {
                        notyf.dismiss(notiLoad);
                    }, 500); // 0.5s

                });
        },
        setSelectedValue: function() {
            this.selectedValue = this.$refs.filtersearchname.value
        },

        sendInfosToShowCampoInfo(campo) {
            this.dataToShowCampoInfo = campo;
        },
        async copyLinkTransicao(idAmostra) {
            await this.$store.dispatch('setLinkTransicao', {
                idInstituicao: this.$route.params.idInstituicao,
                idAmostra: idAmostra,
            })

            if (this.$store.getters.getLinkTransicao) {
                navigator.clipboard.writeText(this.$getters.getLinkTransicao)
                notyf.open({
                    type: "success",
                    message: "Link copiado para a área de transferência",
                });
            } else
                notyf.open({
                    type: "error",
                    message: "Não foi possível gerar o link",
                });
        },
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>

@import "../../../assets/css/Dados/list.css";
.text-info-personal {
  color: #fff;
  font-weight: 450;
  font-size: 1.1rem;
  text-align: justify;
}


.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.list-box {
    margin: 2rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.breadcrumb-style{
    display: inline-flex;
}

.title-info{
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
}
.title-style{
    margin: 0;
    padding: 0;
}

 h3.title-info{
    margin-top: 1.6rem;
    font-size: 1.25rem;
 }

.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.separator {
    border: none;
    height: 1px;
}

 :deep(.btnLista div) {
     color: #fff;
     text-align: center;
 }

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
    }
}

.box-lista {
    background-color: #FCFCFC;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    min-height: 15vh;
    margin: 1rem;
    padding: 0.4rem 2.5rem;
}

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

.second-info {
    width: 20%;
    text-align: center;
}

.info-hide {
    opacity: 0;
    pointer-events: none;
}

.box-img {
    flex-basis: 5%;
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
    .first-info-short {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 680px) {
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .first-info-short {
        text-align: center;
        width: -webkit-fill-available;
    }
    .second-info, .third-info{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .info-hide{
        display: none;
    }
    .box-lista{
        place-content: center;
    }
}

.slide-fade-enter-active {
    transition: all .2s ease;
}
.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-40px);
    opacity: 0;
}

.fa-info-circle {
    color: #ffac30;
    font-size: 1.1rem;
    cursor: pointer;
}

</style>
