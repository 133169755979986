<template>

  <div class="container">
   
    <div>
        <h1 class="materiais-documentos title-info">Permissões de Usuário</h1>
        <p class="text-info-personal">
            Confira abaixo os tipos de permissões de cada usuário em nossa plataforma:
        </p>

        <p><i class="fas fa-pencil-alt pr-2"></i>Aplicador:</p>
        <p><i class="fas fa-crown pr-2"></i>Administrador:</p>
        <p><i class="fas fa-user pr-2"></i>Coordenador:</p>
      
    </div>
  </div>
</template>

<script>

  export default {
      name: 'Apresentacao',
      data (){
          return {
          }
      },
      components: {
        
      }
  }
</script>

<style scoped>

    .title-info {
        font-weight: 600;
        font-size: 1.6rem;
        color: #0d9f9f;
        margin: 0;
    }

    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }

    .text-info-personal, .fas {
        color: #656565;
        font-size: 1.1rem;
        text-align: justify;
        padding: 0rem;
    }

</style>