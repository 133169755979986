import axios from '@middleware/api.js';
import Cookies from 'js-cookie';
const JWT = () => {
  return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO");
};

const state = () => ({
  listEmpresas: null,
  isEmpresaCreated: null,
  isEmpresaUpdated: null,
  isEmpresaRemoved: null,
  isEmpresaUploaded: null,
});

const mutations = {
  setListEmpresas: ( state, list ) => state.listEmpresas = list,
  setIsEmpresaCreated: ( state, v ) => state.isEmpresaCreated = v,
  setIsEmpresaUpdated: ( state, v ) => state.isEmpresaUpdated = v,
  setIsEmpresaRemoved: ( state, v ) => state.isEmpresaRemoved = v,
  setIsEmpresaUploaded: ( state, v ) => state.isEmpresaUploaded = v,
};

const actions = {
  async loadListEmpresas({ commit }, payload) {
    commit('setListEmpresas', []);

    await axios
      .get(`empresa/${payload.idInstituicao}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        },
      })
      .then(response => commit('setListEmpresas', response.data?.data))
      .catch(err => {
        console.log(err);
        commit('setListEmpresas', null);
      });
  },

  async createEmpresa({ commit }, payload) {
    commit('setIsEmpresaCreated', null);

    await axios
      .post(
        `empresa/${payload.idInstituicao}`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => commit('setIsEmpresaCreated', true))
      .catch((error) => {
        console.log(error);
        commit('setIsEmpresaCreated', false);
      });
  },

  async updateEmpresa({ commit }, payload) {
    commit('setIsEmpresaCreated', null);

    await axios
      .put(
        `empresa/${payload.idInstituicao}/${payload.idEmpresa}`,
        payload,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => commit('setIsEmpresaUpdated', true))
      .catch((error) => {
        console.log(error);
        commit('setIsEmpresaUpdated', false);
      });
  },

  async removeEmpresa({ commit }, payload) {
    commit('setIsEmpresaRemoved', null);

    await axios
      .delete(
        `empresa/${payload.idInstituicao}/${payload.idEmpresa}`,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then(() => commit('setIsEmpresaRemoved', true))
      .catch((error) => {
        console.log(error);
        commit('setIsEmpresaRemoved', false);
      });
  },

  async uploadEmpresas({ commit }, payload) {
    commit('setIsEmpresaUploaded', null);

    const formData = new FormData();
    if (payload.idAmostra)
      formData.append('idAmostra', payload.idAmostra);
    formData.append('file', payload.file);

    await axios
      .post(
        `empresa/uploadEmpresa/${payload.idInstituicao}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        },
      )
      .then((response) => {
        if (response.data.status == "created")
          commit('setIsEmpresaUploaded', { success: true, message: "" })
        else
          commit('setIsEmpresaUploaded', { success: false, message: response.data.message })
      })
      .catch((error) => {
        console.log(error);
        commit('setIsEmpresaUploaded', { success: false, message: "Houve algum problema com o seu arquivo CSV. Por favor, revise as informações." })
      });
  },
};

const getters = {
  getListEmpresas: (state) => state.listEmpresas,
  getIsEmpresaCreated: (state) => state.isEmpresaCreated,
  getIsEmpresaUpdated: (state) => state.isEmpresaUpdated,
  getIsEmpresaRemoved: (state) => state.isEmpresaRemoved,
  getIsEmpresaUploaded: (state) => state.isEmpresaUploaded,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
