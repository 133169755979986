<template>
<div class="container offset">
  <form @submit.prevent="uploadEmpresas" class="custom-form">
    <div class="mb-3">
      <p class="text-justify">Para tornar mais eficiente o método de inserção das empresas da sua instituição, nós criamos um método de inserção por arquivo CSV. Para isso, baixe o nosso arquivo de modelo e edite seguindo o mesmo padrão para adicionar os novos elementos.</p>
      <a href="/examples/empresas_data.csv" download="Modelo Empresas" target="_blank">Baixar planilha de modelo.</a>
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Lista de empresas: *</label>
      <FileInput ref="empresaInput" :types="[ 'text/csv' ]" :file="fileTemp" />
    </div>

    <div class="button-div pt-3">
      <Button
        style="float: right"
        section="instituicao-cadastrar"
        value="Salvar empresas"
        :isOutline="true"
        :hasIcon="true"
        iconName="save"
        :isDisabled="!hasFile"
        />
    </div>
  </form>
</div>
</template>

<script>
import FileInput from '../FileInput.vue';
import Button from '@components/Button'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
  components: {
    FileInput,
    Button,
  },
  data: () => ({
    fileTemp: null,
    hasFile: null,
  }),
  mounted() {
    this.$watch(
      () => this.$refs.empresaInput.getFile(),
      (newFileChange) => {
        this.hasFile = newFileChange;
      }
    );
  },
  methods: {
    async uploadEmpresas() {
      if (!this.$refs.empresaInput.getFile()) return;

      const payload = {
        file: this.$refs.empresaInput.getFile(),
        idInstituicao: this.$route.params.idInstituicao,
      }
      if(this.$route.params.idCampo){
        payload['idAmostra'] = this.$route.params.idCampo;
      }
      await this.$store.dispatch('uploadEmpresas', payload);

      if (this.$store.getters.getIsEmpresaUploaded.success) {
        if(this.$route.params.idCampo){
          await this.$store.dispatch('loadListCampoEmpresas', 
            { idInstituicao: this.$route.params.idInstituicao, idAmostra: this.$route.params.idCampo }
          )
        }
        await this.$store.dispatch('loadListEmpresas', { idInstituicao: this.$route.params.idInstituicao });

        notyf.success("Empresas adicionadas com sucesso");
        this.$emit("closeModal");
        this.$refs.empresaInput.clearFile();
      } else{
        notyf.open({
            type: "error",
            className: "preformatedNotyf",
            message: this.$store.getters.getIsEmpresaUploaded.message,
        });
        this.$refs.empresaInput.clearFile();
      }
    },

    clearForm() {},
  },
}
</script>

<style scoped>
div {
    color: currentColor;
}
.preformatedNotyf {
     white-space: pre-wrap;
}
</style>
