<template>
    <div class="container offset">
        <div class="custom-form ">
            <h3>{{ dataLocal.titulo }}:</h3>
            <p>{{ dataLocal.descricao }}</p>
            <ul>
                <li><b>Cidade: </b> {{ dataLocal.cidade }}</li>
                <li><b>Ano: </b> {{ dataLocal.ano }}</li>
                <li v-show="containsTerm"><u><a :href="termLink" target="_blank"><b>Termo de consentimento</b></a></u></li>
            </ul>
        </div>
    </div>
</template>

<script>
import axios from '@middleware/api.js';

export default {
  props: {
    data: Object
  },
  computed: {
    dataLocal() {
      return this.data
    },
    containsTerm() {
      return (this.dataLocal?.termo && this.dataLocal?.termo !== 'null' && this.dataLocal?.termo !== 'undefined');
    },
    termLink() {
      return axios.defaults.baseURL + "/transicao/getTermo/?path=" + this.dataLocal?.termo;
    },
  }
}
</script>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

</style>
