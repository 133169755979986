<template>
    <ModalCentral
        v-if="userPerm"
        componentName="EditCard"
        title="Editar card"
        idName="editCard"
        :data="{ idTipo: idTipo }"
    />

    <div class="bg">
        <h2 class="pageTitle">Avaliação da qualidade</h2>

        <div class="card oquee">
            <img
                :src="getImage(cards[45]?.imagem[0])"
                alt="Crianças na escola" />
            <div class="text">
                <h3 class="innerTitle">
                    <i class="fas fa-pen editIcon"
                       v-if="userPerm"
                       @click="setIdTipo(45)"
                       data-toggle="modal"
                       data-target="#editCard" />
                    {{ cards[45]?.titulo }}
                </h3>

                <div class="content" v-html="cards[45]?.corpoTexto"></div>

                <a
                    v-for="(button, idx) in cards[45]?.botao"
                    :key="idx"
                    :href="button.link"
                    target="_blank" >
                    <Button
                        :value="button.texto"
                        class="cardBut"
                        :isOutline="false" />
                </a>
            </div>
        </div>

        <br />

        <div class="card elementos">
            <div class="text">
                <i class="fas fa-pen editIcon"
                   v-if="userPerm"
                   @click="setIdTipo(46)"
                   data-toggle="modal"
                   data-target="#editCard" />
                {{ startHtml(cards[46]?.corpoTexto) }}
            </div>

            <img
                :src="getImage(cards[46]?.imagem[0])"
                alt="Elementos fundamentais da qualidade" />

            <div class="text elementosSub">
                {{ lastHtml(cards[46]?.corpoTexto) }}
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
 import Button from '@components/Button';
 import ModalCentral from '@components/ModalCentral';
 import axios from '@middleware/api.js';

 export default {
     name: "Avaliacao",
     components: {
         Button,
         ModalCentral,
     },
     data() {
         return {
             idTipo: null,
         };
     },
     async created() {
         this.$store.dispatch("setListCards");
     },
     methods: {
         getImage(path) {
             return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
         },
         setIdTipo(id) { this.idTipo = id; },
         startHtml(text) {
             let pseudo = document.createElement("div"),
                 processed = "";
             pseudo.innerHTML = text;
             const firstEls = pseudo.querySelectorAll("p:not(:last-child)");
             console.log(firstEls);
             for (const el of firstEls) processed += el.innerHTML;
             return processed;
         },
         lastHtml(text) {
             let pseudo = document.createElement("div");
             pseudo.innerHTML = text;
             const el = pseudo.querySelector("p:last-child");
             return el.innerHTML;
         },
     },
     computed: {
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
         cards() { return this.$store.getters.getListCards; },
     },
 };
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;

     padding: 1em;
     box-sizing: border-box;
 }

 img {
     box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
     border-radius: 1em;
 }

 .pageTitle {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/azulEapi.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
     width: 100%;
     text-align: center;
     padding: 0 2em;
 }

 .card {
     background: url("~@/assets/img/Backgrounds/roxoEapi.png");
     background-repeat: repeat;
     background-size: 256px;
     width: 100%;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 1em;
     padding: 1em;
     box-sizing: border-box;
 }

 .text {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .oquee {
     display: flex;
     flex-flow: row wrap;
     align-items: center;
 }

 .oquee > img {
     flex: 1 0 40%;
     min-width: 300px;
     object-fit: cover;
     height: 100%;
 }

 .oquee > .text {
     flex: 1 0 60%;
     padding: 10px;
     box-sizing: border-box;
 }

 .text > .innerTitle {
     width: 100%;
     text-align: right;
     font-weight: bold;
 }

 .text > p {
     text-align: justify;
 }

 .cardBut {
     border-radius: 50px;
     background-color: #59566B;
     float: right;
 }

 .elementosSub {
     font-size: 0.9em;
 }

 .elementos img {
     width: 100%;
     display: block;
     margin: 10px auto;
     max-width: 500px;
     border-radius: 0;
 }

 .editIcon {
     float: right;
     color: #fcfaf2;
     font-size: 1.3rem;
     cursor: pointer;
     transition: 0.4s;
     box-sizing: border-box;
     margin: 5px;
 }

 .editIcon:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }

 button.grow:hover {
     transform: scale(1.01);
 }

 .content :deep(ol) {
     list-style-type: decimal;
     list-style-position: inside;
 }

 .content :deep(li::marker) {
     color: #fcfaf2;
 }
</style>
