<template>
    <ModalCentral
        v-if="userPerm"
        componentName="EditCard"
        :title="idCard ? 'Editar card' : 'Adicionar card'"
        idName="editCard"
        :data="{ idCard: idCard, ordem: order, idTipo: idTipo }"
    />

    <div class="root container">
        <div class="info-card row project-metrics p-4 mb-6"
            v-for="card in sobreTop"
            :key="card.idCard"> 
            <div class="d-flex justify-content-between">
                <h1 class="card-title">
                    {{ card?.titulo }}
                </h1>
                <div class="card-options" v-if="userPerm">
                    <button type="button" class="btn btn-success btn-circle" @click="setIdCard(card.idCard)" data-toggle="modal" data-target="#editCard">
                        <i class="fas fa-pen"/>
                    </button>
                    <button type="button" class="btn btn-success btn-circle" @click="deleteCard(card.idCard)">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
            </div>
            <div class="card-text row">
                <p class = "col" v-html="card?.corpoTexto"></p>
                <!--<div class="card-list col-6">
                    <ul>
                        <li>Disponibilizar ferramentas para acompanhar a transição entre o mundo da escola e o trabalho.</li>
                        <li>Democratizar o acesso aos dados coletados com estas ferramentas.</li>
                        <li>Disseminar conhecimento para enfrentar o desafio de alinhar as diferentes fases da vida dos jovens brasileiros.</li>
                    </ul>
                </div>-->
            </div>
        </div>

        <div v-if="userPerm" class="text-center mb-6">
            <button type="button" 
            @click="setIdCard(parseInt(cards.length) + 1, true, 45)"
            data-toggle="modal"
            data-target="#editCard"
            class="btn btn-success btn-circle">
            <i class="fas fa-plus"></i>
            </button>
        </div>

        <!--<div 
            class="info-card row project-metrics p-4 mb-6"
            v-for="card in sobreBot"
            :key="card.idCard"> 
            <div class="d-flex justify-content-between">
                <h1 class="card-title">
                    {{ card?.titulo }}
                </h1>
                <div class="card-options" v-if="userPerm">
                    <button type="button" class="btn btn-success btn-circle" @click="setIdCard(card.idCard)" data-toggle="modal" data-target="#editCard">
                        <i class="fas fa-pen"/>
                    </button>
                    <button type="button" class="btn btn-success btn-circle disabled">
                        <i class="fas fa-trash"></i>
                    </button>
                </div>
            </div>
            
            <div class="card-text row">
                <p v-html="card?.corpoTexto"></p>
            </div>
        </div>-->
    </div>

</template>

<script type="text/javascript">
 import ModalCentral from '@components/ModalCentral';
 import axios from '@middleware/api.js';
 import Swal from 'sweetalert2';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 });

 export default {
     name: "OQueE",
     components: {
         ModalCentral,
     },
     data() {
         return {
             idCard: null,
             order: null,
             idTipo: null,
         };
     },
     async created() {
         this.$store.dispatch("setListCards");
     },
     methods: {
        setIdCard(id, newCard, idTipo) {
             this.idCard = newCard ? null : id;
             this.order = newCard ? id : null;
             this.idTipo = newCard ? idTipo : this.cards.find(card => card.idCard == id)?.idTipo;
         },
        getImage(path) {
            return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
        },
        async deleteCard(idCard) {
            const result = await Swal.fire({
                title: 'Deseja realmente excluir esse?',
                text: 'Tem certeza que deseja excluir esse card? Essa operação não pode ser desfeita.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
            });
            if (!result.isConfirmed) return;

            await this.$store.dispatch("deleteCard", idCard);

            if (this.$store.getters.getCardDeleted) {
                notyf.open({
                    type: "success",
                    message: "Card excluído com sucesso",
                });
                this.$store.dispatch("setListCards");
            } else {
                notyf.open({
                    type: "error",
                    message: "Não foi possível excluir o card",
                });
            }
        },
     },
     computed: {
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
         cards() { return this.$store.getters.getListCards; },
         sobreTop() { return (this.cards || []).filter(card => card?.idTipo == 45); },
         //sobreBot() { return (this.cards || []).filter(card => card?.idTipo == 46); },
         
     },
 };
</script>

<style scoped>

@import "../../assets/css/MateriaiseDocumentos/main.css";

p, li, ::v-deep li{
    text-align: justify;
    color: #6B6B6B;
    font-weight: 500;
    font-size: 18px !important;
    line-height: 28px !important;
}

</style>
