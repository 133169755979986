const parsers = {

    convertUTC(data) {
        const currentDate = new Date(data);

        const myDate = currentDate.toLocaleTimeString("pt-BR", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "numeric",
            minute: "numeric",
        });

        return myDate;
    }

}

export default parsers;
