<template>
    <div class="container offset">
        <form @submit.prevent="handleStudent" ref="formClass">
            <div class="custom-form">
                <div class="form-group">
                    <label class="question-style pt-1"
                        >Nome do estudante: *</label
                    >
                    <input
                        v-model.trim="estudante.nome"
                        type="name"
                        class="form-control"
                        placeholder="Insira aqui o nome do estudante..."
                        required
                    />
                </div>

                <div class="form-group">
                    <label class="question-style pt-1">CPF:</label>
                    <input
                        class="form-control"
                        v-maska="'###.###.###-##'"
                        type="text"
                        placeholder="xxx.xxx.xxx-xx"
                        v-model.trim="estudante.cpf"
                    />
                </div>

                <div class="form-group">
                    <label class="question-style">Gênero do estudante:</label>
                    <select
                        v-model.trim="estudante.genero"
                        class="form-control"
                    >
                        <option
                            class="question-style"
                            value=""
                            selected
                            disabled
                        >
                            Selecione aqui o gênero...
                        </option>
                        <option class="question-style" value="MASCULINO">
                            Masculino
                        </option>
                        <option class="question-style" value="FEMININO">
                            Feminino
                        </option>
                        <option class="question-style" value="OUTRO">
                            Outro
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label class="question-style pt-1"
                        >Data de nascimento do estudante:</label
                    >
                    <input
                        type="date"
                        name="dataNasc"
                        v-model.trim="estudante.dataNascimento"
                        class="form-control"
                        :max="maxDate"
                        :min="minDate"
                    />
                </div>

                <div
                    class="formMsg"
                    v-if="!data"
                    :class="{
                        formMsgSuccess: lastAdd?.length,
                        formMsgError: !lastAdd?.length,
                    }"
                >
                    Último estudante adicionado: '{{ lastAdd }}'
                </div>

                <div class="formButtons">
                    <div class="button-div pt-4">
                        <Button
                            value="Limpar"
                            section="descartar"
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="trash"
                            @click="clearForm"
                            type="button"
                        />
                    </div>
                    <div class="button-div pt-4">
                        <Button
                            section="instituicao-cadastrar"
                            value="Salvar estudante"
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="save"
                        />
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Button from "@components/Button";
import { validate } from 'gerador-validador-cpf';
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
    name: "AddStudent",
    props: {
        data: {
            type: Object,
            required: false,
        },
    },
    watch: {
        data() {
            if (this.data.editing) {
                this.estudante.idEstudante = this.data.editing.idEstudante;
                this.estudante.nome = this.data.editing.nome;
                this.estudante.cpf = this.data.editing.cpf;
                this.estudante.genero = this.data.editing.genero || "";
                this.estudante.dataNascimento =
                    this.data.editing.dataNascimento;
            } else {
                this.estudante.idEstudante = null;
                this.estudante.cpf = null;
                this.estudante.nome = null;
                this.estudante.genero = "";
                this.estudante.dataNascimento = null;
            }

            if (this.data.listStudents) {
                this.actualList = this.data.listStudents;
            } else {
                this.actualList = [];
            }
        },
    },
    data() {
        return {
            estudante: {
                idEstudante: null,
                nome: null,
                cpf: null,
                genero: "",
                dataNascimento: null,
            },
            actualList: null,
            lastAdd: null,
            maxDate: this.getCurrentDate(),
            minDate: this.getMinDate(),
        };
    },
    methods: {
        async handleStudent() {
            if ((this.estudante.cpf != null && this.estudante.cpf != "")) {
                if(!this.validCpf){
                    notyf.error("CPF inválido!");
                    return;
                }
            }
            if (this.data.editing) {
                await this.updateStudent();
            } else {
                await this.createStudent();
            }
        },
        async updateStudent() {

            if(this.estudante.genero == ""){
                this.estudante.genero = null;
            }
            this.estudante.cpf = this.estudante?.cpf?.replace(/\D/g, '')?.toString();
            const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idTurma: this.$route.params.idTurma,
                estudante: this.estudante,
            };

            await this.$store.dispatch("updateStudent", payload);
            if (this.$store.getters.getStudentUpdated.success) {
                notyf.open({
                    type: "success",
                    message: "Estudante atualizado com sucesso!",
                });
                this.$emit("emit");
                this.$emit("closeModal");
            } else {
                notyf.open({
                    type: "error",
                    message: this.$store.getters.getStudentUpdated.message,
                });
            }
            
        },
        async createStudent() {

            if(this.estudante.genero == ""){
                this.estudante.genero = null;
            }
            this.estudante.cpf = this.estudante?.cpf?.replace(/\D/g, '')?.toString();
            const payload = {
                idInstituicao: this.$route.params.idInstituicao,
                idTurma: this.$route.params.idTurma,
                estudante: this.estudante,
            };

            await this.$store.dispatch("addStudent", payload);
            if (this.$store.getters.getStudentCreated.success) {
                this.lastAdd = this.estudante.nome;
                notyf.open({
                    type: "success",
                    message: "Estudante adicionado com sucesso!",
                });
                this.clearForm();
                this.$emit("emit");
            } else {
                notyf.open({
                    type: "error",
                    message: this.$store.getters.getStudentCreated.message,
                });
            }
        },
        clearForm() {
            if (this.data.editing) {
                this.estudante.idEstudante = this.data.editing.idEstudante;
                this.estudante.nome = this.data.editing.nome;
                this.estudante.cpf = this.data.editing.cpf;
                this.estudante.genero = this.data.editing.genero || "";
                this.estudante.dataNascimento =
                    this.data.editing.dataNascimento;
            } else {
                this.estudante.idEstudante = null;
                this.estudante.cpf = null;
                this.estudante.nome = null;
                this.estudante.genero = "";
                this.estudante.dataNascimento = null;
            }
        },
        getCurrentDate() {
            const now = new Date();
            const year = now.getFullYear() - 12;
            const month = String(now.getMonth() + 1).padStart(2, "0");
            const day = String(now.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        getMinDate() {
            const now = new Date();
            const year = now.getFullYear() - 60;
            const month = String(now.getMonth() + 1).padStart(2, "0");
            const day = String(now.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
    },
    components: {
        Button,
    },
    computed:{
        validCpf() { return validate(this.estudante.cpf) },
    }
};
</script>

<style scoped>
.title-style {
    font-size: 1.5rem;
    font-weight: 600;
    color: #656565;
}

.question-style {
    width: 100%;
    font-size: 1rem;
    color: #656565;
}

.image-style {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding: 1rem;
}

.button-div {
    text-align: center;
}

.formMsg {
    margin-top: 5px;
}

.formMsgSuccess {
    color: green;
}

.formMsgError {
    display: none;
}

.formButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-content: space-around;
}
</style>
