<template>
<div class="container offset">
  <form @submit.prevent="upsertEgresso" class="custom-form">
    <div class="form-group">
      <label class="question-style pt-1">Nome: *</label>
      <input
        v-model.trim="egresso.nome"
        type="name"
        class="form-control"
        placeholder="Insira aqui o nome do egresso..."
        required
        />
    </div>

    <div class="form-group">
      <label class="question-style pt-1">CPF:</label>
      <input
        class="form-control"
        v-maska="'###.###.###-##'"
        type="text"
        placeholder="xxx.xxx.xxx-xx"
        v-model="egresso.cpf"
        />
    </div>

    <div class="form-group">
      <label class="question-style pt-1">Data de nascimento:</label>
      <input
        class="form-control"
        type="date"
        v-model="egresso.dataNascimento" />
    </div>

    <div class="formButtons">
      <div class="button-div pt-4">
        <Button
          value="Limpar"
          type="button"
          section="descartar"
          :isOutline="true"
          :hasIcon="true"
          iconName="trash"
          @click="clearData" />
      </div>

      <div class="button-div pt-4">
        <Button
          section="instituicao-cadastrar"
          value="Salvar egresso"
          :isOutline="true"
          :hasIcon="true"
          iconName="save"
          />
      </div>
    </div>
  </form>
</div>
</template>

<script>
import Button from '@components/Button';
import { maska } from 'maska';
import { validate } from 'gerador-validador-cpf';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'left',
    y: 'bottom',
  }
});

export default {
  components: {
    Button,
  },
  directives: {
    maska,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    egresso: {
      nome: null,
      cpf: null,
      dataNascimento: null,
    },
  }),
  computed: {
    validCpf() { return validate(this.egresso.cpf); },
    // egressoPayload() {
    //   let payload = { nome: this.egresso.nome };
    //   if (this.egresso.cpf && this.egresso.cpf.trim() != '')
    //     payload.cpf = this.egresso.cpf.replace(/\D/g, '');
    //   if (this.egresso.dataNascimento)
    //     payload.dataNascimento = this.egresso.dataNascimento;
    //   return payload;
    // },
  },
  watch: {
    data(updated) {
      if (!updated?.editing || updated?.editing == {}) {
        this.clearData();
      }
      else {
        this.egresso.nome = updated?.editing.nome;
        this.egresso.cpf = updated?.editing.cpf;
        this.egresso.dataNascimento = updated?.editing.dataNascimento != null ?
        this.maskDate(updated?.editing.dataNascimento)
        : 
        null;
      }
    },
  },
  methods: {
    maskDate(inputDate){
      let date = new Date(inputDate);
      var day = date.getUTCDate();
      var month = date.getUTCMonth() + 1;
      var year = date.getUTCFullYear();

      var formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
      return formattedDate
    },
    clearData() {
      this.egresso = {
        nome: null,
        cpf: null,
        dataNascimento: null,
      };
    },
    createEgresso() {
      if (this.egresso.cpf == "") {
        this.egresso.cpf = null;
      }
      if (this.egresso.dataNascimento == "") {
        this.egresso.dataNascimento = null;
      }
      this
        .$store
        .dispatch("createEgresso", {
          idInstituicao: this.$route.params.idInstituicao,
          egresso: {
            ...this.egresso,
            cpf: this.egresso.cpf != null ? this.egresso.cpf.replace(/\D/g, '') : null,
          },
        })
        .then(() => {
          if (!this.$store.getters.getIsEgressosCreated) {
            if(this.$store.getters.getEgressoCreationError == 409){
              notyf.error("O CPF digitado já se encontra cadastrado. Verifique o campo e tente novamente.");
            }
            else{
              notyf.error("Erro na criação do egresso");
            }
            return;
          }
          notyf.success("Egresso criado com sucesso");
          this.clearData();

          this.$store.dispatch('loadListEgressos', {
            idInstituicao: this.$route.params.idInstituicao
          });

          this.$emit("closeModal");
        })
        .catch(() => {
          notyf.error("Erro na adição do egresso");
        });
    },

    updateEgresso() {
      if (this.egresso.cpf == "") {
        this.egresso.cpf = null;
      }
      if (this.egresso.dataNascimento == "") {
        this.egresso.dataNascimento = null;
      }
      this
        .$store
        .dispatch("updateEgresso", {
          idInstituicao: this.$route.params.idInstituicao,
          idEgresso: this.data.editing.idEgresso,
          egresso: {
            ...this.egresso,
            cpf: this.egresso.cpf != null ? this.egresso.cpf.replace(/\D/g, '') : null,
          },
        })
        .then(() => {
          if (!this.$store.getters.getIsEgressosUpdated) {
            if(this.$store.getters.getEgressoUpdateError == 409){
              notyf.error("O CPF digitado já se encontra cadastrado. Verifique o campo e tente novamente.");
            }
            else{
              notyf.error("Erro na atualização do egresso");
            }
            return;
          }
          notyf.success("Egresso atualizado com sucesso");
          this.clearData();

          this.$store.dispatch('loadListEgressos', {
            idInstituicao: this.$route.params.idInstituicao
          });

          this.$emit("closeModal");
        })
        .catch(() => {
          notyf.error("Erro na atualização do egresso");
        });
    },
    upsertEgresso() {
      if ((this.egresso.cpf != null && this.egresso.cpf != "")) {
        if(!this.validCpf){
          notyf.error("CPF inválido!");
          return;
        }
      }

      if (!this.data.editing) this.createEgresso();
      else this.updateEgresso();
    },
    clearForm() {
      if (!this.data.editing) {
        this.clearData();
      }
      else {
        this.egresso.nome = this.data.editing.nome
        this.egresso.cpf = this.data.editing.cpf
        if(this.egresso.dataNascimento != null){
          this.egresso.dataNascimento = this.maskDate(this.data.editing.dataNascimento)
        }
      }
    }
  },
}
</script>

<style scoped>
.formButtons {
    display: flex;
    flex-direction: row-reverse;
    gap: 10px;
}
</style>
