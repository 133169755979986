<template>
<div class="initModal">
  <p>Para iniciar o formulário, digite abaixo seu identificador ({{ identificador }}):</p>

  <input
    v-if="identificador == 'cpf'"
    class="modalInput"
    @input="checkInput"
    v-maska="'###.###.###-##'"
    type="text"
    placeholder="xxx.xxx.xxx-xx"
    v-model="inputIdentificador" />

  <input
    v-else-if="identificador == 'código'"
    @input="checkInput"
    maxlength="4"
    class="modalInput"
    type="text"
    placeholder="XXXX"
    v-model="inputIdentificador" />

  <input
    v-else
    @input="checkInput"
    minlength="2"
    class="modalInput"
    type="text"
    placeholder="Identificador"
    v-model="inputIdentificador" />

  <div class="termContainer" v-show="containsTerm">
    <div class="bylawCheck">
      <input type="checkbox" name="bylaw" v-model="termAccepted" />
      <label for="bylaw">
        Li e aceito os <a target="_blank" class="clickable" :href="termLink">termos e condições</a>
      </label>
    </div>
  </div>
  <div class="buttonContainer">
    <button
      @click="initForm"
      :disabled="!termAccepted || !idValid"
      :class="{
              'greenGradient clickable': termAccepted && idValid,
              'disabled': !termAccepted || !idValid,
              }">
      Começar!
    </button>

    <!-- <a -->
    <!--   v-if="identificador == 'cpf'" -->
    <!--   @click="e => noCpf(e)" -->
    <!--   class="clickable" -->
    <!--   > -->
    <!--   Não é possível informar o CPF -->
    <!-- </a> -->
    <!-- <button
         @click="teste"
         :class="{
         'greenGradient clickable': true
         }">
         Testar!
         </button> -->
  </div>
</div>
</template>

<script type="text/javascript">
import { maska } from 'maska';
import { validate } from 'gerador-validador-cpf';
import axios from '@middleware/api.js';
// import ModalCentral from '@components/ModalCentral';
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: 'left',
        y: 'bottom',
    }
});

export default {
    name: "TransicaoInitModal",
    directives: {
        maska,
    },
    // components: {
    //     ModalCentral,
    // },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            inputIdentificador: "",
            termAccepted: !(this.$store.getters.getFormTransicao?.termo && this.$store.getters.getFormTransicao?.termo !== 'null' && this.$store.getters.getFormTransicao?.termo !== 'undefined'),
        };
    },
    computed: {
      identificador() { return this.$store.getters.getFormTransicao?.identificador },
      formIsUnique() { return this.$store.getters.getFormTransicao?.isUniqueId },
        containsTerm() {
            return (this.$store.getters.getFormTransicao?.termo && this.$store.getters.getFormTransicao?.termo !== 'null' && this.$store.getters.getFormTransicao?.termo !== 'undefined');
        },
      curingaValid() { return this.$store.getters.getCodeIsValid; },
      uniqueValid() { return this.$store.getters.getIsUnique; },
        cypher() {
            return this.$route.params.cypher;
        },
        formStarted() {
            return this.$store.getters.getFormStarted;
        },
        termLink() {
            return axios.defaults.baseURL + "/transicao/getTermo/?path=" + this.$store.getters.getFormTransicao?.termo;
        },
      validCpf() {
        return validate(this.inputIdentificador);
      },
      idValid() {
        let check = true;
        if (this.formIsUnique)
          check &= this.uniqueValid;

        if (this.identificador == 'cpf')
          check &= this.inputIdentificador.length == 14 && this.validCpf;
        else if (this.identificador == 'código')
          check &= this.curingaValid;
        else
          check &= this.inputIdentificador.length >= 1;
        return check;
      }
    },
    mounted() { this.checkShouldClose(); },
    watch: {
        formStarted() { this.checkShouldClose(); },
    },
    methods: {
        noCpf(e) {
            e.preventDefault();
            this.data.changeComponent('CpfCuringa');
        },
      async checkUnique() {
        await this.$store.dispatch("checkUnique", { cypher: this.cypher, id: this.inputIdentificador });
        if (!this.uniqueValid)
          notyf.open({
            type: "error",
            message: "Resposta já registrada para esse identificador",
          });
      },
      async checkCuringa() {
        this.$store.dispatch("checkCode", { cypher: this.cypher, id: this.inputIdentificador });
      },
      async checkInput() {
        if (this.identificador == 'cpf' && this.inputIdentificador.length >= 14 && !this.validCpf)
          notyf.open({
            type: "error",
            message: "CPF inválido, favor confira a entrada",
          });

        if (this.formIsUnique)
          this.checkUnique();
        if (this.identificador == 'código')
          this.checkCuringa();
      },
        async initForm() {
          if (!this.idValid) return;
          if (!['código', "cpf"].includes(this.identificador) && this.inputIdentificador.length < 1)
            return;
          this.$emit("closeModal");
          this.$store.dispatch("startForm", { id: this.inputIdentificador });
        },

        async teste(){



            async function gerarCpf() {
                const num1 = aleatorio(); //aleatorio já devolve string, logo não precisa de toString
                const num2 = aleatorio();
                const num3 = aleatorio();

                const dig1 = dig(num1, num2, num3); //agora só uma função dig
                const dig2 = dig(num1, num2, num3, dig1); //mesma função dig aqui

                //aqui com interpolação de strings fica bem mais legivel
                return `${num1}.${num2}.${num3}-${dig1}${dig2}`;
            }

            //o quarto parametro(n4) só será recebido para o segundo digito
            function dig(n1, n2, n3, n4) {

                //as concatenações todas juntas uma vez que são curtas e legíveis
                const nums = n1.split("").concat(n2.split(""), n3.split(""));

                if (n4 !== undefined){ //se for o segundo digito coloca o n4 no sitio certo
                    nums[9] = n4;
                }

                let x = 0;

                //o j é também iniciado e incrementado no for para aproveitar a própria sintaxe dele
                //o i tem inicios diferentes consoante é 1º ou 2º digito verificador
                for (let i = (n4 !== undefined ? 11:10), j = 0; i >= 2; i--, j++) {
                    x += parseInt(nums[j]) * i;
                }

                const y = x % 11;
                //ternário aqui pois ambos os retornos são simples e continua legivel
                return y < 2 ? 0 : 11 - y;
            }

            function aleatorio() {
                const aleat = Math.floor(Math.random() * 999);
                //o preenchimento dos zeros à esquerda é mais facil com a função padStart da string
                return ("" + aleat).padStart(3, '0');
            }

            async function fillForm() {
                [...document.querySelectorAll('.radioGroup')].map(async (x) => {
                    try{
                        x.querySelectorAll('input')[Math.floor(Math.random() * 5)].click()
                    } catch(e){x.querySelectorAll('input')[Math.floor(Math.random() * 4)].click()
                              }
                    await document.querySelector("#app > div > div > div.formMain > div.card.form > div.footer > button:nth-child(3)").click()
                })

            }

            async function exec() {
                for (let i = 0; i < 22; i++){
                    await fillForm();
                }

                document.querySelector("#app > div > div > div.formMain > div.card.sections > div.footer > button").click()
            }

            async function init() {
                const cpf = await gerarCpf()
                document.querySelectorAll("input")[0].__vueParentComponent.data.cpf = cpf
                document.querySelectorAll("input")[0].__vueParentComponent.renderCache[1]()
                document.querySelector("#popupInit > div > div > div.modal-body > div > div.termContainer > div > input[type=checkbox]").click()



                return cpf
            }



            async function firstPage() {
                const escola = document.querySelector("#app > div > div > div.formMain > div.card.form > div.innerForm > div:nth-child(1) > div > div.questionBody > div > div > div > input[type=radio]")
                escola.click()
                // document.querySelectorAll("input")[1].__vueParentComponent.data.other = cpf

                // Seleciona o ano
                setTimeout(() => {
                    [document.querySelectorAll('.radioGroup')[1]].map((x) => {
                        try{
                            x.querySelectorAll('input')[Math.floor(Math.random() * 5)].click()
                        } catch(e){x.querySelectorAll('input')[Math.floor(Math.random() * 4)].click()
                                  }
                    })

                }, 100);

                const turma = document.querySelector("#app > div > div > div.formMain > div.card.form > div.innerForm > div:nth-child(3) > div > div.questionBody > div > div > div > input[type=radio]")
                turma.click()
                // document.querySelectorAll("input")[7].__vueParentComponent.data.other = cpf

                const nome = document.querySelector("#app > div > div > div.formMain > div.card.form > div.innerForm > div:nth-child(4) > div > div.questionBody > div > div > div > input[type=radio]")
                nome.click()
                // document.querySelectorAll("input")[9].__vueParentComponent.data.other = cpf

                // sexo
                setTimeout(() => {
                    [document.querySelectorAll('.radioGroup')[4]].map(async (x) => {
                        try{
                            x.querySelectorAll('input')[Math.floor(Math.random() * 5)].click()
                        } catch(e){x.querySelectorAll('input')[Math.floor(Math.random() * 4)].click()
                                  }
                    })
                }, 100);


                // // ano
                document.querySelectorAll("input")[10].__vueParentComponent.data.answerTmp['0'] = Math.floor(Math.random() * 2000).toString()
                document.querySelectorAll("input")[10].__vueParentComponent.renderCache[6]()

                // // mes
                document.querySelectorAll("input")[11].__vueParentComponent.data.answerTmp['0'] = Math.floor(Math.random() * 12).toString()
                document.querySelectorAll("input")[11].__vueParentComponent.renderCache[6]()

                // // dia
                document.querySelectorAll("input")[12].__vueParentComponent.data.answerTmp['0'] = Math.floor(Math.random() * 30).toString()
                document.querySelectorAll("input")[12].__vueParentComponent.renderCache[6]()


                // experiencia
                setTimeout(() => {
                    [document.querySelectorAll('.radioGroup')[5]].map(async (x) => {
                        try{
                            x.querySelectorAll('input')[Math.floor(Math.random() * 5)].click()
                        } catch(e){x.querySelectorAll('input')[Math.floor(Math.random() * 2)].click()
                                  }
                    })
                }, 100);

                setTimeout(() => {
                    [document.querySelectorAll('.radioGroup')[6]].map(async (x) => {
                        try{
                            x.querySelectorAll('input')[Math.floor(Math.random() * 5)].click()
                        } catch(e){x.querySelectorAll('input')[Math.floor(Math.random() * 1)].click()
                                  }
          })
        }, 100);









      }


      async function main() {
        const getCPF = await init();
        setTimeout(() => {
          console.log('1')
          document.querySelector('button').click()
          // document.querySelector("#popupInit > div > div > div.modal-body > div > div.buttonContainer > button").click()
        }, 100);
        setTimeout(() => {
          console.log('executou first')
          firstPage();
        }, 100);
        setTimeout(() => {
          console.log('others')
          document.querySelectorAll("input")[1].__vueParentComponent.data.other = getCPF
          document.querySelectorAll("input")[1].__vueParentComponent.update()
          document.querySelectorAll("input")[7].__vueParentComponent.data.other = getCPF
          document.querySelectorAll("input")[7].__vueParentComponent.update()

          document.querySelectorAll("input")[9].__vueParentComponent.data.other = getCPF
          document.querySelectorAll("input")[9].__vueParentComponent.update()


        }, 200); 

        setTimeout(() => {
          try {
            document.querySelectorAll("input")[20].__vueParentComponent.data.answerTmp = Math.floor(Math.random() * 100).toString()
            document.querySelectorAll("input")[20].__vueParentComponent.renderCache[1]()
    
        }
        catch(e){
            console.log(e)
        }
    }, 1000);

    setTimeout(() => {

        console.log('executou fim')
        document.querySelectorAll('button')[4].click()

    }, 1000);

    setTimeout(() => {
        exec()

    }, 1000);


}

main();
         },
         checkShouldClose() {
             if (this.formStarted)
                 this.$emit("closeModal");
         },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 @import url('../../assets/css/TransicaoEscolaTrabalho/styling.css');

 .initModal {
     display: flex;
     flex-direction: row;
     align-items: center;
     flex-wrap: wrap;
 }

 p {
     flex: 1 0 100%;
 }

 .modalInput {
     flex: 1 0 200px;
     display: block;
     margin: 0 auto 1em auto;
     border: 0;
     border-radius: 8px;
     box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
     min-width: 0;
     padding: 5px;
 }

 .termContainer {
     flex: 1 0 100%;
     display: flex;
     flex-wrap: wrap;
 }

 .termContainer a, .buttonContainer a {
     color: var(--dark-green);
     font-weight: bold;
     display: inline-block;
 }

 .termContainer a:hover, .buttonContainer a:hover {
     transform: scale(1.015);
 }

 .bylaw {
     flex: 1 0 100%;
     display: block;
     background-color: whitesmoke;
     max-height: 200px;
     overflow: hidden;
     padding: 5px;
     margin: 0 auto 1em auto;
     border-radius: 8px;
     box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
 }

 .bylaw > div {
     height: 100%;
     overflow: auto;
 }

 .bylawCheck {
     display: grid;
     grid-template-columns: auto 1fr;
     gap: 5px;
     align-items: center;
 }

 .bylawCheck > input {
     cursor: pointer;
 }

 .bylawCheck > label {
     padding: 0;
     margin: 0;
 }

 .buttonContainer {
     margin-top: 10px;
     flex: 1 0 100%;
     display: flex;
     flex-direction: row-reverse;
     justify-content: space-between;
     align-items: center;
 }
</style>
