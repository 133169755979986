<template>
<div>
  <template
    v-for="question of questionList"
    :key="question?.id || question?.uuid"
    >
    <QuestionCard
      :question="question"
      :blocked="true"
      />
    <br />
  </template>
</div>
</template>

<script type="text/javascript">
import QuestionCard from "./QuestionCard.vue";

export default {
  name: "InfoQuestoes",
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [],
      form: null,
    };
  },
  watch: {
    data(updated) {
      if (!updated?.items) return;
      this.loadItems(updated?.items);
      this.form = updated?.form;
    },
  },
  components: {
    QuestionCard,
  },
  computed: {
    listItemQuestions() { return this.$store.getters.getListItemQuestions || []; },
    questionList() {
      if (this.form)
        return [].concat.apply([], (this.$store.getters.getListFormItems || []).map(item => item?.questions));
      else
        return [].concat.apply([], this.listItemQuestions.map(item => item?.corpo?.questions));
    },
  },
  methods: {
    loadItems(_items) {
      if (!this.form) {
        if (!_items || _items?.length === 0) return;
        this.items = _items;
        this.$store.dispatch('loadListItemQuestions', _items.map(i => i?.idItem || i?.id_item));
      }
    },
  },
};
</script>

<style scoped>
div {
    width: 100%;
}
</style>
