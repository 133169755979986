<template>
    <div class="container">
        <span>
            <i class="fas fa-university"></i>
            {{ filteredQnt }}
        </span>
        <span>Total de instituições: {{ totalQnt }}</span>
    </div>
</template>

<script type="text/javascript">
 export default {
     name: "InstisQnt",
     props: {
         totalQnt: {
             type: Number,
             required: true,
         },
         filteredQnt: {
             type: Number,
             required: true,
         },
     }
 };
</script>

<style scoped>
 .container {
     width: 100%;
     display: flex;
     justify-content: space-between;
     flex-wrap: wrap;
 }
</style>
