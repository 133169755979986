<template>
    <form v-if="loaded" class="container offset" @submit.prevent="updateSections">
        <div class="form-group">
            <label class="question-style">Seção 1: *</label>
            <input
                v-model="secoes[0].nome"
                type="text"
                class="form-control"
                placeholder="Título da primeira seção..."
                required
            />
        </div>

        <div class="form-group">
            <label class="question-style">Seção 2: *</label>
            <input
                v-model="secoes[1].nome"
                type="text"
                class="form-control"
                placeholder="Título da segunda seção..."
                required
            />
        </div>

        <div class="form-group">
            <label class="question-style">Seção 3: *</label>
            <input
                v-model="secoes[2].nome"
                type="text"
                class="form-control"
                placeholder="Título da terceira seção..."
                required
            />
        </div>

        <div class="form-group">
            <label class="question-style">Seção 4: *</label>
            <input
                v-model="secoes[3].nome"
                type="text"
                class="form-control"
                placeholder="Título da quarta seção..."
                required
            />
        </div>

        <div class="form-group">
            <label class="question-style">Seção 5: *</label>
            <input
                v-model="secoes[4].nome"
                type="text"
                class="form-control"
                placeholder="Título da quinta seção..."
                required
            />
        </div>

        <Button
            section="instituicao-campo"
            value="Salvar Seções"
            :isOutline="true"
            :hasIcon="true"
            iconName="save"
        />
    </form>
</template>

<script type="text/javascript">
 import Button from '@components/Button'
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });


 export default {
     name: "UpdateSectionsPraticas",
     components: {
         Button,
     },
     data() {
         return {
             loaded: false,
             secoes: [],
         };
     },
     async created() {
         await this.$store.dispatch('setPraticaSections');
         this.secoes = this.$store.getters.getPraticaSections;
         this.loaded = true;
     },
     methods: {
         async updateSections() {
             let success = true;
             for (const section of this.secoes)  {
                 await this.$store.dispatch('updatePraticaSection', {
                     idSecao: section?.idTipo,
                     nome: section?.nome,
                 });
                 if (!this.$store.getters.getPraticaSectionUpdated)
                     notyf.open({
                         type: "error",
                         message: `Erro na atualização da seção ${section?.nome}`,
                     });
                 success &= this.$store.getters.getPraticaSectionUpdated;
             }

             if (success)
                notyf.open({
                    type: "success",
                    message: `Seções atualizadas com sucesso`,
                });
         },
     },
     computed: {
     },
 };
</script>

<style scoped>
 button {
     float: right;
 }
</style>
