<template>

    <div>
        
        <p class="text-justify box-info text-secondary">A troca da permissão do usuário afeta o funcionamento da 
            plataforma alterando o nível de acesso do usuário. Saiba mais 
            <router-link @click="emitClose" to="/dados/permissao" class="btn-router-link">
                aqui.
            </router-link>
        </p>
        <!------- PRIMEIRO BOTAO ------->
        <!-- BOTAO DESABILITADO -->
        <button v-if="data.permissao == 'ADMINISTRADOR'" class="button-style disabled-style mb-3" >
            <div class="box-img">
                <img src="@assets/img/Dados/ListarMembros/admin-user.svg"  class="turning-grey">
            </div>
            <div class="box-label">
                <h1 class='title-style' v-if="data.permissao == 'ADMINISTRADOR'">USUÁRIO JÁ É UM ADMINISTRADOR</h1> 
            </div>
        </button>

        <!-- BOTAO TORNAR ADMINISTRADOR -->
        <button v-if="userPerm?.permissao == 'ADMINISTRADOR'" class="button-style mb-3" @click="changePermissionToAdm()">
            <div class="box-img">
                <img src="@assets/img/Dados/ListarMembros/admin-user.svg">
            </div>
            <div class="box-label">
                <h1 class='title-style'>TORNAR ADMINISTRADOR</h1> 
            </div>
        </button>


        <!------- SEGUNDO BOTAO ------->
        <!-- BOTAO DESABILITADO -->
        <button v-if="data.permissao == 'COORDENADOR'" class="button-style disabled-style mb-3" >
            <div class="box-img">
                <img src="@assets/img/Dados/ListarMembros/coordenador-user.svg"  class="turning-grey">
            </div>
            <div class="box-label">
                <h1 class='title-style' v-if="data.permissao == 'COORDENADOR'">USUÁRIO JÁ É UM COORDENADOR</h1> 
            </div>
        </button>

        <!-- BOTAO TORNAR COORDENADOR -->
        <button v-else class="button-style mb-3" @click="changePermissionToColaborator()">
            <div class="box-img">
                <img src="@assets/img/Dados/ListarMembros/coordenador-user.svg">
            </div>
            <div class="box-label">
                <h1 class='title-style'>TORNAR COORDENADOR</h1> 
            </div>
        </button>

        <!------- TERCEIRO BOTAO ------->
        <!-- BOTAO DESABILITADO -->
        <button v-if="data.permissao == 'APLICADOR'" class="button-style disabled-style mt-3">
            <div class="box-img">
                <img src="@assets/img/Dados/ListarMembros/aplicador-user-blue.png" class="turning-grey">
            </div>
            <div class="box-label">
                <h1 class='title-style' v-if="data.permissao == 'APLICADOR'">USUÁRIO JÁ É UM APLICADOR</h1> 
            </div>
       </button>

        <!-- BOTAO TORNAR APLICADOR -->
        <button v-else class="button-style" @click="changePermissionToApplicator()">
            <div class="box-img">
                <img src="@assets/img/Dados/ListarMembros/aplicador-user-blue.png">
            </div>
            <div class="box-label">
                <h1 class='title-style'>TORNAR APLICADOR</h1>
            </div>
        </button>

    </div>
</template>

<script>
import Swal from 'sweetalert2';

export default {
    name: "ChangePermission",
    data () {
        return {
            isActiveDropdownMenu: true
        }
    },
    props: {
        data: Object
    },
    computed: {
        userPerm() {
            return this.$store.getters.getUserPerm
        },
    },
    async created() {
        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }
        await this.$store.dispatch('setUserPerm', payload)
    },
    methods: {
        emitClose() { this.$emit('closeModal'); },
        async changePermissionToAdm() {
            const result = await Swal.fire({
                title: 'Deseja mesmo alterar a permissão?',
                text: 'Tem certeza que deseja alterar a permissão de '+ this.data.nome + ' para administrador? Ao fazer isso, você será atualizado para coordendador, pois só pode haver um administrador por instituição.',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
            })
            if (result.isConfirmed) {
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUsuario: this.data.id,
                    idPermissao: "3"
                }

                const payload2 = {
                    idInstituicao: this.$route.params.idInstituicao
                }

                await this.$store.dispatch('setUpdateMember', payload)
                await this.$store.dispatch('setUserPerm', payload2)
                await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)

                Swal.fire(
                    'Atualizado!',
                    this.data.nome + ' agora é um administrador.',
                    'success'
                )
                this.$emit('closeModal')
            }
        },

        async changePermissionToColaborator() {
            const result = await Swal.fire({
                title: 'Deseja mesmo alterar a permissão?',
                text: 'Tem certeza que deseja alterar a permissão de '+ this.data.nome + ' para coordenador?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
            })
            if (result.isConfirmed) {

                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUsuario: this.data.id,
                    idPermissao: "2"
                }

                await this.$store.dispatch('setUpdateMember', payload)
                await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)
                
                Swal.fire(
                    'Atualizado!',
                    this.data.nome + ' agora é um coordenador.',
                    'success'
                )
                this.$emit('closeModal')
            }
        },


        async changePermissionToApplicator() {
            const result = await Swal.fire({
                title: 'Deseja mesmo alterar a permissão?',
                text: 'Tem certeza que deseja alterar a permissão de '+ this.data.nome + ' para aplicador?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
            })
            if (result.isConfirmed) {

                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUsuario: this.data.id,
                    idPermissao: "1"
                }

                await this.$store.dispatch('setUpdateMember', payload)
                await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)

                Swal.fire(
                    'Atualizado!',
                    this.data.nome + ' agora é um aplicador.',
                    'success'
                )

                this.$emit('closeModal')
            }
        },
        closeDropdownMenu: function() {
            this.isActiveDropdownMenu = true
        },
    }
}

</script>

<style scoped>
    
    .button-style{
        background-color: #FCFCFC;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.11);
        box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        min-height: 15vh;
        margin: 0;
        padding: 0.4rem;
        cursor: pointer;
        width: 100%;
        outline: none;
        transition: all ease 0.3s;
    }

    .overlay-visible {
        opacity: 1;
        pointer-events: all;
        cursor: default;
    }

    .popup-menu-visible {
        opacity: 1;
        pointer-events: all;
    }

    .button-style:hover{
        background-color: rgb(235, 235, 235);
    }

    .box-img {
        flex-basis: 30%;
    }

    .box-img img {
        margin: 0.5rem;
    }

    .turning-grey{        
        filter: grayscale(100%);
    }
 
    .box-label {
        flex-basis: 70%;
    }

    .box-info {
        font-weight: 500;
    }

    .box-info a{
        text-decoration: underline;
    }

    .box-info a:hover{
        text-decoration: underline;
        cursor: pointer;
    }

    .disabled-style{
        background-color: #D0D0D0 !important;
        cursor: default;
    }

    .title-style{
        font-size: 1.3rem;
        font-weight: 600;
        color: #606060;
        margin: 0;
    }

</style>
