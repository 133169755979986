<template>

    <div>
        <ModalCentral 
            componentName="ChangePermission"
            title="ALTERAR PERMISSÃO"
            idName="AlterarPermissao"
            :data="dataToChangePermission"
        />

        <ModalCentral 
            componentName="ShowCampoInfo"
            title="DESCRIÇÃO DO CAMPO"
            idName="showCampoInformacaoTwo"
            :data="dataToShowCampoInfo"
        />

        <!-- CADASTRAR INFORMACOES - LISTAR UNIDADES EDUCACIONAIS -->
        <div v-if="listaType == 'addUnidadesEducacionais'">
            <transition-group v-if="infosData?.length > 0" name="slide-fade">
            <div class="box-lista" v-for="(info) in infosData" :key="info.counter">
                <img  class="img-info" src="@assets/img/Dados/ListarUE/icon-ue.svg" alt="">
                <span class="first-info">{{info.nome}}</span>
                <span class="second-info">{{info.cidade}}</span>
                <router-link :to="{name: 'ListarTurmas', params: { idUnidade: info.idue }}" class="btn-router-link">
                    <span class="third-info">Ver UE</span>
                </router-link>
                <!-- <span class="last-info">Excluir Unidade Educacional</span> -->
            </div>
            </transition-group>
            <transition-group v-else>
                <h2 class="title-style">Nenhuma Unidade Educacional encontrada.</h2>
            </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- CADASTRAR INFORMACOES - LISTAR TURMAS -->
        <div v-if="listaType == 'addTurmas'">
            <transition-group v-if="infosData?.length > 0" name="slide-fade">
            <div class="box-lista" v-for="(info) in infosData" :key="info.counter">
                <img  class="img-info" src="@assets/img/Dados/ListarUE/icon-turmas.svg" alt="">
                <span class="first-info">{{info.nome}}</span>
                <span class="second-info">{{info.periodo}}</span>
                <!-- <span class="third-info">Editar Turma</span> -->
                <!-- <span class="last-info">Excluir Turma</span> -->
            </div>
            </transition-group>
            <transition-group v-else>
                <h2 class="title-style">Nenhuma Turma encontrada.</h2>
            </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- GERENCIAR CAMPO - LISTAGEM COM SELEÇÃO DE MEMBROS -->
        <div v-if="listaType == 'selectMembros'">
        <transition-group name="slide-fade">
            <div class="box-lista" v-for="info in infosData" :key="info.counter" :class="{'list-item-selected-pink': info.isSelect.isSelected}">
                <img v-if="info.permissao == 'ADMINISTRADOR'" class="img-info" :class="{'pink-img': info.isSelect.isSelected, 'black-img': !info.isSelect.isSelected}" src="@assets/img/Dados/ListarMembros/admin-user.svg" alt="">
                <img v-else-if="info.permissao == 'COORDENADOR'" class="img-info" :class="{'pink-img': info.isSelect.isSelected, 'black-img': !info.isSelect.isSelected}" src="@assets/img/Dados/ListarMembros/coordenador-user.svg" alt="">
                <img v-else class="img-info" :class="{'pink-img-multiple': info.isSelect.isSelected, 'black-img': !info.isSelect.isSelected}" src="@assets/img/Dados/ListarMembros/aplicador-user-blue.png" alt="">
                <span class="first-info">{{info.nome}} ({{info.email}})</span>
                <span class="second-info">{{info.permissao}}</span>
                <div v-if="((campoStatus != 'OFF' && info.isLocked == false && info.isSelect.isSelected == false && userPerm.idPermissao != '1') && userPerm.idPermissao != '1')" @click="setIsSelectedAplicador(info.idusuario)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="((campoStatus == 'INATIVO' && info.isLocked == false && info.isSelect.isSelected == true && userPerm.idPermissao != '1') && userPerm.idPermissao != '1')" @click="setIsSelectedAplicador(info.idusuario)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="((campoStatus == 'ON' && info.isLocked == false && info.isSelect.isSelected == true && userPerm.idPermissao != '1') && userPerm.idPermissao != '1')" @click="setIsSelectedAplicador(info.idusuario)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="((campoStatus == 'ON' && info.isLocked == true && info.isSelect.isSelected == true && userPerm.idPermissao != '1') && userPerm.idPermissao != '1')" class="select-info-blocked">
                    <!-- <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div> -->
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <!-- <span class="span-label-selected" v-else>Selecionar</span> -->
                </div>
            </div>
        </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- GERENCIAR CAMPO - LISTAGEM COM SELEÇÃO DE FORMULÁRIOS -->
        <div v-if="listaType == 'selectFormularios'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.counter" :class="{'list-item-selected-pink': info.isSelect.isSelected}">
                <img  class="img-info" :class="{'pink-img': info.isSelect.isSelected, 'black-img': !info.isSelect.isSelected}" src="@assets/img/Dados/ListarForms/icon-forms.png" alt="">
                <span class="first-info">{{info.nome}}</span>
                <span class="second-info">{{info.modelo}}</span>
                <div v-if="(campoStatus != 'OFF' && info.isSelect.isSelected == false && userPerm.idPermissao != '1')" @click="setIsSelectedFormulario(info.idformulario)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'INATIVO' && info.isSelect.isSelected == true && userPerm.idPermissao != '1')" @click="setIsSelectedFormulario(info.idformulario)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'ON' && info.isSelect.isSelected == true && info.isSelect.isSaved == false && userPerm.idPermissao != '1')" @click="setIsSelectedFormulario(info.idformulario)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'ON' && info.isSelect.isSelected == true && info.isSelect.isSaved == true && userPerm.idPermissao != '1')" class="select-info-blocked">
                    <!-- <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div> -->
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <!-- <span class="span-label-selected" v-else>Selecionar</span> -->
                </div>
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style">Nenhum formulário encontrado.</h2>
        </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- GERENCIAR CAMPO - LISTAGEM COM SELEÇÃO DE UNIDADES EDUCACIONAIS -->
        <div v-if="listaType == 'selectUnidadesEducacionais'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.counter" :class="{'list-item-selected-pink': info.isSelect.isSelected}">
                <img  class="img-info" :class="{'pink-img': info.isSelect.isSelected, 'black-img': !info.isSelect.isSelected}" src="@assets/img/Dados/ListarUE/icon-ue.svg" alt="">
                <span class="first-info">{{info.nome}}</span>
                <span class="second-info">{{info.cidade}}</span>
                <router-link v-show="info.isSelect.isSelected" :to="{name: 'CampoListaTurmas', params: { idUnidade: info.idue }}" class="btn-router-link">
                    <Button 
                        value="Ver UE"
                        section="instituicao-campo"
                        :isOutline="true"
                    />
                </router-link>
                <div v-if="(campoStatus != 'OFF' && info.isSelect.isSelected == false && userPerm.idPermissao != '1')" @click="setIsSelectedUnidade(info.idue)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'INATIVO' && info.isSelect.isSelected == true && userPerm.idPermissao != '1')" @click="setIsSelectedUnidade(info.idue)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'ON' && info.isSelect.isSelected == true && info.isSelect.isSaved == false && userPerm.idPermissao != '1')" @click="setIsSelectedUnidade(info.idue)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'ON' && info.isSelect.isSelected == true && info.isSelect.isSaved == true && userPerm.idPermissao != '1')" class="select-info-blocked">
                    <!-- <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div> -->
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <!-- <span class="span-label-selected" v-else>Selecionar</span> -->
                </div>
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="educationalUnitKey">Nenhuma Unidade Educacional encontrada.</h2>
        </transition-group>
        </div>
        <!-- -------------------------------------- --> 


        <!-- GERENCIAR CAMPO - LISTAGEM COM SELEÇÃO DE TURMAS -->
        <div v-if="listaType == 'selectTurmas'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.counter" :class="{'list-item-selected-pink': info.isSelect.isSelected}">
                <img  class="img-info" :class="{'pink-img': info.isSelect.isSelected, 'black-img': !info.isSelect.isSelected}" src="@assets/img/Dados/ListarUE/icon-turmas.svg" alt="">
                <span class="first-info">{{info.nome}}</span>
                <span class="second-info">{{info.periodo}}</span>
                <div v-if="(campoStatus != 'OFF' && info.isSelect.isSelected == false && userPerm.idPermissao != '1')" @click="setIsSelectedTurma(info.idturma)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'INATIVO' && info.isSelect.isSelected == true && userPerm.idPermissao != '1')" @click="setIsSelectedTurma(info.idturma)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'ON' && info.isSelect.isSelected == true && info.isSelect.isSaved == false && userPerm.idPermissao != '1')" @click="setIsSelectedTurma(info.idturma)" class="select-info">
                    <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div>
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <span class="span-label-selected" v-else>Selecionar</span>
                </div>
                <div v-else-if="(campoStatus == 'ON' && info.isSelect.isSelected == true && info.isSelect.isSaved == true && userPerm.idPermissao != '1')" class="select-info-blocked">
                    <!-- <div :class="['box-select-info mr-2 ',{'selected-info': info.isSelect.isSelected}]"></div> -->
                    <span class="span-label-selected" v-if="info.isSelect.isSelected">Selecionado</span>
                    <!-- <span class="span-label-selected" v-else>Selecionar</span> -->
                </div>
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="classesKey">Nenhuma Turma encontrada.</h2>
        </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- APLICAÇÕES - LISTAR CAMPOS -->
        <div v-if="listaType == 'aplicacoesCampos'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0"> 
            <div class="box-lista" v-for="info in infosData" :key="info.counter" :class="{'list-item-selected-orange': info.isativo == 'ON'}">
                <img  class="img-info" src="@assets/img/Dados/ListarCampos/field-icon-orange.png" alt="">
                <span class="first-info">{{info.titulo}}<i @click="sendInfosToShowCampoInfo(info.descricao)" data-toggle="modal" data-target="#showCampoInformacaoTwo" class="fa fa-info-circle ml-2"></i></span>
                <span class="second-info">Iniciada em {{info.ano}}</span>
                <span v-if="info.isativo == 'INATIVO'" class="second-info-fixed">Campo Inativo</span>
                <span v-if="info.isativo == 'OFF'" class="second-info-fixed">Campo Encerrado</span>
                <span v-if="info.isativo == 'ON'" class="second-info-fixed orange-text-no-hover">Campo Ativo</span>
                <router-link :to="{name: 'ListarAplicacoes', params: { idCampoAplicacoes: info.idamostra }}" class="btn-router-link">
                    <Button 
                        class="orange-text" 
                        value="Ver Aplicações"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                    />
                </router-link>
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="sampleKey">Nenhum Campo encontrado.</h2>
        </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- APLICAÇÕES - LISTAR APLICAÇÕES -->
        <div v-if="listaType == 'aplicacoesAllAplicacoes'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.counter" :class="{'list-item-selected-orange': info.isAtivo}">
                <img  class="img-info" src="@assets/img/Dados/ListarCampos/icon-aplicacoes.png" alt="">
                <span class="second-info-fixed-small">{{info.aplicador}}</span>
                <span class="second-info-fixed-small">{{info.form_name}}</span>
                <span class="second-info-fixed-small">{{info.unidade}}</span>
                <span class="second-info-fixed-small">{{info.turma}}</span>
                <span class="second-info-fixed-small">{{info.status}}</span>
                <span class="second-info-fixed-small orange-text-no-hover ">Enviado em {{convertUTC(info.data_upload)}}</span>
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="applicationKey">Nenhuma Aplicação encontrada.</h2>
        </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- DEVOLUTIVA - LISTAR DEVOLUTIVAS -->
        <div v-if="listaType == 'listarDevolutivas'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.id" :class="{'list-item-selected-orange': info.isAtivo}">
                <img  class="img-info" src="@assets/img/Dados/ListarDevolutiva/icon-devolutiva.png" alt="">
                <span class="first-info">{{info.cidade}}</span>
                <span class="second-info">Iniciada em {{info.inicio}}</span>
                <span class="second-info">Encerrada em {{info.encerrado}}</span>
                <span class="second-info red-text">Baixar Devolutiva</span>
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="devKey">Nenhuma Devolutiva encontrada.</h2>
        </transition-group>
        </div>
        <!-- -------------------------------------- -->

        <!-- CSV - LISTAR CSVs -->
        <div v-if="listaType == 'listarCSV'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.id" :class="{'list-item-selected-orange': info.isAtivo}">
                <img  class="img-info" src="@assets/img/Dados/ListarCSV/icon-csv.png" alt="">
                <span class="first-info">{{info.cidade}}</span>
                <span class="second-info">Iniciada em {{info.inicio}}</span>
                <span class="second-info">Encerrada em {{info.encerrado}}</span>
                <Button 
                    class="green-text" 
                    value="Baixar CSV"
                    section="instituicao-csv"
                    :isOutline="true"
                />
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="CSVKey">Nenhum CSV encontrado.</h2>
        </transition-group>
        </div>

        <!-- GRUPOS - LISTAR INSTITUICOES -->
        <div v-if="listaType == 'listarInstisGrupo'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.id" :class="{'list-item-selected-orange': info.isAtivo}">
                <img  class="img-info" src="@assets/img/Dados/ListarInstis/img.png" alt="">
                <span class="first-info">{{info.nome}}</span>
                <span class="second-info">Iniciada em {{info.inicio}}</span>
                <span class="second-info">Encerrada em {{info.encerrado}}</span>
                <Button 
                    class="second-info dados-text" 
                    value="Ver Campos"
                    section="instituicao-cadastrar"
                    :isOutline="true"
                />
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="instiKey">Nenhuma instituição encontrada.</h2>
        </transition-group>
        </div>


        <!-- GRUPOS - LISTAR MEMBROS -->
        <div v-if="listaType == 'listarMembrosGrupo'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista boxGrupo" v-for="info in infosData" :key="info.id" :class="{'list-item-selected-orange': info.isAtivo}">
               <img v-if="info.permissao == 'ADMINISTRADOR'" class="img-info" src="@assets/img/Dados/ListarMembros/admin-user.svg" alt="">
                <img v-else-if="info.permissao == 'COORDENADOR'" class="img-info" src="@assets/img/Dados/ListarMembros/coordenador-user.svg" alt="">
                <img v-else class="img-info" src="@assets/img/Dados/ListarMembros/aplicador-user-blue.png" alt="">
                <span class="first-info">{{info.nome}} ({{info.email}})</span>
                <span class="second-info">{{info.permissao}}</span>
                <!-- <span v-if="(Number(userPerm.idPermissao) >= info.idPermissao) && (userPerm.idPermissao != '1') && (userInfo.data.idUsuario != info.idusuario)"  @click="sendInfosToChangePermission(info.idusuario, info.permissao,info.nome)" data-toggle="modal" data-target="#AlterarPermissao" class="third-info">Alterar Permissão</span>
                <span v-if="(Number(userPerm.idPermissao) >= info.idPermissao) && (userPerm.idPermissao != '1') && (userInfo.data.idUsuario != info.idusuario)"  @click="deleteUser(info.idusuario, info.nome)" class="last-info">Desvincular Membro</span> -->
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="memberKey">Nenhum membro encontrado.</h2>
        </transition-group>
        </div>


        <!-- GRUPOS - LISTAR SOLICITACOES -->
        <div v-if="listaType == 'listarSolicitacoesGrupo'">
        <transition-group name="slide-fade" v-if="infosData?.length > 0">
            <div class="box-lista" v-for="info in infosData" :key="info.id" :class="{'list-item-selected-orange': info.isAtivo}">
                <img class="img-info" src="@assets/img/Dados/ListarMembros/coordenador-user.svg" alt="Membro convidado">
                <span class="first-info">{{info?.idUsuario?.nome}}</span>
                <span class="second-info">Enviado em {{new Date(info?.timestampNotificacao).toLocaleDateString('pt-BR')}}</span>
                <span class="second-info">{{info.aceito}}</span>
                <Button
                    @click="cancelGroupNoti(info)"
                    value="Cancelar convite"
                    section="descartar"
                    :isOutline="true"
                />
            </div>
        </transition-group>
        <transition-group v-else>
            <h2 class="title-style" key="requestKey">Nenhuma solicitação encontrada.</h2>
        </transition-group>
        </div>

    </div>
    <!-- -------------------------------------- -->
</template>

<script>
import parsers from '@middleware/parsers'
import Swal from 'sweetalert2';
import ModalCentral from '@components/ModalCentral';
import Button from '@components/Button';

export default {
    name: 'ListagemElement',
    components: {
        ModalCentral,
        Button
    },
    data() {
        return {
            dataToShowCampoInfo: {},
            dataToChangePermission: {},
            infosData: this.infos,
        }
    },
    computed: {
        campoStatus() {
            return this.$store.getters.getAmostraStatusAtiva
        },
        userPerm() {
            return this.$store.getters.getUserPerm
        },
        userInfo() {
            return this.$store.getters.getUserInfo
        }

    },
    props: {
        infos: Array,
        listaType: String
    },
    watch: {
        infos(updated) { this.infosData = updated }
    },
    async created() {
        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }
        await this.$store.dispatch('setUserPerm', payload)
        await this.$store.dispatch('setUserInfo')
    },
    methods: {

        convertUTC(data){
            return parsers.convertUTC(data)
        },
        
        sendInfosToShowCampoInfo: function(descricao) {
            this.dataToShowCampoInfo = {
                descricao: descricao
            }
        },

        //! REFATORAR AS LISTAGENS DE SELECAO PARA IMPLEMENTAR ESA VERIFICACAO 
        //! (SO EXIBIR OS BOTOES DE ADD ALL E REMOVE ALL SE TIVER PELO MENOS UM ISSAVED FALSE)
        // verifyAtLeastOneIsSaved() {
        //     return this.infosData.some((value) => value.isSelect.isSaved === false)
        // },

        //METODO QUE SELECIONA TODOS
        setSelectAllItems() {

            if (this.listaType == 'selectMembros') {
                this.infosData.forEach((value) => {
                    if (!value.isLocked) {
                        value.isSelect.isSelected = true
                        value.isSelect.isSaved = false
                    }
                })
            }
            else {
                this.infosData.forEach((value) => {
                    if (value.isSelect.isSaved == false || value.isSelect.isSelected == false) {
                        value.isSelect.isSelected = true
                        value.isSelect.isSaved = false
                    }
                })
            }
        },

        //METODO QUE DESSELECIONA TODOS
        setUnselectAllItems() {
            if (this.listaType == 'selectMembros') {
                this.infosData.forEach((value) => {
                    if (!value.isLocked) {
                        value.isSelect.isSelected = false
                        value.isSelect.isSaved = false
                    }
                })
            }
            else {
                this.infosData.forEach((value) => {
                    if (value.isSelect.isSaved == false || value.isSelect.isSelected == false) {
                        value.isSelect.isSelected = false
                        value.isSelect.isSaved = false
                    }
                })
            }
        },

        //METODO QUE SETA NO ESTADO TODOS OS MEMBROS SELECIONADOS OU NAO
        setAllSelectedAplicadores() {
            let payload = this.infosData.filter((value) => {
                return value.isSelect.isSelected
            }).map((value) => {
                if (value.isSelect.isSelected) { 
                    return value.idmembroinstituicao
                }
            })
            this.$store.commit('setPayloadManageAplicadores', payload)
        },

        //METODO QUE SETA O VALOR DE ISSELECTED DO MEMBRO
        setIsSelectedAplicador(idusuario){
            this.infosData.forEach((value) => {
                if (value.idusuario == idusuario){
                    value.isSelect.isSelected = !value.isSelect.isSelected
                    value.isSelect.isSaved = false
                }
            })
            this.setAllSelectedAplicadores()
        },

        //METODO QUE SETA NO ESTADO TODOS OS FORMULARIOS SELECIONADOS OU NAO
        setAllSelectedFormularios() {
            let payload = this.infosData.filter((value) => {
                return value.isSelect.isSelected
            }).map((value) => {
                if (value.isSelect.isSelected) { 
                    return value.idformulario
                }
            })
            this.$store.commit('setPayloadManageFormularios', payload)
        },

        //METODO QUE SETA O VALOR DE ISSELECTED DO FORMULARIO
        setIsSelectedFormulario(idformulario){
            this.infosData.forEach((value) => {
                if (value.idformulario == idformulario){
                    value.isSelect.isSelected = !value.isSelect.isSelected
                    value.isSelect.isSaved = false
                }
            })
            this.setAllSelectedFormularios()
        },

        //METODO QUE SETA NO ESTADO TODOS AS UNIDADES SELECIONADAS OU NAO
        setAllSelectedUnidades() {
            let payload = this.infosData.filter((value) => {
                return value.isSelect.isSelected
            }).map((value) => {
                if (value.isSelect.isSelected) { 
                    return value.idue
                }
            })
            this.$store.commit('setPayloadManageUnidades', payload)
        },

        //METODO QUE SETA O VALOR DE ISSELECTED DA UNIDADE
        setIsSelectedUnidade(idue){
            this.infosData.forEach((value) => {
                if (value.idue == idue){
                    value.isSelect.isSelected = !value.isSelect.isSelected
                    value.isSelect.isSaved = false
                }
            })
            this.setAllSelectedUnidades()
        },

        //METODO QUE SETA NO ESTADO TODOA AS TURMAS SELECIONADAS OU NAO
        setAllSelectedTurmas() {
            let payload = this.infosData.filter((value) => {
                return value.isSelect.isSelected
            }).map((value) => {
                if (value.isSelect.isSelected) { 
                    return value.idturma
                }
            })
            this.$store.commit('setPayloadManageTurmas', payload)
        },

        //METODO QUE SETA O VALOR DE ISSELECTED DA TURMA
        setIsSelectedTurma(idturma){
            this.infosData.forEach((value) => {
                if (value.idturma == idturma){
                    value.isSelect.isSelected = !value.isSelect.isSelected
                    value.isSelect.isSaved = false
                }
            })
            this.setAllSelectedTurmas()
        },
    
        //METODO PARA DESVINCULAR MEMBRO
        async deleteUser(idusuario, name) {
            const result = await Swal.fire({
                title: 'Desvincular membro!',
                text: 'Tem certeza que deseja desvincular '+ name + '?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
                })
                
                if (result.isConfirmed) {
                    console.log(idusuario)
                    const payload = {
                        idInstituicao: this.$route.params.idInstituicao,
                        idUsuario: idusuario
                    }
                    await this.$store.dispatch('deleteMember', payload)
                    Swal.fire(
                        'Desvinculado!',
                        name + ' não faz mais parte dessa instituição.',
                        'success'
                    )  
                    await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)
                }
        },

        //METODO PARA ALTERAR PERMISSAO DO MEMBRO
        sendInfosToChangePermission: function(index, permissao, nome) {
            this.dataToChangePermission = {
                id: index,
                permissao: permissao,
                nome: nome,
                modalName: "AlterarPermissao"
            }
        },
        async deleteUE(){
            const payload = {
                idInstituicao: 1,
                idUE: 17
            }            
            await this.$store.dispatch('deleteUE', payload)
        },
        async deleteTurma(){
            const payload = {
                idInstituicao: 1,
                idUE: 1,
                idTurma: 31
            }            
            await this.$store.dispatch('deleteTurma', payload)
        },

        async cancelGroupNoti(info) {
            const swalResult = await Swal.fire({
                title: 'Cancelar convite?',
                text: `Tem certeza que deseja cancelar convite a ${info?.idUsuario?.nome}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                cancelButtonText: 'Cancelar',
                confirmButtonText: 'Sim, tenho certeza'
            })
            if (!swalResult.isConfirmed) return;

            await this.$store.dispatch('deleteGroupNoti', {
                idGrupo: info?.idGrupo?.idGrupo,
                idNoti: info?.idNotificacao,
            })

            if (this.$store.getters.getIsCanceledGroupNoti) {
                Swal.fire(
                    'Convite cancelado!',
                    `O convite feito a ${info?.idUsuario?.nome} foi cancelado com sucesso`,
                    'success'
                )
                this.$store.dispatch('setListGroupRequests', {
                    idGrupo: info?.idGrupo?.idGrupo,
                    filter: "GRUPO_USUARIO",
                })
            } else {
                Swal.fire(
                    'Erro no cancelamento!',
                    `Não foi possível cancelar o convite`,
                    'error'
                )
            }
        },
    }
}
</script>


<style scoped>
.box-lista {
    background-color: #FCFCFC;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.11);
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    min-height: 15vh;
    margin: 1rem;
    padding: 0.4rem;
    transition: all ease-in-out 0.3s;
}

.title-style{
    font-size: 25px;
    color: #656565;
    margin: 0;
    text-align: center;
    font-weight: 500;
}

.third-info {
    color: #00853B;
    cursor: pointer;
    user-select: none;
    font-weight: 500;   
}

.second-info-fixed {
    width: 20%;
    text-align: center;
}

.second-info-fixed-small {
    width: 12%;
    text-align: center;
}

.last-info {
    color: #EB3E3E;
    cursor: pointer;
    user-select: none;
    transition: all ease 0.3s;
    font-weight: 500;
    text-align: right;
}

.last-info:hover {
    color: #8f1c1c;
}

.img-info {
    width: auto;
    max-height: 72px;
    margin: 0.2rem;
}

.pink-img {
    filter: hue-rotate(26deg) brightness(0.9);
    transition: all ease-in-out 0.3s;
}

.pink-img-multiple {
    filter: hue-rotate(27deg) brightness(1.09);
    transition: all ease-in-out 0.3s;
}

.black-img {
    filter: saturate(0);
    transition: all ease-in-out 0.3s;
}

.select-info {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.select-info-blocked {
    display: flex;
    align-items: center;
    cursor: default;
}

.span-label-selected {
    width: 5rem;
    user-select: none;
}

.box-select-info {
    width: 1.2rem;
    height: 1.2rem;
    background-color: transparent;
    border-radius: 4px;
    border: 3px solid #00853b;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.selected-info {
    background-color: #00853b;
    transition: all ease-in-out 0.3s;
}

.list-item-selected-pink {
    background-color: #e4ccfe;
    border: 1.8px solid #00853b;
    transition: all ease-in-out 0.3s;
}

.pink-text {
    color: #4d2b74;
}

.pink-text:hover {
    color: #27133d;
}

.red-text {
    color: #c41818;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.red-text:hover {
    color: #790909;
}

.green-text {
    color: #01b327;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
}

.green-text:hover {
    color: #049e25;
}

.list-item-selected-orange {
    background-color: #FFECD0;
    border: 1.8px solid #FFAC30;
    transition: all ease-in-out 0.3s;
}

.dados-text {
    color: #0d9f9f;
    cursor: pointer;
    font-weight: 600;
    font-size: 1.05rem;
    transition: all ease-in-out 0.3s;
}

.dados-text:hover {
    color: #0b5a5a;
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 680px) {
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .box-lista span, .box-lista a{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .box-lista{
        place-content: center;
    }
}

.slide-fade-enter-active {
    transition: all .2s ease;
}
.slide-fade-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to {
    transform: translateX(-40px);
    opacity: 0;
}

.fa-info-circle {
    color: #ffac30;
    font-size: 1.1rem;
    cursor: pointer;
}

 .boxGrupo {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.png");

     border: 1px solid #952713;
 }

 .boxGrupo span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }
</style>
