<template>
    <div class="container offset">
        <form @submit.prevent="updateUnidade" ref="formUE">
            <div class="custom-form">
                <div class="form-group">
                    <label class="question-style pt-1"
                        >Nome da Unidade Educacional: *</label
                    >
                    <input
                        v-model.trim="UEData.nome"
                        type="name"
                        class="form-control"
                        placeholder="Insira aqui o nome da unidade educacional"
                        required
                    />
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="question-style">Município: *</label>
                        <input
                            v-model.trim="UEData.municipio"
                            type="name"
                            class="form-control"
                            placeholder="Insira o município da unidade"
                            required
                        />
                    </div>

                    <div class="form-group col-md-6">
                        <label class="question-style">Bairro:</label>
                        <input
                            v-model.trim="UEData.bairro"
                            type="name"
                            class="form-control"
                            placeholder="Insira o bairro da unidade"
                        />
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-8">
                        <label class="question-style">Rua:</label>
                        <input
                            v-model.trim="UEData.rua"
                            type="name"
                            class="form-control"
                            placeholder="Insira a rua da unidade"
                        />
                    </div>

                    <div class="form-group col-md-4">
                        <label class="question-style">Número:</label>
                        <input
                            v-model.trim="UEData.numero"
                            type="number"
                            min="0"
                            class="form-control"
                            placeholder="Insira o número da unidade"
                        />
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label class="question-style">Complemento:</label>
                        <input
                            v-model.trim="UEData.complemento"
                            type="name"
                            class="form-control"
                            placeholder="Insira o complemento da unidade"
                        />
                    </div>

                    <div class="form-group col-md-4">
                        <label class="question-style">Ano de início:</label>
                        <input
                            v-model.trim="UEData.anoInicioAtividades"
                            type="number"
                            min="1900"
                            :max="new Date().getFullYear()"
                            class="form-control"
                            placeholder="Insira o ano de início das atividades"
                            @blur="formatYear"
                        />
                    </div>

                    <div class="form-group col-md-4">
                        <label class="question-style">Código INEP:</label>
                        <input
                            v-model.trim="UEData.inep"
                            type="text"
                            pattern="[0-9]{8}"
                            maxlength="8"
                            class="form-control"
                            placeholder="Insira o códgio INEP da unidade educacional"
                        />
                    </div>
                </div>

                <div class="d-flex">
                    <div
                        class="d-flex flex-column align-items-start macroarea-wrapper"
                    >
                        <label class="question-style pt-1">Macroárea: *</label>
                        <input
                            class="form-control"
                            type="text"
                            v-model.trim="UEData.macroarea"
                            list="macroareas"
                            placeholder="Selecione ou digite uma macroárea..."
                        />
                        <datalist id="macroareas" required>
                            <!-- <option hidden class="question-style">Selecione aqui a Macroárea:</option> -->
                            <option
                                v-for="mac in macroareas"
                                :key="mac.counter"
                                :value="mac.macroarea"
                            >
                                {{ mac.macroarea }}
                            </option>
                        </datalist>
                    </div>
                    <!-- <div class="button-add-macroarea-wrapper">
                         <i class="add-macroarea fas fa-plus"></i>
                         </div> -->
                </div>

                <label class="question-style pt-2">Modalidade: *</label>
                <select
                    class="form-control"
                    v-model.trim="UEData.tipoModalidade"
                    required
                >
                    <option hidden class="question-style">
                        Selecione aqui o tipo da unidade educacional:
                    </option>
                    <option class="question-style" value="15">Pública</option>
                    <option class="question-style" value="16">Privada</option>
                    <option class="question-style" value="17">
                        Filantrópica
                    </option>
                    <option class="question-style" value="18">
                        Parceira/Conveniada
                    </option>
                </select>

                <label class="question-style pt-2">Localização: *</label>
                <select
                    class="form-control"
                    v-model.trim="UEData.tipoLocalizacao"
                    required
                >
                    <option hidden class="question-style">
                        Selecione aqui a localização da unidade educacional:
                    </option>
                    <option class="question-style" value="9">Rural</option>
                    <option class="question-style" value="10">Indígena</option>
                    <option class="question-style" value="11">Urbana</option>
                    <option class="question-style" value="12">
                        Assentamento
                    </option>
                    <option class="question-style" value="13">
                        Área remanescente de quilombos
                    </option>
                    <option class="question-style" value="14">
                        Unidade de uso sustentável
                    </option>
                </select>
            </div>

            <div class="formButtons">
                <!-- <div class="button-div pt-4">
                     <Button
                     value="Limpar"
                     section="descartar"
                     :isOutline="true"
                     :hasIcon="true"
                     iconName="trash"
                     @click="clearForm" />
                     </div>
                -->
                <div class="button-div pt-4">
                    <Button
                        section="instituicao-cadastrar"
                        value="Atualizar UE"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="save"
                    />
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Button from "@components/Button";
// import Swal from "sweetalert2";
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
    name: "AddEducationalUnit",
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            UEData: {
                nome: "",
                bairro: "",
                macroarea: "",
                inep: "",
                tipoLocalizacao: "",
                tipoModalidade: "",
                anoInicioAtividades: "",
                municipio: "",
                rua: "",
                numero: "",
                complemento: "",
            },
        };
    },
    watch: {
        data() {
            this.loadInfo();
        },
        ues() {
            this.loadInfo();
        },
    },
    computed: {
        macroareas() {
            return this.$store.getters.getListMacroareas;
        },
        ues() {
            return this.$store.getters.getListUE || [];
        },
    },
    created() {
        this.$store.dispatch(
            "setListMacroareas",
            this.$route.params.idInstituicao
        );
        this.$store.dispatch("setListUE", {
            idInstituicao: this.$route.params.idInstituicao,
        });
    },
    methods: {
        formatYear(event) {
            if (this.UEData.anoInicioAtividades != "") {
                let inputValue = parseInt(event.target.value);

                const currentYear = new Date().getFullYear();
                if (isNaN(inputValue)) {
                    inputValue = currentYear;
                } else {
                    // [1900, currentYear]
                    inputValue = Math.max(
                        1900,
                        Math.min(currentYear, inputValue)
                    );
                }

                this.UEData.anoInicioAtividades = inputValue;
            }
        },

        async loadInfo() {
            const found =
                this.ues.find((ue) => ue.idue === this.data.idue) || {};
            this.UEData = {
                ...found,
                idUe: found?.idue,
                tipoLocalizacao: found?.tipolocalizacao,
                tipoModalidade: found?.tipomodalidade,
                anoInicioAtividades: found?.anoinicioatividades,
            };
        },
        async updateUnidade() {
            let foundUe =
                this.ues.find((ue) => ue.idue === this.data.idue) || {};
            let ueAlreadyExists = this.ues.some(
                (item) =>
                    (item.nome.toLowerCase() ===
                        this.UEData.nome.toLowerCase() &&
                        item.municipio.toLowerCase() ===
                            this.UEData.municipio.toLowerCase() &&
                        (item.nome.toLowerCase() !=
                            foundUe.nome.toLowerCase() ||
                            item.municipio.toLowerCase() !=
                                foundUe.municipio.toLowerCase())) ||
                    (item.inep != null &&
                        item.inep === this.UEData.inep &&
                        item.inep != foundUe.inep)
            );

            // se nao houver unidades ou se as informações não existirem em outra ue
            if (this.ues.length == 0 || !ueAlreadyExists) {
                if (this.UEData.inep == "") {
                    this.UEData.inep = null;
                }

                await this.$store.dispatch("updateUE", {
                    idInstituicao: this.$route.params.idInstituicao,
                    infoUE: this.UEData,
                });

                this.$emit("closeModal");

                if (this.$store.getters.getIsUEUpdated) {
                    notyf.open({
                        type: "success",
                        message: "Unidade atualizada com sucesso!",
                    });
                    this.$store.dispatch("setListUE", {
                        idInstituicao: this.$route.params.idInstituicao,
                    });
                } else {
                    notyf.open({
                        type: "error",
                        message: "Erro na atualização da unidade!",
                    });
                }
            } else {
                //se as informações já existirem em outra ue
                notyf.open({
                    type: "error",
                    message:
                        "Já existe uma unidade criada com estas informações, verifique os campos e tente novamente.",
                });
            }
        },
        clearForm() {
            this.loadInfo();
        },
    },
    components: {
        Button,
    },
};
</script>

<style scoped>
.container {
    width: 100%;
    background-color: white;
}

.title-style {
    font-size: 1.5rem;
    font-weight: 600;
    color: #656565;
}

.question-style {
    width: 100%;
    font-size: 1rem;
    color: #656565;
}

.macroarea-wrapper {
    width: 100%;
}

.button-add-macroarea-wrapper {
    position: relative;
    width: 10%;
}

.add-macroarea {
    position: absolute;
    right: 20%;
    bottom: 15%;
    font-size: 1.4rem;
    color: #00853b;
    cursor: pointer;
    transition: all ease 0.3s;
}

.add-macroarea:hover {
    color: #3e4194;
}

.image-style {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    padding: 1rem;
}

.button-div {
    text-align: center;
}

.formMsg {
    margin-top: 5px;
}

.formSuccess {
    color: green;
}

.formError {
    display: none;
}

.formButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-content: space-around;
}
</style>
