<template>
    <!-- <VideoModal
         v-if="isVideo"
         :videoLink="link"
         :id="`modal${index}`"
         />
         <PDFModal
         v-else
         :pdfLink="handledPdfLink"
         :id="`modal${index}`"
         /> -->

    <ModalCentral
        v-if="isVideo"
        componentName="EditVideo"
        title="EDITAR VÍDEO"
        :idName="`edit${index}`"
        :data="{ idModal: index }"
    />
    <ModalCentral
        v-else
        componentName="EditPDF"
        title="EDITAR PDF"
        :idName="`edit${index}`"
        :data="{ idModal: index }"
    />

    <div
        class="itemContainer"
        :class="{ 'right': listIdx % 2, 'left': !(listIdx % 2) }" >
        <div class="thumb">
            <img v-if="isVideo" :src="thumb" alt="Imagem do material" />

            <object
                class="pdfContainer"
                v-else-if="trulyPdf"
                :data="handledPdfLink"
                type="application/pdf"
                width="100%" height="100%" fameborder="0"
            >
                <p class="pdf-indisponivel">
                    <i class="fas fa-file-pdf"></i>
                </p>
            </object>

            <p v-else class="pdf-indisponivel">
                <i class="fas fa-file-alt"></i>
            </p>
        </div>
        <div class="title">
            <a :href="link" target="_blank">
                <Button
                    data-toggle="modal"
                    :data-target="`#modal${index}`"
                    :value="`${listIdx}. ${ title }`"
                    class="cardBut resourceBut"
                    :isOutline="false" />
            </a>

            <i
                data-toggle="modal"
                :data-target="`#edit${index}`"
                v-if="userPerm"
                class="fas fa-cog icon"
            />

            <i
                v-if="userPerm"
                class="fas fa-trash icon"
                @click="deleteMaterial"
            />
        </div>
    </div>
</template>

<script type="text/javascript">
 import Button from '@components/Button';
 // import PDFModal from '@components/Forms/PDFModal';
 // import VideoModal from '@components/Forms/VideoModal';
 import ModalCentral from '@components/ModalCentral';
 import Swal from 'sweetalert2';

 export default {
     name: "MaterialListItem",
     components: {
         Button,
         // PDFModal,
         // VideoModal,
         ModalCentral,
     },
     props: {
         listIdx: {
             type: Number,
             required: true,
         },
         index: {
             type: Number,
             required: true,
         },
         title: {
             type: String,
             required: true,
         },
         link: String,
         isVideo: {
             type: Boolean,
             required: true,
         }
     },
     methods: {
         clipVideoId(raw) {
             if (!raw) return "";
             let url = raw.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
             return (url[2] !== undefined) ? url[2].split(/[^0-9a-z_-]/i)[0] : url[0];
         },
         async deleteMaterial() {
             const result = await Swal.fire({
                 title: 'Deseja mesmo excluir o material?',
                 text: 'Tem certeza que deseja excluir '+ this.title + '',
                 icon: 'warning',
                 showCancelButton: true,
                 confirmButtonColor: '#d33',
                 cancelButtonColor: '#3085d6',
                 cancelButtonText: 'Cancelar',
                 confirmButtonText: 'Sim, tenho certeza'
             });
             if (!result) return;

             await this.$store.dispatch('deleteMaterial', {
                 idModal: this.index,
             });

             if (this.$store.getters.getMaterialDeleted) {
                Swal.fire(
                    'Material excluído!',
                    `Material ${this.title} excluído com sucesso!`,
                    'success'
                );

                this.$store.dispatch('setListModais', {
                    categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
                });
             } else
                Swal.fire(
                    'Erro na exclusão!',
                    `Erro ao excluir o material ${this.title}.`,
                    'error'
                );
         },
     },
     computed: {
         thumb() {
             if (this.isVideo)
                 return `https://i.ytimg.com/vi/${this.clipVideoId(this.link)}/maxresdefault.jpg`;
             else
                 return require('../../assets/img/MateriaiseDocumentos/Avaliacao/escola.jpeg');
         },
         handledPdfLink() {
             return `${this.link}#toolbar=0&navpanes=0&scrollbar=0`
         },
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
         trulyPdf() {
             return this.link.includes(".pdf");
         }
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .itemContainer {
     display: grid;
     grid-template-rows: auto;
     width: 100%;
     margin-bottom: 1em;
     align-items: center;
 }

 .right {
     grid-template-columns: 4fr minmax(250px, 1fr);
     grid-template-areas: 'title image';
 }

 .left {
     grid-template-columns: minmax(250px, 1fr) 4fr;
     grid-template-areas: 'image title';
 }

 .thumb {
     grid-area: image;
     padding: 15px;
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 1em;
 }

 .thumb > img {
     width: 100%;
 }

 .thumb > img, .thumb > object {
     border-radius: 1em;
     margin: 0;
     height: auto;
 }

 .right .thumb {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.png");
 }

 .left .thumb {
     background-image: url("~@/assets/img/Backgrounds/vermelhoEapi.png");
 }

 .title {
     grid-area: title;
     background-repeat: repeat;
     background-size: 256px;
     width: 100%;
     padding: 2em;
     height: min-content;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);

     display: grid;
     grid-template-columns: 1fr auto auto;
     grid-template-rows: auto;
     align-items: center;
 }

 .left .title {
     border-radius: 0 1em 1em 0;
     background-image: url("~@/assets/img/Backgrounds/vermelhoClaro.png");
 }

 .right .title {
     border-radius: 1em 0 0 1em;
     background-image: url("~@/assets/img/Backgrounds/roxo.png");
 }

 .cardBut {
     border-radius: 50px;
     width: 100%;
     box-sizing: border-box;
     height: max-content;
     color: white;
 }

 .left .cardBut {
     background-color: #B1514E;
 }

 .right .cardBut {
     background-color: #5C5285;
 }

 .pdf-indisponivel {
     width: 100%;
     height: 100%;
     display: flex;
     align-content: center;
     justify-content: center;
     align-items: center;
     text-align: center;
     min-height: 6.3rem;
     margin: 0;
     padding: 0;
 }

 .pdf-indisponivel > i {
     font-size: 3em;
 }

 .icon {
     margin: 10px;
     line-height: auto;
     font-size: 1.3em;
     cursor: pointer;
     transition: 0.4s;
 }

 .icon:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }

 .pdfContainer {
     margin: 0;
     padding: 0;
 }
</style>
