<template>
    <ModalCentral
        componentName="UpdateTurma"
        title="Atualizar turma"
        idName="UpdateTurma"
        :data="{
            idturma: editing,
        }"
    />

    <div class="root-dados">
        <div class="container list-data">

            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br />

            <div class="title-row" style="justify-content: flex-start; gap: 10px;">
                <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todas as Turmas pertencentes à Unidade Educacional. Caso necessário, utilize o filtro abaixo para localizar a Turma desejada.</p>

            <template v-if="userPerm.permissao != 'APLICADOR'">
                <div class="lista-data-info">
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="setSelectAllItems"
                        class="btn btnCtrl greenGradient"
                        section="instituicao-campo"
                        value="Marcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check-square"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="setUnselectAllItems"
                        class="btn ml-2 btnCtrl greenGradient"
                        section="instituicao-campo"
                        value="Desmarcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="square"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="applyChanges"
                        class="btn ml-2 btnCtrl greenGradient"
                        section="instituicao-campo"
                        value="Aplicar Alterações"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                    />
                </div>
                <br />
            </template>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar turmas por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS TURMAS DOS CAMPOS --------------------------------------------->

            <div v-if="isLoadedInfo">

                <transition-group v-if="turmas_filtered?.length > 0" name="slide-fade">
                    <div class = "table-wrap">
                    <table class="table mt-3">
                        <thead>
                            <tr>
                                <th scope="col">  </th>
                                <th scope="col">Nome</th>
                                <th scope="col">Período</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table-row" v-for="turma in turmas_filtered" :key="turma.counter">

                                <td> <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-turmas.svg" alt=""> </td>

                                <td> {{turma.nome}} </td>

                                <td> {{turma.periodo}} </td>

                                <td>
                                    <div v-if="userPerm.idPermissao != '1'" class="controlGroup">
                                        <span v-if="turma.isLocked">Selecionado</span>
                                        <div v-else>
                                            <div v-if="['ON', 'INATIVO'].includes(campoStatus)" @click="setIsSelected(turma.idturma)" class="select-info">
                                                <div :class="['box-select-info mr-2 ',{'selected-info': turma.isSelect.isSelected}]"></div>
                                                <span class="span-label-selected" v-if="turma.isSelect.isSelected">Selecionado</span>
                                                <span class="span-label-selected" v-else>Selecionar</span>
                                            </div>
                                            <div v-else class="select-info-blocked">
                                                <span class="span-label-selected" v-if="turma.isSelect.isSelected">Selecionado</span>
                                            </div>
                                        </div>
                                        <router-link :to="{ name: 'CampoListaEstudantes', params: { idTurma: turma.idturma } }">
                                            <Button
                                                class="greenGradient clickable"
                                                section="instituicao-campo"
                                                value="Gerenciar estudantes"
                                                :isOutline="true"
                                                :hasIcon="true"
                                                iconName="clipboard"
                                            />
                                        </router-link>

                                        <Button
                                            v-if="!turma.isEditionLocked && campoStatus != 'OFF'"
                                            class="greenGradient clickable"
                                            section="instituicao-campo"
                                            value="Editar turma"
                                            :isOutline="true"
                                            :hasIcon="true"
                                            iconName="pen"
                                            @click="editing = turma.idturma"
                                            data-toggle="modal"
                                            data-target="#UpdateTurma"
                                        />

                                        <Button
                                            v-if="!turma.isDeletionLocked && campoStatus != 'OFF'"
                                            class="greenGradient clickable"
                                            section="instituicao-campo"
                                            value="Excluir turma"
                                            :isOutline="true"
                                            :hasIcon="true"
                                            iconName="trash"
                                            @click="deleteTurma(turma.idturma)"
                                        />
                                    </div>
                                    <div v-else>
                                        -
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style-box-lista" key="classesKey">Nenhuma Turma encontrada.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>
        </div>
    </div>

</template>

<script>
import Button from '@components/Button'
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import Amostra from '@middleware/amostra.js'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import ModalCentral from '@components/ModalCentral'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
    name: 'CampoListaFormularios',
    data (){
        return {
            editing: null,
            nomeCampo: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            searchByCode: '',
            searchWithOrder: '',
            isLoadedInfo: false,
            teste: [],
            turmas_amostra: [],
            turmas: [],
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome'
                }
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Campos',
                    iconName: 'th-large',
                    name_route: 'ListarCampos'
                },
                {
                    id:4,
                    value: 'Gerenciar Campo',
                    iconName: 'chart-pie',
                    name_route: 'InstituicaoMenuCampo'
                },
                {
                    id:5,
                    value: 'Unidades Educacionais',
                    iconName: 'graduation-cap',
                    name_route: 'CampoListaUnidadesEducacionais'
                },
                {
                    id:6,
                    value: 'Turmas',
                    iconName: 'graduation-cap',
                    name_route: ''
                }
            ]
        }
    },
    watch: {
        rawListTurma() { this.setData() },
        rawListCampoTurmas() { this.setData() },
    },
    computed: {
        campoStatus() {
            return this.$store.getters.getAmostraStatusAtiva
        },
        userPerm() {
            return this.$store.getters.getUserPerm
        },
        turmas_filtered() {
            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = this.turmas || [];

            //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
            if (this.searchByName.trim() !== '') {
                var filtered = this.turmas.filter(turma =>turma.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                if(filtered?.length != 0){
                    query = filtered
                }

                else{
                    query = false
                }
            }
            return query;

        },
        rawListTurma() { return this.$store.getters.getListTurma },
        rawListCampoTurmas() { return this.$store.getters.getListCampoTurmas },
    },
    async created() {
        this.isLoadedInfo = false

        this.campos = this.$store.getters.getListUE

        this.loadData()

        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }
        await this.$store.dispatch('setUserPerm', payload)
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

        const all_campos = this.$store.getters.getListCampos
        const campo = all_campos.filter((value) => {
            return value.idamostra == this.$route.params.idCampo
        }).map((value) => {return value.isativo})

        const campoStatus = campo[0]

        this.$store.commit('setAmostraStatusAtiva', campoStatus)
    },
    components: {
        Button,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
        ModalCentral,
    },
    methods: {
      async deleteTurma(idturma) {
        const result = await Swal.fire({
          title: "Tem certeza que deseja excluir essa Turma?",
          text: "Essa ação não poderá ser revertida.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim",
        });

        if (!result.isConfirmed) return;

        await this.$store.dispatch("deleteTurma", {
          idInstituicao: this.$route.params.idInstituicao,
          idUE: this.$route.params.idUnidade,
          idTurma: idturma,
        });

        if (!this.$store.getters.getIsTurmaDeleted) {
          notyf.open({
            type: "error",
            message: "Erro ao excluir!",
          });
          return;
        }

        notyf.open({
          type: "success",
          message: "Turma excluída com sucesso",
        });
        this.loadData();
      },
        setIsSelected(id) {
            this.turmas = Amostra.setIsSelectedItem(this.turmas, 'turma', id)
        },
        setSelectAllItems() {
            this.turmas = Amostra.setSelectAllItems(this.turmas, 'selectTurmas')
 
            Amostra.setAllSelectedItems(this.turmas, 'turma')
        },
        setUnselectAllItems() {
            this.turmas = Amostra.setUnselectAllItems(this.turmas, 'selectTurmas')
            
            Amostra.setAllSelectedItems(this.turmas, 'turma')
        },
        setData() {
            this.turmas = this.rawListTurma || []
            this.turmas_amostra = this.rawListCampoTurmas || []

            this.turmas.forEach(turma => {
                turma.isLocked = false
                this.turmas_amostra.forEach(turma_amostra => {
                    if (turma_amostra.idturma == turma.idturma) {
                        turma.isLocked = turma_amostra.isLocked
                    }
                })
                turma.isSelect = {
                    isSelected: this.turmas_amostra.some(turma_amostra => turma_amostra.idturma == turma.idturma),
                    isSaved: true
                }
            });

            this.isLoadedInfo = true
        },
        async loadData() {
            const payload = {
             idInstituicao: this.$route.params.idInstituicao,
             idAmostra: this.$route.params.idCampo,
             idUnidade: this.$route.params.idUnidade
            }
            const payload2 = {
             idInstituicao: this.$route.params.idInstituicao,
             idUnidade: this.$route.params.idUnidade
            }

            await this.$store.dispatch('setListCampoTurmas', payload)
            await this.$store.dispatch('setListTurmas', payload2)
        },
        async applyChanges() {
            if (this.$store.getters.getAmostraStatusAtiva == 'ON') {
                const result = await Swal.fire({
                    title: "Tem certeza que deseja aplicar as alterações?",
                    text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Sim, tenho certeza",
                })
                
                if (result.isConfirmed) {
                    Amostra.setAllSelectedItems(this.turmas, 'turma')
                    Amostra.setAllUnselectedItems(this.turmas, 'turma')
                    const payloadManageTurmas = this.$store.getters.getPayloadManageTurmas;
                    const payloadUnselectedTurmas = this.$store.getters.getPayloadUnselectedTurmas;
                    const payload = {
                        idInstituicao: this.$route.params.idInstituicao,
                        idUnidade: this.$route.params.idUnidade,
                        idAmostra: this.$route.params.idCampo,
                        addIds: JSON.parse(JSON.stringify(payloadManageTurmas)),
                        removeIds: JSON.parse(JSON.stringify(payloadUnselectedTurmas)),
                    }
                    await this.$store.dispatch('setManageTurmas', payload)
                    
                    this.loadData()
                    Swal.fire(
                        'Alterações aplicadas!',
                        'As seleções foram aplicadas com sucesso.',
                        'success'
                    )
                }
   
            } else {
                Amostra.setAllSelectedItems(this.turmas, 'turma')
                Amostra.setAllUnselectedItems(this.turmas, 'turma')
                const payloadManageTurmas = this.$store.getters.getPayloadManageTurmas;
                const payloadUnselectedTurmas = this.$store.getters.getPayloadUnselectedTurmas;
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUnidade: this.$route.params.idUnidade,
                    idAmostra: this.$route.params.idCampo,
                    addIds: JSON.parse(JSON.stringify(payloadManageTurmas)),
                    removeIds: JSON.parse(JSON.stringify(payloadUnselectedTurmas)),
                }
                await this.$store.dispatch('setManageTurmas', payload)
                
                this.loadData()
                Swal.fire(
                    'Alterações aplicadas!',
                    'As seleções foram aplicadas com sucesso.',
                    'success'
                )
            }
        },
        setSelectedValue() {
            this.selectedValue = this.$refs.filtersearchname.value
        },

    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idUnidade

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Unidade"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.campos.filter(team => 
                team.idue == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeCampo = teamSelected.nome
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>
 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     color: #fcfcfc;
 }
.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.list-box {
    margin: 2rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree {
    margin: 1rem 3rem;
    font-weight: bold;
    display: flex;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: flex-end;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
    }
}

 .active-img {
     filter: brightness(0) invert(1) contrast(0.9);
 }
    
</style>
