import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
  return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
};

const state = () => ({
  listEgressos: [],
  isEgressosUploaded: null,
  isEgressosCreated: null,
  egressoCreationError: null,
  isEgressosUpdated: null,
  egressoUpdateError: null,
  isEgressosRemoved: null,
});


const mutations = {
  setListEgressos(state, list) { state.listEgressos = list; },
  setIsEgressosUploaded(state, v) { state.isEgressosUploaded = v; },
  setIsEgressosCreated(state, v) { state.isEgressosCreated = v; },
  setCreationError(state,v){state.egressoCreationError = v},
  setIsEgressosUpdated(state, v) { state.isEgressosUpdated = v; },
  setUpdateError(state,v){state.egressoUpdateError = v},
  setIsEgressosRemoved(state, v) { state.isEgressosRemoved = v; },
};

const actions = {
  async loadListEgressos({ commit }, payload) {
    commit('setListEgressos', []);
    await axios
      .get(`egresso/${payload.idInstituicao}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        commit('setListEgressos', response.data?.data);
      })
      .catch((err) => {
        console.log(err);
        commit('setListEgressos', null);
      });
  },

  async uploadEgressos({ commit }, payload) {
    commit('setIsEgressosUploaded', null);

    let formData = new FormData();
    formData.append("idInstituicao", payload.idInstituicao);
    formData.append("file", payload.file);
    if(payload.idAmostra){
      formData.append("idAmostra", payload.idAmostra)
    }
    
    await axios
      .post(
        `egresso/uploadEgresso/${payload.idInstituicao}`,
        formData,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        }
      ).then((response) => {
        if (response.data.status == "created")
          commit('setIsEgressosUploaded', { success: true, message: "" })
        else
          commit('setIsEgressosUploaded', { success: false, message: response.data.message })
      }).catch((err) => {
        console.log(err);
        commit('setIsEgressosUploaded', { success: false, message: "Houve algum problema com o seu arquivo CSV. Por favor, revise as informações." })
      });
  },

  async createEgresso({ commit }, payload) {
    commit('setIsEgressosCreated', null);

    await axios
      .post(
        `egresso/${payload.idInstituicao}`,
        payload.egresso,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        }
      ).then(() => {
        commit('setIsEgressosCreated', true);
        commit('setCreationError', null)
      }).catch((err) => {
        commit('setCreationError', err.response.data.code)
        commit('setIsEgressosCreated', false);
      });
  },

  async updateEgresso({ commit }, payload) {
    commit('setIsEgressosUpdated', null);

    await axios
      .put(
        `egresso/${payload.idInstituicao}/${payload.idEgresso}`,
        payload.egresso,
        {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        }
      ).then(() => {
        commit('setIsEgressosUpdated', true);
        commit('setUpdateError', null);
      }).catch((err) => {
        commit('setUpdateError', err.response.data.code);
        commit('setIsEgressosUpdated', false);
      });
  },

  async removeEgresso({ commit }, payload) {
    commit('setIsEgressosRemoved', null);

    await axios
      .delete(
        `egresso/${payload.idInstituicao}/${payload.idEgresso}`, {
          headers: {
            'Authorization': `Bearer ${JWT()}`,
          },
        }
      ).then(() => {
        commit('setIsEgressosRemoved', true);
      }).catch((err) => {
        console.log(err);
        commit('setIsEgressosRemoved', false);
      });
  },
};

const getters = {
  getListEgressos: (state) => state?.listEgressos,
  getIsEgressosUploaded: (state) => state?.isEgressosUploaded,
  getIsEgressosCreated: (state) => state?.isEgressosCreated,
  getEgressoCreationError: (state) => state?.egressoCreationError,
  getIsEgressosUpdated: (state) => state?.isEgressosUpdated,
  getEgressoUpdateError: (state) => state?.egressoUpdateError,
  getIsEgressosRemoved: (state) => state?.isEgressosRemoved,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
