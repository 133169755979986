<!--
|---------------------------------------------
| Button Component 
|---------------------------------------------
| @usage Import the component and use <Button value='Button Text' section='Section Name'/>
| Sections main colors: 
|   main => #18A0FB
|   boas_praticas => #41B3A3
|   materiais_documentos => #EB7071
|   certificacao => #F498C0
|   dados =>  #0d9f9f;
|
|   outline: dados #0d9f9f w/ border
-->

<template>
    <button v-if="!isOutline && !isDisabled" :class="'button grow ' + section">
        {{ value }}
    </button>
    <button
        v-else-if="!isOutline && isDisabled"
        :class="'button grow ' + section"
        disabled
    >
        {{ value }}
    </button>
    <button
        v-else
        :class="['button-outline ' + section, { 'shadow-button': hasShadow }]"
        :disabled="isDisabled"
    >
        <div v-if="!isReverse" class="d-flex align-items-center gap-2">
            <i
                v-if="hasIcon && iconName!= ''"
                :class="'fas fa-' + iconName"
                :style="'font-size:' + iconSize + ';'"
            ></i>
            <component :is="hasSavedIcon" v-if="hasSavedIcon"></component>
            <span class="m-0" v-if="value">{{ value }}</span>
        </div>

        <div v-else class="d-flex align-items-center gap-2">
            <span class="m-0" v-if="value">{{ value }}</span>
            <i
                v-if="hasIcon && iconName!= ''"
                :class="'fas fa-' + iconName"
                :style="'font-size:' + iconSize + ';'"
            ></i>
            <component :is="hasSavedIcon" v-if="hasSavedIcon"></component>
        </div>
    </button>
</template>

<script>
import IconContexto from './Icons/IconContexto.vue';
import IconFatores from './Icons/IconFatores.vue';
import IconPerguntas from './Icons/IconPerguntas.vue';
export default {
    name: "Button",
    components: {
        IconContexto,
        IconFatores,
        IconPerguntas,
    },
    props: {
        section: String,
        value: String,
        isOutline: Boolean,
        hasIcon: Boolean,
        hasShadow: Boolean,
        iconName: String,
        isReverse: Boolean,
        isDisabled: Boolean,
        iconSize: String,
        hasSavedIcon: String,
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables.scss";
/* BOTAO OUTLINE */

.button-outline {
    border-radius: 4px;
    padding: 0.4rem 0.8rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: all ease-in-out 0.2s;
}

.shadow-button {
    -webkit-box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 10px 10px 5px -1px rgba(0, 0, 0, 0.15);
    box-shadow: 6px 6px 5px -1px rgba(0, 0, 0, 0.15);
}

/* PADRAO DE CORES PARA BOTOES OUTLINE */
.instituicao-cadastrar,
.instituicao-campo,
.instituicao-aplicacoes,
.instituicao-csv {
    border: 1px solid #00853b;
    color: #00853b;
    background-color: white;
}

.new-contexto-grey {
    border: 1px solid #6b6b6b;
    color: white;
    background-color: #6b6b6b;
}

.new-contexto-grey:hover {
    color: #6b6b6b;
    background-color: white;
}

.instituicao-cadastrar:hover,
.instituicao-campo:hover,
.instituicao-aplicacoes:hover,
.instituicao-csv:hover {
    color: white;
    background-color: #00853b;
}

.materiais-documentos-outline {
    border: 1px solid #eb7071;
    color: #eb7071;
    background-color: white;
}

.materiais-documentos-outline:hover {
    color: white;
    background-color: #eb7071;
}

.painel-outline {
    border: 1px solid $verdeEscuro-eapi;
    color: $verdeEscuro-eapi;
    background-color: white;
}

.painel-outline:hover {
    color: white;
    background-color: $verdeEscuro-eapi;
}

.descartar {
    border: 1px solid #d9534f;
    color: #d9534f;
    background-color: white;
}

.descartar:hover {
    color: white;
    background-color: #d9534f;
}

.salvar {
    border: 1px solid #00853b;
    color: #00853b;
    background-color: white;
}

.salvar:hover {
    color: white;
    background-color: #00853b;
}

/* ----------------- BOTAO NORMAL ----------------- */

.button {
    color: white;
    max-height: 2.5rem;
    width: auto;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
    padding: 0.6rem 0.6rem 0.6rem 0.6rem;
    outline: none;
    border: none;
    cursor: pointer;
}

.main {
    border-radius: 6px;
    background-color: #00853b;
    box-shadow: -1px 2px 10px 2px rgba(24, 160, 251, 0.3);
    transition: all ease-in-out 0.26s;
}

.main:hover {
    background-color: #00b852;
    box-shadow: -1px 2px 10px 2px rgba(61, 138, 189, 0.384);
}

.main-confirm {
    border-radius: 6px;
    background-color: rgb(137, 197, 87);
    box-shadow: -1px 2px 10px 2px rgba(137, 197, 87, 0.3);
    transition: all ease-in-out 0.26s;
}

.main-confirm:hover {
    background-color: rgb(103, 150, 64);
    box-shadow: -1px 2px 10px 2px rgba(139, 199, 90, 0.384);
}

.boas-praticas {
    border-radius: 20px;
    background-color: white;
    color: rgb(139, 199, 90);
    /* color: $verdeClaro-eapi; */
    transition: all ease-in-out 0.26s;
}

.boas-praticas:hover {
    /* background-color: #6C7E63; */
    color: white;
    background-color: rgb(139, 199, 90);
}

.materiais-documentos {
    border-radius: 20px;
    background-color: #74c5f6;
    /* background-color: #eb7071; */
    transition: all ease-in-out 0.26s;
}

.materiais-documentos:hover {
    /* background-color: #ac4242; */
    background-color: white;
    color: #74c5f6;
}

.certificacao {
    border-radius: 20px;
    /* background-color: $roxo; */
    color: #7c7c7c;
    background-color: white;
    transition: all ease-in-out 0.26s;
}

.certificacao:hover {
    /* background-color: #7B76BC; */
    background-color: #7c7c7c;
    color: white;
}

.dados {
    border-radius: 20px;
    /* background-color: $verdeEscuro-eapi; */
    background-color: #9f9cce;
    color: white;
    transition: all ease-in-out 0.26s;
}

.dados:hover {
    /* background-color: #154747; */
    color: #9f9cce;
    background-color: white;
}

.activeBtn {
    color: white;
    background-color: #00853b;
}

button:disabled,
button[disabled] {
    color: white;
    background-color: #7c8185;
    box-shadow: -1px 2px 10px 2px rgba(136, 138, 139, 0.3);
    cursor: default;
    pointer-events: none;
}

button:disabled:hover,
button[disabled]:hover {
    background-color: #7c8185;
    box-shadow: -1px 2px 10px 2px rgba(136, 138, 139, 0.3);
    cursor: default;
    transform: none;
}

.buttonIcon {
    display: flex;
    align-items: center;
    gap: 0.5em;
}

.action-buttons i{
    min-width: 16px!important;
}
</style>
