<template>
    <ModalCentral
        componentName="InstiRequests"
        title="Solicitações da instituição"
        idName="InstiRequests"
    />


    <div class="root-dados">
        <div class="container">
            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br />

            <h3 class="title-info"> Cadastro de informações </h3>
            <div class="title-row mb-6">
                <h1 class="title-info">{{ nomeInstituicao || "..." }}</h1>
                <div>
                    <Button
                        v-if="userPerm.permissao != 'APLICADOR'"
                        class="btn mr-2 menuButton"
                        section="dados-outline"
                        value="Solicitações"
                        :isOutline="true"
                        :hasIcon="true"
                        data-target="#InstiRequests"
                        data-toggle="modal"
                        iconName="envelope" />
                </div>
            </div>

            <div class="cardsful-bg">

                <router-link :to="{name: 'ListarMembros'}">
                <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="user-friends"
                                title="Membros"
                                palette="blue-section"
                />
                </router-link>
                <!-- :thumbnailPath="require('../../assets/img/Cards/Membros.svg')" -->

                <!-- <router-link :to="{name: 'ListarFormularios'}">
                <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="file-alt"
                                title="Formulários"
                                palette="blue-section"
                                data-target="#modalExemplo"
                                data-toggle="modal"

                />
                 :thumbnailPath="require('../../assets/img/Cards/Documento.svg')"
                </router-link>!-->

                <router-link :to="{name: 'ListarUnidadesEducacionais'}">
                <ColorfulCardsBG
                                :hasIcon="true"
                                class="cardful-single"
                                thumbnail="graduation-cap"
                                title="Unidades Educacionais e Turmas"
                                palette="blue-section"
                />
                </router-link>

                <router-link :to="{name: 'ListarEmpresas'}">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="building"
                        title="Empresas"
                        palette="orange-section"
                    />
                </router-link>

                <router-link :to="{name: 'ListarEgressos'}">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="door-open"
                        title="Egressos"
                        palette="orange-section"
                    />
                </router-link>

            </div>
        </div>
    </div>

</template>

<script>

import ColorfulCardsBG from '@components/ColorfulCardsBG'
import Button from '@components/Button';
import ModalCentral from '@components/ModalCentral';
import Breadcrumb from "@components/Breadcrumb";

export default {
    name: 'Instituicao',
    data () {
        return {
            nomeInstituicao: '',
            descricao: '',
            teams: [],
            crumbs: [
                {
                    id: 1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Cadastrar',
                    iconName: 'pen',
                    name_route: ''
                },
            ]
        }
    },
    components: {
        ColorfulCardsBG,
        Button,
        ModalCentral,
        Breadcrumb,
    },
    created(){
        this.teams = this.$store.getters.getListTeams
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    },
     computed: {
         userPerm() {
             return this.$store.getters.getUserPerm
         },
     }
}

</script>

<style scoped>

.root-dados{
    background: linear-gradient(180deg, rgba(0, 133, 59, 0.8) 0%, rgba(0, 133, 59, 0) 100%) !important;
    padding: 2em 0;
}

.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 3rem;
}

.cardsful-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.cardful-single {
    margin: 1rem;
}

.title-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

 .title-info{
     font-weight: 600;
     font-size: 1.6rem;
     color: #fff;
     margin: 0;
 }

 h3.title-info{
    font-size: 1.25rem;
    margin-bottom: 0.8em;
 }

 .menuButton {
     background-image: url("~@/assets/img/Backgrounds/verdeEscuroEAPI.webp");
     background-repeat: repeat;
     background-size: 256px;
     box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.5);
 }

 :deep(.menuButton div) {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

</style>
