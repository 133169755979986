<template>
    <div class="input-file">
        <div v-cloak @click="openDialog" @drop.prevent="addFile" @dragover.prevent @dragover.capture="isDragOverActions()" @dragleave.capture="isDragLeaveActions()" class="drag-and-drop" :class="{'drag-style': isDragOver}">
            <div v-if="arquivo == null" class="text-center infos">
                <img class="img-fluid img-center-input-file" :src="require('@assets/img/Dados/ExportarDevolutiva/'+name_image+'.png')">
                <p class="label-center-input-file">{{label}}</p>
            </div>
            <div class="file-uploaded" v-else>
                <img class="img-icon-uploaded" src="@assets/img/Dados/ExportarDevolutiva/img-icon.png" alt="">
                {{ arquivo.name.substr(0, 7)+'...'}} {{ arquivo?.size ? (arquivo.size/1000).toFixed(1)+ ' KB' : "..." }}
            </div>
        </div>
        <button v-if="arquivo != null" class="remove-file" @click="removeFile()" title="Remove">Remover Arquivo</button>
        <input tabindex="-1" @change="addFileViaInput" type="file" ref="uploadFile" placeholder="Escolha ou arraste um arquivo para gerar a devolutiva" hidden>
    </div>
</template>

<script type="text/javascript">
 import Swal from "sweetalert2";
 // import { Notyf } from "notyf";
 // const notyf = new Notyf({
 //     duration: 5000,
 //     position: {
 //         x: 'left',
 //         y: 'bottom',
 //     }
 // });
 //
 export default {
     name: "ImageInput",
     data() {
         return {
             label_button: 'Fazer Upload',
             isTurningCog: false,
             arquivo: null,
             isDragOver: false,
             name_image: "icone-upload",
             label: "Escolha ou arraste uma imagem",
             altered: false,
         };
     },
     props: {
         img: String,
     },
     watch: {
         async img(updated) {
             this.altered = false;
             if (!updated || updated == "null" || updated == "undefined") {
                 this.arquivo = null;
                 return;
             }

             this.arquivo = {
                 name: "ATUAL",
             };
           this.altered = false;
         },
     },
     methods: {
         isDragOverActions() {
             this.isDragOver = true;
             this.label = "Solte o arquivo aqui!";
             this.name_image = "icone-upload-happy";
         },
         isDragLeaveActions() {
             this.isDragOver = false;
             this.label = "Clique ou arraste um arquivo para gerar a devolutiva";
             this.name_image = "icone-upload";
         },
         addFile(e) {
             let droppedFiles = e.dataTransfer.files;
             this.isDragLeaveActions();
             for (let f of droppedFiles) {
                 if (f.type.split("/")[0] == "image") {
                     this.arquivo = f;
                     this.altered = true;
                 } else
                     Swal.fire(
                         'Erro!',
                         'Só é permitido arquivos de imagem.',
                         'error'
                     );
             }
         },
         addFileViaInput() {
             let droppedFiles = this.$refs.uploadFile.files;
             for (let f of droppedFiles) {
                 if (f.type.split("/")[0] == "image") {
                     this.arquivo = f;
                     this.altered = true;
                 } else
                     Swal.fire(
                         'Erro!',
                         'Só é permitido arquivos de imagem.',
                         'error'
                     )
             }
         },
         removeFile(){
             this.arquivo = null;
             this.altered = true;
         },
         openDialog() {
             this.$refs.uploadFile.value = '';
             this.$refs.uploadFile.click();
         },
         getFile() { return this.arquivo; },
         getAltered() { return this.altered; },
     },
 };
</script>

<style lang="scss" media="screen" scoped>
 @import "../assets/scss/_variables.scss";

 .input-file .remove-file {
     border: 1px solid #fd5454;
     color: #fd5454;
     padding: 0.4rem;
     margin-top: 0.2rem;
     font-size: 0.9rem;
     position: absolute;
     bottom: 24%;
 }

 .input-file .remove-file:hover {
     color: white;
     background-color: #fd5454;
 }

 @keyframes cogAnimation {
     to {
         transform: rotate(360deg);
     }
 }

 .fade-enter-active, .fade-leave-active {
     transition: opacity .5s;
 }
 .fade-enter, .fade-leave-to {
     opacity: 0;
 }

 @media screen and (max-width: 728px) {
     .drag-and-drop{
         width: auto;
         margin: 0 1rem;
     }
 }

 .img-icon-uploaded{
     max-height: 17% !important;
     width: auto;
 }

 .input-file {
     display: flex;
     align-items: center;
     flex-direction: column;
     justify-content: center;
     position: relative;
 }

 .input-file input {
     max-width: 30rem;
 }

 .input-file h1 {
     font-size: 1.6rem;
     font-weight: bold;
     text-align: left;
     margin-top: 1rem;
     margin-bottom: 1.5rem;
     color: #0d9f9f;
 }

 .input-file button {
     border: 1px solid $verdeEscuro-eapi;
     color: #0d9f9f;
     background-color: white;
     border-radius: 4px;
     padding: 0.4rem 1.2rem 0.4rem 0.4rem;
     font-weight: bold;
     cursor: pointer;
     outline: none;
     transition: all ease-in-out 0.2s;
     -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
     -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
     box-shadow: 6px 6px 5px -1px rgba(0,0,0,0.2);
 }

 .input-file button:hover {
     color: white;
     background-color: $verdeEscuro-eapi;
 }

 .drag-and-drop {
     height: 14rem;
     width: 27rem;
     border: 4px dashed $verdeEscuro-eapi;
     background-color: white;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     cursor: pointer;
     padding: 0 1.5rem;
     margin: 0 2rem;
 }

 .drag-and-drop img {
     max-height: 5rem;
 }

 .drag-and-drop p {
     color: #656565;
     font-weight: 400;
     user-select: none;
 }

 .drag-style {
     filter: blur(0.7px);
 }

 .file-uploaded {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     margin-top: -2.5rem;
 }

</style>