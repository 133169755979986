//---------------------------------------------
// Notifications Module 
//---------------------------------------------
// Módulo para gerenciar as notificacoes

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
// var qs = require('qs');
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listInstiRequests: [],
    listUserRequests: [],
    listGroupRequests: [],

    listInstiExternal: [],
    listGroupExternal: [],


    //VERIFICACOES
    isSentRequestUserInsti: null,
    isSentRequestInstiUser: null,
    isSentRequestGroupUser: null,
    isSentRequestGroupInsti: null,
    isSentRequestUserGroup: null,

    isCanceledGroupNoti: null,
    isCanceledUserNoti: null,
    isCanceledInstiNoti: null,

    isRequestUpdated: null,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DE TODAS AS SOLICITACOES DA INSTI
    setListInstiRequests(state, payload) {
        state.listInstiRequests = payload
    },

    //-- MUTATION REQUISICAO DE TODAS AS SOLICITACOES DO USER
    setListUserRequests(state, payload) {
        state.listUserRequests = payload
    },

    //-- MUTATION REQUISICAO DE TODAS AS SOLICITACOES DO GRUPO
    setListGroupRequests(state, payload) {
        state.listGroupRequests = payload
    },

    //-- MUTATION REQUISIÇÃO SOLICITAÇÕES USUÁRIO EXTERNO DA INSTI
    setInstiExternal(state, payload) {
        state.listInstiExternal = payload
    },

    //-- MUTATION REQUISIÇÃO SOLICITAÇÕES USUÁRIO EXTERNO DO GRUPO
    setGroupExternal(state, payload) {
        state.listGroupExternal = payload
    },

    //-- MUTATION PARA SETAR VALIDACAO DO ENVIO USER -> INSTI
    setIsSentRequestUserInsti(state, payload) {
        state.isSentRequestUserInsti = payload
    },

    //-- MUTATION PARA SETAR VALIDACAO DO ENVIO USER -> GROUP
    setIsSentRequestUserGroup(state, payload) {
        state.isSentRequestUserGroup = payload
    },

    //-- MUTATION PARA SETAR VALIDACAO DO ENVIO USER -> INSTI
    setIsSentRequestInstiUser(state, payload) {
        state.isSentRequestInstiUser = payload
    },

    //-- MUTATION PARA SETAR VALIDACAO DO ENVIO GROUP -> USER
    setIsSentRequestGroupUser(state, payload) {
        state.isSentRequestGroupUser = payload
    },

    //-- MUTATION PARA SETAR VALIDACAO DO ENVIO GROUP -> INSTI
    setIsSentRequestGroupInsti(state, payload) {
        state.isSentRequestGroupInsti = payload
    },

    //-- MUTATION PARA SETAR CONFIRMAÇÃO DE EXCLUSÃO DE NOTIFICAÇÃO DE GROUP
    setIsCanceledGroupNoti(state, payload) {
        state.isCanceledGroupNoti = payload
    },

    //-- MUTATION PARA SETAR CONFIRMAÇÃO DE EXCLUSÃO DE NOTIFICAÇÃO DE USER
    setIsCanceledUserNoti(state, payload) {
        state.isCanceledUserNoti = payload
    },

    //-- MUTATION PARA SETAR CONFIRMAÇÃO DE EXCLUSÃO DE NOTIFICAÇÃO DE INSTI
    setIsCanceledInstiNoti(state, payload) {
        state.isCanceledInstipNoti = payload
    },

    //-- MUTATION PARA SETAR CONFIRMAÇÃO DE ALTERAÇÃO DE ESTADO DO CONVITE
    setIsRequestUpdated(state, payload) {
        state.isRequestUpdated = payload
    }
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DE SOLICITACAO (USER PEDINDO PRA ENTRAR NUMA INSTI)
    async setNotifyUserJoinInsti({commit}, payload) {
        await axios
            .post(`notificacoes/user`, {
                idInstituicao: payload.idInstituicao,
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(
                commit('setIsSentRequestUserInsti', true)
            )
            .catch((error) => {
                console.log(error.message)
                commit('setIsSentRequestUserInsti', false)
            })
    },

    //-- ACTION REQUISICAO DE SOLICITACAO (USER PEDINDO PRA ENTRAR NUM GRUPO)
    async setNotifyUserJoinGrupo({ commit }, payload) {
        commit('setIsSentRequestUserGroup', null)
        await axios
            .post(`notificacoes/user`, {
                idGrupo: payload.idGrupo,
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => { commit('setIsSentRequestUserGroup', true) })
            .catch((error) => {
                console.log(error.message)
                commit('setIsSentRequestUserGroup', false)
            })
    },

    //-- ACTION REQUISICAO DE SOLICITACAO (INSTI PEDINDO PRA USER ENTRAR)
    async setNotifyInstiInviteUser({commit}, payload) {
        await axios
            .post(`notificacoes/insti/${payload.idInstituicao}`, {
                mails: payload.userMails,
                notiType: 'INSTITUICAO_USUARIO',
                ...(payload.permissao && { permissao: payload.permissao }),
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(
                commit('setIsSentRequestInstiUser', true)
            )
            .catch((error) => {
                console.log(error.message)
                commit('setIsSentRequestInstiUser', false)
            })
    },

    //-- ACTION REQUISICAO DE SOLICITACAO (GROUP PEDINDO PRA USER ENTRAR)
    async setNotifyGroupInviteUser({commit}, payload) {
        commit('setIsSentRequestGroupUser', null)
        await axios
            .post(`notificacoes/grupo/${payload.idGrupo}`, {
                mails: payload.userMails,
                notiType: 'GRUPO_USUARIO'
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(
                commit('setIsSentRequestGroupUser', true)
            )
            .catch((error) => {
                console.log(error.message)
                commit('setIsSentRequestGroupUser', false)
            })
    },

    //-- ACTION REQUISICAO DE SOLICITACAO (GROUP PEDINDO PRA INSTI ENTRAR)
    async setNotifyGroupInviteInsti({commit}, payload) {
        commit('setIsSentRequestGroupInsti', null)
        await axios
            .post(`notificacoes/grupo/${payload.idGrupo}`, {
                idInstituicao: payload.instiId,
                notiType: 'GRUPO_INSTITUICAO',
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(
                commit('setIsSentRequestGroupInsti', true)
            )
            .catch((error) => {
                console.log(error.message)
                commit('setIsSentRequestGroupInsti', false)
            })
    },

    //-- ACTION REQUISICAO RETORNO DAS SOLICITACOES DA INSTI
    async setListInstiRequests({ commit }, payload) {
        await axios
            .get(
                `notificacoes/insti/${payload.idInstituicao}?${payload.notiTypes?.map(type => "notiTypes[]=" + type).join('&')}`, {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .then(response => {
                commit('setListInstiRequests', response.data.data.notificacoes)
                commit('setInstiExternal', response.data.data.inviteUser)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setListInstiRequests', [])
                commit('setInstiExternal', [])
            })
    },

    //-- ACTION REQUISICAO RETORNO DAS SOLICITACOES DO USER
    async setListUserRequests({ commit }, payload) {
        await axios
            .get(`notificacoes/user/?${payload.notiTypes?.map(type => "notiTypes[]=" + type).join('&')}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListUserRequests', response.data.data.notificacoes)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setListUserRequests', [])
            })
    },

    //-- ACTION REQUISICAO RETORNO DAS SOLICITACOES DO GRUPO
    async setListGroupRequests({ commit }, payload) {
        await axios
            .get(`notificacoes/Grupo/${payload.idGrupo}?${payload.notiTypes?.map(type => "notiTypes[]=" + type).join('&')}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListGroupRequests', response.data.data.notificacoes)
                commit('setGroupExternal', response.data.data.inviteUser)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setListGroupRequests', [])
                commit('setGroupExternal', [])
            })
    },

    //-- ACTION PARA ACEITAR/RECUSAR AS SOLICITACOES DA INSTI
    async setStatusInstiRequest({ commit }, payload)  {
        commit("setIsRequestUpdated", null)
        await axios
            .put(`notificacoes/insti/${payload.idInstituicao}/${payload.idSolicitacao}`, {
                isAceito: payload.isAceito
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
                })
            .then(() => { commit("setIsRequestUpdated", true) })
            .catch((error) => {
                console.log(error.message)
                commit("setIsRequestUpdated", true)
            })
    },

    //-- ACTION PARA ACEITAR/RECUSAR AS SOLICITACOES DO USER
    async setStatusUserRequest({ commit }, payload)  {
        commit("setIsRequestUpdated", null)
        await axios
            .put(`notificacoes/user/${payload.idSolicitacao}`, {
                isAceito: payload.isAceito
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
                })
            .then(() => { commit("setIsRequestUpdated", true) })
            .catch((error) => {
                console.log(error.message)
                commit("setIsRequestUpdated", false)
            })
    },

    //-- ACTION PARA ACEITAR/RECUSAR AS SOLICITACOES DO GRUPO
    async setStatusGroupRequest({ commit }, payload)  {
        commit("setIsRequestUpdated", null)
        await axios
            .put(`notificacoes/grupo/${payload.idGrupo}/${payload.idSolicitacao}`, {
                isAceito: payload.isAceito
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => { commit("setIsRequestUpdated", true) })
            .catch((error) => {
                console.log(error.message)
                commit("setIsRequestUpdated", false)
            })
    },

    //-- ACTION PARA DELETAR NOTIFICAÇÃO DE GRUPO
    async deleteGroupNoti({ commit }, payload) {
        commit('setIsCanceledGroupNoti', null)
        await axios.delete(`notificacoes/Grupo/${payload.idGrupo}/${payload.idNoti}`, {
            headers: {
                Authorization: `Bearer ${JWT()}`,
            }
        }).then(response => {
            console.log(response)
            commit('setIsCanceledGroupNoti', true)
        }).catch(err => {
            console.log(err)
            commit('setIsCanceledGroupNoti', false)
        })
    },

    //-- ACTION PARA DELETAR NOTIFICAÇÃO DE USUÁRIO
    async deleteUserNoti({ commit }, payload) {
        commit('setIsCanceledUserNoti', null)
        await axios.delete(`notificacoes/user/${payload.idNoti}`, {
            headers: {
                Authorization: `Bearer ${JWT()}`,
            }
        }).then(response => {
            console.log(response)
            commit('setIsCanceledUserNoti', true)
        }).catch(err => {
            console.log(err)
            commit('setIsCanceledUserNoti', false)
        })
    },

    //-- ACTION PARA DELETAR NOTIFICAÇÃO DE INSTITUIÇÃO
    async deleteInstiNoti({ commit }, payload) {
        commit('setIsCanceledInstiNoti', null)
        await axios.delete(`notificacoes/insti/${payload.idInsti}/${payload.idNoti}`, {
            headers: {
                Authorization: `Bearer ${JWT()}`,
            }
        }).then(response => {
            console.log(response)
            commit('setIsCanceledInstiNoti', true)
        }).catch(err => {
            console.log(err)
            commit('setIsCanceledInstiNoti', false)
        })
    },

}

// GETTERS
const getters = {
    getListInstiRequests(state) {
        return state.listInstiRequests || []
    },

    getInstiExternal(state) {
        return state.listInstiExternal || []
    },

    getListUserRequests(state) {
        return state.listUserRequests || []
    },

    getListGroupRequests(state) {
        return state.listGroupRequests || []
    },

    getGroupExternal(state) {
        return state.listGroupExternal || []
    },

    getIsSentRequestUserInsti(state) {
        return state.isSentRequestUserInsti
    },

    getIsSentRequestUserGroup(state) {
        return state.isSentRequestUserGroup
    },

    getIsSentRequestInstiUser(state) {
        return state.isSentRequestInstiUser
    },

    getIsSentRequestGroupUser(state) {
        return state.isSentRequestGroupUser
    },

    getIsSentRequestGroupInsti(state) {
        return state.isSentRequestGroupInsti
    },

    getIsCanceledGroupNoti(state) {
        return state.isCanceledGroupNoti
    },

    getIsCanceledUserNoti(state) {
        return state.isCanceledUserNoti
    },

    getIsCanceledInstiNoti(state) {
        return state.isCanceledInstiNoti
    },

    getIsRequestUpdated(state) {
        return state.isRequestUpdated
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}


