<template>
    <!-- <ModalCentral componentName="AddPDF" title="ADICIONAR PDF" idName="AddPDF" />
         <ModalCentral componentName="AddVideo" title="ADICIONAR VÍDEO" idName="AddVideo" /> -->

    <div class="bg">
        <h2>Documentos oficiais</h2>

        <!-- <div v-show="userPerm == true" class="buttons">
             <Button
             value="Adicionar PDF"
             :isOutline="true"
             :hasIcon="true"
             :hasShadow="true"
             iconName="file-pdf"
             :isReverse="false"
             data-toggle="modal"
             data-target="#AddPDF"
             class="adminButton"
             />
             <Button
             value="Adicionar Vídeo"
             :isOutline="true"
             :hasIcon="true"
             iconName="play"
             :isReverse="false"
             data-toggle="modal"
             data-target="#AddVideo"
             class="adminButton"
             />
             </div>
        -->
        <div v-if="materials.length">
            <MaterialListItem
                v-for="(material, idx) in materials"
                :key="idx"
                :listIdx="idx + 1"
                :index="idx"
                :title="material.titulo"
                :isVideo="false"
                :link="material.link"
            />
        </div>
        <div class="none" v-else>
            <h2>Nenhum documento oficial cadastrado!</h2>
        </div>
    </div>
</template>

<script type="text/javascript">
 import MaterialListItem from '@components/MateriaiseDocumentos/MaterialListItem';
 // import ModalCentral from '@components/ModalCentral';
 // import Button from '@components/Button';

 export default {
     name: "DocsOficiais",
     data() {
         return {};
     },
     computed: {
         materials() {
             return [
                 {
                     titulo: "Constituição Federal (1998)",
                     link: "http://www.planalto.gov.br/ccivil_03/constituicao/constituicao.htm",
                 }, {
                     titulo: "Estatuto da Criança e do Adolescente (ECA/1990)",
                     link: "http://www.planalto.gov.br/ccivil_03/leis/l8069.htm",
                 }, {
                     titulo: "Lei de Diretrizes e Bases da Educação Nacional (LDB/1996)",
                     link: "http://www.planalto.gov.br/ccivil_03/leis/l9394.htm",
                 }, {
                     titulo: "Parâmetros Básicos de Infra-estrutura para Instituições de Educação Infantil  (2006)",
                     link: "http://portal.mec.gov.br/seb/arquivos/pdf/Educinf/miolo_infraestr.pdf",
                 }, {
                     titulo: "Critérios para um Atendimento em Creches que Respeite os Direitos Fundamentais das Crianças (2009)",
                     link: "http://portal.mec.gov.br/dmdocuments/direitosfundamentais.pdf",
                 }, {
                     titulo: "Parecer 20/2009",
                     link: "http://portal.mec.gov.br/dmdocuments/pceb020_09.pdf",
                 }, {
                     titulo: "Diretrizes Curriculares Nacionais para a Educação Infantil (2009)",
                     link: "http://portal.mec.gov.br/dmdocuments/diretrizescurriculares_2012.pdf",
                 }, {
                     titulo: "Indicadores da Qualidade na Educação Infantil (2009)",
                     link: "http://portal.mec.gov.br/dmdocuments/indic_qualit_educ_infantil.pdf",
                 }, {
                     titulo: "EDUCAÇÃO INFANTIL: Subsídios para construção de uma sistemática de avaliação",
                     link: "http://portal.mec.gov.br/index.php?option=com_docman&view=download&alias=11990-educacao-infantil-sitematica-avaliacao-pdf&category_slug=novembro-2012-pdf&Itemid=30192",
                 }, {
                     titulo: "Avaliação em Educação Infantil a partir da avaliação de contexto (2015)",
                     link: "http://portal.mec.gov.br/index.php?option=com_docman&view=download&alias=36641-seb-avaliacao-educacao-infantil-a-partir-avaliacao-contexto-pdf&category_slug=marco-2016-pdf&Itemid=30192",
                 }, {
                     titulo: "Plano Nacional de Educação (2014-2024)",
                     link: "https://pne.mec.gov.br/18-planos-subnacionais-de-educacao/543-plano-nacional-de-educacao-lei-n-13-005-2014",
                 }, {
                     titulo: "Parâmetros Nacionais de Qualidade para a Educação Infantil",
                     link: "http://portal.mec.gov.br/docman/2020/141451-public-mec-web-isbn-2019-003/file",
                 }, {
                     titulo: "Base Nacional Comum Curricular (BNCC/2019)",
                     link: "http://basenacionalcomum.mec.gov.br/images/BNCC_EI_EF_110518_versaofinal_site.pdf",
                 }, {
                     titulo: "Base Nacional Comum para Formação Continuada de Professores",
                     link: "https://www.in.gov.br/web/dou/-/resolucao-cne/cp-n-1-de-27-de-outubro-de-2020-285609724",
                 }
             ];
         },
     },
     components: {
         MaterialListItem,
         // ModalCentral,
         // Button,
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;

     padding: 1em;
     box-sizing: border-box;
 }

 h2 {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .buttons {
     width: 100%;
     display: flex;
     flex-wrap: wrap;
     justify-content: flex-end;
     margin-bottom: 1em;
 }

 .adminButton {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.png");
     background-repeat: repeat;
     background-size: 256px;
     border: 0;
     box-shadow: inset -2px 2px 2px rgba(0, 0, 0, 0.25);
     color: #fcfcfc;
     margin: 5px 10px;
 }

 .adminButton span {
     background-clip: text;
     text-fill-color: transparent;
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .none {
     width: 100%;
     text-align: center;
 }

 button.grow:hover {
     transform: scale(1.01);
 }
</style>
