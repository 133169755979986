const form = {
  id: 1,
  formName: 'Competências gerais para o mundo do trabalho',
  type: 6,
  categories: [
    {
      id: 1,
      categoryName: 'INFORMAÇÕES CADASTRAIS',
      questions: [
        {
          id: 1,
          enunciated: '<strong>Qual a sua escola?</strong>',
          visible: true,
          questionType: {
            list: {},
          },
          questionItem: 'a',
          ordination: 1,
        },
        {
          id: 2,
          enunciated: '<strong>Qual a sua série?</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>1º ano EM</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>2º ano EM</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>3º ano EM</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>4º ano EM</p>',
                },
              ],
            },
          },
          questionItem: 'b',
          ordination: 2,
        },
        {
          id: 3,
          enunciated: '<strong>Qual a sua turma</strong>',
          visible: true,
          questionType: {
            list: {},
          },
          questionItem: 'c',
          ordination: 3,
        },
        {
          id: 4,
          enunciated: '<strong>Qual o seu nome?</strong>',
          visible: true,
          questionType: {
            list: {},
          },
          questionItem: 'd',
          ordination: 4,
        },
        {
          id: 5,
          enunciated: '<strong>Qual o seu sexo?</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Homem</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Mulher</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Prefiro não responder</p>',
                },
              ],
            },
          },
          questionItem: 'e',
          ordination: 5,
        },
        {
          id: 6,
          enunciated: '<strong>Em que ano você nasceu?</strong>',
          visible: true,
          questionType: {
            date: {
              activityFields: [3]
            },
          },
          questionItem: 'f',
          ordination: 6,
        },
        {
          id: 7,
          enunciated: '<strong>Em que mês você nasceu?</strong>',
          visible: true,
          questionType: {
            date: {
              activityFields: [2]
            },
          },
          questionItem: 'g',
          ordination: 7,
        },
        {
          id: 8,
          enunciated: '<strong>Em que dia você nasceu?</strong>',
          visible: true,
          questionType: {
            date: {
              activityFields: [1]
            },
          },
          questionItem: 'h',
          ordination: 8,
        },
        {
          id: 9,
          enunciated: '<strong>Você já teve alguma experiência profissional?</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Sim</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Não</p>',
                },
              ],
            },
          },
          questionItem: 'i',
          ordination: 9,
        },
        {
          id: 10,
          enunciated: '<strong>Você está trabalhando atualmente?</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Sim</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Não</p>',
                },
              ],
              subType: {
                goTo: {
                  trigger: {
                    1: [1]
                  }
                }
              }
            },

          },
          questionItem: 'j',
          ordination: 10,
        },
        {
          id: 11,
          enunciated: '<strong>Faz quanto tempo que você trabalha? (meses)</strong>',
          visible: false,
          questionType: {
            num: {
              maxLength: 3,
            },
          },
          questionItem: 'k',
          ordination: 11,
        },
      ],
    },
    {
      id: 2,
      categoryName: 'Seção 1',
      questions: [
        {
          id: 12,
          enunciated: '<strong>Tenho dificuldade para saber qual tarefa devo fazer primeiro</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '1',
          ordination: 1,
        },
        {
          id: 13,
          enunciated: '<strong>Corrijo meus planos sempre que considero necessário</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '2',
          ordination: 2,
        },
        {
          id: 14,
          enunciated: '<strong>Prometo mais do que consigo cumprir</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '3',
          ordination: 3,
        },
        {
          id: 15,
          enunciated: '<strong>Sei como explicar um problema para outras pessoas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '4',
          ordination: 4,
        },
        {
          id: 16,
          enunciated: '<strong>Cumpro com aquilo que eu prometo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '5',
          ordination: 5,
        },
        {
          id: 17,
          enunciated: '<strong>Avalio as tarefas que já fiz para pensar em melhores formas realizá-las no futuro</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '6',
          ordination: 6,
        },
        {
          id: 18,
          enunciated: '<strong>Creio ser capaz de lidar com as consequências positivas e negativas do trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '7',
          ordination: 7,
        },
        {
          id: 19,
          enunciated: '<strong>Gosto de tomar decisões em um trabalho em grupo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '8',
          ordination: 8,
        },
        {
          id: 20,
          enunciated: '<strong>Sei como administrar as dificuldades que podem surgir no que eu planejei</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '9',
          ordination: 9,
        },
        {
          id: 21,
          enunciated: '<strong>Gosto/gostaria de realizar atividades para promoção da saúde</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '10',
          ordination: 10,
        },
        {
          id: 22,
          enunciated: '<strong>Consigo controlar minha ansiedade em situações difíceis</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '11',
          ordination: 11,
        },
        {
          id: 23,
          enunciated: '<strong>Confio no potencial dos meus colegas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '12',
          ordination: 12,
        },
        {
          id: 24,
          enunciated: '<strong>Gosto/gostaria de lidar com transações bancárias de clientes</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '13',
          ordination: 13,
        },
        {
          id: 25,
          enunciated: '<strong>Acredito que minhas atitudes definirão meus resultados</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '14',
          ordination: 14,
        },
        {
          id: 26,
          enunciated: '<strong>Posso me aperfeiçoar para alcançar um objetivo no futuro</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '15',
          ordination: 15,
        },
      ],
    },
    {
      id: 3,
      categoryName: 'Seção 2',
      questions: [
        {
          id: 27,
          enunciated: '<strong>Creio ser capaz de obter bons resultados no trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '16',
          ordination: 1,
        },
        {
          id: 28,
          enunciated: '<strong>Gosto/gostaria de convencer pessoas a comprar um produto</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '17',
          ordination: 2,
        },
        {
          id: 29,
          enunciated: '<strong>Sou reconhecido como uma pessoa confiável para realizar tarefas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '18',
          ordination: 3,
        },
        {
          id: 30,
          enunciated: '<strong> Encontro formas de terminar meu trabalho mesmo quando as coisas não acontecem como eu tinha previsto</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '19',
          ordination: 4,
        },
        {
          id: 31,
          enunciated: '<strong>Fico confortável ao realizar tarefas novas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '20',
          ordination: 5,
        },
        {
          id: 32,
          enunciated: '<strong>Consigo resolver sozinho(a) os problemas que me aparecem</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '21',
          ordination: 6,
        },
        {
          id: 33,
          enunciated: '<strong>Tenho facilidade em mudar meu ritmo de trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '22',
          ordination: 7,
        },
        {
          id: 34,
          enunciated: '<strong>Consigo finalizar minhas tarefas sozinho(a)</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '23',
          ordination: 8,
        },
        {
          id: 35,
          enunciated: '<strong>Gosto/gostaria de transportar dinheiro em um carro-forte</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '24',
          ordination: 9,
        },
        {
          id: 36,
          enunciated: '<strong>Gosto/gostaria de realizar a contabilidade de uma empresa</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '25',
          ordination: 10,
        },
        {
          id: 37,
          enunciated: '<strong>Tenho dificuldade para criar documentos de texto em um computador ou celular</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '26',
          ordination: 11,
        },
        {
          id: 38,
          enunciated: '<strong>Busco aprimoramento para fazer as coisas melhor do que tenho feito</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '27',
          ordination: 12,
        },
        {
          id: 39,
          enunciated: '<strong>Faço minhas tarefas dentro do prazo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '28',
          ordination: 13,
        },
        {
          id: 40,
          enunciated: '<strong>Evito procurar trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '29',
          ordination: 14,
        },
        {
          id: 41,
          enunciated: '<strong>Sei qual a opção profissional que pode ser mais vantajosa pra mim</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '30',
          ordination: 15,
        },
      ],
    },
    {
      id: 4,
      categoryName: 'Seção 3',
      questions: [
        {
          id: 42,
          enunciated: '<strong>Mantenho a calma quando preciso lidar com resultados inesperados</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '31',
          ordination: 1,
        },
        {
          id: 43,
          enunciated: '<strong>Penso que não preciso procurar um trabalho agora</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '32',
          ordination: 2,
        },
        {
          id: 44,
          enunciated: '<strong>Ordeno minhas tarefas diárias por prioridade</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '33',
          ordination: 3,
        },
        {
          id: 45,
          enunciated: '<strong>Gosto/gostaria de fazer pesquisas científicas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '34',
          ordination: 4,
        },
        {
          id: 46,
          enunciated: '<strong>Consigo falar de forma clara sobre temas difíceis </strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '35',
          ordination: 5,
        },
        {
          id: 47,
          enunciated: '<strong>Gosto/gostaria de saber mais sobre o comportamento humano</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '36',
          ordination: 6,
        },
        {
          id: 48,
          enunciated: '<strong>Gosto/gostaria de pensar em ideias para aumentar as vendas de uma empresa</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '37',
          ordination: 7,
        },
        {
          id: 49,
          enunciated: '<strong>Gosto/gostaria de criar cenários para peças</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '38',
          ordination: 8,
        },
        {
          id: 50,
          enunciated: '<strong>Gosto/gostaria de gerar a folha de pagamento mensal de uma empresa</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '39',
          ordination: 9,
        },
        {
          id: 51,
          enunciated: '<strong>Penso em diferentes formas de solucionar um mesmo problema</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '40',
          ordination: 10,
        },
        {
          id: 52,
          enunciated: '<strong>Consigo decidir pela opção profissional que considero a melhor pra mim</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '41',
          ordination: 11,
        },
        {
          id: 53,
          enunciated: '<strong>Tenho dificuldade para encontrar as informações que preciso na Internet</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '42',
          ordination: 12,
        },
        {
          id: 54,
          enunciated: '<strong>Gosto/gostaria de recomendar mudanças na forma de como a empresa opera</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '43',
          ordination: 13,
        },
        {
          id: 55,
          enunciated: '<strong>Gosto/gostaria de montar uma planilha usando um programa de computador</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '44',
          ordination: 14,
        },
        {
          id: 56,
          enunciated: '<strong>Sinto que não posso melhorar minhas habilidades</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '45',
          ordination: 15,
        },
      ],
    },
    {
      id: 5,
      categoryName: 'Seção 4',
      questions: [
        {
          id: 57,
          enunciated: '<strong>Conquisto meus objetivos por causa dos meus esforços</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '46',
          ordination: 1,
        },
        {
          id: 58,
          enunciated: '<strong>Consigo utilizar os meios digitais para aprimomar ou aprender novas habilidades</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '47',
          ordination: 2,
        },
        {
          id: 59,
          enunciated: '<strong>Acho importante compreender como um problema foi resolvido</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '48',
          ordination: 3,
        },
        {
          id: 60,
          enunciated: '<strong>Mantenho o foco em meu objetivo mesmo quando enfrento dificuldades</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '49',
          ordination: 4,
        },
        {
          id: 61,
          enunciated: '<strong>Costumo perder prazos de entrega por não saber organizar meu tempo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '50',
          ordination: 5,
        },
        {
          id: 62,
          enunciated: '<strong>Gosto/gostaria de estudar formas de reduzir a poluição da água</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '51',
          ordination: 6,
        },
        {
          id: 63,
          enunciated: '<strong>Gosto/gostaria de fazer uma obra de arte criativa</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '52',
          ordination: 7,
        },
        {
          id: 64,
          enunciated: '<strong>Gosto/gostaria de ensaiar artistas para um espetáculo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '53',
          ordination: 8,
        },
        {
          id: 65,
          enunciated: '<strong>Assumo as responsabilidades das minhas escolhas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '54',
          ordination: 9,
        },
        {
          id: 66,
          enunciated: '<strong>Costumo realizar as coisas da mesma maneira por ter dificuldade de saber como fazer melhor</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '55',
          ordination: 10,
        },
        {
          id: 67,
          enunciated: '<strong>Fiz meu currículo para procurar trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '56',
          ordination: 11,
        },
        {
          id: 68,
          enunciated: '<strong>Identifico facilmente os momentos em que sou líder e em que sou um dos liderados</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '57',
          ordination: 12,
        },
        {
          id: 69,
          enunciated: '<strong>Fico nervoso quando preciso falar em público</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '58',
          ordination: 13,
        },
        {
          id: 70,
          enunciated: '<strong>Prefiro que não me peçam ajuda no trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '59',
          ordination: 14,
        },
        {
          id: 71,
          enunciated: '<strong>Assumo a iniciativa para realizar as atividades necessárias</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '60',
          ordination: 15,
        },
      ],
    },
    {
      id: 6,
      categoryName: 'Seção 5',
      questions: [
        {
          id: 72,
          enunciated: '<strong>Gosto/gostaria de fazer a apresentação de um produto</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '61',
          ordination: 1,
        },
        {
          id: 73,
          enunciated: '<strong>Gosto/gostaria de reparar o motor de um automóvel</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '62',
          ordination: 2,
        },
        {
          id: 74,
          enunciated: '<strong>Sinto que nasci para um único tipo de trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '63',
          ordination: 3,
        },
        {
          id: 75,
          enunciated: '<strong>Gosto/gostaria de determinar a causa de uma doença</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '64',
          ordination: 4,
        },
        {
          id: 76,
          enunciated: '<strong>Reviso minhas tarefas antes de entregar</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '65',
          ordination: 5,
        },
        {
          id: 77,
          enunciated: '<strong>Penso em passos para conseguir atingir meu objetivo profissional</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '66',
          ordination: 6,
        },
        {
          id: 78,
          enunciated: '<strong>Gosto/gostaria de prestar serviços de acolhimento de crianças</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '67',
          ordination: 7,
        },
        {
          id: 79,
          enunciated: '<strong>Tenho vergonha de expor meu ponto de vista</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '68',
          ordination: 8,
        },
        {
          id: 80,
          enunciated: '<strong>Estou me empenhando para conseguir trabalhar</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '69',
          ordination: 9,
        },
        {
          id: 81,
          enunciated: '<strong>Gosto/gostaria de testar reações químicas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '70',
          ordination: 10,
        },
        {
          id: 82,
          enunciated: '<strong>Sinto necessidade de me dedicar à procura de um trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '71',
          ordination: 11,
        },
        {
          id: 83,
          enunciated: '<strong>Penso que terei dificuldade para entrar no mercado do trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '72',
          ordination: 12,
        },
        {
          id: 84,
          enunciated: '<strong>Gosto/gostaria de cantar em uma banda</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '73',
          ordination: 13,
        },
        {
          id: 85,
          enunciated: '<strong>Sei como lidar com meus sentimentos ruins</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '74',
          ordination: 14,
        },
        {
          id: 86,
          enunciated: '<strong>Lido bem com o imprevisível</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '75',
          ordination: 15,
        },
      ],
    },
    {
      id: 7,
      categoryName: 'Seção 6',
      questions: [
        {
          id: 87,
          enunciated: '<strong>Gosto/gostaria de administrar recursos financeiros de empresas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '76',
          ordination: 1,
        },
        {
          id: 88,
          enunciated: '<strong>Acredito que serei capaz de atender as expectativas de pessoas importantes no trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '77',
          ordination: 2,
        },
        {
          id: 89,
          enunciated: '<strong>Fico feliz em ajudar um colega no trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '78',
          ordination: 3,
        },
        {
          id: 90,
          enunciated: '<strong>Raramente eu me esforço para aprender coisas novas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '79',
          ordination: 4,
        },
        {
          id: 91,
          enunciated: '<strong>Decido com facilidade entre as opções profissionais ao analisar prós e contras de cada uma delas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '80',
          ordination: 5,
        },
        {
          id: 92,
          enunciated: '<strong>Posso adquirir habilidades que não tenho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '81',
          ordination: 6,
        },
        {
          id: 93,
          enunciated: '<strong>Analiso por conta própria as demandas do mercado de trabalho para saber o que eu preciso fazer</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '82',
          ordination: 7,
        },
        {
          id: 94,
          enunciated: '<strong>Dedico-me continuamente a aumentar meus conhecimentos</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '83',
          ordination: 8,
        },
        {
          id: 95,
          enunciated: '<strong>Sei quais são as tarefas que mais gosto de fazer</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '84',
          ordination: 9,
        },
        {
          id: 96,
          enunciated: '<strong>Gosto/gostaria de elaborar documentos, relatórios e registros técnicos</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '85',
          ordination: 10,
        },
        {
          id: 97,
          enunciated: '<strong>Acho difícil começar a resolver um problema</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '86',
          ordination: 11,
        },
        {
          id: 98,
          enunciated: '<strong>Identifico fortalezas e fraquezas dos integrantes do grupo antes de dividir as tarefas a serem feitas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '87',
          ordination: 12,
        },
        {
          id: 99,
          enunciated: '<strong>Tenho um planejamento profissional para quando me formar</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '88',
          ordination: 13,
        },
        {
          id: 100,
          enunciated: '<strong>Creio que conseguirei uma posição no mercado de trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '89',
          ordination: 14,
        },
        {
          id: 101,
          enunciated: '<strong>Consigo fazer com que as pessoas se organizem para realizar uma tarefa em grupo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '90',
          ordination: 15,
        },
      ],
    },
    {
      id: 8,
      categoryName: 'Seção 7',
      questions: [
        {
          id: 102,
          enunciated: '<strong>Me sinto despreparado para a vida adulta</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '91',
          ordination: 1,
        },
        {
          id: 103,
          enunciated: '<strong>Acredito que o sucesso da minha carreira será consequência das minhas escolhas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '92',
          ordination: 2,
        },
        {
          id: 104,
          enunciated: '<strong>Coloco metas realistas em meus compromissos</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '93',
          ordination: 3,
        },
        {
          id: 105,
          enunciated: '<strong>Consigo o que quero porque me esforço muito</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '94',
          ordination: 4,
        },
        {
          id: 106,
          enunciated: '<strong>Desempenho bem tarefas que exigem improvisação</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '95',
          ordination: 5,
        },
        {
          id: 107,
          enunciated: '<strong>Tenho dificuldade para identificar as vantagens e desvantagens das opções profissionais que tenho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '96',
          ordination: 6,
        },
        {
          id: 108,
          enunciated: '<strong>Tenho dificuldade quando tenho que sair da minha rotina</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '97',
          ordination: 7,
        },
        {
          id: 109,
          enunciated: '<strong>Penso na melhor sequência de ações para que as tarefas sejam concluídas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '98',
          ordination: 8,
        },
        {
          id: 110,
          enunciated: '<strong>Sei como obter as informações que desejo utilizando sites de busca na Internet</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '99',
          ordination: 9,
        },
        {
          id: 111,
          enunciated: '<strong>Tenho dificuldade em lidar com dois problemas ao mesmo tempo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '100',
          ordination: 10,
        },
        {
          id: 112,
          enunciated: '<strong>Consigo trabalhar com pessoas de diferentes idades</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '101',
          ordination: 11,
        },
        {
          id: 113,
          enunciated: '<strong>Gosto/gostaria de participar de um teste para um filme</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '102',
          ordination: 12,
        },
        {
          id: 114,
          enunciated: '<strong>Consigo falar quando alguém me faz algo de que não gosto</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '103',
          ordination: 13,
        },
        {
          id: 115,
          enunciated: '<strong>Posso aprimorar minhas habilidades para quaisquer tipos de tarefa</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '104',
          ordination: 14,
        },
        {
          id: 116,
          enunciated: '<strong>Tenho dificuldade para analisar as opções profissionais que tenho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '105',
          ordination: 15,
        },
      ],
    },
    {
      id: 9,
      categoryName: 'Seção 8',
      questions: [
        {
          id: 117,
          enunciated: '<strong>Consigo manter um ambiente saudável em um grupo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '106',
          ordination: 1,
        },
        {
          id: 118,
          enunciated: '<strong>Mantenho os meus objetivos profissionais e pessoais alinhados</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '107',
          ordination: 2,
        },
        {
          id: 119,
          enunciated: '<strong>Preocupo-me em ajudar somente quando é minha obrigação</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '108',
          ordination: 3,
        },
        {
          id: 120,
          enunciated: '<strong>Gosto/gostaria de dar aulas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '109',
          ordination: 4,
        },
        {
          id: 121,
          enunciated: '<strong>Sei o que quero fazer depois que terminar o ensino médio</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '110',
          ordination: 5,
        },
        {
          id: 122,
          enunciated: '<strong>Tenho dificuldade de me adaptar a novas situações</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '111',
          ordination: 6,
        },
        {
          id: 123,
          enunciated: '<strong>Sei qual o tipo de carreira que mais condiz com minhas habilidades</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '112',
          ordination: 7,
        },
        {
          id: 124,
          enunciated: '<strong>Busco por conta própria efetuar mudanças que melhorem meu desempenho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '113',
          ordination: 8,
        },
        {
          id: 125,
          enunciated: '<strong>Coopero com meus colegas de trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '114',
          ordination: 9,
        },
        {
          id: 126,
          enunciated: '<strong>Tomo para mim a responsabilidade do trabalho do grupo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '115',
          ordination: 10,
        },
        {
          id: 127,
          enunciated: '<strong>Sei qual o tipo de carreira que mais condiz com meus interesses</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '116',
          ordination: 11,
        },
        {
          id: 128,
          enunciated: '<strong>Consigo perceber quando é a hora de começar a fazer as coisas de forma diferente</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '117',
          ordination: 12,
        },
        {
          id: 129,
          enunciated: '<strong>Consigo alternar entre diferentes tipos de tarefa</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '118',
          ordination: 13,
        },
        {
          id: 130,
          enunciated: '<strong>Dou minha opinião mesmo sabendo que a maioria discorda</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '119',
          ordination: 14,
        },
        {
          id: 131,
          enunciated: '<strong>Recebo bem as tarefas que me foram atribuídas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '120',
          ordination: 15,
        },
      ],
    },
    {
      id: 10,
      categoryName: 'Seção 9',
      questions: [
        {
          id: 132,
          enunciated: '<strong>Consigo me adaptar a novas funções no trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '121',
          ordination: 1,
        },
        {
          id: 133,
          enunciated: '<strong>Sou o(a) responsável pelas minhas ações</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '122',
          ordination: 2,
        },
        {
          id: 134,
          enunciated: '<strong>Gosto/gostaria de realizar manutenção de máquinas e equipamentos</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '123',
          ordination: 3,
        },
        {
          id: 135,
          enunciated: '<strong>Sei como utilizar diferentes formatos de arquivo (PDF, texto, planilha) para facilitar o compartilhamento de documentos</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '124',
          ordination: 4,
        },
        {
          id: 136,
          enunciated: '<strong>Analiso os riscos, atuais e futuros, das minhas opções profissionais na tomada de decisão</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '125',
          ordination: 5,
        },
        {
          id: 137,
          enunciated: '<strong>Consigo ligar um dispositivo eletrônico e fazer login se necessário</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '126',
          ordination: 6,
        },
        {
          id: 138,
          enunciated: '<strong>Posso aprender a trabalhar em coisas novas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '127',
          ordination: 7,
        },
        {
          id: 139,
          enunciated: '<strong> Consigo me manter bem quando algo de ruim acontece comigo</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '128',
          ordination: 8,
        },
        {
          id: 140,
          enunciated: '<strong>Gosto/gostaria de dirigir um caminhão para entregar encomendas em escritórios e casas</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '129',
          ordination: 9,
        },
        {
          id: 141,
          enunciated: '<strong>Gosto/gostaria de coletar amostras de produtos para análises laboratoriais</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '130',
          ordination: 10,
        },
        {
          id: 142,
          enunciated: '<strong>Acredito que conseguirei agir com responsabilidade no trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '131',
          ordination: 11,
        },
        {
          id: 143,
          enunciated: '<strong>Acredito que consigo cumprir com combinados no trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '132',
          ordination: 12,
        },
        {
          id: 144,
          enunciated: '<strong>Gosto/gostaria de instalar a fiação elétrica em uma casa</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '133',
          ordination: 13,
        },
        {
          id: 145,
          enunciated: '<strong>Gosto/gostaria de prestar serviços sociais em comunidades e bairros</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '134',
          ordination: 14,
        },
        {
          id: 146,
          enunciated: '<strong>Consigo conduzir uma conversa em grupo quando necessário</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '135',
          ordination: 15,
        },
      ],
    },
    {
      id: 11,
      categoryName: 'Seção 10',
      questions: [
        {
          id: 147,
          enunciated: '<strong>Tendo a desistir quando tenho muitas dificuldades</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '136',
          ordination: 1,
        },
        {
          id: 148,
          enunciated: '<strong>Costumo deixar as coisas para a última hora</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '137',
          ordination: 2,
        },
        {
          id: 149,
          enunciated: '<strong>Gosto/gostaria de escrever novelas ou filmes</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '138',
          ordination: 3,
        },
        {
          id: 150,
          enunciated: '<strong>Estou disposto a conseguir um trabalho</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '139',
          ordination: 4,
        },
        {
          id: 151,
          enunciated: '<strong>Consigo me adaptar às mudanças que ocorrem no meu dia a dia</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '140',
          ordination: 5,
        },
        {
          id: 152,
          enunciated: '<strong>Tendo a ficar com raiva com frequência</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '141',
          ordination: 6,
        },
        {
          id: 153,
          enunciated: '<strong>Gosto/gostaria de supervisionar o trabalho de equipes</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '142',
          ordination: 7,
        },
        {
          id: 154,
          enunciated: '<strong>Gosto/gostaria de cuidar de idosos em asilos ou casas de repouso</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '143',
          ordination: 8,
        },
        {
          id: 155,
          enunciated: '<strong>Gosto/gostaria de montar produtos em uma fábrica</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '144',
          ordination: 9,
        },
        {
          id: 156,
          enunciated: '<strong>Consigo entregar minhas tarefas com eficiência e rapidez</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '145',
          ordination: 10,
        },
        {
          id: 157,
          enunciated: '<strong>Gosto/gostaria de investigar a estrutura genética humana</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '146',
          ordination: 11,
        },
        {
          id: 158,
          enunciated: '<strong>Busco ativamente por alternativas para minha trajetória profissional</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '147',
          ordination: 12,
        },
        {
          id: 159,
          enunciated: '<strong>Consigo resolver problemas difíceis</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '148',
          ordination: 13,
        },
        {
          id: 160,
          enunciated: '<strong>Gosto/gostaria de apresentar argumentos para um juri</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '149',
          ordination: 14,
        },
        {
          id: 161,
          enunciated: '<strong>Planejo o meu futuro profissional</strong>',
          visible: true,
          questionType: {
            radio: {
              options: [
                {
                  id: 1,
                  optionEnum: '<p>Não tem nada a ver comigo</p>',
                },
                {
                  id: 2,
                  optionEnum: '<p>Tem pouco a ver comigo</p>',
                },
                {
                  id: 3,
                  optionEnum: '<p>Às vezes tem e às vezes não tem a ver comigo</p>',
                },
                {
                  id: 4,
                  optionEnum: '<p>Tem muito a ver comigo</p>',
                },
                {
                  id: 5,
                  optionEnum: '<p>Tem tudo a ver comigo</p>',
                },
              ],
            },
          },
          questionItem: '150',
          ordination: 15,
        },
      ],
    }
  ],
};

export default form;
