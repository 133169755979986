<template>
    <div class="container offset">
        <form @submit.prevent="upsertJovem">
            <div class="custom-form">
                <div class="form-group">
                    <label class="question-style pt-1">Nome do jovem: *</label>
                    <input
                        v-model.trim="jovem.nome"
                        type="name"
                        class="form-control"
                        placeholder="Insira aqui o nome do jovem..."
                        required
                    />
                </div>
            </div>

            <div class="form-group">
                <label class="question-style pt-1">CPF:</label>
                <input
                    class="form-control"
                    v-maska="'###.###.###-##'"
                    type="text"
                    placeholder="xxx.xxx.xxx-xx"
                    v-model="jovem.cpf"
                />
            </div>

            <div class="form-group">
                <label class="question-style">Gênero do jovem:</label>
                <select v-model.trim="jovem.genero" class="form-control">
                    <option class="question-style" value="" selected disabled>
                        Selecione aqui o gênero...
                    </option>
                    <option class="question-style" value="MASCULINO">
                        Masculino
                    </option>
                    <option class="question-style" value="FEMININO">
                        Feminino
                    </option>
                    <option class="question-style" value="OUTRO">Outro</option>
                </select>
            </div>

            <div class="form-group">
                <label class="question-style pt-1">Data de nascimento:</label>
                <input
                    class="form-control"
                    type="date"
                    v-model="jovem.dataNascimento"
                    :max="maxDate"
                    :min="minDate"
                />
            </div>

            <div class="saveButton button-div pt-4">
                <Button
                    section="instituicao-cadastrar"
                    value="Salvar jovem"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="save"
                />
            </div>
        </form>
    </div>
</template>

<script>
import Button from "@components/Button";
import { maska } from "maska";
import { validate } from "gerador-validador-cpf";
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
    components: {
        Button,
    },
    directives: {
        maska,
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            jovem: {
                nome: null,
                cpf: null,
                genero: "",
                dataNascimento: null,
            },
            maxDate: this.getCurrentDate(),
            minDate: this.getMinDate(),
        };
    },
    watch: {
        data() {
            if (this.data.editing) {
                this.jovem.nome = this.data.editing.nome;
                this.jovem.cpf = this.data.editing.cpf;
                this.jovem.genero = this.data.editing.genero || "";
                this.jovem.dataNascimento = this.data.editing.dataNascimento;
            } else {
                this.clearForm();
            }
        },
    },
    computed: {
        jovens() {
            return this.$store.getters.getListJovens;
        },
        validCpf() {
            return validate(this.jovem.cpf);
        },
    },
    methods: {
        clearForm() {
            if (this.data.editing) {
                this.jovem.nome = this.data.editing.nome;
                this.jovem.cpf = this.data.editing.cpf;
                this.jovem.genero = this.data.editing.genero || "";
                this.jovem.dataNascimento = this.data.editing.dataNascimento;
            } else {
                this.jovem.nome = null;
                this.jovem.cpf = null;
                this.jovem.genero = "";
                this.jovem.dataNascimento = null;
            }
        },
        async upsertJovem() {
            if (this.jovem.cpf != null && this.jovem.cpf != "") {
                if (!this.validCpf) {
                    notyf.error("CPF inválido!");
                    return;
                }
            }

            if (this.data.editing) {
                await this.updateJovem();
            } else await this.createJovem();
        },

        async createJovem() {
            let jovemAlreadyExists = this.jovens.some(
                (item) =>
                    item.nome.toLowerCase() === this.jovem.nome.toLowerCase()
            );

            if (!jovemAlreadyExists) {
                if (this.jovem.genero == "") {
                    this.jovem.genero = null;
                }
                if (this.jovem.cpf == "") {
                    this.jovem.cpf = null;
                }
                await this.$store.dispatch("createJovem", {
                    idInstituicao: this.$route.params.idInstituicao,
                    idEmpregador: this.$route.params.idEmpregador,
                    jovem: {
                        ...this.jovem,
                        cpf:
                            this.jovem.cpf != null
                                ? this.jovem.cpf.replace(/\D/g, "")
                                : null,
                    },
                });

                if (this.$store.getters.getIsJovemCreated) {
                    notyf.success("Jovem criado com sucesso");

                    this.$store
                        .dispatch("loadListJovens", {
                            idInstituicao: this.$route.params.idInstituicao,
                            idEmpregador: this.$route.params.idEmpregador,
                        })
                        .then(() => (this.isLoadedInfo = true));

                    this.$emit("closeModal");
                } else {
                    if (this.$store.getters.getJovemCreationError == 409) {
                        notyf.error(
                            "O CPF digitado já se encontra cadastrado. Verifique o campo e tente novamente."
                        );
                        if (this.jovem.genero == null) {
                            this.jovem.genero = "";
                        }
                    } else notyf.error("Erro ao criar jovem");
                }
            } else {
                //se as informações já existirem
                notyf.open({
                    type: "error",
                    message: "Já existe um jovem cadastrado com esse nome.",
                });
            }
        },

        async updateJovem() {
            let jovemAlreadyExists = this.jovens.some(
                (item) =>
                    item.nome.toLowerCase() === this.jovem.nome.toLowerCase() &&
                    item.nome != this.data.editing.nome
            );
            if (!jovemAlreadyExists) {
                if (this.jovem.genero == "") {
                    this.jovem.genero = null;
                }
                if (this.jovem.cpf == "") {
                    this.jovem.cpf = null;
                }

                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idEmpregador: this.$route.params.idEmpregador,
                    idJovem: this.data.editing?.idJovem,
                    jovem: {
                        ...this.jovem,
                        cpf:
                            this.jovem.cpf != null
                                ? this.jovem.cpf.replace(/\D/g, "")
                                : null,
                    },
                };
                await this.$store.dispatch("updateJovem", payload);

                if (this.$store.getters.getIsJovemUpdated) {
                    notyf.success("Jovem atualizado com sucesso");

                    this.$store.dispatch("loadListJovens", {
                        idInstituicao: this.$route.params.idInstituicao,
                        idEmpregador: this.$route.params.idEmpregador,
                    });

                    this.$emit("closeModal");
                } else {
                    if (this.$store.getters.getJovemUpdateError == 409) {
                        notyf.error(
                            "O CPF digitado já se encontra cadastrado. Verifique o campo e tente novamente."
                        );
                        if (this.jovem.genero == null) {
                            this.jovem.genero = "";
                        }
                    } else notyf.error("Erro ao criar jovem");
                }
            } else {
                //se as informações já existirem
                notyf.open({
                    type: "error",
                    message: "Já existe um jovem cadastrado com esse nome.",
                });
            }
        },
        getCurrentDate() {
            const now = new Date();
            const year = now.getFullYear() - 12;
            const month = String(now.getMonth() + 1).padStart(2, "0");
            const day = String(now.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
        getMinDate() {
            const now = new Date();
            const year = now.getFullYear() - 60;
            const month = String(now.getMonth() + 1).padStart(2, "0");
            const day = String(now.getDate()).padStart(2, "0");
            return `${year}-${month}-${day}`;
        },
    },
};
</script>

<style scoped>
.saveButton {
    float: right;
}
</style>
