<template>
<form @submit.prevent v-show="data.campo?.idamostra">
  <div class="container offset">
    <div class="custom-form">
      <div class="form-group">
        <label class="question-style">Nome:</label>
        <input
          :value="titulo"
          type="name"
          class="form-control"
          placeholder="Insira aqui o nome do campo..."
          readonly
          />
      </div>

      <div class="form-group">
        <label class="question-style">Ano:</label>
        <input
          :value="ano"
          type="number"
          min="1900"
          class="form-control"
          placeholder="Insira aqui o ano do campo..."
          readonly
          />
      </div>

      <div class="form-group">
        <label class="question-style">Descrição: *</label>
        <textarea v-model.trim="descricao" class="form-control" rows=5 placeholder="Insira aqui a descrição do campo..." required></textarea>
      </div>
    </div>
    <div class="button-div pt-3">
      <Button
        @click="updateAmostra"
        section="instituicao-campo"
        value="Salvar Campo"
        :isOutline="true"
        :hasIcon="true"
        iconName="save"
        />
    </div>
  </div>
</form>
</template>

<script>
import Button from '@components/Button'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: "UpdateField",
  data() {
    return {
      titulo: "",
      ano: "",
      descricao: "",
      file: null,
    }
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  components: {
    Button,
  },
  watch: {
    data(updated) {
      this.titulo = updated?.campo?.titulo;
      this.ano = updated?.campo?.ano;
      this.descricao = updated?.campo?.descricao;
      this.file = updated?.campo?.termo;
    },
  },
  methods: {
    async updateAmostra() {
      if ((this.nome != '') && (this.ano != '') && (this.descricao != '')) {
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          idAmostra: this.data.campo.idamostra,
          titulo: this.titulo,
          ano: this.ano,
          descricao: this.descricao,
        }

        await this.$store.dispatch('setUpdateAmostra', payload)
        if (!this.$store.getters.getIsAmostraUpdated) {
          notyf.open({
            type: "error",
            message: "Erro ao atualizar campo!",
          });
          return;
        }

        this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
        this.$emit('closeModal')
        notyf.open({
          type: "success",
          message: "Campo atualizado com sucesso!",
        });
      }
    },
    clearForm() {
      this.titulo = this.data?.campo?.titulo;
      this.ano = this.data?.campo?.ano;
      this.descricao = this.data?.campo?.descricao;
      this.file = this.data?.campo?.termo;
    },
  }
}
</script>

<style scoped>
.container{
    padding: 1rem;
    width: 100%;
    background-color: white;
    margin: 0;
}

.title-style{
    font-size: 1.5rem;
    font-weight: 600;
    color: #656565;
}

.word-style{
    color:  #656565;
}

.custom-form{
    margin: 0 auto;
}

.question-style{
    width: 100%;
    font-size: 1rem;
    color: #656565;
}

.button-div{
    text-align: right;
}
</style>
