<template>
    <!-- REMOVENDO AS TEXTURAS -->
    <!-- <div
        class="card cardAnimation"
        :style="{
            '--bg': `url(${imgBgs[paletteColorFulCards]})`,
            '--font': `url(${fontBgs[paletteColorFulCards]})`,
        }"> -->
    <div class="card cardAnimation">
        <div class="colorful-cards-BG justify-content-center" @mouseenter="changeToHoverImage()" @mouseleave="returnsOriginalImage()" :class="{'change-to-hover-img': isChangeToHoverImage, 'change-back-img': isChangeBackImage}">
            
            <div v-if="hasIcon" class="row cardWrapper">
                <i v-if="thumbnail" :class="'fas fa-' + thumbnail" class="icon-info mx-auto cardText"></i>
                <img
                    v-else-if="thumbnailPath"
                    :src="thumbnailPath"
                    class="imgCard"
                    alt="Ícone" />
                <h1 class='title-style cardText'>{{title}}</h1>
            </div>

            <div v-if="hasImage">
                <img :src="imgPath" class="img-info mx-auto">
                <h1 class='title-style img-title-style'>{{title}}</h1>
            </div> 
                            
        </div>
    </div>
</template>

<script>

    export default ({
        name: 'ColorfulCardsBG',
        data() {
            return {
                isChangeToHoverImage: false,
                isChangeBackImage: false,
                paletteColorFulCards: this.palette,
                imgPath: this.imagePath,
                // imgBgs: {
                //     "blue-section": require('@assets/img/Backgrounds/azulMaisClaro.webp'),
                //     "pink-section": require('@assets/img/Backgrounds/roxoMaisClaro.webp'),
                //     "orange-section": require('@assets/img/Backgrounds/laranjaClaroEAPI.webp'),
                //     "red-section": require('@assets/img/Backgrounds/vermelhoClaro.webp'),
                //     "green-section": require('@assets/img/Backgrounds/verdeClaroEAPI.webp'),
                //     "salmon-section": require('@assets/img/Backgrounds/salmao.webp'),
                // },
                // fontBgs: {
                //     "blue-section": require('@assets/img/Backgrounds/azulEapi.webp'),
                //     "pink-section": require('@assets/img/Backgrounds/roxoIntenso.webp'),
                //     "orange-section": require('@assets/img/Backgrounds/laranjaIntenso.webp'),
                //     "red-section": require('@assets/img/Backgrounds/marromEscuro.webp'),
                //     "green-section": require('@assets/img/Backgrounds/verdeEscuro.webp'),
                //     "salmon-section": require('@assets/img/Backgrounds/marromEscuro.webp'),
                // },
            }
        },
        props: {
            hasIcon: Boolean,
            thumbnail: String,
            thumbnailPath: String,
            title: String,
            palette: String,
            hasImage: Boolean,
            imagePath: String,
            hoverImagePath: String
        },
        methods: {
            changeToHoverImage: function() {
                this.isChangeToHoverImage = true
                this.isChangeBackImage = false
                this.imgPath = this.hoverImagePath
            },
            returnsOriginalImage: function() {
                this.isChangeToHoverImage = false
                this.isChangeBackImage = true
                this.imgPath = this.imagePath
            }
        }
    })
</script>

<style scoped>
 .cardText {
     background-image: var(--font);
     background-repeat: repeat;
     background-size: 256px;
     color: #00853B;
     transition: all ease-in-out 0.2s;
 }

 .cardAnimation {
     transition: all ease-in-out 0.2s;
 }

 .cardAnimation:hover {
     transform: scale(1.05);
     transform-origin: center center;
 }

 .card:before {
     transition: all ease-in-out 0.2s;
     content: "";
     background-repeat: repeat;
     position: absolute;
     width: 100%;
     height: 100%;
     top: 0;
     left: 0;
     z-index: 0;
     border-radius: 0.25rem;
     transition: all ease-in-out 0.2s;
 }

.colorful-cards-BG {
    position: relative;
    z-index: 1;
    height: 12rem;  
    width: 14rem;
    /* border-radius: 0.6rem; */
    cursor: pointer;
    -webkit-box-shadow: 12px 12px 23px -7px rgb(158 146 158 / 67%);
    -moz-box-shadow: 12px 12px 23px -7px rgb(158 146 158 / 67%);
    box-shadow: 12px 12px 23px -7px rgb(158 146 158 / 67%);
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
}

.title-style{
    text-align: center;
    font-size: 1.07rem;
    padding: 0;
    margin: 0;
}

.img-title-style{
    padding-top: 2rem;

}
.icon-info{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.img-info{
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 60%;
    height: 5rem;
    padding: 0;
}

.fa, .far, .fas {
    font-size: 5rem;
    height: 6.5rem;
    padding: 0;
    display: flex;
} 

.change-to-hover-img, .change-back-img{
    transition: all ease-in-out 0.2s;
}

 .imgCard {
     height: 5rem;
     margin: 0 auto 1.5rem auto;
     color: var(--font);
 }

 .cardWrapper {
     text-align: center;
 }

</style>
