<template>
    <div class="root-dados">
        <div class="container list-data">
            <!-------------- MODAL CENTRAL PARA ADICIONAR USUÁRIO -------------------->
            <ModalCentral
                componentName="AddUser"
                title="ADICIONAR USUÁRIO"
                idName="AddUsuario"
            />

            <!-------------- MODAL CENTRAL PARA ALTERAR PERMISSÃO DO USUÁRIO -------------------->
            <ModalCentral
                componentName="ChangePermission"
                title="ALTERAR PERMISSÃO"
                idName="AlterarPermissao"
                :data="dataToChangePermission"
            />

            <Breadcrumb
                class="breadcrumb-style"
                :crumbs="crumbs"
                section="instituicao-cadastrar"
            />
            <br />

            <h3 class="title-info">Membros</h3>

            <div class="title-row">
                <InstitutionInfo
                    class="title-style"
                    :title="nomeInstituicao"
                    section="instituicao-cadastrar"
                />
            </div>

            <p class="text-info-personal mt-4 mb-6">
                Nesta seção, você encontrará todos os membros da Instituição.
                Caso necessário, utilize o filtro abaixo para localizar o membro
                desejado e o botão "Convidar membro" para convidar um novo
                integrante.
            </p>

            <div class="lista-data-info">
                <Button
                    v-if="userPerm.permissao != 'APLICADOR'"
                    class="btnInvite"
                    value="Convidar membro"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="user-plus"
                    data-toggle="modal"
                    data-target="#AddUsuario"
                />
            </div>
            <br />

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input
                    class="form-control input-personal"
                    v-model="searchByName"
                    type="text"
                    name="filtro_nome_membros"
                    id="inlineFormCustomName"
                    placeholder="Pesquisar membros por nome..."
                    autocomplete="off"
                />
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator" />

            <!-------------------------------- LISTANDO OS MEMBROS ----------------------------------------------->
            <div v-if="isLoadedInfo">
                <MemberQnts
                    :totalQnt="totalMembros"
                    :filteredQnt="membros?.length"
                />

                <transition tag="div" v-if="membros" name="slide-fade">
                    <div class="table-wrap">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Cargo</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <TransitionGroup tag="tbody" appear name="list">
                                <!-- <ListagemElements :infos="membros" listaType="addMembros"/>  -->
                                <tr
                                    class="table-row"
                                    v-for="membro in membros"
                                    :key="membro.idusuario"
                                >
                                    <td>
                                        <img
                                            v-if="
                                                membro.permissao ==
                                                'ADMINISTRADOR'
                                            "
                                            class="img-info-box-lista"
                                            src="@assets/img/Cards/Coroa.svg"
                                            alt=""
                                        />
                                        <img
                                            v-else-if="
                                                membro.permissao ==
                                                'COORDENADOR'
                                            "
                                            class="img-info-box-lista"
                                            src="@assets/img/Cards/Membro.svg"
                                            alt=""
                                        />
                                        <img
                                            v-else
                                            class="img-info-box-lista"
                                            src="@assets/img/Cards/Lapis.svg"
                                            alt=""
                                        />
                                    </td>

                                    <td>{{ membro.nome }}</td>

                                    <td>{{ membro.permissao }}</td>

                                    <td>{{ membro.email }}</td>

                                    <td>
                                        <div class="controlGroup">
                                            <Button
                                                v-if="
                                                    Number(
                                                        userPerm?.idPermissao
                                                    ) >= membro?.idPermissao &&
                                                    userPerm?.idPermissao !=
                                                        '1' &&
                                                    userInfo?.data?.idUsuario !=
                                                        membro?.idusuario
                                                "
                                                @click="
                                                    sendInfosToChangePermission(
                                                        membro.idusuario,
                                                        membro.permissao,
                                                        membro.nome
                                                    )
                                                "
                                                data-toggle="modal"
                                                data-target="#AlterarPermissao"
                                                class="third-info-box-lista btnLista"
                                                value="Alterar permissão"
                                                :isOutline="true"
                                                :has-icon="true"
                                                iconName="right-left"
                                            />
                                            <Button
                                                v-if="
                                                    Number(
                                                        userPerm?.idPermissao
                                                    ) >= membro?.idPermissao &&
                                                    userPerm?.idPermissao !=
                                                        '1' &&
                                                    userInfo?.data.idUsuario !=
                                                        membro?.idusuario
                                                "
                                                @click="
                                                    deleteUser(
                                                        membro.idusuario,
                                                        membro.nome
                                                    )
                                                "
                                                class="last-info-box-lista btnLista"
                                                value="Excluir"
                                                :isOutline="true"
                                                :has-icon="true"
                                                iconName="link-slash"
                                            />
                                        </div>
                                    </td>
                                </tr>
                            </TransitionGroup>
                        </table>
                    </div>
                </transition>

                <transition v-else>
                    <h2 class="title-style-box-lista">
                        Nenhum resultado encontrado.
                    </h2>
                </transition>
            </div>

            <div v-else>
                <SkeletonList />
            </div>

            <!----------------------------------------------------------------------------------------------------->
        </div>
    </div>
</template>

<script>
import Button from "@components/Button";
import Swal from "sweetalert2";
import ModalCentral from "@components/ModalCentral";
import Breadcrumb from "@components/Breadcrumb";
import SkeletonList from "@components/SkeletonUI/SkeletonList";
import InstitutionInfo from "@dados/VisualizarInstituicao/InstitutionInfo";
import MemberQnts from "@dados/VisualizarInstituicao/MemberQnt";

export default {
    name: "ListarMembros",
    data() {
        return {
            nomeInstituicao: "",
            descricao: "",
            dataToChangePermission: {},
            searchByName: "",
            isLoadedInfo: false,
            teams: [],
            crumbs: [
                {
                    id: 1,
                    value: "",
                    iconName: "th",
                    name_route: "GerenciarInstituicoes",
                },
                {
                    id: 2,
                    value: "Instituição",
                    iconName: "university",
                    name_route: "InstituicaoMenu",
                },
                {
                    id: 3,
                    value: "Cadastrar Informações",
                    iconName: "search-plus",
                    name_route: "InstituicaoMenuCadastrar",
                },
                {
                    id: 4,
                    value: "Membros",
                    iconName: "user-friends",
                    name_route: "",
                },
            ],
        };
    },
    async created() {
        this.teams = this.$store.getters.getListTeams;

        window.scrollTo(0, 0);
        const payload = {
            idInstituicao: this.$route.params.idInstituicao,
        };
        await this.$store.dispatch("setUserPerm", payload);

        this.isLoadedInfo = false;
        await this.$store.dispatch(
            "setListMembros",
            this.$route.params.idInstituicao
        );
        this.isLoadedInfo = true;
    },
    computed: {
        totalMembros() {
            return this.$store.getters.getListMembros?.length;
        },
        membros() {
            var membros = this.$store.getters.getListMembros;
            membros?.forEach((value) => {
                if (value.permissao == "ADMINISTRADOR") {
                    value.idPermissao = 3;
                }
                if (value.permissao == "COORDENADOR") {
                    value.idPermissao = 2;
                }
                if (value.permissao == "APLICADOR") {
                    value.idPermissao = 1;
                }
            });

            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = membros;

            if (this.searchByName.trim() != "") {
                var filtered = membros.filter((membro) =>
                    membro.nome
                        .trim()
                        .toLowerCase()
                        .match(this.searchByName.trim().toLowerCase())
                );

                if (filtered?.length != 0) {
                    query = filtered;
                } else {
                    query = false;
                }
            }

            return query;
        },

        userPerm() {
            return this.$store.getters.getUserPerm;
        },
        userInfo() {
            return this.$store.getters.getUserInfo;
        },
    },

    components: {
        Button,
        ModalCentral,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
        MemberQnts,
    },
    methods: {
        clearForm() {},
        setSelectedValue() {
            this.selectedValue = this.$refs.filtersearchname.value;
        },

        //METODO PARA ALTERAR PERMISSAO DO MEMBRO
        sendInfosToChangePermission: function (idusuario, permissao, nome) {
            this.dataToChangePermission = {
                id: idusuario,
                permissao: permissao,
                nome: nome,
                modalName: "AlterarPermissao",
            };
        },

        //METODO PARA DESVINCULAR MEMBRO
        async deleteUser(idusuario, name) {
            const result = await Swal.fire({
                title: "Desvincular membro!",
                text: "Tem certeza que deseja desvincular " + name + "?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                cancelButtonText: "Cancelar",
                confirmButtonText: "Sim, tenho certeza",
            });

            if (result.isConfirmed) {
                console.log(idusuario);
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUsuario: idusuario,
                };
                await this.$store.dispatch("deleteMember", payload);
                Swal.fire(
                    "Desvinculado!",
                    name + " não faz mais parte dessa instituição.",
                    "success"
                );
                await this.$store.dispatch(
                    "setListMembros",
                    this.$route.params.idInstituicao
                );
            }
        },
    },
    mounted: function () {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao;

        if (typeof idTeamSelected == "undefined") {
            this.nomeInstituicao = "Nome da Instituição";
        } else {
            let teamsSelected;
            let teamSelected;

            teamsSelected = this.teams.filter(
                (team) => team.idinstituicao == idTeamSelected
            );

            teamSelected = [...teamsSelected].shift();

            this.nomeInstituicao = teamSelected.name;
            this.descricao = teamSelected.descricao;
        }
    },
};
</script>

<style scoped>
@import "../../../assets/css/Dados/list.css";

.box-lista {
    background-repeat: repeat;
    background-size: 256px;
    border: 1px solid #952713;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
}

:deep(.box-lista span) {
    font-size: 1.1em;
}

.box-lista span {
    background-clip: text;
    background-repeat: repeat;
    background-size: 256px;
    color: black;
}

.text-info-personal {
    color: #fff;
    font-weight: 450;
    font-size: 1.1rem;
    text-align: justify;
}

.label-filtro {
    font-weight: 600;
}

.title-info {
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
}
.title-style {
    margin: 0;
    padding: 0;
}

h3.title-info {
    margin-top: 1.6rem;
    font-size: 1.25rem;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s;
}

a:hover {
    color: #4d4f50;
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}

@media screen and (max-width: 680px) {
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }

    .box-lista span,
    .box-lista a {
        width: 100%;
        text-align: center;
        margin-top: 0.5rem;
    }
    .box-lista {
        place-content: center;
    }
}

.img-info-box-lista {
    padding: 10px;
}

.btnInvite {
    box-sizing: border-box;
    border: none;
}

:deep(.btnInvite div) {
    background-clip: text;
    background-repeat: repeat;
    background-size: 256px;
    color: #00853b;
    padding: 0.5rem 0;
    transition: 0.4s;
}

:deep(.btnLista div) {
    color: #fff;
    text-align: center;
}

.btnInvite,
.btnLista {
    background-color: white;
    text-align: center;
}

.title-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.input-group {
    border: 2px solid #00853b;
    width: auto;
    border-radius: 8px;
}

input {
    border-style: solid;
    box-shadow: inset 0px 0px 0px 0px red;
}

.input-group-append {
    text-decoration: none;
    border-radius: 8px;
    background: #fff;
}

/* LIST TRANSITION */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
