//---------------------------------------------
// Grupos Module 
//---------------------------------------------
// Módulo para gerenciar as ações do grupo

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    groups: [],
    membrosGroup: [],
    locationByCep: {},
    isMemberCreated: null,
    memberCreationMsg: "",
    areInstitutionsUnlinked: false,
    listInstis: [],
    groupLeft: null,
    groupDeleted: null,
    groupPerm: {},
    groupUpdated: null,
    listAllGroups: [],
    groupCreated: null,
})

// MUTATIONS
const mutations = {
    setListGroups(state, payload){
        state.groups = payload
    },

    setListAllGroups(state, payload){
        state.listAllGroups = payload
    },

    setListMembrosGrupo(state, payload){
        state.membrosGroup = payload
    },

    setLocationByCep(state, payload) {
        state.locationByCep = payload
    },

    setMemberCreated(state, payload) {
        state.isMemberCreated = payload
    },

    setMemberCreationMsg(state, payload) {
        state.memberCreationMsg = payload
    },

    setAreInstitutionsUnlinked(state, payload) {
        state.areInstitutionsUnlinked = payload
    },

    setListInstis(state, payload) {
        state.listInstis = payload
    },

    setGroupLeft(state, payload) {
        state.groupLeft = payload
    },

    setGroupDeleted(state, payload) {
        state.groupDeleted = payload
    },

    setGroupPerm(state, payload) {
        state.groupPerm[payload.id] = payload.value
    },

    setGroupUpdated(state, payload) {
        state.groupUpdated = payload
    },

    setGroupCreated(state, payload) {
        state.groupCreated = payload;
    },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DE CRIAR GRUPO
    async setCreateGroup({ commit }, payload)  {
        var formData = new FormData();
        formData.append("nome", payload.nome);
        formData.append("descricao", payload.descricao);
        formData.append("atuacao", payload.atuacao);
        formData.append("rua", payload.rua);
        formData.append("numero", payload.numero);
        formData.append("bairro", payload.bairro);
        formData.append("complemento", payload.complemento);
        formData.append("cep", payload.cep);
        formData.append("idCidade", payload.id_cidade);
        if (payload.imagem) {
            formData.append("imagem", payload.imagem)
        }
        await axios
            .post(`grupo/`, 
            formData,
            {   
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => commit("setGroupCreated", true))
            .catch((error) => {
                console.log(error.message)
                commit("setGroupCreated", false)
            })
    },

    //-- ACTION PERMISSÃO DE USUÁRIO NO GRUPO
    async groupPerm({ commit }, payload) {
        await axios
            .get(`membros_grupo/userPerm/${payload.idGrupo}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit("setGroupPerm", { id: payload.idGrupo, value: response.data.data })
            })
            .catch(err => {
                commit("setGroupPerm", { id: payload.idGrupo, value: null })
                console.log(err)
            })
    },

    //-- ACTION REQUISICAO DOS GRUPOS
    async setListGroups({ commit }) {
        await axios
            .get(`grupo/listUserGrupos`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListGroups', response.data.data)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setListGroups', [])
            })
    },

    //-- ACTION REQUISICAO DE TODOS OS GRUPOS
    async setListAllGroups({ commit }) {
        commit('setListAllGroups', [])
        await axios
            .get(`grupo`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListAllGroups', response.data.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO DO LISTMEMBROS
    async setListMembrosGrupo({ commit }, idGrupo) {
        await axios
            .get(`membros_grupo/${idGrupo}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListMembrosGrupo', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO DE CRIAR MEMBRO DO GRUPO
    async setCreateGroupMember({ commit }, payload)  {
        commit('setMemberCreated', null);
        commit('setMemberCreationMsg', "")

        await axios
            .post(`membros_grupo/${payload.id_grupo}`, {
                idPermissao: payload.idPermissao,
                email: payload.email
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setMemberCreated', true)
                commit('setMemberCreationMsg', response.data.message)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setMemberCreated', false)
            })
    },

     //-- ACTION DELETE GRUPO
     async deleteGrupo({ commit }, payload) {
         commit('setGroupDeleted', null)
        await axios
            .delete(`grupo/${payload.idGrupo}`, {
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => {
                commit('setGroupDeleted', true)
            })
            .catch((error) => {
                commit('setGroupDeleted', false)
                console.log(error.message)
            })
    },

    //-- ACTION DELETE MEMBRO DO GRUPO
    async deleteMembroGrupo(_, payload) {
        await axios
            .delete(`grupo/${payload.idGrupo}`, {
                idUsuario: payload.idUsuario
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION UNLINK INSTITUIÇÃO AO GRUPO
    async unlinkInstis({ commit }, payload) {
        commit('setAreInstitutionsUnlinked', null)

        await axios
            .patch(`grupo/unlinkInsti/${payload.idGrupo}`, {
                ids: payload.instiIds
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => {
                commit('setAreInstitutionsUnlinked', true)
            })
            .catch(err => {
                commit('setAreInstitutionsUnlinked', false)
                console.log(err)
            })
    },

    //-- ACTION INSTITUIÇÕES DO GRUPO
    async loadListInstis({ commit }, payload)  {
        commit('setListInstis', [])
        await axios
            .get(`grupo/listInstiGrupos/${payload.idGrupo}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit("setListInstis", response.data?.data)
            })
            .catch(err => {
                commit("setListInstis", [])
                console.log(err)
            })
    },

    //-- ACTION DEIXAR GRUPO
    async leaveGroup({ commit }, payload) {
        commit("setGroupLeft", null)
        await axios
            .delete(`membros_grupo/sair/${payload.idGrupo}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
                data: {
                    justificativa: payload.justification.value
                },
            })
            .then(response => {
                console.log(response)
                commit('setGroupLeft', true)
            })
            .catch(err => {
                console.log(err)
                commit('setGroupLeft', false)
            })
    },

    //-- ACTION UPDATE GRUPO
    async updateGroup({ commit }, payload) {
        commit("setGroupUpdated", null)
        await axios.put(`grupo/${payload.idGrupo}`, {
            nome: payload.nome,
            descricao: payload.descricao,
            imagem: payload.imagem,
        },{
            headers: {
                'Authorization': `Bearer ${JWT()}`
            }
        })
                   .then(() => {
                       commit("setGroupUpdated", true)
                   })
                   .catch(err => {
                       commit("setGroupUpdated", false)
                       console.log(err)
                   })
    },

    //-- ACTION INFORMAÇÃO COM BASE NO CEP
    async loadLocationByCep({ commit }, payload) {
        commit('setLocationByCep', {})
        await axios
            .get(`https://opencep.com/v1/${payload.cep}.json`)
            .then(response => {
                commit('setLocationByCep', response.data)
            })
            .catch(err => console.log(err))
    }
}

// GETTERS
const getters = {
    getListGroups(state){
        return state.groups || []
    },

    getListAllGroups(state) {
        return state.listAllGroups || []
    },

    getListMembrosGrupo(state){
        try {
            return state.membrosGroup.data
        } catch(e) {
            return []
        }
    },

    getLocationByCep(state) {
        return state.locationByCep
    },

    getMemberCreated(state) {
        return state.isMemberCreated
    },

    getMemberCreationMsg(state) {
        return state.memberCreationMsg
    },

    getAreInstitutionsUnlinked(state) {
        return state.areInstitutionsUnlinked
    },

    getListInstis(state) {
        return state.listInstis
    },

    getLeftGroup(state) {
        return state.groupLeft
    },

    getGroupDeleted(state) {
        return state.groupDeleted
    },

    getGroupPerm: (state) => (id) => {
        return state.groupPerm[id]
    },

    getGroupUpdated(state) {
        return state.groupUpdated
    },

    getGroupCreated(state) {
        return state.groupCreated;
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
