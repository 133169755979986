<template>
<div class="root-dados">
  <ModalCentral
    componentName="AddEmpregador"
    :title="empregadorEdit == null ? 'Adicionar empregador' : 'Atualizar empregador'"
    idName="AddEmpregador"
    :data="{ editing: empregadorEdit, listEmpregadores: rawListEmpregadores }"
  />

  <div class="container list-data">
    <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-aplicacoes"/>
    <br/>

    <div class="title-row">
      <InstitutionInfo class="title-style" :title="nomeInsti" section="instituicao-cadastrar"/>
    </div>

    <p class="text-info-personal">Nesta seção, você encontrará todos os Empregadores relacionadas à Empresa. Caso necessário, utilize o filtro abaixo para localizar o Empregador desejado.</p>

    <template v-if="userPerm.permissao != 'APLICADOR'">
      <div class="lista-data-info">
        <Button
          v-if="campoStatus != 'OFF'"
          @click="setSelectAllItems"
          class="btn"
          section="instituicao-campo"
          value="Marcar Todos"
          :isOutline="true"
          :hasIcon="true"
          iconName="check-square"
          style="height: min-content;"
          />

        <Button
          v-if="campoStatus != 'OFF'"
          @click="setUnselectAllItems"
          class="btn ml-2"
          section="instituicao-campo"
          value="Desmarcar Todos"
          :isOutline="true"
          :hasIcon="true"
          iconName="square"
          style="height: min-content;"
          />

        <Button
          v-if="campoStatus != 'OFF'"
          @click="applyChanges"
          class="btn ml-2"
          section="instituicao-campo"
          value="Aplicar Alterações"
          :isOutline="true"
          :hasIcon="true"
          iconName="check"
          style="height: min-content;"
          />

        <Button
          v-if="campoStatus != 'OFF'"
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Adicionar empregador"
          @click="empregadorEdit = null"
          :isOutline="true"
          :hasIcon="true"
          iconName="plus"
          data-toggle="modal"
          data-target="#AddEmpregador"
          />
      </div>
      <br />
    </template>

    <div class="input-group">
      <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar empregador de uma empresa por nome..." autocomplete="off">
      <div class="input-group-append">
        <span class="input-group-text">Nome</span>
      </div>
    </div>
    <hr class="separator">

    <div v-if="isLoadedInfo">
      <MemberQnts
        :totalQnt="totalEmpregadores"
        term="empregadores"
        :filteredQnt="empregadoresFiltered?.length || 0"
        icon="building" />

      <div class="list-box">
        <transition-group name="slide-fade" v-if="empregadoresFiltered?.length > 0">
          <div class = "table-wrap">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col">  </th>
                  <th scope="col">Nome</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr class="table-row" v-for="empregador in empregadoresFiltered" :key="empregador.counter">
                  <td>
                    <img  class="img-info-box-lista" src="@assets/img/Dados/ListarInstis/img.png" alt="Ícone de empregador">
                  </td>

                  <td> {{ empregador.nome }} </td>

                  <td>
                    <div class="controlGroup">
                      <div v-if="userPerm.idPermissao != '1'" :class="campoStatus == 'OFF'? 'not-clickable' : ''">
                        <div v-if="empregador.isEditionLocked">Selecionado</div>
                        <div v-else>
                          <div @click="setIsSelected(empregador)" class="select-info">
                            <div :class="['box-select-info mr-2 ',{'selected-info': empregador.isSelect.isSelected}]"></div>
                            <span class="span-label-selected" v-if="empregador.isSelect.isSelected">Selecionado</span>
                            <span class="span-label-selected" v-else>Selecionar</span>
                          </div>
                        </div>
                      </div>

                      <router-link :to="{ name: 'CampoListaJovens', params: { idEmpregador: empregador.idEmpregador } }">
                        <Button
                          class="orange-text"
                          value="Gerenciar jovens"
                          section="instituicao-aplicacoes"
                          :isOutline="true"
                          :hasIcon="true"
                          iconName="user-alt"
                          />
                      </router-link>

                      <Button
                        v-if="!empregador.isEditionLocked && campoStatus != 'OFF'"
                        @click="empregadorEdit = empregador"
                        class="orange-text"
                        value="Atualizar empregador"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="pen"
                        data-toggle="modal"
                        data-target="#AddEmpregador"
                        />

                      <Button
                        v-if="!empregador.isDeletionLocked && campoStatus != 'OFF'"
                        @click="removeEmpregador(empregador)"
                        class="orange-text"
                        value="Excluir empregador"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="trash"
                        />
                    </div>
                  </td>

                </tr>
              </tbody>
            </table>
          </div>
        </transition-group>

        <transition-group v-else>
          <h2 class="title-style-box-lista">Nenhuma empregador encontrado.</h2>
        </transition-group>
      </div>

    </div>

    <div v-else>
      <SkeletonList/>
    </div>

  </div>
</div>
</template>

<script>
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral';
import Swal from 'sweetalert2';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
  components: {
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    MemberQnts,
    Button,
    ModalCentral,
  },
  data: () => ({
    searchByName: '',
    isLoadedInfo: null,
    empregadorEdit: null,
    empregadores: [],
    empregadores_amostra: [],
    crumbs: [
      {
        id: 1,
        value: '',
        iconName: 'th',
        name_route: 'GerenciarInstituicoes'
      },
      {
        id: 2,
        value: 'Instituição',
        iconName: 'university',
        name_route: 'InstituicaoMenu'
      },
      {
        id:3,
        value: 'Campos',
        iconName: 'th-large',
        name_route: 'ListarCampos'
      },
      {
        id:4,
        value: 'Gerenciar Campo',
        iconName: 'chart-pie',
        name_route: 'InstituicaoMenuCampo'
      },
      {
        id:5,
        value: 'Empresas',
        iconName: 'building',
        name_route: 'CampoListaEmpresas'
      },
      {
        id:5,
        value: 'Empregadores',
        iconName: 'user-tie',
        name_route: ''
      },
    ],
  }),
  watch: {
    rawListEmpregadores() { this.setData() },
    rawListCampoEmpregadores() { this.setData() },
  },
  async beforeMount() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao,
      idAmostra: this.$route.params.idAmostra,
      idEmpresa: this.$route.params.idEmpresa,
    };

    this.$store.dispatch('setUserPerm', payload);
    this.$store.dispatch('setListCampos', this.$route.params.idInstituicao);
    this.loadData();
  },
  computed: {
    userPerm() { return this.$store.getters.getUserPerm; },
    teams() {
      return this.$store.getters.getListTeams;
    },
    nomeInsti() {
      return this
        .teams
        .find(insti =>
          insti.idinstituicao == this.$route.params.idInstituicao
        )?.name;
    },
    _empregadores() { return this.$store.getters.getListEmpregadores; },
    totalEmpregadores() { return this._empregadores?.length || 0; },
    empregadoresFiltered() {
      let query = this.empregadores;

      if (this.searchByName.trim() != '') {
        let filtered = query?.filter(empregador => empregador.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
        if (filtered?.length != 0)
          query = filtered;
        else
          query = false;
      }

      return query;
    },
    rawListEmpregadores() { return this.$store.getters.getListEmpregadores },
    rawListCampoEmpregadores() { return this.$store.getters.getListCampoEmpregadores },
    campos() {
      return this.$store.getters.getListCampos;
    },
    campoStatus() {
      return this.campos.find(campo => campo.idamostra == this.$route.params.idCampo)?.isativo || null;
    },
  },
  methods: {
    setIsSelected(empregador) {
      empregador.isSelect.isSelected = !empregador.isSelect.isSelected;
      empregador.isSelect.isSaved = false;
    },
    setSelectAllItems() {
      this.empregadores.forEach(empregador => {
        empregador.isSelect.isSelected = true;
        empregador.isSelect.isSaved = false;
      });
    },
    setUnselectAllItems() {
      this.empregadores.forEach(empregador => {
        empregador.isSelect.isSelected = false;
        empregador.isSelect.isSaved = false;
      });
    },
    async applyChanges() {
      if (this.campusStatus == 'ON') {
        const result = await Swal.fire({
          title: "Tem certeza que deseja aplicar as alterações?",
          text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, tenho certeza",
        });

        if (!result.isConfirmed)
          return;
      }

      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo,
        idEmpresa: this.$route.params.idEmpresa,
        addIds: this.empregadores.filter(empregador => empregador.isSelect.isSelected).map(empregador => empregador.idEmpregador),
        removeIds: this.empregadores.filter(empregador => empregador.isSelect.isSelected == false).map(empregador => empregador.idEmpregador),
      };
      await this.$store.dispatch('setManageEmpregadores', payload);

      if (!this.$store.getters.getIsEmpregadoresManaged) {
        notyf.error("Erro ao aplicar alterações");
        return;
      }

      Swal.fire(
        'Alterações aplicadas!',
        'As seleções foram aplicadas com sucesso.',
        'success'
      );

      this.loadData()
    },

    async removeEmpregador(empregador) {
      const result = await Swal.fire({
        title: 'Deseja mesmo excluir o empregador?',
        text: 'Ao excluir, você não terá mais acesso às informações do empregador.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir empregador",
      })

      if (result.isConfirmed) {
        await this.$store.dispatch('removeEmpregador', {
          idInstituicao: this.$route.params.idInstituicao,
          idEmpresa: this.$route.params.idEmpresa,
          idEmpregador: empregador.idEmpregador,
        });

        if (this.$store.getters.getIsEmpregadorRemoved) {
          notyf.success("Empregador excluído com sucesso");
          this.loadData();
        } else
          notyf.error("Erro ao excluir empregador");
      }
    },
    setData() {
      this.empregadores = this.rawListEmpregadores || []
      this.empregadores_amostra = this.rawListCampoEmpregadores || []

      this.empregadores.forEach(empregador => {
        const found = this?.empregadores_amostra.find(empregador_amostra => empregador_amostra.idEmpregador == empregador.idEmpregador);
        empregador.isSelect = {
          isSelected: found ? true : false,
          isSaved: true
        };
        empregador.idempregadoramostra = found?.idempregadoramostra;
      });

      this.isLoadedInfo = true;
    },
    async loadData() {
      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo,
        idEmpresa: this.$route.params.idEmpresa,
      };

      this.$store.dispatch('setUserPerm', payload);
      await this.$store.dispatch('loadListEmpregadores', payload);
      await this.$store.dispatch('loadListCampoEmpregadores', payload);
      this.isLoadedInfo = true;
    },
  },
};
</script>

<style scoped>
div {
    color: currentColor;
}

.not-clickable{
    cursor: not-allowed;
}

.not-clickable > div{
    pointer-events: none;
}
</style>
