<template>
    <div class="container offset">
        <form @submit.prevent="createUnidade">
        <div class="custom-form">
            <p class="text-justify">Para tornar mais eficiente o método de inserção das unidades educacionais da sua instituição, nós criamos um método de inserção por arquivo CSV. Para isso, baixe o nosso arquivo de modelo e edite seguindo o mesmo padrão para adicionar novas unidades e turmas.</p>
            <a href="/examples/entenda_modelo_unidade.xlsx" download="Dicionario_Modelo_Unidades" target="_blank">Entenda o modelo do CSV.</a><br/>
            <a href="/examples/professor_data.csv" download="Modelo_Unidades_Educacionais_CSV" target="_blank">Baixar planilha de modelo para <b>Unidades Educacionais</b>.</a>
            <div class="input-file">    
                <label class="question-style">Arquivo CSV:</label>
                <div v-cloak @click="openDialog" @drop.prevent="addFile" @dragover.prevent @dragover.capture="isDragOverActions()" @dragleave.capture="isDragLeaveActions()" class="drag-and-drop" :class="{'drag-style': isDragOver}">
                    <div v-if="arquivo == null" class="text-center infos">
                        <img class="img-fluid img-center-input-file" :src="require('@assets/img/Dados/ExportarDevolutiva/'+name_image+'.png')">
                        <p class="label-center-input-file">{{label}}</p>
                    </div>
                    <div class="file-uploaded" v-else>
                        <img class="img-icon-uploaded" src="@assets/img/Dados/ExportarDevolutiva/img-icon.png" alt="">
                        {{ arquivo.name.substr(0, 7)+'...'}} {{(arquivo.size/1000).toFixed(1)+ ' KB'}}     
                    </div>
                </div>
                <button v-if="arquivo != null" class="remove-file" @click="removeFile()" title="Remove">Remover Arquivo</button>
                <input tabindex="-1" @change="addFileViaInput" type="file" ref="uploadFile" placeholder="Escolha ou arraste um arquivo para gerar a devolutiva" hidden>
            </div>
            
            <div class="button-div pt-4">
                <Button 
                    section="instituicao-cadastrar"
                    value="Salvar UEs"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="save"
                /> 
            </div>

        </div>
        </form>
    </div>
</template>

<script>

import Button from '@components/Button'
import Swal from "sweetalert2";
 // import Encoding from 'encoding-japanese';
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
    name: "AddEducationalUnit",
    data() {
        return {
            csv_file: '',
            label_button: 'Cadastrar Unidades',
            isTurningCog: false,
            arquivo: null,
            isDragOver: false,
            name_image: "icone-upload",
            label: "Escolha ou arraste um arquivo para cadastrar",
        }
    },
    methods: {
        // METODOS DO FILE INPUT IMAGE
        isDragOverActions: function() {
            this.isDragOver = true
            this.label = "Solte o arquivo aqui!"
            this.name_image = "icone-upload-happy"
        },
        isDragLeaveActions: function() {
            this.isDragOver = false
            this.label = "Escolha ou arraste um arquivo para gerar a devolutiva"
            this.name_image = "icone-upload"
        },
        geraDevolutiva: function() {
            //método com funções de demonstração
            if (this.arquivo != null){

                this.label_button = 'Gerando Devolutiva...'
                this.isTurningCog = true  
                
                setTimeout( () => { 
                    this.label_button = 'Gerar Devolutiva'
                    this.isTurningCog = false
                    this.arquivo = null
                    this.$refs.uploadFile.value = ''
                    Swal.fire(
                        'Devolutiva Gerada!',
                        'O download começará imediatamente!',
                        'success'
                    )
                }, 3000)
            } 
            else {
                Swal.fire(
                    'Erro!',
                    'Você precisa fazer o upload de um arquivo CSV para gerar a devolutiva.',
                    'error'
                )   
            }
        },
        loadFile(file) {
            // let reader = new FileReader();
            // reader.onload = (e) => {
            //     if (!["UNICODE", "UTF8"].includes(Encoding.detect(e.target.result))) {
            //         Swal.fire(
            //             'Erro!',
            //             'O arquivo deve estar formatado em UTF-8/UNICODE para inserçao',
            //             'error'
            //         );
            //         return;
            //     }
            //     this.arquivo = file;
            // };
            // reader.onerror = (e) => {
            //     console.log(e);
            //     Swal.fire(
            //         'Erro!',
            //         'Erro na leitura do arquivo',
            //         'error'
            //     );
            // };
            // reader.readAsText(file);
            this.arquivo = file;
        },
        addFile: function(e) {
            let droppedFiles = e.dataTransfer.files;
            this.isDragLeaveActions()
            for (let f of droppedFiles) {
                if (f.type == "text/csv" || f.type == "application/vnd.ms-excel") {
                    this.loadFile(f);
                } else {
                    Swal.fire(
                        'Erro!',
                        'Só é permitido arquivos com extensão ".csv"',
                        'error'
                    )
                }
            }     
        },
        addFileViaInput: function() {
            let droppedFiles = this.$refs.uploadFile.files;
            for (let f of droppedFiles) {
                if (f.type == "text/csv" || f.type == "application/vnd.ms-excel") {
                    this.loadFile(f);
                } else {
                    Swal.fire(
                        'Erro!',
                        'Só é permitido arquivos com extensão ".csv"',
                        'error'
                    )
                }
            }  
        },
        removeFile(){
            this.arquivo = null; 
        },
        openDialog: function() {
            this.$refs.uploadFile.value = ''
            this.$refs.uploadFile.click() 
        },


        clearForm() {       
            this.csv_file = ''
            this.label_button = 'Cadastrar Unidades'
            this.isTurningCog = false
            this.arquivo = null
            this.isDragOver = false
            this.name_image = "icone-upload"
            this.label = "Escolha ou arraste um arquivo para cadastrar"
        },

        async createUnidade() {
            if (this.arquivo != null){
                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    file: this.arquivo,
                }

                const payload2 = {
                    idInstituicao: this.$route.params.idInstituicao
                } 

                await this.$store.dispatch('setSendUEFileWithTurmas', payload)
                await this.$store.dispatch('setListUE', payload2)

                if (this.$store.getters.getIsCreateUnidadeWithTurmasCSV.success) {
                    this.clearForm()
                    this.$emit('closeModal')
                    notyf.open({
                        type: "success",
                        message: "Unidades e turmas adicionadas com sucesso!",
                    });
                } else{
                    this.clearForm()
                    notyf.open({
                        type: "error",
                        className: "preformatedNotyf",
                        message: this.$store.getters.getIsCreateUnidadeWithTurmasCSV.message,
                    });
                }
            }
            else {
                notyf.open({
                    type: "error",
                    message: "Você precisa escolher um arquivo CSV válido.",
                });
            }     
        }
    },
    components: {
        Button
    }
    
}

</script>

<style>
 .preformatedNotyf {
     white-space: pre-wrap;
 }
</style>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;        
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .image-style{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding: 1rem;
    }

    .button-div{
        text-align: right;
    }

    /* INPUT FILE STYLES */

    .input-file {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 40vh;
    position: relative;
}

.input-file input {
    max-width: 30rem;
}

.input-file h1 {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    color: #0d9f9f;
}

.input-file button {
    border: 1px solid #0d9f9f;
    color: #0d9f9f;
    background-color: white;
    border-radius: 4px;
    padding: 0.4rem 1.2rem 0.4rem 0.4rem;
    font-weight: bold;
    cursor: pointer;
    outline: none;
    transition: all ease-in-out 0.2s;
    -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
    box-shadow: 6px 6px 5px -1px rgba(0,0,0,0.2);
}

.input-file button:hover {
    color: white; 
    background-color: #0d9f9f;
}

.drag-and-drop {
    height: 14rem;
    width: 27rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
    padding: 0 1.5rem;
    margin: 0 2rem;

    border: 4px dashed white;
    background:
        linear-gradient(#fff,#fff) padding-box,
        url("~@/assets/img/Backgrounds/verdeEscuroEAPI.png") border-box;
}

.drag-and-drop img {
    max-height: 5rem;
}

.drag-and-drop p {
    color: #656565;
    font-weight: 400;
    user-select: none;
}

.drag-style {
    filter: blur(0.7px);
}

.file-uploaded {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -2.5rem;
}

.animateCog {
    animation: cogAnimation linear infinite 1s;
}

.infos {
    pointer-events: none;
}

.input-file .remove-file {
    border: 1px solid #fd5454;
    color: #fd5454;
    padding: 0.4rem;
    margin-top: 0.2rem;
    font-size: 0.9rem;
    position: absolute;
    bottom: 24%;
}

.input-file .remove-file:hover {
    color: white; 
    background-color: #fd5454;
}

@keyframes cogAnimation {
    to {
        transform: rotate(360deg);
    }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

@media screen and (max-width: 728px) {
    .drag-and-drop{
        width: auto;
        margin: 0 1rem;
    }
}

.img-icon-uploaded{
    max-height: 17% !important;
    width: auto;
}

.item-checkbox label{
    /* white-space: nowrap; */
    display: contents;
    cursor: pointer;
    user-select: none;
}

.item-checkbox input {
    margin-right: 0.2rem;
    cursor: pointer;
}

</style>
