//---------------------------------------------
// Formulários Module 
//---------------------------------------------
// Módulo para gerenciar as ações dos formulários

import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listFormularios: [],
    listFormItems: null,
    formDeleted: null,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DO LISTFORMS
    setListFormularios(state, payload) {
        state.listFormularios = payload
    },
    setListFormItems(state, items) { state.listFormItems = items; },
    setFormDeleted(state, payload) { state.formDeleted = payload; },
}

// ACTIONS
const actions = {
      //-- ACTION REQUISICAO DO LISTFORMS
    async setListFormularios({ commit }, payload) {
        await axios
            .get(`formularios/${payload?.idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
                params:{
                    'idFormulario[]' : payload?.idFormulario
                }
            })
            .then(response => {
                commit('setListFormularios', response.data)
            })
            .catch((error) => console.log(error.message))
        return true;
    },

    async setFormItems({ commit }, payload) {
        commit('setListFormItems', []);
        await axios
            .get(`formularios/${payload?.idInstituicao}?idFormulario[]=${payload?.idFormulario}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`,
                },
            })
            .then(response => {
                commit('setListFormItems', JSON.parse(response.data.data?.at(0)?.formulario));
            })
            .catch((error) => {
                console.log(error.message);
                commit('setListFormItems', null);
            });
    },

    //-- ACTION DELETE FORMULARIO 
    async deleteFormulario({ commit }, payload) {
        commit("setFormDeleted", null);
        await axios
          .delete(`formularios/${payload.idInstituicao}/${payload.idFormulario}`, {
            headers: {
              Authorization: `Bearer ${JWT()}`,
            },
          })
          .then(() => commit("setFormDeleted", true))
          .catch((error) => {
            console.log(error.message);
            commit("setFormDeleted", false);
          });
      }
}

// GETTERS
const getters = {
    getListFormularios(state) {
        try {
            return state.listFormularios.data
        } catch (e) {
            return []
        }
    },
    getListFormItems(state) {
        return state.listFormItems;
    },
    getFormDeleted(state) {
        return state.formDeleted;
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
