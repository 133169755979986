<template>
    <Teleport to="body">
        <div>
            <div :ref="idName" class="modal fade" data-backdrop="static" :id="idName" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title titulo-modal" id="exampleModalLabel" v-html="title"></h5>
                        <div v-show="!disableClose" ref="elementCloseModalCentral" data-dismiss="modal" class="close-container" @click="resetData">
                            <div class="leftright"></div>
                            <div class="rightleft"></div>
                        </div>                
                    </div>
                    <div class="modal-body">
                        <component ref="component" @closeModal="closeModalCentral" :is="componentName" :data="data" :slides="slides" @emit="emit"></component>
                    </div>
                    </div>
                </div>
            </div>
        </div>    
    </Teleport>
</template>

<script>

import AddUser from '@forms/AddUser'
import AddEducationalUnit from '@forms/AddEducationalUnit'
import AddEducationalUnitByFile from '@forms/AddEducationalUnitByFile'
import AddEducationalUnitByFileAmostra from '@forms/AddEducationalUnitByFileAmostra'
import AddField from '@forms/AddField'
import UploadEgressos from '@forms/UploadEgressos'
import AddClass from '@forms/AddClass'
import AddProfessor from '@forms/AddProfessor'
import AddClassByFile from '@forms/AddClassByFile'
import ChangePermission from '@forms/ChangePermission'
import AddStudent from '@forms/AddStudent'
import AddEmpresa from '@forms/AddEmpresa'
import AddEmpregador from '@forms/AddEmpregador'
import AddJovem from '@forms/AddJovem'
import UploadEmpresa from '@forms/UploadEmpresa'
import AddEgresso from '@forms/AddEgresso'
import Carousel from '@components/Carousel'
import SearchInstitution from '@forms/SearchInstitution'
import CreateInstitution from '@forms/CreateInstitution'
import UpdateInstitution from '@forms/UpdateInstitution'
import CreateGroup from '@forms/CreateGroup'
import Profile from '@forms/Profile'
import Information from '@forms/Information'
import InstiRequests from '@forms/InstiRequests'
import ShowCampoInfo from '@forms/ShowCampoInfo'
import UserInvites from '@forms/UserInvites'
import AddPDF from '@forms/AddPDF'
import AddVideo from '@forms/AddVideo'
import EditPDF from '@forms/EditPDF'
import EditVideo from '@forms/EditVideo'
import InviteUserGroup from '@forms/InviteUserGroup'
import AddInstitutionGroup from '@forms/AddInstitutionGroup'
import LeaveGrupo from '@forms/LeaveGrupo'
import UpdateGrupo from '@forms/UpdateGrupo'
import GroupInstiRequests from '@forms/GroupInstiRequests'
import GroupUserRequests from '@forms/GroupUserRequests'
import SearchGroup from '@forms/SearchGroup'
import EditCard from '@components/MateriaiseDocumentos/EditCard'
import GroupInvites from '@forms/GroupInvites'
import PopupRating from '@components/BoasPraticas/PopupAvaliar'
import PopupDownload from '@components/BoasPraticas/PopupDownload'
import CreatePratica from '@components/BoasPraticas/CreatePratica'
import UpdatePratica from '@components/BoasPraticas/UpdatePratica'
import UpdateSectionsPraticas from '@forms/UpdateSectionsPraticas'
import UpdateCategoriesPraticas from '@forms/UpdateCategoriesPraticas'
import TransicaoInitModal from '@components/TransicaoEscolaTrabalho/InitModal'
import CpfCuringa from '@components/TransicaoEscolaTrabalho/CpfCuringa'
import UpdateUE from '@forms/UpdateUE'
import UpdateTurma from '@forms/UpdateTurma'
import InfoQuestoes from '@components/TransicaoEscolaTrabalho/InfoQuestoes'
import CreateForm from '@forms/CreateForm'
import UpdateField from '@forms/UpdateField'

export default {
    name: 'ModalCentral',
    components:{
        AddUser,
        AddEducationalUnit,
        AddEducationalUnitByFile,
        AddEducationalUnitByFileAmostra,
        AddField,
        UploadEgressos,
        AddClass,
        AddProfessor,
        AddClassByFile,
        ChangePermission,
        Carousel,
        SearchInstitution,
        CreateInstitution,
        UpdateInstitution,
        CreateGroup,
        UpdateField,
        Profile,
        Information,
        InstiRequests,
        ShowCampoInfo,
        UserInvites,
        AddPDF,
        AddVideo,
        EditPDF,
        EditVideo,
        PopupDownload,
        PopupRating,
        CreatePratica,
        UpdatePratica,
        UpdateSectionsPraticas,
        UpdateCategoriesPraticas,
        InviteUserGroup,
        AddInstitutionGroup,
        LeaveGrupo,
        UpdateGrupo,
        GroupInstiRequests,
        GroupUserRequests,
        SearchGroup,
        EditCard,
        GroupInvites,
        TransicaoInitModal,
        CpfCuringa,
        AddStudent,
        AddEmpresa,
        AddEmpregador,
        AddJovem,
        UploadEmpresa,
        AddEgresso,
        UpdateUE,
        UpdateTurma,
        InfoQuestoes,
        CreateForm,
    },
    props: {
        componentName: String,
        title: String,
        idName: String,
        data: Object,
        hasButtonLeft: Boolean,
        hasButtonRight: Boolean,
        slides: Object,
        textured: Boolean,
        disableClose: {
            required: false,
            default: false,
        },
        canReset: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    data() {
        return {
            visibleWatcher: null,
        };
    },
    methods: {
        closeModalCentral(){
            this.$refs.elementCloseModalCentral.click();
        },
        emit(){
            this.$emit('emit');
        },
        resetData(){
            if (this.$refs.component?.clearForm) this.$refs.component?.clearForm();
        },
        dispatch(fName, ...args) {
            return this.$refs.component[fName](...args);
        }
    },
    mounted() {
        this.visibleWatcher = new MutationObserver(mutation => {
            for (const mutated of mutation) {
                if (mutated.target.classList.contains('show'))
                    this.$nextTick(() => { document.documentElement.style.overflow = 'hidden' })
                else
                    this.$nextTick(() => { document.documentElement.style.overflow = 'auto' })
            }
        })

        this.visibleWatcher.observe(this.$refs[this.idName], {
            attributes: true
        });
    },
    beforeUnmount() { this.visibleWatcher.disconnect() }
}
</script>

<style scoped>

    .titulo-modal{
        margin: 0;
        font-weight: 800;
    }

    .close-container{
        position: relative;
        margin-right: 0;
        width: 30px;
        height: 30px;
        cursor: pointer;
        box-shadow: none !important;
    }

    .close button{
        box-shadow: none !important;  
    }

    .leftright{
        height: 2.5px;
        width: 20px;
        position: absolute;
        margin-top: 12px;
        background-color: #414141;
        border-radius: 2px;
        transform: rotate(45deg);
        transition: all .3s ease-in;
    }

    .rightleft{
        height: 2.5px;
        width: 20px;
        position: absolute;
        margin-top: 12px;
        background-color: #414141;
        border-radius: 2px;
        transform: rotate(-45deg);
        transition: all .3s ease-in;
    }

    .close-container:hover .leftright{
        transform: rotate(-45deg);
        background-color: #F25C66;
    }
    .close-container:hover .rightleft{
        transform: rotate(45deg);
        background-color: #F25C66;
    }
    

    .modal-body{
        /* height: 50vh; */
        overflow: overlay;
    }
    .modal-body::-webkit-scrollbar {
        width: 0.5rem;
    }
    
    .modal-body::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        border-radius: 10px;
    }
    
    .modal-body::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--dark); 
    }
</style>
