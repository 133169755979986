<template>
    <div class="tiptapOuter">
        <div class="buttons">
            <i class="fas fa-bold"
               @click="editor?.chain().focus().toggleBold().run()"
               :class="{ 'active': editor?.isActive('bold') }"
            />
            <i class="fas fa-italic"
               @click="editor?.chain().focus().toggleItalic().run()"
               :class="{ 'active': editor?.isActive('italic') }"
            />
            <i class="fas fa-underline"
               @click="editor?.chain().focus().toggleUnderline().run()"
               :class="{ 'active': editor?.isActive('underline') }"
            />
            <i class="fas fa-list-ol"
               @click="editor?.chain().focus().toggleOrderedList().run()"
               :class="{ 'active': editor?.isActive('orderedList') }"
            />
        </div>
        <editor-content class="tiptap" :editor="editor"/>
    </div>
</template>

<script type="text/javascript">
 import { Editor, EditorContent } from '@tiptap/vue-3';
 import OrderedList from '@tiptap/extension-ordered-list';
 import ListItem from '@tiptap/extension-list-item';
 import Placeholder from '@tiptap/extension-placeholder';
 import Underline from '@tiptap/extension-underline';
 import StarterKit from '@tiptap/starter-kit';

 export default {
     name: "MarkdownEditor",
     components: {
         EditorContent,
     },
     data() {
         return {
             editor: null,
         };
     },
     props: {
         text: {
             type: String,
             required: true,
         },
     },
     methods: {
     },
     watch: {
         text(updated) {
             this.editor.commands.setContent(updated || "");
         },
     },
     mounted() {
         this.editor = new Editor({
             content: this.text || "",
             editorProps: {
                 attributes: {
                     class: "tiptapInner",
                 },
             },
             parseOptions: {
                preserveWhitespace: "full"
             },
             extensions: [
                 StarterKit,
                 ListItem.configure({ HTMLAttributes: { class: 'listItemExtension' } }),
                 OrderedList.configure({ HTMLAttributes: { class: 'innerTipTapList' } }),
                 Underline,
                 Placeholder.configure({
                     placeholder: "Corpo...",
                 }),
             ],
         });


        this.editor.on("blur", ({ editor }) => {
            this.$emit("change", editor.getHTML());
        });

     },
     beforeUnmount() {
         this.editor.destroy();
     },
 };
</script>

<style type="text/css" media="screen">
.ProseMirror * {
  white-space: pre-wrap;
  word-wrap: break-word;
}

 .tiptapInner {
     outline: none;
     box-sizing: border-box;
     width: 100%;
 }

 .tiptapInner p {
     margin: 0;
 }

 .innerTipTapList {
     list-style-position: inside;
 }

 .innerTipTapList p {
     display: inline;
 }

 p.is-editor-empty::before {
     content: attr(data-placeholder);
     color: lightgray;
 }
</style>


<style type="text/css" media="screen" scoped>
 .tiptapOuter {
     border-radius: 8px;
     border: 1px solid rgba(0, 0, 0, 0.11);
     width: 100%;
     grid-template-columns: 1fr;
     grid-template-rows: auto;
     box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.25);
 }

 .tiptapOuter > .buttons {
     width: 100%;
     padding: 5px;
     box-shadow: 1px 2px 6px -3px rgba(0, 0, 0, 0.25);
     display: flex;
     justify-content: space-around;
     flex-wrap: wrap;
 }

 .buttons > i {
     border-radius: 5px;
     border: 1px solid rgba(0, 0, 0, 0.25);
     padding: 5px;
     cursor: pointer;
     transition: 0.4s;
     flex: 0 0 max-content;
 }

 .buttons > i:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }

 i.active {
     background-color: rgba(0, 0, 0, 0.25);
 }

 .tiptap {
     width: 100%;
     padding: 10px;
     box-sizing: border-box;
 }

 .tiptap b, strong{
    color: #00853B !important;
    font-weight: 600;
}
</style>
