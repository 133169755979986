<template>
<div class="root-dados">

    <ModalCentral
        componentName="AddProfessor"
        :title="profEditing == null ? 'ADICIONAR PROFESSORES' : 'EDITAR PROFESSOR'"
        idName="AddProf"
        :data="{ editing: profEditing, listProfessores: professores}"
        />

    <div class="container list-data">

        <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
        <h3 class="title-info"> Lista de professores da UE </h3>

        <div class="title-row">
            <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
        </div>

        <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todas os professores pertencentes à essa Unidade Educacional. Caso necessário, utilize o filtro abaixo para localizar o professor desejada.</p>

        <div class="lista-data-info">
            <Button
                v-if="userPerm?.permissao != 'APLICADOR'"
                @click="profEditing = null"
                class="btn mr-2 btnCtrl"
                value="Adicionar professores"
                :isOutline="true"
                :hasIcon="true"
                iconName="chalkboard-teacher"
                data-toggle="modal"
                data-target="#AddProf"
                />
        </div>

        <br />

        <div class="input-group">
            <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_professor" id="inlineFormCustomName" placeholder="Pesquisar professor por nome..." autocomplete="off">
            <div class="input-group-append">
                <span class="input-group-text">Nome</span>
            </div>
        </div>

        <hr class="separator">

        <div v-if="isLoadedInfo">

            <transition v-if="professores_filtered?.length > 0" name="slide-fade">
                <div class = "table-wrap">
                    <table class="table mt-3">
                        <thead>
                            <tr>
                                <th scope="col">  </th>
                                <th scope="col">Nome</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <TransitionGroup name="list" appear tag="tbody">
                            <tr class="table-row" v-for="professor in professores_filtered" :key="professor.idProfessor">

                                <td> <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-turmas.svg" alt=""> </td>

                                <td> {{professor.nome}} </td>

                                <td>
                                    <div class="controlGroup">
                                        <Button 
                                            v-if="!professor.isEditionLocked"
                                            section="instituicao-campo"
                                            value="Editar"
                                            @click="profEditing = professor"
                                            :isOutline="true"
                                            :hasIcon="true"
                                            iconName="pen"
                                            data-toggle="modal"
                                            data-target="#AddProf"
                                            />

                                        <Button
                                            v-if="!professor.isDeletionLocked"
                                            @click="removeProfessor(professor.idProfessor)"
                                            section="descartar"
                                            value="Excluir"
                                            :isOutline="true"
                                            :hasIcon="true"
                                            iconName="trash"
                                            />
                                    </div>
                                </td>

                            </tr>
                        </TransitionGroup>
                    </table>
                </div>
            </transition>

            <transition v-else>
                <h2 class="title-style-box-lista">Nenhum professor encontrado.</h2>
            </transition>

        </div>

        <div v-else>
            <SkeletonList/>
        </div>

    </div>

</div>
</template>

<script>
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral'
import Swal from 'sweetalert2';
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: "ListarProfessores",
  components: {
    Button,
    ModalCentral,
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
  },
  data() {
    return {
      searchByName: '', 
      nomeInstituicao: null,
      descricao: null,
      isLoadedInfo: false,
      profEditing: null,
      crumbs: [
        {
          id:1,
          value: '',
          iconName: 'th',
          name_route: 'GerenciarInstituicoes'
        },
        {
          id:2,
          value: 'Instituição',
          iconName: 'university',
          name_route: 'InstituicaoMenu'
        },
        {
          id:3,
          value: 'Cadastrar Informações',
          iconName: 'search-plus',
          name_route: 'InstituicaoMenuCadastrar'
        },
        {
          id:4,
          value: 'Unidades Educacionais',
          iconName: 'graduation-cap',
          name_route: 'ListarUnidadesEducacionais'
        },
        {
          id:5,
          value: 'Professores',
          iconName: 'chalkboard-teacher',
          name_route: ''
        }
      ],
    };
  },
  computed: {
    teams() { return this.$store.getters.getListTeams; },
    professores() { return this.$store.getters.getListProfessores; },
    userPerm() { return this.$store.getters.getUserPerm; },
    professores_filtered() {
      var query = this.$store.getters.getListProfessores || [];

      if (this.searchByName.trim() !== '') {
        let filtered = this.professores.filter(professor => professor.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()));

        if (filtered?.length != 0) query = filtered;
        else query = false
      }

      return query;
    },
  },
  async created() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao,
      idUnidade: this.$route.params.idUnidade
    };

    this.$store.dispatch('setUserPerm', payload)
    this.$store.dispatch('setListProfessores', payload).then(() => this.isLoadedInfo = true);
  },
  async mounted() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao,
      idUnidade: this.$route.params.idUnidade
    };
    await this.$store.dispatch('setListTurmas', payload);

    let idTeamSelected = this.$route.params.idInstituicao;

    if (!idTeamSelected) this.nomeInstituicao = "NÃO DEFINIDO";
    else {
      let teamsSelected,
          teamSelected;

      teamsSelected = this.teams.filter(team => team.idinstituicao == idTeamSelected);

      teamSelected = [...teamsSelected].shift();

      this.nomeInstituicao = teamSelected.name;
      this.descricao = teamSelected.descricao;
    }
  },
  methods: {
    async removeProfessor(idProfessor) {
      const result = await Swal.fire({
        title: 'Deseja mesmo excluir o professor?',
        text: 'Ao excluir, você não terá mais acesso às informações do professor.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir professor",
      })
      if (result.isConfirmed) {
        const payload = {
          idInstituicao: this.$route.params.idInstituicao,
          idUnidade: this.$route.params.idUnidade,
          idProfessor
        };

        await this.$store.dispatch('setRemoveProfessor', payload);
        if (this.$store.getters.getIsProfessorRemoved) {
          notyf.success("Professor excluído com sucesso");
          this.$store.dispatch('setListProfessores', payload).then(() => this.isLoadedInfo = true);
        } else {
          notyf.error("Erro ao excluir o professor, tente novamente");
        }
      }
    },
  },
}
</script>

<style scoped>
div {
    color: black;
}

.btnCtrl {
    background-color: white;
    text-align: center;
    box-sizing: border-box;
    border: none;
}

:deep(.btnCtrl div) {
    background-clip: text;
    background-repeat: repeat;
    background-size: 256px;
    color: #00853B;
    padding: 0.5rem 0;
    transition: 0.4s;
}

/* LIST TRANSITION */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
