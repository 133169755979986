<template>
    <div class="container offset">
        <form @submit.prevent="createTurma" ref="formClass">
        <div class="custom-form">

            <div class="form-group">
                <label class="question-style pt-1">Nome da turma: *</label>
                <input
                    v-model.trim="classData.nome"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o nome da turma..."
                    required
                />
            </div>
            <div class="form-group">
                <label class="question-style">Código/Número da turma:</label>
                <input
                    v-model.trim="classData.codigo"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o código da turma..."
                />
            </div>

             <div class="form-group">
            <label class="question-style">Período da turma: *</label>
            <select v-model.trim="classData.periodo" class="form-control" required>
                <option hidden class="question-style"> Selecione aqui o período</option>
                <option class="question-style" value="19">Matutino</option>
                <option class="question-style" value="20">Vespertino</option>
                <option class="question-style" value="21">Noturno</option>
                <option class="question-style" value="22">Integral</option>
                <option class="question-style" value="23">Intermediário</option>
            </select>
            </div>

            <div class="form-group">
            <label class="question-style pt-1">Tipo da turma: *</label>
            <select v-model.trim="classData.tipo" class="form-control" required>
                <option hidden class="question-style"> Selecione aqui o tipo da turma</option>
                <option class="question-style" value="25">Regular</option>
                <option class="question-style" value="26">Multisseriada/Mista</option>
            </select>
            </div>

            <div class="form-group">
                <label class="question-style pt-1">Série da turma: *</label>
                <input
                    v-model.trim="classData.idade"
                    type="name"
                    class="form-control"
                    placeholder="Insira a série ou ano da turma..."
                    required
                />
            </div>

        </div>
        <div class="formMsg"
             :class="{'formMsgSuccess': lastAdd.length, 'formMsgError': !lastAdd.length}">
            Última Turma adicionada: '{{ lastAdd }}'
        </div>

        <div class="formButtons">
            <div class="button-div pt-4">
                <Button
                    value="Limpar"
                    section="descartar"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="trash"
                    type="button"
                    @click="clearForm"
                />
            </div>
            <div class="button-div pt-4">
                <Button 
                    section="instituicao-cadastrar"
                    value="Salvar Turma"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="save"
                    type="submit"
                /> 
            </div>
        </div>

    </form> 
    </div>
</template>

<script>

import Button from '@components/Button'
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
    name: "AddUser",
    props: {
        data: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
          classData: {  
          nome: '',
          codigo: '',
          periodo: '',
          idade: '',
          tipo: '',
          },
          lastAdd: ''

        }
    },
    methods: {
        async createTurma() {
            let turmaAlreadyExists = this.data.some(item => {

                let newP;
                if(item.periodo == 'MATUTINO'){
                    newP = 19;
                } else if(item.periodo == 'VESPERTINO'){
                    newP = 20;
                } else if(item.periodo == 'NOTURNO'){
                    newP = 21;
                }else if(item.periodo == 'INTEGRAL'){
                    newP = 22;
                } else if(item.periodo == 'INTERMEDIARIO'){
                    newP = 23;
                }
                
                return (
                item.nome.toLowerCase() === this.classData.nome.toLowerCase() &&
                item.codigoturma === this.classData.codigo &&
                newP == this.classData.periodo &&
                item.tipo_turma == this.classData.tipo &&
                item.idade == this.classData.idade
                ) 
            });

            // se nao houver turmas ou se as informações não existirem em outra turma
            if(this.data.length == 0 || !turmaAlreadyExists){

                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUnidade: this.$route.params.idUnidade,
                    nome: this.classData.nome,
                    codigoTurma: this.classData.codigo,
                    tipoPeriodo: this.classData.periodo,
                    tipoIdade: this.classData.idade,
                    tipoTurma: this.classData.tipo
                }

                const payload2 = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idUnidade: this.$route.params.idUnidade
                }

                await this.$store.dispatch('setCreateTurma', payload)
                await this.$store.dispatch('setListTurmas', payload2)
                if (this.$store.getters.getIsTurmaCreated){
                    this.lastAdd = this.classData.nome;
                    notyf.open({
                        type: "success",
                        message: "Turma adicionada com sucesso!",
                    });
                    this.clearForm();
                } else {
                    notyf.open({
                        type: "error",
                        message: "Erro na criação da turma!",
                    });
                }
            } else {
                //se as informações já existirem em outra turma
                notyf.open({
                    type: "error",
                    message: "Já existe uma turma criada com estas informações, verifique os campos e tente novamente.",
                });
            }
        },
        clearForm() {
            Object.keys(this.classData).map((prop)=>this.classData[prop] = '' )
        }
    },
    components: {
        Button
    }
    
}

</script>

<style scoped>

    .container{
        width: 100%;
        background-color: white;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;        
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .image-style{
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
        padding: 1rem;
    }

    .button-div{
        text-align: center;
    }
    
    .formMsg {
        margin-top: 5px;
    }
    .formMsgSuccess {
        color: green;
    }
    .formMsgError {
        display: none;
    }
    .formButtons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto;
        align-content: space-around;
    }
</style>
