<template>
  
    <div
      id="carouselControlsComponent"
      class="carousel slide"
      data-ride="carousel"
      data-interval="false"
    >
      <!------- *** 3 SLIDES *** --------->
      <div class="carousel-inner">
        <div
          class="carousel-item"
          v-for="(slide, index) in slides"
          :key="slide.id"
          :class="{ active: index === 0 }"
        >
          <div class="carousel-wrapper">
            <h3 class="font-weight-bold">{{ slide.title }}</h3>
            <div class="dflex text-center">
              <img v-if="slide.image"
                class="my-3 img-modal-carousel"
                :src="slide.image"
                :alt="slide.alt ? slide.alt : 'Sem descrição'"
              />
              <img v-else
                class="my-3 img-modal-carousel"
                src="@assets/img/Dados/no-matches.png"
                alt="Placeholder"
              />
            </div>
            <p>{{ slide.text }}</p>

            <div
              class="d-flex"
              :class="{
                'justify-content-end': index == 0,
                'justify-content-between': index != 0,
              }">

              <Button
                value="Anterior"
                iconName="chevron-left"
                section="painel-outline"
                :isOutline="true"
                :hasIcon="true"
                :hasShadow="false"
                :class="{'hidden-button': index == 0}"
                href="#carouselControlsComponent"
                data-slide="prev"
              />
              <Button
                value="Próximo"
                iconName="chevron-right"
                section="painel-outline"
                :isOutline="true"
                :hasIcon="true"
                :hasShadow="false"
                :isReverse="true"
                :class="{'hidden-button': index == Object.keys(slides).length - 1}"
                href="#carouselControlsComponent"
                data-slide="next"
              />
              <Button
                value="Concluído"
                iconName="check-circle"
                section="painel-outline"
                :isOutline="true"
                :hasIcon="true"
                :hasShadow="false"
                :class="{'hidden-button': index != Object.keys(slides).length - 1}"
                data-dismiss="modal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  
</template>

<script>
import Button from "@components/Button";

export default {
  name: "Carousel",

  components: {
    Button,
  },

  props: {
    slides: Object,
  },
};
</script>

<style scoped>

.carousel-wrapper{
  min-height: 26rem;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

p {
  text-align: justify;
}
.img-modal-carousel {
  width: 70%;
  text-align: center;
}

.hidden-button {
  display: none;
}

.show-button {
  display: inline-block;
}

.align-next {
  display: block;
  justify-self: flex-end !important;
}
</style>
