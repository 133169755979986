//---------------------------------------------
// Membros Module 
//---------------------------------------------
// Módulo para gerenciar as ações dos membros

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
// var qs = require('qs');
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listMembros: [],
    userPerm: {},

    //VERIFICACAO DE ERRO
    isAlreadyRegisteredUser: false,
    leaveInstiErr: null,
    leaveInstiCode: null,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DO LISTMEMBROS
    setListMembros(state, payload) {
        state.listMembros = payload
    },

    //-- MUTATION PERMISSAO DO USER
    setUserPerm(state, payload) {
        state.userPerm = payload
    },

    //-- MUTATION VERIIFCAR SE O USER JA FOI CADASTRADO
    setIsAlreadyRegisteredUser(state, payload) {
        state.isAlreadyRegisteredUser = payload
    },

    setLeaveInstiErr(state, err) { state.leaveInstiErr = err },
    setLeaveInstiCode(state, code) { state.leaveInstiCode = code },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DE CRIAR MEMBRO 
    async setCreateMember({commit}, payload) {
        await axios
            .post(`membros/${payload.idInstituicao}`, {
                    idPermissao: payload.idPermissao,
                    email: payload.email
                },{
           
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .then(() => {
                commit('setIsAlreadyRegisteredUser', false)
            })
            .catch((error) => {
                commit('setIsAlreadyRegisteredUser', true)
                console.log(error.message)
            })
    },


     //-- ACTION REQUISICAO DE ATUALIZAR MEMBRO 
     async setUpdateMember(_, payload) {
        await axios
            .put(`membros/${payload.idInstituicao}`, {
                    idUsuario: payload.idUsuario,
                    idPermissao: payload.idPermissao,
                },{
           
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                })
            .catch((error) => {
                console.log(error.message)
            })
    },

    //-- ACTION REQUISICAO DO LISTMEMBROS
    async setListMembros({ commit }, idInstituicao) {
        await axios
            .get(`membros/${idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListMembros', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO PARA SABER A PERMISSAO DO MEMBRO
    async setUserPerm({ commit }, payload) {
        await axios
            .get(`membros/userPerm/${payload.idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setUserPerm', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE MEMBRO
    async deleteMember(_, payload) {
        await axios
            .delete(`membros/${payload.idInstituicao}`, 
            {
                data: {
                    idUsuario: payload.idUsuario
                },
                
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DEIXAR INSTI
    async deixarInsti({ commit }, payload) {
        await axios
            .delete(`membros/sair/${payload.idInstituicao}`,
                {data: {
                    justificativa: payload.justificativa.value
                },
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }}, 
            )
            .then(() => {
                commit('setLeaveInstiErr', false)
                commit('setLeaveInstiCode', 200)
            })
            .catch((error) => {
                console.log(error)
                commit('setLeaveInstiErr', error?.response?.data?.message?.message)
                commit('setLeaveInstiCode', error?.response?.data?.code)
            })
    },
}

// GETTERS
const getters = {
    getListMembros(state) {
        try {
            return state.listMembros.data
        } catch (e) {
            return []
        }
    },

    getUserPerm(state) {
        try {
            return state.userPerm.data
        } catch (e) {
            return {}
        }
    },

    getIsAlreadyRegisteredUser(state) {
        return state.isAlreadyRegisteredUser
    },

    getLeaveInstiErr(state) { return state.leaveInstiErr },
    getLeaveInstiCode(state) { return state.leaveInstiCode },
}

export default {
    state,
    mutations,
    actions,
    getters
}
