import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
const JWT = () => {
  return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO");
};

const state = () => ({
  listProfessores: [],
  isProfessorCreated: null,
  isProfessorUpdated: null,
  isProfessorRemoved: null,
});

const mutations = {
  setListProfessores(state, list) {
    state.listProfessores = list;
  },
  setIsProfessorCreated(state, v) {
    state.isProfessorCreated = v;
  },
  setIsProfessorUpdated(state, v) {
    state.isProfessorUpdated = v;
  },
  setIsProfessorRemoved(state, v) {
    state.isProfessorRemoved = v;
  },
}

const actions = {
  async setListProfessores({ commit }, payload)  {
    commit('setListProfessores', null);
    await axios
      .get(`professores/${payload.idInstituicao}/${payload.idUnidade}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`,
        }
      })
      .then(response => {
        commit('setListProfessores', response.data?.data);
      })
      .catch((error) => {
        console.log(error)
        commit('setIsTurmaCreated', null);
      });
  },

  async setCreateProfessor({ commit }, payload) {
    commit('setIsProfessorCreated', null);

    await axios
      .post(`professores/${payload.idInstituicao}/${payload.idUnidade}`, payload, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(() => {
        commit('setIsProfessorCreated', true);
      })
      .catch((error) => {
        console.log(error);
        commit('setIsProfessorCreated', false);
      });
  },

  async setUpdateProfessor({ commit }, payload) {
    commit('setIsProfessorUpdated', null);

    await axios
      .put(`professores/${payload.idInstituicao}/${payload.idUnidade}/${payload.idProfessor}`, payload, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(() => {
        commit('setIsProfessorUpdated', true);
      })
      .catch((error) => {
        console.log(error);
        commit('setIsProfessorUpdated', false);
      });
  },

  async setRemoveProfessor({ commit }, payload) {
    commit('setIsProfessorRemoved', null);

    await axios
      .delete(`professores/${payload.idInstituicao}/${payload.idUnidade}/${payload.idProfessor}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(() => {
        commit('setIsProfessorRemoved', true);
      })
      .catch((error) => {
        console.log(error);
        commit('setIsProfessorRemoved', false);
      });
  },
}

const getters = {
  getListProfessores(state) { return state.listProfessores; },
  getIsProfessorCreated(state) { return state.isProfessorCreated; },
  getIsProfessorUpdated(state) { return state.isProfessorUpdated; },
  getIsProfessorRemoved(state) { return state.isProfessorRemoved; },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
