<template>
<div class="root-dados">
  <ModalCentral
    componentName="AddEmpresa"
    :title="empresaEdit == null ? 'Adicionar empresa' : 'Atualizar empresa'"
    idName="AddEmpresa"
    :data="{ editing: empresaEdit, listEmpresas: empresas}"
    />

  <ModalCentral
    componentName="UploadEmpresa"
    title="Upload de lista de empresas"
    idName="UploadEmpresa"
    />

  <div class="container list-data">
    <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-aplicacoes"/>
    <br/>

    <div class="title-row">
      <InstitutionInfo class="title-style" :title="nomeInsti" section="instituicao-cadastrar"/>
    </div>

    <p class="text-info-personal">Nesta seção, você encontrará todas as Empresas relacionadas à Instituição. Caso necessário, utilize o filtro abaixo para localizar a Empresa desejada.</p>

    <template v-if="userPerm.permissao != 'APLICADOR'">
      <div class="lista-data-info">
        <Button
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Importar arquivo"
          :isOutline="true"
          :hasIcon="true"
          iconName="file-upload"
          data-toggle="modal"
          data-target="#UploadEmpresa"
          />

        <Button
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Adicionar empresa"
          @click="empresaEdit = null"
          :isOutline="true"
          :hasIcon="true"
          iconName="plus"
          data-toggle="modal"
          data-target="#AddEmpresa"
          />
      </div>
      <br />
    </template>

    <div class="input-group">
      <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar empresas de uma instituição por nome..." autocomplete="off">
      <div class="input-group-append">
        <span class="input-group-text">Nome</span>
      </div>
    </div>
    <hr class="separator">

    <div v-if="isLoadedInfo">
      <MemberQnts
        :totalQnt="totalEmpresas"
        term="empresas"
        :filteredQnt="empresasFiltered?.length || 0"
        icon="building" />

      <div class="list-box">
        <transition name="slide-fade" v-if="empresasFiltered?.length > 0">
          <div class = "table-wrap">
            <table class="table mt-3">
              <thead>
                <tr>
                  <th scope="col">  </th>
                  <th scope="col">Nome</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <TransitionGroup name="list" appear tag="tbody">
                <tr class="table-row" v-for="empresa in empresasFiltered" :key="empresa.counter">
                  <td>
                    <img  class="img-info-box-lista" src="@assets/img/Dados/ListarInstis/img.png" alt="Ícone de empresa">
                  </td>

                  <td> {{ empresa.nome }} </td>

                  <td>
                    <div class="controlGroup">
                      <Button
                        v-if="!empresa.isEditionLocked"
                        @click="empresaEdit = empresa"
                        class="orange-text"
                        value="Atualizar empresa"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="pen"
                        data-toggle="modal"
                        data-target="#AddEmpresa"
                        />

                      <router-link :to="{ name: 'ListarEmpregadores', params: { 'idEmpresa': empresa.idEmpresa } }">
                        <Button
                          class="orange-text"
                          value="Gerenciar empregadores"
                          section="instituicao-aplicacoes"
                          :isOutline="true"
                          :hasIcon="true"
                          iconName="user-tie"
                        />
                      </router-link>

                      <Button
                        v-if="!empresa.isDeletionLocked"
                        @click="removeEmpresa(empresa)"
                        class="orange-text"
                        value="Excluir empresa"
                        section="instituicao-aplicacoes"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="trash"
                        />
                    </div>
                  </td>

                </tr>
              </TransitionGroup>
            </table>
          </div>
        </transition>

        <transition v-else>
          <h2 class="title-style-box-lista">Nenhuma empresa encontrada.</h2>
        </transition>
      </div>

    </div>

    <div v-else>
      <SkeletonList/>
    </div>

  </div>
</div>
</template>

<script>
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
  components: {
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    MemberQnts,
    Button,
    ModalCentral,
  },
  data: () => ({
    searchByName: '',
    isLoadedInfo: null,
    empresaEdit: null,
    crumbs: [
      {
        id: 1,
        value: '',
        iconName: 'th',
        name_route: 'GerenciarInstituicoes'
      },
      {
        id: 2,
        value: 'Instituição',
        iconName: 'university',
        name_route: 'InstituicaoMenu'
      },
      {
        id: 3,
        value: 'Cadastrar Informações',
        iconName: 'search-plus',
        name_route: 'InstituicaoMenuCadastrar'
      },
      {
        id: 4,
        value: 'Empresas',
        iconName: 'building',
        name_route: ''
      }
    ],
  }),
  async beforeMount() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao
    };

    this.$store
        .dispatch('loadListEmpresas', payload)
        .then(() => this.isLoadedInfo = true);

    this.$store.dispatch('setUserPerm', payload);
  },
  computed: {
    userPerm() { return this.$store.getters.getUserPerm; },
    teams() {
      return this.$store.getters.getListTeams;
    },
    nomeInsti() {
      return this
        .teams
        .find(insti =>
          insti.idinstituicao == this.$route.params.idInstituicao
        )?.name;
    },
    empresas() { return this.$store.getters.getListEmpresas; },
    totalEmpresas() { return this.empresas?.length || 0; },
    empresasFiltered() {
      let query = this.empresas;

      if (this.searchByName.trim() != '') {
        let filtered = query?.filter(empresa => empresa.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
        if (filtered?.length != 0)
          query = filtered;
        else
          query = false;
      }

      return query;
    },
  },
  methods: {
    async removeEmpresa(empresa) {
      const result = await Swal.fire({
        title: 'Deseja mesmo excluir a empresa?',
        text: 'Ao excluir, você não terá mais acesso às informações da empresa.',
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir empresa",
      })
      if (result.isConfirmed) {
        await this.$store.dispatch('removeEmpresa', {
          idInstituicao: this.$route.params.idInstituicao,
          idEmpresa: empresa.idEmpresa,
        });

        if (this.$store.getters.getIsEmpresaRemoved) {
          notyf.success("Empresa excluída com sucesso");

          this.$store
              .dispatch('loadListEmpresas', { idInstituicao: this.$route.params.idInstituicao })
              .then(() => this.isLoadedInfo = true);
        } else
          notyf.error("Erro ao excluir empresa");
      }
    },
  },
};
</script>

<style scoped>
div {
    color: currentColor;
}

/* LIST TRANSITION */
.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
