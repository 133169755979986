// IMPORTACAO DOS MODULOS
import SecureLS from "secure-ls";
import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import amostras from "./modules/amostras";
import aplicacoes from "./modules/aplicacoes";
import auth from "./modules/auth";
import boasPraticas from './modules/boasPraticas';
import cidades from "./modules/cidades";
import egressos from './modules/egressos';
import empregadores from './modules/empregadores';
import empresas from './modules/empresas';
import erros from "./modules/erros";
import estudantes from './modules/estudantes';
import formularios from "./modules/formularios";
import grupos from "./modules/grupos";
import instituicao from "./modules/instituicao";
import jovens from './modules/jovens';
import materiaisDocumentos from "./modules/materiais-documentos";
import membros from "./modules/membros";
import modalLateral from "./modules/modalLateral";
import notificacao from "./modules/notificacao";
import professores from "./modules/professores";
import transicao from './modules/transicao';
import turmas from "./modules/turmas";
import unidades from "./modules/unidades";

const ls = new SecureLS({ isCompression: false });

export default createStore({
  modules: [
    auth,
    modalLateral,
    transicao,
    amostras,
    aplicacoes,
    cidades,
    erros,
    formularios,
    grupos,
    instituicao,
    materiaisDocumentos,
    membros,
    notificacao,
    turmas,
    unidades,
    boasPraticas,
    estudantes,
    empresas,
    empregadores,
    jovens,
    egressos,
    professores,
  ],

  plugins: [
    createPersistedState({
      // paths: [""], // PERMITE O SALVAMENTO DOS ESTADOS EM CACHE CRIPTOGRAFADO
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
});
