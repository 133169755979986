<template>
<div class="container offset">
  <form @submit.prevent="upsertEmpregador">
    <div class="custom-form">
      <div class="form-group">
        <label class="question-style pt-1">Nome do empregador: *</label>
        <input
          v-model.trim="nome"
          type="name"
          class="form-control"
          placeholder="Insira aqui o nome do empregador..."
          required
          />
      </div>
    </div>

    <div class="saveButton button-div pt-4">
      <Button
        section="instituicao-cadastrar"
        value="Salvar empregador"
        :isOutline="true"
        :hasIcon="true"
        iconName="save"
        :isDisabled="isLoading"
        />
    </div>
  </form>
</div>
</template>

<script>
import Button from '@components/Button';
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    }
});

export default {
  components: {
    Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    nome: '',
    actualListEmpregadores: null,
    isLoading: false,
  }),
  watch: {
    data(updated) {
      if (!updated.editing) this.nome = '';
      else this.nome = updated?.editing.nome;

      if(this.data.listEmpregadores){
        this.actualListEmpregadores = this.data.listEmpregadores
      } 
      else { this.actualListEmpregadores = [];}
    },
  },
  methods: {
    async upsertEmpregador() {
      this.isLoading = true;
      if (!this.data.editing){
        await this.createEmpregador();
      }
      else { await this.updateEmpregador();}
      this.isLoading = false;
    },
    async createEmpregador() {
      //se houver outros empregadores com o mesmo nome
      let empregadorAlreadyExists = this.actualListEmpregadores.some(item => (
        item.nome.toLowerCase() === this.nome.toLowerCase())
      );

      if(!empregadorAlreadyExists){
        await this.$store.dispatch('createEmpregador', {
          idInstituicao: this.$route.params.idInstituicao,
          idEmpresa: this.$route.params.idEmpresa,
          nome: this.nome,
        });

        if (this.$store.getters.getIsEmpregadorCreated) {
          notyf.success("Empregador criado com sucesso");
          this.nome = '';

          this.$store
              .dispatch('loadListEmpregadores', {
                idInstituicao: this.$route.params.idInstituicao,
                idEmpresa: this.$route.params.idEmpresa,
              })
              .then(() => this.isLoadedInfo = true);

          this.$emit('closeModal');
        } else
          notyf.error("Erro ao criar empregador");
      } else {
        //se as informações já existirem
        notyf.open({
        type: "error",
        message: "Já existe um empregador cadastrado com essas informações, verifique os campos e tente novamente.",
        });
      }
    },
    async updateEmpregador() {
      //se houver outras empresas com o mesmo nome e se o nome for diferente do seu atual
      let empregadorAlreadyExists = this.actualListEmpregadores.some(item => (
        item.nome.toLowerCase() === this.nome.toLowerCase() && item.nome != this.data.editing.nome)
      );

      if(!empregadorAlreadyExists){
        await this.$store.dispatch('updateEmpregador', {
          idInstituicao: this.$route.params.idInstituicao,
          idEmpresa: this.$route.params.idEmpresa,
          idEmpregador: this.data.editing?.idEmpregador,
          nome: this.nome,
        });

        if (this.$store.getters.getIsEmpregadorUpdated) {
          notyf.success("Empregador atualizado com sucesso");
          this.nome = '';

          this.$store
              .dispatch('loadListEmpregadores', {
                idInstituicao: this.$route.params.idInstituicao,
                idEmpresa: this.$route.params.idEmpresa,
              });

          this.$emit('closeModal');
        } else
          notyf.error("Erro ao atualizar empregador");
      } else {
        //se as informações já existirem
        notyf.open({
        type: "error",
        message: "Já existe um empregador cadastrado com essas informações, verifique os campos e tente novamente.",
        });
      }
    },
    clearForm() {
      if (!this.data.editing) this.nome = '';
      else this.nome = this.data.editing.nome;
    }
  },
};
</script>

<style scoped>
.saveButton {
    float: right;
}
</style>
