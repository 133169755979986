<template>
<div class="outer">
  <div class="questionCard" v-show="question.visible || gotoVisible">
    <div class="questionTitle">
      <h5>
        <span v-if="!blocked">Questão {{ question.questionItem }}:&nbsp;</span>
        <span v-html="question.enunciated"></span>
      </h5>
      <template v-if="!blocked">
        <i v-if="answerValid" class="fas fa-check check" />
        <i v-else class="fa-solid fa-exclamation"></i>
      </template>
    </div>
    <div class="questionBody">
      <!-- RADIO -->
      <div v-if="questionType === 'radio'" class="radioGroup group">
        <div
          v-for="option in (questionValues?.options || [])"
          :key="option.id"
          class="radioEntry entry">
          <input
            :disabled="blocked"
            type="radio"
            @change="registerAnswer(option.id); handleGotos(option.id);"
            :name="`question${question.uuid}`"
            :value="option.id"
            :checked="answer == option.id" />
          <span v-html="option.optionEnum" />
        </div>
      </div>

      <!-- NUM -->
      <div v-else-if="questionType === 'num'" class="numEntry">
        <input
          :disabled="blocked"
          @input="registerAnswer(answerTmp)"
          type="number"
          :name="`question${question.uuid}`"
          :maxlength="questionValues?.maxLength || -1"
          min="0"
          placeholder="Digite aqui o valor..."
          v-model.trim="answerTmp" />
      </div>

      <!-- LIST -->
      <div v-else-if="questionType === 'list'" class="group">
        <div v-if="visibleList(questionValues?.value).length < maxList">
          <div
            v-for="option in visibleList(questionValues?.value)"
            :key="option.id"
            class="radioEntry entry">
            <input
              type="radio"
              :disabled="blocked || option.name === 'selecione'"
              @change="registerAnswer(option.id, option?.linkedKey, option?.keyNext); handleGotos(option.id); handleKeyNext(option, questionValues?.value); registerIdType(option);"
              :value="option.id"
              :name="`question${question.uuid}`"
              :checked="answer == option.id" />
            <span v-html="option.name" />
          </div>
        </div>

        <div class="multiselectContainer" v-else>
          <multiselect
            style="font-size: 1.2em;"
            v-if="allLoaded"
            :disabled="blocked"
            class="multiselect"
            :options="zip(visibleList(questionValues?.value))"
            :close-on-select="true"
            :allow-empty="false"
            placeholder="Selecione um valor..."
            :show-labels="false"
            label="label"
            track-by="value"
            @select="(opt, _) => { registerAnswer(opt.value, opt?.linkedKey, opt?.keyNext); handleGotos(opt.value); handleKeyNext(opt, questionValues?.value); registerIdType(opt); }"
            v-model="answerTmp">
            <template v-slot:noResult>
              <span>Nenhum valor encontrado com esse nome.</span>
            </template>
          </multiselect>
        </div>
      </div>

      <!-- NESTED LIST -->
      <div v-else-if="questionType === 'nestedList'" class="group">
        <div
          class="nestedContainer"
          v-for="(values, idx) in questionValues?.value || []"
          :key="idx"
          >
          <div class="multiselectContainer">
            <multiselect
              v-if="allLoaded"
              :disabled="blocked"
              class="multiselect"
              :options="zip(visibleList(values, answerTmp.filter((v, innerIdx) => v && innerIdx != idx)))"
              :close-on-select="true"
              :allow-empty="false"
              placeholder="Selecione um valor..."
              :show-labels="false"
              label="label"
              track-by="value"
              @select="(opt, _) => { registerAnswer(answerTmp, opt?.linkedKey, opt?.keyNext); handleGotos(opt.name); handleKeyNext(opt, values); registerComp(); }"
              v-model="answerTmp[idx]">
              <template v-slot:noResult>
                <span>Nenhum valor encontrado com esse nome.</span>
              </template>
            </multiselect>

            <div v-if="nestedOtherVisible(idx)" class="numEntry">
              <input
                :disabled="blocked"
                type="text"
                :name="`question${question.uuid}Other${idx}`"
                placeholder="Digite aqui o valor..."
                @input="registerAnswer(answerTmp, opt?.linkedKey, opt?.keyNext); registerComp();"
                v-model.trim="nestedOtherVals[idx]" />
            </div>
          </div>

        </div>

      </div>

      <!-- DATA -->
      <div v-else-if="questionType === 'date'" class="dateGroup group">
        <input
          :disabled="blocked"
          v-for="(v, idx) in questionValues.activityFields"
          :key="idx"
          type="number"
          :min="v === 3 ? minYear : 1"
          :max="v === 1 ? 31 : ( v === 2 ? 12 : (new Date().getFullYear()) )"
          :placeholder="v === 1 ? 'Dia' : ( v === 2 ? 'Mês' : 'Ano' )"
          @input="forceDateRange(v, idx); registerAnswer(answerTmp);"
          @focusout="forceDateMinRange(v, idx)"
          v-model.trim="answerTmp[idx]" />
      </div>

      <!-- TEXT -->
      <div v-else-if="questionType === 'text'" class="numEntry">
        <input
          :disabled="blocked"
          @input="registerAnswer(answerTmp)"
          type="text"
          :name="`question${question.uuid}`"
          :maxlength="questionValues?.maxLength || -1"
          placeholder="Digite aqui o valor..."
          v-model.trim="answerTmp" />
      </div>

      <!-- EXTENDED RADIO -->
      <!-- <div v-else-if="questionType === 'extendedRadio'" class="eRadioEntry"> -->
        <!--   <div -->
        <!--     v-for="option in (questionValues?.options || [])" -->
        <!--     :key="option.id" -->
        <!--     class="radioEntry entry"> -->
          <!--     <input -->
          <!--       type="radio" -->
          <!--       @change="registerAnswer(`${option.optionEnum} ${eRadioTxt}`); handleGotos(option.id);" -->
          <!--       :name="`question${question.uuid}`" -->
          <!--       :value="option.id" -->
          <!--       :checked="answer == option.id" /> -->
          <!--     <span v-html="option.optionEnum" /> -->
          <!--   </div> -->
        <!--   <input -->
        <!--     type="text" -->
        <!--     @input="registerAnswer(`${option.optionEnum} ${eRadioTxt}`)" -->
        <!--     v-model="eRadioTxt" /> -->
        <!-- </div> -->
    </div>
  </div>

  <div class="questionCard" style="margin-top: 1rem;" v-if="otherVisible">
    <div class="questionTitle">
      <h5><b>Qual?</b></h5>
      <i v-if="otherValid" class="fas fa-check check" />
      <i v-else class="fa-solid fa-exclamation"></i>
    </div>
    <div class="questionBody">
      <div class="numEntry">
        <input
          :disabled="blocked"
          @input="registerInsert"
          type="text"
          :name="`other${question.uuid}`"
          placeholder="Especifique o valor..."
          v-model.trim="other" />
      </div>
    </div>
  </div>

  <div class="questionCard" style="margin-top: 1rem;" v-if="extraVisible">
    <div class="questionTitle">
      <h5><span v-html="selectedOption?.extraQuestions?.enunciated" /></h5>
      <i v-if="extraValid" class="fas fa-check check" />
      <i v-else class="fa-solid fa-exclamation"></i>
    </div>

    <div class="questionBody">
      <div class="numEntry" v-if="selectedOption?.extraQuestions?.extraQuestionType === 'text'">
        <input
          :disabled="blocked"
          @input="handleExtraInsert"
          type="text"
          :name="`extra${question.uuid}`"
          placeholder="Especifique o valor..."
          v-model.trim="extra" />
      </div>

      <div class="numEntry" v-else-if="selectedOption?.extraQuestions?.extraQuestionType === 'num'">
        <input
          :disabled="blocked"
          @input="handleExtraInsert"
          type="number"
          min="0"
          :name="`extra${question.uuid}`"
          placeholder="Especifique o valor..."
          v-model.trim="extra" />
      </div>

      <div class="eRadioEntry" v-else-if="selectedOption?.extraQuestions?.extraQuestionType === 'extendedRadio'">
        <div>
          <div class="radioGroup group">
            <div
              v-for="(option, idx) in (selectedOption?.extraQuestions?.options || [])"
              :key="idx"
              class="radioEntry entry">
              <input
                :disabled="blocked"
                type="radio"
                @change="other = option; extra = option; handleExtraInsert();"
                :name="`extra${question.uuid}`"
                :value="option"
                :checked="other == option || extra == option" />
              <span v-html="option" />
            </div>
          </div>
        </div>

        <div
          v-for="(misc, idx) of selectedOption?.extraQuestions?.misc"
          :key="idx"
          class="miscGroup">
          <label :for="`extra${question.uuid}${idx}`">{{ misc }}</label>

          <input
            :disabled="blocked"
            @input="eRadioInput[idx] = eRadioInput[idx].replaceAll('-', ' ').replaceAll(/\s+/g, ' '); registerInsert();"
            type="text"
            :name="`extra${question.uuid}${idx}`"
            placeholder="Digite aqui o valor..."
            v-model.trim="eRadioInput[idx]" />
        </div>
      </div>

    </div>
  </div>

</div>
</template>

<script type="text/javascript">
import Multiselect from 'vue-multiselect';
import { toRaw } from 'vue';

export default {
  name: "QuestionCard",
  props: {
    question: {
      type: Object,
      required: true,
    },
    questionList: {
      type: [Object],
      required: true,
    },
    currentIdx: {
      type: Number,
      required: true,
    },
    blocked: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  components: {
    Multiselect,
  },
  data() {
    return {
        minYear: 1900,
      answerTmp: null,
      eRadioInput: { '0': '', '1': '', },
      maxList: 15,
      other: null,
      extra: null,
      nestedOtherVals: null,
      allLoaded: false,
    };
  },
  beforeMount() {
    this.loadAnswer();
  },
  mounted() {
    this.allLoaded = true;
  },
  watch: {
    otherVisible(state) {
      if (!state) this.other = null;
    },
    extraVisible(state) {
      if (!state) this.extra = null;
    },
    other(value) {
      if (!value) {
        this.$store.dispatch("removeInsert", (this.otherOption || this.extraOption)?.toInsert);
        return;
      }
    },
    selectedOption(cur, old) {
      if (this.allLoaded && cur && (cur?.id != old?.id)) {
        this.removeLinked(old?.keyNext);
      }

      if (
        (old?.extraQuestions?.cod == undefined) ||
          (cur?.extraQuestions?.cod == old?.extraQuestions?.cod)
      ) return;

      this.$store.dispatch("upsertAnswer", {
        ordination: this.question.ordination,
        id: this.question.uuid,
        cod: old?.extraQuestions?.cod,
        answer: null,
        questionItem: this.question.questionItem,
        ignore: !this.question.visible && !this.gotoVisible,
      });
    },
    gotoVisible(state) {
      if (state) return;

      this.$store.dispatch("upsertAnswer", {
        ordination: this.question.ordination,
        id: this.question.uuid,
        cod: this.question.cod,
        answer: null,
        questionItem: this.question.questionItem,
        ignore: !this.question.visible && !this.gotoVisible,
      });

      this.loadAnswer();
    },
    answer(cur, old) {
      if (old && !cur && this.shouldClear)
        this.loadAnswer();
    },
  },
  computed: {
    eRadioTxt() {
      let txt = "";
      for (let i = 0; i < Object.keys(this.eRadioInput)?.length; ++i) {
        if (i) txt += '-';
        txt += `${this.eRadioInput[i] || ''} `;
      }
      return txt;
    },
    otherVisible() {
      if (!['list', 'radio'].includes(this.questionType) || this.answer == null) return false;

      return (this.answer?.includes ? this.answer?.includes(this.otherOption?.name) : undefined) ||
        this.otherOption?.name === this.answer;
    },
    extraVisible() {
      if (!['list', 'radio'].includes(this.questionType) || this.answer == null) return false;

      return this.selectedOption?.extraQuestions !== undefined;
    },
    selectedOption() {
      if (!['list', 'radio'].includes(this.questionType) || this.answer == null) return null;
      return (this.questionValues?.options ||
              this.questionValues?.value)
      ?.find(opt => opt?.id === this.answer) || null;
    },
    otherValid() {
      return this.other?.trim()?.length > 0;
    },
    extraValid() {
      return this.extra && (typeof(this.extra) == 'string' ? (this.extra.trim()?.length > 0) : true);
    },
    otherOption() {
      if (!['list', 'radio'].includes(this.questionType)) return null;
      return (this.questionValues?.options ||
              this.questionValues?.value)
      ?.find(opt => opt?.outrosCheck) || null;
    },
    extraOption() {
      if (!['list', 'radio'].includes(this.questionType)) return null;
      return (this.questionValues?.options ||
              this.questionValues?.value)
      ?.find(opt => opt?.extraCheck) || null;
    },
    questionType() {
      return (Object.keys(this.question?.questionType || {}) || [""])[0];
    },
    questionValues() {
      return this.question.questionType[this.questionType];
    },
    answer() {
      if (this.blocked) return null;
      const value = this.$store.getters.getAnswerId(this.question.cod);
      return value?.answer ?? null;
    },
    shouldClear() {
      const value = this.$store.getters.getAnswerId(this.question.cod);
      return value?.clear || null;
    },
    answerValid() {
      if (this.blocked) return null;
      const value = this.$store.getters.getAnswerId(this.question.cod)?.answer;
      if (Array.isArray(value)) {
        const total = value.length;
        let count = 0;
        value.forEach((v, idx) => {
          if (this.nestedOtherVisible(idx))
            count += (this.nestedOtherVals[idx] != null);
          else
            count += (v != null);
        });
        return total == count;
      }
      return value || null;
    },
    gotoVisible() {
      return this.$store.getters.getGotoSet(this.question.cod);
    },
    nextKeys() {
      return this.$store.getters.getAllNextKeys;
    },
    allAnswers() {
      return this.$store.getters.getAllAnswers;
    },
  },
  methods: {
    removeLinked(link) {
      if (!link) return;
      for (const answerCheck of Object.values(this.allAnswers)) {
        if (answerCheck?.linkedKey != link) continue;

        this.removeLinked(answerCheck?.keyNext);

        const question = this.questionList.find(q => q.cod == answerCheck.cod),
              lastOption = question.questionType?.list?.value?.at(question.questionType?.list?.value?.length - 1),
              extraSelectedOpt = question?.questionType?.radio?.options?.find(o => o.id == answerCheck?.answer)?.extraQuestions;

        this.$store.dispatch("removeInsert", lastOption?.toInsert);
        this.$store.dispatch("upsertAnswer", {
          ordination: this.question.ordination,
          id: answerCheck.uuid,
          cod: extraSelectedOpt?.cod,
          answer: null,
        });

        this.$store.dispatch("upsertAnswer", {
          ordination: this.question.ordination,
          id: answerCheck.uuid,
          cod: answerCheck.cod,
          answer: null,
          questionItem: answerCheck.questionItem,
          ignore: true,
          clear: true,
        });
      }
    },
    loadAnswer() {
      this.answerTmp = this.answer;
      this.nestedOtherVals = this.$store.getters.getComp(this.question.cod) || [ ...Array((this.questionValues.value || []).length) ];
      if (this.questionType === 'nestedList' && this.answerTmp) {
        this.answerTmp = [ ...this.answerTmp ].map((v, idx) => {
          if (typeof(v) == 'string')
            return (this.optToZip({ name: v }, idx) || {});
          else
            return v;
        });
      }

      if (this.nestedOtherVals && this.nestedOtherVals.some(v => v)) {
        this.answerTmp = (this.nestedOtherVals || []).map((v, idx) => {
          if (v)
            return (this.optToZip(this.nestedOther(idx), idx) || {});
          else
            return this.answerTmp[idx];
        });
      }

    if (!this.blocked && this.$store.getters.getAnswerId(this.question.cod) === undefined) {
      if (this.questionType == 'extendedRadio')
        this.$store.dispatch(
          'addFiltering',
          {
            key: this.question.cod,
            filter: 'CLEAR_EXTENDED',
          }
        );

      this.$store.dispatch("upsertAnswer", {
        ordination: this.question.ordination,
        id: this.question.uuid,
        cod: this.question.cod,
        answer: null,
        questionItem: this.question.questionItem,
        ignore: !this.question.visible && !this.gotoVisible,
      });

        if (this.questionType == 'radio') {
            for (const option of this.questionValues?.options) {
                const extra = option?.extraQuestions;
                if (!extra) continue;

                this.$store.dispatch("upsertAnswer", {
                    ordination: this.question.ordination,
                    id: extra.id,
                    cod: extra.cod,
                    answer: null,
                    questionItem: null,
                    ignore: true,
                });
            }
        }
    }

    if (this.answerTmp === null && this.questionType === 'date')
      this.answerTmp = [ ...Array(this.questionValues.activityFields?.length) ];
    else if (this.answerTmp === null && this.questionType === 'nestedList') {
      this.answerTmp = [ ...Array((this.questionValues.value || []).length) ];
      this.nestedOtherVals = [ ...Array((this.questionValues.value || []).length) ];
    }

    if (!this.blocked && this.otherVisible) {
      this.other = this.$store.getters.getInsertSet((this.otherOption || this.extraOption)?.toInsert) || null;
      if (this.selectedOption?.extraQuestions?.extraQuestionType === 'extendedRadio') {
        const segments = (this.other || "").split('-');
        this.other = segments[0];
        this.eRadioInput = segments.slice(-1 * (segments.length - 1));
      }
    }

    if (!this.blocked && this.extraVisible) {
      this.extra = (this.extraOption?.toInsert ? this.$store.getters.getInsertSet(this.extraOption?.toInsert) : this.$store.getters.getAnswerId(this.extraOption?.extraQuestions?.cod)?.answer) || null;
      if (this.selectedOption?.extraQuestions?.extraQuestionType === 'extendedRadio') {
        const segments = (this.extra || this.other || "").split('-');
        this.extra = segments[0];
        this.eRadioInput = segments.slice(-1 * (segments.length - 1));
      }
    }

    this.answerAsMulti();
    },
    handleExtraInsert() {
      if (this.selectedOption?.toInsert) this.registerInsert();
      else this.registerExtra();
    },
      registerExtra() {
        if (this.selectedOption?.extraQuestions?.extraQuestionType === 'extendedRadio') {
          this.$store.dispatch(
            'addFiltering',
            {
              key: this.selectedOption?.extraQuestions.cod,
              filter: 'CLEAR_EXTENDED',
            }
          );
        }

        this.$store.dispatch("upsertAnswer", {
          ordination: this.question.ordination,
          id: this.question.uuid,
          cod: this.selectedOption?.extraQuestions.cod,
          answer: this.extra ?? null,
          questionItem: this.question.questionItem,
          ignore: false,
        });
      },
    optToZip(opt, idx) {
      return { ...this.zip(this.visibleList(this.questionValues?.value?.at(idx))).find(v => opt.name == v.name) } || null;
    },
    registerComp() {
      if (this.questionType !== 'nestedList' || !this.answerTmp || this.answerTmp?.some(v => !v || (v?.trim && v.trim() == ''))) {
        this.$store.dispatch("upsertComp", {
          id: this.question.cod,
          value: undefined,
        });
        return;
      }

      const checkOthersMissing = [ ...this.nestedOtherVals ].some((v, idx) => this.nestedOtherVisible(idx) && (!v || (v?.trim && v.trim() == ''))),
            noneNeeded = [ ...this.nestedOtherVals ].every((_, idx) => !this.nestedOtherVisible(idx));

      if (checkOthersMissing || noneNeeded)
        this.$store.dispatch("upsertComp", {
          id: this.question.cod,
          value: undefined,
        });
      else
        this.$store.dispatch("upsertComp", {
          id: this.question.cod,
          value: this.nestedOtherVals,
        });
    },
    nestedOtherVisible(idx) {
      if (this.questionType !== 'nestedList' || !this.answerTmp[idx]) return false;
      return this.nestedOther(idx)?.name === this.answerTmp[idx]?.name;
    },
    nestedOther(idx) {
      if (this.questionType !== 'nestedList' || !this.questionValues?.value) return null;
      return (this.questionValues?.value[idx] || [])?.find(opt => opt?.extraCheck || opt?.outrosCheck) || null;
    },
    registerIdType(option) {
      if (!option.idType) return;
      this.$store.dispatch("registerIdType", option);
    },
      forceDateMinRange(v, idx) {
          if (!this.answerTmp[idx]) return;
          if (v === 3) {
              this.answerTmp[idx] = Math.max(this.minYear, this.answerTmp[idx]);
              this.registerAnswer(this.answerTmp);
          }
      },
    forceDateRange(v, idx) {
      if (!this.answerTmp[idx]) return;
      this.answerTmp[idx] = Math.max(1, this.answerTmp[idx]);
      switch (v) {
        case 1:
        this.answerTmp[idx] = Math.min(this.answerTmp[idx], 31);
        break;
        case 2:
        this.answerTmp[idx] = Math.min(this.answerTmp[idx], 12);
        break;
        case 3:
        this.answerTmp[idx] = Math.min(this.answerTmp[idx], new Date().getFullYear());
        break;
      }
    },
    zip(optionList) {
      return optionList.map(option => {
        return {
          ...option,
          value: option.id,
          label: option.name,
        };
      });
    },
    visibleList(rawList, extraExcluding) {
      if (extraExcluding) extraExcluding = extraExcluding.map(e => e.name);
      if (!rawList) return [];
      const linked = this.question?.questionType?.nestedList?.linkedQuestion,
            toExclude = this.$store.getters.getAnswerId(linked) ?? null;
      return rawList.filter(option => {
        const check = (((toExclude && option.id === toExclude?.answer) || (extraExcluding || []).includes(option?.name)) && !(option?.outrosCheck || option?.extraCheck)) ? false :
              (!option.linkedKey ||
              option?.outrosCheck ||
              option?.extraCheck ||
              this.$store.getters.getLinkedKeySet(option.linkedKey));
        if (!check && option.id == this.answerTmp) {
          this.answerTmp = null;
          this.registerAnswer(this.answerTmp);
        }
        return check;
      });
    },
    answerAsMulti() {
      if (
        (!['list', 'nestedList'].includes(this.questionType)) ||
          (this.questionType === 'list' && this.visibleList(this.questionValues?.value).length < this.maxList) ||
          this.answerTmp === null || this.answerTmp === undefined || (this.answerTmp?.includes && this.answerTmp.includes(undefined))
      )
        return;

      if (this.questionType == 'list')
        this.answerTmp = this.zip(
          this.visibleList(this.questionValues?.value)
        ).find(opt => opt.value == this.answer) || null;
    },
    registerAnswer(value, link, keyNext) {
      value = toRaw(value);
      if ((this.questionType === 'date' && this.answerTmp.some(v => isNaN(parseInt(v)))) || (this.questionType === 'nestedList' && this.answerTmp.some(v => !v)))
        this.$store.dispatch("upsertAnswer", {
          ordination: this.question.ordination,
          id: this.question.uuid,
          cod: this.question.cod,
          answer: null,
          questionItem: this.question.questionItem,
          ignore: !this.question.visible && !this.gotoVisible,
        });
      else if (this.questionType === 'nestedList') {
        const checkOthersMissing = [ ...this.nestedOtherVals ].some((v, idx) => this.nestedOtherVisible(idx) && (!v || (v?.trim && v.trim() == '')));
        if (checkOthersMissing) {
          this.$store.dispatch("upsertAnswer", {
            ordination: this.question.ordination,
            id: this.question.uuid,
            cod: this.question.cod,
            answer: null,
            // linkedKey: link,
            // keyNext: keyNext,
            questionItem: this.question.questionItem,
            ignore: !this.question.visible && !this.gotoVisible,
          });}
        else
          this.$store.dispatch("upsertAnswer", {
            ordination: this.question.ordination,
            id: this.question.uuid,
            cod: this.question.cod,
            answer: (value?.length === 0) ? null : value.map((v, idx) => this.nestedOtherVisible(idx) ? this.nestedOtherVals[idx] : v.name),
            linkedKey: link,
            keyNext: keyNext,
            questionItem: this.question.questionItem,
            ignore: !this.question.visible && !this.gotoVisible,
          });
      }
      else
        this.$store.dispatch("upsertAnswer", {
          ordination: this.question.ordination,
          id: this.question.uuid,
          cod: this.question.cod,
          answer: (value?.length === 0) ? null : value,
          linkedKey: link,
          keyNext: keyNext,
          questionItem: this.question.questionItem,
          ignore: !this.question.visible && !this.gotoVisible,
        });

      if (!this.otherVisible && !this.extraVisible) {
        this.other = null;
        this.eRadioInput = {};
      }
      this.registerInsert();
    },
    registerInsert() {
      if (this.selectedOption?.extraQuestions?.extraQuestionType === 'extendedRadio') {
        this.$store.dispatch(
          'addFiltering',
          {
            key: (this.otherOption || this.extraOption)?.toInsert,
            filter: 'CLEAR_EXTENDED',
          }
        );
      }

      const insertTxt = this.extraOption?.extraQuestions?.extraQuestionType === 'extendedRadio' ? `${this.other}-${this.eRadioTxt}` : this.extra;

      if (!this.extra || insertTxt?.length === 0) {
        this.$store.dispatch("removeInsert", (this.otherOption || this.extraOption || {})?.toInsert);
        return;
      }

      this.$store.dispatch("addInsert", {
        [ (this.otherOption || this.extraOption)?.toInsert ]: insertTxt,
      });
    },
    handleKeyNext(option, list) {
      this.nextKeys
          .filter(k => list?.map(v => v.keyNext)?.includes(k))
          .forEach(key => this.$store.dispatch("removeNextKey", key));

      if (!option?.keyNext) return;
      this.$store.dispatch("addNextKey", option.keyNext);
    },
    handleGotos(value) {
      if (this.questionValues?.subType?.goTo?.trigger === undefined)
        return;

      if (this.questionType === 'list') {
        const added = this.answerTmp.includes(value);
        if (added) {
          for (const trigger of this.questionValues.subType.goTo.trigger[value])
            this.$store.dispatch("addGoto", {
              from: this.question.cod,
              to: this.questionList[this.currentIdx + trigger].cod,
            });
        } else {
          for (const trigger of this.questionValues.subType.goTo.trigger[value])
            this.$store.dispatch("removeGoto", {
              from: this.question.cod,
              to: this.questionList[this.currentIdx + trigger].cod,
            });
        }
      } else {
        for (const v of Object.keys(this.questionValues.subType.goTo.trigger)) {
          for (const trigger of this.questionValues.subType.goTo.trigger[v])
            this.$store.dispatch("removeGoto", {
              from: this.question.cod,
              to: this.questionList[this.currentIdx + trigger].cod,
            });
        }

          for (const trigger of (this.questionValues?.subType?.goTo?.trigger[value] || []))
              this.$store.dispatch("addGoto", {
                  from: this.question.cod,
                  to: this.questionList[this.currentIdx + trigger].cod,
              });
      }
    },
  },
 };
</script>

<style type="text/css" media="screen" scoped>
.outer {
    width: 100%;
    flex: 1 0 100%;
}

.questionCard {
    width: 100%;
    margin-bottom: 1rem;
    border-radius: 16px;
    background-color: white;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.questionTitle {
    background-color: var(--light-green);
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    align-content: center;
    border-radius: 16px 16px 0 0;
}

:deep(.questionTitle p) {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 1em;
}

.questionTitle > h5 {
    color: white;
    margin: 0 !important;
    padding: 0 !important;

    font-size: 1.5em !important;
}

.fa-exclamation{
  color: red !important;
}

.questionTitle > i {
    font-size: 2.2em;
    padding: 5px 10px;
    color: white;
}

i.check {
    color: var(--dark-green);
}

.questionBody, .questionTitle {
    padding: 1em;
}

/* ALL TYPES */

.radioEntry.entry{
  font-size: 1em;
}

.entry {
    display: flex;
    flex-direction: row;
    padding: 10px;
    box-sizing: border-box;
    font-weight: var(--semibold);
}

.entry > span{
  display: flex;
  align-items: center;
}

/* RADIO */

/* .radioGroup {
} */

:deep(.radioEntry p) {
    flex: 1 0 min-content;
    padding: 0;
    margin: 0;
    display: inline-block;
}

.entry input[type="radio"] {
    flex: 0 0 auto;
}

input[type="radio"] {
    flex: 0 0 min-content;
    margin-right: 1em;
    height: 2em;
    -ms-transform: scale(2); /* IE 9 */
    -webkit-transform: scale(2); /* Chrome, Safari, Opera */
    transform: scale(2);
}

input[type="radio"]:hover{
  accent-color: #232323;
}

/* LIST */

/* .listEntry {
} */

input[type="checkbox"] {
    flex: initial;
    margin-right: 5px;
}

.multiselectContainer {
    padding: 0.8em;
}

/* DATE */

.dateGroup {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1em;
    margin: 1em 0 1em 1em;
}

.dateGroup input[type="number"] {
    border: 0;
    border-radius: 8px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    padding: 0.8em;
    flex: 0 0 5em;
    min-width: 0;
}

/* NUM */

.numEntry {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 1em;
    margin: 1em 0 1em 0;
    padding: 0 15px 0 15px;
}

.numEntry input {
    border: 0;
    border-radius: 8px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    padding: 0.8em;
    flex: 1 0 min-content;
    min-width: 0;
 }

 /* ERADIO */

 .eRadioEntry {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-wrap: wrap;
     flex-direction: column;
     width: 100%;
     gap: 1em;
     margin: 1em 0 1em 0;
     padding: 0 15px 0 15px;
 }

 .eRadioEntry > * {
     margin: 0 auto;
     width: 100%;
     flex: 1 0 min-content;
}

 .eRadioEntry input {
     border: 0;
     border-radius: 8px;
     box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
     padding: 0.8em;
     flex: 1 0 min-content;
     min-width: 0;
 }

 .eRadioEntry input[type="radio"]{
     box-shadow: none;
 }

 .miscGroup {
     display: flex;
     flex-direction: column;
     flex-wrap: wrap;
 }

 .miscGroup > label {
     font-weight: bold;
 }

 .miscGroup > * {
     flex: 1 0 min-content;
 }


/* FONT SIZE*/

.radioEntry.entry, .numEntry input, .eRadioEntry input, .dateGroup input, input{
  font-size: 1.2em;
}
</style>
