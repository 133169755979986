<template>
  <div class="container offset">
    <form @submit.prevent="upsertProfessor" ref="formClass" class="customForm">
      <div class="form-group">
        <label class="question-style pt-1">Nome do professor: *</label>
        <input
          v-model.trim="professor.nome"
          type="name"
          class="form-control"
          placeholder="Insira aqui o nome do professor..."
          required
          />
      </div>

      <div class="formButtons">
        <!-- <div class="button-div pt-4"> -->
        <!--   <Button -->
        <!--     value="Limpar" -->
        <!--     section="descartar" -->
        <!--     :isOutline="true" -->
        <!--     :hasIcon="true" -->
        <!--     iconName="trash" -->
        <!--     @click="clearForm" /> -->
        <!-- </div> -->

        <div class="button-div pt-4">
          <Button
            section="instituicao-cadastrar"
            value="Salvar professor"
            :isOutline="true"
            :hasIcon="true"
            iconName="save"
            :isDisabled="isLoadingCreation"
            />
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import Button from '@components/Button';
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  }
});

export default {
  name: "AddProfessor",
  components: {
    Button,
  },
  props: {
    data: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      professor: {
        nome: null,
        idInstituicao: null,
        idUnidade: null,
      },
      actualList: null,
      isLoadingCreation: false,
    };
  },
  watch: {
    data() {
      if (this.data.editing){
        this.professor.nome = this.data.editing.nome;
      }
      else {
        this.professor.nome = null;
      }

      if(this.data.listProfessores){
        this.actualList = this.data.listProfessores
      } 
      else { this.actualList = [];}
    },
  },
  mounted() {
    this.professor.idInstituicao = this.$route.params.idInstituicao;
    this.professor.idUnidade = this.$route.params.idUnidade;
  },
  methods: {
    async upsertProfessor() {
      this.isLoadingCreation = true;
      if (this.data.editing){
        await this.updateProfessor();
      }
      else{
        await this.createProfessor();
      }
      this.isLoadingCreation = false;
    },
    async updateProfessor() {
      //se houver outros professores com o mesmo nome e se o nome for diferente do seu atual
      let profAlreadyExists = this.actualList.some(item => (
        item.nome.toLowerCase() === this.professor.nome.toLowerCase() && 
        item.nome.toLowerCase() != this.data.editing.nome.toLowerCase())
      );

      if(!profAlreadyExists){
        await this.$store.dispatch('setUpdateProfessor', {
          ...this.professor,
          idProfessor: this.data.editing.idProfessor,
        });

        if (this.$store.getters.getIsProfessorUpdated) {
          notyf.success("Professor atualizado com sucesso");

          const payload = {
            idInstituicao: this.$route.params.idInstituicao,
            idUnidade: this.$route.params.idUnidade
          };

          this.$store.dispatch('setListProfessores', payload).then(() => this.isLoadedInfo = true);
          this.$emit('closeModal')
        } else {
          notyf.error("Erro ao atualizar o professor, tente novamente");
        }
      } else {
        //se as informações já existirem
        notyf.open({
        type: "error",
        message: "Já existe um professor cadastrado com essas informações nesta unidade, verifique os campos e tente novamente.",
        });
      }
    },
    async createProfessor() {
      //se houver outros professores com o mesmo nome
      let profAlreadyExists = this.actualList.some(item => (
        item.nome.toLowerCase() === this.professor.nome.toLowerCase())
      );

      if(!profAlreadyExists){
        await this.$store.dispatch('setCreateProfessor', this.professor);

        if (this.$store.getters.getIsProfessorCreated) {
          notyf.success("Professor adicionado com sucesso");
          this.professor.nome = "";

          const payload = {
            idInstituicao: this.$route.params.idInstituicao,
            idUnidade: this.$route.params.idUnidade
          };

          this.$store.dispatch('setListProfessores', payload).then(() => this.isLoadedInfo = true);
        } else {
          notyf.error("Erro ao adicionar professor, tente novamente");
        }
      } else {
        //se as informações já existirem
        notyf.open({
        type: "error",
        message: "Já existe um professor cadastrado com essas informações nesta unidade, verifique os campos e tente novamente.",
        });
      }
    },
    clearForm() {
      if (this.data.editing){
        this.professor.nome = this.data.editing.nome;
      }
      else {
        this.professor.nome = null;
      }
    }
  },
}
</script>

<style scoped>
div {
    color: currentColor;
}

.formButtons {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
}
</style>
