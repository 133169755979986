<!--
|---------------------------------------------
| Info Cards Component 
|---------------------------------------------
| @usage Import the component and use, no external params
| 
| Receive info from the JSON below, the infos are:
|	id: 'Id of the Card, is the key of v-for'  
|	title: 'Title of the card'
|	desc: 'A larger text for the description of the card'
|	direction: 'Indicates the direction of the img, the options are default or reverse'
|	section: 'Bases on the section the color of the elements in the card changes'
|	img: 'Name of the img on the /assets/img/Home/InfoCards folder'
    textColor: 'CSS color for the card's text'
    bgImg: 'Texture image for the card'
-->

<template>
    <div class='container-fluid'>
	    <div
            :class="`info-card row ${info.direction} ${info.section}`"
            :style="{ 'background-image': `url('${info.bgImg}')`, 'color': `${info.textColor}` }"
            v-for="info in infos"
            :key="info.id" >
            <div class='col-md-6'>
                <img class="img-info-card img-fluid" :src="require('../../assets/img/Home/InfoCards/'+info.img)" alt="">
            </div>
            <div class="infos col-md-6">
                <h1>{{info.title}}</h1>
                <p>{{info.desc}}</p>
                <a :target="info?.newTab ? '_blank' : '_self'" :href="info.link" class="button"><Button value="saiba mais" :section="info.section"/></a>
            </div>
	    </div>
	</div>
</template>

<script>
 import Button from '@components/Button';

 export default {
     name: 'InfoCards',
     data() {
	     return {
		     infos: [
                 {
		             id:1,
		             title: 'Boas Práticas na Educação Infantil',
		             desc: 'A Educação Infantil, enquanto primeira etapa da Educação Básica, fundamenta o processo educacional e vincula educar e cuidar. Ela tem o objetivo de desenvolver as crianças integralmente, ampliando seu universo de experiências, conhecimentos e habilidades, essencialmente por meio de interações e brincadeiras. A Educação Infantil, ao promover a oportunidade das crianças exercerem um papel ativo no ambiente, permite que elas construam significados sobre si, os outros e o mundo social e natural.',
		             direction: 'default',
		             section: 'boas-praticas',
		             img: 'banner-boas-praticas.webp',
                     textColor: "white",
                     bgImg: require('../../assets/img/Backgrounds/verde.webp'),
                     link: "/boaspraticas/oquee"
		         }, {
                  	 id:2,
 		             title: 'Materiais e Documentos',
 		             desc: 'Além das agendas e projetos desenvolvidos, o LEPES também incentiva fortemente a produção interna de pesquisas científicas entre todos os membros, sejam eles Assistentes de Pesquisa, Professores-colaboradores ou outros associados. Além disso, a equipe submete artigos e comparece à congressos, como o Encontro Nacional de Economia promovido pela ANPEC, com o intuito de promover e disseminar o que é produzido, além de trabalhar na melhoria contínua do que é produzido.',
 		             direction: 'reverse',
 		             section: 'materiais-documentos',
 		             img: 'banner-materiais-documentos.webp',
                     textColor: "white",
                     bgImg: require('../../assets/img/Backgrounds/azul.webp'),
                     link: "/materiaisedocumentos/oquee"
 		         }, {
 		             id:3,
 		             title: 'Certificação',
 		             desc: 'O Sistema de Monitoramento da Educação Infantil é um projeto realizado por meio de parceria entre o Laboratório de Estudos e Pesquisas em Economia Social (LEPES) e a Fundação Maria Cecília Souto Vidigal. A EAPI é uma ferramenta utilizada para coleta de dados em processos avaliativos. É composta por um roteiro de observação em conjunto com roteiros de entrevistas. Seu uso permite obter informações sobre a qualidade dos ambientes, englobando aspectos de infraestrutura, de formação dos profissionais e das oportunidades de aprendizagens e interações oferecidas. Pode ser utilizada por instituições educacionais interessadas na melhoria de seus processos, bem como em redes municipais de Educação Infantil.',
 		             direction: 'default',
 		             section: 'certificacao',
 		             img: 'banner-certificacao.webp',
                     textColor: "#7C7C7C",
                     bgImg: require('../../assets/img/Backgrounds/cinza.webp'),
                     link: "http://certificacao.monitoramentoeibv.fearp.usp.br",
                     newTab: true,
 		         }, {
 		             id:4,
 		             title: 'Gestão da Avaliação',
 		             desc: 'O LEPES tem parcerias e desenvolve projetos para diversas instituições importantes e reconhecidas em âmbito nacional e internacional, incluindo governos a níveis municipais e estaduais, sempre buscando maior eficiência e qualidade do investimento social.',
 		             direction: 'reverse',
 		             section: 'dados',
 		             img: 'banner-dados.webp',
                     textColor: "white",
                     bgImg: require('../../assets/img/Backgrounds/roxo.webp'),
                     link: "/implementacao/gestao",
 		         }
		     ]
	     }
     },
     props: {
		 title: String,
		 desc: String,
		 direction: String,
		 img: String
     },
     components: {
	     Button
     }
 }
</script>

<style scoped>
 @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

/*
 |-----------------------------------------------------
 |--ESTILIZANDO OS ELEMENTOS BÁSICOS DO COMPONENTE
 |-----------------------------------------------------
 */
.container-fluid {
    box-shadow: 0px -10px 38px -12px rgb(96 133 236 / 43%);
}

.info-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3rem;
    position: relative;
    box-shadow: inset 0 3px 3px rgba(0, 0, 0, 0.2);
    background-repeat: repeat;
    background-size: 256px;
}

.infos {
    font-size: 0.9rem;
    text-align: justify;
} 

.infos h1 {
    margin-top: 1rem;
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    font-family: 'Montserrat', sans-serif;
}

 .infos p {
     text-indent: 1em;
     font-size: 18px;
     font-weight: 400;
     text-align: justify;
     font-family: 'Montserrat', sans-serif;
 }

.info-card img {
    border-radius: 0.3rem;
    /* -webkit-box-shadow: 10px 10px 34px -22px rgba(0,0,0,0.90);
       -moz-box-shadow: 10px 10px 34px -22px rgba(0,0,0,0.90); */
    box-shadow: 10px 10px 34px -22px rgb(0,0,0);
    transition: transform ease-in-out 0.4s;
}

.info-card img:hover {
    transform: scale(1.02);
}

.img-info-card {
	height: 16rem;
	width: 28rem;
    object-fit: cover;
}

/*-----------------------------------------------------*/

/*
 |-----------------------------------------------------
 |--REUTILIZANDO PARAMETROS PARA ESPECIFICACOES
 |-----------------------------------------------------
 */
.default {
    flex-direction: row;
    /* background-color: #F5F5F5; */
}

.reverse {
    flex-direction: row-reverse;
    /* background-color: #D5E8FE; */
}

 /* .button button {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
    } */

.default .button {
    text-align: right;
}

.reverse .button {
    text-align: left;
}

 .reverse img {
     display: block;
     margin: 0 auto;
 }

 .default img {
     display: block;
     margin: 0 auto;
 }

@media only screen and (min-width: 800px){
    /* .default img {
	   margin-right: 5rem;
       } */
    /*
       .reverse img {
	   margin-left: 5rem;
       } */
}

@media screen and (max-width: 728px) {
    .info-card {
	padding: 1.2rem;
    }
  }

/*-----------------------------------------------------*/
</style>
