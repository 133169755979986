<template>
    <ModalCentral
        v-if="userPerm"
        componentName="EditCard"
        :title="idCard ? 'Editar card' : 'Adicionar card'"
        idName="editCardInfoGraph"
        :data="{ idCard: idCard, ordem: order, idTipo: idTipo }"
    />
    
<div class="container">
    <!--Section: Métricas do Projeto-->
    <section id="metricas" class="container">

        <!-- Heading -->
        <h1 class="font-weight-bold">Métricas do projeto</h1>
        <!-- Description -->
        <p class="grey-text">Dados importantes sobre o contexto da transição do jovem da escola para o mundo do trabalho</p>

        <!--Grid row-->
        <div class="row text-center project-metrics justify-content-between p-4">
            <!--Grid column-->
            <div class="col-12 col-md pr-2 pl-2 mr-2 ml-2"
                :class="{ 'border-right' : card.ordem<3}"
                 v-for="card in homeTop"
                 :key="card.idCard">
                 <div class="card-options" v-if="userPerm">
                    <button type="button" class="btn btn-success btn-circle" @click="setIdCard(card.idCard)" data-toggle="modal" data-target="#editCardInfoGraph">
                        <i class="fas fa-pen"/>
                    </button>
                </div>
                <h2 class="font-weight-bold"><span style="color:#00853B;">{{ card?.titulo }}</span></h2>
                <p class="grey-text text-left card-corpo" v-html="card?.corpoTexto"></p>
            </div>
        </div>

    </section>
    <!--Section: Métricas do Projeto-->
    <hr class="my-5">
</div>
</template>

<script type="text/javascript">
 import ModalCentral from '@components/ModalCentral';
 import axios from '@middleware/api.js';

 export default {
     name: "InfoGraphs",
     components: {
         ModalCentral,
     },
     data() {
         return {
             idCard: null,
             order: null,
             idTipo: null,
         };
     },
     async created() {
         this.$store.dispatch("setListCards");
     },
     methods: {
        setIdCard(id, newCard) {
             this.idCard = newCard ? null : id;
             this.order = newCard ? id : null;
             this.idTipo = newCard ? this.cards.find(card => card.idCard == id)?.idTipo : null;
         },
        getImage(path) {
            return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
        },
     },
     computed: {
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
         cards() { return this.$store.getters.getListCards || []; },
         homeTop() { return this.cards.filter(card => card?.idTipo == 40).sort((a, b) => a.ordem - b.ordem); },
         
     },
 };
</script>

<style scoped>

.border-right { /* Vertical line */
    border-right: 1px solid #00853B !important;
}

@media screen and (max-width: 768px) {
    .border-right{
        border-right: none!important;
        border-bottom: 1px solid #00853B;
        margin-bottom: 2em;
    }
    
}

.btn-circle {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 6px 0px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 50%;
}

.btn-circle:not(:last-child){
    margin-right: 10px;
}

i{
    font-family: "Font Awesome 6 Free" !important; 
}

::v-deep b, ::v-deep strong{
    color: #00853B !important;
}

h2.project-metrics{
    text-align: center;
}

p.card-corpo{
    line-height: 2em !important;
}

</style>
