//---------------------------------------------
// Unidades Educacionais Module 
//---------------------------------------------
// Módulo para gerenciar as ações da unidade

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listUE: [],
    listMacroareas: [],

    //VERIFICACAO DE ERRO
    isCreateUnidadeCSV: false,
    isCreateUnidadeWithTurmasCSV: false,
    isUECreated: false,
    isUEDeleted: null,
    isUEUpdated: null,
})

// MUTATIONS
const mutations = {
    //-- MUTATION REQUISICAO DO LISTUNIDADESEDUCACIONAIS
    setListUE(state, payload) {
        state.listUE = payload
    },

    setIsCreateUnidadeCSV(state, payload) {
        state.isCreateUnidadeCSV = payload
    },

    setIsisCreateUnidadeWithTurmasCSV(state, payload) {
        state.isCreateUnidadeWithTurmasCSV = payload
    },

    setListMacroareas(state, payload) {
        state.listMacroareas = payload
    },

    setAddMacroarea(state, payload) {
        const counter = state?.listMacroareas[state.listMacroareas?.length - 1].counter + 1
        console.log(counter, payload)
        state.listMacroareas.push({macroarea: payload, counter: counter})
    },

    setIsUECreated(state, payload) {
        state.isUECreated = payload;
    },

    setIsUEUpdated(state, success) {
        state.isUEUpdated = success;
    },

    setIsUEDeleted(state, deleted) {
        state.isUEDeleted = deleted;
    },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO DE CRIAR UNIDADE EDUCACIONAL
    async setCreateUE( { commit }, payload)  {
        await axios
            .post(`ue/${payload.idInstituicao}`, {
                nome: payload.nome,
                bairro: payload.bairro,
                macroarea: payload.macroarea,
                inep: payload.inep,
                tipoLocalizacao: payload.tipoLocalizacao,
                anoInicioAtividades: payload.anoInicio,
                municipio: payload.municipio,
                rua: payload.rua,
                numero: payload.numero,
                complemento: payload.complemento,
                tipoModalidade: payload.tipoModalidade
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
            })
            .then(() => {
                commit('setIsUECreated', true);
            })
            .catch((error) => {
                commit('setIsUECreated', false);
                console.log(error.message)
            });
    },

    //-- ACTION REQUISICAO DE ENVIAR ARQUIVO PARA CRIAR UE
    async setSendUEFile( {commit}, payload)  {
        var formData = new FormData();
        if (payload.file) {
            formData.append("file", payload.file)
        }
        await axios
            .post(`ue/upload/${payload.idInstituicao}`, 
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(
                commit('setIsCreateUnidadeCSV', true)
            )
            .catch((error) => {
                console.log(error.message)
                commit('setIsCreateUnidadeCSV', false)
            })
    },

    //-- ACTION REQUISICAO DE ENVIAR ARQUIVO PARA CRIAR UE COM TURMAS NA AMOSTRA
    async setSendUEFileWithTurmasAmostra( {commit}, payload)  {
        var formData = new FormData();
        if (payload.file) {
            formData.append("idAmostra", payload.idAmostra),
            formData.append("file", payload.file)
        }
        await axios
            .post(`ue/uploadEntities/${payload.idInstituicao}`, 
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => {
                commit('setIsisCreateUnidadeWithTurmasCSV', true)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setIsisCreateUnidadeWithTurmasCSV', false)
            })
    },

    //-- ACTION REQUISICAO DE ENVIAR ARQUIVO PARA CRIAR UE COM TURMAS
    async setSendUEFileWithTurmas( {commit}, payload)  {
        var formData = new FormData();
        if (payload.file) {
            formData.append("file", payload.file)
        }
        if (payload.idAmostra) {
            formData.append("idAmostra", payload.idAmostra)
        }
        await axios
            .post(`ue/uploadEntities/${payload.idInstituicao}`, 
            formData,
            {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                if (response.data.status == "created")
                    commit('setIsisCreateUnidadeWithTurmasCSV', { success: true, message: "" })
                else
                    commit('setIsisCreateUnidadeWithTurmasCSV', { success: false, message: response.data.message })
            })
            .catch((error) => {
                console.log(error.message)
                commit('setIsisCreateUnidadeWithTurmasCSV', { success: false, message: "Houve algum problema com o seu arquivo CSV. Por favor, revise as informações." })
            })
    },

    //-- ACTION REQUISICAO DO LISTFORMS
    async setListUE({ commit }, payload) {
        await axios
            .get(`ue/${payload.idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListUE', response.data)
            })
            .catch((error) => console.log(error.message))
    },


    //-- ACTION REQUISICAO DAS MACROAREAS
    async setListMacroareas({ commit }, idInstituicao) {
        await axios
            .get(`ue/listMacroareas/${idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListMacroareas', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE UNIDADE EDUCACIONAL
    async deleteUE({ commit }, payload) {
        commit("setIsUEDeleted", null);

        await axios
            .delete(`ue/${payload.idInstituicao}/${payload.idUE}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => commit("setIsUEDeleted", true))
            .catch((error) => {
                console.log(error.message);
                commit("setIsUEDeleted", false);
            })
    },

    async updateUE({ commit }, payload) {
        commit('setIsUEUpdated', true);

        await axios
            .put(
                `ue/${payload.idInstituicao}/${payload.infoUE.idUe}`, {
                    ...payload.infoUE,
                }, {
                    headers: {
                        'Authorization': `Bearer ${JWT()}`
                    }
                }
            )
            .then(() => commit('setIsUEUpdated', true))
            .catch(err => {
                console.log(err);
                commit('setIsUEUpdated', false);
            });
    },
}

// GETTERS
const getters = {
    getListUE(state) {
        try {
            return state.listUE.data
        } catch (e) {
            return []
        }
    },

    getListMacroareas(state) {
        try {
            return state.listMacroareas.data
        } catch (e) {
            return []
        }
    },

    getIsCreateUnidadeCSV(state) {
        return state.isCreateUnidadeCSV
    },

    getIsCreateUnidadeWithTurmasCSV(state) {
        return state.isCreateUnidadeWithTurmasCSV
    },

    getIsUECreated(state) {
        return state.isUECreated;
    },

    getIsUEUpdated(state) {
        return state.isUEUpdated;
    },

    getIsUEDeleted(state) {
        return state.isUEDeleted;
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
