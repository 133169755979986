<template>

    <div class="root-dados">
        <div class="container list-data">
            <ModalCentral
                componentName="AddField"
                title="ADICIONAR CAMPO"
                idName="AddCampo"
            />

            <ModalCentral
                componentName="ShowCampoInfo"
                title="DESCRIÇÃO DO CAMPO"
                idName="showCampoInformacao"
                :data="dataToShowCampoInfo"
            />

            <ModalCentral
              componentName="UpdateField"
              title="EDITAR CAMPO"
              idName="updateCampo"
              :data="{'campo': campoEdit }"
              />


            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-campo"/>
            <br/>

            <h3 class="title-info"> Campos </h3>

            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os Campos relacionados à Instituição. Caso necessário, utilize o filtro abaixo para localizar o Campo desejado. Apenas um Campo pode apresentar o status "Ativo" por vez. Para acessar e/ou gerenciar os Estudantes, Formulários, Unidades Educacionais e Turmas que participam deste campo, clique no botão "Ver Campo" do Campo desejado.</p>

            <div class="lista-data-info">
                <Button
                    v-if="userPerm.permissao != 'APLICADOR'"
                    class="greenGradient clickable"
                    value="Adicionar Campo"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="chart-pie"
                    data-toggle="modal"
                    data-target="#AddCampo"
                />
            </div>
            <br />

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar campos por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS CAMPOS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnts
                    term="campos"
                    :totalQnt="totalCampos"
                    :filteredQnt="campos?.length || 0"
                    icon="clipboard" />

                <div class="list-box">
                    <transition-group name="slide-fade" v-if="campos?.length > 0">

                        <div class = "table-wrap">
                            <table class="table mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col">  </th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Criação</th>
                                        <th scope="col">Status</th>
                                        <!-- <th scope="col">Código de aplicador</th> -->
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row" v-for="campo in campos" :key="campo.counter" :class="{'list-item-selected': campo.isativo == 'ON'}">

                                        <td>
                                            <img
                                                class="img-info-box-lista"
                                                :class="{ 'turning-grey': campo.isativo == 'ON' }"
                                                src="@assets/img/Cards/Campo.svg"
                                                alt="Ícone de campo" />
                                        </td>

                                        <td class="amostraTitle"> <span>{{campo.titulo}}</span> <i @click="sendInfosToShowCampoInfo(campo)" data-toggle="modal" data-target="#showCampoInformacao" class="fa fa-info-circle"></i> <i v-if="!campo.isEditionLocked && campo.isativo != 'OFF'" @click="campoEdit = campo" data-toggle="modal" data-target="#updateCampo" class="fa fa-pen ml-2" style="margin: 0; padding: 0;"></i> </td>


                                        <td>
                                            <span class="second-info">Criado em {{campo.ano}}</span>
                                            <!-- <span v-if="campo.isativo != 'ON'" class="second-info">Criado em {{campo.ano}}</span> -->
                                            <!-- <span v-else class="second-info">Campo Ativo</span> -->
                                        </td>

                                        <td>
                                            <!-- <span v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == true" class="third-info-non-clickable">Campo Inativo</span> -->
                                            <span v-if="campo.isativo == 'INATIVO'" class="third-info-non-clickable">Campo Inativo</span>
                                            <span v-else-if="campo.isativo == 'OFF'" class="third-info-non-clickable">Campo Encerrado</span>
                                            <span v-else-if="campo.isativo == 'ON'" class="third-info-non-clickable">Campo Ativo</span>
                                        </td>

                                        <!-- <td>{{ campo.curinga }}</td> -->

                                        <td>
                                            <div class="controlGroup">
                                                <router-link :to="{name: 'InstituicaoMenuCampo', params: { idCampo: campo.idamostra }}" >
                                                    <Button
                                                        value="Ver Campo"
                                                        class="btnLista"
                                                        section="instituicao-campo"
                                                        :isOutline="true"
                                                    />
                                                </router-link>

                                                <Button
                                                    v-if="campo.isativo == 'ON' && userPerm.permissao != 'APLICADOR'"
                                                    @click="encerraCampo(campo.idamostra)"
                                                    class="btnLista"
                                                    value="Encerrar Campo"
                                                    section="instituicao-cadastrar"
                                                    :isOutline="true"
                                                />

                                                <Button
                                                    v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == false"
                                                    @click="iniciaCampo(campo.idamostra)"
                                                    class="btnLista"
                                                    value="Iniciar Campo"
                                                    section="salvar"
                                                    :isOutline="true"
                                                />

                                                <Button
                                                    v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR'"
                                                    @click="excluirCampo(campo.idamostra)"
                                                    class="btnLista"
                                                    value="Excluir Campo"
                                                    section="descartar"
                                                    :isOutline="true"
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </transition-group>


                    <transition-group v-else>
                        <h2 class="title-style-box-lista">Nenhum campo encontrado.</h2>
                    </transition-group>
                </div>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>
        </div>
    </div>

</template>

<script>
 import Button from '@components/Button'
 import ModalCentral from '@components/ModalCentral'
 import Breadcrumb from '@components/Breadcrumb'
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
 import Swal from 'sweetalert2';
 import SkeletonList from '@components/SkeletonUI/SkeletonList'
 import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'

 export default {
     name: 'ListarFormularios',
     data (){
         return {
             dataToShowCampoInfo: {},
             campoEdit: {},
             nomeInstituicao: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             isLoadedInfo: false,
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: ''
                 }
             ]
         }
     },
     async created() {

         this.teams = this.$store.getters.getListTeams

         window.scrollTo(0, 0);
         const payload = {
             idInstituicao: this.$route.params.idInstituicao
         }
         await this.$store.dispatch('setUserPerm', payload)

         this.isLoadedInfo = false
         await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
         this.isLoadedInfo = true
     },
     computed: {
         userPerm() {
             return this.$store.getters.getUserPerm
         },
         totalCampos() {
             return this.$store.getters.getListCampos?.length || 0;
         },
         campos() {
             var campos = this.$store.getters.getListCampos
             // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
             var query = campos;

             if (this.searchByName.trim() != '') {
                 var filtered = campos?.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                 if(filtered?.length != 0){
                     query = filtered
                 }

                 else{
                     query = false
                 }
             }

             //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
             // if (this.selectedValue == 1) {
             //     query = campos;
             // }
             //     //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
             // if (this.selectedValue == 2) {
             //     //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
             //     if (this.searchByName.trim() == '') {
             //         query = campos;
             //     }
             //     //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
             //     else {
             //         var filtered = campos?.filter(campo =>campo.titulo.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
             //         if(filtered?.length != 0){
             //             query = filtered
             //         }
             //
             //         else{
             //             query = false
             //         }
             //     }
             // }

             return query;
         },
         rawListCampoFormularios() { return this.$store.getters.getListCampoFormularios },
     },
     components: {
         Button,
         ModalCentral,
         Breadcrumb,
         InstitutionInfo,
         SkeletonList,
         MemberQnts,
     },
     methods: {
         setSelectedValue() {
             this.selectedValue = this.$refs.filtersearchname.value
         },

         sendInfosToShowCampoInfo(campo) {
             this.dataToShowCampoInfo = campo
         },
         hasCampoAtivo() {
             var hasAtivo = false
             this.campos.forEach((value) => {
                 if (value.isativo == 'ON') {
                     hasAtivo =  true
                 }
             })
             return hasAtivo
         },
         async encerraCampo(idAmostra){
             const result = await Swal.fire({
                 title: 'Deseja mesmo encerrar o campo?',
                 text: 'Uma vez encerrado, ele não poderá ser reaberto.',
                 icon: "question",
                 showCancelButton: true,
                 confirmButtonColor: "#3085d6",
                 cancelButtonColor: "#d33",
                 cancelButtonText: "Cancelar",
                 confirmButtonText: "Encerrar Campo",
             })

             if (result.isConfirmed) {
                 const payload = {
                     idInstituicao: this.$route.params.idInstituicao,
                     idAmostra: idAmostra
                 }
                 await this.$store.dispatch('setEncerraCampo', payload)
                 await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
             }
         },

         async iniciaCampo(idAmostra){
             const result = await Swal.fire({
                 title: 'Deseja mesmo inicar o campo?',
                 text: 'Uma vez iniciado, você só podera modificar alguma entidade do campo (membro, formulário, unidades e turmas) caso não possua aplicação vinculada.',
                 icon: "question",
                 showCancelButton: true,
                 confirmButtonColor: "#3085d6",
                 cancelButtonColor: "#d33",
                 cancelButtonText: "Cancelar",
                 confirmButtonText: "Iniciar Campo",
             })

             if (result.isConfirmed) {

                const payloadCampoForms = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idAmostra: idAmostra
                }
                await this.$store.dispatch('setListCampoFormularios', payloadCampoForms)
                if(this.rawListCampoFormularios?.length == 0){
                    const resultCampoForm = await Swal.fire({
                        title: 'Não há formulários selecionados no campo, deseja iniciar o campo mesmo assim?',
                        text: 'É necessário ao menos um formulário para realizar aplicações no campo.',
                        icon: "question",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        cancelButtonText: "Cancelar",
                        confirmButtonText: "Iniciar Campo",
                    })
                    if (!resultCampoForm.isConfirmed) {
                        return
                    }
                }

                const payload = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idAmostra: idAmostra
                }
                await this.$store.dispatch('setIniciaCampo', payload)
                await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
             }

         },

         async excluirCampo(idAmostra){
             const result = await Swal.fire({
                 title: 'Deseja mesmo excluir o campo?',
                 text: 'Ao excluir, você não terá mais acesso às entidades (membro, formulário, unidades e turmas) do campo.',
                 icon: "question",
                 showCancelButton: true,
                 confirmButtonColor: "#3085d6",
                 cancelButtonColor: "#d33",
                 cancelButtonText: "Cancelar",
                 confirmButtonText: "Excluir Campo",
             })

             if (result.isConfirmed) {
                 const payload = {
                     idInstituicao: this.$route.params.idInstituicao,
                     idAmostra: idAmostra
                 }
                 await this.$store.dispatch('setDeleteAmostra', payload)
                 await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)
             }
         },
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idInstituicao

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>

@import "../../../assets/css/Dados/list.css";

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/roxoMaisClaro.webp");

     border: 1px solid #958db1;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     color: #00853b;
 }

 .title-row {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }


 .text-info-personal {
     color: #fff;
     font-weight: 450;
     font-size: 1.1rem;
     text-align: justify;
 }

 .title-info{
     font-weight: 600;
     font-size: 1.6rem;
     color: #fff;
     margin: 0;
 }
 .title-style{
     margin: 0;
     padding: 0;
 }

 h3.title-info{
     margin-top: 1.6rem;
     font-size: 1.25rem;
 }


 .label-filtro {
     font-weight: 600;
 }

 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .separator {
     border: none;
     height: 1px;
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }



 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
         align-items: flex-start;
     }

     .filter-box{
         margin: 0 3rem;
     }
 }

 .breadcrumb-style{
     display: inline-flex;
 }

 .turning-grey{
     filter: grayscale(100%);
 }

 .second-info {
     width: 20%;
     text-align: center;
 }

 .third-info {
     cursor: pointer;
     user-select: none;
     font-weight: 500;
     transition: all ease 0.2s;
 }

 .third-info-non-clickable {
     color: #212529;
     cursor: default;
     user-select: none;
     font-weight: normal;
     transition: all ease 0.2s;
 }

 .last-info {
     color: #EB3E3E;
     cursor: pointer;
     user-select: none;
     transition: all ease 0.3s;
     font-weight: 500;
     text-align: right;
 }

 .last-info:hover {
     color: #8f1c1c;
 }

 .info-hide {
     opacity: 0;
     pointer-events: none;
 }

 .box-img {
     flex-basis: 5%;
 }

 .img-info {
     width: auto;
     max-height: 72px;
     margin: 0.2rem;
 }

 @media screen and (min-width: 901px) {
     .first-info {
         flex-basis: 35%;
         text-align: left;
     }
     .first-info-short {
         flex-basis: 35%;
         text-align: left;
     }
 }

 @media screen and (max-width: 680px) {
     .first-info {
         text-align: center;
         width: -webkit-fill-available;
     }
     .first-info-short {
         text-align: center;
         width: -webkit-fill-available;
     }
     .second-info, .third-info{
         width: 100%;
         text-align: center;
         margin-top: .5rem;
     }
     .info-hide{
         display: none;
     }
     .box-lista{
         place-content: center;
     }
 }

 .slide-fade-enter-active {
     transition: all .2s ease;
 }
 .slide-fade-leave-active {
     transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
 }
 .slide-fade-enter, .slide-fade-leave-to {
     transform: translateX(-40px);
     opacity: 0;
 }

 .fa-info-circle {
     color: #ffac30;
     font-size: 1.1rem;
     cursor: pointer;
 }

 .btnAdd {
     box-sizing: border-box;
     border: none;
 }

 :deep(.btnAdd div) {
     background-clip: text;
     background-repeat: repeat;
     background-size: 256px;
     color: #00853B;
     padding: 0.5rem 0;
     transition: 0.4s;
 }

 :deep(.btnLista div) {
     color: #fff;
     text-align: center;
 }

 .btnAdd, .btnLista {
     background-color: white;
     text-align: center;
 }

.amostraTitle {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    border: none;
}

.amostraTitle > span {
    /* flex: 1; */
    flex: 1 0 min-content;
    text-align: center;
}

.amostraTitle > i {
    flex: 0;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

table {
  border-collapse: collapse;
}

tr {
  border-bottom: 1pt solid #e0e0e0;
}

.table-row > tr:last-child{
    border-bottom: 0 !important;
}
</style>
