//---------------------------------------------
// Boas Práticas Module
//---------------------------------------------
// Módulo para gerenciar os dados de boas práticas

import axios from '@middleware/api.js'
import Cookies from 'js-cookie'

var JWT = () => {
  return Cookies.get('accessToken') ? Cookies.get('accessToken') : 'JWT VAZIO'
};

// STATES
const state = () => ({
  listBoasPraticas: [],
  praticaCreated: false,
  creationMessage: "",
  praticaUpdated: false,
  updateMessage: "",
  praticaRated: false,
  praticaDeleted: false,
  sections: [],
  categories: [],
  praticaSectionUpdated: null,
  praticaCategoryUpdated: null,
  praticaDownloaded: null,
});

// MUTATIONS
const mutations = {
  setListBoasPraticas(state, payload) {
    state.listBoasPraticas = payload;
  },

  setPraticaCreated(state, payload) {
    state.praticaCreated = payload;
  },

  setCreationMessage(state, payload) {
    state.creationMessage = payload;
  },

  setPraticaUpdated(state, payload) {
    state.praticaUpdated = payload;
  },

  setUpdateMessage(state, payload) {
    state.updateMessage = payload;
  },

  setPraticaRated(state, payload) {
    state.praticaRated = payload;
  },

  setPraticaDeleted(state, payload) {
    state.praticaDeleted = payload;
  },

  setPraticaSections(state, payload) {
    state.sections = payload;
  },

  setPraticaSectionUpdated(state, payload) {
    state.praticaSectionUpdated = payload;
  },

  setPraticaCategories(state, payload) {
    state.categories = payload;
  },

  setPraticaCategoryUpdated(state, payload) {
    state.praticaCategoryUpdated = payload;
  },

  setPraticaDownloaded(state, payload) {
    state.praticaDownloaded = payload;
  },
};

// ACTIONS
const actions = {
  async loadListBoasPraticas({ commit }, ) {
    await axios
      .get(`boas_praticas`, {
        headers: {
          Authorization: `Bearer ${JWT()}`,
        }
      })
      .then(response => {
        commit('setListBoasPraticas', response.data.data);
      })
      .catch(error => console.log('Erro ao carregar boas práticas: ', error));
  },

  async createBoasPraticas({ commit }, payload) {
    commit('setPraticaCreated', null);
    commit('setCreationMessage', "");

    let formData = new FormData();
    formData.append("titulo", payload?.titulo);
    formData.append("secao1", payload?.secao1);
    formData.append("autor", payload?.autor);
    formData.append("regiao", payload?.regiao);
    formData.append("idade", payload?.idade);
    formData.append("link", payload?.link);
    formData.append("pdf", payload?.pdf);
    payload?.tematicas.forEach(v => formData.append("tematicas[]", v));
    formData.append("secao2", payload?.secao2);
    formData.append("secao3", payload?.secao3);
    formData.append("secao4", payload?.secao4);
    formData.append("secao5", payload?.secao5);

    await axios
      .post(`boas_praticas`, formData, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        commit('setPraticaCreated', true);
        commit('setCreationMessage', response.data.status);
      })
      .catch(err => {
        console.log(err);
        commit('setPraticaCreated', false);

        const messageFilters = [
          msg => msg?.includes("empty") ? "Um ou mais campos vazios" : null,
        ];

        commit(
          'setCreationMessage',
          messageFilters.reduce(
            (curMsg, filter) => curMsg || filter(err.response?.message?.message[0]),
            null
          ) || "Erro na criação da prática"
        );
      });
  },

  async updateBoasPraticas({ commit }, payload) {
    commit('setPraticaUpdated', null);
    commit('setUpdateMessage', "");

    let formData = new FormData();
    formData.append("titulo", payload?.titulo);
    formData.append("secao1", payload?.secao1);
    formData.append("autor", payload?.autor);
    formData.append("regiao", payload?.regiao);
    formData.append("idade", payload?.idade);
    formData.append("link", payload?.link);
    payload?.tematicas.forEach(v => formData.append("tematicas[]", v));
    formData.append("secao2", payload?.secao2);
    formData.append("secao3", payload?.secao3);
    formData.append("secao4", payload?.secao4);
    formData.append("secao5", payload?.secao5);
    if (payload.pdf !== undefined) formData.append("pdf", payload.pdf || undefined);

    await axios
      .put(`boas_praticas/${payload.idPratica}`, formData, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        commit('setPraticaUpdated', true);
        commit('setUpdateMessage', response.data.status);
      })
      .catch(err => {
        commit('setPraticaUpdated', false);
        commit('setUpdateMessage', err);
        console.log(err);
      });
  },

  async ratePratica({ commit }, payload) {
    commit('setPraticaRated', null);

    await axios
      .patch(`boas_praticas/${payload.idPratica}`, {
        avaliacao: payload.rating,
        avaliacaoText: payload.ratingTxt || "",
      }, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        console.log(response.data);
        commit('setPraticaRated', true);
      })
      .catch(err => {
        console.log(err);
        commit('setPraticaRated', false);
      });
  },

  async deletePratica({ commit }, payload) {
    commit('setPraticaDeleted', null);

    await axios
      .delete(`boas_praticas/${payload.idPratica}`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        console.log(response.data);
        commit('setPraticaDeleted', true);
      })
      .catch(err => {
        console.log(err);
        commit('setPraticaDeleted', false);
      });
  },

  async setPraticaSections({ commit }, ) {
    await axios
      .get(`boas_praticas/secoes`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        commit('setPraticaSections', response?.data?.data);
      })
      .catch(err => {
        console.log(err);
        commit('setPraticaSections', null);
      });
  },

  async updatePraticaSection({ commit }, payload) {
    commit('setPraticaSectionUpdated', null);

    await axios
      .put(`boas_praticas/secoes/${payload.idSecao}`, {
        nome: payload.nome
      }, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(() => { commit('setPraticaSectionUpdated', true); })
      .catch(err => {
        console.log(err);
        commit('setPraticaSectionUpdated', false);
      });
  },

  async setPraticaCategories({ commit }, ) {
    await axios
      .get(`boas_praticas/categorias`, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(response => {
        commit('setPraticaCategories', response?.data?.data);
      })
      .catch(err => {
        console.log(err);
        commit('setPraticaCategories', null);
      });
  },

  async updatePraticaCategory({ commit }, payload) {
    commit('setPraticaCategoryUpdated', null);

    await axios
      .put(`boas_praticas/categorias/${payload.idCategoria}`, {
        nome: payload.nome
      }, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(() => { commit('setPraticaCategoryUpdated', true); })
      .catch(err => {
        console.log(err);
        commit('setPraticaCategoryUpdated', false);
      });
  },

  async registerDownloadPratica({ commit }, payload) {
    await axios
      .post(`boas_praticas/download/${payload.idPratica}`, {
        email: payload.email,
        atuacao: payload.atuacao,
      }, {
        headers: {
          'Authorization': `Bearer ${JWT()}`
        }
      })
      .then(() => commit("setPraticaDownloaded", true))
      .catch(err => {
        console.log(err);
        commit("setPraticaDownloaded", false);
      });
  },
};

const getters = {
  getListBoasPraticas(state) {
    return state.listBoasPraticas || [];
  },

  getPraticaCreated(state) {
    return state.praticaCreated;
  },

  getCreationMessage(state) {
    return state.creationMessage;
  },

  getPraticaUpdated(state) {
    return state.praticaUpdated;
  },

  getUpdateMessage(state) {
    return state.updateMessage;
  },

  getPraticaRated(state) {
    return state.praticaRated;
  },

  getPraticaDeleted(state) {
    return state.praticaDeleted;
  },

  getPraticaSections(state) {
    return state.sections;
  },

  getPraticaSectionUpdated(state) {
    return state.praticaSectionUpdated;
  },

  getPraticaCategories(state) {
    return state.categories;
  },

  getPraticaCategoryUpdated(state) {
    return state.praticaCategoryUpdated;
  },

  getPraticaDownloaded(state) {
    return state.praticaDownloaded;
  }
};

export default {
  state,
  mutations,
  actions,
  getters,
};
