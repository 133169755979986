<template>
  <div class="container">
     <div>
      <!-- MODAL CENTRAL DO PRIMEIRO BOTÃO -->
      <ModalCentral
        :componentName="`Search${infos.type}`"
        :title="`Buscar ${infos.type == 'Group' ? 'Grupo' : 'Instituição'}`"
        :idName="`Search${infos.type}`"
      />
       <!-- MODAL CENTRAL DO SEGUNDO BOTÃO -->
      <ModalCentral
        :componentName="`Create${infos.type}`"
        :title="`Criar ${infos.type == 'Group' ? 'Grupo' : 'Instituição'}`"
        :idName="`Create${infos.type}`"
        :textured="true"
      />
    </div>


    <!-- SE A PESSOA PERTENCE A ALGUM GRUPO DE PESQUISA/INSTITUIÇÃO -->
    <div class="row">
      <div class="col">
        <h1 class="title-style pb-3">{{ infos.title }}</h1>
      </div>
    </div>
    
    <div class="row align-items-center">

      <!----------- PRIMEIRO BOTÃO ------------>
      <div class="col-md grow mb-3" :data-target="`#Search${infos.type}`" data-toggle="modal">
        <div class="d-flex btn btn-light btn-block shadow-light buttonGrid">
          <div class="ml-2 mr-3 my-auto buttonIcon">
            <i class="fas fa-search-plus fa-2x"></i>
          </div>
          <div class="text-left">
            <h3 class="font-weight-semibold mb-3">{{ infos.firstButtonTitle }}</h3>
            <p>{{ infos.firstButtonText }}</p>
          </div>
        </div>
      </div>

      <!----------- SEGUNDO BOTÃO ------------>
      <div class="col-md grow mb-3" :data-target="`#Create${infos.type}`" data-toggle="modal">
        <div class="d-flex btn btn-light btn-block shadow-light buttonGrid">
          <div class="ml-2 mr-3 my-auto buttonIcon">
            <i class="fas fa-plus-circle fa-2x"></i>
          </div>
          <div class="text-left">
            <h3 class="font-weight-semibold mb-3">{{ infos.secondButtonTitle }}</h3>
            <p>{{ infos.secondButtonText }}</p>
          </div>
        </div>
      </div>

      <!----------- IMAGEM SVG ------------>
      <div class="col-md">
        <img
          class="img-max-size"
          src="@assets/img/Dados/gerenciar-instituicoes.svg"
          alt="Fundo"
        />
      </div>

    </div>

    <!-- SE A PESSOA NÃO PERTENCE A NENHUM GRUPO DE PESQUISA/INSTITUIÇÃO -->
    <div v-if="(Object.keys(teams || {}) ?? []).length == 0">
      <div class="row mb-3">
        <div class="col">

          <h3 v-if="infos.type == 'Institution'" class="title-style font-weight-light mt-4">
            Você não pertence a
            <span class="font-weight-bold">nenhuma</span> instituição.
          </h3>

          <h3 v-else class="title-style font-weight-light mt-4">
            Você não pertence a
            <span class="font-weight-bold">nenhum</span> grupo.
          </h3>

        </div>
      </div>

      <div class="row">
        <div class="col d-flex justify-content-center">
          <img
            class="img-max-size-40vw"
            src="@assets/img/not-found-image.png"
            alt="Fundo"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalCentral from '@components/ModalCentral';

export default {
  name: "Panel",
  methods: {
    async createGroup(){
      const payload = {
          nome: 'blablabla',
          descricao: 'blablabla',
          atuacao: 'blablabla',
          rua: 'blablabla',
          numero: 777,
          complemento: 'blablabla',
          bairro: 'blablabla',
          idCidade: 1,
          imagem: null
      }
      await this.$store.dispatch('setCreateGroup', payload)
    }
  },

  props: {
    infos: Object,
    teams: Object,
  },

  components: {
    ModalCentral,
  },

  
};
</script>

<style scoped lang="scss">
@import "../../../assets/scss/_variables.scss";

.fa, .far, .fas {
   font-family: "Font Awesome 5 Free";
 }

p {
  margin-bottom: 1rem;
}

button {
  padding: 2rem;
  text-align: left;
  border: none;
  border-radius: 10px;
}

.card-panel {
  display: grid;
  grid-template-columns: minmax(00px, 1fr) minmax(300px, 1fr);
  padding: 20em;
  background: #FFFFFF;
}

.title-style {
  font-size: 2rem;
  font-weight: 700;
  color: var(--font-white);
}


p {
  white-space: break-spaces !important;
}

.img-btn img {
  max-width: 48px;
}

.btn-primary {
  /* border: 1px solid $verdeEscuro-eapi !important; */
}
.btn-primary:hover {
  /* background: $verdeEscuro-eapi !important;
     border: 1px solid $verdeEscuro-eapi !important; */
}
.btn-light {
  padding: 1rem;
  background: $white !important;
  box-sizing: border-box !important;
  border-radius: 10px !important;
}

.btn-light h3{
  font-size: 1.2rem;
  text-transform: none !important;
  font-weight: 700;
  color: var(--font-green);
}

.btn-light p{
  font-size: 1rem;
  text-transform: none !important;
  hyphens: auto;
  font-weight: 400;
  color: $mid-gray;
}

.shadow-light{
  box-shadow: 4px 6px 6px -2px rgb(0 0 0 / 25%);
}
.btn p {
  margin: 0;
  font-style: normal;
  font-family: 'Poppins', sans-serif !important;
  font-weight: 300;
  text-transform: none !important;
  color: #6B6B6B;
}

.btn {
  padding: 1rem 1.25rem !important;
  color: #fcfcfc;
  border: none !important;
}

.img-max-size {
  width: 100%;
  min-width: 15vw;
}

.img-max-size-40vw{
  max-width: 40vw;
}

.disabled {
  background-color: #afc9c9;
}

.buttonGrid {
   display: grid !important;
   grid-template-columns: 1fr auto;
   grid-template-rows: auto;
 }

.buttonIcon {
  color: var(--font-green);
   padding: 5px;
 }

@media (max-width: 767px) {
  .hide-on-mobile {
    display: none;
  }
}

.font-weight-semibold {
     font-weight: 600;
}
</style>
