<template>
    <ModalCentral
        v-if="userPerm"
        componentName="EditCard"
        title="Editar card"
        idName="editCard"
        :data="{ idTipo: idTipo }"
    />

    <div class="bg">
        <h2 class="pageTitle">Avaliações realizadas</h2>
        <div class="card">
            <h2 class="text">
                <i class="fas fa-pen editIcon"
                   v-if="userPerm"
                   @click="setIdTipo(47)"
                   data-toggle="modal"
                   data-target="#editCard" />
                {{ cards[47]?.titulo }}
            </h2>

            <div class="resource">
                <img
                    :src="getImage(cards[47]?.imagem[0])"
                    alt="Avaliações" />
                <div class="info">
                    <div class="text" v-html="cards[47].corpoTexto" />

                    <br />

                    <a
                        v-for="(button, idx) in cards[47]?.botao"
                        target="_blank"
                        :key="idx"
                        :href="button?.link">
                        <Button
                            :value="button?.texto"
                            class="cardBut resourceBut"
                            :isOutline="false" />
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script type="text/javascript">
 import Button from '@components/Button';
 import ModalCentral from '@components/ModalCentral';
 import axios from '@middleware/api.js';

 export default {
     name: "AvaliacoesRealizadas",
     components: {
         Button,
         ModalCentral,
     },
     data() {
         return {
             idTipo: null,
         }
     },
     methods: {
         setIdTipo(id) { this.idTipo = id; },
         getImage(path) {
             return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
         },
     },
     computed: {
         userPerm() {
             try{
                 return this.$store.getters.getUserInfo.data.isAdmin;
             } catch(e) {
                 return false;
             }
         },
         cards() { return this.$store.getters.getListCards; },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;

     padding: 1em;
     box-sizing: border-box;
 }

 .pageTitle {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
     width: 100%;
     text-align: center;
 }

 .card {
     background-repeat: repeat;
     background-size: 256px;
     width: 100%;
     box-shadow: inset 0px 4px 4px 4px rgba(0, 0, 0, 0.25);
     border-radius: 1em;
     padding: 1em;
     box-sizing: border-box;
     margin-bottom: 10px;
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.png");
 }

 .card > h2 {
     width: 100%;
     text-align: right;
 }

 .resource {
     display: flex;
     flex-flow: row wrap;
     align-items: center;
 }

 .resource > img {
     flex: 1 0 30%;
     min-width: 250px;
     object-fit: cover;
     border-radius: 1em;
     box-sizing: border-box;
 }

 .resource > .info {
     flex: 1 0 70%;
     padding: 10px;
     box-sizing: border-box;
 }

 .cardBut {
     border-radius: 50px;
     background: #B1514E;
     width: max-content;
     margin: 5px 0;
     float: right;
     box-sizing: border-box;
     height: max-content;
     padding-left: 3em;
     padding-right: 3em;
 }

 .text {
     background-clip: text;
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     color: transparent;
 }

 .editIcon {
     float: right;
     color: #fcfaf2;
     font-size: 1.3rem;
     cursor: pointer;
     transition: 0.4s;
     box-sizing: border-box;
     margin: 5px;
 }

 .editIcon:hover {
     transform: scale(1.1);
     transform-origin: center center;
 }
</style>
