<template>
<ModalCentral
  title="Bem vindo!"
  :componentName="componentModal"
  idName="popupInit"
  :disableClose="true"
  :data="{ changeComponent: (name) => componentModal = name, }"
  />

<button
  ref="openInit"
  data-toggle="modal"
    data-target="#popupInit"
    data-backdrop="static"
    data-keyboard="false"
    hidden />

  <div class="transitionContainer">
    <div v-if="!cypher" class="loader">
      <h2>Link inválido!</h2>
    </div>
    <div v-else-if="!data && !formLoaded" class="loader">
      <div class="lds-dual-ring"></div>
      <h2>Carregando...</h2>
    </div>
    <div v-else-if="!data" class="loader">
      <h2 style="text-align: center">{{ formEncerrado ? 'Este formulário não está aceitando mais respostas' : 'Link do formulário inválido! Verifique o link e tente novamente.' }}</h2>
    </div>

    <div v-if="data" class="formMain">
      <div class="card sections">
        <div class="header">
          Seções do formulário
        </div>

        <div class="map">
          <SectionMap
            v-for="n in (totalSections ? [...Array(totalSections + 1).keys()].slice(1) : [])"
            :key="n"
            @click="(n == (_sectionN + 1) && proximaEnabled) ? next() : goToSection(n)"
            :current="_sectionN"
            :proxima="proximaEnabled"
            :connector="n != totalSections"
            :id="`mapSecao${n}`"
            :n="n" />
        </div>

        <div class="footer">
          <div class="load">
            <div class="loadBar">
              <div
                class="loadContent"
                :style="{
                        'width': `${(_sectionN*100/totalSections).toFixed(2)}% !important`,
                        }" />
            </div>
            <span>{{ sectionN }} de {{ totalSections }}</span>
          </div>
          <button
            @click="sendForm"
            :class="{
                    'greenGradient': finished,
                    'clickable': finished,
                    'disabled': !finished,
                    }">
            Enviar
          </button>
        </div>
      </div>

      <div class="card form">
        <div class="d-flex header justify-content-between align-items-center p-3">
          <div>
            {{ currentName }}
            <br />
            <span>Seção {{ sectionString(sectionN) }}</span>
          </div>
           <div class="personal-data align-items-start text-center">
                <span>{{ data?.formName}}</span><br/>
                <template v-if="identificador != 'código'">
                  <span>{{ identificador }}: {{ identificacao }}</span>
                </template>
            </div>
          <button
            @click="clearAll"
            class="greenGradient clickable">
            Cancelar
          </button>
        </div>

        <div class="innerForm" ref="innerForm">
          <QuestionCard
            v-for="(question, idx) in currentQuestions"
            :id="`card${question.cod}`"
            :key="question.uuid"
            :question="question"
            :questionList="currentQuestions"
            :currentIdx="idx"
            class="innerQuestion" />
        </div>

        <div class="footer">
          <div class="load">
            <div class="loadBar">
              <div
                class="loadContent"
                :style="{
                        'width': `${(currentAnswered*100/totalQuestions).toFixed(2)}% !important`,
                        }" />
            </div>
            <span>{{ currentAnswered }} de {{ totalQuestions }}</span>
          </div>
          <button
            :disabled="sectionN == 1"
            @click="prev"
            :class="{
                    'disabled': sectionN == 1,
                    'greenGradient clickable': sectionN > 1,
                    }" >
            Seção anterior
          </button>
          <button
            @click="next"
            :class="{
                    'disabled': !proximaEnabled,
                    'greenGradient clickable': proximaEnabled,
                    }" >
            Próxima seção
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import SectionMap from '../../components/TransicaoEscolaTrabalho/SectionMap';
import QuestionCard from '../../components/TransicaoEscolaTrabalho/QuestionCard';
import ModalCentral from "@components/ModalCentral";
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: 'left',
        y: 'bottom',
    }
});

export default {
    name: "FormTransicao",
    components: {
        // Header,
        SectionMap,
        QuestionCard,
        ModalCentral,
    },
    data() {
        return {
            sectionN: 1,
            maxHours: 3,
            componentModal: "TransicaoInitModal",
            sendingFlag: false,
        };
    },
    watch: {
      proximaEnabled(updated, old) {
        if (old && !updated)
        this.$store.dispatch('setSection', this.sectionN);
      },
      initModalEnabled(v) {
        if (v) this.$refs.openInit.click();
      }
    },
    beforeCreate() {
        if (
            this.$store.getters.getFormStarted &&
                (
                    (new Date().getTime() - new Date(this.$store.getters.getFormStartTime).getTime()) / 1000
                ) > this.maxHours*60*60
        ) this.$store.dispatch("clearAll");
    },
  async beforeMount() {
        await this.$store.dispatch("loadFormTransicao", this.cypher);
  },
    async mounted() {
        this.sectionN = this._sectionN;
    },
    computed: {
      initModalEnabled() {
        return this.formLoaded && !(this.$store.getters.getFormStarted) && (this.cypher) && (this.data);
      },
        proximaEnabled() {
            return this.currentAnswered >= this.totalQuestions && this.sectionN < this.totalSections;
        },
        cypher() {
            return this.$route.params.cypher;
        },
        data() {
            return this.$store.getters.getFormTransicao;
        },
        identificacao() {
            return this.$store.getters.getIdentificacao || "";
        },
      identificador() {
        return this.data?.identificador.toUpperCase();
      },
        formLoaded() {
            return this.$store.getters.getFormTransicaoLoaded;
        },
        formEncerrado() {
            return this.$store.getters.getErrFormEncerrado;
        },
        _sectionN() {
            return this.$store.getters.getSection;
        },
        totalSections() {
            return this.data?.categories?.length;
        },
        currentQuestions() {
            try {
                return this.data?.categories[this.sectionN - 1]?.questions || [];
            } catch (e) {
                return [];
            }
        },
        totalQuestions() {
            const num = this.currentQuestions?.filter(q => { return q.visible || this.$store.getters.getGotoSet(q.cod); }).length +
                this.currentQuestions.filter(q => {
                    const lastOption = q.questionType?.list?.value?.at(q.questionType?.list?.value?.length - 1);
                    return (lastOption?.toInsert && lastOption?.id == this.$store.getters.getAnswerId(q.cod)?.answer);
                }).length +
                  this.currentQuestions.filter(q => {
                    const _ans = this.$store.getters.getAnswerId(q.cod)?.answer;
                    const extraSelectedOpt = q?.questionType?.radio?.options?.find(o => o.id == _ans)?.extraQuestions;
                    return (extraSelectedOpt != null);
                  }).length;
          return num;
        },
        currentName() {
            try {
                return this.data?.categories[this.sectionN - 1]?.categoryName || "...";
            } catch (e) {
                return "...";
            }
        },
        answers() {
            return this.$store.getters.getAllAnswers;
        },
        currentAnswered() {
          let count = 0;
          for(const question of this.currentQuestions) {
            if (!(question.visible || this.$store.getters.getGotoSet(question.cod))) continue;

            const id = question.cod,
                  _ans = this.$store.getters.getAnswerId(id)?.answer;
            if (!_ans) continue;

            count += 1;

            const insertSelectedOpt = question?.questionType?.list?.value?.find(o => o.id == _ans)?.toInsert,
                  extraSelectedOpt = question?.questionType?.radio?.options?.find(o => o.id == _ans)?.extraQuestions;

            if (insertSelectedOpt && this.$store.getters.getInsertSet(insertSelectedOpt))
              count++;

            if (extraSelectedOpt && this.$store.getters.getAnswerId(extraSelectedOpt?.cod)?.answer)
              count++;
          }

          return count;
        },
        finished() {
            return this.data?.categories.map(category => {
                let answeredCount = 0;

                const curQuestions = category?.questions || [];
                for(const question of curQuestions) {
                    if (!(question.visible || this.$store.getters.getGotoSet(question.cod))) continue;

                    const id = question.cod,
                          _ans = this.$store.getters.getAnswerId(id)?.answer;
                    if (!_ans) continue;

                    answeredCount += 1;

                    const insertSelectedOpt = question?.questionType?.list?.value?.find(o => o.id == _ans)?.toInsert,
                          extraSelectedOpt = question?.questionType?.radio?.options?.find(o => o.id == _ans)?.extraQuestions;

                    if (insertSelectedOpt && this.$store.getters.getInsertSet(insertSelectedOpt))
                        answeredCount++;

                    if (extraSelectedOpt && this.$store.getters.getAnswerId(extraSelectedOpt?.cod)?.answer)
                        answeredCount++;
                }

                const totalCount = curQuestions?.filter(q => { return q.visible || this.$store.getters.getGotoSet(q.cod); }).length +
                      curQuestions.filter(q => {
                          const lastOption = q.questionType?.list?.value?.at(q.questionType?.list?.value?.length - 1);
                          return (lastOption?.toInsert && lastOption?.id == this.$store.getters.getAnswerId(q.cod)?.answer);
                      }).length +
                      curQuestions.filter(q => {
                          const _ans = this.$store.getters.getAnswerId(q.cod)?.answer;
                          const extraSelectedOpt = q?.questionType?.radio?.options?.find(o => o.id == _ans)?.extraQuestions;
                          return (extraSelectedOpt != null);
                      }).length;

                return answeredCount >= totalCount;
            }).every(v => v);
        },
    },
    methods: {
        async sendForm() {
            if (this.sendingFlag || !this.checkAnswers() || !this.finished) return;
            this.checkAnswers();

            let currentIds = [];
            try {
                this.data?.categories?.forEach(cat => {
                    cat?.questions?.map(q => q.cod);
                });
            } catch(e) {
                notyf.open({
                    type: "error",
                    message: "Não foi possível obter informação de todas as questões",
                });
                console.log(e);
                return;
            }

            let err = false;
            for (const id of currentIds) {
                if (this.$store.getters.getAnswerId(id)?.answer == null) {
                    err = true;
                    break;
                }

                const question = this.currentQuestions.find(q => q.uuid == id),
                      lastOption = question.questionType?.list?.value?.at(question.questionType?.list?.value?.length - 1);
                if (!this.$store.getters.getInsertSet(lastOption?.toInsert)) {
                    err = true;
                    break;
                }
            }

            if (err) {
                notyf.open({
                    type: "error",
                    message: "Uma ou mais questões não foram respondidas, por favor confira suas respostas",
                });
                return;
            }

            if (this.sendingFlag) {
                notyf.open({
                    message: "O formulário está sendo enviado, favor aguarde",
                });
                return;
            }

            this.sendingFlag = true;
            const sendingNoti = notyf.open({
                type: "info",
                background: "var(--light-green)",
                message: "Enviando dados...",
                duration: 0,
            });

            await this.$store.dispatch("sendForm", this.cypher);
            this.sendingFlag = false;
            notyf.dismiss(sendingNoti);
            if (this.$store.getters.getFormSent) {
                notyf.open({
                    type: "success",
                    message: "Formulário enviado com sucesso!",
                });
                document.location.reload();
            } else {
                notyf.open({
                    type: "error",
                    message: this.formEncerrado ? "Este formulário não está aceitando mais respostas" : "Erro no envio do formulário",
                });
            }
        },
        sectionString(section) {
            return section.toLocaleString('en-US', {
                minimumIntegerDigits: 2,
                useGrouping: false
            });
        },
        checkAnswers() {
          let item = null;
          if (this.sectionN >= this.totalSections || this.currentAnswered < this.totalQuestions) {

            for(const quest of this.currentQuestions) {
              const id = quest.cod,
                    q = this.$store.getters.getAnswerId(id),
                    _ans = q?.answer;

              if (!(quest.visible || this.$store.getters.getGotoSet(q?.cod))) continue;

              if (!_ans) {
                item = { ...quest };
                break;
              }

              const question = this.currentQuestions.find(q => q.cod == id),
                    lastOption = question.questionType?.list?.value?.at(question.questionType?.list?.value?.length - 1);

              if (lastOption?.id == _ans && (!(this.$store.getters.getInsertSet(lastOption?.toInsert)) || this.$store.getters.getAnswerId(lastOption?.extraQuestions?.cod))) {
                item = q;
                break;
              }

              const extraSelectedOpt = question?.questionType?.radio?.options?.find(o => o.id == _ans)?.extraQuestions;
              if (extraSelectedOpt && !this.$store.getters.getAnswerId(extraSelectedOpt?.cod)?.answer) {
                item = q;
                break;
              }
            }
          }

          if (item) {
            document.getElementById(`card${item?.cod}`).scrollIntoView();
            document.getElementById(`mapSecao${this._sectionN}`).scrollIntoView();
            notyf.open({
              type: "error",
              message: `Questão (${item?.questionItem}) não respondida!`,
            });
            return false;
          }

          return true;
        },
        next() {
            if (this.sectionN >= this.totalSections || !this.checkAnswers()) return;

            this.sectionN++;
            this.backTop();
            if (this.sectionN > this._sectionN)
                 this.$store.dispatch('setSection', this.sectionN);
         },
         prev() {
             if (this.sectionN <= 1) return;
             this.sectionN--;
             this.backTop();
         },
         backTop() {
             this.$refs.innerForm.scrollTop = 0;
         },
         goToSection(n) {
             if (n > this.sectionN && (!this.checkAnswers() || (n > this.sectionN && this.currentAnswered < this.totalQuestions) || n > this._sectionN)) return;
             this.sectionN = n;
         },
         clearAll() {
             const c = confirm("Tem certeza que quer confirmar o envio desse formulário? Todas as informações até aqui serão perdidas.");
             if (!c) return;
             this.$store.dispatch("clearAll");
             document.location.reload();
         },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 @import url('../../assets/css/TransicaoEscolaTrabalho/styling.css');

 .formMain {
     display: flex;
     flex-direction: row;
     flex-wrap: wrap-reverse;
     justify-content: space-around;
     align-items: stretch;
     gap: 1rem;
     padding: 0 15px;
     margin: 0 auto;
     max-width: var(--max-width);
     padding-bottom: 1rem;
     height: 100vh;
 }

 .sections {
     flex: 0 0 max-content;
 }

 .transitionContainer {
     min-height: 100vh;
     background: linear-gradient(to bottom, rgba(0, 133, 59, 0.8), rgba(0, 133, 59, 0));
     padding-top: 1rem;
 }

 .map {
     padding: 0 10px;
     height: 100%;
     overflow-y: auto;
 }

 .form {
     flex: 1 0 min-content;
 }

 .form > .header > span {
     font-size: 0.8em;
 }

 .form > .footer {
     margin-top: 0;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     align-items: center;
     gap: 15px;
 }

 .sections > .footer {
     margin-top: 0;
 }

 .form > .footer > button {
     flex: 1 0 min-content;
     max-width: max-content;
     height: min-content;
 }

 .form > .footer > .load {
     flex: 1 0 min-content;
 }

 .formHeader {
     padding: 0 15px 1rem 15px;
     max-width: var(--max-width);
     width: 100%;
     margin: 0 auto;
     display: flex;
     justify-content: space-between;
     flex-direction: row;
     flex-wrap: wrap;
     align-items: center;
     text-align: justify;
     gap: 15px;
 }

 .formHeader > h2, .formHeader > h3 {
    font-size: 12px!important;
     color: #4f4f4f;
 }

 .formHeader > h2 {
     flex: 1 0 min-content;
     text-align: left;
 }

 .formHeader > h3 {
     flex: 0 0 max-content;
 }

 .formHeader > button {
     flex: 0 0 auto;
 }

 .sections .footer {
     display: grid;
     grid-template-columns: 1fr min-content;
     gap: 5px;
     align-items: center;
 }

 .load span {
     font-size: 0.9em;
     line-height: 0.9em;
 }

 .sections .footer > button {
     font-size: 0.9em;
     padding: 9px 15px;
 }

 .innerForm {
     height: 100%;
     width: 100%;
     display: flex;
     flex-direction: row;
     flex-wrap: wrap;
     padding: 0 15px;
     justify-content: center;
     align-items: center;
     /* gap: 1rem; */
     overflow: auto;
 }

 .innerQuestion {
     flex: 1 0 100%;
 }

 .innerForm > .innerQuestion:first-child {
     margin-top: 1em;
 }

.personal-data {
    font-size: 1.2em;
 }
</style>
