<template>
    <div
        class="innerMap"
        :class="{
            'connector': connector,
            'done': n < current,
            'clickable': n <= current || (n == (current + 1) && proxima),
        }">
        <div
            class="indicator"
            :class="{
                'done': n < current || (n == (current + 1) && proxima),
                'current': n == current,
                'toDo': n > current,
            }">
            <i v-if="n < current" class="fas fa-check" />
            <i v-else-if="n > current && !(n == (current + 1) && proxima)" class="fas fa-lock" />
            <i v-else class="fas fa-pen" />
        </div>
        <span>Seção {{ sectionString  }}</span>
    </div>
</template>

<script type="text/javascript">
 export default {
     name: "SectionMap",
     props: {
         n: {
             type: Number,
             required: true,
         },
         current: {
             type: Number,
             required: true,
         },
         connector: {
             type: Boolean,
             required: false,
         },
         proxima: {
             type: Boolean,
             required: true,
         },
     },
     computed: {
         sectionString() {
             return this.n.toLocaleString('en-US', {
                 minimumIntegerDigits: 2,
                 useGrouping: false
             });
         },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 .innerMap {
     --indicator-size: 2.8em;
     width: 100%;
     display: grid;
     grid-template-columns: auto 1fr;
     align-items: center;
     margin: 10px auto;
     position: relative;
     box-sizing: border-box;
 }

 .indicator {
     display: flex;
     align-items: center;
     justify-content: center;
     width: var(--indicator-size);
     box-sizing: border-box;
     aspect-ratio: 1 / 1;
     font-size: 1rem;
     border-radius: 100%;
     background-color: white;
     color: white;
     position: relative;
     z-index: 2;
 }

 .innerMap.connector::before {
     content: '';
     position: absolute;
     background-color: var(--grey);
     width: 3px;
     left: calc(var(--indicator-size)/2);
     top: 50%;
     height: 50px;
     transform: translate(-50%, 0);
     z-index: -1;
 }

 .innerMap.connector.done::before {
     background-color: var(--dark-green);
 }

 .indicator.done {
     background-color: var(--dark-green);
 }

 .indicator.current {
     color: var(--dark-green);
     border: 3px solid var(--dark-green);
 }

 .indicator.toDo {
     color: var(--grey);
     border: 3px solid var(--grey);
 }

 .innerMap > span {
     font-weight: bold;
     padding: 3px;
     background-color: white;
     text-align: center;
     position: relative;
     border-radius: 8px;
 }

 .innerMap > span::before {
     position: absolute;
     content: '';
     height: 100%;
     width: 20px;
     left: -10px;
     top: 0;
     background-color: white;
     z-index: 1;
 }
</style>
