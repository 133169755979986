<template>
    <div class="root-dados"> 
        <div class="container list-data">

            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br/>

            <h3 class="title-info">Arquivos CSV</h3>

            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os arquivos em formato CSV da Instituição. Caso necessário, utilize o filtro abaixo para localizar o arquivo desejado.</p>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar campos por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>

            <hr class="separator">

            <div v-if="isLoadedInfo">
                <MemberQnts :totalQnt="totalCsvs" :filteredQnt="csvs?.length || 0" icon="clipboard" />

                <div class="list-box">
                    <transition-group name="slide-fade" v-if="csvs?.length > 0">

                        <div class = "table-wrap">
                            <table class="table mt-3">
                                <thead>
                                    <tr>
                                        <th scope="col">  </th>
                                        <th scope="col">Nome</th>
                                        <th scope="col">Criação</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="table-row" v-for="csv in csvs" :key="csv.id">

                                        <td>
                                            <img
                                                class="img-info-box-lista"
                                                :class="{ 'turning-grey': campo.isativo == 'ON' }"
                                                src="@assets/img/Cards/Campo.svg"
                                                alt="Ícone de campo" />
                                        </td>

                                        <td> {{campo.titulo}} <i @click="sendInfosToShowCampoInfo(campo)" data-toggle="modal" data-target="#showCampoInformacao" class="fa fa-info-circle"></i> </td>

                                        <td>
                                            <span class="second-info">Criado em {{campo.ano}}</span>
                                            <!-- <span v-if="campo.isativo != 'ON'" class="second-info">Criado em {{campo.ano}}</span> -->
                                            <!-- <span v-else class="second-info">Campo Ativo</span> -->
                                        </td>

                                        <td>
                                            <!-- <span v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == true" class="third-info-non-clickable">Campo Inativo</span> -->
                                            <span v-if="campo.isativo == 'INATIVO'" class="third-info-non-clickable">Campo Inativo</span>
                                            <span v-else-if="campo.isativo == 'OFF'" class="third-info-non-clickable">Campo Encerrado</span>
                                            <span v-else-if="campo.isativo == 'ON'" class="third-info-non-clickable">Campo Ativo</span>
                                        </td>

                                        <td>
                                            <div class="controlGroup">
                                                <router-link :to="{name: 'InstituicaoMenuCampo', params: { idCampo: campo.idamostra }}" >
                                                    <Button
                                                        value="Ver Campo"
                                                        class="btnLista"
                                                        section="instituicao-campo"
                                                        :isOutline="true"
                                                    />
                                                </router-link>
                                                <Button
                                                    v-if="campo.isativo == 'ON' && userPerm.permissao != 'APLICADOR'"
                                                    @click="encerraCampo(campo.idamostra)"
                                                    class="btnLista"
                                                    value="Encerrar Campo"
                                                    section="instituicao-cadastrar"
                                                    :isOutline="true"
                                                />

                                                <Button
                                                    v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR' && hasCampoAtivo() == false"
                                                    @click="iniciaCampo(campo.idamostra)"
                                                    class="btnLista"
                                                    value="Iniciar Campo"
                                                    section="salvar"
                                                    :isOutline="true"
                                                />

                                                <Button
                                                    v-if="campo.isativo == 'INATIVO' && userPerm.permissao != 'APLICADOR'"
                                                    @click="excluirCampo(campo.idamostra)"
                                                    class="btnLista"
                                                    value="Excluir Campo"
                                                    section="descartar"
                                                    :isOutline="true"
                                                />
                                            </div>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </transition-group>

                    <transition-group v-else>
                        <h2 class="title-style-box-lista">Nenhuma aplicação disponível.</h2>
                    </transition-group>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
 import Breadcrumb from '@components/Breadcrumb'
 import Swal from 'sweetalert2';
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'

 export default {
     name: 'CampoListaFormularios',
     data (){
         return {
             nomeInstituicao: '',
             descricao: '',
             selectedValue: '',
             searchByName: '',
             searchByCode: '',
             searchWithOrder: '',
             options: [
                 {
                     id:1,
                     value: 'Todos'
                 },
                 {
                     id:2,
                     value: 'Nome'
                 },
                 {
                     id:3,
                     value: 'Grupo de Pesquisa'
                 }
             ],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:4,
                     value: 'CSV',
                     iconName: 'file-csv',
                     name_route: ''
                 }

             ]
         }
     },
     components: {
         Breadcrumb,
         InstitutionInfo
     },
     computed: {
         totalCsvs() {
             return 0;
         },
         csvs() {
             return [];
         },
     },
     methods: {
         applyChanges: function() {
             Swal.fire(
                 'Alterações aplicadas!',
                 'As seleções foram aplicadas com sucesso.',
                 'success'
             )
         },
         setSelectedValue: function() {
             //! TODO: só pra parar de dar erro no console
         }
     },
     created() {
         this.teams = this.$store.getters.getListTeams
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idInstituicao

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome da Instituição"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.teams.filter(team =>
                 team.idinstituicao == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.name
             this.descricao = teamSelected.descricao
         }
     }
 }
</script>

<style scoped>

@import "../../../assets/css/Dados/list.css";

 .text-info-personal {
     color: #fff;
     font-weight: 450;
     font-size: 1.1rem;
     text-align: justify;
 }


 .label-filtro {
     font-weight: 600;
 }


 .itens-box {
     display: flex;
     align-items: center;
     justify-content: space-between;
 }

 .title-tree {
     margin: 1rem 3rem;
     font-weight: bold;
     display: flex;
 }

 .title-info{
     font-weight: 600;
     font-size: 1.6rem;
     color: #fff;
     margin: 0;
 }
 .title-style{
     margin: 0;
     padding: 0;
 }

 h3.title-info{
     margin-top: 1.6rem;
     font-size: 1.25rem;
 }

 .lista-data-info {
     display: flex;
     align-items: center;
     justify-content: space-between;
     flex-wrap: wrap;
 }

 .title-tree h1 {
     font-size: 1.2rem;
     font-weight: 700;
 }

 .main-link {
     outline:none;
     text-decoration: none;
     cursor: pointer;
     margin-right: .3rem;
 }

 .sub-link {
     margin-left: .3rem;
 }

 .separator {
     border: none;
     height: 1px;
 }

 a {
     color: #212529;
     text-decoration: none;
     transition: all ease-in-out 0.2s
 }

 a:hover {
     color: #4d4f50;
 }

 @media screen and (max-width: 1010px) {
     .lista-data-info {
         justify-content: center;
     }
 }


 @media screen and (max-width: 1012px) {
     .filter-box {
         display: flex;
         align-items: center;
         justify-content: center;
     }
 }

 @media screen and (max-width: 680px) {
     .itens-box{
         flex-direction: column;
         align-items: flex-start;
     }

     .filter-box{
         margin: 0 3rem;
     }

     .lista-data-info{
         margin: 0 3rem;
     }
 }


 :deep(.btnLista div) {
     color: #fff;
     text-align: center;
 }

 .breadcrumb-style{
     display: inline-flex;
 }

 .title-row {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
 }

</style>
