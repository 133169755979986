//---------------------------------------------
// Amostras Module 
//---------------------------------------------
// Módulo para gerenciar as ações da amostra

// import axios from 'axios';
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'
// var qs = require('qs');
var JWT = () => {
    return (Cookies.get("accessToken") ? Cookies.get("accessToken") : "JWT VAZIO")
}

// STATES
const state = () => ({
    listCampos: [],
    listCamposGrupo: [],
    listCampoMembros: [],
    listCampoEgressos: [],
    listCampoFormularios: [],
    listCampoUEs: [],
    listCampoTurmas: [],
    listCampoProfessores: [],
    listCampoEstudantes: [],
    listCampoEmpresas: [],
    listCampoEmpregadores: [],
    listCampoJovens: [],
    amostraStatusAtiva: false,
    linkTransicao: null,

    //ESTADOS PARA ARMAZENAR OS ARRAYS DE PAYLOAD DAS REQUISICOES MANAGE
    payloadManageAplicadores: [],
    payloadManageFormularios: [],
    payloadUnselectedFormularios: [],
    payloadManageUnidades: [],
    payloadUnselectedUnidades: [],
    payloadManageTurmas: [],
    payloadUnselectedTurmas: [],

    amostraCreated: null,
    amostraUpdated: null,

    isEstudantesManaged: null,
    isEmpresasManaged: null,
    isEmpregadoresManaged: null,
    isJovensManaged: null,
    isEgressosManaged: null,
    isProfessoresManaged: null,
})

// MUTATIONS
const mutations = {
    setIsEstudantesManaged(state, payload) {
        state.isEstudantesManaged = payload;
    },

    setIsEmpresasManaged(state, payload) {
        state.isEmpresasManaged = payload;
    },

    setIsEmpregadoresManaged(state, payload) {
        state.isEmpregadoresManaged = payload;
    },

    setIsJovensManaged(state, payload) {
        state.isJovensManaged = payload;
    },

    setIsEgressosManaged(state, payload) {
        state.isEgressosManaged = payload;
    },
    
    setIsProfessoresManaged(state, payload) {
        state.isProfessoresManaged = payload;
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOS/AMOSTRAS
    setListCampos(state, payload) {
        state.listCampos = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOS/AMOSTRAS (GRUPO)
    setListCamposGrupo(state, payload) {
        state.listCamposGrupo = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOMEMBROS/APLICADORES
    setListCampoMembros(state, payload) {
        state.listCampoMembros = payload
    },

    setListCampoProfessores(state, list) {
        state.listCampoProfessores = list;
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOFORMULARIOS
    setListCampoFormularios(state, payload) {
        state.listCampoFormularios = payload
    },

    setListCampoEgressos(state, payload) {
        state.listCampoEgressos = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOUNIDADESEDUCACIONAIS
    setListCampoUEs(state, payload) {
        state.listCampoUEs = payload
    },

    //-- MUTATION REQUISICAO DO LISTCAMPOUNIDADESEDUCACIONAIS
    setListCampoTurmas(state, payload) {
        state.listCampoTurmas = payload
    },

    setListCampoEstudantes(state, payload) {
        state.listCampoEstudantes = payload;
    },

    setListCampoEmpresas(state, list) {
        state.listCampoEmpresas = list;
    },

    setListCampoEmpregadores(state, list) {
        state.listCampoEmpregadores = list;
    },

    setListCampoJovens(state, list) {
        state.listCampoJovens = list;
    },

    //-- MUTATION ESTADO STATUS AMOSTRA
    setAmostraStatusAtiva(state, payload) {
        state.amostraStatusAtiva = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE APLICADORES
    setPayloadManageAplicadores(state, payload) {
        state.payloadManageAplicadores = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE FORMULARIOS
    setPayloadManageFormularios(state, payload) {
        state.payloadManageFormularios = payload
    },

    //-- MUTATION ESTADO DE UNSELECTED UNIDADES
    setPayloadUnselectedFormularios(state, payload) {
        state.payloadUnselectedFormularios = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE UNIDADES
    setPayloadManageUnidades(state, payload) {
        state.payloadManageUnidades = payload
    },

    //-- MUTATION ESTADO DE UNSELECTED UNIDADES
    setPayloadUnselectedUnidades(state, payload) {
        state.payloadUnselectedUnidades = payload
    },

    //-- MUTATION ESTADO DE PAYLOAD MANAGE TURMAS
    setPayloadManageTurmas(state, payload) {
        state.payloadManageTurmas = payload
    },

    //-- MUTATION ESTADO DE UNSELECTED TURMAS
    setPayloadUnselectedTurmas(state, payload) {
        state.payloadUnselectedTurmas = payload
    },

    setPayloadManageEstudantes(state, estudantes) {
        state.payloadManageEstudantes = estudantes;
    },

    setLinkTransicao(state, payload) {
        state.linkTransicao = payload
    },

    setAmostraCreated(state, payload) {
        state.amostraCreated = payload;
    },

    setAmostraUpdated(state, payload) {
        state.amostraUpdated = payload;
    },
}

// ACTIONS
const actions = {
    //-- ACTION REQUISICAO CRIAR AMOSTRA/CAMPO
    async setCreateAmostra({ commit }, payload)  {
        // let formData = new FormData();
        // formData.append('titulo', payload.titulo);
        // formData.append('ano', payload.ano);
        // formData.append('descricao', payload.descricao);
        // formData.append('complementares', "{}");
        // if (payload.pdf) formData.append('pdf', payload.pdf);

        await axios
            .post(`amostras/${payload.idInstituicao}`, {
                ...payload,
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => commit('setAmostraCreated', true))
            .catch((error) => {
                commit('setAmostraCreated', false);
                console.log(error.message);
            });
    },

    async setUpdateAmostra({ commit }, payload)  {
        // let formData = new FormData();
        // formData.append('titulo', payload.titulo);
        // formData.append('ano', payload.ano);
        // formData.append('descricao', payload.descricao);
        // formData.append('updateTermo', payload.updateTermo);
        // if (payload.pdf) formData.append('pdf', payload.pdf);

        await axios
            .put(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {
                ...payload,
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(() => commit('setAmostraUpdated', true))
            .catch((error) => {
                commit('setAmostraUpdated', false);
                console.log(error.message);
            });
    },

    //-- ACTION DELETE AMOSTRA
    async setDeleteAmostra(_, payload) {
        await axios
            .delete(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            // .then(response => {
            //     console.log(response)
            // })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO DO LISTCAMPOS (AMOSTRAS)
    async setListCampos({ commit }, idInstituicao) {
        await axios
            .get(`amostras/${idInstituicao}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampos', response.data)
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION REQUISICAO DO LISTCAMPOS DE GRUPO (AMOSTRAS)
    async setListCamposGrupo({ commit }, payload) {
        await axios
            .get(`amostras/${payload?.idInstituicao}/${payload?.idGrupo}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCamposGrupo', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    async setEncerraCampo( _, payload)  {
        await axios
            .put(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {
                estaAtiva: "OFF"
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },

    async setIniciaCampo( _, payload)  {
        await axios
            .put(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {
                estaAtiva: "ON"
            },
                {headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .catch((error) => console.log(error.message))
    },

    //-- ACTION DELETE CAMPO/AMOSTRA
    async deleteCampo(_, payload) {
        await axios
            .delete(`amostras/${payload.idInstituicao}/${payload.idAmostra}`, {

            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            // .then(response => {
            //     console.log(response)
            // })
            .catch((error) => console.log(error.message))
    },


    //-- ACTION REQUISICAO DO LISTCAMPOMEMBROS (ESTUDANTES)
    async setListCampoMembros({ commit }, payload) {
        await axios
            .get(`amostras/estudantes/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoMembros', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DO LISTCAMPOFORMULARIOS
    async setListCampoFormularios({ commit }, payload) {
        await axios
            .get(`amostras/formularios/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoFormularios', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DO LISTCAMPOUNIDADESEDUCACIONAIS
    async setListCampoUEs({ commit }, payload) {
        await axios
            .get(`amostras/unidades/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoUEs', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DO LISTCAMPOTURMAS
    async setListCampoTurmas({ commit }, payload) {
        await axios
            .get(`amostras/turmas/${payload.idInstituicao}/${payload.idAmostra}/${payload.idUnidade}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoTurmas', response.data)
            })
            .catch((error) => console.log(error.message))

    },

    async loadListCampoEgressos({ commit }, payload) {
        commit('setListCampoEgressos', []);

        await axios
            .get(`amostras/egressos/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoEgressos', response.data?.data);
            })
            .catch((error) => {
                console.log(error.message);
                commit('setListCampoEgressos', null);
        })
    },

    async setListCampoProfessores({ commit }, payload) {
        commit('setListCampoProfessores', null);
        await axios
            .get(`amostras/professores/${payload.idInstituicao}/${payload.idAmostra}/${payload.idUnidade}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoProfessores', response.data.data);
            })
            .catch((error) => console.log(error.message))

    },

    async setListCampoEstudantes({ commit }, payload) {
        commit('setListCampoEstudantes', null);
        await axios
            .get(`amostras/estudantes/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoEstudantes', response.data.data)
            })
            .catch((error) => console.log(error.message))

    },

    async loadListCampoEmpresas({ commit }, payload) {
        commit('setListCampoEmpresas', []);

        await axios
            .get(`amostras/empresas/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoEmpresas', response.data?.data)
            })
            .catch((error) => {
                commit('setListCampoEmpresas', null);
                console.log(error.message);
            });

    },

    async loadListCampoEmpregadores({ commit }, payload) {
        commit('setListCampoEmpregadores', []);

        await axios
            .get(`amostras/empregadores/${payload.idInstituicao}/${payload.idAmostra}/${payload.idEmpresa}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoEmpregadores', response.data?.data)
            })
            .catch((error) => {
                commit('setListCampoEmpregadores', null);
                console.log(error.message);
            });

    },

    async loadListCampoJovens({ commit }, payload) {
        commit('setListCampoJovens', []);

        await axios
            .get(`amostras/jovens/${payload.idInstituicao}/${payload.idAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                commit('setListCampoJovens', response.data?.data)
            })
            .catch((error) => {
                commit('setListCampoJovens', null);
                console.log(error.message);
            });

    },

    //-- ACTION REQUISICAO DE MANAGE APLICADORES
    async setManageAplicadores(_, payload) {
        await axios
            .post(`amostras/manageEstudantes/${payload.idInstituicao}/${payload.idAmostra}`, {
                    ids: payload.ids
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            // .then(response => {
            //     console.log(response)
            // })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DE MANAGE FORMULARIOS
    async setManageFormularios(_, payload) {
        await axios
            .post(`amostras/manageFormularios/${payload.idInstituicao}/${payload.idAmostra}`, {
                ids: payload.ids,
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            // .then(response => {
            //     console.log(response)
            // })
            .catch((error) => console.log(error.message))

    },

    //-- ACTION REQUISICAO DE MANAGE UNIDADES
    async setManageUnidades(_, payload) {
        await axios
            .post(`amostras/manageUnidades/${payload.idInstituicao}/${payload.idAmostra}`, {
                ids: payload.ids,
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            .then(response => {
                console.log(response)
            })
            .catch((error) => console.log(error.message))

    },

    async setManageProfessores({ commit }, payload) {
        commit('setIsProfessoresManaged', null);
        await axios
            .post(`amostras/manageProfessores/${payload.idInstituicao}/${payload.idAmostra}/${payload.idUnidade}`, {
                addIds: payload.addIds,
                removeIds: payload.removeIds
            }, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
            })
            .then(() => {
                commit('setIsProfessoresManaged', true);
            })
            .catch((error) => {
                commit('setIsProfessoresManaged', false);
                console.log(error);
            })

    },

    //-- ACTION REQUISICAO DE MANAGE TURMAS
    async setManageTurmas(_, payload) {
        await axios
            .post(`amostras/manageTurmas/${payload.idInstituicao}/${payload.idAmostra}/${payload.idUnidade}`, {
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                }
            })
            // .then(response => {
            //     console.log(response)
            // })
            .catch((error) => console.log(error.message))

    },

    async setManageEstudantes({ commit }, payload) {
        commit('setIsEstudantesManaged', null);
        await axios
            .post(`amostras/manageEstudantes/${payload.idInstituicao}/${payload.idAmostra}`, {
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
            })
            .then(() => {
                commit('setIsEstudantesManaged', true);
            })
            .catch((error) => {
                console.log(error.message);
                commit('setIsEstudantesManaged', false);
            });

    },

    async setManageEmpresas({ commit }, payload) {
        commit('setIsEmpresasManaged', null);

        await axios
            .post(`amostras/manageEmpresas/${payload.idInstituicao}/${payload.idAmostra}`, {
                ids: payload.ids,
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
            })
            .then(() => {
                commit('setIsEmpresasManaged', true);
            })
            .catch((error) => {
                console.log(error.message);
                commit('setIsEmpresasManaged', false);
            });

    },

    async setManageEmpregadores({ commit }, payload) {
        commit('setIsEmpregadoresManaged', null);

        await axios
            .post(`amostras/manageEmpregadores/${payload.idInstituicao}/${payload.idAmostra}/${payload.idEmpresa}`, {
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
            })
            .then(() => {
                commit('setIsEmpregadoresManaged', true);
            })
            .catch((error) => {
                console.log(error.message);
                commit('setIsEmpregadoresManaged', false);
            });

    },

    async setManageJovens({ commit }, payload) {
        commit('setIsJovensManaged', null);
        //mudar aqui /${payload.idEmpregador}
        await axios
            .post(`amostras/manageJovens/${payload.idInstituicao}/${payload.idAmostra}`, {
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
            })
            .then(() => {
                commit('setIsJovensManaged', true);
            })
            .catch((error) => {
                console.log(error.message);
                commit('setIsJovensManaged', false);
            });

    },

    async setManageEgressos({ commit }, payload) {
        commit('setIsEgressosManaged', null);
        await axios
            .post(`amostras/manageEgressos/${payload.idInstituicao}/${payload.idAmostra}`, {
                addIds: payload.addIds,
                removeIds: payload.removeIds
            },{
                headers: {
                    'Authorization': `Bearer ${JWT()}`
                },
            })
            .then(() => {
                commit('setIsEgressosManaged', true);
            })
            .catch((error) => {
                console.log(error.message);
                commit('setIsEgressosManaged', false);
            });
    },

    async setLinkTransicao({ commit }, payload) {
        commit('setLinkTransicao', null)
        await axios
            .get(`amostras/linkTransicao/${payload.idInstituicao}/${payload.idAmostra}/${payload.idFormularioAmostra}`, {
                headers: {
                    'Authorization': `Bearer ${JWT()}`,
                },
            })
            .then(response => {
                commit('setLinkTransicao', response.data.data)
            })
            .catch((error) => {
                console.log(error.message)
                commit('setLinkTransicao', null)
            })
    },

    getAmostraFile(_, payload) {
        return axios.get(`amostras/amostraEntities/${payload.idInstituicao}/${payload.idAmostra}`, {
            headers: {
                'Authorization': `Bearer ${JWT()}`
            }
        });
    },
}

// GETTERS
const getters = {
    getListCampos(state) {
        try {
            return state.listCampos.data
        } catch (e) {
            return []
        }
    },

    getListCamposGrupo(state) {
        try {
            return state.listCamposGrupo.data
        } catch (e) {
            return []
        }
    },

    getListCampoMembros(state) {
        try {
            return state.listCampoMembros.data
        } catch (e) {
            return []
        }
    },

    getListCampoEgressos(state) {
        return state.listCampoEgressos;
    },

    getListCampoFormularios(state) {
        try {
            return state.listCampoFormularios.data
        } catch (e) {
            return []
        }
    },

    getListCampoUEs(state) {
        try {
            return state.listCampoUEs.data
        } catch (e) {
            return []
        }
    },

    getListCampoTurmas(state) {
        try {
            return state.listCampoTurmas.data
        } catch (e) {
            return []
        }
    },

    getListCampoEstudantes(state) {
        return state.listCampoEstudantes;
    },

    getListCampoEmpresas(state) {
        return state.listCampoEmpresas;
    },

    getListCampoEmpregadores(state) {
        return state.listCampoEmpregadores;
    },
    getListCampoJovens(state) {
        return state.listCampoJovens;
    },

    getListCampoProfessores(state) {
        return state.listCampoProfessores || [];
    },

    getPayloadManageAplicadores(state) {
        return state.payloadManageAplicadores
    },

    getPayloadManageFormularios(state) {
        return state.payloadManageFormularios
    },

    getPayloadUnselectedFormularios(state) {
        return state.payloadUnselectedFormularios
    },

    getPayloadManageUnidades(state) {
        return state.payloadManageUnidades
    },

    getPayloadUnselectedUnidades(state) {
        return state.payloadUnselectedUnidades
    },

    getPayloadManageTurmas(state) {
        return state.payloadManageTurmas
    },

    getPayloadUnselectedTurmas(state) {
        return state.payloadUnselectedTurmas
    },

    getAmostraStatusAtiva(state) {
        return state.amostraStatusAtiva
    },

    getLinkTransicao(state) {
        return state.linkTransicao
    },

    getAmostraCreated(state) {
        return state.amostraCreated;
    },

    getIsAmostraUpdated(state) {
        return state.amostraUpdated;
    },

    getIsEstudantesManaged(state) {
        return state.isEstudantesManaged;
    },

    getIsEmpresasManaged(state) {
        return state.isEmpresasManaged;
    },

    getIsEmpregadoresManaged(state) {
        return state.isEmpregadoresManaged;
    },
    getIsJovensManaged(state) {
        return state.isJovensManaged;
    },

    getIsProfessoresManaged(state) {
        return state.isProfessoresManaged;
    },
}

export default {
    state,
    mutations,
    actions,
    getters
}
