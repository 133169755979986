<template>
    <div class="root-dados">
        <ModalCentral
            :title="modalTitle"
            componentName="InfoQuestoes"
            idName="popupQuestoes"
            :data="{ items: showItems }"
        />

        <ModalCentral
            :title="
                $route.params.idForm
                    ? 'Confirmar edição do formulário'
                    : 'Confirmar criação do formulário'
            "
            componentName="CreateForm"
            idName="popupCreate"
            :data="{
                contexto: { ...selectedContexto },
                fatores: [...fatores].filter((f) =>
                    selectedFatores.includes(f?.idItem)
                ),
                perguntas: newQuestions ? newQuestions : null,
                formName: formId ? curForm?.nome : null,
                termo: curForm?.termo,
                identificador: curForm?.identificador,
                isUniqueId: curForm?.isUniqueId,
            }"
        />

        <div class="container list-data">
            <Breadcrumb
                class="breadcrumb-style"
                :crumbs="crumbs"
                section="instituicao-campo"
            />

            <div
                class="w100 row text-center justify-content-center position-relative form-steps mt-5"
            >
                <div class="col-4 col-md-3 col-lg-2 selection-group">
                    <Button
                        class="p-3 btn mt-3 mb-3"
                        section="instituicao-campo"
                        :class="{
                            activeBtn: showContexto,
                            completeBtn:
                                !showContexto &&
                                selectedContexto &&
                                selectedContexto != null,
                        }"
                        :isOutline="true"
                        :hasIcon="true"
                        :iconName="
                            !showContexto &&
                            selectedContexto &&
                            selectedContexto != null
                                ? 'check'
                                : ''
                        "
                        :hasSavedIcon="
                            !showContexto &&
                            selectedContexto &&
                            selectedContexto != null
                                ? null
                                : 'IconContexto'
                        "
                        iconSize="2.5em"
                        @click="
                            (showContexto = true),
                                (showFatores = false),
                                (showCustom = false),
                                scrollToTop()
                        "
                    />
                    <br />
                    <span class="p-2 d-flex justify-content-center"
                        >Contexto</span
                    >
                </div>

                <div class="col-4 col-md-3 col-lg-2 selection-group">
                    <Button
                        class="p-3 btn mt-3 mb-3"
                        section="instituicao-campo"
                        :class="{
                            activeBtn: showFatores,
                            completeBtn:
                                !showContexto &&
                                !showFatores &&
                                selectedFatores &&
                                selectedFatores.length,
                        }"
                        :isOutline="true"
                        :hasIcon="true"
                        :iconName="
                            !showContexto &&
                            !showFatores &&
                            selectedFatores &&
                            selectedFatores.length
                                ? 'check'
                                : ''
                        "
                        :hasSavedIcon="
                            !showContexto &&
                            !showFatores &&
                            selectedFatores &&
                            selectedFatores.length
                                ? null
                                : 'IconFatores'
                        "
                        iconSize="2.5em"
                        @click="
                            (showContexto = false),
                                (showFatores = true),
                                (showCustom = false),
                                scrollToTop()
                        "
                    />
                    <br />
                    <span class="p-2 d-flex justify-content-center">Itens</span>
                </div>

                <div class="col-4 col-md-3 col-lg-2 selection-group">
                    <Button
                        class="p-3 btn mt-3 mb-3"
                        :class="{
                            activeBtn: showCustom,
                            completeBtn:
                                !showContexto && !showFatores &&
                                !showCustom &&
                                newQuestions &&
                                newQuestions.length,
                        }"
                        section="instituicao-campo"
                        :isOutline="true"
                        :hasIcon="true"
                        :iconName="
                            !showContexto && !showFatores && !showCustom && newQuestions && newQuestions.length
                                ? 'check'
                                : ''
                        "
                        :hasSavedIcon="
                            !showContexto && !showFatores && !showCustom && newQuestions && newQuestions.length
                                ? null
                                : 'IconPerguntas'
                        "
                        iconSize="2.5em"
                        @click="
                            (showContexto = false),
                                (showFatores = false),
                                (showCustom = true),
                                scrollToTop()
                        "
                    />
                    <br />
                    <span class="p-2 d-flex justify-content-center"
                        >Perguntas adicionais</span
                    >
                </div>
                <div
                    class="steps-line col-8 col-md-6 col-lg-4"
                    :class="
                        selectedContexto &&
                        selectedFatores.length > 0 &&
                        !showContexto &&
                        !showFatores
                            ? 'step-full'
                            : !showContexto && selectedContexto
                            ? 'step-half'
                            : ''
                    "
                >
                    .
                </div>
            </div>

            <span v-if="showContexto" class="descricao-view"
                >Selecione abaixo o contexto desejado. Apenas um contexto pode
                ser selecionado para fazer parte deste formuláro.</span
            >
            <span v-if="showFatores" class="descricao-view"
                >Selecione abaixo os itens desejados. Os itens listados abaixo
                fazem parte do contexto selecionado.</span
            >
            <span v-if="showCustom" class="descricao-view"
                ><b>ESTE PASSO É OPCIONAL.</b> Você poderá, caso deseje, criar
                até 5 novas perguntas que não pertençam aos itens listados
                anteriormente.</span
            >

            <!-- CONTEXTOS -->
            <div v-if="showContexto" class="mt-4 container">
                <div class="w100 d-flex row" id="contextos">
                    <div
                        v-for="(contexto) of contextos"
                        :key="contexto.idItem"
                        @click="
                            selectedContexto = { ...contexto };
                            selectedFatores = [];
                        "
                        class="contexto clickable col-sm-12 col-md-6 col-lg-4"
                        :class="{
                            selected:
                                selectedContexto?.idItem == contexto.idItem,
                        }"
                    >
                        <div class="header">
                            <img
                                :src="
                                    require(`@assets/img/Transicao/contexto${
                                        contexto.idItem
                                    }.svg`)
                                "
                                alt="Ícone do contexto"
                                class="contextoIcon"
                            />
                            <Button
                                class="btn ml-2"
                                section="instituicao-campo"
                                value="Ver questões"
                                @click="selectItem(contexto)"
                                :isOutline="true"
                                :hasIcon="false"
                                data-toggle="modal"
                                data-target="#popupQuestoes"
                            />
                        </div>
                        <div class="titles">
                            <div class="titulo">{{ contexto.titulo }}</div>
                            <!-- <div class="modelo">{{ contexto.modelo }}</div> -->
                        </div>
                        <span>
                            {{ contexto.descricao }}
                        </span>
                    </div>
                </div>

                <div class="control-view">
                    <router-link :to="{ name: 'CampoListaFormularios' }">
                        <Button
                            class="btn ml-2"
                            section="descartar"
                            :value="
                                formId ? 'Cancelar edição' : 'Cancelar criação'
                            "
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="cancel"
                        />
                    </router-link>

                    <Button
                        id="addForm"
                        class="btn ml-2"
                        section="instituicao-campo"
                        value="Continuar configuração"
                        :isOutline="true"
                        :hasIcon="true"
                        :isReverse="true"
                        iconName="arrow-right"
                        data-toggle="modal"
                        @click="
                            (showContexto = false),
                                (showFatores = true),
                                (showCustom = false),
                                scrollToTop()
                        "
                    />
                </div>
            </div>

            <!-- FATORES OU FORMULÁRIOS -->
            <div v-if="showFatores" class="mt-4">
                <!-- INICIO DO FORM DE FILTRAGEM -->
                <div class="input-group mt-2 mb-3">
                    <select class="col-2" v-model="searchType">
                        <option selected value="todos">Todos</option>
                        <option value="nome">Nome</option>
                        <option value="modelo">Modelo</option>
                    </select>
                    <input
                        class="col form-control input-personal"
                        v-model="searchTerm"
                        type="text"
                        name="filtro_fatores"
                        id="inlineFormCustomName"
                        placeholder="Fator a buscar..."
                        autocomplete="off"
                    />
                </div>

                <div id="elementContainer">
                    <div id="fatores">
                        <div id="mainFatores">
                            <div
                                class="d-flex justify-content-between mb-3 flex-wrap"
                            >
                                <div>
                                    <h4 style="font-weight: 700">
                                        {{ selectedContexto?.titulo || "..." }}
                                    </h4>
                                    <h5>Itens disponíveis para o contexto</h5>
                                </div>

                                <div class="d-flex gap-3 form-buttons">
                                    <Button
                                        class="p-2 btn mt-3 mb-3"
                                        section="instituicao-campo"
                                        :value="selectAllButtonText"
                                        @click="selectAllFatores"
                                        :isOutline="true"
                                        :hasIcon="false"
                                    />

                                    <Button
                                        class="p-2 btn mt-3 mb-3"
                                        section="instituicao-campo"
                                        :value="
                                            sortOrder === 'asc'
                                                ? 'A > Z'
                                                : 'Z > A'
                                        "
                                        @click="toggleSortOrder"
                                        :isOutline="true"
                                        :hasIcon="false"
                                    />
                                </div>
                            </div>

                            <div class="table-container">
                                <table class="table fatores">
                                    <thead class="table-header">
                                        <tr>
                                            <th class="title" scope="col">
                                                &nbsp;
                                            </th>
                                            <th class="title" scope="col">
                                                Nome
                                            </th>
                                            <th class="title" scope="col">
                                                Modelo
                                            </th>
                                            <th class="title" scope="col">
                                                Ações
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <template
                                            v-for="fator of filteredFatoresList"
                                            :key="fator?.idItem"
                                        >
                                            <tr class="d-grid d-lg-table-row fator-item">
                                                <td
                                                    class="fatorContainer innerBorder"
                                                >
                                                    <!-- CAIXA DE SELEÇÃO !-->
                                                    <div
                                                        @click="
                                                            includeFator(
                                                                fator?.idItem
                                                            )
                                                        "
                                                        class="selector"
                                                        :class="{
                                                            selected:
                                                                selectedFatores.includes(
                                                                    fator?.idItem
                                                                ),
                                                        }"
                                                    />
                                                </td>
                                                <td class="titleFator">
                                                    <!-- TÍTULO DO FATOR !-->
                                                    <span
                                                        ><i
                                                            class="fas fa-clipboard"
                                                        />{{
                                                            fator?.titulo
                                                        }}</span
                                                    >
                                                </td>

                                                <td>
                                                    <!-- MODELO DO FATOR !-->
                                                    <span
                                                        class="fatorContainer modeloTable innerBorder"
                                                        >{{
                                                            fator?.modelo
                                                        }}</span
                                                    >
                                                </td>
                                                <td>
                                                    <!-- AÇÕES !-->
                                                    <Button
                                                        class="btn ml-2 greenGradient clickable"
                                                        section=""
                                                        :isOutline="true"
                                                        :hasIcon="true"
                                                        iconName="info"
                                                        value=""
                                                        @click="
                                                            selectItem(fator)
                                                        "
                                                        data-toggle="modal"
                                                        data-target="#popupQuestoes"
                                                    />
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="control-view mt-5">
                    <router-link :to="{ name: 'CampoListaFormularios' }">
                        <Button
                            class="btn ml-2"
                            section="descartar"
                            :value="
                                formId ? 'Cancelar edição' : 'Cancelar criação'
                            "
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="cancel"
                        />
                    </router-link>

                    <Button
                        id="addForm"
                        class="btn ml-2"
                        section="instituicao-campo"
                        value="Voltar aos contextos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="arrow-left"
                        data-toggle="modal"
                        @click="
                            (showContexto = true),
                                (showFatores = false),
                                scrollToTop()
                        "
                    />

                    <Button
                        id="addForm"
                        class="btn ml-2"
                        section="instituicao-campo"
                        value="Continuar configuração"
                        :isOutline="true"
                        :hasIcon="true"
                        :isReverse="true"
                        :isDisabled="selectedFatores.length < 1"
                        iconName="arrow-right"
                        data-toggle="modal"
                        @click="
                            (showCustom = true),
                                (showFatores = false),
                                (showContexto = false),
                                scrollToTop()
                        "
                    />
                </div>
            </div>

            <!-- NOVAS QUESTÕES -->
            <div v-if="showCustom" class="mt-4">
                <div class="d-flex flex-wrap flex-sm-nowrap justify-content-between gap-3 align-items-center">
                    <span style="color: white"
                        ><b
                            >{{ createdQuestions }} de 5 novas perguntas
                            criadas</b
                        ></span
                    >
                    <Button
                        class="btn"
                        section="instituicao-campo"
                        value="Adicionar nova pergunta"
                        :isOutline="true"
                        :hasIcon="false"
                        @click="
                            createdQuestions < 5
                                ? addNewQuestion()
                                : (createdQuestions = createdQuestions, notifyCantAddQuestion())
                        "
                    />
                </div>

                <div class="custom-question mt-4">
                    <template v-if="newQuestions.length">
                        <template
                            v-for="(question, index) in newQuestions"
                            :key="question.questionItem"
                        >
                            <div class="d-sm-block d-md-flex question-row">
                                <div
                                    class="col-md-5 col-lg-4 col-xl-3 left flex-column"
                                >
                                    <div
                                        class="d-flex align-items-center gap-3"
                                    >
                                        <span class="title"
                                            >Pergunta 0{{ index + 1 }}</span
                                        >
                                        <Button
                                            class="p-2 btn mt-3 mb-3"
                                            section="new-contexto-grey"
                                            value=""
                                            :isOutline="true"
                                            :hasIcon="true"
                                            iconName="trash"
                                            @click="removeQuestion(index)"
                                        />
                                    </div>
                                    <div>
                                        <span class="grey-txt"
                                            >Qual o tipo da pergunta?</span
                                        >
                                        <div class="input-radio mt-3 mb-1">
                                            <input
                                                type="radio"
                                                :id="'objetiva' + index"
                                                :name="
                                                    'typeQuestion' +
                                                    String(index)
                                                "
                                                value="objetiva"
                                                @change="
                                                    newQuestions[
                                                        index
                                                    ].questionType = {
                                                        text: {},
                                                    }
                                                "
                                                :checked="
                                                    'text' in
                                                    question.questionType
                                                "
                                            />
                                            <label
                                                :for="'objetiva' + index"
                                                class="mb-0"
                                                >Objetiva</label
                                            >
                                        </div>
                                        <div class="input-radio mb-1">
                                            <input
                                                type="radio"
                                                :id="'unica' + index"
                                                :name="
                                                    'typeQuestion' +
                                                    String(index)
                                                "
                                                value="unica"
                                                @change="
                                                    newQuestions[
                                                        index
                                                    ].questionType = {
                                                        radio: {},
                                                    }
                                                "
                                                :checked="
                                                    'radio' in
                                                    question.questionType
                                                "
                                            />
                                            <label
                                                :for="'unica' + index"
                                                class="mb-0"
                                                >Seleção única</label
                                            >
                                        </div>
                                    </div>
                                </div>
                                <div class="vr"></div>
                                <div class="col right flex-column">
                                    <div class="mb-4">
                                        <span class="grey-txt"
                                            >Digite o enunciado da questão</span
                                        >
                                        <input
                                            type="text"
                                            v-model="question.enunciado"
                                            @input="
                                                question.enunciated =
                                                    '<strong>' +
                                                    question.enunciado +
                                                    '</strong>'
                                            "
                                            class="mt-2"
                                        />
                                    </div>
                                    <div
                                        v-if="
                                            'select' in question.questionType ||
                                            'radio' in question.questionType
                                        "
                                    >
                                        <span class="grey-txt"
                                            >Adicione as opções</span
                                        >
                                        <div class="d-flex gap-3 flex-wrap flex-sm-nowrap mt-2">
                                            <input
                                                type="text"
                                                class="addQuest"
                                                v-model="newOption[index]"
                                            />
                                            <Button
                                                class="btn greenGradient"
                                                value="Adicionar nova opção"
                                                :isOutline="true"
                                                :hasIcon="false"
                                                @click="addNewOption(index)"
                                                :isDisabled="
                                                    !newOption[index] ||
                                                    newOption[index] == '' ||
                                                    Object.keys(
                                                        question.questionType
                                                    ).length == 0
                                                "
                                            />
                                        </div>
                                        <template
                                            v-if="
                                                'radio' in question.questionType
                                            "
                                        >
                                            <div
                                                class="d-flex align-items-center mt-2 question-option"
                                                v-for="(
                                                    opcao, innerIndex
                                                ) in question.questionType.radio
                                                    .options"
                                                :key="opcao"
                                            >
                                                <div
                                                    class="outline-square mr-1"
                                                ></div>
                                                <span
                                                    v-html="opcao.optionEnum"
                                                ></span>
                                                <Button
                                                    style="
                                                        background-color: transparent;
                                                        border: none;
                                                        box-shadow: none;
                                                        color: #6b6b6b;
                                                    "
                                                    class="p-2 btn"
                                                    section="new-contexto-grey"
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    iconName="trash"
                                                    @click="
                                                        removeOption(
                                                            index,
                                                            innerIndex
                                                        )
                                                    "
                                                />
                                            </div>
                                        </template>
                                        <template
                                            v-if="
                                                'select' in
                                                question.questionType
                                            "
                                        >
                                            <div
                                                class="d-flex align-items-center mt-2 question-option"
                                                v-for="(
                                                    opcao, innerIndex
                                                ) in question.questionType
                                                    .select.options"
                                                :key="opcao"
                                            >
                                                <div
                                                    class="outline-square mr-1"
                                                ></div>
                                                <span
                                                    v-html="opcao.optionEnum"
                                                ></span>
                                                <Button
                                                    style="
                                                        background-color: transparent;
                                                        border: none;
                                                        box-shadow: none;
                                                        color: #6b6b6b;
                                                    "
                                                    class="p-2 btn"
                                                    section="new-contexto-grey"
                                                    :isOutline="true"
                                                    :hasIcon="true"
                                                    iconName="trash"
                                                    @click="
                                                        removeOption(
                                                            index,
                                                            innerIndex
                                                        )
                                                    "
                                                />
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <hr v-if="index < createdQuestions - 1" />
                        </template>
                    </template>
                    <h5
                        style="font-weight: 600; color: #00853b"
                        class="mb-0"
                        v-else
                    >
                        Não há novas perguntas criadas para este formulário.
                    </h5>
                </div>

                <div class="control-view mt-5">
                    <router-link :to="{ name: 'CampoListaFormularios' }">
                        <Button
                            class="btn ml-2"
                            section="descartar"
                            :value="
                                formId ? 'Cancelar edição' : 'Cancelar criação'
                            "
                            :isOutline="true"
                            :hasIcon="true"
                            iconName="cancel"
                        />
                    </router-link>

                    <Button
                        id="addForm"
                        class="btn ml-2"
                        section="instituicao-campo"
                        value="Voltar aos itens"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="arrow-left"
                        data-toggle="modal"
                        @click="
                            (showCustom = false),
                                (showFatores = true),
                                scrollToTop()
                        "
                    />

                    <Button
                        id="addForm"
                        class="btn ml-2"
                        section="instituicao-campo"
                        :value="
                            formId ? 'Editar formulário' : 'Finalizar fomulário'
                        "
                        :isOutline="true"
                        :hasIcon="true"
                        :isReverse="true"
                        :isDisabled="
                            selectedFatores.length < 1 ||
                            newQuestions.some((item) => item.enunciado === '') ||
                            newQuestions.some(
                                (item) =>
                                    Object.keys(item.questionType).length === 0
                            ) ||
                            newQuestions.some(
                                (item) =>
                                (item.questionType.radio && !item.questionType.radio.options) || (item.questionType.radio && item.questionType.radio.options && item.questionType.radio.options.length == 0)
                            )
                        "
                        iconName="clipboard-check"
                        data-toggle="modal"
                        :data-target="
                            selectedFatores?.length ? `#popupCreate` : ''
                        "
                        @click="checkModal"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breadcrumb from "@components/Breadcrumb";
import Button from "@components/Button";
import ModalCentral from "@components/ModalCentral";
import { Notyf } from "notyf";
import { nanoid } from "nanoid";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});

export default {
    name: "AddForm",
    components: {
        Breadcrumb,
        Button,
        ModalCentral,
    },
    data() {
        return {
            heterorrelatoVal: 'HETERORRELATO',
            createdQuestions: 0,
            showContexto: true,
            showFatores: false,
            showCustom: false,
            sortOrder: "asc",
            areAllFatoresSelected: false,
            selectedContexto: {},
            selectedFatores: [],
            newQuestions: [],
            newOption: [],
            filteredFatores: [],
            showItems: [],
            modalTitle: "",
            selectedValue: "",
            searchTerm: "",
            searchType: "todos",
            crumbs: [
                {
                    id: 1,
                    value: "",
                    iconName: "th",
                    name_route: "GerenciarInstituicoes",
                },
                {
                    id: 2,
                    value: "Instituição",
                    iconName: "university",
                    name_route: "InstituicaoMenu",
                },
                {
                    id: 3,
                    value: "Campos",
                    iconName: "th-large",
                    name_route: "ListarCampos",
                },
                {
                    id: 4,
                    value: "Gerenciar Campo",
                    iconName: "chart-pie",
                    name_route: "InstituicaoMenuCampo",
                },
                {
                    id: 5,
                    value: "Formulários",
                    iconName: "file-alt",
                    name_route: "CampoListaFormularios",
                },
                {
                    id: 6,
                    value: this.$route.params.idForm
                        ? "Editar formulário"
                        : "Criar novo formulário",
                    iconName: this.$route.params.idForm
                        ? "pen"
                        : "clipboard-check",
                    name_route: "",
                },
            ],
        };
    },
    async created() {
        this.$store.dispatch("setListCampos", this.$route.params.idInstituicao);
        await this.$store.dispatch("loadListItens");
        if (this.formId) {
            this.$store
                .dispatch("setListFormularios", {
                    idInstituicao: this.$route.params.idInstituicao,
                    idFormulario: this.formId,
                })
                .then(this.loadFormInfo);
        } else this.selectedContexto = this.contextos[0] || {};
    },
    computed: {
        heterorrelatoSelected() { return this.selectedContexto.modelo == this.heterorrelatoVal; },
        selectAllButtonText() {
            return this.areAllFatoresSelected
                ? "Desmarcar todos"
                : "Marcar todos";
        },
        formId() {
            return this.$route.params.idForm;
        },
        curForm() {
            if (!this.formId) return {};
            return (
                this.$store.getters.getListFormularios.find(
                    (f) => f.idformulario == this.formId
                ) || {}
            );
        },
        itens() {
            return this.$store.getters.getListItens || [];
        },
        contextos() {
            return this.itens.filter((i) => i?.categoria === "CONTEXTO");
        },
        campo() {
            return (
                (this.$store.getters.getListCampos || []).find(
                    (campo) => campo.idamostra == this.$route.params.idCampo
                ) || null
            );
        },
        fatores() {
            if (!this.heterorrelatoSelected)
                return this.itens.filter(i => i?.categoria === 'FATOR' && i?.modelo != this.heterorrelatoVal);
            return this.itens.filter(i => i?.categoria === 'FATOR' && i?.modelo == this.heterorrelatoVal);
        },
        sortedFatores() {
            // Filter the items to get only those with the category 'FATOR'
            const filteredFatores = this.fatores;

            // Sort the filtered fatores by "modelo" and then by "titulo"
            const sortedFatores = filteredFatores.sort((a, b) => {
                // Compare "modelo" first
                const modeloComparison = a.modelo.localeCompare(b.modelo);

                if (modeloComparison === 0) {
                    // If modelos are the same, compare "titulo"
                    return a.titulo.localeCompare(b.titulo);
                }

                return modeloComparison;
            });

            return sortedFatores;
        },
        filteredFatoresList() {
            if (this.searchTerm.trim() === "") {
                return this.sortedFatores; // No search term, return all factors
            }

            const lowerCaseSearchTerm = this.searchTerm.trim().toLowerCase();

            return this.fatores.filter((fator) => {
                if (this.searchType === "todos") {
                    // Search in all fields (nome and modelo)
                    return (
                        fator.titulo
                            .toLowerCase()
                            .includes(lowerCaseSearchTerm) ||
                        fator.modelo.toLowerCase().includes(lowerCaseSearchTerm)
                    );
                } else if (this.searchType === "nome") {
                    // Search only in 'nome'
                    return fator.titulo
                        .toLowerCase()
                        .includes(lowerCaseSearchTerm);
                } else if (this.searchType === "modelo") {
                    // Search only in 'modelo'
                    return fator.modelo
                        .toLowerCase()
                        .includes(lowerCaseSearchTerm);
                }
            });
        },
    },
    methods: {
        notifyCantAddQuestion(){
            notyf.open({
                type: "error",
                message: "O limite de perguntas adicionais é de 5.",
            });
        },
        setHeterorrelato() {
            if (!this.heterorrelatoSelected) return;
            this.selectedFatores = [ ...this.itens.filter(item => item?.categoria === 'FATOR' && item?.modelo == this.heterorrelatoVal).map(fator => `${fator?.idItem}`) ];
        },
        toggleSortOrder() {
            this.sortOrder = this.sortOrder === "asc" ? "desc" : "asc";
            this.sortFatores();
        },
        sortFatores() {
            this.filteredFatoresList.sort((a, b) => {
                const titleA = a.titulo.toLowerCase();
                const titleB = b.titulo.toLowerCase();

                if (this.sortOrder === "asc") {
                    if (titleA < titleB) return -1;
                    if (titleA > titleB) return 1;
                    return 0;
                } else {
                    if (titleA > titleB) return -1;
                    if (titleA < titleB) return 1;
                    return 0;
                }
            });
        },
        selectAllFatores() {
            if (this.heterorrelatoSelected) return;
            this.areAllFatoresSelected = !this.areAllFatoresSelected;

            // Logic to select or deselect all "fatores" based on this.areAllFatoresSelected
            if (this.areAllFatoresSelected) {
                // Select all "fatores"
                this.selectedFatores = this.filteredFatoresList.map(
                    (fator) => fator.idItem
                );
            } else {
                // Deselect all "fatores"
                this.selectedFatores = [];
            }
        },
        loadFormInfo() {
            const itens = this.curForm?.itens,
                idContexto = itens.find(
                    (i) => i?.categoria == "CONTEXTO"
                )?.idItem;
            this.selectedContexto = {
                ...(this.contextos.find((i) => i.idItem == idContexto) || {}),
            };
            this.selectedFatores = [
                ...itens
                    .map((i) => `${i?.idItem}`)
                    .filter((id) => id != this.selectedContexto.idItem),
            ];
            this.newQuestions = JSON.parse(this.curForm?.formulario).find(
                (item) => item.categoryName === "Complementar"
            )?.questions ?? [];
            this.createdQuestions = this.newQuestions?.length;
        },
        includeFator(id) {
            if (this.selectedContexto.modelo == this.heterorrelatoVal) return;
            if (this.selectedFatores.includes(id))
                this.selectedFatores = this.selectedFatores.filter(
                    (f) => f != id
                );
            else this.selectedFatores.push(id);
        },
        selectItem(item) {
            this.showItems = [{ ...item }];
            this.modalTitle = `Questões do ${(
                item?.categoria || ""
            ).toLocaleLowerCase()}:&nbsp;&nbsp;'${this.showItems[0]?.titulo}'`;
        },
        checkModal() {
            if (!this.selectedFatores?.length)
                notyf.open({
                    type: "error",
                    message: "Selecione ao menos um fator",
                });
        },
        addNewQuestion() {
            this.createdQuestions += 1;
            const nanoidValue = nanoid();

            this.newQuestions.push({
                uuid: `${nanoidValue}`,
                enunciado: "",
                enunciated: "",
                visible: true,
                questionType: {},
                questionItem: "c" + this.createdQuestions,
                ordination: String(this.createdQuestions),
                cod: "CP0" + this.createdQuestions,
            });
        },
        removeQuestion(questionsIndex) {
            this.newQuestions.splice(questionsIndex, 1);
            this.createdQuestions -= 1;
            //atualizar questionItem
            for (let i = 0; i < this.newQuestions.length; i++) {
                this.newQuestions[i].ordination = String(i + 1);
                this.newQuestions[i].questionItem =
                    "c" + this.newQuestions[i].ordination;
                this.newQuestions[i].cod =
                    "CP0" + this.newQuestions[i].ordination;
            }
        },
        addNewOption(index) {
            if (this.newOption[index] != "" && this.newOption[index]) {
                //se radio
                if (this.newQuestions[index].questionType.radio) {
                    //se nao existir options, criar o array
                    if (!this.newQuestions[index].questionType.radio.options) {
                        this.newQuestions[index].questionType.radio = {
                            options: [],
                        };
                    }
                    this.newQuestions[index].questionType.radio.options.push({
                        id:
                            this.newQuestions[index].questionType.radio.options
                                .length + 1,
                        optionEnum: "<p>" + this.newOption[index] + "<p/>",
                    });
                }
                //senao se select
                else if (this.newQuestions[index].questionType.select) {
                    if (!this.newQuestions[index].questionType.select.options) {
                        this.newQuestions[index].questionType.select = {
                            options: [],
                        };
                    }
                    this.newQuestions[index].questionType.select.options.push({
                        id:
                            this.newQuestions[index].questionType.select.options
                                .length + 1,
                        optionEnum: "<p>" + this.newOption[index] + "<p/>",
                    });
                }
                this.newOption[index] = "";
            }
        },
        removeOption(questionsIndex, optionIndex) {
            if (this.newQuestions[questionsIndex].questionType.radio) {
                this.newQuestions[
                    questionsIndex
                ].questionType.radio.options.splice(optionIndex, 1);
                for (
                    let i = 0;
                    i <
                    this.newQuestions[questionsIndex].questionType.radio.options
                        .length;
                    i++
                ) {
                    this.newQuestions[
                        questionsIndex
                    ].questionType.radio.options[i].id = i + 1;
                }
            } else if (this.newQuestions[questionsIndex].questionType.select) {
                this.newQuestions[
                    questionsIndex
                ].questionType.select.options.splice(optionIndex, 1);
                for (
                    let i = 0;
                    i <
                    this.newQuestions[questionsIndex].questionType.select
                        .options.length;
                    i++
                ) {
                    this.newQuestions[
                        questionsIndex
                    ].questionType.select.options[i].id = i + 1;
                }
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    watch: {
        searchType: {
            handler() {
                // Handle updates when search type changes
                // For example, you might want to reset searchTerm
                this.searchTerm = "";
            },
            immediate: true, // Trigger initial update
        },
        searchTerm: {
            handler() {
                // Handle updates when search term changes
                // The filteredFatoresList computed property will update automatically
            },
            immediate: true, // Trigger initial update
        },
        selectedContexto(cur) {
            if (cur?.modelo == this.heterorrelatoVal)
                this.setHeterorrelato();
        },
    },
};
</script>

<style scoped>

.topSection {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.control-view {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: end;
}

.control-view > * {
    margin: 0 !important;
}

.pageTitle {
    flex: 1;
}

.pageTitle h2,
.pageTitle h4 {
    color: white;
}

.pageTitle h4 > span {
    font-style: italic;
}

#elementContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap-reverse;
}

#contextos {
    flex: 0 1 275px;
    min-width: min-content;

    display: flex;
    gap: 1em !important;
    margin-bottom: 3em;
    /* justify-content: space-between; */
}

#fatores {
    flex: 1;
    min-width: 250px;
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    color: #00853b;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#fatores h2,
#fatores h4 {
    color: #00853b;
}

.contexto {
    flex: 1;
    background: white;
    border-radius: 8px;
    padding: 1.25em;
    margin-bottom: 10px;
    box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
    color: #00853b;

    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
}

.contexto .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
    min-width: min-content;
    flex-wrap: nowrap;
}

.contexto .titles {
    font-weight: bold !important;
    margin-bottom: 1em;
}

.contexto > div {
    width: 100%;
}

.contexto .header .icon {
    font-size: 2em;
}

.contexto .titulo {
    font-size: 1.2em;
}

.contexto .modelo {
    font-size: 0.9em;
}

.contexto:hover {
    transform: scale(1.015);
}

.contexto.selected {
    background-color: #196555;
    color: white;
}

.contexto.selected .contextoIcon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(25%)
        hue-rotate(70deg) brightness(108%) contrast(108%);
}

.contexto .contextoIcon {
    color: black;
    filter: brightness(0) saturate(100%) invert(30%) sepia(74%) saturate(1235%)
        hue-rotate(117deg) brightness(97%) contrast(101%);
}

.modeloTable {
    text-align: center;
    font-style: italic;
}

.input-group > select {
    background-color: white;
    outline: 0;
    border-width: 0 3px 0 0;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 0;
    border-style: solid;
    border-color: #00853b;
}

/* Estilização da tabela de fatores */
.table.fatores {
    overflow: auto;
    align-items: center;
    color: black;
    max-height: 600px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #888;
}

.table.fatores th,
td {
    border-bottom: 1px solid #00853b;
}

/* Target the second column (index 1) */
.table.fatores th:nth-child(2),
.table.fatores td:nth-child(2) {
    /* width: 550px;
    max-width: 600px;
    */
    width: 100%;
    max-width: 62%;
}

.table.fatores th {
    text-align: left;
}
.table-container {
    max-height: 400px;
    overflow: auto;
}

th.title {
    text-align: left;
    border-bottom: 2px solid #00853b;
    text-align: center;
    padding: 0.5rem 1.4rem;
    color: #00853b;
}

.selector {
    margin: 5px;
    width: 1.2em;
    aspect-ratio: 1 / 1;
    border-radius: 3px;
    border: 2px solid #00853b;
    cursor: pointer;
    transition: 0.2s;
}

@media screen and (max-width:992px) {
    td {
        border: none;
    }
    .fator-item{
        text-align: center;
        border: 1px solid #00853b;
        border-radius: 8px;
    }

    .fator-item:not(:first-child):not(:last-child){
        margin: 1em 0;
    }

    .table.fatores th:nth-child(2),
    .table.fatores td:nth-child(2) {
        width: 100%;
        max-width: 100%;
    }

    .table-header{
        display: none;
    }

    .selector {
        margin: 0 auto;
    }
    
}

.selector.selected {
    background-color: #196555;
}

.titleFator i {
    font-size: 1.5em;
    margin-right: 0.5em;
}

.fatorActions * {
    margin: 10px;
}

/* Novo fluxo de criação do formulário */

.selection-group {
    z-index: 1;
}

.selection-group .button-outline {
    border-radius: 8px;
    border: 2px solid transparent;
}

.selection-group span {
    color: white;
    font-weight: bold;
}

.activeBtn {
    color: #196555;
    border: 2px solid #196555;
    background-color: white;
    border-color: #196555 !important;
    pointer-events: none;
}

.completeBtn {
    color: white;
    background-color: #196555;
}

.custom-question {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;

    min-width: 250px;
    background-color: white;
    padding: 25px;
    border-radius: 8px;

    box-sizing: border-box;
}

.descricao-view {
    color: white;
    margin-bottom: 30em;
}
.right {
    width: 100%;
}
span.title {
    color: rgba(0, 133, 59, 1);
    font-size: 24px;
    font-weight: 600;
}

span.grey-txt {
    color: rgba(107, 107, 107, 1);
    font-size: 1.125rem;
    font-weight: 500;
}

.input-radio:is(:first-child) {
    margin-top: 1em;
}

.input-radio {
    display: flex;
    justify-content: start;
    align-items: center;
}

input[type="radio"] {
    flex: 0 0 min-content;
    margin-right: 1em;
    height: 2em;
    -ms-transform: scale(1.3); /* IE 9 */
    -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
    transform: scale(1.3);
}

input[type="radio"]:hover {
    accent-color: #232323;
}

.outline-square {
    width: 24px;
    height: 24px;
    border: 2px solid rgba(0, 133, 59, 1);
    border-radius: 4px;
}

.right input[type="text"] {
    border: 1px solid rgba(0, 133, 59, 1);
    border-radius: 8px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    padding: 0.35em 0.5em;
    font-size: 1em;
    min-width: 100%;
}

input.addQuest {
    min-width: auto !important;
    width: 100%;
}

.vr {
    border: 1.5px solid #00853b;
    opacity: 100%;
}

@media screen and (max-width:768px) {
    .vr{
        opacity: 0;
    } 
}

.form-steps {
    margin-bottom: 2.5rem;
}

.steps-line {
    position: absolute;
    top: 32%;
    height: 0.188rem;
    background-color: white;
    z-index: 0;
}

.step-half {
    background: linear-gradient(to left, white 50%, #196555 50%);
}

.step-full {
    background-color: #196555;
}

:deep(.question-option p) {
    margin-bottom: 0;
}
</style>
