<template>
    <div class="container offset">
        <div class="custom-form">

            <p>
                Veja aqui todas as solicitações recebidas de instituições.
                Você pode aceitar ou recusá-las.
            </p>
            <input 
                type="name"
                class="form-control input-personal"
                placeholder="Digite o nome da instituição..."
                v-model="searchByName"
                id="inlineFormCustomName"
                autocomplete="off"
            />

            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <div class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home-insti" role="tab" aria-controls="nav-home" aria-selected="true">Pendentes</div>
                    <div class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile-insti" role="tab" aria-controls="nav-profile" aria-selected="false">Aceitos</div>
                    <div class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact-insti" role="tab" aria-controls="nav-contact" aria-selected="false">Recusados</div>
                </div>
            </nav>

            <!------------ ABAS - GERAL ------------------------------------------------------>
            <div class="tab-content" id="nav-tabContent">
                <!----------------- ABA CONVITES PENDENTES/NULL ---------------------------->
                <div class="tab-pane fade show active" id="nav-home-insti" role="tabpanel" aria-labelledby="nav-home-tab">
                    <transition-group tag="div" v-if='filterSolicitacoes' class="listagem-solicitacao" name="listanimate">
                        <div v-for="info in filterSolicitacoes" :key="info.idInstituicao?.idInstituicao">
                            <div v-if="info.aceito === 'PENDENTE'">
                                <div class="institution-row-style">
                                    <div class="infos-text">
                                        <i class="img-style ml-1 mr-2 fas fa-user"></i>
                                        <label class="text-style"> {{ info.idInstituicao?.nome }} </label>
                                    </div>
                                    <div class="buttons">
                                        <Button 
                                            @click="setStatusInstiRequest(info.idNotificacao, 'false')"
                                            section="descartar"
                                            value="Recusar"
                                            :isOutline="true"
                                            :hasIcon="false"
                                            class="button-style"
                                        />  
                                        <Button 
                                            @click="setStatusInstiRequest(info.idNotificacao, 'true')"
                                            section="salvar"
                                            value="Aceitar"
                                            :isOutline="true"
                                            :hasIcon="false"
                                            class="button-style ml-2"
                                        />  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                    <transition-group tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style">Sem resultados.</label>
                    </transition-group>             
                </div>

                <!----------------- FIM DA ABA CONVITES PENDENTES/NULL ---------------------------->
                <!----------------- ABA CONVITES ACEITOS/TRUE ------------------------------------->
                <div class="tab-pane fade" id="nav-profile-insti" role="tabpanel" aria-labelledby="nav-profile-tab">
                    <transition-group tag="div" v-if='filterSolicitacoes' class="listagem-solicitacao" name="listanimate">
                        <div v-for="info in filterSolicitacoes" :key="info.idInstituicao?.idInstituicao">
                            
                            <div v-if="info.aceito == 'ACEITO'">
                                <div class="institution-row-style">
                                    <div class="infos-text">
                                        <i class="img-style ml-1 mr-2 fas fa-user"></i>
                                        <label class="text-style"> {{ info.idInstituicao?.nome }} </label>
                                    </div>
                                    <label class="text-style aceito-text">ACEITO</label>
                                </div>
                            </div>
                        </div>
                    </transition-group>
                    <transition-group tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style">Sem resultados.</label>
                    </transition-group>
                </div>
                <!----------------- FIM DA ABA CONVITES ACEITOS/TRUE ------------------------------------->
                <!----------------- ABA CONVITES RECUSADOS/FALSE ------------------------------------->
                <div class="tab-pane fade" id="nav-contact-insti" role="tabpanel" aria-labelledby="nav-contact-tab">
                    <transition-group tag="div" v-if='filterSolicitacoes' class="listagem-solicitacao" name="listanimate">
                        <div v-for="info in filterSolicitacoes" :key="info.idInstituicao?.idInstituicao">
                            <div v-if="info.aceito == 'RECUSADO'">
                                <div class="institution-row-style">
                                    <div class="infos-text">
                                        <i class="img-style ml-1 mr-2 fas fa-user"></i>
                                        <label class="text-style"> {{ info.idInstituicao?.nome }} </label>
                                    </div>                                
                                    <label class="text-style recusado-text">RECUSADO</label>                                
                                </div>
                            </div>
                        </div>
                    </transition-group>
                    <transition-group tag="div" v-else class="listagem-solicitacao" name="listanimate">
                        <label class="text-style">Sem resultados.</label>
                    </transition-group>
                </div>   
            </div>
        </div>   
    </div>
</template>

<script>

 import Button from '@components/Button'
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 })

 export default {
     name: "GroupInstiRequests",
     data() {
         return {
             searchByName: '',
             infosData: [],
         }
     },
     components: {
         Button,
     },
     async created() {
         const payload = {
             idGrupo: this.$route.params.idGrupo,
             notiTypes: ["INSTITUICAO_GRUPO"],
         }
         this.$store.dispatch('setListGroupRequests', payload)
     },
     computed: {
         filterSolicitacoes: function() {
             //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
             var allRequests = this.$store.getters.getListGroupRequests;

             var query = allRequests
             query = query?.sort((a, b) => a?.idInstituicao?.nome?.localeCompare(b?.idInstituicao?.nome));

             if (this.searchByName.trim() == '') {
                 query = allRequests
             }
             //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
             else {
                 query = allRequests?.filter(team => team.idInstituicao.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
             }
             return query;
         }
     },
     methods: {
         async setStatusInstiRequest(idSolicitacao, status) {

             const payload = {
                 idInstituicao: this.$route.params.idInstituicao,
                 idSolicitacao: idSolicitacao,
                 isAceito: status
             }
             await this.$store.dispatch('setStatusInstiRequest', payload)
             const payload2 = {
                 idInstituicao: this.$route.params.idInstituicao,
                 notiTypes: [ "INSTITUICAO_GRUPO" ],
             }
             this.$store.dispatch('setListInstiRequests', payload2)
             notyf.open({
                 type: 'success',
                 message: 'Solicitação atualizada!'
             });
         }
     },

 }

</script>

<style scoped>
 .container{
     padding: 2%;
     width: 100%;
     background-color: white;
 }

 .title-style{
     font-size: 1.5rem;
     font-weight: 600;
     color: #656565;
 }

 .nav-tabs{
     margin: 1rem 0 0 0;
     cursor: pointer;
 }

 .image-style{
     display: block;
     margin-left: auto;
     margin-right: auto;
     width: 50%;
     padding: 1rem;
 }

 .button-div{
     text-align: right;
 }

 .recusado-text {
     color: #d40707;
     font-weight: bold;
 }

 .aceito-text {
     color: #038b25b4;
     font-weight: bold;
 }

 .institution-row-style{
     border-bottom: 1px solid #ddd;
     margin: 0;
     padding: 0.5rem 0.3rem;
     text-align: left;
     display: flex;
     justify-content: space-between;
     align-items: center;
 }

 .img-style{
     font-size: 1.3rem;
     color: #0d9f9f;
     border-radius: 5rem;
 }

 .text-style{
     padding-top: 0.8rem;
     font-weight: 500;
 }

 .separator {
     border: none;
     height: 1px;
     margin: 1 0 0 0;
     margin-bottom: 0;
     background-color: rgba(51, 51, 51, 0.178);
 }

 .button-style {
     height: 2.2rem;
     margin-top: 0.35rem;
 }

 .input-personal {
     padding: 0.2rem 0.4rem;
     font-size: 15px;
     font-weight: 400;
 }

 .select-personal, .input-personal {
     border-radius: 8px;
     background: #FCFCFC;
     outline: none;
     border: 1px solid rgba(0, 0, 0, 0.11);
     font-weight: lighter;
     padding: 0.2rem 0.4rem;
     box-shadow: 1px 2px 6px -3px rgb(0 0 0 / 25%);
 }

 .listagem-solicitacao {
     max-height: 300px;
 }

 #nav-home-insti {
     overflow: auto;
 }

 #nav-tabContent {
     overflow: auto;
     padding: 0 5px;
 }

 .nav {
     flex-wrap: nowrap;
     gap: 5px;
     align-items: center;
     justify-content: space-around;
 }

 .nav-item {
     flex: 1;
     padding: 10px 0;
     text-align: center;
 }

</style>
