<template>
    <div class="header">
        <div class="logo">
            Transição
            <br />
            Escola-Trabalho
        </div>

        <router-link to="/" class="clickable">
            Página inicial
        </router-link>

        <!-- <router-link to="/">
             <button class="greenGradient clickable">Responder</button>
             </router-link> -->
    </div>
</template>

<script type="text/javascript">
 export default {
     name: "TransicaoHeader",
 };
</script>

<style type="text/css" media="screen" scoped>
 @import url('../../assets/css/TransicaoEscolaTrabalho/styling.css');

 .header {
     width: 100%;
     position: sticky;
     top: 0;
     left: 0;
     padding: 1rem;
     background-color: white;
     border-bottom: 3px solid var(--light-green);
     box-sizing: border-box;
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: space-around;
     color: var(--font-green) !important;
     font-weight: var(--semibold);
     margin-bottom: 1rem;
 }

 .logo {
     width: max-content;
     border-left: 2px solid currentColor;
     padding-left: 10px;
     line-height: 1.03em;
 }
</style>
