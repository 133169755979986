//---------------------------------------------
// Materiais Documentos Module
//---------------------------------------------
// Módulo para gerenciar a seção materiais e documentos

// import axios from 'axios';
// // axios.defaults.baseURL = 'http://localhost:3000'
// axios.defaults.baseURL = process.env.VUE_APP_API_ROOT ? process.env.VUE_APP_API_ROOT : 'http://localhost:3000'
import axios from '@middleware/api.js'
import Cookies from 'js-cookie'

// var qs = require('qs');
var JWT = () => {
	return Cookies.get('accessToken') ? Cookies.get('accessToken') : 'JWT VAZIO';
};

// STATES
const state = () => ({
	listModais: [],
	listCards: [],
	materialDeleted: null,
    cardUpdated: null,
    cardInserted: null,
    cardItemDeleted: null,
    cardDeleted: null,
});

// MUTATIONS
const mutations = {
	setListModais(state, payload) {
		state.listModais = payload;
	},

	setListCards(state, payload) {
		state.listCards = payload;
	},

	setMaterialDeleted(state, payload) {
		state.materialDeleted = payload;
	},

    setCardUpdated(state, payload) {
        state.cardUpdated = payload;
    },

    setCardInserted(state, payload) {
        state.cardInserted = payload;
    },

    setCardItemDeleted(state, payload) {
        state.cardItemDeleted = payload;
    },

    setCardDeleted(state, payload) {
        state.cardDeleted = payload;
    },
}

// ACTIONS
const actions = {
	async createModal(_, payload) {
		await axios
			.post(
				`materiais_documentos`,
				{
					titulo: payload.titulo,
					link: payload.link,
					categoria: payload.categoria,
					isVideo: payload.isVideo,
				},
				{
					headers: {
						Authorization: `Bearer ${JWT()}`,
					},
				}
			)
			.catch(error => console.log(error.message))
	},

	async updateModal(_, payload) {
		await axios
			.put(
				`materiais_documentos/${payload.idModal}`,
				{
					titulo: payload.titulo,
					link: payload.link,
				},
				{
					headers: {
						Authorization: `Bearer ${JWT()}`,
					},
				}
			)
			.catch(error => console.log(error.message))
	},

	async deleteMaterial({ commit }, payload) {
		commit('setMaterialDeleted', null)

		await axios
			.delete(
				`materiais_documentos/${payload.idModal}`,
				{
					headers: {
						Authorization: `Bearer ${JWT()}`,
					},
				}
			)
			.then(() => commit('setMaterialDeleted', true))
			.catch(error => {
				console.log(error.message);
				commit('setMaterialDeleted', false);
			});
	},

	async setListModais({ commit }, payload) {
		await axios
			.get(`materiais_documentos?categoria=${payload.categoria}`, {
				headers: {
					Authorization: `Bearer ${JWT()}`,
				},
			})
			.then(response => commit('setListModais', response.data))
			.catch(error => console.log(error.message));
	},

    async setListCards({ commit }, ) {
		await axios
			.get(`materiais_documentos/cards`, {
				headers: {
					Authorization: `Bearer ${JWT()}`,
				},
			})
			.then(response => {
                commit('setListCards', response.data.data.sort((card1, card2) => card1.ordem - card2.ordem));
            })
			.catch(error => {
                console.log(error.message);
                commit('setListCards', null);
            });
	},

	async updateCard({ commit }, payload) {
		commit('setCardUpdated', null);

		let formData = new FormData();
		if (payload?.idTipo) formData.append("idTipo", payload?.idTipo);
		if (payload?.titulo) formData.append("titulo", payload?.titulo);
		if (payload?.corpoTexto) formData.append("corpoTexto", payload?.corpoTexto);
		if (payload?.imagem) formData.append("imagem", payload?.imagem);
		if (payload?.ordem) formData.append("ordem", payload?.ordem);
		if (payload?.botoes) payload?.botoes.forEach(v => formData.append("botoes[]", JSON.stringify(v)));

		await axios
			.put(
				`materiais_documentos/cards/${payload.idCard}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${JWT()}`,
					},
				}
			)
            .then(() => commit("setCardUpdated", true))
			.catch(error => {
                console.log(error.message);
		        commit('setCardUpdated', false);
            });
	},

	async insertCard({ commit }, payload) {
		commit('setCardInserted', null);

		let formData = new FormData();
		formData.append("idTipo", payload?.idTipo);
		formData.append("titulo", payload?.titulo);
		formData.append("corpoTexto", payload?.corpoTexto);
		formData.append("imagem", payload?.imagem);
		formData.append("ordem", payload?.ordem);
		payload?.botoes.forEach(v => formData.append("botoes[]", JSON.stringify(v)));

		await axios
			.post(
				`materiais_documentos/cards`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${JWT()}`,
					},
				}
			)
            .then(() => commit("setCardInserted", true))
			.catch(error => {
                console.log(error.message);
		        commit('setCardInserted', false);
            });
	},

	async deleteCardItem({ commit }, payload) {
		commit('setCardItemDeleted', null)

		await axios
			.patch(
				`materiais_documentos/cards/${payload.idCard}`,
				{
                    ...payload.data,
				}, {
					headers: {
						Authorization: `Bearer ${JWT()}`,
					},
                }
			)
			.then(() => commit('setCardItemDeleted', true))
			.catch(error => {
				console.log(error.message);
				commit('setCardItemDeleted', false);
			});
	},

	async deleteCard({ commit }, payload) {
		commit('setCardDeleted', null)

		await axios
			.delete(
				`materiais_documentos/cards/${payload}`, {
					headers: {
						Authorization: `Bearer ${JWT()}`,
					},
				}
			)
			.then(() => commit('setCardDeleted', true))
			.catch(error => {
				console.log(error.message);
				commit('setCardDeleted', false);
			});
	},
}

const getters = {
	getListModais(state) {
		try {
			return state.listModais.data;
		} catch (e) {
			return [];
		}
	},

	getMaterialDeleted(state) {
		return state.materialDeleted;
	},

    getListCards(state) {
        return state.listCards;
    },

    getCardUpdated(state) {
        return state.cardUpdated;
    },

    getCardInserted(state) {
        return state.cardInserted;
    },

    getCardItemDeleted(state) {
        return state.cardItemDeleted;
    },

    getCardDeleted(state) {
        return state.cardDeleted;
    },
};

export default {
	state,
	mutations,
	actions,
	getters,
};