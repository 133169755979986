<template>
<div class="root-dados">
  <div class="container list-data">

    <ModalCentral
      componentName="UploadEgressos"
      title="Carregar egressos"
      idName="uploadEgressos"
    />

    <ModalCentral
      componentName="AddEgresso"
      :title="egressoEditing == null ? 'Adicionar egresso' : 'Atualizar egresso'"
      idName="addEgresso"
      :data="{ editing: egressoEditing }"
    />

    <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-campo"/>
    <br/>

    <div class="title-row">
      <InstitutionInfo class="title-style" :title="campo.titulo" section="instituicao-cadastrar"/>
    </div>

    <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os Egressos relacionados ao Campo. Caso necessário, utilize o filtro abaixo para localizar o elemento desejado.</p>

    <template v-if="userPerm.permissao != 'APLICADOR'">
      <div class="lista-data-info">
        <Button
          v-if="campoStatus != 'OFF'"
          @click="setSelectAllItems"
          class="btn greenGradient"
          section="instituicao-campo"
          value="Marcar Todos"
          :isOutline="true"
          :hasIcon="true"
          iconName="check-square"
          style="height: min-content;"
          />

        <Button
          v-if="campoStatus != 'OFF'"
          @click="setUnselectAllItems"
          class="btn ml-2 greenGradient"
          section="instituicao-campo"
          value="Desmarcar Todos"
          :isOutline="true"
          :hasIcon="true"
          iconName="square"
          style="height: min-content;"
          />

        <Button
          v-if="campoStatus != 'OFF'"
          @click="applyChanges"
          class="btn ml-2 greenGradient"
          section="instituicao-campo"
          value="Aplicar Alterações"
          :isOutline="true"
          :hasIcon="true"
          iconName="check"
          style="height: min-content;"
        />

        <Button
          v-if="campoStatus != 'OFF'"
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Carregar dados"
          :isOutline="true"
          :hasIcon="true"
          iconName="user-friends"
          data-toggle="modal"
          data-target="#uploadEgressos"
        />

        <Button
          v-if="campoStatus != 'OFF'"
          @click="egressoEditing = null"
          class="btn btnCtrl"
          section="instituicao-campo"
          value="Adicionar egresso"
          :isOutline="true"
          :hasIcon="true"
          iconName="plus"
          data-toggle="modal"
          data-target="#addEgresso"
        />
      </div>
      <br />
    </template>


    <div class="input-group">
      <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar egressos por nome..." autocomplete="off">
      <div class="input-group-append">
        <span class="input-group-text">Nome</span>
      </div>
    </div>
    <hr class="separator">

    <div v-if="isLoadedInfo">
      <MemberQnts
        term="formulários"
        :totalQnt="totalEgressos"
        :filteredQnt="egressos_filtered?.length || 0"
        icon="clipboard" />

      <transition-group v-if="egressos_filtered?.length > 0" name="slide-fade">

        <div class="table-wrap">
          <table class="table mt-3">
            <thead>
              <tr>
                <th scope="col">  </th>
                <th scope="col">Nome</th>
                <th scope="col">CPF</th>
                <th scope="col">Data de nascimento</th>
                <th scope="col">Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr class="table-row" v-for="egresso in egressos_filtered" :key="egresso.idegresso">

                <td>
                  <img
                    class="img-info-box-lista"
                    src="@assets/img/Dados/ListarMembros/coordenador-user-blue.png"
                    alt="Ícone de egresso">
                </td>

                <td> {{ egresso.nome }} </td>
                <td>
                  {{ egresso?.cpf ? formatCpf(`${egresso.cpf}`) : 'Não informado' }}
                </td>
                <td>
                  <span class="second-info">{{ new Date(egresso.dataNascimento).toLocaleDateString() }}</span>
                </td>

                <td>
                  <div class="controlGroup">
                    <div v-if="userPerm.idPermissao != '1'" :class="campoStatus == 'OFF'? 'not-clickable' : ''">
                      <div v-if="egresso.isEditionLocked">Selecionado</div>
                      <div v-else>
                        <div @click="setIsSelected(egresso)" class="select-info">
                          <div :class="['box-select-info mr-2 ',{'selected-info': egresso.isSelect.isSelected}]"></div>
                          <span class="span-label-selected" v-if="egresso.isSelect.isSelected">Selecionado</span>
                          <span class="span-label-selected" v-else>Selecionar</span>
                        </div>
                      </div>
                    </div>

                    <Button
                      v-if="!egresso.isEditionLocked && campoStatus != 'OFF'"
                      value="Editar egresso"
                      class="orange-text"
                      section="instituicao-campo"
                      :isOutline="true"
                      :hasIcon="true"
                      iconName="pen"
                      data-toggle="modal"
                      data-target="#addEgresso"
                      @click="egressoEditing = egresso"
                    />

                    <Button
                      v-if="!egresso.isEditionLocked && campoStatus != 'OFF'"
                      value="Excluir egresso"
                      class="orange-text"
                      section="instituicao-campo"
                      :isOutline="true"
                      :hasIcon="true"
                      iconName="trash"
                      @click="removeEgresso(egresso)"
                    />
                  </div>
                </td>

              </tr>
            </tbody>
          </table>
        </div>
      </transition-group>

      <transition-group v-else>
        <h2 class="title-style-box-lista" key="transition-group2">Nenhum egresso encontrado.</h2>
      </transition-group>
    </div>

    <div v-else>
      <SkeletonList/>
    </div>
  </div>
</div>
</template>

<script>
import Breadcrumb from '@components/Breadcrumb'
import ModalCentral from '@components/ModalCentral';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import Button from '@components/Button'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import Swal from 'sweetalert2';
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: 'left',
    y: 'bottom',
  }
});

export default {
  components: {
    Button,
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    MemberQnts,
    ModalCentral,
  },
  data: () => ({
    egressos: [],
    egressos_amostra: [],
    egressoEditing: null,
    searchByName: '',
    crumbs: [
      {
        id: 1,
        value: '',
        iconName: 'th',
        name_route: 'GerenciarInstituicoes'
      },
      {
        id: 2,
        value: 'Instituição',
        iconName: 'university',
        name_route: 'InstituicaoMenu'
      },
      {
        id: 3,
        value: 'Campos',
        iconName: 'th-large',
        name_route: 'ListarCampos'
      },
      {
        id: 4,
        value: 'Gerenciar Campo',
        iconName: 'chart-pie',
        name_route: 'InstituicaoMenuCampo'
      },
      {
        id: 5,
        value: 'Egressos',
        iconName: 'door-open',
        name_route: ''
      }
    ],
    isLoadedInfo: null,
  }),
  watch: {
    rawListEgressos() { this.setData() },
    rawListCampoEgressos() { this.setData() },
  },
  computed: {
    rawListEgressos() { return this.$store.getters.getListEgressos || []; },
    rawListCampoEgressos() { return this.$store.getters.getListCampoEgressos || []; },
    userPerm() { return this.$store.getters.getUserPerm || {}; },
    campos() {
      return this.$store.getters.getListCampos;
    },
    campoStatus() {
      return this.campos.find(campo => campo.idamostra == this.$route.params.idCampo)?.isativo || null;
    },
    campo() {
      return this
        .$store
        .getters
        .getListCampos
        .find(campo => campo.idamostra == this.$route.params.idCampo);
    },
    egressos_filtered() {
      let query = this?.egressos || [];

      if (this.searchByName.trim() != '') {
        let filtered = this?.egressos.filter(egresso => egresso.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))

        if (filtered?.length != 0)
          query = filtered;
        else
          query = false;
      }

      return query;
    },
    totalEgressos() {
      return this.rawListEgressos?.length || 0;
    },
  },
  async created() {
    this.loadData()
    const payload = {
      idInstituicao: this.$route.params.idInstituicao,
      idAmostra: this.$route.params.idCampo,
    };

    this.$store.dispatch('setUserPerm', payload)
    this.$store.dispatch('setListCampos', this.$route.params.idInstituicao);
  },
  methods: {
    async removeEgresso(egresso) {
      const result = await Swal.fire({
        title: "Tem certeza que deseja excluir esse egresso?",
        text: "Essa ação não poderá ser revertida.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir egresso",
      });

      if (!result.isConfirmed) return;

      this
        .$store
        .dispatch("removeEgresso", {
          idInstituicao: this.$route.params.idInstituicao,
          idEgresso: egresso.idEgresso,
        })
        .then(() => {
          if (!this.$store.getters.getIsEgressosRemoved) {
            notyf.error("Erro ao excluir o egresso");
            return;
          }

          notyf.success("Egresso excluído com sucesso");
          this.$store.dispatch('loadListEgressos', {
            idInstituicao: this.$route.params.idInstituicao,
          });
        });
    },
    formatCpf(cpf) {
      return cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring(6, 9) + '-' + cpf.substring(9, 11);
    },
    async applyChanges() {
      const result = await Swal.fire({
        title: "Tem certeza que deseja aplicar as alterações?",
        text: "Adicionar todos os egressos selecionados à amostra.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim, tenho certeza",
      });
      if (!result.isConfirmed) return;

      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo,
        addIds: JSON.parse(
          JSON.stringify(
            this.egressos
                .filter(egresso => egresso.isSelect.isSelected)
                .map(egresso => egresso.idEgresso)
          )
        ),
        removeIds: JSON.parse(
          JSON.stringify(
            this.egressos
                .filter(egresso => egresso.isSelect.isSelected == false)
                .map(egresso => egresso.idEgresso)
          )
        ),
      };

      this
        .$store
        .dispatch('setManageEgressos', payload)
        .then(() => {
          this.loadData();
          Swal.fire(
            'Alterações aplicadas!',
            'As seleções foram aplicadas com sucesso.',
            'success'
          );
        })
        .catch(() => {
          Swal.fire(
            'Erro nas alterações!',
            'Houve um erro ao aplicar as seleções. Tente novamente mais tarde.',
            'error',
          );
        });
    },
    async loadData() {
      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo
      };

      this.$store.dispatch('loadListCampoEgressos', payload);
      this.$store.dispatch('loadListEgressos', payload);
    },
    async setData() {
      this.isLoadedInfo = false;

      this.egressos = this.rawListEgressos;
      this.egressos_amostra = this.rawListCampoEgressos;

      this.egressos.forEach(egresso => {
        const foundEgressoAmostra = this.egressos_amostra.find(egresso_amostra => egresso.idEgresso == egresso_amostra.idEgresso);

        egresso.isSelect = {
          isSelected: foundEgressoAmostra ? true : false,
          isSaved: true,
        }
        egresso.isLocked = foundEgressoAmostra?.isLocked || false;
      });

      this.isLoadedInfo = true;
    },
    setIsSelected(egresso) {
      egresso.isSelect.isSelected = !(egresso.isSelect.isSelected || false);
      egresso.isSelect.isSaved = false;
    },
    setSelectAllItems() {
      this.egressos.forEach(egresso => {
        egresso.isSelect = {
          isSelected: true,
          isSaved: false,
        };
      });
    },
    setUnselectAllItems() {
      this.egressos.forEach(egresso => {
        egresso.isSelect = {
          isSelected: false,
          isSaved: false,
        };
      });
    },

  },
};
</script>

<style scoped>
div {
    color: currentColor;
}

.not-clickable{
    cursor: not-allowed;
}

.not-clickable > div{
    pointer-events: none;
}
</style>
