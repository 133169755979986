<template>

    <div class="root-dados">
        <div class="container list-data">

            <ModalCentral
                componentName="AddEducationalUnit"
                title="ADICIONAR UNIDADE EDUCACIONAL"
                idName="AddUE"
                :data="unidades_educacionais"
            />

            <ModalCentral
                componentName="AddEducationalUnitByFile"
                title="ADICIONAR UNIDADES EDUCACIONAIS"
                idName="AddUEbyFile"
            />

            <ModalCentral
                componentName="UpdateUE"
                title="Atualizar UE"
                idName="UpdateUE"
                :data="{
                    idue: editing,
                }"
            />

            <Breadcrumb class="breadcrumbs-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br/>

            <h3 class="title-info"> Unidades Educacionais e Turmas </h3>

            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
            </div>
            
            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todas as Unidades Educacionais da Instituição. Caso necessário, utilize o filtro abaixo para localizar a Unidade Educacional desejada.</p>

            <div class="lista-data-info">
                <Button
                    v-if="userPerm.permissao != 'APLICADOR'"
                    class="btn mb-mobile btnCtrl"
                    value="Adicionar UE"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="graduation-cap"
                    data-toggle="modal"
                    data-target="#AddUE"
                />

                <Button
                    v-if="userPerm.permissao != 'APLICADOR'"
                    class="btn btnCtrl"
                    value="Importar lista de UE"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="clipboard-list"
                    data-toggle="modal"
                    data-target="#AddUEbyFile"
                />

                <Button
                    :disabled="baixando"
                    v-if="userPerm.permissao != 'APLICADOR'"
                    class="btn btnCtrl"
                    value="Baixar dados"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="download"
                    @click="downloadInfo"
                />
            </div>
            <br />

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar Unidades Educacionais por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO AS UNIDADES EDUCACIONAIS ---------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnts
                    term="unidades"
                    :totalQnt="rawListUE?.length || 0"
                    :filteredQnt="unidades_educacionais?.length || 0"
                    icon="graduation-cap" />

                <transition-group v-if="unidades_educacionais?.length > 0" name="slide-fade">
                    <div class = "table-wrap">
                    <table class="table mt-3">
                        <thead>
                            <tr class="text-center">
                                <th scope="col">  </th>
                                <th scope="col">Nome</th>
                                <th scope="col">Município</th>
                                <th scope="col">Turmas</th>
                                <th scope="col">Professores</th>
                                <th scope="col">Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- <ListagemElements :infos="membros" listaType="addMembros"/>  -->
                            <tr class="table-row text-center" v-for="unidade_educacional in unidades_educacionais" :key="unidade_educacional.counter">
                            
                                <td>
                                    <img  class="img-info-box-lista" src="@assets/img/Dados/ListarUE/icon-ue.svg" alt="">
                                </td>

                                <td> {{unidade_educacional.nome}} </td>

                                <td> {{unidade_educacional.cidade}} </td>

                                <td>
                                    <router-link :to="{name: 'ListarTurmas', params: { idUnidade: unidade_educacional.idue }}" class="btn-router-link">
                                    <Button
                                        class="third-info-box-lista btnLista"
                                        value="Ver turmas"
                                        :isOutline="true"
                                    /></router-link>
                                </td>

                                <td>
                                    <router-link :to="{name: 'ListarProfessores', params: { idUnidade: unidade_educacional.idue }}" class="btn-router-link">
                                    <Button
                                        class="third-info-box-lista btnLista"
                                        value="Ver professores"
                                        :isOutline="true"
                                    /></router-link>
                                </td>
                                
                                <td>
                                    <div class="controlGroup">
                                        <Button
                                                v-if="userPerm.permissao != 'APLICADOR' && !unidade_educacional.isEditionLocked"
                                                @click="editing = unidade_educacional.idue"
                                                value="Editar UE"
                                                class="btnLista greenGradient"
                                                section="instituicao-campo"
                                                :isOutline="true"
                                                data-toggle="modal"
                                                data-target="#UpdateUE"
                                            />

                                             <Button
                                             v-if="userPerm.permissao != 'APLICADOR' && !unidade_educacional.isDeletionLocked"
                                             @click="excluirUE(unidade_educacional.idue)"
                                             class="third-info-box-lista btnLista"
                                             value="Excluir"
                                             :isOutline="true"
                                             :has-icon="true"
                                             iconName="trash"
                                             /> 
                                        <span v-if="userPerm.permissao === 'APLICADOR'">-</span>
                                    </div>
                                </td>

                            </tr>
                        </tbody>
                    </table>
                    </div>
                </transition-group>

                <transition-group v-else>
                    <h2 class="title-style">Nenhuma Unidade Educacional encontrada.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

            <br />
        </div>
    </div>

</template>

<script>
import Button from '@components/Button'
import ModalCentral from '@components/ModalCentral'
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import { Notyf } from "notyf";
const notyf = new Notyf({
    duration: 5000,
    position: {
        x: "left",
        y: "bottom",
    },
});
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'

export default {
    name: 'ListarUnidadesEducacionais',
    data (){
        return {
            editing: null,
            baixando: false,
            nomeInstituicao: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            isLoadedInfo: false,
            teste: [],
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome'
                }
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Cadastrar Informações',
                    iconName: 'search-plus',
                    name_route: 'InstituicaoMenuCadastrar'
                },
                {
                    id:4,
                    value: 'Unidades Educacionais',
                    iconName: 'graduation-cap',
                    name_route: ''
                }
            ]
        }
    },
    async created(){
        this.teams = this.$store.getters.getListTeams
        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }
        await this.$store.dispatch('setUserPerm', payload)

        this.isLoadedInfo = false
        await this.$store.dispatch('setListUE', payload)
        this.isLoadedInfo = true
    },
    computed: {
        rawListUE() {
            return this.$store.getters.getListUE;
        },
        unidades_educacionais(){
            var unidades_educacionais = this.$store.getters.getListUE

            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = unidades_educacionais;

            if (this.searchByName.trim() != '') {
                var filtered = unidades_educacionais?.filter(unidades_educacional =>unidades_educacional.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                // console.log(filtered);
                if(filtered?.length != 0){
                    query = filtered
                }

                else{
                    query = false
                }
            }

            //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
            // if (this.selectedValue == 1) {
            //     query = unidades_educacionais;
            // }
            //     //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
            // if (this.selectedValue == 2) {
            //     //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
            //     if (this.searchByName.trim() == '') {
            //         query = unidades_educacionais;
            //     }
            //     //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
            //     else {
            //         var filtered = unidades_educacionais?.filter(unidades_educacional =>unidades_educacional.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
            //             // console.log(filtered);
            //         if(filtered?.length != 0){
            //             query = filtered
            //         }
            //
            //         else{
            //             query = false
            //         }
            //     }
            // }

            return query;
        },
        userPerm() {
            return this.$store.getters.getUserPerm
        }
    },
    watch: {
        // rawListUE() { this.setData(); },
    },
    components: {
        Button,
        ModalCentral,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
        MemberQnts,
    },
    methods: {
        async downloadInfo() {
            if (this.baixando) return;

            this.baixando = true;

            const notiLoad = notyf.open({
                type: "info",
                background: "var(--light-green)",
                message: "Carregando dados...",
                duration: 0,
            });

            this.$store
                .dispatch("getInstiFile", this.$route.params.idInstituicao)
                .then(response => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'info.csv');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(err => {
                    console.log(err);
                    notyf.open({
                        type: "error",
                        message: "Erro ao obter informações da instituição",
                    });
                })
                .finally(() => {
                    notyf.dismiss(notiLoad);
                    this.baixando = false;
                });
        },

        setSelectedValue: function() {
            //! TODO: só pra parar de dar erro no console
            this.selectedValue = this.$refs.filtersearchname.value
        },
        async createUE(){
            const payload = {
                nome: 'Silverio',
                bairro: 'Santa Tereza',
                macroarea: 'Sei la bro, São jose?',
                inep: 12666556,
                localizacao: 'RURAL',
                anoInicioAtividades: 2010,
                municipio: 'São José Do rio Pardo',
                rua: 'Dos Bandeirantes',
                numero: 890,
                complemento: 'Em frente à praça',
                modalidade: 'PRIVADA'
            }            
            await this.$store.dispatch('setCreateUE', payload)
        },
        async excluirUE(idUE){
             const result = await Swal.fire({
                 title: 'Deseja mesmo excluir a Unidade Educacional?',
                 text: 'Ao excluir, você não terá mais acesso às informações da Unidade Educacional.',
                 icon: "question",
                 showCancelButton: true,
                 confirmButtonColor: "#3085d6",
                 cancelButtonColor: "#d33",
                 cancelButtonText: "Cancelar",
                 confirmButtonText: "Excluir UE",
             })

             if (result.isConfirmed) {
                 const payload = {
                     idInstituicao: this.$route.params.idInstituicao,
                     idUE: idUE
                 }
                 await this.$store.dispatch('deleteUE', payload)
                 await this.$store.dispatch('setListUE', 
                    {idInstituicao: this.$route.params.idInstituicao}
                 )
                 notyf.open({
                    type: 'success',
                    message: 'A unidade foi excluída com sucesso.'
                 });
             }
         },
        async sendFile(){
            const payload = {
                id_instituicao: 3,
                file: 'filePathHere'
            }            
            await this.$store.dispatch('setSendFile', payload)
        }
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>

@import "../../../assets/css/Dados/list.css";

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border: 1px solid #952713;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

.text-info-personal {
  color: #fff;
  font-weight: 450;
  font-size: 1.1rem;
  text-align: justify;
}


.label-filtro {
    font-weight: 600;
}


.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-style{
    font-size: 25px;
    color: #656565;
    margin: 0;
    text-align: center;
    font-weight: 500;
}

.title-info{
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
}
.title-style{
    margin: 0;
    padding: 0;
}

 h3.title-info{
    margin-top: 1.6rem;
    font-size: 1.25rem;
 }

 .title-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.separator {
    border: none;
    height: 1px;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-self: start;
        align-items: flex-start;
    }
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .filter-box{
        margin: 0 3rem;
    }
    .lista-data-info{
        margin: 0 3rem;
    }
    .mb-mobile{
        margin-bottom: 1rem;
    }
    .box-lista span, .box-lista a{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .box-lista{
        place-content: center;
    }
}

 .img-info-box-lista {
     padding: 10px;
 }

 .btnCtrl {
     box-sizing: border-box;
     border: none !important;
 }

 :deep(.btnCtrl div) {
     background-clip: text;
     background-repeat: repeat;
     background-size: 256px;
     color: #00853B;
     padding: 0.5rem 0;
     transition: 0.4s;
 }


 .btnCtrl, .btnLista{
     background-color: white;
 }
    
</style>
