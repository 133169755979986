<template>
    <form
        v-if="loaded && pratica"
        @submit.prevent
        class="custom-form">

        <div class="form-group">
            <label for="title" class="question-style">Título da prática: *</label>
            <input
                v-model="pratica.titulo"
                name="title"
                type="text"
                class="form-control"
                placeholder="Insira aqui o título da prática..."
                required
            />
        </div>

        <div class="form-group">
            <label for="author" class="question-style">Autor: *</label>
            <input
                v-model="pratica.autor"
                name="author"
                type="text"
                class="form-control"
                placeholder="Insira aqui o autor da prática..."
                required
            />
        </div>

        <div class="form-group">
            <label for="region" class="question-style">Região: *</label>
            <select
                v-model="pratica.regiao"
                name="region"
                class="form-control"
                required>
                <option
                    v-for="(region, idx) in regions"
                    :key="idx"
                    :value="region">
                    {{ region }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label for="ageGroup" class="question-style">Idade: *</label>
            <select
                v-model="pratica.idade"
                name="ageGroup"
                class="form-control"
                required>
                <option
                    v-for="(age, idx) in ageBrackets"
                    :key="idx"
                    :value="age">
                    {{ age }}
                </option>
            </select>
        </div>

        <div class="form-group">
            <label for="link" class="question-style">Vídeo: *</label>
            <input
                v-model="pratica.link"
                name="link"
                type="url"
                class="form-control"
                placeholder="Insira aqui o link do vídeo relacionado..."
            />
        </div>

        <div class="input-file">
            <label class="question-style">Novo documento PDF: *</label>
            <div v-cloak @click="openDialog" @drop.prevent="addFile" @dragover.prevent @dragover.capture="isDragOverActions()" @dragleave.capture="isDragLeaveActions()" class="drag-and-drop" :class="{'drag-style': isDragOver}">
                <div v-if="pdf == null" class="text-center infos">
                    <img class="img-fluid img-center-input-file" :src="require('@assets/img/Dados/ExportarDevolutiva/'+name_image+'.png')">
                    <p class="label-center-input-file">{{label}}</p>
                </div>
                <div class="file-uploaded" v-else>
                    <img class="img-icon-uploaded" src="@assets/img/Dados/ExportarDevolutiva/img-icon.png" alt="">
                    <br />
                    {{ pdf.name.substr(0, 7)+'...'}} {{ pdf?.size ? (pdf.size/1000).toFixed(1)+ ' KB' : '...' }}
                </div>
            </div>
            <button v-if="pdf != null" class="remove-file" @click="removeFile()" title="Remove">Remover Arquivo</button>
            <input tabindex="-1" @change="addFileViaInput" type="file" ref="uploadFile" placeholder="Escolha ou arraste um arquivo para gerar a devolutiva" hidden>
        </div>

        <div class="form-group">
            <label for="summary" class="question-style">{{ sections[1] }}:</label>
            <textarea
                v-model="pratica.secao2"
                name="summary"
                rows="3"
                class="form-control"
                tabindex="-1"
                required>
            </textarea>
        </div>

        <div class="form-group">
            <label for="themes" class="question-style">Categorias:</label>
            <div
                v-for="(setTheme, curIdx) in pratica.tematicas"
                :key="curIdx"
                class="themeContainer">
                <select
                    class="form-control"
                    v-model="pratica.tematicas[curIdx]">
                    <option
                        v-for="(theme, idx) in categories"
                        :key="idx"
                        :value="theme">
                        {{ theme }}
                    </option>
                </select>
                <i @click="removeTheme(curIdx)" class="fas fa-trash-alt"></i>
            </div>
            <div class="themeControls">
                <Button
                    @click="addTheme"
                    class="themeButton"
                    section="instituicao-cadastrar"
                    value="Adicionar temática"
                    :isOutline="true"
                    :hasIcon="true"
                    iconName="plus"
                />
            </div>
        </div>

        <div class="form-group">
            <label for="resume" class="question-style">{{ sections[0] }}: *</label>
            <textarea
                v-model="pratica.secao1"
                name="resume"
                rows="3"
                class="form-control"
                tabindex="-1"
                required>
            </textarea>
        </div>

        <div class="form-group">
            <label for="body" class="question-style">{{ sections[2] }}: *</label>
            <textarea
                v-model="pratica.secao3"
                name="body"
                rows="3"
                class="form-control"
                tabindex="-1"
                required>
            </textarea>
        </div>

        <div class="form-group">
            <label for="body" class="question-style">{{ sections[3] }}: *</label>
            <textarea
                v-model.trim="pratica.secao4"
                name="body"
                rows="3"
                class="form-control"
                tabindex="-1"
                required>
            </textarea>
        </div>

        <div class="form-group">
            <label for="body" class="question-style">{{ sections[4] }}: *</label>
            <textarea
                v-model.trim="pratica.secao5"
                rows="3"
                class="form-control"
                tabindex="-1"
                required>
            </textarea>
        </div>

        <Button
            @click="updatePratica"
            class="saveButton"
            section="instituicao-cadastrar"
            value="Salvar prática"
            :isOutline="true"
            :hasIcon="true"
            iconName="save"
        />
    </form>
</template>

<script type="text/javascript">
 import Button from '@components/Button'
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: "left",
         y: "bottom",
     },
 });

 export default {
     name: "UpdatePratica",
     props: {
         data: {
             type: Object,
             required: true,
         }
     },
     components: {
         Button,
     },
     data() {
         return {
             idPratica: this.data.idPratica,
             pratica: null,
             loaded: false,
             ageBrackets: [
                 "Bebês (0 a 6 meses de idade)",
                 "Crianças bem pequenas (1 a 3 anos e 11 meses)",
                 "Crianças pequenas (4 a 5 anos e 11 meses)",
             ],
             regions: ['NORTE', 'NORDESTE', 'CENTRO-OESTE', 'SUDESTE', 'SUL'],
             pdf: null,
             pdfAltered: false,

             isTurningCog: false,
             isDragOver: false,
             name_image: "icone-upload",
             label: "Escolha ou arraste o PDF da prática"
         };
     },
     computed: {
         sections() {
             const raw = this.$store.getters.getPraticaSections;
             return raw?.map(section => section.nome) || [
                 "Seção 1",
                 "Seção 2",
                 "Seção 3",
                 "Seção 4",
                 "Seção 5",
             ];
         },
         categories() {
             const raw = this.$store.getters.getPraticaCategories;
             return raw?.sort((a, b) => a.codigo - b.codigo)?.map(cat => cat.nome) || [
                 "Categoria 1",
                 "Categoria 2",
                 "Categoria 3",
                 "Categoria 4",
                 "Categoria 5",
             ];
         },
     },
     async created() {
         this.$store.dispatch('setPraticaCategories');
         this.$store.dispatch('setPraticaSections');
     },
     methods: {
         async setData() {
             this.loaded = false;
             await this.$store.dispatch('loadListBoasPraticas');
             this.listBoasPraticas = this.$store.getters.getListBoasPraticas;
             this.pratica = {...this.listBoasPraticas.find(p => p.idPratica == this.idPratica)};
             if (this.pratica) this.pratica.tematicas = [];
             this.pratica?.boasPraticasCategorias?.forEach(category =>
                 this.pratica.tematicas.push(category.idTipo.nome)
             );

             if (!this.pratica?.link || this.pratica?.link === 'null' || this.pratica?.link === 'undefined')
                 this.pratica.link = "";

             if (this.pratica?.pdf && this.pratica?.pdf != 'null' && this.pratica?.pdf != 'undefined') {
                 this.pdf = {
                     name: "ATUAL",
                 };
                 delete this.pratica?.pdf;
             }
             this.loaded = true;
         },
         async updatePratica() {
             if (this.pratica.link.trim() === "" && this.pdf === null) {
                 notyf.open({
                     type: "error",
                     message: "A prática deve conter um PDF ou vídeo!"
                 });
                 return;
             }

             await this.$store.dispatch(
                 "updateBoasPraticas", {
                     idPratica: this.idPratica,
                     titulo: this.pratica.titulo,
                     secao1: this.pratica.secao1,
                     autor: this.pratica.autor,
                     regiao: this.pratica.regiao,
                     idade: this.pratica.idade,
                     ...(this.pratica.link.trim() != "" && { link: this.pratica.link }),
                     ...(this.pratica.secao2.trim() != "" && { secao2: this.pratica.secao2 }),
                     tematicas: this.pratica.tematicas,
                     secao3: this.pratica.secao3,
                     secao4: this.pratica.secao4,
                     secao5: this.pratica.secao5,
                     ...(this.pdfAltered ? { pdf: this.pdf } : { pdf: undefined }),
                 }
             );

             if (this.$store.getters.getPraticaUpdated) {
                 notyf.open({
                     type: "success",
                     message: "Prática atualizada com sucesso"
                 });
                 this.$store.dispatch('loadListBoasPraticas');
                 this.$emit('closeModal');
             } else {
                 notyf.open({
                     type: "error",
                     message: "Erro na atualização da prática!"
                 });
             }
         },
         addTheme() {
             if (this.pratica.tematicas.length >= this.categories.length) return;
             this.pratica.tematicas.push(this.ageBrackets[0]);
         },
         removeTheme(idx) {
             if (this.pratica.tematicas.length <= 1) return;
             this.pratica.tematicas.splice(idx, 1);
         },
         isDragOverActions() {
             this.isDragOver = true;
             this.label = "Solte o arquivo aqui!";
             this.name_image = "icone-upload-happy";
         },
         isDragLeaveActions() {
             this.isDragOver = false
             this.label = "Escolha ou arraste um arquivo para gerar a devolutiva";
             this.name_image = "icone-upload";
         },
         addFile(e) {
             let droppedFiles = e.dataTransfer.files;
             this.isDragLeaveActions()
             for (let f of droppedFiles) {
                 if (f.type == "application/pdf") {
                     this.pdf = f;
                     this.pdfAltered = true;
                 } else {
                     notyf.open({
                         type: "error",
                         message: `Só são permitidos arquivos do tipo PDF`
                     });
                 }
             }
         },
         addFileViaInput() {
             let droppedFiles = this.$refs.uploadFile.files;
             for (let f of droppedFiles) {
                 if (f.type == "application/pdf") {
                     this.pdf = f;
                     this.pdfAltered = true;
                 } else {
                     notyf.open({
                         type: "error",
                         message: `Só são permitidos arquivos do tipo PDF`
                     });
                 }
             }
         },
         removeFile(){
             this.pdf = null;
             this.pdfAltered = true;
         },
         openDialog() {
             this.$refs.uploadFile.value = ''
             this.$refs.uploadFile.click()
         },
         clearForm() {
             this.titulo = this.autor = this.link = this.sumario = this.resumo = this.corpo = "";
             this.pdf = null;
             this.idade = this.ageBrackets[0];
             this.regiao = this.regions[0];
             this.tematicas.push(this.ageBrackets[0]);
         },
     },
 };
</script>

<style type="text/css" media="screen" scoped>
 form {
     width: 100%;
 }

 .title-style{
     font-size: 1.5rem;
     font-weight: 600;
     color: #656565;
 }

 .question-style{
     width: 100%;
     font-size: 1rem;
     color: #656565;
 }

 textarea {
     resize: none;
     padding: 5px;
 }

 .themeContainer {
     width: 100%;
     display: grid;
     grid-template-columns: 1fr auto;
     grid-template-rows: auto;
     align-items: center;
     margin-bottom: 5px;
 }

 .themeContainer i {
     font-size: 1.1em;
     margin: 5px;
     cursor: pointer;
 }

 .themeButton {
     display: block;
     margin: 5px auto;
 }

 .saveButton {
     float: right;
 }

 .input-file {
     display: flex;
     align-items: center;
     flex-direction: column;
     justify-content: center;
     position: relative;
     margin-bottom: 1rem;
 }

 .input-file input {
     max-width: 30rem;
 }

 .input-file h1 {
     font-size: 1.6rem;
     font-weight: bold;
     text-align: left;
     margin-top: 1rem;
     margin-bottom: 1.5rem;
     color: #0d9f9f;
 }

 .input-file button {
     border: 1px solid #0d9f9f;
     color: #0d9f9f;
     background-color: white;
     border-radius: 4px;
     padding: 0.4rem 1.2rem 0.4rem 0.4rem;
     font-weight: bold;
     cursor: pointer;
     outline: none;
     transition: all ease-in-out 0.2s;
     -webkit-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
     -moz-box-shadow: 10px 10px 5px -1px rgba(0,0,0,0.51);
     box-shadow: 6px 6px 5px -1px rgba(0,0,0,0.2);
 }

 .input-file button:hover {
     color: white;
     background-color: #0d9f9f;
 }

 .drag-and-drop {
     height: 14rem;
     width: 27rem;
     border: 4px dashed #0d9f9f;
     background-color: white;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     cursor: pointer;
     padding: 0 1.5rem;
     margin: 0 2rem;
 }

 .drag-and-drop img {
     max-height: 5rem;
 }

 .drag-and-drop p {
     color: #656565;
     font-weight: 400;
     user-select: none;
 }

 .drag-style {
     filter: blur(0.7px);
 }

 .remove-file {
     margin: 10px;
     text-align: center;
 }

 .file-uploaded {
     text-align: center;
 }
</style>
