import { createApp } from 'vue'
import App from './App.vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'notyf/notyf.min.css';
// import 'vue-search-select/dist/VueSearchSelect.css';
import "vue-multiselect/dist/vue-multiselect.css"

import mitt from 'mitt';
const emitter = mitt();   

import VueCookies from 'vue3-cookies'
import Maska from 'maska';

import store from './store';
import router from './router';

const app = createApp(App);
app.config.globalProperties.emitter = emitter;
app.use(VueCookies, {
    expireTimes: 3600,
    //secure: true //? habilitar quando site estiver no ar em com HTTPS
});
app.use(store);
app.use(router);
app.use(VueSweetalert2);
app.use(Maska);
app.mount('#app');
