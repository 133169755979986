<template>
  <div class="root-dados">
    <ModalCentral
      componentName="AddJovem"
      :title="jovemEdit == null ? 'Adicionar jovem' : 'Atualizar jovem'"
      idName="AddJovem"
      :data="{ editing: jovemEdit, }"
    />

    <div class="container list-data">
      <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-aplicacoes" />
      <br />

      <div class="title-row">
        <InstitutionInfo class="title-style" :title="nomeInsti" section="instituicao-cadastrar" />
      </div>

      <p class="text-info-personal">
        Nesta seção, você encontrará todos os Jovens relacionados ao Empregador. Caso necessário, utilize o filtro abaixo para localizar o Jovem desejado.
      </p>

      <template v-if="userPerm.permissao != 'APLICADOR'">
        <div class="lista-data-info">
          <Button v-if="campoStatus != 'OFF'" @click="setSelectAllItems" class="btn" section="instituicao-campo"
            value="Marcar Todos" :isOutline="true" :hasIcon="true" iconName="check-square" style="height: min-content" />

          <Button v-if="campoStatus != 'OFF'" @click="setUnselectAllItems" class="btn ml-2"
            section="instituicao-campo" value="Desmarcar Todos" :isOutline="true" :hasIcon="true" iconName="square"
            style="height: min-content" />

          <Button v-if="campoStatus != 'OFF'" @click="applyChanges" class="btn ml-2" section="instituicao-campo"
            value="Aplicar Alterações" :isOutline="true" :hasIcon="true" iconName="check" style="height: min-content" />

          <Button v-if="campoStatus != 'OFF'" class="btn btnCtrl" section="instituicao-campo" value="Adicionar jovem"
            @click="jovemEdit = null" :isOutline="true" :hasIcon="true" iconName="plus" data-toggle="modal"
            data-target="#AddJovem" />
        </div>
        <br />
      </template>

      <div class="input-group">
        <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros"
          id="inlineFormCustomName" placeholder="Pesquisar jovens de um empregador por nome..." autocomplete="off" />
        <div class="input-group-append">
          <span class="input-group-text">Nome</span>
        </div>
      </div>
      <hr class="separator" />

      <div v-if="isLoadedInfo">
        <MemberQnts :totalQnt="totalJovens" term="jovens" :filteredQnt="jovensFiltered?.length || 0"
          icon="user-alt" />

        <div class="list-box">
          <transition-group name="slide-fade" v-if="jovensFiltered?.length > 0">
            <div class="table-wrap">
              <table class="table mt-3">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Nome</th>
                    <th scope="col">CPF</th>
                    <th scope="col">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="table-row" v-for="jovem in jovensFiltered" :key="jovem.counter">
                    <td>
                      <img class="img-info-box-lista" src="@assets/img/Dados/ListarInstis/img.png"
                        alt="Ícone de jovem" />
                    </td>

                    <td>{{ jovem.nome }}</td>
                    <td>
                      {{ jovem?.cpf ? formatCpf(`${jovem.cpf}`) : 'Não informado' }}
                    </td>

                    <td>
                      <div class="controlGroup">
                        <div v-if="userPerm.idPermissao != '1'" :class="campoStatus == 'OFF'? 'not-clickable' : ''">
                          <div v-if="jovem.isEditionLocked
                            ">
                            Selecionado
                          </div>
                          <div v-else>
                            <div @click="
                              setIsSelected(
                                jovem
                              )
                              " class="select-info">
                              <div :class="[
                                'box-select-info mr-2 ',
                                {
                                  'selected-info':
                                  jovem
                                      .isSelect
                                      .isSelected,
                                },
                              ]"></div>
                              <span class="span-label-selected" v-if="jovem
                                  .isSelect
                                  .isSelected
                                ">Selecionado</span>
                              <span class="span-label-selected" v-else>Selecionar</span>
                            </div>
                          </div>
                        </div>

                        <Button v-if="!jovem.isEditionLocked && campoStatus != 'OFF'
                          " @click=" jovemEdit = jovem" class="orange-text" value="Atualizar jovem"
                          section="instituicao-aplicacoes" :isOutline="true" :hasIcon="true" iconName="pen"
                          data-toggle="modal" data-target="#AddJovem" />

                        <Button v-if="!jovem.isDeletionLocked && campoStatus != 'OFF'
                          " @click="removeJovem(jovem)" class="orange-text" value="Excluir jovem"
                          section="instituicao-aplicacoes" :isOutline="true" :hasIcon="true" iconName="trash" />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </transition-group>

          <transition-group v-else>
            <h2 class="title-style-box-lista">
              Nenhum jovem encontrado.
            </h2>
          </transition-group>
        </div>
      </div>

      <div v-else>
        <SkeletonList />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@components/Breadcrumb";
import InstitutionInfo from "@dados/VisualizarInstituicao/InstitutionInfo";
import SkeletonList from "@components/SkeletonUI/SkeletonList";
import MemberQnts from "@dados/VisualizarInstituicao/MemberQnt";
import Button from "@components/Button";
import ModalCentral from "@components/ModalCentral";
import Swal from "sweetalert2";
import { Notyf } from "notyf";
const notyf = new Notyf({
  duration: 5000,
  position: {
    x: "left",
    y: "bottom",
  },
});

export default {
  components: {
    Breadcrumb,
    InstitutionInfo,
    SkeletonList,
    MemberQnts,
    Button,
    ModalCentral,
  },
  data: () => ({
    searchByName: "",
    isLoadedInfo: null,
    jovemEdit: null,
    jovens: [],
    jovens_amostra: [],
    crumbs: [
      {
        id: 1,
        value: "",
        iconName: "th",
        name_route: "GerenciarInstituicoes",
      },
      {
        id: 2,
        value: "Instituição",
        iconName: "university",
        name_route: "InstituicaoMenu",
      },
      {
        id: 3,
        value: "Campos",
        iconName: "th-large",
        name_route: "ListarCampos",
      },
      {
        id: 4,
        value: "Gerenciar Campo",
        iconName: "chart-pie",
        name_route: "InstituicaoMenuCampo",
      },
      {
        id: 5,
        value: "Empresas",
        iconName: "building",
        name_route: "CampoListaEmpresas",
      },
      {
        id: 5,
        value: "Empregadores",
        iconName: "user-tie",
        name_route: "CampoListaEmpregadores",
      },
      {
        id: 6,
        value: 'Jovens',
        iconName: 'user-alt',
        name_route: ''
      },
    ],
  }),
  watch: {
    rawListJovens() {
      this.setData();
    },
    rawListCampoJovens() {
      this.setData();
    },
  },
  async beforeMount() {
    const payload = {
      idInstituicao: this.$route.params.idInstituicao,
    };

    this.$store.dispatch("setUserPerm", payload);
    this.$store.dispatch("setListCampos", this.$route.params.idInstituicao);
    this.loadData();
  },
  computed: {
    userPerm() {
      return this.$store.getters.getUserPerm;
    },
    teams() {
      return this.$store.getters.getListTeams;
    },
    nomeInsti() {
      return this.teams.find(
        (insti) =>
          insti.idinstituicao == this.$route.params.idInstituicao
      )?.name;
    },
    _jovens() {
      return this.$store.getters.getListJovens;
    },
    totalJovens() {
      return this._jovens?.length || 0;
    },
    jovensFiltered() {
      let query = this.jovens;

      if (this.searchByName.trim() != "") {
        let filtered = query?.filter((jovem) =>
          jovem.nome
            .trim()
            .toLowerCase()
            .match(this.searchByName.trim().toLowerCase())
        );
        if (filtered?.length != 0) query = filtered;
        else query = false;
      }

      return query;
    },
    rawListJovens() {
      return this.$store.getters.getListJovens;
    },
    rawListCampoJovens() {
      return this.$store.getters.getListCampoJovens;
    },
    campos() {
      return this.$store.getters.getListCampos;
    },
    campoStatus() {
      return (
        this.campos.find(
          (campo) => campo.idamostra == this.$route.params.idCampo
        )?.isativo || null
      );
    },
  },
  methods: {
    formatCpf(cpf) {
      return cpf.substring(0, 3) + '.' + cpf.substring(3, 6) + '.' + cpf.substring(6, 9) + '-' + cpf.substring(9, 11);
    },
    setIsSelected(jovem) {
      jovem.isSelect.isSelected = !jovem.isSelect.isSelected;
      jovem.isSelect.isSaved = false;
    },
    setSelectAllItems() {
      this.jovens.forEach((jovem) => {
        jovem.isSelect.isSelected = true;
        jovem.isSelect.isSaved = false;
      });
    },
    setUnselectAllItems() {
      this.jovens.forEach((jovem) => {
        jovem.isSelect.isSelected = false;
        jovem.isSelect.isSaved = false;
      });
    },
    async applyChanges() {
      if (this.campusStatus == "ON") {
        const result = await Swal.fire({
          title: "Tem certeza que deseja aplicar as alterações?",
          text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Cancelar",
          confirmButtonText: "Sim, tenho certeza",
        });

        if (!result.isConfirmed) return;
      }

      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo,
        idEmpregador: this.$route.params.idEmpregador,
        addIds: this.jovens
          .filter((jovem) => jovem.isSelect.isSelected)
          .map((jovem) => jovem.idJovem),
        removeIds: this.jovens.filter(jovem => jovem.isSelect.isSelected == false).map(jovem => jovem.idJovem),
      };
      await this.$store.dispatch("setManageJovens", payload);

      if (!this.$store.getters.getIsJovensManaged) {
        notyf.error("Erro ao aplicar alterações");
        return;
      }

      Swal.fire(
        "Alterações aplicadas!",
        "As seleções foram aplicadas com sucesso.",
        "success"
      );

      this.loadData();
    },

    async removeJovem(jovem) {
      const result = await Swal.fire({
        title: "Deseja mesmo excluir o jovem?",
        text: "Ao excluir, você não terá mais acesso às informações do jovem.",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Excluir jovem",
      });

      if (result.isConfirmed) {
        await this.$store.dispatch("removeJovem", {
          idInstituicao: this.$route.params.idInstituicao,
          idEmpregador: this.$route.params.idEmpregador,
          idJovem: jovem.idJovem,
        });

        if (this.$store.getters.getIsJovemRemoved) {
          notyf.success("Jovem excluído com sucesso");
          this.loadData();
        } else notyf.error("Erro ao excluir o jovem");
      }
    },
    setData() {
      this.jovens = this.rawListJovens || [];
      this.jovens_amostra = this.rawListCampoJovens || [];

      this.jovens.forEach((jovem) => {
        const found = this?.jovens_amostra.find(
          (jovens_amostra) =>
            jovens_amostra.idJovem ==
            jovem.idJovem
        );
        jovem.isSelect = {
          isSelected: found ? true : false,
          isSaved: true,
        };
        jovem.idjovemamostra = found?.idjovemamostra;
      });

      this.isLoadedInfo = true;
    },
    async loadData() {
      //payload
      const payload = {
        idInstituicao: this.$route.params.idInstituicao,
        idAmostra: this.$route.params.idCampo,
        idEmpregador: this.$route.params.idEmpregador,
      };

      this.$store.dispatch("setUserPerm", payload);
      await this.$store.dispatch("loadListJovens", payload);
      await this.$store.dispatch("loadListCampoJovens", payload);
      this.isLoadedInfo = true;
    },
  },
};
</script>

<style scoped>
div {
  color: currentColor;
}

.not-clickable{
    cursor: not-allowed;
}

.not-clickable > div{
    pointer-events: none;
}
</style>
