<template>

    <div class="root-dados">
        <div class="container list-data">

            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-campo"/>
            <br/>

            <div class="title-row" style="justify-content: flex-start; gap: 10px;">

                <InstitutionInfo class="title-style" :title="nomeCampo" section="instituicao-cadastrar"/>
                <template class="lista-data-info" v-if="userPerm.permissao != 'APLICADOR'">
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="setSelectAllItems"
                        class="btn greenGradient"
                        section="instituicao-campo"
                        value="Marcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check-square"
                        style="height: min-content;"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="setUnselectAllItems"
                        class="btn ml-2 greenGradient"
                        section="instituicao-campo"
                        value="Desmarcar Todos"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="square"
                        style="height: min-content;"
                    />
                    <Button
                        v-if="campoStatus != 'OFF'"
                        @click="applyChanges"
                        class="btn ml-2 greenGradient"
                        section="instituicao-campo"
                        value="Aplicar Alterações"
                        :isOutline="true"
                        :hasIcon="true"
                        iconName="check"
                        style="height: min-content;"
                    />
                </template>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os Membros pertencentes ao Campo. Caso necessário, utilize o filtro abaixo para localizar o Membro desejado.</p>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar membros por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS MEMBROS ----------------------------------------------->

            <div v-if="isLoadedInfo">

                <transition-group name="slide-fade" v-if="membros_filtered?.length > 0">
                    <div class = "table-wrap" key="table">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">  </th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Cargo</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <ListagemElements :infos="membros" listaType="addMembros"/>  -->
                                <tr class="table-row" v-for="membro in membros_filtered"  :key="membro.idusuario">

                                    <td>
                                        <img v-if="membro.permissao == 'ADMINISTRADOR'" class="img-info-box-lista" src="@assets/img/Cards/Coroa.svg" alt="">
                                        <img v-else-if="membro.permissao == 'COORDENADOR'" class="img-info-box-lista" src="@assets/img/Cards/Membro.svg" alt="">
                                        <img v-else class="img-info-box-lista" src="@assets/img/Cards/Lapis.svg" alt="">
                                    </td>

                                    <td> {{membro.nome}} </td>

                                    <td>{{membro.email}}</td>

                                    <td> {{membro.permissao}} </td>

                                    <td>
                                        <div v-if="userPerm.idPermissao != '1'">
                                            <div v-if="membro.isLocked">Selecionado</div>
                                            <div v-else>
                                                <div v-if="['ON', 'INATIVO'].includes(campoStatus)" @click="setIsSelected(membro.idusuario)" class="select-info">
                                                    <div :class="['box-select-info mr-2 ',{'selected-info': membro.isSelect.isSelected}]"></div>
                                                    <span class="span-label-selected" v-if="membro.isSelect.isSelected">Selecionado</span>
                                                    <span class="span-label-selected" v-else>Selecionar</span>
                                                </div>
                                                <div v-else class="select-info-blocked">
                                                    <span class="span-label-selected" v-if="membro.isSelect.isSelected">Selecionado</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            -
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhum aplicador encontrado.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 // import Filter from '@components/Filter'
import Button from '@components/Button'
import Breadcrumb from '@components/Breadcrumb'
import Swal from 'sweetalert2';
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import Amostra from '@middleware/amostra.js'
import SkeletonList from '@components/SkeletonUI/SkeletonList'

export default {
    name: 'CampoListaMembros',
    data (){
        return {
            nomeCampo: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            searchByCode: '',
            searchWithOrder: '',
            isLoadedInfo: false,
            teste: [],
            membros: [],
            membros_amostra: [],
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome'
                },
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Campos',
                    iconName: 'th-large',
                    name_route: 'ListarCampos'
                },
                {
                    id:4,
                    value: 'Gerenciar Campo',
                    iconName: 'chart-pie',
                    name_route: 'InstituicaoMenuCampo'
                },
                {
                    id:5,
                    value: 'Membros',
                    iconName: 'user-friends',
                    name_route: ''
                }
            ]
        }
    },
    watch: {
        rawListMembros() { this.setData() },
        rawListCampoMembros(){ this.setData() },
    },
    computed: {
        campoStatus() {
            return this.$store.getters.getAmostraStatusAtiva
        },
        userPerm() {
            return this.$store.getters.getUserPerm
        },
        membros_filtered() {
            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = this.membros || [];
            if (this.searchByName.trim() !== '') {
                var filtered = this.membros.filter(membro =>membro.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                // console.log(filtered);
                if(filtered?.length != 0){
                    query = filtered
                }

                else{
                    query = false
                }
            }
            return query;

        },
        rawListMembros() { return this.$store.getters.getListMembros },
        rawListCampoMembros() { return this.$store.getters.getListCampoMembros },
    },
    async created() {
        this.isLoadedInfo = false

        this.campos = this.$store.getters.getListCampos

        this.loadData()

        const payload = {
            idInstituicao: this.$route.params.idInstituicao
        }
        await this.$store.dispatch('setUserPerm', payload)
        await this.$store.dispatch('setListCampos', this.$route.params.idInstituicao)

        const all_campos = this.$store.getters.getListCampos
        const campo = all_campos.filter((value) => {
            return value.idamostra == this.$route.params.idCampo
        }).map((value) => {return value.isativo})

        const campoStatus = campo[0]

        this.$store.commit('setAmostraStatusAtiva', campoStatus)
    },
    components: {
        // Filter,
        Button,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList
    },
    methods: {
        setIsSelected(id) {
            this.membros = Amostra.setIsSelectedItem(this.membros, 'usuario', id)
        },
        setSelectAllItems() {
            this.membros = Amostra.setSelectAllItems(this.membros, 'selectMembros')
 
            Amostra.setAllSelectedItems(this.membros, 'aplicador')
        },
        setUnselectAllItems() {
            this.membros = Amostra.setUnselectAllItems(this.membros, 'selectMembros')

            Amostra.setAllSelectedItems(this.membros, 'aplicador')
        },
        setData() {
            this.membros = this.$store.getters.getListMembros || []
            this.membros_amostra = this.$store.getters.getListCampoMembros || []

            this.membros.forEach(membro => {
                membro.isLocked = false
                this.membros_amostra.forEach(membro_amostra => {
                    if (membro_amostra.idusuario == membro.idusuario) {
                        membro.isLocked = membro_amostra.isLocked
                    }
                })
                membro.isSelect = {
                    isSelected: this.membros_amostra.some(membro_amostra => membro_amostra.idusuario == membro.idusuario),
                    isSaved: true
                }
            })

            this.isLoadedInfo = true
        },
        async loadData() {
            const payload = {
             idInstituicao: this.$route.params.idInstituicao,
             idAmostra: this.$route.params.idCampo
            }
            await this.$store.dispatch('setListCampoMembros', payload)
            await this.$store.dispatch('setListMembros', this.$route.params.idInstituicao)
        },
        async applyChanges() {
            if (this.$store.getters.getAmostraStatusAtiva == 'ON') {
                const result = await Swal.fire({
                    title: "Tem certeza que deseja aplicar as alterações?",
                    text: "Você está em um campo ativo, os elementos inseridos não poderão ser modificados.",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    cancelButtonText: "Cancelar",
                    confirmButtonText: "Sim, tenho certeza",
                }) 
                
                if (result.isConfirmed) {
                   
                    Amostra.setAllSelectedItems(this.membros, 'aplicador')
                
                    const payloadManageAplicadores = this.$store.getters.getPayloadManageAplicadores
                    const payload1 = {
                        idInstituicao: this.$route.params.idInstituicao,
                        idAmostra: this.$route.params.idCampo,
                        ids: JSON.parse(JSON.stringify(payloadManageAplicadores))
                    }
                    
                    await this.$store.dispatch('setManageAplicadores', payload1)
                    
                    this.loadData()
                    Swal.fire(
                        'Alterações aplicadas!',
                        'As seleções foram aplicadas com sucesso.',
                        'success'
                    )
                }
   
            } else {
                Amostra.setAllSelectedItems(this.membros, 'aplicador')
                
                const payloadManageAplicadores = this.$store.getters.getPayloadManageAplicadores
                const payload1 = {
                    idInstituicao: this.$route.params.idInstituicao,
                    idAmostra: this.$route.params.idCampo,
                    ids: JSON.parse(JSON.stringify(payloadManageAplicadores))
                }
                await this.$store.dispatch('setManageAplicadores', payload1)
                
                this.loadData()
                Swal.fire(
                    'Alterações aplicadas!',
                    'As seleções foram aplicadas com sucesso.',
                    'success'
                )
            }
        },
        setSelectedValue() {
            this.selectedValue = this.$refs.filtersearchname.value
        }
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idCampo

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome do Campo"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.campos.filter(team => 
                team.idamostra == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeCampo = teamSelected.titulo
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.png");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/roxoEapi.png");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/roxoMaisClaro.png");

     border: 1px solid #958db1;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

.label-filtro {
    font-weight: 600;
}

.filter-box {
    margin: 1rem 3rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: flex-end;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
    }
}
    
</style>
