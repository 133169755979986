<template>
  <ModalCentral
      v-if="userPerm"
      componentName="EditCard"
      :title="idCard ? 'Editar card' : 'Adicionar card'"
      idName="editCardInfoCard"
      :data="{ idCard: idCard, ordem: order, idTipo: idTipo }"
  />

<div class="container">

<!--Section: Publicações e Conteúdos-->
<section id="publicacoes">
      <div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap gap-3 mb-4">
        <div class="">
          <!-- Heading -->
          <h1 class="font-weight-bold">CONTEÚDO</h1>
          <!-- Description -->
          <p class="grey-text">Nossas publicações e conteúdos</p>
        </div>

        <div class="d-flex">
          <!-- Carousel controls -->
          <div class="p-2 text-right">
            <a class="btn btn-success mb-3 mr-1" href="#carouselExampleIndicators" role="button" data-slide="prev" style="color:white;">
                <i class="fa fa-arrow-left"></i>
            </a>
            <a class="btn btn-success mb-3 " href="#carouselExampleIndicators" role="button" data-slide="next" style="color:white;">
                <i class="fa fa-arrow-right"></i>
            </a>
          </div>

          <!-- Add card button -->
          <div class="py-2" v-if="userPerm"> 
                <button type="button" 
                @click="setIdCard(parseInt(cards.length) + 1, true, 41)"
                data-toggle="modal"
                data-target="#editCardInfoCard"
                class="btn btn-success btn-circle">
                  <i class="fas fa-plus"></i>
                </button>
          </div>

        </div>
      </div>

      <!--Grid row-->
        <div id="carouselExampleIndicators" class="carousel slide" data-interval="false">
          <div class="carousel-inner row">
            <div class="carousel-item"
              v-for="(numCards) in homeBotLength" 
              :key="numCards"
              :class="{ active: numCards == 1 }">
              <!--Card-->
              Slide <span style="color:rgb(0, 133, 59); font-weight: 600;">{{ numCards }}</span> de <span style="color:rgb(0, 133, 59); font-weight: 600;">{{ homeBotLength }}</span>
              <div class="row">
                <div class="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3 p-2"
                    v-for="card in homeBot.slice((numCards-1)*4, ((numCards-1)*4) + 4)" 
                    :key="card.idCard">
                  <div class="card card-info-cards bg-dark text-white">

                    <img class="card-img"
                    v-if="card?.imagem?.length > 0"
                    :src="path_imagem(card?.imagem[0])"
                    :alt="card?.titulo"/>

                    <div class="card-img-overlay cardOuter">
                      <div class="cardContent" lang="pt-BR">
                        <div class="cardOptions text-center" v-if="userPerm">
                          <button type="button" class="btn btn-success btn-circle" @click="setIdCard(card.idCard)" data-toggle="modal" data-target="#editCardInfoCard">
                                <i class="fas fa-pen"/>
                          </button> 
                          <button type="button" class="btn btn-success btn-circle" @click="deleteCard(card.idCard)">
                              <i class="fas fa-trash"></i>
                          </button>
                        </div>
                        <h5 class="cardTitle">
                          {{ card?.titulo }}
                        </h5>
                        <div class="cardText mb-4" lang="pt-BR">
                          <p class="cardText" lang="pt-BR" v-html="card?.corpoTexto"></p>
                        </div>
                        <div class="cardButtons">
                          <a
                            v-for="(button, idx) in card?.botao"
                            :key="idx"
                            :href="button?.link"
                            target="_blank"
                            class="text-center greenGradient">
                            {{ button?.texto }}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        <!--Card-->
          </div>
        </div>
        </div>
      <!-- Container -->

    </section>
    <!--Section: Publicações e Conteúdos-->
</div>
</template>

<script type="text/javascript">
 import ModalCentral from '@components/ModalCentral';
 import axios from '@middleware/api.js';
 import Swal from 'sweetalert2';
 import { Notyf } from "notyf";
 const notyf = new Notyf({
     duration: 5000,
     position: {
         x: 'left',
         y: 'bottom',
     }
 });

export default {
  name: "InfoCards",
  components: {
      ModalCentral,
  },
  data() {
      return {
          idCard: null,
          order: null,
          idTipo: null,
          path: null
      };
  },
  async created() {
      this.$store.dispatch("setListCards");
  },
  methods: {
    setIdCard(id, newCard, idTipo) {
      this.idCard = newCard ? null : id;
      this.order = newCard ? id : null;
      this.idTipo = newCard ? idTipo : this.cards.find(card => card.idCard == id)?.idTipo;
    },
     async deleteCard(idCard) {
        const result = await Swal.fire({
            title: 'Deseja realmente excluir esse?',
            text: 'Tem certeza que deseja excluir esse card? Essa operação não pode ser desfeita.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, tenho certeza'
        });
        if (!result.isConfirmed) return;

        await this.$store.dispatch("deleteCard", idCard);

        if (this.$store.getters.getCardDeleted) {
            notyf.open({
                type: "success",
                message: "Card excluído com sucesso",
            });
            this.$store.dispatch("setListCards");
        } else {
            notyf.open({
                type: "error",
                message: "Não foi possível excluir o card",
            });
        }
    },
    path_imagem(path) {
        if(!path)
          return require('@/assets/img/NewHome/InfoCards/card-img.png');
        else
          return `${axios.defaults.baseURL}/materiais_documentos/cards/getImage?path=${path}`;
      },
  },
  computed: {
      userPerm() {
          try{
              return this.$store.getters.getUserInfo.data.isAdmin;
          } catch(e) {
              return false;
          }
      },
      cards() { return this.$store.getters.getListCards; },
      homeBot() { return (this.cards || []).filter(card => card?.idTipo == 41); },
      homeBotLength() { return this.homeBot.length > 1 ? Math.ceil(this.homeBot.length / 4) : this.homeBot.length;},
  },
};
</script>

<style type="text/css" media="screen">
 @import url('../../assets/css/TransicaoEscolaTrabalho/styling.css');

 @media screen and (max-width: 700px) {
   .card-img-overlay {
     padding: 5px;
   }
 }

 .card-img-overlay{
    max-height: calc(100vh - 3rem);
    cursor: pointer;
    background: linear-gradient(
      45deg,
      rgba(0, 133, 59, 0.6),
      rgba(64, 201, 162, 0.4) 100%);

 }

 .card-info-cards{
    transition: 0.3s;
    max-height: 400px;
    min-height: 400px;
    overflow: hidden;
    border-radius: calc(0.5rem - 1px);
 }

 .card-info-cards:hover{
  transform: scale(1.05);
  transform-origin: center;
 }


 .cardText{
  width: 100%;
 }

 p.cardText{
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
 }

 .cardTitle{
  width: 100%;
 }

 .cardOptions{
  width: 100%;
 }
 .cardButtons{
  width: 100%;
 }

 img.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  flex-shrink: 0;
 }

 .cardContent {
   width: 100%;
   height: 100%;
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
   align-items: center;

   text-overflow: ellipsis;
   word-break: break-word;
   -webkit-hyphens: auto;
   -moz-hyphens: auto;
   -ms-hyphens: auto;
   hyphens: auto;
 }

 .cardContent > * {
   flex: 1 0 100%;
   overflow: hidden;
   text-overflow: ellipsis;
 }

 .btn-circle {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 6px 0px;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 50%;
}

.btn-circle:not(:last-child){
    margin-right: 10px;
}

i{
    font-family: "Font Awesome 6 Free" !important; 
}

.carousel-inner, .carousel-item{
  width: 100%;
  transition: -webkit-transform 0.2s ease !important;
  transition: transform 0.2s ease !important;
  transition: transform 0.2s ease, -webkit-transform 0.2s ease !important;
}

::v-deep b, ::v-deep strong{
    color: #00853B !important;
}

a.greenGradient{
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;

    text-decoration: none;
    color: initial;

    text-align: center;
    font-weight: bolder;
    border-radius: 8px;
    line-height: 0.85em;
    padding: 12px 20px;
    box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.2);

    /*max-width: 20rem; */

}

</style>
