<template>
    <div class="root-dados">
        <div class=" container list-data">
            <Breadcrumb class="breadcrumb-style" :crumbs="crumbs" section="instituicao-cadastrar"/>
            <br/>
            
            <h3 class="title-info"> Formulários </h3>
            
            <div class="title-row">
                <InstitutionInfo class="title-style" :title="nomeInstituicao" section="instituicao-cadastrar"/>
            </div>

            <p class="text-info-personal mt-4 mb-6">Nesta seção, você encontrará todos os formulários da Instituição. Caso necessário, utilize o filtro abaixo para localizar o formulário desejado.</p>

            <!-- INICIO DO FORM DE FILTRAGEM -->
            <div class="input-group">
                <input class="form-control input-personal" v-model="searchByName" type="text" name="filtro_nome_membros" id="inlineFormCustomName" placeholder="Pesquisar formulários por nome..." autocomplete="off">
                <div class="input-group-append">
                    <!-- <i class="fas fa-bars"></i> -->
                    <span class="input-group-text"> Nome </span>
                </div>
            </div>
            <hr class="separator">

            <!-------------------------------- LISTANDO OS FORMULÁRIOS ----------------------------------------------->

            <div v-if="isLoadedInfo">
                <MemberQnts
                    term="formulários"
                    :totalQnt="totalFormularios"
                    :filteredQnt="formularios?.length || 0"
                    icon="clipboard" />

                <transition-group v-if="formularios?.length > 0" name="slide-fade">

                    <div class = "table-wrap">
                        <table class="table mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">  </th>
                                    <th scope="col">Nome</th>
                                    <th scope="col">Modelo</th>
                                    <th scope="col">Data de criação</th>
                                    <th scope="col">Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="table-row" v-for="formulario in formularios" :key="formulario.idformulario">

                                    <td>
                                        <img  class="img-info-box-lista" src="@assets/img/Cards/Documento.svg" alt="">
                                    </td>

                                    <td> {{formulario.nome}} </td>

                                    <td> {{formulario.modelo}} </td>

                                    <td> - </td>

                                    <td>
                                        <div class="controlGroup">
                                            <!-- <Button
                                                 class="third-info-box-lista btnLista"
                                                 value="Editar"
                                                 :isOutline="true"
                                                 :has-icon="true"
                                                 iconName="pencil"
                                                 />
                                                 <Button
                                                 class="third-info-box-lista btnLista"
                                                 value="Excluir"
                                                 :isOutline="true"
                                                 :has-icon="true"
                                                 iconName="trash"
                                                 /> -->
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </transition-group>
                <transition-group v-else>
                    <h2 class="title-style-box-lista">Nenhum formulário encontrado.</h2>
                </transition-group>

            </div>

            <div v-else>
                <SkeletonList/>
            </div>

        </div>
    </div>

</template>

<script>
 // import Button from '@components/Button'
// import ListagemElements from '@components/Dados/VisualizarInstituicao/ListagemElement'
import Breadcrumb from '@components/Breadcrumb'
import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo'
import SkeletonList from '@components/SkeletonUI/SkeletonList'
import MemberQnts from '@dados/VisualizarInstituicao/MemberQnt'

export default {
    name: 'ListarFormularios',
    data (){
        return {
            nomeInstituicao: '',
            descricao: '',
            selectedValue: '',
            searchByName: '',
            isLoadedInfo: false,
            teste: [],
            options: [
                {
                id:1,
                value: 'Todos'
                },
                {
                id:2,
                value: 'Nome'
                }
            ],
            crumbs: [
                {
                    id:1,
                    value: '',
                    iconName: 'th',
                    name_route: 'GerenciarInstituicoes'
                },
                {
                    id:2,
                    value: 'Instituição',
                    iconName: 'university',
                    name_route: 'InstituicaoMenu'
                },
                {
                    id:3,
                    value: 'Cadastrar Informações',
                    iconName: 'search-plus',
                    name_route: 'InstituicaoMenuCadastrar'
                },
                {
                    id:4,
                    value: 'Formulários',
                    iconName: 'file-alt',
                    name_route: ''
                }
            ]
        }
    },
    async created() {
        this.teams = this.$store.getters.getListTeams

        this.isLoadedInfo = false
        await this.$store.dispatch('setListFormularios', {
            idInstituicao: this.$route.params.idInstituicao,
        })
        this.isLoadedInfo = true
    },
    computed: {
        totalFormularios() {
            return this.$store.getters.getListFormularios?.length || 0;
        },
        formularios() {
            var formularios = this.$store.getters.getListFormularios

            // VARIAVEL QUERY INICIA COM TODOS OS ITENS DO ARRAY
            var query = formularios;

            if (this.searchByName.trim() != '') {
                var filtered = formularios?.filter(formulario =>formulario.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
                // console.log(filtered);
                if(filtered?.length != 0){
                    query = filtered
                }

                else{
                    query = false
                }
            }

            //CASO SEJA SELECIONADO TODOS, EXIBE TODOS OS ITENS DO ARRAY
            // if (this.selectedValue == 1) {
            //     query = formularios;
            // }
            //     //CASO SEJA SELECIONADO NOME, FILTRA POR NOME
            // if (this.selectedValue == 2) {
            //     //SE ESTIVER VAZIO O CAMPO DE BUSCA RETORNA TODOS
            //     if (this.searchByName.trim() == '') {
            //         query = formularios;
            //     }
            //     //SENAO ELE FILTRA PELO O QUE FOR DIGITADO
            //     else {
            //         var filtered = formularios?.filter(formulario =>formulario.nome.trim().toLowerCase().match(this.searchByName.trim().toLowerCase()))
            //             // console.log(filtered);
            //         if(filtered?.length != 0){
            //             query = filtered
            //         }
            //
            //         else{
            //             query = false
            //         }
            //     }
            // }

            return query;
        }   
    },
    components: {
        // Button,
        // ListagemElements,
        Breadcrumb,
        InstitutionInfo,
        SkeletonList,
        MemberQnts,
    },
    methods: {
        setSelectedValue: function() {
            this.selectedValue = this.$refs.filtersearchname.value
        }
        
    },
    mounted: function() {
        window.scrollTo(0, 0);
        let idTeamSelected = this.$route.params.idInstituicao

        if (typeof idTeamSelected == "undefined"){
            this.nomeInstituicao = "Nome da Instituição"
        }
        else {
            let teamsSelected
            let teamSelected

            teamsSelected = this.teams.filter(team => 
                team.idinstituicao == idTeamSelected
            )

            teamSelected = [...teamsSelected].shift()

            this.nomeInstituicao = teamSelected.name
            this.descricao = teamSelected.descricao
        }
    }
}
</script>

<style scoped>

@import "../../../assets/css/Dados/list.css";

 .imgText {
     background-image: url("~@/assets/img/Backgrounds/azulEapi.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

 .box-lista {
     background-repeat: repeat;
     background-size: 256px;
     background-image: url("~@/assets/img/Backgrounds/azulMaisClaro.webp");
     box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
     border: 1px solid #952713;
 }

 .btnInvite {
     box-sizing: border-box;
     border: none;
 }

 :deep(.btnInvite div) {
     background-clip: text;
     background-repeat: repeat;
     background-size: 256px;
     color: #00853B;
     padding: 0.5rem 0;
     transition: 0.4s;
 }

 :deep(.btnLista div) {
     color: #fff;
     text-align: center;
 }

 :deep(.box-lista span) {
     font-size: 1.1em;
 }

 .box-lista span {
     background-image: url("~@/assets/img/Backgrounds/marromEscuro.webp");
     background-clip: text;
     text-fill-color: transparent;
     background-repeat: repeat;
     background-size: 256px;
     color: transparent !important;
 }

.text-info-personal {
  color: #fff;
  font-weight: 450;
  font-size: 1.1rem;
  text-align: justify;
}

.label-filtro {
    font-weight: 600;
}

.title-info{
    font-weight: 600;
    font-size: 1.6rem;
    color: #fff;
    margin: 0;
}

.title-style{
    margin: 0;
    padding: 0;
}

 h3.title-info{
    margin-top: 1.6rem;
    font-size: 1.25rem;
 }

.filter-box {
    margin: 1rem 3rem;
}

.list-box {
    margin: 2rem;
}

.itens-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.title-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.input-group{
    border: 2px solid #00853B;
    width: auto;
    border-radius: 8px;
}

input{
    border-style: solid;
    box-shadow: inset 0px 0px 0px 0px red;
}

.input-group-append{
    text-decoration: none;
    border-radius: 8px;
    background: #fff;
}


.lista-data-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 3rem;
}

.title-tree h1 {
    font-size: 1.2rem;
    font-weight: 700;
}

.main-link {
    outline:none;
    text-decoration: none;
    cursor: pointer;
    margin-right: .3rem;
}

.sub-link {
    margin-left: .3rem;
}

.separator {
    border: none;
    height: 1px;
}

a {
    color: #212529;
    text-decoration: none;
    transition: all ease-in-out 0.2s
}

a:hover {
    color: #4d4f50;
}

@media screen and (max-width: 1010px) {
    .lista-data-info {
        justify-content: center;
    }
}


@media screen and (max-width: 1012px) {
    .filter-box {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (min-width: 901px) {
    .first-info {
        flex-basis: 35%;
        text-align: left;
    }
}

@media screen and (max-width: 680px) {
    .itens-box{
        flex-direction: column;
        align-items: flex-start;
    }
    .filter-box{
        margin: 0 3rem;
    }
    .lista-data-info{
        margin: 0 3rem;
    }
    .first-info {
        text-align: center;
        width: -webkit-fill-available;
    }
    .box-lista span, .box-lista a{
        width: 100%;
        text-align: center;
        margin-top: .5rem;
    }
    .box-lista{
        place-content: center;
    }
}

 .breadcrumb-style{
     display: inline-flex;
 }

 .img-info-box-lista {
     padding: 10px;
 }

</style>
