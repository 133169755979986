<template>
    <div class="root-dados">
        <ListTeams/>
    </div>
</template>

<script>
import ListTeams from '@dados/GerenciarInstituicoes/ListTeams';

export default {
    name: 'GerenciarInstituicoes',
    components: {
        ListTeams
    }
}
</script>

<style scoped>

.root-dados{
    background: linear-gradient(180deg, rgba(0, 133, 59, 0.8) 0%, rgba(0, 133, 59, 0) 100%) !important;
    padding: 2em 0;
}

</style>
