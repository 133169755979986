<template>
    <form @submit.prevent>
    <div class="container offset">
        <div class="custom-form">

            <div class="form-group">
                <label class="question-style">Título do PDF:</label>
                <input
                    v-model.trim="title"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o título do PDF..."
                    required
                />
            </div>
            <div class="form-group">
                <label class="question-style">Link do PDF:</label>
                <input
                    v-model.trim="pdfLink"
                    type="name"
                    class="form-control"
                    placeholder="Insira aqui o link do PDF..."
                    required
                />
            </div>
        </div> 
        <div class="button-div pt-3">
            <Button 
                @click="addPDF()"
                section="instituicao-campo"
                value="Adicionar PDF"
                :isOutline="true"
                :hasIcon="true"
                iconName="plus"
            /> 
        </div>
    </div>
    </form>
   
</template>

<script>

import Button from '@components/Button'
// import Swal from 'sweetalert2';
// import { Notyf } from "notyf";
// const notyf = new Notyf({
//     duration: 5000,
//     position: {
//         x: "left",
//         y: "bottom",
//     }
// });

export default {
    name: "AddPDF",
    data() {
        return {
			categoria: '',
			title: '',
			pdfLink: '',
		}
    },
    components: {
        Button
    },
    methods: {
		async addPDF() {
			const payload = {
				categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
				titulo: this.title,
				link: this.pdfLink,
				isVideo: 'false',
			}
			await this.$store.dispatch('createModal', payload)
			await this.$store.dispatch('setListModais', {
				categoria: window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1),
			})
			this.clearForm()
			this.$emit('closeModal')
		},

		clearForm() {
			this.title = ''
			this.pdfLink = ''
		},
	},
}

</script>

<style scoped>

    .container{
        padding: 1rem;
        width: 100%;
        background-color: white;
        margin: 0;
    }

    .title-style{
        font-size: 1.5rem;
        font-weight: 600;
        color: #656565;
    }

    .word-style{
        color:  #656565;
    }

    .custom-form{
        margin: 0 auto;
    }

    .question-style{
        width: 100%;
        font-size: 1rem;
        color: #656565;
    }

    .button-div{
        text-align: right;
    }

</style>
