<template>

    <div class="root-dados">
        <div class="container">
            <InstitutionInfo class="imgText title-info" :title="nomeInstituicao" :crumbs="crumbs" section="instituicao-campo" :text="descricao"/>

            <div class="cardsful-bg">

                <!-- <router-link :to="{name: 'CampoListaMembros'}">
                     <ColorfulCardsBG
                     :hasIcon="true"
                     class="cardful-single"
                     thumbnail="user-friends"
                     title="Membros"
                     palette="pink-section"
                     />
                     </router-link>
                -->

                <router-link :to="{name: 'CampoListaFormularios'}">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="file-alt"
                        title="Formulários"
                        palette="pink-section"

                    />
                </router-link>

                <router-link :to="{name: 'CampoListaUnidadesEducacionais'}">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="graduation-cap"
                        title="Unidades Educacionais e Turmas"
                        palette="pink-section"
                    />
                </router-link>

                <router-link :to="{ name: 'CampoListaEmpresas', }">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="building"
                        title="Empresas"
                        palette="orange-section"
                    />
                </router-link>

                <router-link :to="{name: 'CampoListaEgressos'}">
                    <ColorfulCardsBG
                        :hasIcon="true"
                        class="cardful-single"
                        thumbnail="door-open"
                        title="Egressos"
                        palette="pink-section"
                    />
                </router-link>

            </div>
        </div>
    </div>

</template>

<script>
 import InstitutionInfo from '@dados/VisualizarInstituicao/InstitutionInfo';
 import ColorfulCardsBG from '@components/ColorfulCardsBG'

 export default {
     name: 'Instituicao',
     data () {
         return {
             nomeInstituicao: '',
             descricao: '',
             campos: [],
             crumbs: [
                 {
                     id:1,
                     value: '',
                     iconName: 'th',
                     name_route: 'GerenciarInstituicoes'
                 },
                 {
                     id:2,
                     value: 'Instituição',
                     iconName: 'university',
                     name_route: 'InstituicaoMenu'
                 },
                 {
                     id:3,
                     value: 'Campos',
                     iconName: 'th-large',
                     name_route: 'ListarCampos'
                 },
                 {
                     id:4,
                     value: 'Gerenciar Campo',
                     iconName: 'chart-pie',
                     name_route: ''
                 }
             ]
         }
     },
     components: {
         InstitutionInfo,
         ColorfulCardsBG,
     },
     created(){
         this.campos = this.$store.getters.getListCampos
     },
     mounted: function() {
         window.scrollTo(0, 0);
         let idTeamSelected = this.$route.params.idCampo

         if (typeof idTeamSelected == "undefined"){
             this.nomeInstituicao = "Nome do Campo"
         }
         else {
             let teamsSelected
             let teamSelected

             teamsSelected = this.campos.filter(team =>
                 team.idamostra == idTeamSelected
             )

             teamSelected = [...teamsSelected].shift()

             this.nomeInstituicao = teamSelected.titulo
             this.descricao = teamSelected.descricao
         }
     },
     computed: {
         userPerm() {
             return this.$store.getters.getUserPerm;
         },
     }
 }

</script>

<style scoped>
 .bg {
     background-image: url("~@/assets/img/Backgrounds/branco.webp");
     background-repeat: repeat;
     background-size: 256px;
     overflow: auto;
     box-sizing: border-box;
 }
 .cardsful-bg {
     display: flex;
     align-items: center;
     justify-content: center;
     flex-wrap: wrap;
 }
 
 .cardful-single {
     margin: 1rem;
 }
</style>
